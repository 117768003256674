import React, { Component } from "react";
import RemoteAll from "../components/RemoteAll";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";

import { readParameter } from "../modules/ParameterModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Select from "react-select";
import {
  addFactor,
  overwriteFactor,
  readFactor,
  editFactor
} from '../modules/FactorModule'

class Factor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showSpotPriceOutlook: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      isValidParameter: true,
      isValidParameterCreate: true,
      isClearable: true,
      collection: {},
      select_items_paramater: [],
      list_datatable: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "parameter_value",
      sortOrder: "asc",
      count_data: null,
      current_page: 1,
      errors: {},
      errors_create: {},
      id: null,
      parameter_code: null,
      parameter_value: null,
      factor: null,
      requester: null,
      material: null,
      date: null,
      parameter_create_id: null,
      parameter_create_value: null,
      date_create: null,
      factor_create: null,
      columns: [
        {
          dataField: "parameter_code",
          text: "Code",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "parameter_value",
          text: "Parameter",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "factor",
          text: "Factor",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "updated_at",
          text: "Updated On",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "parameter_value",
          order: "asc",
        },
      ],
      cellEditProps: {},
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readParameter().then((response) => {
      this.setState({ select_items_paramater: response });
    });

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readFactor(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

    this.setState({
      date_create: new Date(),
      requester: user.username,
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      })
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit
      })
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.created_at), "dd-mmm-yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    return isEdit ? (
      <div>
        <button
          key={row.id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row.id,
              row.parameter_code,
              row.parameter_value,
              row.factor,
              is_noneditable,
            )
          }
          }
        >
          <i className="fas fa-edit" key={row.id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //handle change
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + '_code']: newValue.value
      });
      if (name == "parameter") {
        this.setState({
          isValidParameter: true,
        });
      } else if (name == "parameter_create") {
        this.setState({
          isValidParameterCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + '_code']: null
      });
      if (name == "parameter") {
        this.setState({
          isValidParameter: false,
        });
      } else if (name == "parameter_create") {
        this.setState({
          isValidParameterCreate: false,
        });
      }
    }
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(isNaN(cell) ? '' : cell, ".", ".", ",")}</div>;
  };
  toDetail = (
    id,
    parameter_code,
    parameter_value,
    factor,
  ) => {
    this.setState(
      {
        id: id,
        parameter_code: parameter_code,
        parameter_value: parameter_value,
        factor: factor,
        submitted: false,
        isValidParameter: true,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      parameter_code: null,
      parameter_value: null,
      factor: null,
      showModal: !this.state.showModal,
      readOnly: false
    });
  };
  //validate input
  validateForm = (parameter_code, factor) => {
    let errors = this.state.errors;
    if (!parameter_code) {
      errors.parameter = "This is field required";
      this.setState({
        isValidParameter: false,
      })
    }
    if (!factor) {
      errors.factor = "This is field required";
    } else if (factor < 0) {
      errors.factor = "Value cannot below 0"
    }
    return errors;
  };
  validateFormCreate = (parameter_code, factor) => {
    let errors = this.state.errors_create;
    if (!parameter_code) {
      errors.parameter = "This is field required";
      this.setState({
        isValidParameterCreate: false,
      })
    }
    if (!factor) {
      errors.factor = "This is field required";
    } else if (factor < 0) {
      errors.factor = "Value cannot below 0"
    }
    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    this.form.reset();
    this.refParameter.state.value.value = "";
    this.refParameter.state.value.label = "";
    this.setState({
      submitted_create: false,
      parameter_code: null,
      factor_create: null
    });
    readParameter().then((response) => {
      this.setState({ select_items_paramater: response });
    });

    const { parameter_code, sortBy, sortOrder, page, sizePerPage } = this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (parameter_code) {
      query_string += `&parameter_code=${parameter_code}`;
    }
    readFactor(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

  }
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const { requester, factor_create, parameter_create, parameter_create_code } =
      this.state;
    if (!this.validateFormCreate(parameter_create, factor_create)) {
      return;
    }

    let collection = {
      requester: requester,
      parameter_code: parameter_create_code,
      parameter_value: parameter_create,
      factor: factor_create,
    };

    if (requester && parameter_create && parameter_create_code && factor_create >= 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          //TODO
          addFactor(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-factor-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (err === "data is already exist, Are you sure to overwrite data ?!") {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${parameter_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteFactor(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          this.reloadDataBackend()
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const {
      parameter_code,
      parameter_value,
      factor,
      requester
    } = this.state;

    if (
      !this.validateForm(parameter_code, factor)) {
      return;
    }
    const { history } = this.props;
    let collection = {
      parameter_code: parameter_code,
      parameter_value: parameter_value,
      factor: factor,
      requester: requester
    };

    if (
      parameter_code &&
      parameter_value &&
      factor >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          //TODO
          editFactor(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted: false
              })
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { parameter_code, sortBy, sortOrder, page, sizePerPage } = this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (parameter_code) {
      query_string += `&parameter_code=${parameter_code}`;
    }

    readFactor(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState({
      sizePerPage: sizePerPage,
      page: page
    });

    const { parameter_code } = this.state;
    let query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (parameter_code) {
      query_string += `&parameter_code=${parameter_code}`;
    }
    readFactor(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      list_datatable,
      select_items_paramater,
      id,
      parameter_code,
      parameter_value,
      factor,
      parameter_create,
      parameter_create_id,
      factor_create,
      errors,
      errors_create,
      submitted_create,
      submitted,
      loading,
      isValidParameter,
      isValidParameterCreate,
      isClearable,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
    } = this.state;



    const customStylesParameter = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidParameter
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidParameter
              ? "#ddd"
              : "green",
        },
      }),
    };

    const customStylesParameterCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidParameterCreate
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidParameterCreate
              ? "#ddd"
              : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Factors
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.parameter != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="parameter">Parameter *</label>
                        <Select
                          isDisabled
                          styles={customStylesParameter}
                          onChange={this.handleChange.bind(this, "parameter")}
                          options={select_items_paramater}
                          defaultValue={select_items_paramater.filter(
                            (e) => e.label === parameter_value
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidParameter && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.parameter}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="factor">Factor *</label>
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="factor"
                          placeholder="Factor"
                          required="required"
                          value={factor || ""}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.factor && (
                          <div className="invalid-feedback">{errors.factor}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Factors</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={form => this.form = form}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div
                                  className={
                                    errors_create.parameter !=
                                      "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="parameter">Parameter *</label>
                                  <Select
                                    styles={customStylesParameterCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "parameter_create"
                                    )}
                                    options={select_items_paramater}
                                    defaultValue={select_items_paramater.filter(
                                      (e) => e.value === parameter_create
                                    )}
                                    isClearable={isClearable}
                                    ref={ref => this.refParameter = ref}
                                  />

                                  {!isValidParameterCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.parameter}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="factor_create">Factor *</label>
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="factor_create"
                                    placeholder="Factor"
                                    required="required"
                                    value={factor_create || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                  />
                                  {errors_create.factor && (
                                    <div className="invalid-feedback">
                                      {errors_create.factor}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">Parameter </label>
                                  <Select
                                    onChange={this.handleChange.bind(
                                      this,
                                      "parameter"
                                    )}
                                    options={select_items_paramater}
                                    defaultValue={select_items_paramater.filter(
                                      (e) => e.label === parameter_code
                                    )}
                                    isClearable={isClearable}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}><br></br>
                                <div className="form-group">
                                  <label htmlFor="parameter">&nbsp;</label>
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(Factor);
