import React, { Component } from "react";
import Footer from "../../components/templates/Footer";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";

import Swal from "sweetalert2";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import thousandformat from "../../helpers/thousanddelimiter";

import "react-datepicker/dist/react-datepicker.css";
import { Link, withRouter } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  getSegment,
  getCustomer,
  getFreight,
  getMaterial,
  getVehicle,
  getStockPrice,
  calculatePrice,
} from "../../modules/sodiq/CustomerPriceModule";
import { getLastExchange } from "../../modules/POContractModule";
import moment from "moment";

const DECIMAL_PLACES = 2;
const convertToRupiah = (angka) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(angka)
    .replace(/\D00(?=\D*$)/, "");
};

class CustomerPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidMaterial: true,
      loading: false,
      list_segment: [],
      list_customer_sold: [],
      list_customer_ship: [],
      list_origin: [],
      list_city: [],
      list_vehicle: [],
      list_port_loading: [],
      list_port_destination: [],
      list_product: [],
      list_stock_price: [],
      is_local: false,
      is_check: false,
      payload: {},
      min_date: new Date(),
      max_date: new Date(),
      customer_price: 0,
      kurs: 0,
      lowest_price: null,
      stock_point: null,
      freight_cost: null,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const segment_list = await getSegment();
    const customer_list = await getCustomer("sizePerPage=10");
    const freight_list = await getFreight();
    const vehicle_list = await getVehicle();
    const material_list = await getMaterial("sizePerPage=10");
    const stock_price_list = await getStockPrice();

    const temp_list_origin = [];
    const temp_list_city = [];
    const temp_list_port_loading = [];
    const temp_list_port_destination = [];

    Promise.all(
      freight_list.map((item) => {
        const objOrigin = {
          ...item,
          value: item.origin,
          label: item.origin,
        };
        if (
          temp_list_origin.filter((e) => e.origin === item.origin).length ===
            0 &&
          item.origin
        ) {
          temp_list_origin.push(objOrigin);
        }
        const objCity = {
          ...item,
          value: item.city,
          label: item.city,
        };
        if (
          temp_list_city.filter((e) => e.city === item.city).length === 0 &&
          item.city
        ) {
          temp_list_city.push(objCity);
        }
        const objPortLoading = {
          ...item,
          value: item.port_dest_code,
          label: item.port_dest_code,
        };
        if (
          temp_list_port_loading.filter(
            (e) => e.port_dest_code === item.port_dest_code
          ).length === 0 &&
          item.port_dest_code
        ) {
          temp_list_port_loading.push(objPortLoading);
        }
        const objPortDestination = {
          ...item,
          value: item.port_dest_name,
          label: item.port_dest_name,
        };
        if (
          temp_list_port_destination.filter(
            (e) => e.port_dest_name === item.port_dest_name
          ).length === 0 &&
          item.port_dest_name
        ) {
          temp_list_port_destination.push(objPortDestination);
        }
        return item;
      })
    );

    const curKurs = await this.exchangeRate();
    const currDate = new Date();
    const max = new Date(currDate.setMonth(currDate.getMonth + 5));
    this.setState({
      list_segment: segment_list,
      list_customer_sold: customer_list,
      list_customer_ship: customer_list,
      list_product: material_list,
      list_vehicle: vehicle_list,
      list_origin: temp_list_origin,
      list_city: temp_list_city,
      list_port_loading: temp_list_port_loading,
      list_port_destination: temp_list_port_destination,
      list_stock_price: stock_price_list,
      max_date: max,
    });
    this.setState({
      loading: false,
      kurs: curKurs.kurs_tengah ? parseFloat(curKurs.kurs_tengah) : 0,
    });
  }

  listSegment = (inputValue) => {
    const getOptionSegment = (search) => {
      let params = {
        search: search,
      };
      return getSegment(new URLSearchParams(params));
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOptionSegment(inputValue));
      }, 1000);
    });
  };

  listProduct = (inputValue) => {
    const getOption = (search) => {
      let params = {
        search: search,
      };
      return getMaterial(new URLSearchParams(params));
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listCustomer = (inputValue) => {
    const { list_customer_sold } = this.state;
    const getOption = (search) => {
      return list_customer_sold.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listOrigin = (inputValue) => {
    const { list_origin } = this.state;
    const getOption = (search) => {
      return list_origin.filter((e) =>
        e.origin.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listCity = (inputValue) => {
    const { list_city } = this.state;
    const getOption = (search) => {
      return list_city.filter((e) =>
        e.city.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listLoading = (inputValue) => {
    const { list_port_loading } = this.state;
    const getOption = (search) => {
      return list_port_loading.filter((e) =>
        e.port_dest_code.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listDestination = (inputValue) => {
    const { list_port_destination } = this.state;
    const getOption = (search) => {
      return list_port_destination.filter((e) =>
        e.port_dest_name.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listType = (inputValue) => {
    const { list_vehicle } = this.state;
    const getOption = (search) => {
      return list_vehicle.filter((e) =>
        e.label.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  listPrice = (inputValue) => {
    const { list_stock_price } = this.state;
    const getOption = (search) => {
      return list_stock_price.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      );
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOption(inputValue));
      }, 1000);
    });
  };

  handleChange = (value, name) => {
    if (name === "segment") {
      const { payload } = this.state;
      payload.origin = null;
      payload.city = null;
      payload.port_loading = null;
      payload.port_destination = null;
      this.setState({
        is_local: value.is_local,
        payload: payload,
        customer_price: 0,
      });
    }
    if (name === "planning_month") {
      const min = new Date(value);
      const currDate = new Date(value);
      const max = new Date(currDate.setMonth(currDate.getMonth() + 5));
      this.setState({
        min_date: min,
        max_date: max,
      });
    }

    const { payload } = this.state;
    payload[name] = value;
    this.setState({
      payload: payload,
    });
  };

  showToats = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "toats",
      onClose: () => this.setState({ submitted: false }),
    });
  };

  exchangeRate = async () => {
    const stMonth = moment().format("YYYY-MM-DD");
    const query_string_ex = `start_month=${stMonth}&currency=USD`;
    return await getLastExchange(query_string_ex);
  };
  handleCustomerPrice = async () => {
    const { payload, is_local, is_check, kurs } = this.state;
    let check = false;
    let checkStock = false;
    if (is_local) {
      if (payload.origin && payload.city) {
        check = true;
      }
    } else {
      if (payload.port_loading && payload.port_destination) {
        check = true;
      }
    }

    if (is_check) {
      if (payload.stock_price) {
        checkStock = true;
      }
    } else {
      checkStock = true;
    }

    if (
      checkStock &&
      check &&
      payload.segment &&
      payload.customer_sold &&
      payload.product &&
      payload.customer_ship &&
      payload.input_kg &&
      payload.vehicle_type &&
      payload.vehicle_type &&
      payload.planning_month &&
      payload.production_month
    ) {
      this.setState({ loading: true });
      try {
        const collection = {
          ...payload,
          is_check,
          is_local,
        };
        const res = await calculatePrice(collection);
        const veTonase = res?.vehicle_tonase
          ? parseInt(res.vehicle_tonase)
          : 0;
        const cNp = res.freight_cost
          ? veTonase === 0
            ? 0
            : parseInt(res.freight_cost) / veTonase
          : 0;
        const stockP = res.stock_point ? parseInt(res.stock_point) : 0;
        const spPrice = kurs === 0 ? 0 : stockP / kurs;
        const freC = kurs === 0 ? 0 : cNp / kurs;
        this.setState({
          customer_price: parseFloat(parseInt(res.lower_price) + spPrice + freC),
          lowest_price: res.lower_price,
          stock_point: spPrice,
          freight_cost: freC,
        });
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Calculate Customer Price successfully",
          showConfirmButton: false,
          timer: 1000,
        });
      } catch (err) {
        Swal.fire("Warning", err, "error");
      }
      this.setState({ loading: false });
    } else {
      if (!payload.segment) {
        this.showToats("Please choose segment");
      }
      if (!payload.customer_sold) {
        this.showToats("Please choose customer sold to");
      }
      if (!payload.product) {
        this.showToats("Please choose product");
      }
      if (!payload.customer_ship) {
        this.showToats("Please choose customer ship to");
      }
      if (is_local) {
        if (!payload.origin) {
          this.showToats("Please choose origin");
        }
        if (!payload.city) {
          this.showToats("Please choose city");
        }
      } else {
        if (!payload.port_loading) {
          this.showToats("Please choose loading");
        }
        if (!payload.port_destination) {
          this.showToats("Please choose destination");
        }
      }
      if (!payload.input_kg) {
        this.showToats("Please entries input kg");
      }
      if (!payload.vehicle_type) {
        this.showToats("Please choose vehicle type");
      }
      if (!payload.planning_month) {
        this.showToats("Please entries planning month");
      }
      if (!payload.production_month) {
        this.showToats("Please entries production month");
      }
      if (is_check) {
        if (!payload.stock_price) {
          this.showToats("Please choose stock price");
        }
      }
    }
  };

  render() {
    let {
      loading,
      isValidMaterial,
      list_segment,
      list_customer_sold,
      list_customer_ship,
      list_origin,
      list_city,
      list_port_loading,
      list_port_destination,
      list_vehicle,
      list_product,
      list_stock_price,
      is_local,
      is_check,
      payload,
      min_date,
      max_date,
      customer_price,
      kurs,
      lowest_price,
      stock_point,
      freight_cost,
    } = this.state;

    const customStylesMaterial = {
      option: (provided) => ({
        ...provided,
        color: "black",
      }),
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    const mb3 = { marginBottom: "3px" };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Container
              style={{ backgroundColor: "#007bff", borderRadius: "5px" }}
            >
              <Row>
                <Col xs={12} md={12}>
                  <ProgressBar animated now={100} />
                </Col>
              </Row>
            </Container>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Customer Price</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">customer price</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Customer Price</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Choose Segment
                              </label>
                              <AsyncSelect
                                ref={this.selectRef}
                                isClearable={true}
                                styles={customStylesMaterial}
                                single
                                cacheOptions
                                defaultOptions={list_segment}
                                loadOptions={this.listSegment}
                                onChange={(e) =>
                                  this.handleChange(e, "segment")
                                }
                                value={payload?.segment}
                                thema="info"
                                className="loading"
                                placeholder="Choose..."
                              />
                              <span className="error invalid-feedback">
                                Please choose segment
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Choose Customer Sold To
                              </label>
                              <AsyncSelect
                                ref={this.selectRef}
                                isClearable={true}
                                styles={customStylesMaterial}
                                single
                                cacheOptions
                                defaultOptions={list_customer_sold}
                                loadOptions={this.listCustomer}
                                onChange={(e) =>
                                  this.handleChange(e, "customer_sold")
                                }
                                value={payload?.customer_sold}
                                thema="info"
                                className="loading"
                                placeholder="Choose..."
                              />
                              <span className="error invalid-feedback">
                                Please choose customer sold to
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Choose Product
                              </label>
                              <AsyncSelect
                                ref={this.selectRef}
                                isClearable={true}
                                styles={customStylesMaterial}
                                single
                                cacheOptions
                                defaultOptions={list_product}
                                loadOptions={this.listProduct}
                                onChange={(e) =>
                                  this.handleChange(e, "product")
                                }
                                value={payload?.product}
                                thema="info"
                                className="loading"
                                placeholder="Choose..."
                              />
                              <span className="error invalid-feedback">
                                Please choose product
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Choose Customer Ship To
                              </label>
                              <AsyncSelect
                                ref={this.selectRef}
                                isClearable={true}
                                styles={customStylesMaterial}
                                single
                                cacheOptions
                                defaultOptions={list_customer_ship}
                                loadOptions={this.listCustomer}
                                onChange={(e) =>
                                  this.handleChange(e, "customer_ship")
                                }
                                value={payload?.customer_ship}
                                thema="info"
                                className="loading"
                                placeholder="Choose..."
                              />
                              <span className="error invalid-feedback">
                                Please choose customer ship to
                              </span>
                            </div>
                          </div>
                        </div>
                        {is_local ? (
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label className="form-label" style={mb3}>
                                  Choose Origin Warehouse
                                </label>
                                <AsyncSelect
                                  ref={this.selectRef}
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  single
                                  cacheOptions
                                  defaultOptions={list_origin}
                                  loadOptions={this.listOrigin}
                                  onChange={(e) =>
                                    this.handleChange(e, "origin")
                                  }
                                  value={payload?.origin}
                                  thema="info"
                                  className="loading"
                                  placeholder="Choose..."
                                />
                                <span className="error invalid-feedback">
                                  Please choose origin
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="form-label" style={mb3}>
                                  Choose City Destination
                                </label>
                                <AsyncSelect
                                  ref={this.selectRef}
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  single
                                  cacheOptions
                                  defaultOptions={list_city}
                                  loadOptions={this.listCity}
                                  onChange={(e) => this.handleChange(e, "city")}
                                  value={payload.city}
                                  thema="info"
                                  className="loading"
                                  placeholder="Choose..."
                                />
                                <span className="error invalid-feedback">
                                  Please choose city
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label className="form-label" style={mb3}>
                                  Choose Port Loading
                                </label>
                                <AsyncSelect
                                  ref={this.selectRef}
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  single
                                  cacheOptions
                                  defaultOptions={list_port_loading}
                                  loadOptions={this.listLoading}
                                  onChange={(e) =>
                                    this.handleChange(e, "port_loading")
                                  }
                                  value={payload?.port_loading}
                                  thema="info"
                                  className="loading"
                                  placeholder="Choose..."
                                />
                                <span className="error invalid-feedback">
                                  Please choose port loading
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label className="form-label" style={mb3}>
                                  Choose Port Destination
                                </label>
                                <AsyncSelect
                                  ref={this.selectRef}
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  single
                                  cacheOptions
                                  defaultOptions={list_port_destination}
                                  loadOptions={this.listDestination}
                                  onChange={(e) =>
                                    this.handleChange(e, "port_destination")
                                  }
                                  value={payload?.port_destination}
                                  thema="info"
                                  className="loading"
                                  placeholder="Choose..."
                                />
                                <span className="error invalid-feedback">
                                  Please choose port destination
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Input KG
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  this.handleChange(e.target.value, "input_kg")
                                }
                                value={payload?.input_kg}
                              />
                              <span className="error invalid-feedback">
                                Please entries kg
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Choose Vehicle Type
                              </label>
                              <AsyncSelect
                                ref={this.selectRef}
                                isClearable={true}
                                styles={customStylesMaterial}
                                single
                                cacheOptions
                                defaultOptions={list_vehicle}
                                loadOptions={this.listType}
                                onChange={(e) =>
                                  this.handleChange(e, "vehicle_type")
                                }
                                value={payload?.vehicle_type}
                                thema="info"
                                className="loading"
                                placeholder="Choose..."
                              />
                              <span className="error invalid-feedback">
                                Please choose type vehicle
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Planning Month
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  selected={payload?.planning_month}
                                  startDate={payload?.planning_month}
                                  disabledKeyboardNavigation
                                  onChange={(e) =>
                                    this.handleChange(e, "planning_month")
                                  }
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control"
                                  required
                                  showMonthYearPicker
                                  autoComplete="off"
                                />
                                <span className="error invalid-feedback">
                                  Please entries planning month
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-label" style={mb3}>
                                Production Month
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  selected={payload?.production_month}
                                  startDate={payload?.production_month}
                                  disabledKeyboardNavigation
                                  onChange={(e) =>
                                    this.handleChange(e, "production_month")
                                  }
                                  name="production_month"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control"
                                  minDate={min_date}
                                  maxDate={max_date}
                                  required
                                  showMonthYearPicker
                                  autoComplete="off"
                                />
                                <span className="error invalid-feedback">
                                  Please entries production month
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="d-flex">
                              <label
                                style={{ marginLeft: "5px", marginTop: "7px" }}
                              >
                                Exchange Rate $1={" Rp. "}
                                {kurs
                                  ? thousandformat(
                                      kurs.toFixed(DECIMAL_PLACES),
                                      ".",
                                      ".",
                                      ","
                                    )
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                name="stock_price"
                                id="stock_price"
                                onChange={(e) => {
                                  const { payload } = this.state;
                                  payload.stock_price = null;
                                  this.setState({
                                    is_check: e.target.checked,
                                    payload: payload,
                                    customer_price: 0,
                                  });
                                }}
                                checked={is_check}
                              />
                              <label
                                style={{ marginLeft: "5px", marginTop: "7px" }}
                              >
                                Stock Point ?
                              </label>
                            </div>
                          </div>
                          {is_check && (
                            <div className="col-3">
                              <div className="form-group">
                                <AsyncSelect
                                  ref={this.selectRef}
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  single
                                  cacheOptions
                                  defaultOptions={list_stock_price}
                                  loadOptions={this.listPrice}
                                  onChange={(e) =>
                                    this.handleChange(e, "stock_price")
                                  }
                                  value={payload?.stock_price}
                                  thema="info"
                                  className="loading"
                                  placeholder="Choose..."
                                />
                                <span className="error invalid-feedback">
                                  Please choose type vehicle
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row mt-2">
                          <div className="col-6">
                            <Button
                              className="col-md-12"
                              onClick={this.handleCustomerPrice}
                              variant="info"
                            >
                              <i className="fas fa-calculator" /> Customer Price
                            </Button>
                          </div>
                          <div className="col-6">
                            <div class="input-group mb-3">
                              <span class="input-group-text">$</span>{" "}
                              <input
                                value={convertToRupiah(customer_price)}
                                type="text"
                                className="form-control"
                                readOnly
                              />
                              <span class="input-group-text">/KG</span>
                            </div>
                          </div>

                          <div className="offset-md-6 col-6">
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th>Lowest Price</th>
                                  <th>Freight Cost</th>
                                  <th>Stock Cost</th>
                                </tr>
                                <tr>
                                  <th>
                                    {lowest_price
                                      ? `$ ${convertToRupiah(lowest_price)}`
                                      : ""}
                                  </th>
                                  <th>
                                    {freight_cost
                                      ? `$ ${convertToRupiah(freight_cost)}`
                                      : ""}
                                  </th>
                                  <th>
                                    {stock_point
                                      ? `$ ${convertToRupiah(stock_point)}`
                                      : ""}
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerPrice);
