import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialService {
  readCustomer() {
    return axios.get(API_URL_DEFAULT + "customer?sizePerPage=100000", {
      headers: authHeader(),
    });
  }
  readCustomerAsync(query) {
    return axios.get(API_URL_DEFAULT + "customer/", {
      headers: authHeader(),
      params: query,
    });
  }
}

export default new MaterialService();
