import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "customer-price";

class CustomerPriceService {
  getSegment(payload) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/get-segment?" + payload, {
      headers: authHeader(),
    });
  }
  getCustomer(payload) {
    return axios.get(
      API_URL_DEFAULT + API_MODULE + "/get-customer?" + payload,
      {
        headers: authHeader(),
      }
    );
  }
  getFreight(payload) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/get-freight?" + payload, {
      headers: authHeader(),
    });
  }
  getMaterial(payload) {
    return axios.get(
      API_URL_DEFAULT + API_MODULE + "/get-material?" + payload,
      {
        headers: authHeader(),
      }
    );
  }
  getVehicle(payload) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/get-vehicle?" + payload, {
      headers: authHeader(),
    });
  }
  getStockPrice(payload) {
    return axios.get(
      API_URL_DEFAULT + API_MODULE + "/get-stock-price?" + payload,
      {
        headers: authHeader(),
      }
    );
  }
  calculatePrice(payload) {
    return axios.post(
      API_URL_DEFAULT + API_MODULE + "/calculate-price",
      payload,
      {
        headers: authHeader(),
      }
    );
  }
}
export default new CustomerPriceService();
