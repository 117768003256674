import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HorizontalTimeline from "react-horizontal-timeline";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment/moment";
import DashboardTimelineModule from "../../modules/rio/DashboardTimelineModule";

const VALUES = [
  {
    label: "SALESFORECAST",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-01`,
  },
  {
    label: "MRP",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-02`,
  },
  {
    label: "POWDER RND PROCESS",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-03`,
  },
  {
    label: "PO CONTRACT",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-04`,
  },
  {
    label: "MAP",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-05`,
  },
  {
    label: "POWDER PRICE",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-06`,
  },
  {
    label: "PRICE LIST",
    value: `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
      "MM"
    )}-07`,
  },
];

const VALUES_INDEX = {
  salesforecast: 0,
  mrp: 1,
  powder_rnd_process: 2,
  po_contract: 3,
  map: 4,
  powder_price: 5,
  price_list: 6,
};

export default class DashaboardHorizontalTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      selectedDate: new Date(),
      loading: false,
      timeline: {
        value: 0,
        previous: 0,
      },
      data: [],
    };
  }

  renderMonthContent(val) {
    this.setState({
      selectedDate: val,
    });
  }

  clearDateValues() {
    return VALUES.forEach((item) => delete item.date);
  }

  fetchTimelineData(date) {
    this.setState({ loading: true });
    const params = {
      date: moment(date).format("YYYY-MM"),
    };
    DashboardTimelineModule.getData(new URLSearchParams(params))
      .then((res) => {
        this.clearDateValues();
        this.setState({
          timeline: {
            value: 0,
            previous: 0,
          },
        });
        Object.keys(res.foundData).forEach((item, index) => {
          if (res.foundData[item]) {
            this.setState({
              timeline: {
                value: VALUES_INDEX[item],
                previous: 0,
              },
            });
            const labelText = item.split("_").join(" ").toUpperCase();
            const VAL_INDEX = VALUES.findIndex(
              (item) => item.label === labelText
            );
            VALUES[VAL_INDEX].date = moment(
              res.foundData[item]?.created_at
            ).format("YYYY-MM-DD HH:MM");
            this.setState({
              data: VALUES,
            });
          }
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({
        currentUser: user,
      });
    }
    this.fetchTimelineData(this.state.selectedDate);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDate !== this.state.selectedDate) {
      this.fetchTimelineData(this.state.selectedDate);
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="card card-info">
              <div className="card-header ">
                <h3 className="card-title">Timeline Input</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <label for="planning-month-select">Planning Month</label>
                    <DatePicker
                      id="planning-month-select"
                      selected={this.state.selectedDate}
                      onChange={this.renderMonthContent.bind(this)}
                      className="w-100 form-control"
                      placeholderText={"Planning Month"}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                    />
                  </div>
                </div>
                <div className="row mt-10 py-10">
                  <div
                    className="col-12 mt-5"
                    style={{
                      width: "98%",
                      height: "150px",
                      margin: "0 auto",
                    }}
                  >
                    {VALUES.length > 0 && !this.state.loading ? (
                      <HorizontalTimeline
                        isKeyboardEnabled={false}
                        isTouchEnabled={false}
                        labelWidth={210}
                        maxEventPadding={5}
                        linePadding={50}
                        getLabel={function (date) {
                          if (VALUES.some((item) => item.value === date)) {
                            const labelIndex = VALUES.findIndex(
                              (item) => item.value === date
                            );
                            const label = VALUES[labelIndex];
                            if (label.date) {
                              return (
                                <span style={{ fontSize: "11px" }}>
                                  {`${label.label}`}
                                  <br /> {`${label.date}`}
                                </span>
                              );
                            }
                            return (
                              <span style={{ fontSize: "11px" }}>
                                {label.label}
                              </span>
                            );
                          } else {
                            return "Null";
                          }
                        }}
                        index={this.state.timeline.value}
                        indexClick={(index) => {
                          this.setState({
                            value: index,
                            previous: this.state.timeline.value,
                          });
                        }}
                        values={VALUES.map((item) => item.value)}
                      />
                    ) : (
                      <div className="d-flex align-self-center">Loading...</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
