import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import FileDownload from "js-file-download";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { Link, withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Pagination from "react-bootstrap/Pagination";
import {
  read,
  create,
  update,
  download,
} from "../../modules/sodiq/StandardCbmModule";
import Footer from "../../components/templates/Footer";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";

class VehicleTonase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      search: null,
      list_datatable: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "material_no",
      sortOrder: "asc",
      count_data: null,
      current_page: 1,
      columns: [
        {
          dataField: "material_no",
          text: "Material No",
          sort: true,
        },
        {
          dataField: "material_desc",
          text: "Material Desc",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "standard_max",
          text: "Standard Max",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "qty",
          text: "Quantity",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "net_weight",
          text: "Net Weight",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "gross_weight",
          text: "Gross Weight",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "length",
          text: "p (mm)",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "width",
          text: "l (mm)",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "height",
          text: "h (mm)",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "volume",
          text: "m3",
          classes: "text-center",
          sort: true,
        },
      ],
      defaultSorted: [
        {
          dataField: "material_no",
          order: "asc",
        },
      ],
      type_action: null,
      data_detail: null,
      current_filename: "Please choose file...",
      file_value: {},
      file_content: {},
      file_worksheet: {},
      prev_temp_worksheet: [],
      prev_list_worksheet: [],
      prev_curr_page: 1,
      prev_per_page: 10,
      payload: {},
    };
  }

  fetchData = (isReset) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, page, sizePerPage, search } = this.state;
    let params = {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
    };
    if (search && !isReset) {
      params.search = search;
      params.page = 1;
    }

    if (isReset) {
      this.setState({ search: null });
      document.getElementById("search_val").value = "";
      params.page = 1;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
      });
    });
  };

  //when component already mount (lifecycle react)
  componentDidMount() {
    this.fetchData(false);
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      date_create: new Date(),
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  toOpen = (type, datas) => {
    const { payload } = this.state;
    if (type === "edit") {
      payload._id = datas?._id;
      payload.material_no = datas?.material_no;
      payload.material_desc = datas?.material_desc;
      payload.standard_max = datas?.standard_max;
      payload.qty = datas?.qty;
      payload.price_pcs = datas?.price_pcs;
      payload.price_card = datas?.price_card;
      payload.net_weight = datas?.net_weight;
      payload.gross_weight = datas?.gross_weight;
      payload.length = datas?.length;
      payload.width = datas?.width;
      payload.height = datas?.height;
      payload.volume = datas?.volume;
    }
    this.setState(
      {
        type_action: type,
        payload: payload,
        prev_list_worksheet: [],
        prev_temp_worksheet: [],
        file_value: {},
        file_content: {},
        file_worksheet: {},
        prev_curr_page: 1,
        prev_per_page: 10,
        current_filename: "Please choose file...",
      },
      () => {
        this.toggle();
      }
    );
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  onModalHide = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleSubmit = () => {
    const { file_value, prev_list_worksheet } = this.state;
    if (file_value && prev_list_worksheet.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          create(prev_list_worksheet)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", "Please import data...", "error");
    }
  };

  handleUpdate = () => {
    const { payload } = this.state;
    if (payload) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          update(payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", "Please check your entries...", "error");
    }
  };

  handleDelete = (datas) => {
    if (datas._id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You wont be able to revert this !",
        icon: "info",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete it!`,
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          const payload = {
            _id: datas._id,
            is_active: false,
          };
          this.setState({ loading: true });
          update(payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", "Please check your entries...", "error");
    }
  };

  handleFile = (e) => {
    if (e.target.files.length > 0) {
      const fileObject = e.target.files[0];
      this.handleReadFile(fileObject)
        .then((response) => {
          if (response) {
            let temp = response[0]?.temp_data;
            let detail = response[0]?.detail_data;
            this.setState({
              load_file: true,
              file_value: fileObject,
              file_worksheet: response[0],
              file_content: {
                name: fileObject.name,
                type: fileObject.type,
                size: fileObject.size,
              },
              current_filename: fileObject.name,
              prev_list_worksheet: detail,
              prev_temp_worksheet: temp.splice(
                0,
                temp.length < 10 ? temp.length : 10
              ),
              prev_curr_page: 1,
              prev_per_page: 10,
            });
          }
        })
        .catch((e) => {
          this.setState({
            load_file: false,
            file_value: null,
            file_worksheet: [],
            file: {
              name: "Please choose file...",
              type: "",
              size: 0,
            },
            current_filename: "Please choose file...",
          });
        })
        .finally(() => {
          this.setState({ errors_create_bulk: {} });
        });
    }
  };

  handleReadFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const binaryString = e.target.result;
          const workbook = XLSX.read(binaryString, { type: "buffer" });

          const result = workbook.SheetNames.map((item) => {
            const worksheet = workbook.Sheets[item];
            const datas = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              defval: "",
              blankrows: false,
            });
            const list = [];
            const temp = [];
            const item_list = datas.slice(2, datas.length);
            if (item_list.length > 0) {
              item_list.map((el) => {
                const obj = {
                  material_no: el[0],
                  material_desc: el[1],
                  standard_max: el[2],
                  qty: el[3],
                  price_pcs: el[4],
                  price_card: el[5],
                  net_weight: el[6],
                  gross_weight: el[7],
                  length: el[8],
                  width: el[9],
                  height: el[10],
                  volume: el[11],
                };
                list.push(obj);
                temp.push(obj);
                return el;
              });
            }
            return {
              sheetName: item,
              worksheet: item_list,
              detail_data: list,
              temp_data: temp,
            };
          });
          resolve(result);
        };
      } catch (e) {
        reject(new Error(e.message));
      }
    });
  };

  changePagiantion = (type) => {
    const { prev_curr_page, prev_per_page, prev_list_worksheet } = this.state;
    const list = [...prev_list_worksheet];

    if (type === "next") {
      let next = prev_curr_page + 1;
      const start = (next - 1) * prev_per_page + 1;
      const end =
        prev_per_page * next < prev_list_worksheet?.length
          ? prev_per_page * next
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: next,
      });
    }
    if (type === "prev") {
      let prev = prev_curr_page - 1;
      const start = (prev - 1) * prev_per_page + 1;
      const end =
        prev_per_page * prev < prev_list_worksheet?.length
          ? prev_per_page * prev
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: prev,
      });
    }
  };

  changePagiantionHeader = (type, sizeVal = 10) => {
    const { page, sizePerPage } = this.state;
    let currPage = page;
    let currSize = sizePerPage;

    if (type === "next") {
      currPage = page + 1;
    }
    if (type === "prev") {
      currPage = page - 1;
    }
    if (type === "size") {
      currPage = 1;
      currSize = sizeVal.target.value;
    }

    this.setState({ loading: true });
    const { sortBy, sortOrder, search } = this.state;
    let params = {
      page: currPage,
      sizePerPage: currSize,
      sortBy,
      sortOrder,
    };
    if (search) {
      params.search = search;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
        sizePerPage: currSize,
      });
    });
  };

  handleDownloadTemplate = async () => {
    try {
      const result = await download();
      const fileName = `Standard-CBM.xlsx`;
      FileDownload(result, fileName);
    } catch (e) {
      Swal.fire("Warning", e, "error");
    }
  };

  render() {
    let {
      columns,
      showModal,
      list_datatable,
      loading,
      isRead,
      isEdit,
      isDelete,
      page,
      sizePerPage,
      totalSize,
      current_filename,
      type_action,
      prev_temp_worksheet,
      prev_list_worksheet,
      prev_curr_page,
      prev_per_page,
      payload,
      search,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size={type_action === "edit" ? "md" : "xl"}
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                {type_action === "edit" ? "Update" : "Import Excel"} Standard
                CBM
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {type_action === "edit" ? (
                <Container>
                  {columns
                    .filter((e) => e.text !== "Action")
                    .map((el, i) => (
                      <div className={`row ${i > 0 ? "mt-3" : ""}`}>
                        <div className="col-12">
                          <label>{el.text}</label>
                          <input
                            className="form-control"
                            value={payload[el.dataField]}
                            onChange={(e) => {
                              const { payload } = this.state;
                              payload[el.dataField] = e.target.value;
                              this.setState({ payload: payload });
                            }}
                            readOnly={i === 0}
                          />
                        </div>
                      </div>
                    ))}
                </Container>
              ) : (
                <Container>
                  <Row>
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          style={{ marginBottom: "3px" }}
                        >
                          Import Excel <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <div
                            className="custom-file"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={this.handleFile}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {current_filename}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12">Preview Data</div>
                  </Row>
                  <Row className="mt-1">
                    <div className="col-12">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                              Material No
                            </th>
                            <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                              Material Desc
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Standard Max. <br />
                              Stack in Truck
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Quantity
                              <br />
                              /Carton
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Net Weight
                              <br />
                              /Carton
                            </th>
                            <th
                              rowSpan={2}
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              Gross Weight
                              <br />
                              /Carton
                            </th>
                            <th colSpan={4} className="text-center">
                              Carton Dimension
                            </th>
                          </tr>
                          <tr>
                            <th className="text-center">p (mm)</th>
                            <th className="text-center">l (mm)</th>
                            <th className="text-center">t (mm)</th>
                            <th className="text-center">m3</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prev_temp_worksheet &&
                            prev_temp_worksheet?.length > 0 &&
                            prev_temp_worksheet.map((item) => (
                              <tr>
                                <td>{item?.material_no}</td>
                                <td>{item?.material_desc}</td>
                                <td className="text-center">
                                  {item?.standard_max}
                                </td>
                                <td className="text-center">{item?.qty}</td>
                                <td className="text-center">
                                  {item?.net_weight}
                                </td>
                                <td className="text-center">
                                  {item?.gross_weight}
                                </td>
                                <td className="text-center">{item?.length}</td>
                                <td className="text-center">{item?.width}</td>
                                <td className="text-center">{item?.height}</td>
                                <td className="text-center">{item?.volume}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Row>
                  {prev_temp_worksheet && prev_temp_worksheet?.length > 0 && (
                    <Row>
                      <div className="col-12">
                        <div className="d-flex float-right">
                          <span
                            style={{
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            Showing {(prev_curr_page - 1) * prev_per_page + 1}-
                            {prev_per_page * prev_curr_page <
                            prev_list_worksheet?.length
                              ? prev_per_page * prev_curr_page
                              : prev_list_worksheet?.length}{" "}
                            of {prev_list_worksheet?.length}
                          </span>
                          <Pagination>
                            <Pagination.Prev
                              disabled={prev_curr_page === 1}
                              onClick={() => this.changePagiantion("prev")}
                            />
                            <Pagination.Next
                              disabled={
                                prev_per_page * prev_curr_page >
                                prev_list_worksheet?.length
                              }
                              onClick={() => this.changePagiantion("next")}
                            />
                          </Pagination>
                        </div>
                      </div>
                    </Row>
                  )}
                </Container>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="default"
                onClick={this.onModalHide}
                className="float-left"
              >
                <i className="fas fa-times" /> Close
              </Button>
              {type_action === "edit" ? (
                <Button
                  variant="success"
                  onClick={this.handleUpdate}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Update
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={this.handleSubmit}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              )}
            </ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Standard CBM</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">standard cbm</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Standard CBM</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <Row>
                            <div className="col-3">
                              <div className="form-group">
                                <input
                                  id="search_val"
                                  placeholder="Search"
                                  value={search}
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({ search: e.target.value });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <Button
                                  variant="info"
                                  onClick={() => this.fetchData(false)}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                                <Button
                                  style={{ marginLeft: "2px" }}
                                  variant="warning"
                                  onClick={() => this.fetchData(true)}
                                  className="float-left"
                                >
                                  <i className="fas fa-history" /> Reset
                                </Button>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="form-group">
                                <Button
                                  variant="success"
                                  onClick={() => {
                                    this.toOpen("add", {});
                                  }}
                                  className="float-right"
                                >
                                  <i className="fas fa-upload" /> Import Excel
                                </Button>
                                <Button
                                  style={{ marginRight: "5px" }}
                                  variant="info"
                                  onClick={() => {
                                    this.handleDownloadTemplate();
                                  }}
                                  className="float-right"
                                >
                                  <i className="fas fa-download" /> Download
                                  Template
                                </Button>
                              </div>
                            </div>
                          </Row>
                          <br />
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th
                                  rowSpan={2}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Material No
                                </th>
                                <th
                                  rowSpan={2}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Material Desc
                                </th>
                                <th
                                  rowSpan={2}
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Standard Max. <br />
                                  Stack in Truck
                                </th>
                                <th
                                  rowSpan={2}
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Quantity
                                  <br />
                                  /Carton
                                </th>
                                <th
                                  rowSpan={2}
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Net Weight
                                  <br />
                                  /Carton
                                </th>
                                <th
                                  rowSpan={2}
                                  className="text-center"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Gross Weight
                                  <br />
                                  /Carton
                                </th>
                                <th colSpan={4} className="text-center">
                                  Carton Dimension
                                </th>
                                <th
                                  rowSpan={2}
                                  style={{ verticalAlign: "middle" }}
                                  className="text-center"
                                >
                                  Action
                                </th>
                              </tr>
                              <tr>
                                <th className="text-center">p (mm)</th>
                                <th className="text-center">l (mm)</th>
                                <th className="text-center">t (mm)</th>
                                <th className="text-center">m3</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list_datatable &&
                                list_datatable?.length > 0 &&
                                list_datatable.map((item) => (
                                  <tr>
                                    <td>{item?.material_no}</td>
                                    <td>{item?.material_desc}</td>
                                    <td className="text-center">
                                      {item?.standard_max}
                                    </td>
                                    <td className="text-center">{item?.qty}</td>
                                    <td className="text-center">
                                      {item?.net_weight}
                                    </td>
                                    <td className="text-center">
                                      {item?.gross_weight}
                                    </td>
                                    <td className="text-center">
                                      {item?.length}
                                    </td>
                                    <td className="text-center">
                                      {item?.width}
                                    </td>
                                    <td className="text-center">
                                      {item?.height}
                                    </td>
                                    <td className="text-center">
                                      {item?.volume}
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex text-center">
                                        {isEdit ? (
                                          <div>
                                            <button
                                              key={item._id}
                                              type="button"
                                              className="btn btn-info btn-sm ml-2"
                                              size="sm"
                                              onClick={() => {
                                                this.toOpen("edit", item);
                                              }}
                                            >
                                              <i
                                                className="fas fa-edit"
                                                key={item._id}
                                              ></i>
                                              &nbsp;Edit
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {isDelete ? (
                                          <div>
                                            <button
                                              key={item._id}
                                              type="button"
                                              className="btn btn-danger btn-sm ml-2"
                                              size="sm"
                                              onClick={() => {
                                                this.handleDelete(item);
                                              }}
                                            >
                                              <i
                                                className="fas fa-trash"
                                                key={item._id}
                                              ></i>
                                              &nbsp;Delete
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {list_datatable && list_datatable?.length > 0 && (
                            <Row className="mt-3">
                              <div className="col-12">
                                <div className="d-flex float-right">
                                  <select
                                    className="form-control form-control-sm"
                                    style={{
                                      width: "65px",
                                      marginRight: "10px",
                                      marginTop: "5px",
                                    }}
                                    onChange={(e) =>
                                      this.changePagiantionHeader("size", e)
                                    }
                                  >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                  </select>
                                  <span
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Showing {(page - 1) * sizePerPage + 1}-
                                    {page * sizePerPage < totalSize
                                      ? page * sizePerPage
                                      : totalSize}{" "}
                                    of {totalSize}
                                  </span>
                                  <Pagination>
                                    <Pagination.Prev
                                      disabled={page === 1}
                                      onClick={() =>
                                        this.changePagiantionHeader("prev")
                                      }
                                    />
                                    <Pagination.Next
                                      disabled={page * sizePerPage > totalSize}
                                      onClick={() =>
                                        this.changePagiantionHeader("next")
                                      }
                                    />
                                  </Pagination>
                                </div>
                              </div>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(VehicleTonase);
