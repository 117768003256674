import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Select from "react-select";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import MATERIALService from "../services/material.service";

const AddMore = ({
  data, //data that is inputted by user in each row
  columns, //column from the parent, each type of PR has its own column
  toll_out,
  onChange, //to return data to parrent
  readOnly,
}) => {

  let refUnit = null;
  //state to do some validation for the Select type
  const headerStyle = { backgroundColor: '#17A2B8', color: 'white' };

  const handleTableRowAdd = () => {

    // make new object based on the data sent and empty it
    let data_template = { ...data[0] }
    Object.keys(data_template).forEach(key => data_template[key] = "")

    data.push(data_template)
    onChange(data)
  }

  const handleTableRowRemove = () => {
    if (data.length > 1) {

      data.pop();
      onChange(data)
    }
  }

  const handleSelectChange = (val, type, idx) => {
    data[idx] = val.value
    onChange(data)
  }

  return (
    <Container>
      <Table bordered hover size="sm" style={{ textAlign: 'center', justifyContent: 'center' }}>
        <thead>
          <tr >
            <th style={Object.assign(headerStyle, { width: '5%' })}> No. </th>
            {columns.map(item => (<th style={headerStyle}>{item.text} </th>))}
          </tr>
        </thead>
        <tbody>
          {data.map((itemData, idxData) => (
            <tr>
              <td> {idxData + 1} </td>
              {columns.map((item) => {
                if (item.type === 'toll_out') {
                  return (
                    <td className="col-sm-3">
                      <Select
                        onChange={(val) => handleSelectChange(val, 'toll_out', idxData)}
                        options={toll_out}
                        value={toll_out.filter(
                          (e) => JSON.stringify(e.value) === JSON.stringify(itemData)
                        ) || ''}
                        isDisabled={readOnly}
                      />
                    </td>
                  )
                }
              }
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      {
        !readOnly && (
          <div>
            <i
              className="fas fa-plus"
              style={{ backgroundColor: 'green', color: 'white', padding: 5, fontWeight: 'bold', borderRadius: 3 }}
              onClick={() => handleTableRowAdd()}
            />

            <i
              className="fas fa-minus"
              style={{ backgroundColor: 'red', color: 'white', padding: 5, fontWeight: 'bold', borderRadius: 3 }}
              onClick={() => handleTableRowRemove()}
            />
          </div>
        )
      }
    </Container >
  )
};

export default AddMore;
