import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE_MAP = "map_rnd";
const API_MODULE_FINAL = "simulator/rnd";

class RNDSimulatorService {
    list_material(query) {
        return axios.get(API_URL_DEFAULT + API_MODULE_MAP + "/list?" + query, {
            headers: authHeader(),
        });
    }
    simulateRND(payload) {
        return axios.post(API_URL_DEFAULT + API_MODULE_FINAL, payload, {
            headers: authHeader(),
        });
    }
}
export default new RNDSimulatorService();
