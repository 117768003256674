import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class CheckNeedPOService {
  read(query) {
    return axios.get(API_URL_DEFAULT + `check_need_po?${query}`, {
      headers: authHeader(),
    });
  }
  readMaterial(query) {
    return axios.get(API_URL_DEFAULT + `check_need_po/material/?${query}`, {
      headers: authHeader(),
    });
  }
  readOutstanding(query) {
    return axios.get(API_URL_DEFAULT + `check_need_po/outstanding/?${query}`, {
      headers: authHeader(),
    });
  }
}

export default new CheckNeedPOService();
