import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "standard-cbm";

class VehicleTonaseService {
  read(query) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/?" + query, {
      headers: authHeader(),
    });
  }
  create(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE, payload, {
      headers: authHeader(),
    });
  }
  update(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE + "/update", payload, {
      headers: authHeader(),
    });
  }
  download() {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/download", {
      headers: authHeader(),
      responseType: "blob",
    });
  }
}
export default new VehicleTonaseService();
