import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BOMFGService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "bom_fg", payload, {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "bom_fg", payload, {
      headers: authHeader(),
    });
  }
  readBOMFG(query_string) {
    return axios.get(API_URL_DEFAULT + "bom_fg/?" + query_string, {
      headers: authHeader(),
    });
  }
  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "bom_fg", {
      data: payload,
      headers: authHeader(),
    });
  }
  readBOMFGSpec(query_string) {
    return axios.get(API_URL_DEFAULT + "bom_fg/spec/?" + query_string, {
      headers: authHeader(),
    });
  }
  readBOMFGSpecPlant(query_string) {
    return axios.get(API_URL_DEFAULT + "bom_fg/spec-plant/?" + query_string, {
      headers: authHeader(),
    });
  }
}

export default new BOMFGService();
