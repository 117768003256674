import React, { Component, useMemo, useState } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import FileDownload from "js-file-download";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalTitle,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import {
  createCIP,
  createBulkCIP,
  readCIP,
  updateCIP,
  generateExcel,
} from "../modules/ApsCipModule";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { readProdFacility } from "../modules/ProdFacilityModule";
import DataTable from "react-data-table-component";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Swal from "sweetalert2";
import { readMaterialSelect } from "../modules/MaterialModule";
import WorksheetCustom from "../components/WorksheetCustom";

const LIST_TIME = [
  { value: "00:00", label: "00:00", detail: { duration: 0, uom: "minute" } },
  { value: "00:30", label: "00:30", detail: { duration: 30, uom: "minute" } },
  { value: "01:00", label: "01:00", detail: { duration: 60, uom: "minute" } },
  { value: "01:30", label: "01:30", detail: { duration: 90, uom: "minute" } },
  { value: "02:00", label: "02:00", detail: { duration: 120, uom: "minute" } },
  { value: "02:30", label: "02:30", detail: { duration: 150, uom: "minute" } },
  { value: "03:00", label: "03:00", detail: { duration: 180, uom: "minute" } },
  { value: "03:30", label: "03:30", detail: { duration: 210, uom: "minute" } },
  { value: "04:00", label: "04:00", detail: { duration: 240, uom: "minute" } },
  { value: "04:30", label: "04:30", detail: { duration: 270, uom: "minute" } },
  { value: "05:00", label: "05:00", detail: { duration: 300, uom: "minute" } },
  { value: "05:30", label: "05:30", detail: { duration: 330, uom: "minute" } },
  { value: "06:00", label: "06:00", detail: { duration: 360, uom: "minute" } },
  { value: "06:30", label: "06:30", detail: { duration: 390, uom: "minute" } },
  { value: "07:00", label: "07:00", detail: { duration: 420, uom: "minute" } },
  { value: "07:30", label: "07:30", detail: { duration: 450, uom: "minute" } },
  { value: "08:00", label: "08:00", detail: { duration: 480, uom: "minute" } },
  { value: "08:30", label: "08:30", detail: { duration: 510, uom: "minute" } },
  { value: "09:00", label: "09:00", detail: { duration: 540, uom: "minute" } },
  { value: "09:30", label: "09:30", detail: { duration: 570, uom: "minute" } },
  { value: "10:00", label: "10:00", detail: { duration: 600, uom: "minute" } },
  { value: "10:30", label: "10:30", detail: { duration: 630, uom: "minute" } },
  { value: "11:00", label: "11:00", detail: { duration: 660, uom: "minute" } },
  { value: "11:30", label: "11:30", detail: { duration: 690, uom: "minute" } },
  { value: "12:00", label: "12:00", detail: { duration: 720, uom: "minute" } },
  { value: "12:30", label: "12:30", detail: { duration: 750, uom: "minute" } },
  { value: "13:00", label: "13:00", detail: { duration: 780, uom: "minute" } },
  { value: "13:30", label: "13:30", detail: { duration: 810, uom: "minute" } },
  { value: "14:00", label: "14:00", detail: { duration: 840, uom: "minute" } },
  { value: "14:30", label: "14:30", detail: { duration: 870, uom: "minute" } },
  { value: "15:00", label: "15:00", detail: { duration: 900, uom: "minute" } },
  { value: "15:30", label: "15:30", detail: { duration: 930, uom: "minute" } },
  { value: "16:00", label: "16:00", detail: { duration: 960, uom: "minute" } },
  { value: "16:30", label: "16:30", detail: { duration: 990, uom: "minute" } },
  { value: "17:00", label: "17:00", detail: { duration: 1020, uom: "minute" } },
  { value: "17:30", label: "17:30", detail: { duration: 1050, uom: "minute" } },
  { value: "18:00", label: "18:00", detail: { duration: 1080, uom: "minute" } },
  { value: "18:30", label: "18:30", detail: { duration: 1110, uom: "minute" } },
  { value: "19:00", label: "19:00", detail: { duration: 1140, uom: "minute" } },
  { value: "19:30", label: "19:30", detail: { duration: 1170, uom: "minute" } },
  { value: "20:00", label: "20:00", detail: { duration: 1200, uom: "minute" } },
  { value: "20:30", label: "20:30", detail: { duration: 1230, uom: "minute" } },
  { value: "21:00", label: "21:00", detail: { duration: 1260, uom: "minute" } },
  { value: "21:30", label: "21:30", detail: { duration: 1290, uom: "minute" } },
  { value: "22:00", label: "22:00", detail: { duration: 1320, uom: "minute" } },
  { value: "22:30", label: "22:30", detail: { duration: 1350, uom: "minute" } },
  { value: "23:00", label: "23:00", detail: { duration: 1380, uom: "minute" } },
  { value: "23:30", label: "23:30", detail: { duration: 1410, uom: "minute" } },
  { value: "24:00", label: "24:00", detail: { duration: 1440, uom: "minute" } },
];

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

const handleSubmit = (values) => {
  const newResponse = new Promise((resolve, reject) => {
    try {
      createCIP(values)
        .then(() => {
          Swal.fire(
            "Information",
            "Data has been saved successfuly!",
            "success"
          );

          resolve({ error: false, message: "Insert Successfuly" });
        })
        .catch((err) => {
          Swal.fire("Information", err, "error");
          reject({ error: true, message: "err" });
        });
    } catch (e) {
      reject(new Error(e.message));
    }
  });
  return newResponse;
};

const handleSubmitBulk = (values) => {
  const newResponse = new Promise((resolve, reject) => {
    try {
      createBulkCIP(values)
        .then(() => {
          Swal.fire(
            "Information",
            "Data has been saved successfuly!",
            "success"
          );

          resolve({ error: false, message: "Insert Bulk Successfuly" });
        })
        .catch((err) => {
          Swal.fire("Information", err, "error");
          reject({ error: true, message: "err" });
        });
    } catch (e) {
      reject(new Error(e.message));
    }
  });
  return newResponse;
};

const handleUpdate = (values) => {
  const newResponse = new Promise((resolve, reject) => {
    try {
      updateCIP(values)
        .then(() => {
          Swal.fire(
            "Information",
            "Data has been updated successfuly!",
            "success"
          );

          resolve({ error: false, message: "Delete Successfuly" });
        })
        .catch((err) => {
          Swal.fire("Information", err, "error");
          reject({ error: true, message: "err" });
        });
    } catch (e) {
      reject(new Error(e.message));
    }
  });
  return newResponse;
};

const FormCustom = ({
  initialValues,
  isUpdate,
  isReadOnly,
  list_product_facility,
  loading_product_facility,
  value_select_material_before,
  value_select_product_facility,
  value_data_rules,
  handleCustomSubmit,
}) => {
  const [data_rules, setDataRules] = useState(value_data_rules);
  const [errors, setErrors] = useState({});
  const [select_material_before, setMaterialBefore] = useState(
    value_select_material_before
  );
  const [select_product_facility, setProductFacility] = useState(
    value_select_product_facility
  );

  const handleCustomAddRules = () => {
    const new_data_rules = [...data_rules];
    new_data_rules.push({ material: null, duration: LIST_TIME[0] });
    setDataRules(new_data_rules);

    const new_errors = { ...errors };
    delete new_errors.data_rules;

    setErrors(new_errors);
  };

  const handleCustomRemoveRules = (index) => {
    const new_data_rules = [...data_rules];
    new_data_rules.splice(index, 1);
    setDataRules(new_data_rules);
  };

  const onChangeMaterialBefore = (e) => {
    setMaterialBefore(e);

    const new_errors = { ...errors };
    delete new_errors.select_material_before;

    setErrors(new_errors);
  };

  const onChangeProductFacility = (e) => {
    setProductFacility(e);

    const new_errors = { ...errors };
    delete new_errors.select_product_facility;

    setErrors(new_errors);
  };

  const onChangeMaterial = (e, index) => {
    const new_data_rules = [...data_rules];

    new_data_rules[index] = {
      ...new_data_rules[index],
      material: e,
    };

    setDataRules(new_data_rules);

    const new_errors = { ...errors };
    delete new_errors.data_rules;

    setErrors(new_errors);
  };

  const onChangeDuration = (e, index) => {
    const new_data_rules = [...data_rules];

    new_data_rules[index] = {
      ...new_data_rules[index],
      duration: e,
    };

    setDataRules(new_data_rules);

    const new_errors = { ...errors };
    delete new_errors.data_rules;

    setErrors(new_errors);
  };

  const validateForm = (values) => {
    const new_errors = {};

    if (!values.before_material_code) {
      new_errors.select_material_before = "Field is Required";
    }
    if (!values.prod_facility_code) {
      new_errors.select_product_facility = "Field is Required";
    }
    if (values?.after_material?.length === 0) {
      new_errors.data_rules = "Please define your rules";
    } else {
      const find = values.after_material.findIndex(
        (item) => item.material_code === null
      );
      if (find !== -1) {
        new_errors.data_rules = "Please complete your rules";
      }
    }

    setErrors(new_errors);

    return { error: Object.keys(new_errors).length !== 0, message: new_errors };
  };

  const onCustomSubmit = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const new_rules = data_rules.map((item) => {
        const material_detail = { ...item.material?.detail };
        return {
          material_code: material_detail?.before_material_code ?? null,
          material_description:
            material_detail?.before_material_description ?? null,
          ...item.duration?.detail,
        };
      });
      const new_values = {
        ...initialValues,
        ...select_material_before?.detail,
        ...select_product_facility?.detail,
        after_material: new_rules,
        requester: user?.username,
      };

      const validate = validateForm(new_values);
      if (validate.error) {
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          handleCustomSubmit(new_values, onCustomClear);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } catch (err) {
      Swal.fire("Information ", "Please check your entries again!", "error");
    } finally {
    }
  };

  const onCustomClear = () => {
    setDataRules([]);
    setErrors({});
    setMaterialBefore(null);
    setProductFacility(null);
  };

  const filterPromiseOptions = async (inputValue) => {
    const query = {
      search: inputValue,
      material_type: "ZFGD",
      sortOrder: "asc",
    };
    return readMaterialSelect(query).then((response) => {
      return response;
    });
  };

  const loadPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (isReadOnly) {
        resolve([]);
      } else {
        setTimeout(() => {
          resolve(filterPromiseOptions(inputValue));
        }, 1000);
      }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className={isUpdate ? "col-12 pt-3" : "col-sm-12 col-md-6 pt-3"}>
          <div className="form-group">
            <label htmlFor="materialBefore">Material Before</label>
            <AsyncSelect
              defaultOptions
              cacheOptions
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                singleValue: (base) => ({ ...base, color: "black" }),
              }}
              loadOptions={loadPromiseOptions}
              value={select_material_before}
              defaultValue={select_material_before}
              onChange={onChangeMaterialBefore}
              isDisabled={isUpdate}
            />

            {errors?.select_material_before && (
              <div
                style={{
                  width: "100%",
                  marginTop: ".25rem",
                  fontSize: "80%",
                  color: " #dc3545",
                }}
              >
                {errors?.select_material_before}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="materialBefore">Product Facility</label>
            <Select
              options={list_product_facility}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                singleValue: (base) => ({ ...base, color: "black" }),
              }}
              isLoading={loading_product_facility}
              isDisabled={
                loading_product_facility ||
                list_product_facility.length === 0 ||
                isReadOnly
              }
              placeholder={
                loading_product_facility
                  ? "Loading..."
                  : list_product_facility.length > 0
                  ? "Select..."
                  : "Not Found"
              }
              value={select_product_facility}
              defaultValue={select_product_facility}
              onChange={onChangeProductFacility}
            />

            {errors?.select_product_facility && (
              <div
                style={{
                  width: "100%",
                  marginTop: ".25rem",
                  fontSize: "80%",
                  color: " #dc3545",
                }}
              >
                {errors?.select_product_facility}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="rules">Rules</label>
            {!isReadOnly && (
              <Button
                variant="outline-success"
                className="mr-2 mb-2 float-right"
                onClick={handleCustomAddRules}
              >
                <i className="fa fa-plus" />
              </Button>
            )}
          </div>
          <div>
            <div
              className="overflow-auto"
              style={{
                maxHeight: "240px",
                fontSize: "0.9rem",
              }}
            >
              <Table bordered hover size="sm" style={{ textAlign: "center" }}>
                <thead key={"header"}>
                  <tr key={"h0"} className="bg-info">
                    <th key={"h1"} className="col-sm-1">
                      No
                    </th>
                    <th key={"h2"} className="col-sm-8">
                      Material After
                    </th>
                    <th key={"h3"} className="col-sm-2">
                      Duration
                    </th>
                    {!isReadOnly && (
                      <th key={"h4"} className="col-sm-1">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody key={"body"} align="center">
                  {data_rules.map((item, index) => (
                    <tr key={`b0-${index}`}>
                      <td className="col-sm-1 align-middle" key={`b1-${index}`}>
                        {index + 1}
                      </td>
                      <td className="col-sm-8 align-middle" key={`b2-${index}`}>
                        <AsyncSelect
                          defaultOptions
                          cacheOptions
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "black",
                            }),
                          }}
                          isClearable
                          loadOptions={loadPromiseOptions}
                          value={item.material}
                          defaultValue={item.material}
                          onChange={(e) => onChangeMaterial(e, index)}
                          isDisabled={isReadOnly}
                        />
                      </td>
                      <td className="col-sm-2 align-middle" key={`b3-${index}`}>
                        <Select
                          options={LIST_TIME}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "black",
                            }),
                          }}
                          value={item.duration}
                          defaultValue={item.duration}
                          onChange={(e) => onChangeDuration(e, index)}
                          isDisabled={isReadOnly}
                        />
                      </td>
                      {!isReadOnly && (
                        <td
                          className="col-sm-1 align-middle"
                          key={`b4-${index}`}
                        >
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleCustomRemoveRules(index)}
                          >
                            <i className="fa fa-minus" />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            {errors?.data_rules && (
              <div
                style={{
                  width: "100%",
                  marginTop: ".25rem",
                  fontSize: "80%",
                  color: " #dc3545",
                }}
              >
                {errors?.data_rules}
              </div>
            )}
          </div>
        </div>
      </div>
      {!isReadOnly && (
        <div className="row">
          <div className={isUpdate ? "col-12 pt-3" : "col-sm-12 col-md-6 py-1"}>
            <Button
              variant="success"
              className="float-right"
              onClick={onCustomSubmit}
            >
              <i className="fas fa-save" /> Submit
            </Button>
            {!isUpdate && (
              <Button
                variant="danger"
                className="float-right mr-2"
                onClick={onCustomClear}
              >
                <i className="fas fa-eraser" /> Clear
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

FormCustom.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  isUpdate: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  list_product_facility: PropTypes.instanceOf(Array),
  loading_product_facility: PropTypes.bool,
  value_select_material_before: PropTypes.instanceOf(Object),
  value_select_product_facility: PropTypes.instanceOf(Object),
  value_data_rules: PropTypes.instanceOf(Array),
  handleCustomSubmit: PropTypes.func,
};
FormCustom.defaultProps = {
  initialValues: {},
  isUpdate: false,
  isReadOnly: false,
  list_product_facility: [],
  loading_product_facility: false,
  value_select_material_before: null,
  value_select_product_facility: null,
  value_data_rules: [],
  handleCustomSubmit: () => {},
};

const FormCustomBulk = ({
  initialValues,
  list_product_facility,
  loading_product_facility,
  handleCustomSubmit,
}) => {
  const [select_product_facility, setProductFacility] = useState(null);
  const [errors, setErrors] = useState({});
  const [excel, setExcel] = useState({
    path: "",
    fileName: "Choose file",
    dataLoaded: false,
    file: null,
    data: null,
  });

  const onChangeProductFacility = (e) => {
    setProductFacility(e);

    delete errors.prod_facility_code;
    setErrors(errors);
  };

  const validateForm = (values, download = false) => {
    const new_errors = {};

    if (!values.prod_facility_code) {
      new_errors.prod_facility_code = "Field is Required";
    }
    if (!values.excel && !download) {
      new_errors.excel = "Field is Required";
    }

    setErrors(new_errors);

    return { error: Object.keys(new_errors).length !== 0, message: new_errors };
  };

  const onCustomSubmit = () => {
    try {
      const new_values = {
        ...initialValues,
        ...select_product_facility?.detail,
        excel: excel.data,
      };

      const validate = validateForm(new_values);
      if (validate.error) {
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          //
          handleCustomSubmit(new_values, onCustomClear);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } catch (err) {
      Swal.fire("Information ", "Please check your entries again!", "error");
    } finally {
    }
  };

  const onCustomGenerate = () => {
    try {
      const new_values = { ...select_product_facility?.detail };

      const validate = validateForm(new_values, true);
      if (validate.error) {
        return;
      }

      const query = { ...new_values };
      generateExcel(query)
        .then((response) => {
          const datetime = moment().format("YYYYMMDD_HHmmss");
          const fileName = `matrix_cip_produksi_${datetime}.xlsx`;
          FileDownload(response, fileName);
        })
        .catch(() => {})
        .finally(() => {});
    } catch (e) {}
  };

  const onCustomClear = () => {
    setProductFacility(null);
    setErrors({});
    setExcel({
      path: "",
      fileName: "Choose file",
      file: null,
      dataLoaded: false,
      data: [],
    });
  };

  const fileHandler = (e) => {
    if (e.target.files.length) {
      const fileObject = e.target.files[0];
      const fileName = fileObject.name;

      readFile(fileObject)
        .then((response) => {
          const new_excel = {
            ...excel,
            path: e.target.value,
            fileName,
            file: fileObject,
            dataLoaded: true,
            data: response,
          };
          setExcel(new_excel);
        })
        .catch((error) => {
          setExcel({
            path: e.target.value,
            fileName,
            file: fileObject,
            dataLoaded: false,
            data: [],
          });
          toast.error(error.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const binaryString = e.target.result;
          const workbook = XLSX.read(binaryString, { type: "buffer" });

          if (workbook.SheetNames.length > 1) {
            reject({ message: "Format excel is wrong" });
            return;
          } else {
            const worksheet = workbook.SheetNames.map((item) => {
              const worksheet = workbook.Sheets[item];
              return XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
              });
            });
            resolve(worksheet[0]);
          }
        };
      } catch (e) {
        reject(new Error(e.message));
      }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-sm-12 col-md-6 pt-3">
          <div className="form-group">
            <label htmlFor="productFacility">Product Facility</label>
            <Select
              options={list_product_facility}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              isLoading={loading_product_facility}
              value={select_product_facility}
              defaultValue={select_product_facility}
              onChange={onChangeProductFacility}
            />

            {errors?.prod_facility_code && (
              <div
                style={{
                  width: "100%",
                  marginTop: ".25rem",
                  fontSize: "80%",
                  color: " #dc3545",
                }}
              >
                {errors?.prod_facility_code}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputFile">File input</label>
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="exampleInputFile"
                  onChange={fileHandler}
                  accept={SheetJSFT}
                  value={excel.path}
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  {excel.fileName}
                </label>
              </div>
            </div>

            {errors?.excel && (
              <div
                style={{
                  width: "100%",
                  marginTop: ".25rem",
                  fontSize: "80%",
                  color: " #dc3545",
                }}
              >
                {errors?.excel}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-6 pt-3">
          <div className="pt-4">
            <Button
              variant="primary"
              className="float-right"
              onClick={onCustomGenerate}
            >
              <i className="fas fa-download" /> Download Template
            </Button>
          </div>
        </div>
      </div>
      {excel.dataLoaded && (
        <div className="row">
          <div className="col-sm-12 col-md-12 pt-3">
            <WorksheetCustom data={excel.data} />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-12 col-md-12 pt-3">
          <Button
            variant="success"
            className="float-right"
            onClick={onCustomSubmit}
          >
            <i className="fas fa-save" /> Submit
          </Button>
          <Button
            variant="danger"
            className="float-right mr-2"
            onClick={onCustomClear}
          >
            <i className="fas fa-eraser" /> Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

FormCustomBulk.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  list_product_facility: PropTypes.instanceOf(Array),
  loading_product_facility: PropTypes.bool,
  handleCustomSubmit: PropTypes.func,
};
FormCustomBulk.defaultProps = {
  initialValues: {},
  list_product_facility: [],
  loading_product_facility: false,
  handleCustomSubmit: () => {},
};

const FormCustomModal = ({
  initialValues,
  title,
  list_product_facility,
  loading_product_facility,
  value_select_material_before,
  value_select_product_facility,
  value_data_rules,
  handleCustomUpdate,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isReadOnly, setReadOnly] = useState(false);
  const [type, setType] = useState("");
  return (
    <>
      <Button
        variant="info"
        className="mx-1"
        onClick={() => {
          setType("VIEW - ");
          setReadOnly(true);
          setOpen(true);
        }}
      >
        <i className="fas fa-th"></i>
      </Button>
      <Button
        variant="success"
        className="mx-1"
        onClick={() => {
          setType("UPDATE - ");
          setReadOnly(false);
          setOpen(true);
        }}
      >
        <i className="fas fa-edit"></i>
      </Button>

      <Modal show={isOpen} size="lg" backdrop="static" onHide={setOpen}>
        <ModalHeader closeButton className="bg-info text-white">
          <ModalTitle>
            {type}
            {title}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormCustom
            initialValues={initialValues}
            isUpdate={isOpen}
            isReadOnly={isReadOnly}
            list_product_facility={list_product_facility}
            loading_product_facility={loading_product_facility}
            value_select_material_before={value_select_material_before}
            value_select_product_facility={value_select_product_facility}
            value_data_rules={value_data_rules}
            handleCustomSubmit={handleCustomUpdate}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

FormCustomModal.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  list_product_facility: PropTypes.instanceOf(Array),
  loading_product_facility: PropTypes.bool,
  value_select_material_before: PropTypes.instanceOf(Object),
  value_select_product_facility: PropTypes.instanceOf(Object),
  value_data_rules: PropTypes.instanceOf(Array),
  handleCustomUpdate: PropTypes.func,
};
FormCustomModal.defaultProps = {
  initialValues: {},
  title: null,
  list_product_facility: [],
  loading_product_facility: false,
  value_select_material_before: null,
  value_select_product_facility: null,
  value_data_rules: [],
  handleCustomUpdate: () => {},
};

const TableCustom = ({
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  options,
  fetchData,
  list_product_facility,
  loading_product_facility,
  handleCustomUpdate,
}) => {
  const handlePageChange = (page) => {
    fetchData(page, perPage, options);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return fetchData(page, newPerPage, options);
  };
  const columns = useMemo(
    () => [
      {
        name: "MATERIAL BEFORE",
        selector: (row) =>
          `(${row.before_material_code}) ${row.before_material_description}`,
        sortable: true,
        width: "400px",
      },
      {
        name: "TYPE",
        selector: (row) =>
          row?.prod_facility_number
            ? row.prod_facility_type + " " + row.prod_facility_number
            : row.prod_facility_type,
        sortable: true,
        width: "80px",
      },
      {
        name: "PRODUCT FACILITY",
        selector: (row) =>
          row?.prod_facility_code
            ? `(${row?.prod_facility_code}) ${row?.prod_facility_desc}`
            : "",
        sortable: true,
        width: "400px",
      },
      {
        name: "ACTION",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => {
          const {
            _id,
            after_material,
            before_material_code,
            before_material_description,
            prod_facility_code,
            prod_facility_desc,
            prod_facility_number,
            prod_facility_type,
          } = row;
          const initialValues = {
            _id,
            after_material,
            before_material_code,
            before_material_description,
            prod_facility_code,
            prod_facility_desc,
            prod_facility_number,
            prod_facility_type,
          };

          const title = `(${row.before_material_code}) ${row.before_material_description}`;

          let value_select_material_before = null;

          if (row?.before_material_code) {
            value_select_material_before = {
              value: row.before_material_code,
              label: `(${row.before_material_code}) ${row.before_material_description}`,
              detail: {
                before_material_code: row.before_material_code,
                before_material_description: row.before_material_description,
              },
            };
          }

          let value_select_product_facility = null;

          if (row?.prod_facility_code) {
            value_select_product_facility = {
              value: row.prod_facility_code,
              label: `(${row.prod_facility_code}) ${row.prod_facility_desc}`,
              detail: {
                prod_facility_number: row.prod_facility_number,
                prod_facility_type: row.prod_facility_type,
                prod_facility_code: row.prod_facility_code,
                prod_facility_desc: row.prod_facility_desc,
              },
            };
          }

          let value_data_rules = [];

          if (row?.after_material) {
            value_data_rules = row.after_material.map((item) => {
              return {
                material: {
                  value: item.material_code,
                  label: `(${item.material_code}) ${item.material_description}`,
                  detail: {
                    before_material_code: item.material_code,
                    before_material_description: item.material_description,
                  },
                },
                duration: LIST_TIME.find(
                  (find) => find.detail.duration === item.duration
                ),
              };
            });
          }

          return (
            <FormCustomModal
              initialValues={initialValues}
              title={title}
              list_product_facility={list_product_facility}
              loading_product_facility={loading_product_facility}
              value_select_material_before={value_select_material_before}
              value_select_product_facility={value_select_product_facility}
              value_data_rules={value_data_rules}
              handleCustomUpdate={handleCustomUpdate}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list_product_facility, loading_product_facility]
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  options: PropTypes.instanceOf(Object),
  fetchData: PropTypes.func,
  list_product_facility: PropTypes.instanceOf(Array),
  loading_product_facility: PropTypes.bool,
  handleCustomUpdate: PropTypes.func,
};
TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  loading: false,
  options: {},
  fetchData: () => {},
  list_product_facility: [],
  loading_product_facility: false,
  handleCustomUpdate: () => {},
};

class ApsCIP extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      department: null,
      requester: null,
      loading_data: false,
      perPage: 10,
      curPage: 1,
      totalRows: 0,
      data: [],
      loading_material_list: false,
      loading_product_facility: false,
      filter_material: "",
      filter_product_facility: null,
      list_product_facility: [],
      list_material: [],
      errors: {},
      before_material_code: "",
      before_material_description: "",
      prod_facility_number: "",
      prod_facility_type: "",
      prod_facility_code: "",
      prod_facility_desc: "",
      after_material: [],
      excel: [],
    };
  }

  createSubmit = (values, onCustomClear) => {
    handleSubmit(values)
      .then(() => {
        const { curPage, perPage, filter_material } = this.state;
        this.fetchData(curPage, perPage, filter_material);
        onCustomClear();
      })
      .catch(() => {})
      .finally(() => {});
  };

  createSubmitBulk = (values, onCustomClear) => {
    handleSubmitBulk(values)
      .then(() => {
        const { curPage, perPage, filter_material } = this.state;
        this.fetchData(curPage, perPage, filter_material);
        onCustomClear();
      })
      .catch(() => {})
      .finally(() => {});
  };

  updateSubmit = (values, onCustomClear) => {
    handleUpdate(values)
      .then(() => {
        const { curPage, perPage, filter_material } = this.state;
        this.fetchData(curPage, perPage, filter_material);
        onCustomClear();
      })
      .catch(() => {})
      .finally(() => {});
  };

  onChangeFilterMaterial = (e) => {
    this.setState({ filter_material: e?.target?.value });
  };

  onChangeFilterProductFacility = (e) => {
    this.setState({ filter_product_facility: e });
  };

  handleFilterFind = () => {
    const { perPage, filter_material, filter_product_facility } = this.state;
    const options = {
      search: filter_material,
      product_facility: filter_product_facility?.value,
    };

    this.fetchData(1, perPage, options);
  };

  handleFilterClear = () => {
    this.setState({
      filter_material: "",
      filter_product_facility: null,
      curPage: 1,
      perPage: 10,
    });
    this.fetchData(1, 10);
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const { curPage, perPage } = this.state;

    this.setState({
      department: user?.details?.hris_org_tree?.current_person?.nama_department,
      requester: user?.username,
      loading_material_list: true,
      loading_product_facility: true,
    });

    this.fetchData(curPage, perPage);
    this.fetchDataProdFacility();
  }

  fetchData = async (newPage, newPerPage, options = {}) => {
    this.setState({ loading_data: true });
    const query = { page: newPage, sizePerPage: newPerPage, ...options };
    return readCIP(query)
      .then((response) => {
        this.setState({
          data: response.foundData,
          totalRows: response.countData,
          curPage: response.currentPage,
          perPage: newPerPage,
        });
      })
      .catch(() => {
        this.setState({ data: [], totalRows: 0, curPage: 1, perPage: 10 });
      })
      .finally(() => {
        this.setState({ loading_data: false });
      });
  };

  fetchDataProdFacility = async () => {
    return readProdFacility()
      .then((response) => {
        const new_options = response.map((item) => {
          return {
            value: item.facility_code,
            label: `(${item.facility_code}) ${item.facility_desc}`,
            detail: {
              prod_facility_code: item.facility_code,
              prod_facility_desc: item.facility_desc,
              prod_facility_number: item.facility_number,
              prod_facility_type: item.facility_type,
            },
          };
        });
        this.setState({ list_product_facility: new_options });
      })
      .catch(() => {
        this.setState({ list_product_facility: [] });
      })
      .finally(() => {
        this.setState({ loading_product_facility: false });
      });
  };

  render() {
    const {
      data,
      totalRows,
      curPage,
      perPage,
      filter_material,
      filter_product_facility,
      list_product_facility,
      loading_product_facility,
      loading_data,
      requester,
      before_material_code,
      before_material_description,
      prod_facility_number,
      prod_facility_type,
      prod_facility_code,
      prod_facility_desc,
      after_material,
      excel,
    } = this.state;

    const initialValues = {
      requester,
      before_material_code,
      before_material_description,
      prod_facility_number,
      prod_facility_type,
      prod_facility_code,
      prod_facility_desc,
      after_material,
    };

    const initialValuesBulk = {
      requester,
      excel,
    };

    const filter_options = {
      material: filter_material,
      product_facility: filter_product_facility?.value ?? null,
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                </div>
              </div>
            </div>

            {/* START SECTION */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">CIP</h3>
                      </div>
                      <div className="card-body">
                        <Tabs
                          defaultActiveKey="single"
                          title="Single"
                          variant="tabs"
                        >
                          <Tab eventKey="single" title="Single Input">
                            <FormCustom
                              initialValues={initialValues}
                              list_product_facility={list_product_facility}
                              loading_product_facility={
                                loading_product_facility
                              }
                              handleCustomSubmit={this.createSubmit}
                            />
                          </Tab>
                          <Tab eventKey="bulk" title="Bulk Input">
                            <FormCustomBulk
                              initialValues={initialValuesBulk}
                              list_product_facility={list_product_facility}
                              loading_product_facility={
                                loading_product_facility
                              }
                              handleCustomSubmit={this.createSubmitBulk}
                            />
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Historical Data</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {/* Filter Material */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="material">Material</label>
                              <input
                                type="text"
                                className="form-control"
                                id="material"
                                placeholder="Material Code / Description"
                                onChange={this.onChangeFilterMaterial}
                                value={filter_material}
                              />
                            </div>
                          </div>
                          {/* Filter Product Facility */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="productFacility">
                                Product Facility
                              </label>
                              <Select
                                options={list_product_facility}
                                isSearchable={list_product_facility.length > 7}
                                isLoading={loading_product_facility}
                                isDisabled={
                                  loading_product_facility ||
                                  list_product_facility.length === 0
                                }
                                placeholder={
                                  loading_product_facility
                                    ? "Loading..."
                                    : list_product_facility.length > 0
                                    ? "Select..."
                                    : "Not Found"
                                }
                                onChange={this.onChangeFilterProductFacility}
                                value={filter_product_facility}
                                defaultValue={filter_product_facility}
                                isClearable
                              />
                            </div>
                          </div>
                          {/* Button Filter */}
                          <div className="col-md-3 d-flex align-items-end">
                            <div className="form-group">
                              <Button
                                variant="primary"
                                className="mx-1"
                                onClick={this.handleFilterFind}
                              >
                                <i className="fas fa-search" /> Search
                              </Button>
                              <Button
                                variant="danger"
                                className="mx-1"
                                onClick={this.handleFilterClear}
                              >
                                <i className="fas fa-eraser" /> Clear
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <TableCustom
                              data={data}
                              totalRows={totalRows}
                              perPage={perPage}
                              curPage={curPage}
                              fetchData={this.fetchData}
                              options={filter_options}
                              loading={loading_data}
                              list_product_facility={list_product_facility}
                              loading_product_facility={
                                loading_product_facility
                              }
                              handleCustomUpdate={this.updateSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* END SECTION*/}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ApsCIP);
