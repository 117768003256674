import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://10.88.1.12:3000/api/user/";
const API_URL_DEFAULT = process.env.REACT_APP_API;

class UserService {
  getPublicContent() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getUserByDeptPos(payload) {
    return axios.post(API_URL_DEFAULT + "user", payload, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
