import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";

import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";

import {
  readStickerLnk,
  readStickerType,
  addMaterialSticker,
  editMaterialSticker,
  deleteMaterialSticker,
} from "../modules/StickerModule";
import RemoteAll from "../components/RemoteAll";

import { readMaterialBySearch } from "../modules/MaterialModule";
import FinalSelect from "../components/FinalSelect";
class Sticker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      list_datatable: [],
      errors_edit: {},
      errors_create: {},
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      vendor_create_code: null,
      columns: [
        {
          dataField: "code",
          text: "Sticker Code",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material",
          text: "Sticker Description",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "type",
          text: "Type",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "blank.material_code",
          text: "Blank Code",
          classes: "text-center",
          sort: false,
        },
        {
          dataField: "ribbon.material_code",
          text: "Ribbon Code",
          classes: "text-center",
          sort: false,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "code",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "code",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      isValidCreate: {
        material_sticker_create: true,
        material_blank_create: true,
        material_ribbon_create: true,
        material_type_create: true,
      },
      isValidEdit: {
        material_sticker_edit: true,
        material_blank_edit: true,
        material_ribbon_edit: true,
        material_type_edit: true,
      },
      material_sticker_create: null,
      material_blank_create: null,
      material_ribbon_create: null,
      material_blank_per_create: "700",
      material_ribbon_per_create: "2100",
      material_type_create: null,
      select_items_material_sticker: [],
      select_items_material_ribbon: [],
      select_items_material_blank: [],
      select_items_material_type: [],
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    //read data daily spot on backend
    readStickerLnk(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
    //get sticker
    readMaterialBySearch(
      `constraint_regex=material_desc&constraint_value_regex=STICKER&constraint_value_regex2=STIKER&constraint_value_regex3=LABEL&sizePerPage=10000`
    ).then((res) => {
      this.setState({
        select_items_material_sticker: res,
      });
    });
    //get sticker
    readStickerType(`sizePerPage=10000`).then((res) => {
      this.setState({
        select_items_material_type: res,
      });
    });
    //get blank
    readMaterialBySearch(
      `constraint_regex=material_desc&constraint_value_regex=BLANK&sizePerPage=10000`
    ).then((res) => {
      this.setState({
        select_items_material_blank: res,
      });
    });
    //get ribbon
    readMaterialBySearch(
      `constraint_regex=material_desc&constraint_value_regex=RIBBON&sizePerPage=10000`
    ).then((res) => {
      this.setState({
        select_items_material_ribbon: res,
      });
    });
    this.setState({
      date_create: new Date(),
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
        isAdmin: check_access.isAdmin,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  //handle change
  handleChangeCreate = (e) => {
    let { isValidCreate } = this.state;
    const { name, value } = e;
    if (value) {
      isValidCreate[name] = true;
      this.setState({
        [name]: value.value,
        isValidCreate: { ...isValidCreate, [name]: true },
      });
    } else {
      isValidCreate[name] = false;
      this.setState({
        [name]: null,
        isValidCreate: { ...isValidCreate, [name]: false },
      });
    }
  };
  handleChangeEdit = (e) => {
    let { isValidEdit } = this.state;
    const { name, value } = e;
    if (value) {
      isValidEdit[name] = true;
      this.setState({
        [name]: value.value,
        isValidEdit: { ...isValidEdit, [name]: true },
      });
    } else {
      isValidEdit[name] = false;
      this.setState({
        [name]: null,
        isValidEdit: { ...isValidEdit, [name]: false },
      });
    }
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.month), "yyyy-mm");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    const editKeyButton = `button-edit-${row.id}`;
    const editKeyIcon = `icon-edit-${row.id}`;
    const dtlKeyButton = `button-dtl-${row.id}`;
    const dtlKeyIcon = `icon-dtl-${row.id}`;
    const delKeyButton = `button-del-${row.id}`;
    const delKeyIcon = `icon-del-${row.id}`;
    return (
      <div>
        <button
          key={editKeyButton}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.code,
              row.type,
              row.ribbon.material_code,
              row.blank.material_code,
              row.ribbon.per,
              row.blank.per
            );
          }}
        >
          <i className="fas fa-th" key={editKeyIcon}></i>
        </button>
        {isEdit && (
          <button
            key={dtlKeyButton}
            type="button"
            className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.toEdit(
                row._id,
                row.code,
                row.type,
                row.ribbon.material_code,
                row.blank.material_code,
                row.ribbon.per,
                row.blank.per
              );
            }}
          >
            <i className="fas fa-edit" key={dtlKeyIcon}></i>
          </button>
        )}
        {isDelete && (
          <button
            key={delKeyButton}
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.toDelete(row._id);
            }}
          >
            <i className="fas fa-trash" key={delKeyIcon}></i>
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(cell, ".", ".", ",")}</div>;
  };
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toEdit = (id, code, type, ribbon_code, blank_code, ribbon_per, blank_per) => {
    this.setState(
      {
        id: id,
        material_sticker_edit: code,
        material_type_edit: type,
        material_ribbon_edit: ribbon_code,
        material_blank_edit: blank_code,
        material_ribbon_per_edit: ribbon_per,
        material_blank_per_edit: blank_per,
        editActive: true,
      },
      () => {
        this.toggle();
      }
    );
  };
  toDelete = (id) => {
    const { history } = this.props;
    const { requester, department } = this.state;
    let collection = {
      requester: requester,
      department: department,
      id: id,
    };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          this.setState({
            loading: true,
          });
          deleteMaterialSticker(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-mat-sticker-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/raw-materials/sticker");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-mat-sticker-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toDetail = (
    id,
    code,
    type,
    ribbon_code,
    blank_code,
    ribbon_per,
    blank_per
  ) => {
    this.setState(
      {
        id: id,
        material_sticker_edit: code,
        material_type_edit: type,
        material_ribbon_edit: ribbon_code,
        material_blank_edit: blank_code,
        material_ribbon_per_edit: ribbon_per,
        material_blank_per_edit: blank_per,
        readOnly: true,
      },
      () => {
        this.toggle();
      }
    );
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      readOnly: false,
      material_sticker_edit: null,
      material_type_edit: null,
      material_ribbon_edit: null,
      material_blank_edit: null,
      material_ribbon_per_edit: null,
      material_blank_per_edit: null,
      editActive: false,
      showModal: false,
      submitted: false,
    });
  };
  //validate input
  validateFormCreate = (
    material_sticker,
    material_blank,
    material_ribbon,
    material_type,
    material_ribbon_per,
    material_blank_per
  ) => {
    const { errors_create } = this.state;
    let clone_errors_create = { ...errors_create };
    const { isValidCreate } = this.state;
    let cloneisValidCreate = { ...isValidCreate };
    if (!material_sticker) {
      clone_errors_create.material_sticker = "This is field required";
      cloneisValidCreate.material_sticker_create = false;
    }

    if (!material_ribbon) {
      clone_errors_create.material_ribbon = "This is field required";
      cloneisValidCreate.material_ribbon_create = false;
    }

    if (!material_blank) {
      clone_errors_create.material_blank = "This is field required";
      cloneisValidCreate.material_blank_create = false;
    }
    if (!material_ribbon_per) {
      clone_errors_create.material_ribbon_per = "This is field required";
    }

    if (!material_blank_per) {
      clone_errors_create.material_blank_per = "This is field required";
    }

    if (!material_type) {
      clone_errors_create.material_type = "This is field required";
      cloneisValidCreate.material_type_create = false;
    }
    this.setState({
      errors_create: clone_errors_create,
      isValidCreate: cloneisValidCreate,
    });
    return clone_errors_create;
  };
  validateFormEdit = (
    material_sticker,
    material_blank,
    material_ribbon,
    material_type,
    material_ribbon_per,
    material_blank_per
  ) => {
    const { errors_edit } = this.state;
    let clone_errors_edit = { ...errors_edit };
    const { isValidEdit } = this.state;
    let cloneisValidEdit = { ...isValidEdit };
    if (!material_sticker) {
      clone_errors_edit.material_sticker = "This is field required";
      cloneisValidEdit.material_sticker_edit = false;
    }

    if (!material_ribbon) {
      clone_errors_edit.material_ribbon = "This is field required";
      cloneisValidEdit.material_ribbon_edit = false;
    }

    if (!material_blank) {
      clone_errors_edit.material_blank = "This is field required";
      cloneisValidEdit.material_blank_edit = false;
    }
    if (!material_ribbon_per) {
      clone_errors_edit.material_ribbon_per = "This is field required";
    }

    if (!material_blank_per) {
      clone_errors_edit.material_blank_per = "This is field required";
    }

    if (!material_type) {
      clone_errors_edit.material_type = "This is field required";
      cloneisValidEdit.material_type_edit = false;
    }
    this.setState({
      errors_edit: clone_errors_edit,
      isValidEdit: cloneisValidEdit,
    });
    return clone_errors_edit;
  };
  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readStickerLnk(query_string).then((response) => {
      this.setState({
        submitted_create: false,
        loading: false,
        submitted: false,
        id: null,
        editActive: false,
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        material_blank_per_create: "700",
        material_ribbon_per_create: "2100",
        material_sticker_create: null,
        material_blank_create: null,
        material_ribbon_create: null,
        material_type_create: null,
        material_blank_per_edit: null,
        material_ribbon_per_edit: null,
        material_sticker_edit: null,
        material_blank_edit: null,
        material_ribbon_edit: null,
        material_type_edit: null,
      });
    });
  };
  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });

    const query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readStickerLnk(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleSubmit = (e) => {
    const {
      material_sticker_create,
      material_blank_create,
      material_ribbon_create,
      material_type_create,
      material_ribbon_per_create,
      material_blank_per_create,
      requester,
      department,
    } = this.state;
    const { history } = this.props;
    this.setState({ submitted_create: true });
    if (
      !this.validateFormCreate(
        material_sticker_create,
        material_blank_create,
        material_ribbon_create,
        material_type_create,
        material_ribbon_per_create,
        material_blank_per_create
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      material_sticker: material_sticker_create,
      material_ribbon: material_ribbon_create,
      material_blank: material_blank_create,
      material_ribbon_per: material_ribbon_per_create,
      material_blank_per: material_blank_per_create,
      material_type: material_type_create,
    };
    if (
      material_sticker_create &&
      material_blank_create &&
      material_ribbon_create &&
      material_type_create &&
      material_ribbon_per_create &&
      material_blank_per_create
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          addMaterialSticker(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-sticker-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/raw-materials/sticker");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-sticker-failed",
              });
              if (err === "Data is already exists!") {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${material_sticker_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    editMaterialSticker(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-sticker-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          //read data exchange rate on backend
                          this.reloadDataBackend();
                          history.push("/raw-materials/sticker");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-sticker-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSubmitEdit = (e) => {
    const {
      material_sticker_edit,
      material_blank_edit,
      material_ribbon_edit,
      material_type_edit,
      material_ribbon_per_edit,
      material_blank_per_edit,
      requester,
      department,
    } = this.state;
    const { history } = this.props;
    this.setState({ submitted: true });
    if (
      !this.validateFormEdit(
        material_sticker_edit,
        material_blank_edit,
        material_ribbon_edit,
        material_type_edit,
        material_ribbon_per_edit,
        material_blank_per_edit
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      material_sticker: material_sticker_edit,
      material_ribbon: material_ribbon_edit,
      material_blank: material_blank_edit,
      material_ribbon_per: material_ribbon_per_edit,
      material_blank_per: material_blank_per_edit,
      material_type: material_type_edit,
    };

    if (
      material_sticker_edit &&
      material_blank_edit &&
      material_ribbon_edit &&
      material_type_edit &&
      material_ribbon_per_edit &&
      material_blank_per_edit
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });

          editMaterialSticker(collection)
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-sticker-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend

                this.setState({ loading: false });
                this.toggle();
                this.reloadDataBackend();
                history.push("/raw-materials/sticker");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-sticker-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { search, page, sizePerPage, filter_by, sortBy, sortOrder } =
      this.state;
    const query_string = `page=${page}&sizePerPage=${sizePerPage}&search=${search}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    readStickerLnk(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });
  };

  render() {
    let {
      columns,
      loading,
      defaultSorted,
      cellEditProps,
      list_datatable,
      isRead,
      search,
      page,
      sizePerPage,
      totalSize,
      isClearable,
      isCreate,
      submitted_create,
      material_sticker_create,
      material_ribbon_create,
      material_blank_create,
      material_ribbon_per_create,
      material_blank_per_create,
      material_type_create,
      select_items_material_sticker,
      select_items_material_ribbon,
      select_items_material_blank,
      select_items_material_type,
      isValidCreate,
      errors_create,
      showModal,
      submitted,
      id,
      isValidEdit,
      material_sticker_edit,
      material_type_edit,
      material_blank_edit,
      material_blank_per_edit,
      material_ribbon_edit,
      material_ribbon_per_edit,
      errors_edit,
      readOnly,
      editActive,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            onEscapeKeyDown={() => {}}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>{" "}
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id && editActive ? "Update" : "Detail"} Material Sticker LNK
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <FinalSelect
                        label="Sticker *"
                        name="material_sticker_edit"
                        value={material_sticker_edit}
                        placeholder="Select ..."
                        selectOptions={select_items_material_sticker}
                        isClearable={false}
                        handleChange={this.handleChangeEdit.bind(this)}
                        isValid={isValidEdit.material_sticker_edit}
                        error={errors_edit.material_sticker}
                        isFormRow={false}
                        zIndex="30"
                        isDisabled={true}
                        fontSize="0.8rem"
                        selectStyle={{ marginBottom: "5px" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <FinalSelect
                        label="Type *"
                        name="material_type_edit"
                        value={material_type_edit}
                        placeholder="Select ..."
                        selectOptions={select_items_material_type}
                        isClearable={false}
                        handleChange={this.handleChangeEdit.bind(this)}
                        isValid={isValidEdit.material_type_edit}
                        error={errors_edit.material_type}
                        isFormRow={false}
                        zIndex="29"
                        isDisabled={readOnly ? true : false}
                        fontSize="0.8rem"
                        selectStyle={{ marginBottom: "5px" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} md={4}>
                      <FinalSelect
                        label="Blank *"
                        name="material_blank_edit"
                        value={material_blank_edit}
                        placeholder="Select ..."
                        selectOptions={select_items_material_blank}
                        isClearable={false}
                        handleChange={this.handleChangeEdit.bind(this)}
                        isValid={isValidEdit.material_blank_edit}
                        error={errors_edit.material_blank}
                        isFormRow={false}
                        zIndex="28"
                        isDisabled={readOnly ? true : false}
                        fontSize="0.8rem"
                        selectStyle={{ marginBottom: "5px" }}
                      />
                    </Col>
                    <Col xs={2} md={1}>
                      <div className="form-group">
                        <label>Per *</label>
                        <input
                          name="material_blank_per_edit"
                          value={material_blank_per_edit || ""}
                          placeholder="0"
                          type="number"
                          min="0"
                          className="form-control form-control-sm"
                          onChange={this.onChangeValue}
                          readOnly={readOnly ? true : false}
                          required
                        />
                        {errors_edit.blank_per && (
                          <div className="invalid-feedback">
                            {errors_edit.blank_per}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} md={4}>
                      <FinalSelect
                        label="Ribbon *"
                        name="material_ribbon_edit"
                        value={material_ribbon_edit}
                        placeholder="Select ..."
                        selectOptions={select_items_material_ribbon}
                        isClearable={false}
                        handleChange={this.handleChangeEdit.bind(this)}
                        isValid={isValidEdit.material_ribbon_edit}
                        error={errors_edit.material_ribbon}
                        isFormRow={false}
                        zIndex="27"
                        isDisabled={readOnly ? true : false}
                        fontSize="0.8rem"
                        selectStyle={{ marginBottom: "5px" }}
                      />
                    </Col>
                    <Col xs={2} md={1}>
                      <div className="form-group">
                        <label>Per *</label>
                        <input
                          name="material_ribbon_per_edit"
                          value={material_ribbon_per_edit || ""}
                          placeholder="0"
                          type="number"
                          min="0"
                          className="form-control form-control-sm"
                          onChange={this.onChangeValue}
                          readOnly={readOnly ? true : false}
                          required
                        />
                        {errors_edit.ribbon_per && (
                          <div className="invalid-feedback">
                            {errors_edit.ribbon_per}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && editActive && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Material Sticker LNK</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <FinalSelect
                                  label="Sticker *"
                                  name="material_sticker_create"
                                  value={material_sticker_create}
                                  placeholder="Select ..."
                                  selectOptions={select_items_material_sticker}
                                  isClearable={false}
                                  handleChange={this.handleChangeCreate.bind(
                                    this
                                  )}
                                  isValid={
                                    isValidCreate.material_sticker_create
                                  }
                                  error={errors_create.material_sticker}
                                  isFormRow={false}
                                  zIndex="30"
                                  isDisabled={false}
                                  fontSize="0.8rem"
                                  selectStyle={{ marginBottom: "5px" }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={4}>
                                <FinalSelect
                                  label="Type *"
                                  name="material_type_create"
                                  value={material_type_create}
                                  placeholder="Select ..."
                                  selectOptions={select_items_material_type}
                                  isClearable={false}
                                  handleChange={this.handleChangeCreate.bind(
                                    this
                                  )}
                                  isValid={isValidCreate.material_type_create}
                                  error={errors_create.material_type}
                                  isFormRow={false}
                                  zIndex="29"
                                  isDisabled={false}
                                  fontSize="0.8rem"
                                  selectStyle={{ marginBottom: "5px" }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={10} md={4}>
                                <FinalSelect
                                  label="Blank *"
                                  name="material_blank_create"
                                  value={material_blank_create}
                                  placeholder="Select ..."
                                  selectOptions={select_items_material_blank}
                                  isClearable={isClearable}
                                  handleChange={this.handleChangeCreate.bind(
                                    this
                                  )}
                                  isValid={isValidCreate.material_blank_create}
                                  error={errors_create.material_blank}
                                  isFormRow={false}
                                  zIndex="28"
                                  isDisabled={false}
                                  fontSize="0.8rem"
                                  selectStyle={{ marginBottom: "5px" }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <div className="form-group">
                                  <label>Per *</label>
                                  <input
                                    name="material_blank_per_create"
                                    value={material_blank_per_create || ""}
                                    placeholder="0"
                                    type="number"
                                    min="0"
                                    className="form-control form-control-sm"
                                    onChange={this.onChangeValue}
                                    required
                                  />
                                  {errors_create.blank_per && (
                                    <div className="invalid-feedback">
                                      {errors_create.blank_per}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={10} md={4}>
                                <FinalSelect
                                  label="Ribbon *"
                                  name="material_ribbon_create"
                                  value={material_ribbon_create}
                                  placeholder="Select ..."
                                  selectOptions={select_items_material_ribbon}
                                  isClearable={false}
                                  handleChange={this.handleChangeCreate.bind(
                                    this
                                  )}
                                  isValid={isValidCreate.material_ribbon_create}
                                  error={errors_create.material_ribbon}
                                  isFormRow={false}
                                  zIndex="27"
                                  isDisabled={false}
                                  fontSize="0.8rem"
                                  selectStyle={{ marginBottom: "5px" }}
                                />
                              </Col>
                              <Col xs={2} md={1}>
                                <div className="form-group">
                                  <label>Per *</label>
                                  <input
                                    name="material_ribbon_per_create"
                                    value={material_ribbon_per_create || ""}
                                    placeholder="0"
                                    type="number"
                                    min="0"
                                    className="form-control form-control-sm"
                                    onChange={this.onChangeValue}
                                    required
                                  />
                                  {errors_create.ribbon_per && (
                                    <div className="invalid-feedback">
                                      {errors_create.ribbon_per}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <input
                                    className="form-control form-control-sm"
                                    name="search"
                                    value={search || ""}
                                    placeholder="Search ..."
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    onChange={this.onChangeValue}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />

                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Sticker);
