import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BOMFGEXPService {
    create(payload) {
        return axios.post(API_URL_DEFAULT + "bom_fg_exp", payload, {
            headers: authHeader(),
        });
    }
    edit(payload) {
        return axios.put(API_URL_DEFAULT + "bom_fg_exp", payload, {
            headers: authHeader(),
        });
    }
    readBOMFG(query_string) {
        return axios.get(API_URL_DEFAULT + "bom_fg_exp/?" + query_string, {
            headers: authHeader(),
        });
    }
    delete(payload) {
        return axios.delete(API_URL_DEFAULT + "bom_fg_exp", {
            data: payload,
            headers: authHeader(),
        });
    }
    readBOMFGSpec(query_string) {
        return axios.get(API_URL_DEFAULT + "bom_fg_exp/spec/?" + query_string, {
            headers: authHeader(),
        });
    }
    readBOMFGSpecPlant(query_string) {
        return axios.get(API_URL_DEFAULT + "bom_fg_exp/spec-plant/?" + query_string, {
            headers: authHeader(),
        });
    }
}

export default new BOMFGEXPService();
