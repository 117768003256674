import { DefaultRowPO, DefaultRowContract } from "../defaultData/defaultRow";

export const DefaultTableInputPO = {
  header: [
    {
      label: "No",
      width: "5%",
    },
    {
      label: "PO",
      width: "10%",
    },
    {
      label: "Price/KG",
      width: "10%",
    },
    {
      label: "Beginning Balance",
    },
    {
      label: "1st Month",
    },
    {
      label: "2nd Month",
    },
    {
      label: "3rd Month",
    },
    {
      label: "4th Month",
    },
    {
      label: "5th Month",
    },
    {
      label: "6th Month",
    },
    {
      label: "End Balance",
    },
  ],
  body: DefaultRowPO,
  footer: [
    {
      children: [
        {
          label: "Total",
          colspan: "4",
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
    },
    {
      children: [
        {
          label: "Weighted",
          colspan: "4",
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
    },
  ],
};
export const DefaultTableInputContract = {
  header: [
    {
      label: "No",
      width: "5%",
    },
    {
      label: "Contract",
      width: "10%",
    },
    {
      label: "Price/KG",
      width: "10%",
    },
    {
      label: "Beginning Balance",
    },
    {
      label: "1st Month",
    },
    {
      label: "2nd Month",
    },
    {
      label: "3rd Month",
    },
    {
      label: "4th Month",
    },
    {
      label: "5th Month",
    },
    {
      label: "6th Month",
    },
    {
      label: "End Balance",
    },
  ],
  body: DefaultRowContract,
  footer: [
    {
      children: [
        {
          label: "Total",
          colspan: "4",
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
    },
    {
      children: [
        {
          label: "Weighted",
          colspan: "4",
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
    },
  ],
};
export const DefaultTableInputMRP = {
  header: [
    {
      label: "",
      width: "40%",
      isLabel: true,
    },
    {
      label: "1st Month",
      width: "12%",
    },
    {
      label: "2nd Month",
      width: "12%",
    },
    {
      label: "3rd Month",
      width: "12%",
    },
    {
      label: "4th Month",
      width: "12%",
    },
    {
      label: "5th Month",
      width: "12%",
    },
    {
      label: "6th Month",
      width: "12%",
    },
  ],
  body: [
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Needs (From MRP)",
        },
        {
          readOnly: true,
          data_type: "number",
          value: "50",
          width: "auto",
          name: "n_1",
        },
        {
          readOnly: true,
          data_type: "number",
          value: "20",
          width: "auto",
          name: "n_2",
        },
        {
          readOnly: true,
          data_type: "number",
          value: "10",
          width: "auto",
          name: "n_3",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Receipt",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Carry Over",
        },
        {
          readOnly: true,
          data_type: "number",
          value: "0",
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Balance",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
  ],
  footer: [],
};
export const DefaultTableInputPlanning = {
  header: [
    {
      label: "",
      width: "2%",
      isLabel: true,
    },
    {
      label: "1st Month",
      width: "10%",
    },
    {
      label: "2nd Month",
      width: "10%",
    },
    {
      label: "3rd Month",
      width: "10%",
    },
    {
      label: "4th Month",
      width: "10%",
    },
    {
      label: "5th Month",
      width: "10%",
    },
    {
      label: "6th Month",
      width: "10%",
    },
  ],
  body: [
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Qty",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Price/KG",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Currency",
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
        {
          readOnly: false,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: [],
          isValid: true,
          errors: {},
          zIndex: "20",
          fontSize: "0.60625rem"
        },
      ],
    },
    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "1 USD =",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: false,
          data_type: "number",
          placeholder: "0",
          value: null,
          width: "100%",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },

    {
      children: [
        {
          readOnly: true,
          isLabel: true,
          data_type: "text",
          value: null,
          width: "auto",
          name: null,
          label: "Total Price (USD)",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_1",
          label: "1st Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_2",
          label: "2nd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_3",
          label: "3rd Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_4",
          label: "4th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_5",
          label: "5th Month",
        },
        {
          readOnly: true,
          data_type: "number",
          value: null,
          width: "auto",
          name: "n_6",
          label: "6th Month",
        },
      ],
    },
  ],
  footer: [],
};
