import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialClassificationService {
  readMaterialClassification(queryString) {
    return axios.get(
      API_URL_DEFAULT +
        "material_classification?sizePerPage=100000&" +
        queryString,
      { headers: authHeader() }
    );
  }
}

export default new MaterialClassificationService();
