import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import AllRoute from "./routes/AllRoute";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import { history } from "./helpers/history";
import {
  goONELNKHomapage,
  loginSSO,
  getMenu,
  login,
  loginPhone,
} from "./modules/AuthModule";
import { Cookies } from "react-cookie";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { cookieNameGenerator } from "./helpers/helpers";

const cookies = new Cookies();

const accessToken = localStorage.getItem("accessToken") || null;
const IS_SSO = process.env.REACT_APP_SSO == "true";
const SSO_KEY = process.env.REACT_APP_SSO_KEY || null;
const SSO_HOMEPAGE = process.env.REACT_APP_SSO_HOMEPAGE || null;

const testJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

const isSSOLogin = (setLoadingSubmit, cookie, cookieAcc, cookiePhone) => {
  try {
    const isEmailSSO = cookieAcc ? window.atob(cookieAcc) : null;
    const isHPSSO = cookiePhone ? window.atob(cookiePhone) : null;
    if (IS_SSO) {
      if (!cookie) {
        goONELNKHomapage();
        window.location.href = SSO_HOMEPAGE;
        return SSO_HOMEPAGE;
      }
      const decryptCookieSSO = window.atob(cookie);
      if (!decryptCookieSSO) {
        goONELNKHomapage();
        window.location.href = SSO_HOMEPAGE;
        return SSO_HOMEPAGE;
      }
      const tokenAccSSO = testJSON(decryptCookieSSO)
        ? JSON.parse(decryptCookieSSO)
        : null;
      if (!tokenAccSSO?.sso_key) {
        goONELNKHomapage();
        window.location.href = SSO_HOMEPAGE;
        return SSO_HOMEPAGE;
      }
      if (tokenAccSSO?.sso_key && tokenAccSSO?.sso_key !== SSO_KEY) {
        goONELNKHomapage();
        window.location.href = SSO_HOMEPAGE;
        return SSO_HOMEPAGE;
      }

      const decodeToken = tokenAccSSO
        ? jwtDecode(tokenAccSSO?.accessToken)
        : null;
      //  isEmailSSo then get accessToken
      if (isEmailSSO && decodeToken && !accessToken) {
        setLoadingSubmit(true);
        const parseEmail = JSON.parse(isEmailSSO);
        const newPayload = {
          name: parseEmail?.name,
          email: parseEmail?.idTokenClaims?.preferred_username,
          account_id: parseEmail?.localAccountId,
          username: parseEmail?.localAccountId,
        };
        return loginSSO(newPayload)
          .then(async (res) => {
            const verifyToken = jwtDecode(res.accessToken);
            localStorage.setItem("accessToken", JSON.stringify(res));
            localStorage.setItem("user", JSON.stringify(verifyToken));
            let role = "Guest User";
            if (verifyToken.details.hris_org_tree.current_person) {
              role = "".concat(
                verifyToken.details.hris_org_tree.current_person
                  .nama_department,
                " ",
                verifyToken.details.hris_org_tree.current_person.nama_posisi
              );
              localStorage.setItem("roles", JSON.stringify(role));

              // get menu
              await getMenu(role).then(() => {
                localStorage.setItem("photo", JSON.stringify(res?.photo));
                window.location.reload();
              });
            }
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      }

      // login by phone
      if (
        !isEmailSSO &&
        isHPSSO &&
        decodeToken &&
        !accessToken &&
        tokenAccSSO?.sso_key
      ) {
        setLoadingSubmit(true);

        const parseEmail = JSON.parse(isHPSSO);
        return loginPhone(parseEmail)
          .then(async (res) => {
            const verifyToken = jwtDecode(res.accessToken);
            localStorage.setItem("accessToken", JSON.stringify(res));
            localStorage.setItem("user", JSON.stringify(verifyToken));
            let role = "Guest User";
            if (verifyToken.details.hris_org_tree.current_person) {
              role = "".concat(
                verifyToken.details.hris_org_tree.current_person
                  .nama_department,
                " ",
                verifyToken.details.hris_org_tree.current_person.nama_posisi
              );
              localStorage.setItem("roles", JSON.stringify(role));

              // get menu
              await getMenu(role).then(() => {
                window.location.reload();
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              heightAuto: false,
              title: "Warning!",
              text: err,
              icon: "info",
            });
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      }
      //  !isEmailSSo && !isHPSSO then get accessToken
      if (
        !isEmailSSO &&
        !isHPSSO &&
        decodeToken &&
        !accessToken &&
        tokenAccSSO?.sso_key
      ) {
        setLoadingSubmit(true);
        const accTokenParse = jwtDecode(tokenAccSSO.accessToken);
        return login(
          accTokenParse.username.toUpperCase(),
          accTokenParse.password
        )
          .then(async (res) => {
            const verifyToken = jwtDecode(res.accessToken);
            localStorage.setItem("accessToken", JSON.stringify(res));
            let role = "Guest User";
            if (verifyToken.details.hris_org_tree.current_person) {
              role = "".concat(
                verifyToken.details.hris_org_tree.current_person
                  .nama_department,
                " ",
                verifyToken.details.hris_org_tree.current_person.nama_posisi
              );
              localStorage.setItem("roles", JSON.stringify(role));

              // get menu
              await getMenu(role).then(() => {
                localStorage.setItem("photo", JSON.stringify(res?.photo));
                window.location.reload();
              });
            }
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      }
    }
    return IS_SSO;
  } catch (err) {
    return Swal.fire({
      heightAuto: false,
      title: "Warning!",
      text: err,
      icon: "info",
    });
  }
};
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: undefined,
      cookie: cookies.get(cookieNameGenerator("ssocookies")),
      cookieAcc: cookies.get(cookieNameGenerator("ssoaccount")),
      cookiePhone: cookies.get(cookieNameGenerator("ssophonenumber")),
      loadingPage: false,
    };

    history.listen((location) => {});
  }
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const menu = JSON.parse(localStorage.getItem("menu"));
    const { cookie, cookieAcc, cookiePhone } = this.state;

    if (user) {
      this.setState({
        currentUser: user,
        menu: menu,
      });
    }
    const setLoadingSubmit = (load) => {
      this.setState({
        loadingPage: load,
      });
    };
    const check = isSSOLogin(setLoadingSubmit, cookie, cookieAcc, cookiePhone);
    if (check) {
      history.push("/home");
    }
  }
  onModalLoadingHide = () => {
    this.setState({
      loadingPage: false,
    });
  };
  render() {
    const { menu, loadingPage } = this.state;

    return (
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <div>
          <Modal
            show={loadingPage}
            size="sm"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody
              style={{ backgroundColor: "#007bff", color: "white" }}
              className="text-center"
            >
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span className="sr-only">Loading...</span>
              </button>
            </ModalBody>
          </Modal>

          <Switch>
            <PrivateRoute exact path={["/", "/home"]} component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute component={AllRoute} getMenu={menu} />
            <Redirect from="*" to="/" />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
