import React from "react";
import moment from "moment";
import thousandformat from "../helpers/thousanddelimiter";
import DecimalPrecision from "../helpers/decimalprecision";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const CheckNeedTable = ({ num_month, data, otherClass }) => {
  return (
    <div className="table-responsive" style={{ height: "960px" }}>
      <table
        className={
          otherClass
            ? otherClass
            : " table table-bordered table-striped table-compact"
        }
      >
        {data &&
          data.map((dt) => {
            return (
              <>
                <div style={{ backgroundColor: "#ffc0cb73" }}>
                  <tr>
                    <th style={{ width: "10%" }}>Source Material</th>
                    <th colspan={`${num_month.length + 1}`}>
                      {dt.parent_material}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    {num_month &&
                      num_month.map((nm) => {
                        return (
                          <th className="text-center">
                            {moment(nm).format("MMM-YY")}
                          </th>
                        );
                      })}
                  </tr>
                  <tr>
                    <th>Source Qty</th>
                    {dt.prev_data &&
                      dt.prev_data.map((pd) => {
                        return <th className="text-right">{pd}</th>;
                      })}
                  </tr>
                  <tr>
                    <th>Material Needs</th>
                    <th colspan={`${num_month.length + 1}`}>
                      {dt.material_description_rm}
                    </th>
                  </tr>
                  <tr>
                    <th>Qty Needs</th>
                    {dt.value_month &&
                      dt.value_month.map((pd) => {
                        return (
                          <th className="text-right">
                            {!isNaN(pd)
                              ? thousandformat(
                                  DecimalPrecision.round(pd, DECIMAL_PLACES),
                                  ".",
                                  ".",
                                  ","
                                )
                              : pd}
                          </th>
                        );
                      })}
                  </tr>
                </div>
                <br />
                <br />
              </>
            );
          })}
      </table>
    </div>
  );
};
export default CheckNeedTable;
