import TollOutPriceService from '../services/toll_out_price.service'

export const readTollOutPrice = (query_string) => {
  return TollOutPriceService.readTollOutPrice(query_string).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
