import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class CostRates {

    create(payload) {
        return axios
            .post(API_URL_DEFAULT + "cost_rates", payload, { headers: authHeader() });
    }
    overwrite(payload) {
        return axios
            .post(API_URL_DEFAULT + "cost_rates/?type=overwrite", payload, { headers: authHeader() });
    }
    readCostRates(query_string) {
        return axios
            .get(API_URL_DEFAULT + "cost_rates/?" + query_string, { headers: authHeader() });
    }
    edit(payload) {
        return axios
            .put(API_URL_DEFAULT + "cost_rates", payload, { headers: authHeader() });
    }

    delete(payload) {
        return axios
            .delete(API_URL_DEFAULT + "cost_rates", { data: payload, headers: authHeader() });
    }
}

export default new CostRates();
