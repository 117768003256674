import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class TollOutVendorPriceService {

    readTollOutByVendor(query_string) {
        return axios
            .get(API_URL_DEFAULT + "toll_out_vendor_price?"+query_string, { headers: authHeader() });
    }

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "toll_out_vendor_price", payload, { headers: authHeader() });
  }
  createOverwrite(payload) {
    return axios
      .post(API_URL_DEFAULT + "toll_out_vendor_price/?type=overwrite", payload, { headers: authHeader() });
  }
  readByMonthTollOutVendorPrice(qs) {
    return axios
      .get(API_URL_DEFAULT + "toll_out_vendor_price/?readbymonth=true&" + qs, { headers: authHeader() });
  }
  readSalesForecas(query_string) {
    return axios
      .get(API_URL_DEFAULT + "toll_out_vendor_price?" + query_string, { headers: authHeader() });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "toll_out_vendor_price", payload, { headers: authHeader() });
  }
  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "toll_out_vendor_price", { data: payload, headers: authHeader() });
  }

}

export default new TollOutVendorPriceService();
