import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Check from "react-bootstrap/FormCheck";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RemoteAll from "../components/RemoteAll";
import RemoteTable from "../components/RemoteTable";
import capitalize from "../helpers/capitalize";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

import { readCostRates, addCostRates } from "../modules/CostRatesModule";
import { readPriceListRnd } from "../modules/PriceListRndModule";
import { readBOMFG, addBOMFG } from "../modules/BOMFGModule";
import detectFloat from "../helpers/detectFloat";
import DecimalPrecision from "../helpers/decimalprecision";
import {
  addMonths,
  differenceInMonths,
  startOfMonth,
  subMonths,
} from "date-fns";
import { th } from "date-fns/locale";
import PriceListMonthlyTable from "../components/PriceListMonthlyTable";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class PriceListMonthly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      id: null,
      filename: null,
      excel: null,
      worksheet: [],
      worksheet_detail: [],
      requester: null,
      cols_length: null,
      material_description: null,
      no_material: null,
      date_first: null,
      date_second: null,
      date_first_formatted: null,
      date_second_formatted: null,
      date: null,
      plan_date: null,
      columns: null,
      list_datatable: null,
      search: null,
      price_type: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "no_material",
      sortOrder: "asc",
      defaultSorted: [],
      cellEditProps: {},
      isSearchFound: true,
      link: `${process.env.PUBLIC_URL}/bom_fg.xlsx`,
      errorMessage: null,
      template_price_list: [
        ["No", "Material Code", "Material Name", "Facility", "Status"],
      ],
    };
  }

  columns_default = [
    {
      dataField: "account",
      text: "Account",
      classes: "text-center",
      sort: true,
    },
    {
      dataField: "facility",
      text: "Facility",
      classes: "text-center",
      sort: true,
    },
    {
      dataField: "unit",
      text: "Unit",
      classes: "text-center",
      sort: true,
    },
  ];

  select_items_type = [
    { value: "cogm", label: "COGM" },
    { value: "cogs", label: "COGS" },
    { value: "lowest_price_ndc", label: "Lowest Price NDC" },
    { value: "lowest_price_foamer", label: "Lowest Price Foamer" },
    { value: "lowest_price_ldr", label: "Lowest Price LDR" },
    { value: "lowest_price_premix", label: "Lowest Price Premix" },
    { value: "lowest_price_fc", label: "Lowest Price FC" },
    { value: "margin_upper_1000", label: "> 1000 MT" },
    { value: "margin_500_1000", label: "500-1000 MT" },
    { value: "margin_100_500", label: "100-500 MT" },
    { value: "margin_50_100", label: "50-100 MT" },
    { value: "margin_10_50", label: "10-50 MT" },
    { value: "margin_under_10", label: "< 10 MT" },
  ];

  //render table based on first date and second date
  renderDatatable(date_first, date_second) {
    let columns = [...this.columns_default];
    var diffMonth = differenceInMonths(date_second, date_first) + 1;
    for (let i = 0; i <= diffMonth; i++) {
      columns = [
        ...columns,
        {
          dataField: "value" + i,
          text: dateFormat(addMonths(date_first, i), "mmm-yy"),
          classes: "text-center",
          sort: true,
        },
      ];
    }

    this.setState({
      date_first: date_first,
      date_second: date_second,
      columns: columns,
    });
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    const date_first = new Date();
    const date_second = startOfMonth(new Date());
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `first=${date_first_formatted}&second=${date_second_formatted}`;

    this.setState({
      plan_date: startOfMonth(new Date()),
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState({
      [name]: value,
      list_datatable: null,
      isSearchFound: false,
      errorMessage: null,
    });
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  //action button
  GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailBOMFG(
              row._id,
              row.no_material,
              row.material_description,
              row.data,
              row.cols
            )
          }
        >
          <i className="fas fa-th" key={row._id}></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetailBOMFG = (id, no_material, material_description, data, cols) => {
    const worksheet_detail = [
      {
        worksheet: no_material + "_" + material_description,
        data: data,
        cols: cols,
      },
    ];
    this.setState(
      {
        id: id,
        worksheet_detail: worksheet_detail,
        submitted: false,
        no_material: no_material,
        material_description: material_description,
      },
      () => {
        this.toggle();
      }
    );
  };

  onChangeHandlerFile = (event) => {
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });

      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (
    data,
    cols,
    start_row = 0,
    allowed_cols = null,
    row_header_case = null,
    row_header_value = null
  ) => {
    let header = [];
    let detail = [];
    let row_header = [];
    let row_detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          if (allowed_cols) {
            let z_clone = [...r];
            let splice_custom = z_clone.splice(0, allowed_cols);
            header.push(splice_custom);
          } else {
            header.push(header_concat);
          }
        }
        if (start_row !== 0 && i < start_row) {
          let z_clone = [...r];
          if (row_header_case === i) {
            row_header.push(z_clone);
          }
          if (row_header_value === i) {
            row_detail.push(z_clone);
          }
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== start_row && cleanArray.length > 0) {
          let abc = z.map((o) => {
            return o;
          });

          let array_concat = splice.concat(abc);
          detail.push(array_concat);
        }
      });
    }
    return {
      header: header,
      detail: detail,
      special_case: { header: row_header, detail: row_detail },
    };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);
    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      worksheet_detail: null,
      no_material: null,
      material_description: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //capitalize first word

  //validate input
  validateForm = (plan_date, date) => {
    let errors = this.state.errors;
    if (!plan_date) {
      errors.plan_date = "This field is required";
    }
    if (!date) {
      errors.date = "This field is required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage, date_first, date_second } =
      this.state;
    const query_string = `first=${date_first}&second=${date_second}`;

    this.form.reset();
    this.setState({
      worksheet_detail: [],
      worksheet: [],
      submitted: false,
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
      list_datatable: null,
      isSearchFound: false,
      isDownloadable: true,
      errorMessage: null,
    });
  };

  exportFile = () => {
    /* convert state to workbook */
    const { plan_date, price_type, template_price_list } = this.state;
    const plan_date_formatted = dateFormat(new Date(plan_date), "mmm-yy");
    let data = [];
    data = [...template_price_list];

    for (let i = 0; i < 6; i++) {
      data[0] = [
        ...data[0],
        dateFormat(addMonths(new Date(plan_date), i), "mmm-yy"),
      ];
    }
    //inserting data
    this.state.list_datatable.map((item, idx) => {
      let temp = [];
      temp.push(idx + 1);
      temp.push(item.material_code);
      temp.push(item.material_name);
      temp.push(item.plant ? `${item.type} ${item.plant}` : item.type);
      temp.push(item.status);
      temp.push(
        isNaN(parseFloat(item.val0)) ? "" : item.val0.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.val1)) ? "" : item.val1.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.val2)) ? "" : item.val2.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.val3)) ? "" : item.val3.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.val4)) ? "" : item.val4.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.val5)) ? "" : item.val5.toFixed(DECIMAL_PLACES)
      );

      data = [...data, temp];
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      `price_list_monthly_version_${plan_date_formatted}_${price_type}.xlsx`
    );
  };

  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester, department, excel, selectedFile, worksheet, date } =
      this.state;
    if (!this.validateForm(selectedFile, worksheet)) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      excel: excel,
    };

    if (requester && department && excel && worksheet.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addCostRates(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-cost-rates-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
                history.push("/price-list/cost-rates");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-cost-rates-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSearch = () => {
    const { plan_date, price_type, search } = this.state;
    const plan_date_formatted = dateFormat(plan_date, "yyyy-mm-dd");
    let query_string = `planning_month=${plan_date_formatted}&month=${plan_date_formatted}&read_type=${price_type}&search=${search}`;
    readPriceListRnd(query_string)
      .then((response) => {
        this.setState({
          list_datatable: response,
          isSearchFound: true,
        });
      })
      .catch((e) => {
        this.setState({
          list_datatable: null,
          isSearchFound: false,
          errorMessage: e,
        });
      });
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
    } else {
      this.setState({
        [name]: null,
      });
    }
  };

  render() {
    let {
      showModal,
      id,
      excel,
      worksheet,
      errors,
      submitted,
      loading,
      isDownloadable,
      error_message_filename,
      isCreate,
      isRead,
      list_datatable,
      page,
      sizePerPage,
      totalSize,
      columns,
      defaultSorted,
      cellEditProps,
      link,
      worksheet_detail,
      material_description,
      no_material,
      date_first,
      date_second,
      date,
      search,
      plan_date,
      isSearchFound,
      price_type,
      errorMessage,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                Detail {no_material} {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Worksheet worksheet={worksheet_detail} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Price List Monthly RND</h3>
                        </div>
                        <div className="card-body">
                          <Row
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col xs={12} md={3}>
                              <div
                                className={
                                  errors.plan_date != "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label htmlFor="date">
                                  Choose Planning Month *
                                </label>
                                <DatePicker
                                  selected={plan_date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(
                                    this,
                                    "plan_date"
                                  )}
                                  name="plan_date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                                {errors.plan_date && (
                                  <div className="invalid-feedback">
                                    {errors.date}
                                  </div>
                                )}
                              </div>
                              <div
                                className={
                                  errors.material != "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label htmlFor="price_type">Price Type *</label>
                                <Select
                                  onChange={this.handleChange.bind(
                                    this,
                                    "price_type"
                                  )}
                                  options={this.select_items_type}
                                  value={
                                    this.select_items_type.filter(
                                      (e) => e.value === price_type
                                    ) || ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <label htmlFor="price_type">
                            Filter by Material Code/Name
                          </label>
                          <Row>
                            <Col xs={12} md={3}>
                              <div className="form-group">
                                <input
                                  className="form-control form-control-sm"
                                  name="search"
                                  value={search || ""}
                                  placeholder="Search ..."
                                  onChange={this.onChangeValue}
                                />
                              </div>
                            </Col>
                          </Row>
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <div
                              data-tip="Please choose price type"
                              data-tip-disable={
                                this.state.price_type !== "" &&
                                this.state.price_type !== null
                              }
                            >
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                className="float-right"
                                disabled={
                                  this.state.price_type === "" ||
                                  this.state.price_type === null
                                }
                              >
                                <i className="fas fa-search" /> Show
                              </Button>
                            </div>

                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="float"
                            />
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && list_datatable && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Price List Table &nbsp;
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        {/* {isSearchFound && list_datatable.length > 0 && */}
                        {/* ( */}
                        <div className="card-body">
                          <div className="row-md-12">
                            <h5>
                              Version{" "}
                              {dateFormat(new Date(plan_date), "mmm-yy")}_
                              {price_type}
                            </h5>
                            <Button
                              variant="success"
                              onClick={this.exportFile}
                              className="float-right"
                              style={{ justifyContent: "space-between" }}
                            >
                              <i className="fas fa-download" /> Export to Excel
                            </Button>
                          </div>
                          <div>
                            <PriceListMonthlyTable
                              data={list_datatable}
                              planning_month={plan_date}
                            />
                          </div>
                        </div>
                        {/* ) */}
                        {/* } */}
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
                {!isSearchFound && errorMessage && (
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={12}>
                                <h3
                                  style={{ color: "red" }}
                                  className="text-center"
                                >
                                  {" "}
                                  {errorMessage}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(PriceListMonthly);
