import CurrencyService from "../services/currency.service";
export const readCurrencyWithoutRP = (query_string) =>  {
  return CurrencyService.readCurrencyWithoutRP(query_string).then(
    (response) => {  
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 
      return Promise.reject(message);
    }
  );
}; 
export const readCurrency = () =>  {
  return CurrencyService.readCurrency().then(
    (response) => {  
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 
      return Promise.reject(message);
    }
  );
}; 