import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import Footer from '../../components/templates/Footer';
import Header from '../../components/templates/Header';
import SideMenu from '../../components/templates/SideMenu';
import 'react-toastify/dist/ReactToastify.min.css';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { monitoringScheduler } from '../../modules/ninda/MonitoringSchedulerModule';


const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");

class TriggerPowderPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planning_month: null,
      selectedType: null,
      file_private_key: null,
      current_filename: null,
      user: null
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      user: user.username,
      selectedType: "powder_price"
    });
  }

  handleDate = (date) => {
    this.setState({
      planning_month: date
    })
  }

  handleTrigger = async (e) => {
    const {
      planning_month,
      selectedType,
      user,
      file_private_key
    } = this.state;
    if (planning_month === null) {
      return Swal.fire("Warning", "Please choose planning month", "warning");
    }
    if (selectedType === null) {
      return Swal.fire("Warning", "Please choose scheduler type", "warning");
    }
    let body = {
      private_key: file_private_key,
      planning_month: planning_month,
      type: selectedType,
      requester: user
    };
    await monitoringScheduler(body).then((res) => {
      Swal.fire("Success", res.message, "success")
    }).catch((err) => {
      Swal.fire("Error", err, "error")
    });
  }

  onChangeHandlerFile = (e) => {
    const file = e.target.files;
    if (file && file[0]) {
      this.handlerFile(file[0]);
    }
  };

  handlerFile = (file) => {
    const reader = new FileReader();
    reader.onload = this.handleFilename.bind(reader.result, file);
    reader.readAsText(file);
  };

  handleFilename = (file, e) => {
    this.setState({
      file_private_key: e.target.result,
      current_filename: file.name
    });
  };

  render() {
    let {
      planning_month,
      selectedType,
      file_private_key,
      current_filename
    } = this.state

    return (
      <div className='sidebar-mini sidebar-collapse text-sm'>
        <div className='wrapper'>
          <ToastContainer />

          <Header />
          <SideMenu />
          <div className='content-wrapper'>
            <section className='content'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='card card-info mt-3'>
                      <div className='card-header'>
                        <h3 className='card-title'>Trigger Powder Price</h3>
                      </div>
                      <div className='card-body'>
                        <div className='form-group'>
                          <label className='col-md-5 col-form-label'>Planning Month</label>
                          <div className='input-group col-md-6'>
                            <DatePicker
                              selected={planning_month}
                              startDate={planning_month}
                              disabledKeyboardNavigation
                              onChange={this.handleDate.bind(this)}
                              name='date'
                              wrapperClassName='datePickerCustom'
                              dateFormat='MMMM yyyy'
                              className='form-control'
                              required
                              showMonthYearPicker
                              autoComplete='off'
                            />
                            <span className='error invalid-feedback'>Please entries planning month</span>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-6 col-form-label'>Private Key</label>
                          <div className='input-group col-md-6'>
                            <div className='custom-file' style={{ cursor: 'pointer' }}>
                              <input
                                type="file"
                                className='custom-file-input'
                                id='exampleInputFile'
                                accept={SheetJSFT}
                                onChange={this.onChangeHandlerFile.bind(this)}
                              />
                              <label className='custom-file-label' htmlFor='exampleInputFile'>{current_filename}</label>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex form-group justify-content-end'>
                          <Button variant='info' className='col-md-3 mr-2 loading' onClick={this.handleTrigger}>Trigger</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(TriggerPowderPrice);