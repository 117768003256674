import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Check from "react-bootstrap/FormCheck";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RemoteAll from "../components/RemoteAll";
import capitalize from "../helpers/capitalize";
import { readBOMPowder, addBOMPowder } from "../modules/BOMPowderPriceModule";

import { sendNotification } from "../modules/NotificationModule";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");
class BOMPowder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isClearable: true,
      collection: {},
      errors: {},
      id: null,
      template_weekly: [
        [
          "no_material",
          "powder",
          "week_1",
          "week_2",
          "week_3",
          "week_4",
          "week_5",
        ],
      ],
      template_monthly: [
        [
          "no_material",
          "powder",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      ],
      filename: null,
      periode: null,
      excel: null,
      worksheet: [],
      requester: null,
      cols_length: null,
      search: null,
      filter_by: null,
      columns_weekly: [
        {
          dataField: "no_material",
          text: "No Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "powder",
          text: "Powder",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "month",
          text: "Month",
          formatter: this.GetMonthYear,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "week_1",
          text: "1",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "week_2",
          text: "2",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "week_3",
          text: "3",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "week_4",
          text: "4",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "week_5",
          text: "5",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
      ],
      list_datatable_weekly: [],
      page_weekly: 1,
      sizePerPage_weekly: 10,
      totalSize_weekly: 10,
      sortBy_weekly: "no_material",
      sortOrder_weekly: "asc",
      columns_monthly: [
        {
          dataField: "no_material",
          text: "No Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "powder",
          text: "Powder",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "year",
          text: "Year",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "January",
          text: "January",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "February",
          text: "February",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "March",
          text: "March",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "April",
          text: "April",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "May",
          text: "May",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "June",
          text: "June",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "July",
          text: "July",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "August",
          text: "August",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "September",
          text: "September",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "October",
          text: "October",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "November",
          text: "November",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "December",
          text: "December",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
      ],
      list_datatable_monthly: [],
      page_monthly: 1,
      sizePerPage_monthly: 10,
      totalSize_monthly: 10,
      sortBy_monthly: "no_material",
      sortOrder_monthly: "asc",
      link_weekly: `${process.env.PUBLIC_URL}/weekly.xlsx`,
      link_monthly: `${process.env.PUBLIC_URL}/monthly.xlsx`,
      defaultSorted: [],
      cellEditProps: {},
      filter_date: null,
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
    const {
      sortBy_weekly,
      sortOrder_weekly,
      page_weekly,
      sizePerPage_weekly,
      sortBy_monthly,
      sortOrder_monthly,
      page_monthly,
      sizePerPage_monthly,
    } = this.state;
    const periode_weekly = "weekly";
    const periode_monthly = "monthly";
    const query_string_monthly = `periode=${periode_monthly}&sortBy=${sortBy_monthly}&sortOrder=${sortOrder_monthly}&page=${page_monthly}&sizePerPage=${sizePerPage_monthly}&year=${dateFormat(
      new Date(),
      "yyyy"
    )}`;
    const query_string_weekly = `periode=${periode_weekly}&sortBy=${sortBy_weekly}&sortOrder=${sortOrder_weekly}&page=${page_weekly}&sizePerPage=${sizePerPage_weekly}&year=${dateFormat(
      new Date(),
      "yyyy"
    )}`;
    //monthly
    readBOMPowder(query_string_monthly).then((response) => {
      this.setState({
        list_datatable_monthly: response.foundData ? response.foundData : [],
        totalSize_monthly: response.foundData ? response.countData : 0,
        page_monthly: response.foundData ? response.currentPage : 1,
      });
    });
    //weekly
    readBOMPowder(query_string_weekly).then((response) => {
      this.setState({
        list_datatable_weekly: response.foundData ? response.foundData : [],
        totalSize_weekly: response.foundData ? response.countData : 0,
        page_weekly: response.foundData ? response.currentPage : 1,
      });
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
    this.setState({
      filter_date: new Date(),
    });
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{isNaN(cell) ? "" : thousandformat(cell, ".", ".", ",")}</div>;
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetail = (id) => {
    this.setState(
      {
        id: id,

        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };

  onChangeHandlerFile = (event) => {
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });
      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (data, cols, start_row = 0, allowed_cols = []) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== start_row && cleanArray.length > 2) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }
    return { header: header, detail: detail };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */

    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };

  exportFile = () => {
    /* convert state to workbook */
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date();
    let sheet = monthNames[date.getMonth()] + "_" + date.getFullYear();

    const data = this.state.template_weekly;
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "weekly.xlsx");
  };

  exportFileMonthly = () => {
    /* convert state to workbook */

    let date = new Date();
    let sheet = date.getFullYear().toString();
    const data = this.state.template_monthly;
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "monthly.xlsx");
  };

  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      opening_rate: null,
      closing_rate: null,
      date: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //capitalize first word

  //validate input
  validateForm = (file, periode, worksheet) => {
    let errors = this.state.errors;
    if (!file) {
      errors.file = "This field is required";
    }
    if (!periode) {
      errors.periode = "This field is required";
    }
    if (worksheet.length != 1 && file) {
      errors.worksheet =
        "Please upload excel according to the template that is already available!";
    }
    //check worksheet name
    if (worksheet.length == 1 && file && periode) {
      //weekly
      if (periode == "weekly") {
        let ws_name = worksheet[0].worksheet;
        let ws_name_split = ws_name.split("_");
        if (ws_name_split.length != 2) {
          errors.filename =
            "Please fill name of worksheet with this format:(month_year) (in English)";
          this.setState({
            worksheet_name: false,
          });
        }
        if (
          ws_name_split.length == 2 &&
          !ws_name.match("^[A-Za-z]+_+[0-9]*$")
        ) {
          errors.filename =
            "Please fill name of worksheet with this format:(month_year) (in English)";
          this.setState({
            worksheet_name: false,
          });
        }
        if (ws_name_split.length == 2 && ws_name.match("^[A-Za-z]+_+[0-9]*$")) {
          const array_month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          if (
            !array_month.includes(capitalize(ws_name_split[0].toLowerCase()))
          ) {
            errors.filename =
              "Please fill name of worksheet  with this format:(month_year) (in English)";
            this.setState({
              worksheet_name: false,
            });
          }
        }
      }
      //monthly
      if (periode == "monthly") {
        const ws_name = worksheet[0].worksheet;
        if (!ws_name.match("^[0-9]*$")) {
          errors.filename = "Please fill name of worksheet with year only";
          this.setState({
            worksheet_name: false,
          });
        }
      }
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      sortBy_weekly,
      sortOrder_weekly,
      page_weekly,
      sizePerPage_weekly,
      sortBy_monthly,
      sortOrder_monthly,
      page_monthly,
      sizePerPage_monthly,
      filter_date,
    } = this.state;
    let date_filter = dateFormat(filter_date, "yyyy");
    const periode_weekly = "weekly";
    const periode_monthly = "monthly";
    const query_string_monthly = `periode=${periode_monthly}&sortBy=${sortBy_monthly}&sortOrder=${sortOrder_monthly}&page=${page_monthly}&sizePerPage=${sizePerPage_monthly}&year=${date_filter}`;
    const query_string_weekly = `periode=${periode_weekly}&sortBy=${sortBy_weekly}&sortOrder=${sortOrder_weekly}&page=${page_weekly}&sizePerPage=${sizePerPage_weekly}&year=${date_filter}`;
    //monthly

    this.form.reset();
    readBOMPowder(query_string_monthly).then((response) => {
      this.setState({
        list_datatable_monthly: response.foundData ? response.foundData : [],
        totalSize_monthly: response.foundData ? response.countData : 0,
        page_monthly: response.foundData ? response.currentPage : 1,

        selectedFile: null,
        periode: null,
        submitted: false,
        worksheet: [],
      });
    });
    //weekly
    readBOMPowder(query_string_weekly).then((response) => {
      this.setState({
        list_datatable_weekly: response.foundData ? response.foundData : [],
        totalSize_weekly: response.foundData ? response.countData : 0,
        page_weekly: response.foundData ? response.currentPage : 1,
        selectedFile: null,
        periode: null,
      });
    });
  };
  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "powder_bom",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      excel,
      periode,
      selectedFile,
      worksheet,
      errors,
    } = this.state;
    if (!this.validateForm(selectedFile, periode, worksheet)) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      excel: excel,
      periode: periode,
    };

    if (
      requester &&
      department &&
      excel &&
      periode &&
      worksheet.length == 1 &&
      !errors.filename
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addBOMPowder(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  //handletable
  handleTableChangeWeekly = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage_weekly: sizePerPage,
      page_weekly: page,
    });
    const { filter_by, filter_date, search } = this.state;
    let date_filter = dateFormat(filter_date, "yyyy");

    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      no_material: "no_material",
      powder: "powder",
      week_1: "week_1",
      week_2: "week_2",
      week_3: "week_3",
      week_4: "week_4",
      week_5: "week_5",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }
    const periode_weekly = "weekly";
    let query_string_weekly = `periode=${periode_weekly}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&filterBy=${filter_by}&search=${search}&year=${date_filter}`;

    if (filterColumn) {
      query_string_weekly += "&" + array_filter.join("&");
    }
    //weekly
    readBOMPowder(query_string_weekly).then((response) => {
      this.setState({
        list_datatable_weekly: response.foundData ? response.foundData : [],
        totalSize_weekly: response.foundData ? response.countData : 0,
        page_weekly: response.foundData ? response.currentPage : 1,
      });
    });
  };
  handleTableChangeMonthly = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage_monthly: sizePerPage,
      page_monthly: page,
    });
    const { filter_by, filter_date, search } = this.state;
    let date_filter = dateFormat(filter_date, "yyyy");
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      no_material: "no_material",
      powder: "powder",
      year: "year",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }

    const periode_monthly = "monthly";
    let query_string_monthly = `periode=${periode_monthly}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&filterBy=${filter_by}&search=${search}&year=${date_filter}`;

    if (filterColumn) {
      query_string_monthly += "&" + array_filter.join("&");
    }
    //monthly
    readBOMPowder(query_string_monthly).then((response) => {
      this.setState({
        list_datatable_monthly: response.foundData ? response.foundData : [],
        totalSize_monthly: response.foundData ? response.countData : 0,
        page_monthly: response.foundData ? response.currentPage : 1,
      });
    });
  };

  handleSearch = () => {
    const {
      sortBy_weekly,
      sortOrder_weekly,
      page_weekly,
      sizePerPage_weekly,
      sortBy_monthly,
      sortOrder_monthly,
      page_monthly,
      sizePerPage_monthly,
      filter_by,
      filter_date,
      search,
    } = this.state;
    const periode_weekly = "weekly";
    const periode_monthly = "monthly";
    let date_filter = dateFormat(filter_date, "yyyy");
    const query_string_monthly = `periode=${periode_monthly}&sortBy=${sortBy_monthly}&sortOrder=${sortOrder_monthly}&page=${page_monthly}&sizePerPage=${sizePerPage_monthly}&filterBy=${filter_by}&search=${search}&year=${date_filter}`;
    const query_string_weekly = `periode=${periode_weekly}&sortBy=${sortBy_weekly}&sortOrder=${sortOrder_weekly}&page=${page_weekly}&sizePerPage=${sizePerPage_weekly}&filterBy=${filter_by}&search=${search}&year=${date_filter}`;

    //monthly
    readBOMPowder(query_string_monthly).then((response) => {
      this.setState({
        list_datatable_monthly: response.foundData ? response.foundData : [],
        totalSize_monthly: response.foundData ? response.countData : 0,
        page_monthly: response.foundData ? response.currentPage : 1,

        selectedFile: null,
        periode: null,
        submitted: false,
        worksheet: [],
      });
    });
    //weekly
    readBOMPowder(query_string_weekly).then((response) => {
      this.setState({
        list_datatable_weekly: response.foundData ? response.foundData : [],
        totalSize_weekly: response.foundData ? response.countData : 0,
        page_weekly: response.foundData ? response.currentPage : 1,
        selectedFile: null,
        periode: null,
      });
    });
  };

  render() {
    let {
      disableButtonNotification,
      seconds,
      showModal,
      id,
      excel,
      worksheet,
      errors,
      submitted,
      loading,
      error_message_filename,
      isCreate,
      isRead,
      link_weekly,
      link_monthly,
      list_datatable_weekly,
      page_weekly,
      sizePerPage_weekly,
      totalSize_weekly,
      columns_weekly,
      defaultSorted_weekly,
      list_datatable_monthly,
      page_monthly,
      sizePerPage_monthly,
      totalSize_monthly,
      columns_monthly,
      defaultSorted_monthly,
      cellEditProps,
      periode,
      search,
      filter_by,
      filter_date,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">BOM Powder Price</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={6}>
                                <div className="form-group row">
                                  <div className="col-md-3 col-xs-12">
                                    <label htmlFor="periode">Periode *</label>
                                  </div>
                                  <div className="col-md-9 col-xs-12">
                                    <div key="inline-radio" className="mb-3">
                                      <Check
                                        inline
                                        label="Weekly"
                                        name="periode"
                                        type="radio"
                                        value="weekly"
                                        required
                                        onChange={(e) => {
                                          this.onChangeValue({
                                            target: {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                          });
                                          this.setState({ errors: {} });
                                        }}
                                        checked={periode === "weekly"}
                                      />
                                      <Check
                                        inline
                                        label="Monthly"
                                        name="periode"
                                        type="radio"
                                        value="monthly"
                                        required
                                        onChange={(e) => {
                                          this.onChangeValue({
                                            target: {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                          });
                                          this.setState({ errors: {} });
                                        }}
                                        checked={periode === "monthly"}
                                      />

                                      {errors.periode && (
                                        <em
                                          className="error-message"
                                          style={{
                                            fontSize: "80%",
                                          }}
                                        >
                                          {errors.periode}
                                        </em>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} md={2}></Col>
                              <Col xs={12} md={4}>
                                <Button variant="primary">
                                  <a
                                    onClick={this.exportFile}
                                    className="fas fa-download"
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    Download Template Weekly
                                  </a>
                                </Button>
                                &nbsp;
                                <Button variant="primary">
                                  <a
                                    onClick={this.exportFileMonthly}
                                    className="fas fa-download"
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    Download Template Monthly
                                  </a>
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <div className="form-group row">
                                  <div className="col-md-3 col-xs-12">
                                    <label htmlFor="file">
                                      Choose File (Excel) *
                                    </label>
                                  </div>
                                  <div className="col-md-9 col-xs-12">
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      name="file"
                                      placeholder="File"
                                      onChange={this.onChangeHandlerFile}
                                      accept={SheetJSFT}
                                      required
                                    />

                                    {errors.file && (
                                      <div className="invalid-feedback">
                                        {errors.file}
                                      </div>
                                    )}

                                    {error_message_filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {error_message_filename}
                                      </em>
                                    )}

                                    {errors.worksheet && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.worksheet}
                                      </em>
                                    )}
                                    {errors.filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.filename}
                                      </em>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs={12} md={6}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right  "
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                                <Button
                                  variant="info"
                                  onClick={this.handleNotification}
                                  className="float-right"
                                  disabled={disableButtonNotification}
                                >
                                  <i className="fab fa-telegram-plane" /> Send
                                  Notification{" "}
                                  {disableButtonNotification
                                    ? `(in ${seconds}s)`
                                    : ""}
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={1}>
                                <label htmlFor="filter_by">Filter By </label>
                                <div className="form-row form-group">
                                  <select
                                    className="form-control form-control-sm"
                                    name="filter_by"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={filter_by}
                                  >
                                    <option value="">Choose</option>
                                    <option value="no_material">
                                      No Material
                                    </option>
                                    <option value="powder_name">
                                      Powder Name
                                    </option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} md={2}>
                                <label htmlFor="filter_by">&nbsp;</label>
                                <div className="form-group">
                                  <input
                                    className="form-control form-control-sm"
                                    name="search"
                                    value={search || ""}
                                    placeholder="Search ..."
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    onChange={this.onChangeValue}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="filter_by">Year</label>
                                <div className="form-row form-group">
                                  <DatePicker
                                    selected={filter_date}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "filter_date"
                                    )}
                                    name="date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="yyyy"
                                    className="form-control form-control-sm"
                                    showYearPicker
                                    yearItemNumber={2}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="filter_by">&nbsp;</label>
                                <div className="form-row form-group">
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                    disabled={this.state.date === null}
                                  >
                                    <i className="fas fa-search" /> Filter
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                          <br></br>
                          <Tabs defaultActiveKey="weekly" variant="pills">
                            <Tab eventKey="weekly" title="Weekly">
                              <RemoteAll
                                data={list_datatable_weekly}
                                columns={columns_weekly}
                                defaultSorted={defaultSorted_weekly}
                                page={page_weekly}
                                sizePerPage={sizePerPage_weekly}
                                totalSize={totalSize_weekly}
                                cellEditProps={cellEditProps}
                                onTableChange={this.handleTableChangeWeekly.bind(
                                  this
                                )}
                              />
                            </Tab>
                            <Tab eventKey="monthly" title="Monthly">
                              <RemoteAll
                                data={list_datatable_monthly}
                                columns={columns_monthly}
                                defaultSorted={defaultSorted_monthly}
                                page={page_monthly}
                                sizePerPage={sizePerPage_monthly}
                                totalSize={totalSize_monthly}
                                cellEditProps={cellEditProps}
                                onTableChange={this.handleTableChangeMonthly.bind(
                                  this
                                )}
                              />
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(BOMPowder);
