import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";

import { withRouter } from "react-router-dom";

import styled, { css, createGlobalStyle } from "styled-components";

import DynamicTable from "../components/DynamicTableTollOutVendorPrice";
import ApsRpp from "../views/ApsRpp";

class ApsRppMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isValidMaterial: true,
      isValidCustomer: true,
      isValidMaterialCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isReadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_prod_facility: [],
      list_produksi: [],
      select_vendor_length: null,
      select_vendor_first_code: null,
      select_vendor_first_name: null,
      prod_facility_create: null,
      total_qty: null,
      type_create: null,
      list_datatable: [],
      errors: {},
      errors_create: {},
      plan_date: null,
      month: null,
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      segment: null,
      customer: null,
      customer_code: null,
      qty: null,
      unit: null,
      date: null,
      year: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      safety_stock_create: 0,
      cb_safety_stock_create: false,
      remaining_balance: 0,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "created_at",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "month",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  render() {
    let {
      loading,
      showModal,
      id,
      vendor_name,
      month,
      read_type,
      errors_create,
      submitted,
      data_toll_out_update,
      columns_toll_out,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : ""} Toll Out Vendor Price
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <label htmlFor="currency">Vendor</label>
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors_create.vendor != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          className="form-control form-control-sm"
                          name="vendor"
                          defaultValue={vendor_name}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <label htmlFor="currency">Month</label>
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors_create.date != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          className="form-control form-control-sm"
                          name="month_date"
                          value={month || ""}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={12}>
                      <DynamicTable
                        data={data_toll_out_update}
                        columns={columns_toll_out}
                        read_type={read_type}
                        onChange={(data) => {
                          this.setState({
                            data_toll_out_update: [...data],
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />

          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section>
              <ApsRpp></ApsRpp>
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(ApsRppMain);
