import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import ReactTooltip from "react-tooltip";

import { readPriceListRnd } from "../modules/PriceListRndModule";

import {
  addMonths,
  differenceInMonths,
  startOfMonth,
  subMonths,
} from "date-fns";
import { th } from "date-fns/locale";
import PriceListTable from "../components/PriceListBreakdownTable";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");
class PriceListBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      id: null,
      filename: null,
      excel: null,
      worksheet: [],
      worksheet_detail: [],
      requester: null,
      cols_length: null,
      material_description: null,
      no_material: null,
      date_first: null,
      date_second: null,
      date_first_formatted: null,
      date_second_formatted: null,
      date: null,
      plan_date: null,
      columns: null,
      list_datatable: null,
      filter_by: null,
      search: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "no_material",
      sortOrder: "asc",
      defaultSorted: [],
      cellEditProps: {},
      isSearchFound: true,
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    this.setState({
      plan_date: startOfMonth(new Date()),
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };

  //validate input
  validateForm = (plan_date, date) => {
    let errors = this.state.errors;
    if (!plan_date) {
      errors.plan_date = "This field is required";
    }
    if (!date) {
      errors.date = "This field is required";
    }

    return errors;
  };

  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
      isDownloadable: true,
    });
  };

  exportFile = () => {
    /* convert state to workbook */
    const { date, plan_date } = this.state;
    const data = [
      [
        "No",
        "Material Code",
        "Material Name",
        "Material Cons",
        "Material Description",
        "Plant",
        "Quantity",
        "Price",
        "",
        "",
        "",
        "",
        "",
        "Value",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
      ],
    ];
    let date_prod = dateFormat(
      this.state.list_datatable.production_date,
      "mmm-yy"
    );

    //merge cells
    let merge = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },

      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },

      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },

      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },

      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },

      { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },

      { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } },

      { s: { r: 0, c: 7 }, e: { r: 0, c: 11 } },

      { s: { r: 0, c: 13 }, e: { r: 0, c: 17 } },
    ];

    //inserting data
    this.state.list_datatable.material.map((item, idx) => {
      let temp = [];
      temp.push(idx + 1);
      temp.push(item.material_code);
      temp.push(item.material_name);
      temp.push(item.no_material_rm);
      temp.push(item.material_description_rm);

      temp.push(item.type);

      temp.push(
        isNaN(parseFloat(item.value0))
          ? ""
          : item.value0.toFixed(DECIMAL_PLACES)
      );

      temp.push(
        isNaN(parseFloat(item.price0))
          ? ""
          : item.price0.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.price1))
          ? ""
          : item.price1.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.price2))
          ? ""
          : item.price2.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.price3))
          ? ""
          : item.price3.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.price4))
          ? ""
          : item.price4.toFixed(DECIMAL_PLACES)
      );
      temp.push(
        isNaN(parseFloat(item.price5))
          ? ""
          : item.price5.toFixed(DECIMAL_PLACES)
      );

      temp.push(
        !isFinite(parseFloat(item.price0) * parseFloat(item.value0))
          ? ""
          : (item.price0 * item.value0).toFixed(DECIMAL_PLACES)
      );
      temp.push(
        !isFinite(parseFloat(item.price1) * parseFloat(item.value0))
          ? ""
          : (item.price1 * item.value0).toFixed(DECIMAL_PLACES)
      );
      temp.push(
        !isFinite(parseFloat(item.price2) * parseFloat(item.value0))
          ? ""
          : (item.price2 * item.value0).toFixed(DECIMAL_PLACES)
      );
      temp.push(
        !isFinite(parseFloat(item.price3) * parseFloat(item.value0))
          ? ""
          : (item.price3 * item.value0).toFixed(DECIMAL_PLACES)
      );
      temp.push(
        !isFinite(parseFloat(item.price4) * parseFloat(item.value0))
          ? ""
          : (item.price4 * item.value0).toFixed(DECIMAL_PLACES)
      );
      temp.push(
        !isFinite(parseFloat(item.price5) * parseFloat(item.value0))
          ? ""
          : (item.price5 * item.value0).toFixed(DECIMAL_PLACES)
      );

      data.push(temp);
    });

    // editing header
    let urutan = 7;
    let urutan_value = 13;
    this.state.list_datatable.month.map((x, i) => {
      data[1][urutan] = " " + dateFormat(x, "mmm-yy");
      data[1][urutan_value] = " " + dateFormat(x, "mmm-yy");
      urutan++;
      urutan_value++;
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!merges"] = merge;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      "price_list_breakdown_" + dateFormat(plan_date, "mmm-yy") + ".xlsx"
    );
  };

  handleSearch = () => {
    const { plan_date, date, filter_by, search } = this.state;

    const plan_date_formatted = dateFormat(plan_date, "yyyy-mm-dd");
    let query_string = `planning_month=${plan_date_formatted}&month=${plan_date_formatted}&read_raw=true&filter_by=${filter_by}&search=${search}`;
    readPriceListRnd(query_string)
      .then((response) => {
        this.setState({
          list_datatable: response,
          isSearchFound: true,
        });
      })
      .catch((e) => {
        this.setState({
          list_datatable: null,
          isSearchFound: false,
        });
      });
  };

  render() {
    let {
      worksheet,
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      plan_date,
      filter_by,
      search,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Price List Breakdown RND
                          </h3>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col
                              xs={12}
                              md={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  errors.plan_date != "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label htmlFor="date">
                                  Choose Planning Month *
                                </label>
                                <DatePicker
                                  selected={plan_date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(
                                    this,
                                    "plan_date"
                                  )}
                                  name="plan_date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                                {errors.plan_date && (
                                  <div className="invalid-feedback">
                                    {errors.date}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <label htmlFor="filter_by">Filter By </label>
                          <Row>
                            <Col xs={12} md={1}>
                              <div className="form-row form-group">
                                <select
                                  className="form-control form-control-sm"
                                  name="filter_by"
                                  onChange={this.onChangeValue}
                                  style={{ padding: 0 }}
                                  defaultValue={filter_by}
                                >
                                  <option value="">Choose</option>
                                  <option value="clasification">
                                    Clasification
                                  </option>
                                  <option value="material">
                                    Material Code/Name
                                  </option>
                                </select>
                              </div>
                            </Col>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <input
                                  className="form-control form-control-sm"
                                  name="search"
                                  value={search || ""}
                                  placeholder="Search ..."
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  onChange={this.onChangeValue}
                                />
                              </div>
                            </Col>
                            <Col>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                className="float-right"
                              >
                                <i className="fas fa-search" /> Show
                              </Button>
                            </Col>
                          </Row>
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Col>
                              <Row
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="float"
                                />
                              </Row>
                            </Col>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && list_datatable && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Price List Breakdown Table &nbsp;
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <div className="row-md-12">
                            <Button
                              variant="success"
                              onClick={this.exportFile}
                              className="float-right"
                              style={{ justifyContent: "space-between" }}
                            >
                              <i className="fas fa-download" /> Export to Excel
                            </Button>
                          </div>
                          <div>
                            <PriceListTable
                              data={list_datatable}
                              month={[plan_date]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(PriceListBreakdown);
