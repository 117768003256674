import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  Table,
  Row,
  Col,
  Container,
  Button,
  Tabs,
  Tab,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";
import SpotPrice from "../components/SpotPrice";
import Worksheet from "../components/Worksheet";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  readMaterial,
  readMaterialByType,
  readMaterialUomByCode,
} from "../modules/MaterialModule";
import { readVendor } from "../modules/VendorModule";
import { readUom } from "../modules/UomModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  addDailySpotPrice,
  addBulk,
  editDailySpotPrice,
  readDailySpotPrice,
  overwriteDailySpotPrice,
  readQsDailySpotPrice,
  readDailySpotPriceNewFormat,
} from "../modules/DailySpotPriceModule";
import RemoteAll from "../components/RemoteAll";
import XLSX from "xlsx";

const SheetJSFT = ["xlsx"].map((x) => `.${x}`).join(",");

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
const MAX_SIZE_FILE_UPLOAD = parseInt(
  process.env.REACT_APP_MAX_SIZE_FILE_UPLOAD
);
class DailySpotPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      readOnly: false,
      isValidMaterial: true,
      isValidVendor: true,
      isValidMaterialCreate: true,
      isValidVendorCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_vendor: [],
      select_items_uom: [],
      list_datatable: [],
      list_table: [],
      historical_data_new_format: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      material: null,
      material_search: null,
      material_code: null,
      vendor: null,
      vendor_code: null,
      currency: null,
      price: null,
      unit: null,
      date: null,
      isValidMaterial_create: false,
      material_create: null,
      material_create_code: null,
      vendor_create: null,
      vendor_create_code: null,
      currency_create: null,
      price_create: null,
      unit_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "spot_date",
      sortOrder: "desc",
      columns: [
        {
          dataField: "spot_date",
          text: "Spot Date",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "vendor_name",
          text: "Vendor",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "spot_price",
          text: "Spot Price",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "unit",
          text: "Unit",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      columns_new: [
        {
          type: "vendor",
          text: "Spot Vendor",
          classes: "text-center",
          sort: true,
        },
        {
          type: "spot_price",
          text: "Spot Price",
          classes: "text-center",
        },
      ],
      defaultSorted: [
        {
          dataField: "spot_date",
          order: "desc",
        },
      ],
      cellEditProps: {},
      isSearch: false,
      month_create: null,
      category_create: null,
      currency_bulk_create: null,
      isDisabled: true,
      worksheet: [],
      file_create: null,
      download_template_url: null,
      category: null,
      category_filter: null,
      date_first_filter: null,
      date_second_filter: null,
    };
    this.defaultState = this.state;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    readCurrencyWithoutRP().then((response) => {
      this.setState({ select_items: response });
    });

    readMaterialByType("ZRAW").then((response) => {
      this.setState({ select_items_material: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    readVendor().then((response) => {
      this.setState({ select_items_vendor: response });
    });
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const date_first = new Date();
    const date_second = subDays(new Date(), 7);
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `date_first=${date_second_formatted}&date_second=${date_first_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    //read data daily spot on backend
    readDailySpotPriceNewFormat(query_string).then((response) => {
      this.setState({
        list_table: response,
        isSearch: true,
      });
    });
    this.setState({
      date_create: new Date(),
      month_create: new Date(),
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      date_first_filter: new Date(),
      date_second_filter: subDays(new Date(), 7),
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    const { month_create } = this.state;

    if (name == "category_create") {
      if (value == "global") {
        this.setState({
          currency_bulk_create: "USD",
          isDisabled: false,
          download_template_url: `${
            process.env.REACT_APP_API
          }daily_spot_price/excel/?month=${dateFormat(
            month_create,
            "yyyy-mm-dd"
          )}&category=USD`,
        });
      } else if (value == "local") {
        this.setState({
          currency_bulk_create: "Rp",
          isDisabled: false,
          download_template_url: `${
            process.env.REACT_APP_API
          }daily_spot_price/excel/?month=${dateFormat(
            month_create,
            "yyyy-mm-dd"
          )}&category=Rp`,
        });
      } else {
        this.setState({
          currency_bulk_create: null,
          isDisabled: true,
          download_template_url: null,
        });
      }
    }
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    const { currency_bulk_create } = this.state;
    this.setState({
      [name]: date,
      download_template_url: `${
        process.env.REACT_APP_API
      }daily_spot_price/excel/?month=${dateFormat(
        date,
        "yyyy-mm-dd"
      )}&category=${currency_bulk_create}`,
    });
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: true,
        });
      } else if (name == "material_create") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            unit_create: response[0].value,
            isValidMaterialCreate: true,
          });
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: false,
        });
      } else if (name == "material_create") {
        this.refUnit.value = null;
        this.setState({
          isValidMaterialCreate: false,
          unit_create: null,
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: false,
        });
      }
    }
  };

  exportFile = () => {
    /* convert state to workbook */
    const {
      month_create,
      category_create,
      currency_bulk_create,
      select_items_vendor,
    } = this.state;

    let date = new Date(month_create);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let header = ["Vendor Name"];
    for (var i = firstDay; i <= lastDay; i.setDate(i.getDate() + 1)) {
      header.push(dateFormat(i, "yyyy-mm-dd"));
    }

    let data_vendor = [["Vendor Name"]];
    select_items_vendor.map((item, idx) => {
      let temp = [];
      temp.push(item.label);
      data_vendor.push(temp);
    });

    const ws = XLSX.utils.aoa_to_sheet([header]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Material Code 1");

    const ws2 = XLSX.utils.aoa_to_sheet(data_vendor);
    XLSX.utils.book_append_sheet(wb, ws2, "MasterVendor");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      "TemplateDailySpotPrice_" +
        currency_bulk_create +
        "_" +
        dateFormat(month_create, "mmmm-yyyy") +
        "_.xlsx"
    );
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.spot_date), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let datenow = new Date();
    let is_noneditable = true;
    let date_db = new Date(row.spot_date);
    let currencyreadOnly = true;
    if (this.diff_date_on_day(date_db, datenow) <= 7) {
      is_noneditable = false;
    }
    const { isEdit } = this.state;
    //TODO REVERT BACK
    return isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.currency,
              row.material_name,
              row.material_code,
              row.vendor_name,
              row.vendor_code,
              row.spot_price,
              row.unit,
              date_db,
              is_noneditable,
              currencyreadOnly
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    let formatted_cell = isNaN(cell)
      ? ""
      : parseFloat(cell).toFixed(DECIMAL_PLACES);
    return <div>{thousandformat(formatted_cell, ".", ".", ",")}</div>;
  };

  toDetail = (
    id,
    currency,
    material,
    material_code,
    vendor,
    vendor_code,
    price,
    unit,
    date,
    currencyreadOnly
  ) => {
    this.setState(
      {
        id: id,
        currency: currency,
        material: material,
        material_code: material_code,
        vendor: vendor,
        vendor_code,
        vendor_code,
        date: new Date(date),
        price: price,
        unit: unit,
        currencyreadOnly: currencyreadOnly,
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      material: null,
      vendor: null,
      date: null,
      price: null,
      unit: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };

  onChangeHandlerFile = (event) => {
    let file_size = event.target.files[0].size;
    if (file_size > MAX_SIZE_FILE_UPLOAD) {
      Swal.fire("Warning", "Max File Size is 1MB", "error");
      return;
    }
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        if (wsname != "MasterVendor") {
          const result_worksheet = this.setting_worksheet(
            wb.Sheets[wsname],
            wsname
          );
          if (result_worksheet) worksheet.push(result_worksheet);
        }
      });
      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (data, cols, start_row = 0, allowed_cols = []) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      let z = [];
      clone_data.map((r, i) => {
        if (i == 0) {
          let tmp_z = [];
          r.map((er, ie) => {
            if (ie > 0) {
              if (isNaN(er)) {
                tmp_z.push(er);
              } else {
                //format date excel
                tmp_z.push(
                  dateFormat(
                    new Date(Math.round((er - 25569) * 86400 * 1000)),
                    "yyyy-mm-dd"
                  )
                );
              }
            } else {
              tmp_z.push(er);
            }
          });
          z = [...tmp_z];
        } else {
          z = [...r];
        }
        let splice = z.splice(0, 1);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });
        if (i !== start_row && cleanArray.length > 0) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }
    return { header: header, detail: detail };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */

    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };

  //validate input
  validateForm = (currency, material, vendor, price, unit, date) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendor: false,
      });
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  validateFormCreate = (currency, material, vendor, price, unit, date) => {
    let errors = this.state.errors_create;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendorCreate: false,
      });
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      date_first,
      date_second,
      page,
      sizePerPage,
      sortBy,
      sortOrder,
      material_search,
    } = this.state;
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");

    let query_string = "";
    query_string = `date_first=${date_second_formatted}&date_second=${date_first_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (material_search) {
      query_string += `&material_name=${material_search}`;
    }

    this.form.reset();
    this.formBulk.reset();
    this.refMaterial.state.value.value = "";
    this.refMaterial.state.value.label = "";
    this.refVendor.state.value.value = "";
    this.refVendor.state.value.label = "";
    readQsDailySpotPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        submitted_create: false,
        currency_create: null,
        vendor_create: null,
        vendor_create_code: null,
        material_create: null,
        material_create_code: null,
        unit_create: null,
        price_create: null,
        date_create: new Date(),
        worksheet: [],
        file_create: null,
      });
    });
    readMaterialByType("ZRAW").then((response) => {
      this.setState({ select_items_material: response });
    });
    readVendor().then((response) => {
      this.setState({ select_items_vendor: response });
    });

    readDailySpotPriceNewFormat(query_string).then((response) => {
      this.setState({
        list_table: response,
        isSearch: true,
      });
    });
  };
  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
    const { date_first, date_second } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      material: "material_name",
      vendor: "vendor_name",
      spot_price: "spot_price",
      unit: "unit",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `date_first=${date_second_formatted}&date_second=${date_first_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }
    readQsDailySpotPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      currency_create,
      material_create,
      material_create_code,
      vendor_create,
      vendor_create_code,
      price_create,
      unit_create,
      date_create,
    } = this.state;
    if (
      !this.validateFormCreate(
        currency_create,
        material_create,
        vendor_create,
        price_create,
        unit_create,
        date_create
      )
    ) {
      return;
    }

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(date_create, "dd mmmm yyyy");
    let collection = {
      requester: requester,
      material_name: material_create,
      material_code: material_create_code,
      vendor_name: vendor_create,
      vendor_code: vendor_create_code,
      spot_price: price_create,
      unit: unit_create,
      currency: currency_create,
      spot_date: date_create,
      department: department,
    };
    if (
      requester &&
      currency_create &&
      material_create &&
      material_create_code &&
      vendor_create &&
      vendor_create_code &&
      price_create &&
      unit_create &&
      date_create &&
      price_create >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addDailySpotPrice(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/price/daily-spot-price");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (
                err ===
                "data is already exist, Are you sure to overwrite data ?"
              ) {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${date_formatted} ${currency_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteDailySpotPrice(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          this.setState({
                            material_create: null,
                            material_create_code: null,
                            vendor_create: null,
                            vendor_create_code: null,
                            price_create: null,
                            unit_create: null,
                            currency_create: null,
                            date_create: new Date(),
                          });
                          this.reloadDataBackend();
                          history.push("/price/daily-spot-price");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSubmitBulk = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester, department, excel, selectedFile, worksheet, errors } =
      this.state;
    let collection = {
      requester: requester,
      department: department,
      excel: excel,
    };
    if (requester && department && excel && worksheet.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        this.setState({
          loading: true,
        });
        if (result.value) {
          addBulk(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-success",
              });
              this.setState({
                loading: false,
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-powder-failed",
              });
              Swal.fire({
                title: "Error",
                icon: "error",
                html: err,
                showConfirmButton: true,
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    const { id, currency, material, vendor, price, unit, date, requester } =
      this.state;

    if (!this.validateForm(currency, material, vendor, price, unit, date)) {
      return;
    }
    const { history } = this.props;
    let collection = {
      _id: id,
      spot_price: price ? price.toString() : "",
      requester: requester,
      department: department,
    };
    if (
      id &&
      currency &&
      material &&
      vendor &&
      price &&
      unit &&
      date &&
      price >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editDailySpotPrice(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/price/daily-spot-price");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const {
      date_first,
      date_second,
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      isSearch,
      material_search,
      category_filter,
    } = this.state;
    if (date_second > date_first) {
      this.setState({
        date_first: new Date(),
        date_second: subDays(new Date(), 7),
      });
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = "";
    query_string = `date_first=${date_second_formatted}&date_second=${date_first_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (material_search) {
      query_string += `&material_name=${material_search}`;
    }
    if (category_filter) {
      query_string += `&category=${category_filter}`;
    }
    let tmp_date_first = new Date(date_first);
    readDailySpotPriceNewFormat(query_string).then((response) => {
      this.setState({
        list_table: response,
        isSearch: true,
        date_first_filter: tmp_date_first.setDate(tmp_date_first.getDate() + 1),
        date_second_filter: date_second,
      });
    });
  };
  render() {
    let {
      columns,
      columns_new,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items,
      select_items_material,
      select_items_uom,
      select_items_vendor,
      list_datatable,
      list_table,
      historical_data_new_format,
      id,
      currency,
      material,
      material_search,
      vendor,
      price,
      unit,
      date,
      vendor_create_code,
      material_create_code,
      material_create,
      vendor_create,
      currency_create,
      price_create,
      unit_create,
      date_create,
      date_first,
      date_second,
      errors,
      errors_create,
      submitted_create,
      submitted,
      currencyreadOnly,
      unitreadOnly,
      loading,
      isValidMaterial,
      isValidMaterialCreate,
      isValidVendor,
      isValidVendorCreate,
      isClearable,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
      isSearch,
      month_create,
      category_create,
      currency_bulk_create,
      isDisabled,
      worksheet,
      file_create,
      download_template_url,
      category,
      category_filter,
      date_first_filter,
      date_second_filter,
    } = this.state;
    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesVendor = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidVendor ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendor
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesVendorCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidVendorCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendorCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Daily Spot Price
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.material != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="material">Material *</label>
                        <Select
                          isDisabled
                          styles={customStylesMaterial}
                          onChange={this.handleChange.bind(this, "material")}
                          options={select_items_material}
                          defaultValue={select_items_material.filter(
                            (e) => e.label === material
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidMaterial && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.material}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="date">Spot Date *</label>
                        <DatePicker
                          disabled
                          selected={date}
                          startDate={date}
                          maxDate={
                            date
                              ? subDays(new Date(date), 0) !==
                                subDays(new Date(), 0)
                                ? subDays(new Date(date), 0)
                                : subDays(new Date(), 0)
                              : subDays(new Date(), 0)
                          }
                          minDate={
                            date
                              ? subDays(new Date(date), 7) !==
                                subDays(new Date(), 7)
                                ? subDays(new Date(date), 7)
                                : subDays(new Date(date), 7)
                              : subDays(new Date(), 7)
                          }
                          disabledKeyboardNavigation
                          onChange={this.handleDate.bind(this, "date")}
                          name="date"
                          dateFormat="dd MMMM yyyy"
                          wrapperClassName="datePickerCustom"
                          className="form-control form-control-sm"
                          required
                          isClearable={isClearable}
                        />
                        {errors.date && (
                          <div className="invalid-feedback">{errors.date}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="price">Spot Price *</label>
                        <input
                          type="number"
                          step="1"
                          className="form-control form-control-sm"
                          name="price"
                          placeholder="Spot Price"
                          required="required"
                          value={price || ""}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.price && (
                          <div className="invalid-feedback">{errors.price}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.currency != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>

                        <select
                          disabled
                          className={
                            errors.currency != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="currency"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={currency}
                          readOnly={true}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items &&
                            select_items.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.currency && (
                          <div className="invalid-feedback">
                            {errors.currency}
                          </div>
                        )}
                      </div>
                      {/*TODO still dummy data */}
                      <div
                        className={
                          errors.unit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="unit">Unit *</label>

                        <select
                          disabled
                          className={
                            errors.unit != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="unit"
                          style={{ padding: 0 }}
                          defaultValue={unit}
                          readOnly={unitreadOnly}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_uom &&
                            select_items_uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.unit && (
                          <div className="invalid-feedback">{errors.unit}</div>
                        )}
                      </div>
                      {/*TODO still dummy data */}
                      <div
                        className={
                          errors.vendor != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="vendor">Vendor *</label>
                        <Select
                          isDisabled
                          styles={customStylesVendor}
                          onChange={this.handleChange.bind(this, "vendor")}
                          options={select_items_vendor}
                          defaultValue={select_items_vendor.filter(
                            (e) => e.label === vendor
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidVendor && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.vendor}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Daily Spot Price</h3>
                        </div>
                        <div className="card-body">
                          <Tabs transition={false} defaultActiveKey="bulk">
                            <Tab
                              eventKey="bulk"
                              title="Bulk Input"
                              className="mx-3 mt-2 pt-3"
                            >
                              <form
                                autoComplete="off"
                                className={
                                  submitted_create
                                    ? "needs-validation was-validated"
                                    : "needs-validation"
                                }
                                noValidate
                                ref={(formBulk) => (this.formBulk = formBulk)}
                              >
                                <Row>
                                  <Col xs={12} md={4}>
                                    <div className="form-group">
                                      <label htmlFor="date_create">
                                        Month{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <DatePicker
                                        selected={month_create}
                                        disabledKeyboardNavigation
                                        onChange={this.handleDate.bind(
                                          this,
                                          "month_create"
                                        )}
                                        name="month_create"
                                        wrapperClassName="datePickerCustom"
                                        dateFormat="MMMM yyyy"
                                        className="form-control form-control-sm"
                                        required
                                        showMonthYearPicker
                                        isClearable={false}
                                      />
                                      {errors_create.date && (
                                        <div className="invalid-feedback">
                                          {errors_create.date}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="category_create">
                                        Category{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        className="form-control form-control-sm"
                                        name="category_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={category_create}
                                      >
                                        <option value="">Choose</option>
                                        <option value="global">Global</option>
                                        <option value="local">Local</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="currency_bulk_create">
                                        Currency{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        name="currency_bulk_create"
                                        value={currency_bulk_create || ""}
                                        onChange={this.onChangeValue}
                                        readOnly={true}
                                      />
                                      <br></br>

                                      {isDisabled ? (
                                        <Button
                                          href={download_template_url}
                                          variant="dark"
                                          className="float-left"
                                          disabled={true}
                                        >
                                          <i className="fas fa-download" />{" "}
                                          Template Excel
                                        </Button>
                                      ) : (
                                        <a
                                          href={download_template_url}
                                          variant="primary"
                                          className="float-left btn btn-primary"
                                          download
                                        >
                                          <i className="fas fa-download" />{" "}
                                          Template Excel
                                        </a>
                                      )}
                                    </div>
                                    <br></br>
                                    <br></br>

                                    <div className="form-group">
                                      <label htmlFor="date_create">
                                        Upload Excel{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control form-control-xs"
                                        name="file"
                                        placeholder="File *) XLSX"
                                        defaultValue={file_create}
                                        onChange={this.onChangeHandlerFile}
                                        accept={SheetJSFT}
                                        required
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Worksheet worksheet={worksheet} />
                                  </Col>
                                </Row>{" "}
                              </form>
                              <hr />
                              <Row>
                                {worksheet.length > 0 ? (
                                  <>
                                    <Col xs={12} md={12}>
                                      <Button
                                        variant="success"
                                        onClick={this.handleSubmitBulk}
                                        className="float-right  "
                                      >
                                        <i className="fas fa-save" /> Submit
                                      </Button>
                                    </Col>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Tab>
                            <Tab
                              eventKey="single"
                              title="Single Input"
                              className="mx-3 mt-2 pt-3"
                            >
                              <form
                                autoComplete="off"
                                className={
                                  submitted_create
                                    ? "needs-validation was-validated"
                                    : "needs-validation"
                                }
                                noValidate
                                ref={(form) => (this.form = form)}
                              >
                                <Row>
                                  <Col xs={12} md={4}>
                                    {/*TODO still dummy data */}
                                    <div
                                      className={
                                        errors_create.material !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="material">
                                        Material *
                                      </label>
                                      <Select
                                        styles={customStylesMaterialCreate}
                                        onChange={this.handleChange.bind(
                                          this,
                                          "material_create"
                                        )}
                                        options={select_items_material}
                                        defaultValue={select_items_material.filter(
                                          (e) => e.value === material_create
                                        )}
                                        isClearable={isClearable}
                                        ref={(ref) => (this.refMaterial = ref)}
                                      />

                                      {!isValidMaterialCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.material}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="date_create">
                                        Spot Date *
                                      </label>
                                      <DatePicker
                                        selected={date_create}
                                        startDate={date_create}
                                        maxDate={
                                          date_create
                                            ? subDays(
                                                new Date(date_create),
                                                0
                                              ) !== subDays(new Date(), 0)
                                              ? subDays(new Date(), 0)
                                              : subDays(new Date(), 0)
                                            : subDays(new Date(), 0)
                                        }
                                        minDate={
                                          date_create
                                            ? subDays(
                                                new Date(date_create),
                                                7
                                              ) !== subDays(new Date(), 7)
                                              ? subDays(new Date(), 7)
                                              : subDays(
                                                  new Date(date_create),
                                                  7
                                                )
                                            : subDays(new Date(), 7)
                                        }
                                        disabledKeyboardNavigation
                                        onChange={this.handleDate.bind(
                                          this,
                                          "date_create"
                                        )}
                                        name="date"
                                        wrapperClassName="datePickerCustom"
                                        dateFormat="dd MMMM yyyy"
                                        className="form-control form-control-sm"
                                        required
                                        isClearable={isClearable}
                                      />
                                      {errors_create.date && (
                                        <div className="invalid-feedback">
                                          {errors_create.date}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.price !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="price">
                                        Spot Price *
                                      </label>
                                      <input
                                        type="number"
                                        step="1"
                                        className="form-control form-control-sm"
                                        name="price_create"
                                        placeholder="Spot Price"
                                        required="required"
                                        value={price_create || ""}
                                        onChange={this.onChangeValue}
                                        min="0"
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="category">
                                        Category <span>*</span>
                                      </label>
                                      <select
                                        className="form-control form-control-sm"
                                        name="category"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={category}
                                      >
                                        <option value="">Choose</option>
                                        <option value="global">Global</option>
                                        <option value="local">Local</option>
                                      </select>
                                    </div>
                                    <div
                                      className={
                                        errors_create.currency !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Currency *
                                      </label>

                                      <select
                                        className={
                                          errors_create.currency !=
                                          "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="currency_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={currency_create}
                                        readOnly={currencyreadOnly}
                                        required
                                      >
                                        <option value="">Choose</option>
                                        {select_items &&
                                          select_items.map(function (item, i) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                                hidden={
                                                  category == "local"
                                                    ? item.value == "Rp"
                                                      ? false
                                                      : true
                                                    : item.value == "Rp"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.currency && (
                                        <div className="invalid-feedback">
                                          {errors_create.currency}
                                        </div>
                                      )}
                                    </div>
                                    {/*TODO still dummy data */}
                                    <div
                                      className={
                                        errors_create.unit !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">Unit *</label>

                                      <select
                                        className={
                                          errors_create.unit !=
                                          "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="unit_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={unit_create}
                                        readOnly={true}
                                        required
                                        ref={(ref) => (this.refUnit = ref)}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_uom &&
                                          select_items_uom.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.unit && (
                                        <div className="invalid-feedback">
                                          {errors_create.unit}
                                        </div>
                                      )}
                                    </div>
                                    {/*TODO still dummy data */}
                                    <div
                                      className={
                                        errors_create.vendor !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="vendor_create">
                                        Vendor *
                                      </label>
                                      <Select
                                        styles={customStylesVendorCreate}
                                        onChange={this.handleChange.bind(
                                          this,
                                          "vendor_create"
                                        )}
                                        options={select_items_vendor}
                                        defaultValue={select_items_vendor.filter(
                                          (e) => e.label === vendor_create
                                        )}
                                        isClearable={isClearable}
                                        ref={(ref) => (this.refVendor = ref)}
                                      />

                                      {!isValidVendorCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.vendor}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Button
                                      variant="success"
                                      onClick={this.handleSubmit}
                                      className="float-right"
                                    >
                                      <i className="fas fa-save" /> Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <label htmlFor="material_search">
                                    Material
                                  </label>
                                  <Select
                                    onChange={this.handleChange.bind(
                                      this,
                                      "material_search"
                                    )}
                                    options={select_items_material}
                                    defaultValue={select_items_material.filter(
                                      (e) => e.label === material_search
                                    )}
                                    isClearable={isClearable}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <label htmlFor="date">Category</label>
                                  <select
                                    className="form-control form-control-md"
                                    name="category_filter"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={category_filter}
                                  >
                                    <option value="">Choose</option>
                                    <option value="global">Global</option>
                                    <option value="local">Local</option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <label htmlFor="date">Start Date</label>
                                  <DatePicker
                                    selected={date_second}
                                    startDate={date_second}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_second"
                                    )}
                                    name="date"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control-md"
                                    required
                                  />
                                </div>
                              </Col>
                              &nbsp;
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <label htmlFor="date">End Date</label>
                                  <DatePicker
                                    selected={date_first}
                                    startDate={date_first}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_first"
                                    )}
                                    name="date"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control-md"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="date">&nbsp;</label>
                                  <br></br>
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                          <hr />
                          {/* <br /> */}
                          {isSearch &&
                            list_table.map((item, idx) => (
                              <>
                                <h5>
                                  {item.material_name} ({item.unit})
                                </h5>
                                <SpotPrice
                                  per_material={item}
                                  data={item.per_vendor}
                                  columns={columns_new}
                                  start_date={date_second_filter}
                                  end_date={date_first_filter}
                                  onOpenEdit={(row) => {
                                    this.toDetail(
                                      row._id,
                                      row.currency,
                                      row.material_name,
                                      row.material_code,
                                      row.vendor_name,
                                      row.vendor_code,
                                      row.spot_price,
                                      row.unit,
                                      row.spot_date,
                                      false,
                                      true
                                    );
                                  }}
                                />
                                <hr />
                              </>
                              // </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(DailySpotPrice);
