import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MapPowderRndService {
  readByMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT + "map_powder_rnd/byMonth?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }

  schedule(payload) {
    return axios.post(API_URL_DEFAULT + "map_powder_rnd/scheduler", payload, {
      headers: authHeader(),
    });
  }
}

export default new MapPowderRndService();
