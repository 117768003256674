import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MAPManualService {
    create(payload) {
        return axios.post(API_URL_DEFAULT + "map-manual/", payload, {
            headers: authHeader(),
        });
    }
    read(query) {
        return axios.get(API_URL_DEFAULT + "map-manual/", {
            headers: authHeader(),
            params: query
        });
    }
}

export default new MAPManualService();
