import Services from "../../services/rio/dashboard_timeline.service";

const getData = (query) => {
  return Services.getData(query).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export default { getData };
