import React, { Component } from "react"; 
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'; 
import PropTypes from 'prop-types';
import filterFactory, { Comparator } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor'; 
import Button from "react-bootstrap/Button";

const sizePerPageOptionRenderer = ({
  text,
  page,
  onSizePerPageChange
}) => (
  <li
    key={ text }
    role="presentation"
    className="dropdown-item"

    onClick={ (e) => {
      e.preventDefault();
      onSizePerPageChange(page);
    } } 
  >
  <Button
      variant="default"
      style={{backgroundColor:'transparent', borderColor: 'white'}} 
    >  { text }
    </Button> 
  </li>
); 
const RemoteAll = ({ data,columns,defaultSorted, page, sizePerPage, onTableChange, totalSize,cellEditProps}) => (
  <div>
    <BootstrapTable
      bootstrap4
      remote={ {
        filter: true,
        pagination: true,
        sort: true,
        cellEdit: false
      } }
      keyField="_id"
      data={ data }
      columns={ columns }
      defaultSorted={ defaultSorted }
      filter={ filterFactory() }
      headerClasses="text-center"
      bodyClasses="text-left"
      onTableChange={ onTableChange }
      cellEdit={ cellEditFactory(cellEditProps) }
      
      noDataIndication={() => (<div>No Data</div>)}
    /> 
  </div>
);

RemoteAll.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired
};

class RemoteNoPagination extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      page: 1,
      data: this.props.data,
      totalSize: this.props.data.length,
      sizePerPage: 10,
    }; 
  }   
  componentDidMount(){
    this.setState({
      data: this.props.data
    })
  } 
  
  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }
  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    const currentIndex = (page - 1) * sizePerPage;
    setTimeout(() => {
      // Handle cell editing
      let data = this.props.data;
      if (type === 'cellEdit') {
        const { rowId, dataField, newValue } = cellEdit;
        data = data.map((row) => {
          if (row.id === rowId) {
            const newRow = { ...row };
            newRow[dataField] = newValue; 
            return newRow;
          }
          return row;
        });
      }
      let result = data;

      // Handle column filters
      result = result.filter((row) => {
        let valid = true;
        for (const dataField in filters) {
          const { filterVal, filterType, comparator } = filters[dataField];

          if (filterType === 'TEXT') {
            if (comparator === Comparator.LIKE) {
              valid = row[dataField].toString().indexOf(filterVal) > -1;
            } else {
              valid = row[dataField] === filterVal;
            }
          }
          if (!valid) break;
        }
        return valid;
      });
      // Handle column sort
      if (sortOrder === 'asc') {
        result = result.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return 1;
          } else if (b[sortField] > a[sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = result.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return -1;
          } else if (b[sortField] > a[sortField]) {
            return 1;
          }
          return 0;
        });
      }
      this.setState(() => ({
        page,
        data: result.slice(currentIndex, currentIndex + sizePerPage),
        totalSize: result.length,
        sizePerPage
      }));
    }, 200);
  }
  render() {
    let { data, sizePerPage, page,totalSize } = this.state;
    const{defaultSorted,columns,cellEditProps} =this.props;
   
    return ( 
      <RemoteAll
        data={ data }
        columns={columns}
        page={ page }
        sizePerPage={ sizePerPage }
        totalSize={ totalSize }
        onTableChange={ this.handleTableChange }
        defaultSorted={defaultSorted}
        cellEditProps={cellEditProps} 
      />
    );
  }
}

export default RemoteNoPagination;
