import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApsCipService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "cip", payload, {
      headers: authHeader(),
    });
  }

  createCIP(payload) {
    return axios.post(API_URL_DEFAULT + "cip/data", payload, {
      headers: authHeader(),
    });
  }

  createBulkCIP(payload) {
    return axios.post(API_URL_DEFAULT + "cip/data/bulk", payload, {
      headers: authHeader(),
    });
  }

  readCIP(payload) {
    return axios.get(API_URL_DEFAULT + "cip/data", {
      headers: authHeader(),
      params: payload,
    });
  }

  updateCIP(payload) {
    return axios.put(API_URL_DEFAULT + "cip/data", payload, {
      headers: authHeader(),
    });
  }

  destroyCIP(payload) {
    return axios.delete(API_URL_DEFAULT + "cip/data", payload, {
      headers: authHeader(),
      data: payload,
    });
  }

  generateExcel(query) {
    return axios.get(API_URL_DEFAULT + "cip/data/excel", {
      params: query,
      headers: authHeader(),
      responseType: "blob",
    });
  }
}

export default new ApsCipService();
