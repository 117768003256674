import TollOutVendorPriceService from '../services/toll_out_vendor_price.service'

export const readTollOutByVendor = (payload) => {
  return TollOutVendorPriceService.readTollOutByVendor(payload).then(
    (response) => {
      let optionTollOut = response.data.details.map((toll_out) => {
        return {value: toll_out, label: toll_out.name};
      });
      return Promise.resolve(optionTollOut, response.data.message);
    }
  );
};

export const addTollOutVendorPrice = (payload) => {
  return TollOutVendorPriceService.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const overwriteTollOutVendorPrice = (payload) => {
  return TollOutVendorPriceService.createOverwrite(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}

export const editTollOutVendorPrice = (payload) => {
  return TollOutVendorPriceService.edit(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}


export const deleteTollOutVendorPrice = (payload) => {
  return TollOutVendorPriceService.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}

export const readTollOutVendorPrice = (query_string) => {
  return TollOutVendorPriceService.readSalesForecas(query_string).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}

export const readByMonthTollOutVendorPrice = (qs) => {
  return TollOutVendorPriceService.readByMonthTollOutVendorPrice(qs).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}