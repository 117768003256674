import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class NotificationMrpService {
  readNotification(query_string) {
    return axios.get(API_URL_DEFAULT + "notification/mrp?" + query_string, {
      headers: authHeader(),
    });
  }
}

export default new NotificationMrpService();
