import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApsCalendarService {
  read(query) {
    return axios.get(API_URL_DEFAULT + "calendar/?" + query, {
      headers: authHeader(),
    });
  }

  submitMove(payload) {
    return axios.post(API_URL_DEFAULT + "calendar", payload, {
      headers: authHeader(),
    });
  }
  confirmRpp(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/confirmRpp", payload, {
      headers: authHeader(),
    });
  }
  editQtyRpp(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/editQtyRpp", payload, {
      headers: authHeader(),
    });
  }
  confirmRppWithoutFGBom(payload) {
    return axios.post(
      API_URL_DEFAULT + "calendar/confirmRpp?type=allowWithoutFG",
      payload,
      {
        headers: authHeader(),
      }
    );
  }
  confirmETD(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/confirmEtd", payload, {
      headers: authHeader(),
    });
  }
  splitRpp(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/splitRpp", payload, {
      headers: authHeader(),
    });
  }
  updateCIP(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/updateCIP", payload, {
      headers: authHeader(),
    });
  }
  unconfirmRpp(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/unconfirmRpp", payload, {
      headers: authHeader(),
    });
  }
  cancelCIP(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/cancelCIP", payload, {
      headers: authHeader(),
    });
  }
  deleteUnconfirmRPP(payload) {
    return axios.delete(API_URL_DEFAULT + "calendar/delete-unconfirm-rpp", {
      data: payload,
      headers: authHeader(),
    });
  }
  exportExcel(query) {
    return axios.get(API_URL_DEFAULT + "calendar/export/?" + query, {
      headers: authHeader(),
    });
  }
  recalculate(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/recalculate", payload, {
      headers: authHeader(),
    });
  }
  getSalesForecast(query) {
    return axios.get(API_URL_DEFAULT + "calendar/get-sales-forecast?" + query, {
      headers: authHeader(),
    });
  }
  createMultiRpp(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/create-multi-rpp", payload, {
      headers: authHeader(),
    });
  }
}

export default new ApsCalendarService();
