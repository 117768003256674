import MaterialGroupService from "../services/material_group.service";
  
export const addMaterialGroup = (payload)  => {
  return MaterialGroupService.create(payload).then(
    (response) => { 

      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
};

export const editMaterialGroup = (payload)  => {
  return MaterialGroupService.edit(payload).then(
    (response) => { 

      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
};
export const deleteMaterialGroup = (payload)  => {
  return MaterialGroupService.delete(payload).then(
    (response) => { 
      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
}; 

export const readMaterialGroup = ()  => {
  return MaterialGroupService.readMaterialGroup().then(
    (response) => {  

      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
}; 
 