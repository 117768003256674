import React, { Component } from "react";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Table from "react-bootstrap/Table";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { createRpp, readByDate } from "../modules/ApsRppModule";
import { readProdFacility } from "../modules/ProdFacilityModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  readMaterialGetBalance,
  readMaterialByType,
  readMaterialBomFG,
} from "../modules/MaterialModule";

import NumberFormat from "react-number-format";

class ApsRpp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isValidMaterial: true,
      isValidCustomer: true,
      isValidMaterialCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isReadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_prod_facility: [],
      list_produksi: [],
      select_vendor_length: null,
      select_vendor_first_code: null,
      select_vendor_first_name: null,
      prod_facility_create: null,
      total_qty: null,
      type_create: null,
      list_datatable: [],
      errors: {},
      errors_create: {},
      plan_date: null,
      month: null,
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      segment: null,
      customer: null,
      customer_code: null,
      queue_create: null,
      qty: null,
      unit: null,
      date: null,
      year: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      material_create_code: null,
      vendor_create_code: null,
      facility_code_create: null,
      facility_desc_create: null,
      facility_number_create: null,
      unit_create: null,
      qty_create: null,
      date_create: null,
      duration_create: 12,
      start_time: null,
      finish_time: null,
      safety_stock_create: 0,
      cb_safety_stock_create: false,
      remaining_balance: 0,
      data_toll_out: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
      data_toll_out_update: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
      columns_toll_out: [
        {
          text: "Name",
          type: "text",
        },
        {
          text: "Kg/UoM",
          type: "numeric",
        },
        {
          text: "Price (USD)",
          type: "numeric2",
        },
        {
          text: "UOM",
          type: "text2",
        },
      ],
      select_items_glaccount: [],
      select_items_uom: [],
      columns: [
        {
          dataField: "vendor_name",
          text: "Vendor",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "month",
          text: "Month",
          classes: "text-center",
          formatter: this.GetDateFormat,
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "created_at",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "month",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
      position_picked: null,
      event_picked: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readMaterialByType("ZSFG,ZFGD,ZFG1").then((response) => {
      this.setState({ select_items_material: response });
    });
    readProdFacility().then((response) => {
      this.setState({ select_items_prod_facility: response });
    });
    this.setState({
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
      date_create: new Date(),
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    const {
      facility_code_create,
      facility_type_create,
      facility_number_create,
      material_create_code,
      material_create,
      type_create,
      date_create,
    } = this.state;
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    if (name === "qty_create") {
      data[name] = parseInt(value.replace(/,/g, ''))
    }

    this.setState(data);
    this.setState(data, () => {
      if (name === "qty_create") {
        this.handleKeyUp();
        this.calculateQtyBatch(null, null, any.target.value);
      }
      if (name === "type_create") {
        this.handleReadMaterialByDate();
      }
    });
  };

  calculateQtyBatch = (create_code, facility_code, qty_create) => {
    const query = {
      code: create_code ? create_code : this.state.material_create_code,
      prod_facility: facility_code
        ? facility_code
        : this.state.facility_code_create,
    };
    readMaterialBomFG(new URLSearchParams(query)).then((res) => {
      if (res) {
        let qtyInput = qty_create ? qty_create : this.state.qty_create;
        let qtyInputReplace = qtyInput
          ? parseInt(qtyInput.replace(/,/g, ""))
          : 0;

        let total_batch = res?.qty_per_batch
          ? qtyInputReplace / parseInt(res.qty_per_batch.replace(/,/g, ""))
          : 0;

        this.setState({
          total_batch_format: total_batch,
          total_batch: total_batch,
          qty_batch: res.qty_per_batch,
        });
      }
    });
  };

  handleReadMaterialByDate = (event) => {
    const {
      facility_code_create,
      facility_type_create,
      facility_number_create,
      material_create_code,
      material_create,
      type_create,
      date_create,
    } = this.state;
    let query = `date=${dateFormat(
      date_create,
      "yyyy-mm-dd"
    )}&facility_type=${facility_type_create}`;
    if (facility_number_create) {
      query = `date=${dateFormat(
        date_create,
        "yyyy-mm-dd"
      )}&facility_type=${facility_type_create}&facility_number=${facility_number_create}`;
    }
    if (["CIP", "Maintenance"].includes(type_create)) {
      query += `&create=true`;
    }

    readByDate(query).then((response) => {
      if (["CIP", "Maintenance"].includes(type_create)) {
        let new_schedule = {
          type: type_create,
          isNew: true,
          material_code: material_create_code,
          material_desc: material_create,
        };
        response.unshift(new_schedule);
      }
      this.setState({
        event_picked: response[1] ? response[1]._id : null,
        position_picked: response[1] ? "before" : null,
        list_produksi: response,
      });
    });
  };

  handleKeyUp = (event) => {
    let { qty_create, safety_stock_create, cb_safety_stock_create } =
      this.state;

    if (cb_safety_stock_create) {
      this.setState({
        total_qty: parseFloat(qty_create) + parseFloat(safety_stock_create),
      });
    } else {
      this.setState({
        total_qty: qty_create,
      });
    }
  };

  handleChecklist = async (event) => {
    const { cb_safety_stock_create } = this.state;
    this.setState(
      {
        cb_safety_stock_create: !cb_safety_stock_create,
      },
      () => {
        this.handleKeyUp();
      }
    );
  };
  //change state date
  handleDate = (name, date) => {
    const {
      facility_code_create,
      facility_type_create,
      facility_number_create,
      material_create_code,
      material_create,
      type_create,
    } = this.state;
    this.setState(
      {
        [name]: date,
      },
      () => {
        if (name == "date_create") {
          this.handleReadMaterialByDate();
        }
      }
    );
    if (name == "date_create") {
      let query_material = `code=${material_create_code}&date=${dateFormat(
        date,
        "yyyy-mm-dd"
      )}`;
      readMaterialGetBalance(query_material).then((response) => {
        let safety_stock = response.safety_stock - response.balance;
        this.setState({
          unit_create: response.unit,
          remaining_balance: response.balance,
          safety_stock_create: safety_stock <= 0 ? 0 : safety_stock,
          isValidMaterialCreate: true,
        });
      });
    }
  };

  handleChange = (name, newValue) => {
    const { date_create } = this.state;
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: true,
        });
      } else if (name == "material_create") {
        let query = `code=${newValue.value}&date=${dateFormat(
          date_create,
          "yyyy-mm-dd"
        )}`;
        readMaterialGetBalance(query).then((response) => {
          let safety_stock = response.safety_stock - response.balance;
          this.setState({
            unit_create: response.unit,
            remaining_balance: response.balance,
            safety_stock_create: safety_stock <= 0 ? 0 : safety_stock,
            isValidMaterialCreate: true,
          });
        });
      }
      this.calculateQtyBatch(newValue.value, null, null);
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: false,
        });
      } else if (name == "material_create") {
        this.setState({
          isValidMaterialCreate: false,
        });
      }
    }
  };

  handlePlace = (idx, name) => {
    const { list_produksi } = this.state;
    let btn_name = name;
    let index_list = list_produksi.findIndex((x) => x.isNew);
    let moveable_list = list_produksi[index_list];
    let new_list = [...list_produksi];

    //remove cip list
    new_list.splice(index_list, 1);

    //add cip list
    new_list.splice(idx, 0, moveable_list);

    if (btn_name == "up") {
      if (idx - 1 == index_list) {
        return;
      }
      this.setState({
        position_picked: "before",
        event_picked: list_produksi[idx]._id,
      });
    }
    if (btn_name == "down") {
      if (idx + 1 == index_list) {
        return;
      }
      this.setState({
        position_picked: "after",
        event_picked: list_produksi[idx]._id,
      });
    }
    this.setState({
      list_produksi: [...new_list],
    });
  };

  onChangeProdFacility = (any) => {
    const { select_items_prod_facility, date_create } = this.state;
    let index = any.target.selectedIndex;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      this.setState(
        {
          facility_code_create: prod_facility.facility_code,
          facility_desc_create: prod_facility.facility_desc,
          facility_type_create: prod_facility.facility_type,
          facility_number_create: prod_facility.facility_number,
        },
        () => {
          this.handleReadMaterialByDate();
        }
      );
      let date = dateFormat(date_create, "yyyy-mm-dd");
      if (date_create == null) {
        date = null;
      }
      this.calculateQtyBatch(null, prod_facility.facility_code, null);
    }
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.month), "yyyy-mm");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.vendor_name,
                row.vendor_code,
                row.month,
                row.month_date,
                row.details
              )
            }
          >
            <i className="fas fa-edit" key={row._id}></i>
            &nbsp;Edit
          </button>
        )}

        {isDelete && (
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() =>
              this.toDetailView(
                row.vendor_name,
                row.vendor_code,
                row.month,
                row.month_date,
                row.details
              )
            }
          >
            <i class="fas fa-info-circle"></i>
            &nbsp;View
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(cell, ".", ".", ",")}</div>;
  };

  toDetail = (id, vendor_name, vendor_code, month, month_date, details) => {
    this.setState(
      {
        id: id,
        vendor_name: vendor_name,
        vendor_code: vendor_code,
        month: dateFormat(month, "mmmm yyyy"),
        month_date: month_date,
        read_type: "",
        data_toll_out_update: details,
      },
      () => {
        this.toggle();
      }
    );
  };

  toDetailView = (vendor_name, vendor_code, month, month_date, details) => {
    this.setState(
      {
        vendor_name: vendor_name,
        vendor_code: vendor_code,
        month: dateFormat(month, "mmmm yyyy"),
        month_date: month_date,
        read_type: "readonly",
        data_toll_out_update: details,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      vendor_name: null,
      vendor_code: null,
      month: null,
      month_date: null,
      read_type: null,
      data_toll_out_update: [
        {
          name: "",
          kg: "",
          price: "",
          uom: "",
        },
      ],
    });
    this.toggle();
  };
  //validate input
  validateForm = (currency, material, vendor, unit, date, category) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidCustomer: false,
      });
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!category) {
      errors.category = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  validateFormCreate = (material, date_create) => {
    let errors = this.state.errors_create;
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    }
    if (!date_create) {
      errors.date_create = "This is field required";
    }
    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    this.form.reset();
    this.setState({
      submitted_create: false,
      list_produksi: [],
      material_create: null,
      material_create_code: null,
      qty_create: null,
      unit_create: null,
      date_create: null,
      start_time: null,
      finish_time: null,
      queue_create: null,
      cb_safety_stock_create: false,
      safety_stock_create: 0,
      remark: null,
      type_create: null,
      remaining_balance: 0,
      total_qty: 0,
      facility_code_create: null,
      facility_desc_create: null,
      facility_type_create: null,
      facility_number_create: null,
    });
    readMaterialByType("ZSFG,ZFGD,ZFG1").then((response) => {
      this.setState({ select_items_material: response });
    });
  };

  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      material_create,
      material_create_code,
      facility_code_create,
      facility_desc_create,
      facility_type_create,
      facility_number_create,
      qty_create,
      unit_create,
      date_create,
      start_time,
      finish_time,
      cb_safety_stock_create,
      safety_stock_create,
      remark,
      type_create,
      remaining_balance,
      position_picked,
      event_picked,
      duration_create,
      total_batch,
    } = this.state;

    if (!this.validateFormCreate(material_create, qty_create)) {
      return;
    }
    this.setState({ loading: true });

    if (type_create == "RPP") {
      if (!material_create_code) {
        Swal.fire("Warning", "Please Complete Material", "warning");
        return;
      }
      if (!qty_create) {
        Swal.fire("Warning", "Please Complete QTY", "warning");
        return;
      }
      if (parseInt(qty_create) <= 0) {
        Swal.fire("Warning", "Please Complete QTY more than 0", "warning");
        return;
      }
    }
    if (duration_create <= 0) {
      Swal.fire("Warning", "Please Complete Duration", "warning");
      return;
    }

    let collection = {
      material_desc: type_create == "RPP" ? material_create : type_create,
      material_code: type_create == "RPP" ? material_create_code : type_create,
      qty: qty_create ? qty_create : "0",
      unit: unit_create,
      date_start: date_create,
      date_finish: date_create,
      cb_safety_stock: cb_safety_stock_create,
      safety_stock: safety_stock_create,
      beginning_balance: remaining_balance,
      remark: remark ? remark : "",
      type: type_create,
      data_source: "MANUAL",
      requester: requester,
      prod_facility_desc: facility_desc_create,
      prod_facility_code: facility_code_create,
      prod_facility_type: facility_type_create,
      prod_facility_number: facility_number_create,
      position_picked: position_picked,
      event_picked: event_picked,
      duration: duration_create.toString(),
      total_batch: total_batch,
    };

    if (requester && type_create && date_create && facility_desc_create) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          createRpp(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                if (typeof this.props.handleReload === "function") {
                  this.props.handleReload();
                }
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });

              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      this.setState({
        loading: false,
      });
      Swal.fire("Warning", "Please Complete Form", "warning");
    }
  };

  render() {
    let {
      select_items_material,
      material_create_code,
      unit_create,
      qty_create,
      date_create,
      start_time,
      finish_time,
      duration_create,
      safety_stock_create,
      cb_safety_stock_create,
      prod_facility_create,
      total_qty,
      type_create,
      select_items_prod_facility,
      remaining_balance,
      errors,
      errors_create,
      submitted_create,
      isValidMaterialCreate,
      isClearable,
      isCreate,
      isReadOnly,
      list_produksi,
      total_batch_format,
      qty_batch,
    } = this.state;
    return (
      <div>
        <div>
          <ToastContainer />

          <section className="content">
            <div className="container-fluid">
              {isCreate && (
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">New Schedule</h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <form
                          autoComplete="off"
                          className={
                            submitted_create
                              ? "needs-validation was-validated"
                              : "needs-validation"
                          }
                          noValidate
                          ref={(form) => (this.form = form)}
                        >
                          {/* input vendor */}
                          <Row>
                            <Col xs={12} md={4}>
                              <Row>
                                <Col xs={12} md={6}>
                                  <label htmlFor="currency">Type</label>
                                  <div className="form-row form-group">
                                    <select
                                      className="form-control form-control-sm"
                                      name="type_create"
                                      onChange={this.onChangeValue}
                                      style={{ padding: 0 }}
                                      defaultValue={type_create}
                                      required
                                    >
                                      <option value="">Choose</option>
                                      <option value="RPP">RPP</option>
                                      <option value="CIP">CIP</option>
                                      <option value="Maintenance">
                                        Maintenance
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                                <Col xs={12} md={6}>
                                  <div
                                    className={
                                      errors_create.prod_facility_create !=
                                      "This field is required"
                                        ? "form-group"
                                        : "form-group has-error has-feedback"
                                    }
                                  >
                                    <label htmlFor="currency">
                                      Prod Facility
                                    </label>
                                    <select
                                      className={
                                        errors.prod_facility !=
                                        "This field is required"
                                          ? "form-control form-control-sm "
                                          : "form-control form-control-sm  has-error has-feedback"
                                      }
                                      name="prod_facility_edit"
                                      onChange={this.onChangeProdFacility}
                                      style={{ padding: 0 }}
                                      defaultValue={prod_facility_create}
                                      required
                                    >
                                      <option value="">Choose</option>
                                      {select_items_prod_facility &&
                                        select_items_prod_facility.map(
                                          function (item, i) {
                                            return (
                                              <option
                                                value={item.facility_code}
                                                key={i}
                                              >
                                                {item.facility_code} -{" "}
                                                {item.facility_desc}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    {errors.prod_facility_create && (
                                      <div className="invalid-feedback">
                                        {errors.date}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              {type_create == "RPP" ? (
                                <Row>
                                  <Col xs={12} md={12}>
                                    <label htmlFor="currency">Material</label>
                                    <div
                                      className={
                                        errors_create.vendor !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <Select
                                        onChange={this.handleChange.bind(
                                          this,
                                          "material_create"
                                        )}
                                        options={select_items_material}
                                        value={
                                          select_items_material.filter(
                                            (e) =>
                                              e.value === material_create_code
                                          ) || ""
                                        }
                                        isClearable={isClearable}
                                        ref={(ref) => (this.refMaterial = ref)}
                                      />

                                      {!isValidMaterialCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.vendor}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {type_create == "RPP" ? (
                                <Row>
                                  <Col xs={12} md={6}>
                                    <div
                                      className={
                                        errors_create.qty_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">Qty</label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="any"
                                        className="form-control form-control-sm"
                                        name="qty_create"
                                        value={qty_create || ""}
                                        onChange={this.onChangeValue}
                                        required
                                      />
                                      {errors_create.qty_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.qty_create}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <div
                                      className={
                                        errors_create.unit_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">UoM</label>
                                      <input
                                        className="form-control form-control-sm"
                                        name="unit_create"
                                        readOnly={true}
                                        value={unit_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors.unit_create && (
                                        <div className="invalid-feedback">
                                          {errors.date}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={12}>
                                    <div
                                      className={
                                        errors_create.qty_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Total Batch{" "}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="any"
                                        readOnly={true}
                                        className="form-control form-control-sm"
                                        name="total_batch"
                                        value={total_batch_format || "0"}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              <Row>
                                <Col xs={12} md={12}>
                                  <label htmlFor="currency">Date</label>
                                  <div
                                    className={
                                      errors_create.date_create !=
                                      "This field is required"
                                        ? "form-group"
                                        : "form-group has-error has-feedback"
                                    }
                                  >
                                    <DatePicker
                                      selected={date_create}
                                      disabledKeyboardNavigation
                                      minDate={
                                        date_create
                                          ? subDays(
                                              new Date(date_create),
                                              0
                                            ) !== subDays(new Date(), 0)
                                            ? subDays(new Date(), 0)
                                            : subDays(new Date(), 0)
                                          : subDays(new Date(), 0)
                                      }
                                      onChange={this.handleDate.bind(
                                        this,
                                        "date_create"
                                      )}
                                      name="date_create"
                                      wrapperClassName="datePickerCustom"
                                      dateFormat="dd MMMM yyyy"
                                      className="form-control form-control-sm"
                                      required
                                    />
                                    {errors.date_create && (
                                      <div className="invalid-feedback">
                                        {errors.date_create}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              {["CIP", "Maintenance"].includes(type_create) && (
                                <Row>
                                  <Col xs={12} md={12}>
                                    <div
                                      className={
                                        errors_create.duration !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Duration (hours)
                                      </label>
                                      <input
                                        min="1"
                                        type="number"
                                        className="form-control form-control-sm"
                                        name="duration_create"
                                        required
                                        value={duration_create}
                                        onChange={this.onChangeValue}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col xs={12} md={12}>
                                  <div className="form-group">
                                    <label htmlFor="reason">Remark</label>
                                    <textarea
                                      id="remark"
                                      className="form-control form-control-sm"
                                      name="remark"
                                      onChange={this.onChangeValue}
                                      rows="3"
                                    />

                                    {errors.remark && (
                                      <div className="invalid-feedback">
                                        {errors.remark}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              {type_create == "RPP" ? (
                                <Row>
                                  <Col xs={12} md={12}>
                                    <div
                                      className={
                                        errors_create.remaining_balance !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Beginning Balance
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        name="remaining_balance"
                                        value={remaining_balance || "0"}
                                        readOnly={true}
                                        onChange={this.onChangeValue}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {type_create == "RPP" ? (
                                <Row>
                                  <Col xs={12} md={12}>
                                    <div
                                      className={
                                        errors_create.safety_stock !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={!!cb_safety_stock_create}
                                          name="cb_safety_stock_create"
                                          onChange={this.handleChecklist}
                                        />{" "}
                                        Safety Stock
                                      </label>
                                      <input
                                        className="form-control form-control-sm"
                                        name="safety_stock_create"
                                        value={safety_stock_create || "0"}
                                        onChange={this.onChangeValue}
                                        readOnly={true}
                                      />
                                      {errors.safety_stock_create && (
                                        <div className="invalid-feedback">
                                          {errors.safety_stock_create}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              {type_create == "RPP" ? (
                                <Row>
                                  <Col xs={12} md={12}>
                                    <div
                                      className={
                                        errors_create.total_qty !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Total QTY
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="any"
                                        className="form-control form-control-sm"
                                        name="total_qty"
                                        value={total_qty || "0"}
                                        onChange={this.onChangeValue}
                                        readOnly={true}
                                      />
                                      {errors.total_qty && (
                                        <div className="invalid-feedback">
                                          {errors.total_qty}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col xs={12} md={8} className="float-right">
                              {list_produksi.length > 0 &&
                                ["CIP", "Maintenance"].includes(
                                  type_create
                                ) && (
                                  <Table
                                    className="float-right"
                                    bordered
                                    hover
                                    size="sm"
                                    style={{
                                      textAlign: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <thead>
                                      <tr key="0">
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          Material{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          QTY{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          UOM{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          Time{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          Placement{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {list_produksi &&
                                        list_produksi.map((x, i) => {
                                          if (x.isNew) {
                                            return (
                                              <tr key={i + 1}>
                                                {" "}
                                                <td
                                                  className="bg-success font-weight-bold"
                                                  colSpan={5}
                                                  key={`tn-3-${i + 1}`}
                                                >
                                                  NEW{" "}
                                                  {x.material_desc ?? x.type}{" "}
                                                  HERE
                                                </td>
                                              </tr>
                                            );
                                          } else {
                                            return (
                                              <tr key={i + 1}>
                                                <td key={`td-3-${i + 1}`}>
                                                  {x.material_desc}
                                                </td>
                                                <td key={`td-44-${i + 1}`}>
                                                  {`${x.total_qty} `}
                                                </td>
                                                <td key={`td-43-${i + 1}`}>
                                                  {`${x.unit ? x.unit : ""}`}
                                                </td>
                                                <td key={`td-4-${i + 1}`}>
                                                  {`${x.time_start} - ${x.time_finish}`}
                                                </td>
                                                <td key={`td-5-${i + 1}`}>
                                                  <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    name="up"
                                                    onClick={() =>
                                                      this.handlePlace(i, "up")
                                                    }
                                                  >
                                                    Before
                                                  </Button>{" "}
                                                  <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    name="down"
                                                    onClick={() =>
                                                      this.handlePlace(
                                                        i,
                                                        "down"
                                                      )
                                                    }
                                                  >
                                                    After
                                                  </Button>{" "}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        })}
                                    </tbody>
                                  </Table>
                                )}
                              {list_produksi.length > 0 &&
                                type_create == "RPP" && (
                                  <Table
                                    className="float-right"
                                    bordered
                                    hover
                                    size="sm"
                                    style={{
                                      textAlign: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <thead>
                                      <tr key="00">
                                        <th
                                          colSpan="4"
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          List RPP
                                        </th>
                                      </tr>
                                      <tr key="0">
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          No/Queue{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          Material{" "}
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          QTY{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {list_produksi &&
                                        list_produksi.map((x, i) => (
                                          <tr key={i + 1}>
                                            <td key={`td-5-${i + 1}`}>
                                              {i + 1}
                                            </td>
                                            <td key={`td-3-${i + 1}`}>
                                              {x.material_desc}
                                            </td>
                                            <td key={`td-44-${i + 1}`}>
                                              {`${x.total_qty} ${
                                                x.unit ? x.unit : ""
                                              }`}
                                            </td>
                                            <td
                                              key={`td-4-${i + 1}`}
                                              style={{ display: "none" }}
                                            >
                                              {`${x.time_start} - ${x.time_finish}`}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </Table>
                                )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <Button
                                variant="success"
                                onClick={this.handleSubmit}
                                className="float-right"
                              >
                                <i className="fas fa-save" /> Submit
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>
    );
  }
}

export default withRouter(ApsRpp);
