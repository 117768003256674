import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";

import "react-datepicker/dist/react-datepicker.css";

import { readApproval, updateApproval } from "../modules/ApprovalModule";

import { withRouter } from "react-router-dom";

import RemoteAll from "../components/RemoteAll";

class Approval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      readOnly: false,
      isValidMaterial: true,
      isValidVendor: true,
      isValidMaterialCreate: true,
      isValidVendorCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_vendor: [],
      select_items_uom: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      material: null,
      material_code: null,
      vendor: null,
      vendor_code: null,
      currency: null,
      price: null,
      unit: null,
      date: null,
      isValidMaterial_create: false,
      material_create: null,
      material_create_code: null,
      vendor_create: null,
      vendor_create_code: null,
      currency_create: null,
      price_create: null,
      unit_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "spot_date",
      sortOrder: "desc",
      safety_stock: null,
      interval_rpp_etd: null,
      duration_rpp: null,
      interval_eta_etd: null,
      configs: null,
      created_at: null,
      description: null,
      module_name: null,
      name: null,
      list_approval: [],
      list_approval_all: [],
      date_to: null,
      date_from: null,
      title: null,
      type: null,
      columns: [
        {
          dataField: "created_at",
          text: "Approval Date",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "requester",
          text: "Requester",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "name",
          text: "Name",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "department",
          text: "Department",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "status",
          text: "Status",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "created_at",
          order: "desc",
        },
      ],
      cellEditProps: {},
    };
    this.defaultState = this.state;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
    readApproval(user.username).then((response) => {
      this.setState({
        list_approval: [...response],
        list_approval_all: [...response],
      });
    });
  }

  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.created_at), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };

  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit } = this.state;
    return isEdit && row.status === "Waiting for Approval" ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.created_at,
              row.requester,
              row.department,
              row.name,
              row.description,
              row.to,
              row.date_from,
              row.date_to,
              row.title,
              row.type
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Action
        </button>
      </div>
    ) : (
      ""
    );
  };

  toDetail = (
    id,
    created_at,
    requester,
    department,
    name,
    description,
    to,
    date_from,
    date_to,
    title,
    type
  ) => {
    this.setState(
      {
        id: id,
        created_at: dateFormat(created_at, "dd-mm-yyyy"),
        requester: requester,
        department: department,
        name: name,
        description: description,
        to: to,
        date_from: date_from,
        date_to: date_to,
        title: title,
        type: type,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      created_at: null,
      requester: null,
      department: null,
      module_name: null,
      description: null,
      to: null,
      showModal: !this.state.showModal,
    });
  };

  //get data from backend
  reloadDataBackend = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    readApproval(user.username).then((response) => {
      this.setState({
        list_approval: [...response],
        list_approval_all: [...response],
      });
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
  };

  handleApprove = () => {
    const { id } = this.state;
    let collection = {
      _id: id,
      approved: true,
    };

    Swal.fire({
      title: "Approval",
      text: `Are you sure you want to approve this?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        updateApproval(collection)
          .then((response) => {
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "You have approved this!",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.onModalHide();
              this.reloadDataBackend();
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  handleReject = () => {
    const { id, module_name, requester, to } = this.state;
    let collection = {
      _id: id,
      approved: false,
    };

    Swal.fire({
      title: "Approval",
      text: `Are you sure you want to reject this?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        updateApproval(collection)
          .then((response) => {
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "You have rejected this!",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.onModalHide();
              this.reloadDataBackend();
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  render() {
    let {
      submitted_create,
      loading,
      created_at,
      description,
      requester,
      module_name,
      department,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
      columns,
      defaultSorted,
      cellEditProps,
      showModal,
      list_approval,
      name,
      date_to,
      date_from,
      title,
      type,
    } = this.state;

    const style_approval_title = {
      backgroundColor: "#e9f8fb",
      color: "black",
      fontWeight: "bold",
      margin: 5,
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              Approval Calendar
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <Table hover size="sm">
                      <tbody>
                        <tr>
                          <td style={style_approval_title}>Approval Date</td>
                          <td>{created_at}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Requester</td>
                          <td>{requester}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Department</td>
                          <td>{department}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Name</td>
                          <td>{name}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs={12} md={6}>
                    <Table hover size="sm">
                      <tbody>
                        <tr>
                          <td style={style_approval_title}>Material</td>
                          <td>{title}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Type</td>
                          <td>{type}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Move From</td>
                          <td>{date_from}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Move To</td>
                          <td>{date_to}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs={12} md={12}>
                    <Alert variant="info">{description}</Alert>
                    <Button
                      className="float-left"
                      variant="danger"
                      onClick={this.handleReject}
                    >
                      <i class="fa fa-times" aria-hidden="true"></i> Reject
                    </Button>
                    <Button
                      className="float-right"
                      variant="success"
                      onClick={this.handleApprove}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i> Approve
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Approval</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            {isRead && (
                              <div className="card-body">
                                <RemoteAll
                                  data={list_approval}
                                  columns={columns}
                                  defaultSorted={defaultSorted}
                                  cellEditProps={cellEditProps}
                                  page={page}
                                  sizePerPage={sizePerPage}
                                  totalSize={totalSize}
                                  onTableChange={this.handleTableChange}
                                />
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Approval);
