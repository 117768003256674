import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class Sticker {
  readStickerLnk(query_string) {
    return axios.get(API_URL_DEFAULT + "sticker?" + query_string, {
      headers: authHeader(),
    });
  }
  readStickerType(query_string) {
    return axios.get(API_URL_DEFAULT + "sticker/sticker-type?" + query_string, {
      headers: authHeader(),
    });
  }
  addMaterialSticker(payload) {
    return axios.post(API_URL_DEFAULT + "sticker", payload, {
      headers: authHeader(),
    });
  }
  editMaterialSticker(payload) {
    return axios.put(API_URL_DEFAULT + "sticker", payload, {
      headers: authHeader(),
    });
  }

  deleteMaterialSticker(payload) {
    return axios.delete(API_URL_DEFAULT + "sticker", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new Sticker();
