import BOMFGEXPService from "../services/bom_fg_exp.service";

export const addBOMFG = (payload) => {
    return BOMFGEXPService.create(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const readBOMFG = (query_string) => {
    return BOMFGEXPService.readBOMFG(query_string).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};
export const deleteBOMFG = (payload) => {
    return BOMFGEXPService.delete(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};
export const editBOMFG = (payload) => {
    return BOMFGEXPService.edit(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const readBOMFGSpec = (query_string) => {
    return BOMFGEXPService.readBOMFGSpec(query_string).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};


export const readBOMFGSpecPlant = (query_string) => {
    return BOMFGEXPService.readBOMFGSpecPlant(query_string).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};
