import jwt from "jsonwebtoken";
import { history } from "../helpers/history";
import authService from "./auth.service";

const reactiveToken = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return authService.refreshToken(user.username, user.password);
};
const checkLastAnyMinute = (token) => {
  let isLastMinute = false;
  let decodedToken = jwt.decode(token, process.env.REACT_APP_PRIVATE_KEY, {
    complete: true,
  });
  let dateNow = new Date().getTime();
  const calcSecond = (decodedToken.exp * 1000 - dateNow) / 1000;
  if (
    calcSecond > 0 &&
    calcSecond <= parseFloat(process.env.REACT_APP_SESSION_REFRESH_TOKEN)
  )
    isLastMinute = true;
  return { isLastMinute };
};
const checkSessionExpired = (token) => {
  let isExpired = false;
  let decodedToken = jwt.decode(token, process.env.REACT_APP_PRIVATE_KEY, {
    complete: true,
  });

  let dateNow = new Date().getTime();
  if (decodedToken.exp * 1000 < dateNow) isExpired = true;
  return { isExpired };
};
export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("accessToken"));
  const public_key = process.env.REACT_APP_PUBLIC_KEY;
  const private_key = process.env.REACT_APP_PRIVATE_KEY;
  const app_name = process.env.REACT_APP_NAME;
  const application_token = jwt.sign(
    {
      name: app_name,
      type: "private_apps",
    },
    private_key,
    { expiresIn: String(process.env.REACT_APP_SESSION_TIME) }
  );
  if (user && token.accessToken) {
    const showExpired = checkSessionExpired(token.accessToken);
    if (showExpired.isExpired) {
      localStorage.setItem("message", "Session is expired. Please login again");
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      history.push("/login");
      window.location.reload();
    }
    //reactiveToken
    const checkLastFiveMinute = checkLastAnyMinute(token.accessToken);
    if (checkLastFiveMinute.isLastMinute) reactiveToken();
    return {
      "x-public-key": public_key,
      "x-application-token": `Bearer ${application_token}`,
      "x-user-token": `Bearer ${token.accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      "x-public-key": public_key,
      "x-application-token": `Bearer ${application_token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
}
