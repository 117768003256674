import BOMPowderServiceEXP from "../services/bom_powder_exp.service";

export const addBOMPowder = (payload) => {
    return BOMPowderServiceEXP.create(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const readBOMPowder = (query_string) => {
    return BOMPowderServiceEXP.readBOMPowder(query_string).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};

export const deleteBOMPowder = (payload) => {
    return BOMPowderServiceEXP.delete(payload).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};
