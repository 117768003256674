import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class CurrencyService { 

  create(payload) {  
    return axios
      .post(API_URL_DEFAULT + "currency", payload,{ headers: authHeader()});
  }
  readCurrency() {   
    return axios
      .get(API_URL_DEFAULT + "currency/",{ headers: authHeader()});
  }
  readCurrencyWithoutRP(query_string) {   
    return axios
      .get(API_URL_DEFAULT + "currency_without_rp/Rp"+query_string,{ headers: authHeader()});
  }
  edit(payload) {  
    return axios
      .put(API_URL_DEFAULT + "currency", payload,{ headers: authHeader()});
  }

  delete(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "currency", {data:payload, headers: authHeader()});
  } 
  
}

export default new CurrencyService();