import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  readMaterial,
  readMaterialByType,
  readMaterialUomByCode,
} from "../modules/MaterialModule";
import { readVendor } from "../modules/VendorModule";
import { readUom } from "../modules/UomModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  addDailySpotPrice,
  editDailySpotPrice,
  readDailySpotPrice,
  overwriteDailySpotPrice,
  readQsDailySpotPrice,
} from "../modules/DailySpotPriceModule";
import RemoteAll from "../components/RemoteAll";
import { readApsConfig, addApsConfig } from "../modules/ApsConfigModule";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class ApsConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      readOnly: false,
      isValidMaterial: true,
      isValidVendor: true,
      isValidMaterialCreate: true,
      isValidVendorCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_vendor: [],
      select_items_uom: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      material: null,
      material_code: null,
      vendor: null,
      vendor_code: null,
      currency: null,
      price: null,
      unit: null,
      date: null,
      isValidMaterial_create: false,
      material_create: null,
      material_create_code: null,
      vendor_create: null,
      vendor_create_code: null,
      currency_create: null,
      price_create: null,
      unit_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "spot_date",
      sortOrder: "desc",
      safety_stock: null,
      interval_rpp_etd: null,
      duration_rpp: null,
      interval_eta_etd: null,
      approval_days: null,
      toll_out_vendor_production_days: null,
      toll_out_vendor_qc_days: null,
      toll_out_vendor_delivery_days: null,
      toll_out_qc_days: null,
      configs: null,
    };
    this.defaultState = this.state;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readApsConfig().then((response) => {
      this.setState({
        configs: response,
        safety_stock: response.safety_stock,
        interval_rpp_etd: response.interval_rpp_etd,
        duration_rpp: response.duration_rpp,
        interval_eta_etd: response.interval_eta_etd,
        approval_days: response.approval_days,
        toll_out_vendor_production_days:
          response.toll_out_vendor_production_days,
        toll_out_vendor_qc_days: response.toll_out_vendor_qc_days,
        toll_out_vendor_delivery_days: response.toll_out_vendor_delivery_days,
        toll_out_qc_days: response.toll_out_qc_days,
      });
    });

    this.setState({
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: true,
        });
      } else if (name == "material_create") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            unit_create: response[0].value,
            isValidMaterialCreate: true,
          });
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: false,
        });
      } else if (name == "material_create") {
        this.refUnit.value = null;
        this.setState({
          isValidMaterialCreate: false,
          unit_create: null,
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: false,
        });
      }
    }
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.spot_date), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let datenow = new Date();
    let is_noneditable = true;
    let date_db = new Date(row.spot_date);
    let currencyreadOnly = true;
    if (this.diff_date_on_day(date_db, datenow) <= 7) {
      is_noneditable = false;
    }
    const { isEdit } = this.state;
    //TODO REVERT BACK
    return isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.currency,
              row.material_name,
              row.material_code,
              row.vendor_name,
              row.vendor_code,
              row.spot_price,
              row.unit,
              date_db,
              is_noneditable,
              currencyreadOnly
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    let formatted_cell = isNaN(cell)
      ? ""
      : parseFloat(cell).toFixed(DECIMAL_PLACES);
    return <div>{thousandformat(formatted_cell, ".", ".", ",")}</div>;
  };

  toDetail = (
    id,
    currency,
    material,
    material_code,
    vendor,
    vendor_code,
    price,
    unit,
    date,
    currencyreadOnly
  ) => {
    this.setState(
      {
        id: id,
        currency: currency,
        material: material,
        material_code: material_code,
        vendor: vendor,
        vendor_code,
        vendor_code,
        date: date,
        price: price,
        unit: unit,
        currencyreadOnly: currencyreadOnly,
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      material: null,
      vendor: null,
      date: null,
      price: null,
      unit: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = (
    safety_stock,
    interval_rpp_etd,
    duration_rpp,
    interval_eta_etd,
    approval_days,
    toll_out_vendor_production_days,
    toll_out_vendor_qc_days,
    toll_out_vendor_delivery_days,
    toll_out_qc_days
  ) => {
    let errors = this.state.errors;
    if (interval_rpp_etd < 0) {
      errors.interval_rpp_etd = "Value cannot below 0";
    }
    if (safety_stock < 0) {
      errors.safety_stock = "Value cannot below 0";
    }
    if (duration_rpp < 0) {
      errors.duration_rpp = "Value cannot below 0";
    }
    if (interval_eta_etd < 0) {
      errors.interval_eta_etd = "Value cannot below 0";
    }
    if (approval_days < 0) {
      errors.approval_days = "Value cannot below 0";
    }
    if (toll_out_vendor_production_days < 0) {
      errors.toll_out_vendor_production_days = "Value cannot below 0";
    }
    if (toll_out_vendor_qc_days < 0) {
      errors.toll_out_vendor_delivery_days = "Value cannot below 0";
    }
    if (toll_out_vendor_delivery_days < 0) {
      errors.toll_out_vendor_delivery_days = "Value cannot below 0";
    }
    if (toll_out_qc_days < 0) {
      errors.toll_out_qc_days = "Value cannot below 0";
    }
    return errors;
  };

  //get data from backend
  reloadDataBackend = () => {
    this.form.reset();
    readApsConfig().then((response) => {
      this.setState({
        configs: response,
        safety_stock: response.safety_stock,
        interval_rpp_etd: response.interval_rpp_etd,
        duration_rpp: response.duration_rpp,
        submitted_create: false,
      });
    });
  };
  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
    const { date_first, date_second } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      material: "material_name",
      vendor: "vendor_name",
      spot_price: "spot_price",
      unit: "unit",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `date_first=${date_second_formatted}&date_second=${date_first_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }
    readQsDailySpotPrice(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      safety_stock,
      interval_rpp_etd,
      duration_rpp,
      interval_eta_etd,
      approval_days,
      toll_out_vendor_production_days,
      toll_out_vendor_qc_days,
      toll_out_vendor_delivery_days,
      toll_out_qc_days,
    } = this.state;
    if (
      !this.validateForm(
        safety_stock,
        interval_rpp_etd,
        duration_rpp,
        interval_eta_etd,
        approval_days,
        toll_out_vendor_production_days,
        toll_out_vendor_qc_days,
        toll_out_vendor_delivery_days,
        toll_out_qc_days
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      safety_stock: safety_stock,
      interval_rpp_etd: interval_rpp_etd,
      duration_rpp: duration_rpp,
      interval_eta_etd: interval_eta_etd,
      approval_days: approval_days,
      toll_out_vendor_production_days: toll_out_vendor_production_days,
      toll_out_vendor_qc_days: toll_out_vendor_qc_days,
      toll_out_vendor_delivery_days: toll_out_vendor_delivery_days,
      toll_out_qc_days: toll_out_qc_days,
    };
    if (
      requester &&
      safety_stock > 0 &&
      interval_rpp_etd > 0 &&
      duration_rpp > 0 &&
      interval_eta_etd > 0 &&
      approval_days > 0 &&
      toll_out_vendor_production_days > 0 &&
      toll_out_vendor_qc_days > 0 &&
      toll_out_vendor_delivery_days > 0 &&
      toll_out_qc_days > 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          addApsConfig(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              this.setState({ loading: false });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/calendar/config");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    let {
      errors,
      submitted_create,
      loading,
      isCreate,
      safety_stock,
      interval_rpp_etd,
      duration_rpp,
      interval_eta_etd,
      approval_days,
      toll_out_vendor_production_days,
      toll_out_vendor_qc_days,
      toll_out_vendor_delivery_days,
      toll_out_qc_days,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Configuration</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <Alert variant="secondary">Calendar</Alert>
                                <Row>
                                  <Col>
                                    <div
                                      className={
                                        errors.safety_stock
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      {" "}
                                      <label htmlFor="safety_stock">
                                        Safety Stock
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="safety_stock"
                                          required="required"
                                          value={safety_stock || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div className="invalid-feedback">
                                          {errors.safety_stock}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        errors.interval_rpp_etd
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="interval_rpp_to_etd">
                                        Interval RPP to ETD
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="interval_rpp_etd"
                                          value={interval_rpp_etd || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.interval_rpp_etd}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        errors.approval_days
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="approval_days">
                                        Approval Days
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="approval_days"
                                          value={approval_days || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.approval_days}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className={
                                        errors.duration_rpp
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="safety_stock">
                                        RPP duration
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="duration_rpp"
                                          value={duration_rpp || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            hour(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.duration_rpp}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        errors.interval_eta_etd
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="interval_eta_etd">
                                        Interval ETA to ETD
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="interval_eta_etd"
                                          value={interval_eta_etd || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.interval_eta_etd}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} md={1}></Col>

                              <Col xs={12} md={4}>
                                <Alert variant="secondary">Toll Out ETA</Alert>
                                <Row>
                                  <Col>
                                    <div
                                      className={
                                        errors.toll_out_vendor_production_days
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="toll_out_vendor_production_days">
                                        Vendor Production Days
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="toll_out_vendor_production_days"
                                          value={
                                            toll_out_vendor_production_days ||
                                            ""
                                          }
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {
                                            errors.toll_out_vendor_production_days
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        errors.toll_out_vendor_qc_days
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="toll_out_vendor_qc_days">
                                        Vendor QC Days
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="toll_out_vendor_qc_days"
                                          value={toll_out_vendor_qc_days || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.toll_out_vendor_qc_days}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        errors.toll_out_vendor_delivery_days
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="toll_out_vendor_delivery_days">
                                        Vendor Delivery Days
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="toll_out_vendor_delivery_days"
                                          value={
                                            toll_out_vendor_delivery_days || ""
                                          }
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.toll_out_vendor_delivery_days}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className={
                                        errors.toll_out_qc_days
                                          ? "form-group has-error has-feedback"
                                          : "form-group"
                                      }
                                    >
                                      <label htmlFor="toll_out_qc_days">
                                        QC Days
                                      </label>
                                      <div className="div input-group">
                                        <input
                                          type="number"
                                          step="1"
                                          className="form-control form-control-sm"
                                          name="toll_out_qc_days"
                                          value={toll_out_qc_days || ""}
                                          onChange={this.onChangeValue}
                                          min="0"
                                        />
                                        <div class="input-group-append">
                                          <span class="input-group-text form-control-sm">
                                            day(s)
                                          </span>
                                        </div>
                                        <div className="invalid-feedback">
                                          {errors.toll_out_qc_days}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Save
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(ApsConfig);
