import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApprovalService {
  read(query) {
    return axios.get(API_URL_DEFAULT + "approval/?to=" + query, {
      headers: authHeader(),
    });
  }
  readByEventId(query) {
    return axios.get(API_URL_DEFAULT + "approval/?event_id=" + query, {
      headers: authHeader(),
    });
  }
  create(payload) {
    return axios.post(API_URL_DEFAULT + "approval", payload, {
      headers: authHeader(),
    });
  }
  update(payload) {
    return axios.put(API_URL_DEFAULT + "approval", payload, {
      headers: authHeader(),
    });
  }
  sendApprovalToManagerPpc(payload) {
    return axios.post(API_URL_DEFAULT + "approval/sendApproval", payload, {
      headers: authHeader(),
    });
  }
}

export default new ApprovalService();
