import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BeginningFgBalanceService {

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "fg_balance", payload, { headers: authHeader() });
  }
  createOverwrite(payload) {
    return axios
      .post(API_URL_DEFAULT + "fg_balance/?type=overwrite", payload, { headers: authHeader() });
  }
  createBulk(payload) {
    return axios
      .post(API_URL_DEFAULT + "fg_balance/bulk", payload, { headers: authHeader() });
  }
  readBeginningFgBalance(first, second) {
    return axios
      .get(API_URL_DEFAULT + "fg_balance/?first=" + first + "&second=" + second, { headers: authHeader() });
  }
  readQsBeginningFgBalance(qs) {
    return axios
      .get(API_URL_DEFAULT + "fg_balance/?" + qs, { headers: authHeader() });
  }

  readCurrentBeginningFgBalance(currency) {
    return axios
      .get(API_URL_DEFAULT + "fg_balance/?currency=" + currency, { headers: authHeader() });
  }
  readActiveBeginningFgBalance() {
    return axios
      .get(API_URL_DEFAULT + "fg_balance/?active=active", { headers: authHeader() });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "fg_balance", payload, { headers: authHeader() });
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "fg_balance", { data: payload, headers: authHeader() });
  }
}

export default new BeginningFgBalanceService();
