import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialGroupService { 

  create(payload) {  
    return axios
      .post(API_URL_DEFAULT + "material_group", payload,{ headers: authHeader()});
  }
  readMaterialGroup() {   
    return axios
      .get(API_URL_DEFAULT + "material_group?sizePerPage=100000",{ headers: authHeader()});
  } 
  edit(payload) {  
    return axios
      .put(API_URL_DEFAULT + "material_group", payload,{ headers: authHeader()});
  }

  delete(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "material_group", {data:payload, headers: authHeader()});
  } 
  
}

export default new MaterialGroupService();