import MaterialAveragePriceService from "../services/materialaverageprice.service";

export const readMaterialAveragePrice = (date, material) => {
  return MaterialAveragePriceService.readMaterialAveragePrice(date, material).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readMaterialAveragePriceByMonth = (payload) => {
  return MaterialAveragePriceService.readMaterialAveragePriceByMonth(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
