import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import FinalSelect from "../components/FinalSelect";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { startOfMonth } from "date-fns";

import {
  readMaterial,
  read,
  readOutstanding,
} from "../modules/CheckNeedPOModule";
import { getNextMonthList } from "../helpers/dateHelper";
import CheckNeedTable from "../components/CheckNeedTable";
import CheckNeedTableSummary from "../components/CheckNeedTableSummary";

const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
`;
const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
`;
class CheckNeedPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isCreate: false,
      isEdit: false,
      isRead: false,
      isDelete: false,
      select_items_material: [],
      start_month: startOfMonth(new Date()),
      no_material_rm: null,
      isValid: { no_material_rm: true },
      num_month: [],
      data: [],
      total: {
        material_description_rm: null,
        value_month: [],
      },
      errors: {},
      outstanding: 0,
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const mrp_notif = JSON.parse(localStorage.getItem("to_po_contract"));
    const { start_month } = this.state;
    this.setState({
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
        isAdmin: check_access.isAdmin,
      });
    }
    const query = `start_month=${dateFormat(start_month, "yyyy-mm-dd")}`;
    // load material from db
    readMaterial(query).then((res) => {
      this.setState({
        select_items_material: res,
      });
    });
    const cr = `cron=ingredient_list&start_month=${dateFormat(
      start_month,
      "yyyy-mm-dd"
    )}`;
    readOutstanding(cr).then((res) => {
      this.setState({
        outstanding: res.count,
      });
    });
    this.setNextMonth(start_month);
  }
  setNextMonth = (start_month) => {
    const nextSixMonth = getNextMonthList(
      dateFormat(start_month, "yyyy-mm-dd"),
      6
    );
    return this.setState({
      num_month: nextSixMonth,
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState(
      {
        [name]: date,
      },
      () => {
        if (name == "start_month") {
          const queryMonth = dateFormat(date, "yyyy-mm-dd");
          readMaterial(`start_month=${queryMonth}`).then((response) => {
            this.setState({ select_items_material: response });
          });

          const cr = `cron=ingredient_list&start_month=${dateFormat(
            date,
            "yyyy-mm-dd"
          )}`;
          readOutstanding(cr).then((res) => {
            this.setState({
              outstanding: res.count,
            });
          });
        }
      }
    );
  };

  //handle change
  handleChange = (e) => {
    let isValid = this.state;
    const { name, value } = e;
    if (value) {
      isValid[name] = true;
      this.setState({
        [name]: value.value,
        isValid: isValid,
      });
    } else {
      isValid[name] = false;
      this.setState({
        [name]: null,
        isValid: isValid,
      });
    }
  };
  handleReset = () => {
    this.setState({
      isSearched: false,
      isDisabled: false,
      isExists: false,
      data: [],
      total: {
        material_description_rm: null,
        value_month: [],
      },
    });
  };
  handleSearch = () => {
    this.setState({
      isSearched: true,
      isDisabled: true,
      loading: true,
    });
    const { start_month, no_material_rm } = this.state;
    const query = `start_month=${dateFormat(
      start_month,
      "yyyy-mm-dd"
    )}&no_material_rm=${no_material_rm}`;
    read(query)
      .then((res) => {
        this.hitTotal(res);

        this.setNextMonth(start_month);
        return this.setState({
          loading: false,
          isExists: true,
          data: res,
        });
      })
      .catch((err) => {
        return this.setState({
          loading: false,
        });
      });
  };
  hitTotal = (res) => {
    const { select_items_material, no_material_rm } = this.state;
    let aoa = [];

    res.map((r) => {
      return aoa.push(r.value_month);
    });

    const aoaSum = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    const selectedMat = select_items_material.find(
      (sim) => sim.value == no_material_rm
    );
    const newData = {
      material_description_rm: selectedMat.label,
      value_month: aoaSum,
    };
    this.setState({
      total: newData,
    });
  };

  sum = (a, b) => {
    return a.map((v, i) => v + (b[i] || 0));
  };
  render() {
    let {
      no_material_rm,
      isSearched,
      loading,
      isRead,
      isValid,
      start_month,
      isExists,
      select_items_material,
      isClearable,
      isDisabled,
      data,
      num_month,
      total,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <form
                    autoComplete="off"
                    noValidate
                    ref={(form) => (this.form = form)}
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-header ">
                            <h3 className="card-title">Check Needs</h3>
                          </div>
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group row">
                                  <Col xs={12} md={4}>
                                    <label htmlFor="start_month">
                                      Start Month *{" "}
                                    </label>
                                  </Col>

                                  <Col xs={12} md={8}>
                                    <DatePicker
                                      selected={start_month}
                                      startDate={start_month}
                                      disabledKeyboardNavigation
                                      onChange={this.handleDate.bind(
                                        this,
                                        "start_month"
                                      )}
                                      name="start_month"
                                      dateFormat="MMMM yyyy"
                                      className="form-control form-control-sm"
                                      required
                                      showMonthYearPicker
                                      showFullMonthYearPicker
                                      popperContainer={Popper}
                                      calendarContainer={Calendar}
                                      disabled={!isSearched ? false : true}
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={4}>
                                <Col xs={12} md={12}>
                                  <FinalSelect
                                    label="Material *"
                                    name="no_material_rm"
                                    value={no_material_rm}
                                    placeholder="Select ..."
                                    selectOptions={select_items_material}
                                    isClearable={isClearable}
                                    handleChange={this.handleChange.bind(this)}
                                    isValid={isValid.no_material_rm}
                                    error=""
                                    isFormRow={true}
                                    zIndex="30"
                                    isDisabled={isDisabled}
                                  />
                                </Col>
                              </Col>
                              <Col xs={12} md={2}>
                                {!isSearched && (
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                )}
                                {isSearched && (
                                  <Button
                                    variant="danger"
                                    onClick={this.handleReset}
                                    className="float-left"
                                  >
                                    <i className="fas fa-history" /> Reset
                                  </Button>
                                )}
                              </Col>
                              <Col xs={12} md={3}>
                                <span class="btn btn-primary">
                                  OnProgess{" "}
                                  <span class="badge badge-dark">
                                    {this.state.outstanding}
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isSearched && isExists && !loading && (
                      <>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">Summary</h3>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <CheckNeedTableSummary
                                      num_month={num_month}
                                      total={total}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">Detail</h3>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <CheckNeedTable
                                      num_month={num_month}
                                      data={data}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {isSearched && !isExists && !loading && (
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                          <div className="card card-info">
                            <div className="card-body">
                              <Row>
                                <Col xs={12} md={12}>
                                  <h3
                                    style={{ color: "red" }}
                                    className="text-center"
                                  >
                                    {" "}
                                    Data is no exists!
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(CheckNeedPO);
