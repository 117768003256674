import React, { Component } from "react";

export default class Notification extends Component {
  render() {
    const { message, timestamp } = this.props;
    return (
      <div>
        <a href="#!" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> {message}
          <span className="float-right text-muted text-sm">{timestamp}</span>
        </a>
        <div className="dropdown-divider"></div>
      </div>
    );
  }
}
