import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "simulator/complain";

class ComplainService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE, payload, {
      headers: authHeader(),
    });
  }
}
export default new ComplainService();
