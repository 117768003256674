import React, { Component } from 'react';
import Footer from '../../components/templates/Footer';
import Header from '../../components/templates/Header';
import SideMenu from '../../components/templates/SideMenu';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dateFormat from 'dateformat';
import DatePicker from 'react-datepicker';
import ProgressBar from 'react-bootstrap/ProgressBar';

import 'react-datepicker/dist/react-datepicker.css';
import { Link, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { listMaterial } from '../../modules/sodiq/SimulatorModule';

class Simulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            isValidMaterial: true,
            material_code_option: [],
            material_code_default: [],
            material: [],
            list_material: [],
            temp_list_material: [],
            file_value: null
        }
    }

    componentDidMount() {

    }

    getMaterial = (inputValue) => {
        const getOptionMaterial = (search) => {
            return this.state.material_code_option.filter(
                e =>
                    (e.value.toUpperCase()).includes(search.toUpperCase()) ||
                    (e.material_description_rm.toUpperCase()).includes(search.toUpperCase())
            )
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(getOptionMaterial(inputValue));
            }, 1000);
        });
    }

    onChangeValue = (name, value) => {
        this.setState({ [name]: value.target.value });
    }

    handleChange = (value, index) => {
        let temp_material = this.state.material
        temp_material[index] = value
        this.setState({ material: temp_material });
        let temp_mat = [];
        this.state.temp_list_material.forEach((item) => {
            temp_mat = this.state.list_material
            let check = item.material.filter(e => e.no_material_rm === value.no_material_rm)
            if (check.length > 0) {
                temp_mat[index]=item
            }
            this.setState({ list_material: temp_mat });
        })

    }

    handleNewPrice = (value, index) => {
        let temp_material = this.state.material
        temp_material[index].new_price = parseInt(value.target.value)
        this.setState({ material: temp_material });
    }

    handleDate = (name, date) => {
        let params = {
            planning_month: dateFormat(date, 'mmmm'),
            planning_year: dateFormat(date, 'yyyy')
        }
        this.setState({
            [name]: date,
            material: [],
            material_code_option: [],
            price: '',
            new_price: '',
            list_material: []
        })
        listMaterial(new URLSearchParams(params)).then((res) => {
            let options = [];
            let list_table_material = [];
            if (res.price) {
                res.price.forEach((item) => {
                    if (item.status === 'Data is completed') {
                        item.material.forEach((elem) => {
                            const check = options.filter(e => e.no_material_rm === elem.no_material_rm)
                            if (check.length === 0) {
                                let temp = { ...elem, value: elem.no_material_rm, label: elem.material_description_rm}
                                options.push(temp)
                            }
                        })
                        list_table_material.push(item)
                    }
                });
            } else {
                options = []
                list_table_material = []
            }
            this.setState({
                material_code_option: options,
                temp_list_material: list_table_material
            });
        }).catch((err) => {
            this.setState({
                material_code_option: [],
                temp_list_material: []
            });
        })
    }

    add = () => {
        let temp_material = [ ...this.state.material, { 
            no_material_rm: '', 
            price: '', 
            material_description_rm: '', 
            amount_per_batch: '', 
            total: '',
            plat: null, 
            label: '',
            value: '',
            new_price: ''
        }]
        this.setState({ material : temp_material })
    }

    showToats = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'toats',
            onClose: () => this.setState({ submitted: false }),
        });
    }

    handleSimulate = () => {
        let checkMaterial = this.state.material.filter(e => e.value === '' || e.value === null || e.value === undefined)
        let checkPrice = this.state.material.filter(e => e.price === undefined || e.price === '')
        let checkNewPrice = this.state.material.filter(e => e.new_price === undefined || e.new_price === '')
        if (
            this.state.date !== undefined && this.state.date !== null &&
            checkMaterial.length <= 0 && checkPrice.length <= 0 && checkNewPrice.length <= 0
        ) {
            const listArr = [...this.state.list_material]
            listArr.forEach((item) => {
                let grandtotal = 0;
                let factor = 1 - (item.cogm / item.cogs)
                item.material.forEach((elem) => {
                    let elementPrice = elem.price
                    this.state.material.forEach((mat) => {
                        if (mat.value === elem.no_material_rm) {
                            elementPrice = mat.new_price
                        }
                    })
                    let headerKG = (elem.total * 1000) / (elementPrice * elem.amount_per_batch)
                    let subtotal = elementPrice * (headerKG / 1000) * elem.amount_per_batch
                    grandtotal += subtotal / headerKG
                })
                let cogm = grandtotal
                    + item.resource.gas_value
                    + item.resource.electricity_value
                    + item.resource.labor_value
                    + item.resource.machine_value
                    + item.resource.overhead_value
                item.price_after = (cogm / (1 - factor));
            })

            this.setState({ list_material: listArr })
        } else {
            if (this.state.date === null || this.state.date === undefined) {
                this.showToats('Please entries planning date')
            }
            if (checkMaterial.length > 0) {
                this.showToats('Please entries material')
            }
            if (checkPrice.length > 0) {
                this.showToats('Please entries price')
            }
            if (checkNewPrice.length > 0) {
                this.showToats('Please entries new price')
            }
        }
    }

    handleReset = () => {
        this.setState({
            date: null,
            material: null,
            material_code_option: [],
            price: '',
            new_price: '',
            list_material: []
        })
    }

    handleDelete = (index) => {
        let temp_material = this.state.material
        
        let temp_mat = []
        for (const item of this.state.list_material) {
            temp_mat = this.state.list_material
            let check = item.material.filter(e => e.no_material_rm === temp_material[index].no_material_rm)
            if (check.length > 0) {
                temp_mat.splice(index, 1);
                break
            }
            this.setState({ list_material: temp_mat });
        }

        temp_material.splice(index, 1)
        this.setState({ material: temp_material})
    }

    render() {
        let {
            loading,
            date,
            material_code_default,
            material_code_option,
            isValidMaterial,
            file_value
        } = this.state
        
        const customStylesMaterial = {
            option: provided => ({
                ...provided,
                color: 'black',
            }),
            control: (base, state) => ({
                ...base,
                borderColor: state.isFocused
                    ? '#ddd'
                    : isValidMaterial
                        ? '#ddd'
                        : 'red',
                // overwrittes hover style
                '&:hover': {
                    borderColor: state.isFocused
                        ? '#ddd'
                        : isValidMaterial
                            ? '#ddd'
                            : 'green',
                },
            }),
        }

        return (
            <div className='sidebar-mini sidebar-collapse text-sm'>
                <div className='wrapper'>
                    <ToastContainer />
                    <Modal
                        show={loading}
                        size='xl'
                        backdrop='static'
                        onHide={this.onModalLoadingHide}
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                        keyboard={false}
                    >
                        <ModalHeader className='bg-info text-white'></ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <ProgressBar animated now={100} />
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>

                    <Header />
                    <SideMenu />
                    <div className='content-wrapper'>
                        <div className='content-header'>
                            <div className='container-fluid'>
                                <div className='row mb-2'>
                                    <div className='col-sm-6'>
                                        <h1 className='m-0'>Simulator Price</h1>
                                    </div>
                                    <div className='col-sm-6'>
                                        <ol className='breadcrumb float-sm-right'>
                                            <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                                            <li className='breadcrumb-item active'>simulator price</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className='content'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <div className='card card-info'>
                                            <div className='card-header '>
                                                <h3 className='card-title'>Adjustment</h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='form-group'>
                                                    <label className='col-md-6 col-form-label'>Planning Month</label>
                                                    <div className='input-group col-md-4'>
                                                        <DatePicker
                                                            selected={date}
                                                            startDate={date}
                                                            disabledKeyboardNavigation
                                                            onChange={this.handleDate.bind(this, 'date')}
                                                            name='date'
                                                            wrapperClassName='datePickerCustom'
                                                            dateFormat='MMMM yyyy'
                                                            className='form-control'
                                                            required
                                                            showMonthYearPicker
                                                        />
                                                        <span className='error invalid-feedback'>Please entries planning month</span>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='col-md-6 col-form-label'>Private Key</label>
                                                    <div className="input-group col-md-4">
                                                        <div className='custom-file'>
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="exampleInputFile"
                                                                
                                                                
                                                                value={file_value}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="exampleInputFile"
                                                            >
                                                                Choose file
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.material.map((item, index) => {
                                                        return (
                                                            <div className='form-group row'>
                                                                <div className='col-md-4'>
                                                                    <label className='col-form-label'>Material</label>
                                                                    <div className='input-group' key={index}>
                                                                        <div className='col-md-12'>
                                                                            <AsyncSelect
                                                                                ref={this.selectRef}
                                                                                isClearable={true}
                                                                                styles={customStylesMaterial}
                                                                                single
                                                                                cacheOptions
                                                                                defaultOptions={material_code_option}
                                                                                loadOptions={this.getMaterial}
                                                                                onChange={(e) => this.handleChange(e, index)}
                                                                                value={item}
                                                                                thema='info'
                                                                                className='loading'
                                                                                defaultValue={material_code_default || ''}
                                                                                placeholder='Choose material'
                                                                            />
                                                                            <span className='error invalid-feedback'>Please entries material</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label className='col-form-label'>Price/kg</label>
                                                                    <div className='input-group' key={index}>
                                                                        <input value={item?.price} type='text' className='form-control' readOnly />
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>USD</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label className='col-form-label'>Adjustment Price/kg</label>
                                                                    <div className='input-group' key={index}>
                                                                        <input onChange={(e) => this.handleNewPrice(e, index)} defaultValue={item?.new_price} type='number' className='form-control'/>
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>USD</span>
                                                                        </div>
                                                                    </div>
                                                                    <span className='error invalid-feedback'>Please entries new price/kg</span>
                                                                </div>
                                                                <div className='d-flex align-items-end'>
                                                                    <div className='input-group' key={index}>
                                                                        <Button variant='outline-danger' key={index} onClick={() => this.handleDelete(index)} size='lg'>-</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                <div className='mb-3'>
                                                    <Button variant='outline-primary' onClick={this.add}>Add</Button>
                                                </div>
                                                <div className='d-flex'>
                                                    <Button
                                                        className='col-md-12'
                                                        onClick={this.handleSimulate}
                                                        variant='info'>
                                                        <i className='fas fa-calculator' /> Simulate
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-6">
                                        <div className='card card-info'>
                                            <div className='card-header '>
                                                <h3 className='card-title'>Result</h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='col-12 row'>
                                                    <table className='table table-striped table-bordered'>
                                                        <thead>
                                                            <tr >
                                                                <th rowSpan={2} className='rowspan-center'>No.</th>
                                                                <th rowSpan={2} className='rowspan-center'>Material Code</th>
                                                                <th rowSpan={2} className='rowspan-center'>Material Description</th>
                                                                <th colSpan={2} className='text-center' width='40%'>Price</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Before</th>
                                                                <th>After</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.list_material.length > 0 ? this.state.list_material.map((item, i) => (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{item.material_code}</td>
                                                                    <td>{item.material_desc}</td>
                                                                    <td>{item.cogs}</td>
                                                                    <td>{item.price_after}</td>
                                                                </tr>
                                                            )) : ''}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div >
            </div >
        );
    }
}

export default withRouter(Simulator);
