export const DefaultRowPO = {
  children: [
    {
      readOnly: false,
      data_type: "text",
      value: null,
      name: null,
      label: "No",
    },
    {
      readOnly: false,
      placeholder: "PO",
      data_type: "text",
      value: null,
      name: "po",
      label: "PO",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "price_kg",
      label: "Price/KG",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "beginning_balance",
      label: "Beginning Balance",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_1",
      label: "1st Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_2",
      label: "2nd Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_3",
      label: "3rd Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_4",
      label: "4th Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_5",
      label: "5th Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_6",
      label: "6th Month",
    },
    {
      readOnly: true,
      data_type: "number",
      value: null,
      name: null,
      label: null,
    },
  ],
} 
export const DefaultRowContract = {
  children: [
    {
      readOnly: false,
      data_type: "text",
      value: null,
      name: null,
      label: "No",
    },
    {
      readOnly: false,
      placeholder: "Contract",
      data_type: "text",
      value: null,
      name: "contract",
      label: "Contract",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "price_kg",
      label: "Price/KG",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "beginning_balance",
      label: "Beginning Balance",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_1",
      label: "1st Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_2",
      label: "2nd Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_3",
      label: "3rd Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_4",
      label: "4th Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_5",
      label: "5th Month",
    },
    {
      readOnly: false,
      data_type: "number",
      value: null,
      name: "n_6",
      label: "6th Month",
    },
    {
      readOnly: true,
      data_type: "number",
      value: null,
      name: null,
      label: null,
    },
  ],
}