import React, { Component } from "react";
import RemoteAll from "../components/RemoteAll";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import APSPO from "../components/APSPO";

import "react-datepicker/dist/react-datepicker.css";

import { readMaterialByCode } from "../modules/MaterialModule";
import { readCustomer } from "../modules/CustomerModule";
import { readSalesForecas } from "../modules/SalesForecastModule";
import { readUom } from "../modules/UomModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Select from "react-select";
import {
  addETA,
  readETA,
  editETA,
  deleteETA,
  confirmCancelETA,
  confirmUpdateETA,
} from "../modules/ETAModule";

class EstimateDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModalEdit: false,
      showModal: false,
      showSpotPriceOutlook: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      isValidParameter: true,
      isValidParameterCreate: true,
      isClearable: true,
      collection: {},
      select_items_paramater: [],
      select_items_customer: [],
      select_items_material: [],
      select_items_material_edit: [],
      select_items_uom: [],
      select_items_shipped_to: [],
      select_items_shipped_to_edit: [],
      list_datatable: [],
      data_po: [],
      data_eta: [],
      data_po_edit: [],
      data_eta_edit: [],
      columns_po: [
        {
          text: "PO Number *",
          type: "po_number",
        },
        {
          text: "Quantity *",
          type: "quantity",
        },
        {
          text: "UOM *",
          type: "uom",
        },
        {
          text: "Act",
          type: "del",
        },
      ],
      columns_eta: [
        {
          text: "ETA *",
          type: "eta",
        },
        {
          text: "Quantity *",
          type: "quantity",
        },
        {
          text: "UOM *",
          type: "uom",
        },
        {
          text: "Act",
          type: "del",
        },
      ],
      columns_eta_except_uom: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "month",
      sortOrder: "asc",
      count_data: "",
      current_page: 1,
      errors: {},
      errors_create: {},

      id: "",
      date: null,
      customer_create: "",
      shipped_to_create: "",
      customer_create_code: "",
      shipped_to_create_code: "",
      isValidCustomerCreate: true,
      isValidShippedToCreate: true,
      material_create: "",
      material_create_code: "",
      isValidMaterialCreate: true,
      quantity_create: "",
      uom_create: "",
      qty_etd: [],

      quantity_available: "",
      quantity_remaining: "",
      isValidQuantityRemaining: true,
      isQuantityRemainingSisa: false,
      error_message_quantity_remaining: "",

      customer_edit: "",
      shipped_to_edit: "",
      customer_edit_code: "",
      shipped_to_edit_code: "",
      isValidCustomeredit: true,
      isValidShippedToedit: true,
      material_edit: "",
      material_edit_code: "",
      isValidMaterialedit: true,
      quantity_edit: "",
      remaining_edit: "",
      remaining_edit_to_send: "",
      remaining_edit_unreduced: "",
      uom_edit: "",
      uom_code_edit: "",
      qty_etd_edit: [],

      sales_forecast: [],

      total_eta_qty: "",

      customer_search_code: "",

      requester: "",
      material: "",
      date_edit: null,
      date_create: null,
      columns: [
        {
          dataField: "month",
          text: "Month",
          formatter: this.GetMonthFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "customer_name",
          text: "Sold To",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "shipped_to_name",
          text: "Shipped To",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "remaining",
          text: "Remaining",
          formatter: this.GetRemainingFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "customer",
          order: "asc",
        },
      ],
      cellEditProps: {},
      remark: "",
      remark_edit: "",
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readCustomer().then((response) => {
      this.setState({ select_items_customer: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readETA(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

    this.setState({
      date_create: new Date(),
      requester: user.username,
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state eta
  onChangeETA = (any) => {
    let index = any.target.dataset.index;
    let eta = this.state.eta;
    eta[index] = any.target.value;
  };

  //month format on datatable
  GetMonthFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.month), "mmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //remaining format on datatable
  GetRemainingFormat = (cell, row) => {
    let font_color = row.remaining > 0 ? "black" : "green";
    return <div style={{ color: font_color }}>{row.remaining}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit } = this.state;
    if (row.cancelled_by)
      return (
        <span
          style={{ padding: "5px", color: "white", backgroundColor: "orange" }}
          className="btn btn-sm"
        >
          Waiting for ETD &amp; RPP is cancelled
        </span>
      );
    return isEdit ? (
      <div>
        <button
          key={row.id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toEdit(
              row._id,
              row.month,
              row.customer_code,
              row.customer_name,
              row.material_code,
              row.material_name,
              row.quantity,
              row.uom,
              row.remaining,
              row.po,
              row.eta,
              row.total_quantity,
              row.remaining + row.total_quantity,
              row.shipped_to_code,
              row.shipped_to_name,
              row.remark
            );
          }}
        >
          <i className="fas fa-edit" key={row.id}></i>
          &nbsp;Edit
        </button>
        <button
          key={row.id}
          type="button"
          className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.deleteData(row._id);
          }}
        >
          <i className="fas fa-trash" key={row.id}></i>
          &nbsp;Cancel
        </button>
      </div>
    ) : (
      ""
    );
  };
  //handle change
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState(
        {
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        },
        () => {
          if (name == "material_create") {
            this.refShippedTo.state.value.value = "";
            this.refShippedTo.state.value.label = "";
            readCustomer().then((response) => {
              this.setState({ select_items_shipped_to: response });
            });
            readMaterialByCode(newValue.value).then((response) => {
              this.setState({
                uom_create: response.uom,
                isValidMaterialCreate: true,
              });
            });
            let query_string = `customer_code=${this.state.customer_create_code}&material_fg_code=${newValue.value}`;
            readSalesForecas(query_string).then((response) => {
              this.setState(
                {
                  sales_forecast: response,
                },
                () => {
                  this.calculateRemainingQuantity();
                }
              );
            });
          }
        }
      );
      if (name == "customer_create") {
        let query_string = `customer_code=${newValue.value}`;
        readSalesForecas(query_string).then((response) => {
          let option_shipped_to = response;
          this.refShippedTo.state.value.value = "";
          this.refShippedTo.state.value.label = "";
          readCustomer().then((response) => {
            this.setState({ select_items_customer: response });
          });
          this.setState({
            select_items_material: option_shipped_to,
            shipped_to_create_code: null,
            select_items_shipped_to: [],
            isValidCustomerCreate: true,
          });
        });

        query_string = `getShippedTo=${newValue.value}`;
        readSalesForecas(query_string).then((response) => {
          let option_shipped_to = response;
          this.setState({
            select_items_shipped_to: option_shipped_to,
            isValidCustomerCreate: true,
          });
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
    }
  };
  //handle delete

  deleteData = (id) => {
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteETA(collection)
            .then(() => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-eta-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-eta-failed",
              });
              this.setState({
                loading: false,
              });
              Swal.fire({
                title: "Are you sure to cancel?",
                text: err,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Yes, cancel entries",
              }).then((res) => {
                if (res.value) {
                  confirmCancelETA(collection)
                    .then(() => {
                      Swal.fire({
                        title: "Information",
                        icon: "success",
                        text: "Data has been cancelled successfully",
                        showConfirmButton: false,
                        timer: 2000,
                      }).then(() => {
                        this.reloadDataBackend();
                      });
                    })
                    .catch((err) => {
                      toast.error(err, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId: "customId-confirm-delete-eta-failed",
                      });
                      this.setState({
                        loading: false,
                      });
                    });
                } else if (res.dismiss === Swal.DismissReason.cancel) {
                  Swal.fire("Information", "Your data is safe :)", "error");
                }
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  //thousand delimiter format
  GetThousandDelimiterFormat = (cell) => {
    return <div>{thousandformat(isNaN(cell) ? "" : cell, ".", ".", ",")}</div>;
  };

  //calculate remaining quantity
  calculateRemainingQuantity = () => {
    let forecast = this.state.sales_forecast;
    const found = forecast.find(
      (element) => element.material_fg_code == this.state.material_create_code
    );
    const { date, material_create_code, customer_create_code } = this.state;
    if (date && material_create_code) {
      let short_month = date.toLocaleString("en-US", { month: "short" });
      short_month = short_month.toLowerCase();
      let remaining = parseInt(found.forecast[short_month]);
      let query_string = `month=${date}&customer_code=${customer_create_code}&material_code=${material_create_code}`;
      readETA(query_string).then((response) => {
        if (response.countData > 0) {
          let pengurang = response.foundData.reduce(function (
            accumulator,
            item
          ) {
            return accumulator + parseInt(item.total_quantity || 0);
          },
          0);
          remaining = remaining - pengurang;
        }
        let quantity_remaining = remaining;
        if (this.state.data_eta.length > 0) {
          let total_qty_eta = this.state.data_eta.reduce(function (
            accumulator,
            item
          ) {
            return accumulator + parseInt(item.quantity || 0);
          },
          0);
          quantity_remaining = remaining - total_qty_eta;
        }
        this.setState({
          quantity_available: remaining,
          quantity_remaining: quantity_remaining,
          isValidQuantityRemaining: quantity_remaining >= 0 ? true : false,
          quantity_create: parseInt(found.forecast[short_month]),
        });
      });
    }
  };

  toEdit = (
    id,
    month,
    customer_code,
    customer_name,
    material_code,
    material_name,
    quantity,
    uom,
    remaining,
    po,
    eta,
    total_quantity,
    remaining_unreduced,
    shipped_to_code,
    shipped_to_name,
    remark_edit
  ) => {
    const remaining_copied = remaining;
    this.setState({
      id: id,
      date_edit: new Date(month),
      customer_edit_code: customer_code,
      customer_edit_name: customer_name,
      shipped_to_edit_code: shipped_to_code,
      shipped_to_edit_name: shipped_to_name,
      material_edit_code: material_code,
      material_edit_name: material_name,
      quantity_edit: quantity,
      uom_edit: uom,
      remaining_edit: remaining,
      data_po_edit: [...po],
      data_eta_edit: [...eta],
      total_eta_qty_edit: total_quantity,
      remaining_edit_unreduced: remaining_unreduced,
      remaining_edit_to_send: remaining_copied,
      remark_edit,

      select_items_material_edit: [
        { value: material_code, label: material_name },
      ],
    });
    this.toggle();
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  onModalHide = () => {
    this.setState({
      id: null,
      submitted_edit: false,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateFormCreate = (
    date,
    customer_create_code,
    shipped_to_create_code,
    material_create_code,
    data_po,
    data_eta,
    quantity_remaining
  ) => {
    let errors = this.state.errors_create;
    errors = {};
    if (!date) {
      this.setState({ isValidMonth: false });
    }
    if (!customer_create_code) {
      errors.customer = "This is field required";
      this.setState({ isValidCustomerCreate: false });
    }
    if (!shipped_to_create_code) {
      errors.shipped_to = "This is field required";
      this.setState({ isValidShippedToCreate: false });
    }
    if (!material_create_code) {
      errors.material = "This is field required";
      this.setState({ isValidMaterialCreate: false });
    }
    if (data_po.length > 0) {
      for (const d_po of data_po) {
        for (const c_po of this.state.columns_po) {
          if (c_po.type == "uom" || c_po.type == "del") {
            continue;
          } else if (c_po.type == "quantity") {
            if (d_po[c_po.type] < 0) {
              errors.po_quantity_negative = true;
              break;
            }
          }
          if (!d_po[c_po.type]) {
            errors.po_ada_kosong = true;
            break;
          }
        }
      }
    }
    if (data_eta.length == 0) {
      errors.eta_kosong = true;
      alert("ETA at least 1");
    } else {
      for (const d_eta of data_eta) {
        for (const c_eta of this.state.columns_eta) {
          if (c_eta.type == "uom" || c_eta.type == "del") {
            continue;
          } else if (c_eta.type == "quantity") {
            if (d_eta[c_eta.type] < 0) {
              errors.eta_quantity_negative = true;
              break;
            }
          }
          if (!d_eta[c_eta.type]) {
            errors.eta_ada_kosong = true;
            break;
          }
        }
      }
    }
    if (quantity_remaining == null) {
      errors.quantity_remaining = "This is field required";
      this.state.error_message_quantity_remaining = "This is field required";
      this.state.isValidQuantityRemaining = false;
    } else {
      if (quantity_remaining > 0) {
        errors.quantity_remaining_sisa = true;
        this.state.isValidQuantityRemaining = true;
      } else {
        errors.quantity_remaining_sisa = false;
        this.state.error_message_quantity_remaining = false;
        this.state.isValidQuantityRemaining = true;
      }
      if (quantity_remaining < 0) {
        errors.quantity_remaining = "Should not under 0";
        this.state.error_message_quantity_remaining = "Should not under 0";
        this.state.isValidQuantityRemaining = false;
      }
    }

    return errors;
  };
  // validate edit
  validateFormEdit = (
    date,
    customer_edit_code,
    material_edit_code,
    data_po,
    data_eta,
    total_eta_qty_edit,
    shipped_to_edit_code
  ) => {
    let errors = {};
    if (!date) {
      this.setState({ isValidMonth: false });
    }
    if (!customer_edit_code) {
      errors.customer = "This is field required";
      this.setState({ isValidCustomeredit: false });
    }
    if (!shipped_to_edit_code) {
      errors.customer = "This is field required";
      this.setState({ isValidShippedToedit: false });
    }
    if (!material_edit_code) {
      errors.material = "This is field required";
      this.setState({ isValidMaterialedit: false });
    }
    if (data_po.length > 0) {
      for (const d_po of data_po) {
        for (const c_po of this.state.columns_po) {
          if (c_po.type == "uom" || c_po.type == "del") {
            continue;
          } else if (c_po.type == "quantity") {
            if (d_po[c_po.type] < 0) {
              errors.po_quantity_negative = true;
              break;
            }
          }
          if (!d_po[c_po.type]) {
            errors.po_ada_kosong = true;
            break;
          }
        }
      }
    }
    if (data_eta.length == 0) {
      errors.eta_kosong = true;
      alert("ETA at least 1");
    } else {
      for (const d_eta of data_eta) {
        for (const c_eta of this.state.columns_eta) {
          if (c_eta.type == "uom" || c_eta.type == "del") {
            continue;
          } else if (c_eta.type == "quantity") {
            if (d_eta[c_eta.type] < 0) {
              errors.eta_quantity_negative = true;
              break;
            }
          }
          if (!d_eta[c_eta.type]) {
            errors.eta_ada_kosong = true;
            break;
          }
        }
      }
    }

    if (total_eta_qty_edit > this.state.quantity_edit) {
      errors.total_eta_qty_edit_kelebihan = true;
    } else if (total_eta_qty_edit < this.state.quantity_edit) {
      errors.total_eta_qty_edit_kurang = true;
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    this.setState({
      submitted_create: false,
      date: null,
      customer_create_code: null,
      shipped_to_create_code: null,
      material_create_code: null,
      quantity_create: null,
      quantity_available: 0,
      quantity_remaining: 0,
      uom_create: "",
      data_po: [],
      data_eta: [],
      total_eta_qty: null,
      remark: "",
    });

    const { customer_search_code, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (customer_search_code) {
      query_string += `&customer_code=${customer_search_code}`;
    }
    readETA(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.state.data_eta.forEach((element) => {
      element.eta = "";
    });
    this.setState(
      {
        [name]: date,
        isDownloadable: true,
      },
      this.calculateRemainingQuantity
    );
  };
  onChangeRemark = (e) => {
    this.setState({ remark: e.target.value });
  };
  onChangeRemarkEdit = (e) => {
    this.setState({ remark_edit: e.target.value });
  };
  //submit form
  handleSubmit = () => {
    this.setState({ submitted_create: true });
    const {
      date,
      customer_create_code,
      shipped_to_create_code,
      material_create_code,
      uom_create,
      data_po,
      data_eta,
      quantity_remaining,
    } = this.state;

    let validate_result = this.validateFormCreate(
      date,
      customer_create_code,
      shipped_to_create_code,
      material_create_code,
      data_po,
      data_eta,
      quantity_remaining
    );
    this.setState({ errors_create: validate_result });

    let key_error = Object.keys(validate_result);
    let key_error_except_quantity_remaining = key_error.filter((element) => {
      return element != "quantity_remaining_sisa";
    });

    if (key_error_except_quantity_remaining.length > 0) {
      return;
    }

    if (validate_result.quantity_remaining_sisa) {
      Swal.fire({
        title: "Remaining " + quantity_remaining + " " + uom_create,
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.createETA();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.createETA();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  createETA = () => {
    const {
      requester,
      date,
      customer_create_code,
      customer_create,
      shipped_to_create_code,
      shipped_to_create,
      material_create_code,
      material_create,
      quantity_create,
      uom_create,
      data_po,
      data_eta,
      total_eta_qty,
      quantity_remaining,
      remark,
    } = this.state;

    let collection = {
      requester: requester,
      month: date,
      customer_code: customer_create_code,
      customer_name: customer_create,
      shipped_to_code: shipped_to_create_code,
      shipped_to_name: shipped_to_create,
      material_code: material_create_code,
      material_name: material_create,
      quantity: quantity_create,
      uom: uom_create,
      po: data_po,
      eta: data_eta,
      total_quantity: total_eta_qty,
      remaining: quantity_remaining,
      remark,
    };
    addETA(collection)
      .then(() => {
        toast.success("Data has been saved successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-factor-success",
        });
        Swal.fire({
          title: "Information",
          icon: "success",
          text: "Data has been saved successfully",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.reloadDataBackend();
        });
      })
      .catch((err) => {
        let msg_arr = err.includes("Success") && err.split(":");
        toast.error(err, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-daily-spot-price-failed",
        });
        Swal.fire({
          title: err.includes("Success") ? msg_arr[0] : err,
          text: err.includes("Success") ? msg_arr[1] : null,
          icon: err.includes("Success") ? "info" : "error",
        });
        err.includes("Success")
          ? this.reloadDataBackend()
          : this.calculateRemainingQuantity();
        this.setState({
          loading: false,
        });
      });
  };

  updateETA = () => {
    const {
      id,
      requester,
      date_edit,
      customer_edit_code,
      customer_edit_name,
      material_edit_code,
      material_edit_name,
      quantity_edit,
      uom_edit,
      data_po_edit,
      data_eta_edit,
      total_eta_qty_edit,
      shipped_to_edit_code,
      shipped_to_edit_name,
      remaining_edit_to_send,
      remark_edit,
    } = this.state;

    let collection = {
      id: id,
      requester: requester,
      month: date_edit,
      customer_code: customer_edit_code,
      customer_name: customer_edit_name,
      material_code: material_edit_code,
      material_name: material_edit_name,
      shipped_to_code: shipped_to_edit_code,
      shipped_to_name: shipped_to_edit_name,
      quantity: quantity_edit,
      uom: uom_edit,
      po: data_po_edit,
      eta: data_eta_edit,
      total_quantity: total_eta_qty_edit,
      remaining: remaining_edit_to_send,
      remark: remark_edit,
    };

    editETA(collection)
      .then(() => {
        toast.success("Data has been saved successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-factor-success",
        });
        Swal.fire({
          title: "Information",
          icon: "success",
          text: "Data has been saved successfully",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.reloadDataBackend();
          this.toggle();
          this.setState({ submitted_edit: false });
        });
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-daily-spot-price-failed",
        });
        this.setState({
          loading: false,
        });

        Swal.fire({
          title: "Are you sure to update ?",
          text: err,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes, update entries",
        }).then((res) => {
          if (res.value) {
            confirmUpdateETA(collection)
              .then(() => {
                Swal.fire({
                  title: "Information",
                  icon: "success",
                  text: "Data has been saved successfully",
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  this.reloadDataBackend();
                  this.toggle();
                  this.setState({ submitted_edit: false });
                });
              })
              .catch((err) => {
                toast.error(err, {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-confirm-update-eta-failed",
                });
                this.setState({
                  loading: false,
                });
              });
          } else if (res.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Information", "Your data is safe :)", "error");
          }
        });
      });
  };

  //submit form edit
  handleSubmitEdit = () => {
    this.setState({ submitted_edit: true });
    const {
      date_edit,
      customer_edit_code,
      shipped_to_edit_code,
      material_edit_code,
      uom_edit,
      data_po_edit,
      data_eta_edit,
      total_eta_qty_edit,
      remaining_edit_to_send,
    } = this.state;

    let validate_result = this.validateFormEdit(
      date_edit,
      customer_edit_code,
      material_edit_code,
      data_po_edit,
      data_eta_edit,
      total_eta_qty_edit,
      shipped_to_edit_code
    );
    if (validate_result.eta_ada_kosong || validate_result.po_ada_kosong) {
      alert("Please check your input again.");
      return;
    } else if (
      validate_result.po_quantity_negative ||
      validate_result.eta_quantity_negative
    ) {
      alert("Quantity must be positive number");
    }

    let key_error = Object.keys(validate_result);
    let key_error_except_quantity_remaining = key_error.filter((element) => {
      return element != "total_eta_qty_edit_kurang";
    });

    if (key_error_except_quantity_remaining.length > 0) {
      return;
    }

    if (validate_result.total_eta_qty_edit_kurang) {
      Swal.fire({
        title: "Remaining " + remaining_edit_to_send + " " + uom_edit,
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.updateETA();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.updateETA();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //handle search
  handleSearch = () => {
    const { customer_search_code, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (customer_search_code) {
      query_string += `&customer_code=${customer_search_code}`;
    }

    readETA(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
    });

    const { customer_search_code } = this.state;
    let query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (customer_search_code) {
      query_string += `&customer_search=${customer_search_code}`;
    }
    readETA(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      list_datatable,
      select_items_customer,
      select_items_material,
      select_items_material_edit,
      select_items_uom,
      select_items_shipped_to,
      select_items_shipped_to_edit,

      date,
      customer_create_code,
      shipped_to_create_code,
      isValidCustomerCreate,
      isValidShippedToCreate,
      material_create_code,
      isValidMaterialCreate,
      uom_create,
      data_po,
      data_eta,
      columns_po,
      columns_eta,

      quantity_available,
      quantity_remaining,
      date_edit,
      customer_edit_code,
      shipped_to_edit_code,
      isValidShippedToedit,
      material_edit_code,
      quantity_edit,
      uom_edit,
      remaining_edit_to_send,
      remaining_edit_unreduced,
      data_po_edit,
      data_eta_edit,
      customer_search_code,

      errors,
      errors_create,
      submitted_create,
      submitted_edit,
      isClearable,
      isCreate,
      isRead,

      page,
      sizePerPage,
      totalSize,
      remark,
      remark_edit,
    } = this.state;

    const customStylesCustomerCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCustomerCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCustomerCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesShippedToCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidShippedToCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidShippedToCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          {/* MODAL EDIT */}
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Update</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <form
                      autoComplete="off"
                      className={
                        submitted_edit
                          ? "needs-validation was-validated"
                          : "needs-validation"
                      }
                      noValidate
                      ref={(form) => (this.form = form)}
                    >
                      <Row>
                        <Col xs={12} md={4}>
                          <div
                            className={
                              errors.date != "This field is required"
                                ? "form-group"
                                : "form-group has-error has-feedback"
                            }
                          >
                            <label htmlFor="date">Month *</label>
                            <DatePicker
                              selected={date_edit}
                              minDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth()
                                )
                              }
                              disabledKeyboardNavigation={true}
                              onChange={this.handleDate.bind(this, "date")}
                              name="date"
                              wrapperClassName="datePickerCustom"
                              dateFormat="MMMM yyyy"
                              className="form-control form-control-sm"
                              required
                              showMonthYearPicker
                              readOnly={true}
                            />
                            {errors.date && (
                              <div className="invalid-feedback">
                                {errors.date}
                              </div>
                            )}
                          </div>
                          <div
                            data-tip="Please choose month"
                            data-tip-disable={this.state.date !== null}
                          ></div>

                          {/* INPUT QTY & INPUT UOM */}
                          <Row>
                            <Col xs={12} md={6}>
                              <div
                                className={
                                  errors_create.quantity !=
                                  "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label htmlFor="quantity">Quantity</label>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control form-control-sm"
                                  name="quantity_create"
                                  placeholder="Qty"
                                  required="required"
                                  value={quantity_edit || ""}
                                  readOnly={true}
                                  onChange={this.onChangeValue}
                                  min="0"
                                />
                                {errors_create.quantity_create && (
                                  <div className="invalid-feedback">
                                    {errors_create.quantity_create}
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div
                                className={
                                  errors_create.uom_create !=
                                  "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label htmlFor="uom_create">UOM</label>

                                <select
                                  className={
                                    errors_create.uom_create !=
                                    "This field is required"
                                      ? "form-control form-control-sm "
                                      : "form-control form-control-sm  has-error has-feedback"
                                  }
                                  name="uom_create"
                                  onChange={this.onChangeValue}
                                  value={uom_edit}
                                  required
                                  readOnly={true}
                                  ref={(ref) => (this.refUnit = ref)}
                                >
                                  <option value="">UOM</option>
                                  {select_items_uom &&
                                    select_items_uom.map(function (item, i) {
                                      return (
                                        <option value={item.value} key={i}>
                                          {item.value}
                                        </option>
                                      );
                                    })}
                                </select>

                                {errors_create.uom_create && (
                                  <div className="invalid-feedback">
                                    {errors_create.uom_create}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {/* INPUT REMAINING */}
                          <Row>
                            <Col xs={12} md={6}>
                              <div
                                className={
                                  errors_create.quantity !=
                                  "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <label>Quantity Remaining</label>
                                <input
                                  type="number"
                                  className={
                                    "form-control" +
                                    (remaining_edit_to_send < 0
                                      ? " bg-danger text-white"
                                      : "")
                                  }
                                  value={remaining_edit_to_send}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={4}>
                          {/* INPUT CUSTOMER */}
                          <divremaining_edit
                            className={
                              errors.customer != "This field is required"
                                ? "form-group"
                                : "form-group has-error has-feedback"
                            }
                          >
                            <label htmlFor="customer_edit">Sold To*</label>
                            <Select
                              styles={customStylesCustomerCreate}
                              onChange={this.handleChange.bind(
                                this,
                                "customer_edit"
                              )}
                              options={select_items_customer}
                              defaultValue={select_items_customer.filter(
                                (e) => e.value === customer_edit_code
                              )}
                              isDisabled={true}
                            />
                            {!isValidCustomerCreate && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: ".25rem",
                                  fontSize: "80%",
                                  color: " #dc3545",
                                }}
                              >
                                {errors.customer}
                              </div>
                            )}
                          </divremaining_edit>
                          {/* INPUT SHIPPED_ TO */}
                          <div
                            className={
                              errors.shipped_to != "This field is required"
                                ? "form-group"
                                : "form-group has-error has-feedback"
                            }
                          >
                            <label htmlFor="shipped_to_edit">Shipped To*</label>
                            <Select
                              styles={customStylesShippedToCreate}
                              onChange={this.handleChange.bind(
                                this,
                                "shipped_to_edit"
                              )}
                              options={select_items_shipped_to_edit}
                              defaultValue={select_items_customer.filter(
                                (e) => e.value === shipped_to_edit_code
                              )}
                              isDisabled={true}
                            />
                            {!isValidShippedToedit && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: ".25rem",
                                  fontSize: "80%",
                                  color: " #dc3545",
                                }}
                              >
                                {errors.shipped_to}
                              </div>
                            )}
                          </div>
                          {/* INPUT MATERIAL */}
                          <div
                            className={
                              errors_create.material != "This field is required"
                                ? "form-group"
                                : "form-group has-error has-feedback"
                            }
                          >
                            <label htmlFor="material_create">Material *</label>
                            <Select
                              styles={customStylesMaterialCreate}
                              onChange={this.handleChange.bind(
                                this,
                                "material_create"
                              )}
                              requester
                              options={select_items_material_edit}
                              defaultValue={select_items_material_edit.filter(
                                (e) => e.value === material_edit_code
                              )}
                              isDisabled={true}
                            />
                            {!isValidMaterialCreate && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: ".25rem",
                                  fontSize: "80%",
                                  color: " #dc3545",
                                }}
                              >
                                {errors_create.material}
                              </div>
                            )}
                          </div>
                        </Col>

                        {/* REMARK MODAL */}
                        <Col xs={12} md={4}>
                          <div className="form-group">
                            <label htmlFor="remark_edit">Remarks</label>
                            <textarea
                              className="form-control"
                              id="remark_edit"
                              rows="4"
                              value={remark_edit}
                              onChange={this.onChangeRemarkEdit}
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                      {/* INPUT PO */}
                      <Row>
                        <Col xs={12} md={12}>
                          <label>PO</label>
                          <APSPO
                            data={data_po_edit}
                            columns={columns_po}
                            uom={select_items_uom}
                            selectedUOM={uom_edit}
                            type={"PO"}
                            onChange={(data_po) => {
                              this.setState({
                                data_po_edit: [...data_po],
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      {/* INPUT ETA */}
                      <Row>
                        <Col xs={12} md={12}>
                          <label>ETA *</label>
                          <APSPO
                            data={data_eta_edit}
                            columns={columns_eta}
                            uom={select_items_uom}
                            selectedUOM={uom_edit}
                            month={date_edit}
                            type={"ETA"}
                            onChange={(data_eta_edit) => {
                              let total_eta_qty_edit = data_eta_edit.reduce(
                                function (accumulator, item) {
                                  return (
                                    accumulator + parseInt(item.quantity || 0)
                                  );
                                },
                                0
                              );
                              this.setState({
                                total_eta_qty_edit: total_eta_qty_edit,
                                data_eta_edit: [...data_eta_edit],
                                remaining_edit_to_send:
                                  remaining_edit_unreduced - total_eta_qty_edit,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col xs={12} md={12}>
                          <Button
                            variant="success"
                            onClick={this.handleSubmitEdit}
                            className="float-right"
                          >
                            <i className="fas fa-save" /> Submit
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Delivery Sales</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div
                                  className={
                                    errors.date != "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="date">Month *</label>
                                  <DatePicker
                                    selected={date}
                                    minDate={
                                      new Date(
                                        new Date().getFullYear(),
                                        new Date().getMonth()
                                      )
                                    }
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date"
                                    )}
                                    name="date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                    showMonthYearPicker
                                  />
                                  {errors.date && (
                                    <div className="invalid-feedback">
                                      {errors.date}
                                    </div>
                                  )}
                                </div>
                                <div
                                  data-tip="Please choose month"
                                  data-tip-disable={this.state.date !== null}
                                ></div>

                                {/* INPUT QTY & INPUT UOM */}
                                <Row>
                                  <Col xs={12} md={6}>
                                    <div
                                      className={
                                        errors_create.quantity !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="quantity">Quantity</label>
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control form-control-sm"
                                        name="quantity_available"
                                        placeholder="Qty"
                                        required="required"
                                        value={quantity_available}
                                        readOnly={true}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.quantity_available && (
                                        <div className="invalid-feedback">
                                          {errors_create.quantity_available}
                                        </div>
                                      )}
                                    </div>
                                    <label>Quantity Remaining</label>
                                    <input
                                      type="number"
                                      className={
                                        "form-control" +
                                        (!this.state.isValidQuantityRemaining
                                          ? " bg-danger"
                                          : "") +
                                        (this.state.isQuantityRemainingSisa
                                          ? " bg-warning"
                                          : "")
                                      }
                                      value={quantity_remaining}
                                      required
                                      readOnly
                                    />
                                    {this.state
                                      .error_message_quantity_remaining && (
                                      <div
                                        style={{
                                          fontSize: "80%",
                                          marginTop: "0.25rem",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {
                                          this.state
                                            .error_message_quantity_remaining
                                        }
                                      </div>
                                    )}
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <div
                                      className={
                                        errors_create.uom_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="uom_create">UOM</label>

                                      <select
                                        className={
                                          errors_create.uom_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="uom_create"
                                        onChange={this.onChangeValue}
                                        value={uom_create}
                                        required
                                        readOnly={true}
                                        ref={(ref) => (this.refUnit = ref)}
                                      >
                                        <option value="">UOM</option>
                                        {select_items_uom &&
                                          select_items_uom.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.uom_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.uom_create}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} md={4}>
                                {/* INPUT CUSTOMER */}
                                <div
                                  className={
                                    errors_create.customer !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="customer">Sold To*</label>
                                  <Select
                                    styles={customStylesCustomerCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "customer_create"
                                    )}
                                    options={select_items_customer}
                                    value={
                                      select_items_customer.filter(
                                        (e) => e.value === customer_create_code
                                      ) || null
                                    }
                                    ref={(ref) => (this.refCustomer = ref)}
                                  />
                                  {!isValidCustomerCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.customer}
                                    </div>
                                  )}
                                </div>

                                {/* INPUT MATERIAL */}
                                <div
                                  className={
                                    errors_create.material !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="material_create">
                                    Material *
                                  </label>
                                  <Select
                                    styles={customStylesMaterialCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "material_create"
                                    )}
                                    options={select_items_material}
                                    value={
                                      select_items_material.filter(
                                        (e) => e.value === material_create_code
                                      ) || null
                                    }
                                  />
                                  {!isValidMaterialCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.material}
                                    </div>
                                  )}
                                </div>

                                {/* INPUT SHIPPED TO */}
                                <div
                                  className={
                                    errors_create.shipped_to !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="shipped_to">
                                    Shipped To*
                                  </label>
                                  <Select
                                    styles={customStylesShippedToCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "shipped_to_create"
                                    )}
                                    options={select_items_shipped_to}
                                    value={
                                      select_items_shipped_to.filter(
                                        (e) =>
                                          e.value === shipped_to_create_code
                                      ) || null
                                    }
                                    ref={(ref) => (this.refShippedTo = ref)}
                                  />
                                  {!isValidShippedToCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.shipped_to}
                                    </div>
                                  )}
                                </div>
                              </Col>

                              {/* REMARK */}
                              <Col xs={12} md={4}>
                                <div className="form-group">
                                  <label htmlFor="remarks">Remarks</label>
                                  <textarea
                                    className="form-control"
                                    id="remarks"
                                    rows="4"
                                    value={remark}
                                    onChange={this.onChangeRemark}
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>
                            {/* INPUT PO */}
                            <Row>
                              <Col xs={12} md={12}>
                                <label>PO</label>
                                <APSPO
                                  data={data_po}
                                  columns={columns_po}
                                  uom={select_items_uom}
                                  selectedUOM={uom_create}
                                  type={"PO"}
                                  onChange={(data_po) => {
                                    this.setState({
                                      data_po: [...data_po],
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <br />
                            {/* INPUT ETA */}
                            <Row>
                              <Col xs={12} md={12}>
                                <label>ETA *</label>
                                <APSPO
                                  data={data_eta}
                                  columns={columns_eta}
                                  uom={select_items_uom}
                                  selectedUOM={uom_create}
                                  month={date}
                                  type={"ETA"}
                                  onChange={(data_eta) => {
                                    let total_eta = data_eta.reduce(function (
                                      accumulator,
                                      item
                                    ) {
                                      return (
                                        accumulator +
                                        parseInt(item.quantity || 0)
                                      );
                                    },
                                    0);
                                    let quantity_remaining =
                                      this.state.quantity_available - total_eta;
                                    this.setState({
                                      quantity_remaining: quantity_remaining,
                                      error_message_quantity_remaining: false,
                                      total_eta_qty: total_eta,
                                      data_eta: [...data_eta],
                                      isValidQuantityRemaining:
                                        quantity_remaining < 0 ? false : true,
                                      error_message_quantity_remaining:
                                        quantity_remaining < 0
                                          ? "Should not below 0."
                                          : "",
                                      isQuantityRemainingSisa:
                                        quantity_remaining > 0 ? true : false,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                            <br></br>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="customer">Sold To </label>
                                  <Select
                                    onChange={this.handleChange.bind(
                                      this,
                                      "customer_search"
                                    )}
                                    options={select_items_customer}
                                    defaultValue={select_items_customer.filter(
                                      (e) => e.value === customer_search_code
                                    )}
                                    isClearable={isClearable}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <br></br>
                                <div className="form-group">
                                  <label htmlFor="parameter">&nbsp;</label>
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                    style={{ marginTop: "0.55rem" }}
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(EstimateDelivery);
