import React, { Component, useMemo } from 'react';
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import XLSX from "xlsx";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import {
    create,
    read
} from "../modules/MAPManualModule";
import WorksheetTabCustom from "../components/WorksheetTabCustom";
import DataTable from 'react-data-table-component';
import PropTypes from "prop-types";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
    .map((x) => `.${x}`)
    .join(",");

const TableCustom = ({
    data,
    totalRows,
    curPage,
    perPage,
    loading,
    fetchData
}) => {
    const handlePageChange = (page) => {
        fetchData(page, perPage);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        return fetchData(page, newPerPage);
    };
    const columns = useMemo(() => {
        return [
            {
                name: "Material Code",
                selector: (row) => (row.material_code),
                sortable: true,
                center: true,
                width: "11%"
            },
            {
                name: "Material Desc",
                selector: (row) => (row.material_desc),
                sortable: true,
                center: true,
            },
            {
                name: "Value",
                selector: (row) => (row.value),
                sortable: true,
                center: true,
                width: "11%"
            },
        ]
    }, [data]);

    return (
        <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationDefaultPage={curPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
        />
    );
}

TableCustom.propTypes = {
    data: PropTypes.instanceOf(Array),
    totalRows: PropTypes.number,
    curPage: PropTypes.number,
    perPage: PropTypes.number,
    fetchData: PropTypes.func
};

TableCustom.defaultProps = {
    data: [],
    totalRows: 0,
    curPage: 1,
    perPage: 10,
    fetchData: () => { }
};
const TextJSFT = ["txt"].map((x) => `.${x}`).join(",");
const array = new Uint32Array(10);
class MAPManual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submitted: false,
            showModal: false,
            readOnly: false,
            isClearable: true,
            isRead: false,
            errors: {},
            department: null,
            requester: null,
            requester_name: null,
            inputFileExcel: self.crypto.getRandomValues(array),  /* eslint-disable-line no-restricted-globals */
            fileExcel: {
                name: "Choose file",
            },
            file_worksheet: [],

            datatable: [],
            count_list: 0,
            sizePerPage: 10,
            loading_fetch_data: false,
            page: 1,
            header: [],
            search: null

        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const user = JSON.parse(localStorage.getItem("user"));

        const department =
            user.details.hris_org_tree.current_person.nama_department;


        this.setState({
            department,
            requester: user.username,
            requester_name: user.details.hris_org_tree.current_person.person_name,
        });
        const { page, sizePerPage } = this.state
        this.getData(page, sizePerPage);
        //cari hak akses
        let menu = JSON.parse(localStorage.getItem("menu"));
        const { history } = this.props;
        let current_link = null;
        let check_access = null;
        if (history.location) {
            if (history.location.pathname) {
                current_link = history.location.pathname;
            }
        }
        menu.map((item) => {
            item.details.map((e) => {
                if (e.link === current_link) {
                    check_access = e;
                }
            });
        });
        if (check_access) {
            this.setState({
                isCreate: check_access.isCreate,
                isRead: check_access.isRead,
                isDelete: check_access.isDelete,
                isEdit: check_access.isEdit,
            });
        }
    }

    //toggle modal

    toggle = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };
    //on modal hide

    onModalHide = () => {
        this.setState({
            id: null,
            submitted: false,
            showModal: !this.state.showModal,
            readOnly: false,
            loading_download: false
        });
    };
    isEmpty = (o) => {

        return Object.keys(o).length == 0 ? true : false
    }
    //validate input
    validateForm = ({ fileExcel }) => {
        let errors = this.state.errors;
        if (this.isEmpty(fileExcel) || !fileExcel?.value) {
            errors.excel = "This field is required";
        } else {
            delete errors.excel;
        }
        return errors
    };

    handleSubmit = () => {
        try {
            const { fileExcel, department, requester, file_worksheet, } =
                this.state;
            const { history } = this.props;
            const new_values = {
                excel: file_worksheet,
                department: department,
                requester: requester
            };
            const validate = this.validateForm({ fileExcel });
            if ((validate?.excel) && file_worksheet.length == 0) {
                return this.setState({ errors: validate });
            }

            Swal.fire({
                title: "Are you sure?",
                text: "Please check your entries !",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true
                    });
                    create(new_values)
                        .then(() => {
                            Swal.fire(
                                "Information",
                                "Data has been submitted successfuly!",
                                "success"
                            );
                            // CLEAR
                            this.handleReloadBack();
                            history.push("/production/map-manual");
                        })
                        .catch((err) => {
                            Swal.fire("Information", err, "error");
                        })
                        .finally(() => {
                            this.setState({
                                loading: false
                            });
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                }
            });
        } catch (err) {
            Swal.fire("Information ", "Please check your entries again!", "error");
        }
    };
    handleReloadBack = () => {

        const { page, sizePerPage } = this.state
        this.getData(page, sizePerPage);
        this.setState({
            load_file: false,
            file_worksheet: [],
            fileExcel: {
                name: "Choose file",
                type: "",
                size: 0,
                value: null
            },
            errors: {},
        });
    }
    handleClear = () => {

        this.setState({
            load_file: false,
            file_worksheet: [],
            fileExcel: {
                name: "Choose file",
                type: "",
                size: 0,
                value: null
            },
            errors: {},
        });
    };

    handleFile = (name, e) => {

        if (e.target.files.length > 0 && name == "excel") {
            const fileObject = e.target.files[0];

            this.handleReadFileExcel(fileObject)
                .then((response) => {
                    this.setState({
                        load_file: true,
                        file_worksheet: response,
                        fileExcel: {
                            name: fileObject.name,
                            type: fileObject.type,
                            size: fileObject.size,
                            value: e.target.value
                        },
                    });
                })
                .catch((e) => {
                    this.setState({
                        load_file: false,
                        file_worksheet: [],
                        file: {
                            name: "Choose file",
                            type: "",
                            size: 0,
                            value: e.target.value
                        },
                    });
                })
                .finally(() => {
                    this.setState({ errors: {} });
                });
        }
    };

    handleReadFileExcel = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);

                fileReader.onload = (e) => {
                    const binaryString = e.target.result;
                    const workbook = XLSX.read(binaryString, { type: "buffer" });

                    const result = workbook.SheetNames.map((item) => {
                        const worksheet = workbook.Sheets[item];
                        return {
                            sheetName: item,
                            worksheet: XLSX.utils.sheet_to_json(worksheet, {
                                header: 1,
                                defval: "",
                                blankrows: false,
                            }),
                        };
                    });
                    resolve(result);
                };
            } catch (e) {
                reject(new Error(e.message));
            }
        });
    };

    getData = async (page, sizePerPage, search) => {
        this.setState({
            loading_fetch_data: true
        });
        const query = {
            page: page,
            sizePerPage: sizePerPage,
            search: search
        };
        read(query)
            .then((response) => {
                this.setState({
                    datatable: response.data,
                    count_list: response.countData
                });
            })
            .catch((err) => {
                this.setState({
                    count_list: 0,
                    datatable: []
                })
                Swal.fire("Error", err, "error");
            })
            .finally(() => {
                this.setState({ loading_fetch_data: false });
            })
    }
    handlePage = async (idx) => {
        await this.setState(
            {
                page: idx
            }
        )
    }

    handleChange = (e) => {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value
        })
    }
    handleSearch = () => {
        const { page, sizePerPage, search } = this.state;
        this.getData(page, sizePerPage, search)
    }
    render() {
        let {
            inputFileExcel,
            loading,
            isCreate,
            fileExcel,
            file_worksheet,
            load_file,
            errors,


            datatable,
            count_list,
            page,
            sizePerPage,
            loading_fetch_data,
            search,
            isRead
        } = this.state;
        return (
            <div className="sidebar-mini sidebar-collapse text-sm">
                <div className="wrapper">
                    <ToastContainer />
                    <Modal
                        show={loading}
                        size="xl"
                        backdrop="static"
                        onHide={this.onModalLoadingHide}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        keyboard={false}
                    >
                        <ModalHeader className="bg-info text-white"></ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <ProgressBar animated now={100} />
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>


                    <Header />
                    <SideMenu />
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6"></div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                {isCreate && (
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="card card-info">
                                                <div className="card-header ">
                                                    <h3 className="card-title">MAP Manual</h3>
                                                </div>
                                                <div className="card-body">


                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-6 p-2">

                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputFileExcel">
                                                                    Upload Excel
                                                                </label>
                                                                <div className="input-group">
                                                                    <div className="custom-file">
                                                                        <input
                                                                            type="file"
                                                                            className="custom-file-input"
                                                                            id="exampleInputFileExcel"
                                                                            onChange={this.handleFile.bind(this, 'excel')}
                                                                            accept={SheetJSFT}
                                                                            value={fileExcel?.value || ""}
                                                                            key={inputFileExcel || ""}
                                                                            placeholder={`Choose file ...`}
                                                                        />
                                                                        <label
                                                                            className="custom-file-label"
                                                                            htmlFor="exampleInputFileExcel"
                                                                        >
                                                                            {fileExcel?.name || ""}
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {errors?.excel && (
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            marginTop: ".25rem",
                                                                            fontSize: "80%",
                                                                            color: " #dc3545",
                                                                        }}
                                                                    >
                                                                        {errors?.excel}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {load_file && (
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12 p-2">
                                                                <WorksheetTabCustom data={file_worksheet} type={`no-default`} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-6 p-2">
                                                            <Button
                                                                variant="success"
                                                                className="float-right m-1"
                                                                onClick={this.handleSubmit}
                                                            >
                                                                <i className="fas fa-save" /> Submit
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="float-right m-1"
                                                                onClick={this.handleClear}
                                                            >
                                                                <i className="fas fa-eraser" /> Clear
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}{isRead && (
                                    <div className="row">

                                        <div className='col-md-12'>
                                            <div className='card card-info'>
                                                <div className='card-header'>
                                                    <h3 className='card-title'>Historical List</h3>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row'>

                                                        <div className='col-md-3 col-xs-12'>

                                                            <div class="input-group mb-3">
                                                                <input
                                                                    style={{ fontSize: '0.87rem' }}
                                                                    autoComplete='off'
                                                                    name="search"
                                                                    type="text" class="form-control" placeholder="Material Desc" aria-label="Material Desc" aria-describedby="basic-addon2" onChange={this.handleChange} value={search || ''} />
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-info" type="button"
                                                                        onClick={this.handleSearch}> <i className='fas fa-search'></i>  Search</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <TableCustom
                                                        data={datatable}
                                                        totalRows={count_list}
                                                        perPage={sizePerPage}
                                                        curPage={page}
                                                        fetchData={this.getData}
                                                        loading={loading_fetch_data}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                        {/* /.content */}
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default withRouter(MAPManual);
