import axios from "axios";
import authHeader from "./auth-header";
import refreshToken from "./refresh-token";
import jwt from "jsonwebtoken";
import { Cookies } from "react-cookie";
import { cookieNameGenerator } from "../helpers/helpers";

const API_URL = process.env.REACT_APP_API;
const private_key = process.env.REACT_APP_PRIVATE_KEY;

const cookies = new Cookies();

class AuthService {
  goONELNKHomapage() {
    return localStorage.clear();
  }
  login(username, password) {
    return axios
      .post(
        API_URL + "auth/signin",
        { username, password },
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
          localStorage.setItem("photo", JSON.stringify(response.data.photo));
        }

        return response;
      });
  }

  loginPhone(payload) {
    return axios
      .post(`${API_URL}auth/phone`, payload, { headers: authHeader() })
      .then(async (response) => {
        return response;
      });
  }
  refreshToken(username, password) {
    return axios
      .post(
        API_URL + "auth/signin",
        { username, password },
        { headers: refreshToken() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          const verifyToken = jwt.verify(
            response.data.accessToken,
            private_key
          );
          localStorage.setItem("accessToken", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(verifyToken));
        }

        return response;
      });
  }
  logout() {
    localStorage.clear();
    cookies.remove(cookieNameGenerator("ssocookies"));
    cookies.remove(cookieNameGenerator("ssoaccount"));
    cookies.remove(cookieNameGenerator("ssophonenumber"));
    // return window.location.reload();
  }

  register(username, email, password) {
    return axios.post(
      API_URL + "auth/signup",
      {
        username,
        email,
        password,
      },
      { headers: authHeader() }
    );
  }

  loginSSO(payload) {
    return axios
      .post(`${API_URL}auth/signin-sso`, payload, { headers: authHeader() })
      .then(async (response) => {
        return response;
      });
  }
  getMenu(role) {
    return axios
      .get(API_URL + "authorization/" + role, { headers: authHeader() })
      .then((response) => {
        localStorage.setItem("menu", JSON.stringify(response.data));
        return response.data;
      });
  }
}

export default new AuthService();
