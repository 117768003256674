import { useEffect, useState } from "react";
import { Table, Row, Col } from "react-bootstrap";
import Select from "react-select";

const SelectionVersion = ({
  select_version,
  recipe_version,
  handleChangeVersion,
  type = null,
  selected_version = null,
}) => {
  const [selectedVersion, setVersion] = useState(
    selected_version
      ? { label: `Version ${selected_version}`, value: selected_version }
      : null
  );
  const [selectedIng, setIngre] = useState([]);
  const showDetail = (newValue) => {
    setVersion(newValue);
    const ing = recipe_version.find((it) => {
      return it.version_no == newValue.value;
    });
    const checkIng = typeof ing === "undefined" ? [] : ing.ingredients;
    setIngre(checkIng);
    handleChangeVersion(newValue.value);
  };
  const checkData = () => {
    if (selected_version) {
      const ing = recipe_version.find((it) => {
        return it.version_no == selected_version;
      });
      const checkIng = typeof ing === "undefined" ? [] : ing.ingredients;
      setIngre(checkIng);
    }
  };
  useEffect(() => {
    checkData();
  }, []);
  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="form-group">
          <label>Choose Version</label>
          <Select
            onChange={(e) => showDetail(e)}
            options={select_version}
            value={selectedVersion || null}
          />
        </div>
        {selectedVersion && (
          <Table
            bordered
            hover
            size="sm"
            style={{
              textAlign: "center",
            }}
          >
            <thead key={`header-s-v`}>
              <tr key={`h0sv`} className="bg-info">
                <th key={`h1sv`}>No. </th>
                <th key={`h3sv`}>Material Name</th>
                <th key={`h4sv`}>QTY</th>
                <th key={`h5sv`}>UOM</th>
                <th key={`h6v`}>Plant</th>
              </tr>
            </thead>
            <tbody>
              {selectedVersion &&
                selectedIng.map((item, idx) => (
                  <tr key={`b${idx}sv`}>
                    <td className="col-sm-1" key={`b${idx}1sv`}>
                      {" "}
                      {idx + 1}{" "}
                    </td>
                    <td className="col-sm-5" key={`b${idx}2sv`}>
                      {type && type == "notif-rnd"
                        ? item.material_name
                        : item.material_description_rm}
                    </td>
                    <td className="col-sm-3" key={`b${idx}3sv`}>
                      {type && type == "notif-rnd"
                        ? item.qty
                        : item.amount_per_batch}
                    </td>
                    <td className="col-sm-3" key={`b${idx}4sv`}>
                      {item.uom ? item.uom : ""}
                    </td>
                    <td className="col-sm-3" key={`b${idx}5sv`}>
                      {item.plant}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default SelectionVersion;
