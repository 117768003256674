import dateFormat from "dateformat";
import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { event } from "jquery";
const REJECT_KG = parseInt(process.env.REACT_APP_REJECT_KG);

const TableDetailCalendar = ({ data }) => {
  let filter_rpp_confirmed =
    data.events &&
    data.events.filter(
      (event) =>
        event.status == "confirmed" && event.type == "RPP" && !event.is_cancel
    );
  return (
    <div>
      <h4>{data && dateFormat(data.selectedDate, "dddd, dd mmmm yyyy")}</h4>
      <hr />
      {filter_rpp_confirmed && filter_rpp_confirmed.length > 1 && (
        <div className="d-flex justify-content-start align-items-center mb-3">
          <h5 className="p-1">Reject </h5>
          <h5 className="bg-warning rounded p-1">
            {" "}
            {REJECT_KG * (filter_rpp_confirmed.length - 1)} KG
          </h5>
        </div>
      )}
      <table className="table table-borderless table-hover">
        <tbody>
          {data.events &&
            data.events.map((s, k) => {
              return (
                (s.isForecast || s.isETA) &&
                s.isShowing && (
                  <tr
                    key={k}
                    style={
                      s.isForecast
                        ? s.is_cancel
                          ? { backgroundColor: "black", color: "white" }
                          : { backgroundColor: "orange", color: "black" }
                        : { backgroundColor: "indigo", color: "white" }
                    }
                  >
                    <td style={{ width: "40px" }}>{""}</td>
                    <td>{s.title}</td>
                  </tr>
                )
              );
            })}
          {data &&
            data.events &&
            data.events.length > 0 &&
            data.events
              .filter((e) => {
                return (
                  !e.isForecast &&
                  !e.isETA &&
                  e.isShowing &&
                  e.status == "confirmed"
                );
              })
              .map((s, i) => {
                return (
                  !s.isForecast &&
                  !s.isETA && (
                    <tr
                      key={i}
                      style={
                        s.is_cancel
                          ? {
                              backgroundColor: "black",
                              color: "white",
                            }
                          : {}
                      }
                    >
                      <td style={{ width: "40px" }}>{i + 1}</td>
                      <td>{s.title}</td>
                    </tr>
                  )
                );
              })}
        </tbody>
      </table>
      {data && data.summary_daily && data.summary_daily.length > 0 && (
        <table className="table table-bordered table-hover">
          <tr style={{ backgroundColor: "purple", color: "white" }}>
            <td colSpan={3}>Summary Raw Mat Needs Daily</td>
          </tr>
          <tr>
            <td>
              <b>Material Name</b>
            </td>
            <td>
              <b>Qty</b>
            </td>
            <td>
              <b>Uom</b>
            </td>
          </tr>
          {data.summary_daily.map((mat) => (
            <tr>
              <td>{mat.material_desc}</td>
              <td>{mat.qty}</td>
              <td>{mat.uom}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default TableDetailCalendar;
