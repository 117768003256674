import React, { Component } from 'react'

import Header from './templates/Header';
import SideMenu from './templates/SideMenu';

export default class NavSideLayout extends Component {
  render() {
    return (
      <div   >
        <Header />
        <SideMenu />

      </div>
    )
  }
}
