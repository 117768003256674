import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Select from "react-select";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import MATERIALService from "../services/material.service";

const TablePreviewSplit = ({
  data, //data that is inputted by user in each row
  prod_facility,
  prod_facility_default,
  onChange,
}) => {
  //state to do some validation for the Select type
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const style_align_center = { "vertical-align": "middle" };

  const handleSelectChange = (val, type, idx) => {
    if (type === "prod_facility") {
      data[idx] = {
        ...data[idx],
        prod_facility_code: val.value,
      };
    }
    onChange(data);
  };

  return (
    <Container>
      <Col xs={12} md={12}>
        <Table
          className="float-right text-center text-wrap"
          bordered
          hover
          size="sm"
          style={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <thead>
            <tr key="before">
              <th colSpan={6} key="before0" className="bg-success">
                After
              </th>
            </tr>
            <tr key="head">
              <th key="head0">No.</th>
              <th key="head1">Material</th>
              <th key="head5">Facility</th>

              <th key="head3"> Source </th>
              <th key="head4"> Customer </th>
              <th key="head2">Qty</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => [
              <tr key={"row" + (i + 1)}>
                <td
                  key={"row0" + (i + 1)}
                  rowSpan={item.detail_split.length + 2}
                  style={style_align_center}
                  className="col-sm-1"
                >
                  {i + 1}
                </td>
                <td
                  key={"row1" + (i + 1)}
                  rowSpan={item.detail_split.length + 2}
                  className="col-sm-3"
                >
                  {item.material}
                </td>
                <td
                  key={"row5" + (i + 1)}
                  rowSpan={item.detail_split.length + 2}
                  className="col-sm-3"
                >
                  <div>
                    <Select
                      onChange={(val) =>
                        handleSelectChange(val, "prod_facility", i)
                      }
                      options={prod_facility}
                      defaultValue={
                        prod_facility.filter(
                          (e) => e.value === prod_facility_default
                        ) || ""
                      }
                    />
                  </div>
                </td>
              </tr>,
              item.detail_split.map((item_split) => [
                <tr key={"row" + (i + 2)}>
                  <td
                    key={"row1" + (i + 2)}
                    style={style_align_center}
                    className="col-sm-1"
                  >
                    {item_split.source}
                  </td>
                  <td
                    key={"row2" + (i + 2)}
                    style={style_align_center}
                    className="col-sm-1"
                  >
                    {item_split.customer}
                  </td>
                  <td key={"row3" + (i + 2)} style={style_align_center}>
                    {item_split.qty} {item_split.uom}
                  </td>
                </tr>,
              ]),
              <tr key={"row" + (i + item.detail_split.length + 1)}>
                <td
                  key={"row1" + (i + item.detail_split.length)}
                  style={style_align_center}
                  className="font-weight-bold bg-light"
                  colSpan={2}
                >
                  Total
                </td>
                <td
                  key={"row2" + (i + item.detail_split.length + 1)}
                  style={style_align_center}
                  className="font-weight-bold bg-light col-sm-1"
                >
                  {item.qty} {item.uom}
                </td>
              </tr>,
            ])}
          </tbody>
        </Table>
      </Col>
    </Container>
  );
};

export default TablePreviewSplit;
