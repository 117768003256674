import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import FinalSelect from "../components/FinalSelect";
import { withRouter } from "react-router-dom";
import {
  readMaterialByType,
  readMaterialfromIngredient,
} from "../modules/MaterialModule";
import DynamicTable from "../components/DynamicTable";

import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  searchFuturePrice,
  addPOContract,
  readPOContract,
  checkActiveHash,
  getLastUpdatedData,
  checkPrevMonth,
  getPrevMonth,
  getLastExchange,
} from "../modules/POContractModule";
import styled, { css } from "styled-components";

import {
  DefaultTableInputPO,
  DefaultTableInputContract,
  DefaultTableInputMRP,
  DefaultTableInputPlanning,
} from "../defaultData/defaultPOContract";
import { DefaultRowPO, DefaultRowContract } from "../defaultData/defaultRow";
import { getNextMonthList } from "../helpers/dateHelper";
import { startOfMonth, subMonths, addMonths } from "date-fns";
import moment from "moment";

const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
`;

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
`;
const numRow = 4;
class POContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecklist: false,
      submitted: false,
      isActiveHash: true,
      isSearched: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      errors: {},
      requester: null,
      isCreate: false,
      isEdit: false,
      isRead: false,
      isDelete: false,
      isAdmin: false,
      id: null,
      isExists: false,
      isPrevMonth: false,
      isValid: { material: true },
      select_items_material: [],
      select_items_currency: [],
      select_items_future_price: [],
      start_month: startOfMonth(new Date()),
      material: null,
      hash_ingredient_list: null,
      tableInputPO: DefaultTableInputPO,
      tableInputContract: DefaultTableInputContract,
      tableInputMRP: DefaultTableInputMRP,
      tableInputPlanning: DefaultTableInputPlanning,
      rmbalance_id: null,
      rmbalance_value: null,
      rmbalance_qty: null,
      rmbalance_price_kg: null,
      rmbalance_currency: null,
      mrp_notif: null,
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const mrp_notif = JSON.parse(localStorage.getItem("to_po_contract"));
    const { start_month } = this.state;
    this.setState({
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
    });
    const queryMonth = moment(
      mrp_notif
        ? `${mrp_notif.planning_year} ${mrp_notif.planning_month} 01`
        : start_month,
      "YYYY MMMM 01"
    ).format("YYYY-MM-01");
    readMaterialfromIngredient(queryMonth).then((response) => {
      this.setState(
        { select_items_material: response, mrp_notif: mrp_notif },
        () => {
          this.state.mrp_notif &&
            this.setState(
              {
                material: this.state.mrp_notif.material_code,
                start_month: new Date(queryMonth),
              },
              () => this.handleSearch()
            );
        }
      );
    });

    //set default row

    let { tableInputPO, tableInputContract, tableInputMRP } = { ...this.state };
    this.setDefaultRow(tableInputPO, tableInputContract);
    this.setState({
      tableInputMRP: tableInputMRP,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
        isAdmin: check_access.isAdmin,
      });
    }
  }
  setDefaultRow = (tableInputPO, tableInputContract) => {
    this.generateDefaultRow(tableInputPO, "tableInputPO");
    this.generateDefaultRow(tableInputContract, "tableInputContract");
  };
  //generate default ro
  generateDefaultRow = (tableInput, state) => {
    let body = [];
    for (let a = 0; a < numRow; a++) {
      let tableInputRowChildren = tableInput.body.children;
      let obj = {
        children: tableInputRowChildren,
      };
      body.push(obj);
    }
    tableInput.body = body;
    return this.setState({ [state]: tableInput });
  };
  assignCurrency = (selectOptions) => {
    const { tableInputPlanning } = this.state;
    const clonetableInputPlanning = { ...tableInputPlanning };
    const cloneBodyPlanning = [...clonetableInputPlanning.body];
    const cloneRowCurrency = { ...cloneBodyPlanning[2] };
    let rowCurrencyObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Currency",
      },
    ];
    cloneRowCurrency.children.slice(1).map((a, k) => {
      if (a.isSelect) {
        return rowCurrencyObj.push({
          readOnly: a.readOnly,
          data_type: a.data_type,
          value: a.value,
          width: a.width,
          name: a.name,
          isSelect: a.isSelect,
          placeholder: a.placeholder,
          selectOptions: selectOptions,
          isValid: true,
          errors: {},
          fontSize: a.fontSize,
        });
      }
      return rowCurrencyObj.push({
        readOnly: a.readOnly,
        data_type: a.data_type,
        value: a.value,
        width: a.width,
        name: a.name,
      });
    });
    const updatedBody = [
      { ...cloneBodyPlanning[0] },
      { ...cloneBodyPlanning[1] },
      {
        children: rowCurrencyObj,
      },
      { ...cloneBodyPlanning[3] },
      { ...cloneBodyPlanning[4] },
    ];
    clonetableInputPlanning.body = updatedBody;
    this.setState({
      tableInputPlanning: clonetableInputPlanning,
    });
  };
  handleClearSelect = () => {
    this.setState({
      material: null,
    });
  };
  handleReset = () => {
    this.setState({
      isSearched: false,
      isDisabled: false,
      isPrevMonth: false,
    });
  };
  handleRecalculate = () => {
    const { start_month, material, mrp_notif } = this.state;
    let date_formatted = dateFormat(start_month, "yyyy-mm-dd");
    const query_string = `material_code=${material}&start_month=${date_formatted}`;
    if (mrp_notif) {
      getLastUpdatedData(query_string)
        .then((response) => {
          this.setUpdatedDataMRP(response);
          toast.success("Data has been recalculated successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "customId-recalculate-mrp-success",
          });
          this.setState({
            loading: false,
            mrp_notif: null,
          });
          localStorage.removeItem("to_po_contract");
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "customId-recalculate-mrp-failed",
          });
          this.setState({
            loading: false,
            mrp_notif: null,
          });
          localStorage.removeItem("to_po_contract");
        });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "to Recalculate Data !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({
            loading: true,
          });
          getLastUpdatedData(query_string)
            .then((response) => {
              this.setUpdatedDataMRP(response);
              toast.success("Data has been recalculated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-recalculate-mrp-success",
              });
              this.setState({
                loading: false,
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-recalculate-mrp-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      tableInputPO,
      tableInputContract,
      tableInputMRP,
      tableInputPlanning,
      start_month,
      department,
      material,
      hash_ingredient_list,
      rmbalance_id,
      rmbalance_value,
      rmbalance_qty,
      rmbalance_price_kg,
      isChecklist,
      rmbalance_currency,
    } = this.state;

    let date_formatted = dateFormat(start_month, "yyyy-mm-dd");
    let collection = {
      requester: requester,
      department: department,
      material: material,
      hash_ingredient_list: hash_ingredient_list,
      start_month: date_formatted,
      po_contract: {
        po: tableInputPO,
        contract: tableInputContract,
        mrp: tableInputMRP,
        planning: tableInputPlanning,
      },
      rmbalance_id: rmbalance_id,
      rmbalance_value: rmbalance_value,
      rmbalance_qty: rmbalance_qty,
      rmbalance_price_kg: rmbalance_price_kg,
      rmbalance_currency: rmbalance_currency,
      is_checklist: isChecklist,
    };

    if (requester && department && start_month && material) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({
            loading: true,
          });
          addPOContract(collection)
            .then((response) => {
              this.setState({
                loading: false,
              });
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-po-contract-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.handleReset();
                history.push("/raw-materials/po-contract");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-po-contract-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSearch = () => {
    this.setState({
      isDisabled: true,
      isChecklist: false,
      loading: true,
    });
    const { start_month, material, isCreate } = this.state;
    const formatedMonth = dateFormat(start_month, "yyyy-mm-dd");
    const query_string = `start_month=${formatedMonth}&material_code=${material}`;
    readPOContract(query_string).then((response) => {
      if (response.hash_ingredient_list.length == 0) {
        return this.setState({
          isExists: false,
          loading: false,
        });
      }

      //access right
      //po
      //contract
      const tablePO = { ...response.po_contract.po };
      const tableContract = { ...response.po_contract.contract };
      const tablePlanning = { ...response.po_contract.planning };

      if (!isCreate) {
        const cloneBodyPO = [...tablePO.body];
        const setCloneBodyPO = cloneBodyPO.map((a, i) => {
          a.children = a.children.map((b, k) => {
            b.readOnly = true;
            b.width = k == 0 ? "40px" : "9%";
            return b;
          });
          return a;
        });
        tablePO.body = setCloneBodyPO;

        const cloneBodyContract = [...tableContract.body];
        const setCloneBodyContract = cloneBodyContract.map((a, i) => {
          a.children = a.children.map((b, k) => {
            b.readOnly = true;
            b.width = k == 0 ? "40px" : "9%";
            return b;
          });
          return a;
        });
        tableContract.body = setCloneBodyContract;

        const cloneBodyPlanning = [...tablePlanning.body];
        const setCloneBodyPlanning = cloneBodyPlanning.map((a, i) => {
          a.children = a.children.map((b, k) => {
            b.readOnly = true;
            b.width = k == 0 ? "40px" : "9%";
            return b;
          });
          return a;
        });
        tablePlanning.body = setCloneBodyPlanning;
      }
      const resrmbalance_id = response.rmbalance_id
        ? response.rmbalance_id
        : "";
      const isChecklistFromDB =
        typeof response.is_checklist == "boolean"
          ? response.is_checklist
          : false;
      this.setState(
        {
          isExists: true,
          hash_ingredient_list: response.hash_ingredient_list,
          tableInputPO: tablePO,
          tableInputContract: tableContract,
          tableInputMRP: response.po_contract.mrp,
          tableInputPlanning: tablePlanning,
          rmbalance_id: response.rmbalance_id,
          rmbalance_value: response.rmbalance_value,
          rmbalance_qty: response.rmbalance_qty,
          rmbalance_price_kg: response.rmbalance_price_kg,
          rmbalance_currency: response.rmbalance_currency,
          isChecklist: isChecklistFromDB,
        },
        () => {
          this.activeSetLabel(formatedMonth);
          this.checkHash(
            `rmbalance_id=${resrmbalance_id}&${query_string}&hash_ingredient_list=${response.hash_ingredient_list.join(
              "*"
            )}`
          );
          //check previous month
          checkPrevMonth(query_string).then((res) => {
            if (res.status == "exists") {
              this.setState({
                isPrevMonth: true,
              });
            }
          });
        }
      );
    });
    searchFuturePrice(query_string).then((response) => {
      this.setState({
        select_items_future_price: response,
        isSearched: true,
      });
    });
  };
  exchangeRate = async (kurs) => {
    const { start_month } = this.state;
    const mrp_notif = JSON.parse(localStorage.getItem("to_po_contract"));
    const queryMonth = dateFormat(
      mrp_notif ? mrp_notif.planning_date : start_month,
      "yyyy-mm-dd"
    );
    const query_string_ex = `start_month=${queryMonth}&currency=${kurs}`;
    return await getLastExchange(query_string_ex);
  };
  checkHash = (hash) => {
    checkActiveHash(hash).then((response) => {
      const comparedData = response.check_hash > 0 ? false : true;
      this.setState(
        {
          isActiveHash: comparedData,
          loading: false,
        },
        () => {
          !this.state.isActiveHash &&
            this.state.mrp_notif &&
            this.handleRecalculate();
        }
      );
    });
  };

  activeSetLabel = (formatedMonth) => {
    const {
      tableInputPO,
      tableInputContract,
      tableInputMRP,
      tableInputPlanning,
    } = this.state;
    //get next sixmonth
    const nextSixMonth = getNextMonthList(formatedMonth, 6);
    this.setLabelMonth("tableInputPO", tableInputPO, nextSixMonth, 4);
    this.setLabelMonth(
      "tableInputContract",
      tableInputContract,
      nextSixMonth,
      4
    );
    this.setLabelMonth("tableInputMRP", tableInputMRP, nextSixMonth, 1);
    this.setLabelMonth(
      "tableInputPlanning",
      tableInputPlanning,
      nextSixMonth,
      1
    );
    readCurrencyWithoutRP("?search=all").then((response) => {
      this.assignCurrency(response);
    });
  };
  setLabelMonth = (state, tableInput, nextSixMonth, startCol) => {
    const cloneTableInputMRPHeader = [...tableInput.header];

    let headerObj = [...cloneTableInputMRPHeader.slice(startCol, startCol + 6)];
    const updatedHeaderObj = headerObj.map((a, b) => {
      let insideObj = {};
      insideObj.label = nextSixMonth[b];
      if (a.width) insideObj.width = a.width;
      if (a.isLabel) insideObj.isLabel = a.isLabel;
      return insideObj;
    });
    const updatedHeader = [
      ...cloneTableInputMRPHeader.slice(0, startCol),
      ...updatedHeaderObj.slice(0),
      ...cloneTableInputMRPHeader.slice(startCol + 6),
    ];
    const cloneTableInput = { ...tableInput };
    cloneTableInput.header = updatedHeader;
    this.setState({
      [state]: cloneTableInput,
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState(
      {
        [name]: date,
      },
      () => {
        if (name == "start_month") {
          const queryMonth = dateFormat(date, "yyyy-mm-dd");
          readMaterialfromIngredient(queryMonth).then((response) => {
            this.setState({ select_items_material: response });
          });
        }
      }
    );
  };

  //handle change
  handleChange = (e) => {
    let isValid = this.state;
    const { name, value } = e;
    if (value) {
      isValid[name] = true;
      this.setState({
        [name]: value.value,
        isValid: isValid,
      });
    } else {
      isValid[name] = false;
      this.setState({
        [name]: null,
        isValid: isValid,
      });
    }
  };
  handleAddRow = (state, DefaultRowAdd) => {
    let previousData = { ...this.state[state] };
    previousData.body.splice(previousData.body.length, 0, DefaultRowAdd);
    this.setState({
      [state]: previousData,
    });
  };

  handleDeleteRow = (state) => {
    let previousData = { ...this.state[state] };
    if (previousData.body.length > numRow) {
      previousData.body.splice(previousData.body.length - 1, 1);
      const updatedFooterAll = this.calculateTotalAllOnChanged(
        previousData.body
      );
      previousData.footer = updatedFooterAll;
      this.setState(
        {
          [state]: previousData,
        },
        () => {
          this.calculateMRP();
        }
      );
    }
  };
  handleAllCalculate = (state) => {
    let previousData = { ...this.state[state] };

    const updatedFooterAll = this.calculateTotalAllOnChanged(previousData.body);
    previousData.footer = updatedFooterAll;
    this.setState(
      {
        [state]: previousData,
      },
      () => {
        this.calculateMRP();
      }
    );
  };
  setUpdatedDataMRP = (result) => {
    const { tableInputMRP } = this.state;
    const cloneTableInputMRP = { ...tableInputMRP };
    cloneTableInputMRP.body = result.body;

    this.setState(
      {
        tableInputMRP: cloneTableInputMRP,
        isActiveHash: true,
        hash_ingredient_list: result.hash_ingredient_list,
        rmbalance_id: result.rmbalance_id,
        rmbalance_value: result.rmbalance_value,
        rmbalance_qty: result.rmbalance_qty,
        rmbalance_price_kg: result.rmbalance_price_kg,
        rmbalance_currency: result.rmbalance_currency,
      },
      () => {
        this.calculateMRP();
      }
    );
  };
  handleInputChange = (row, col, data_type, name_state) => (e) => {
    const { name, value } = e.target;
    const tableInput = { ...this.state[name_state] };

    const body = tableInput.body;
    const cloneBody = { ...body[row] };
    const cloneChildren = { ...cloneBody.children[col] };
    let subtotal = 0;
    if (data_type == "number") {
      cloneChildren.value = value >= 0 ? value : null;
    }
    if (data_type == "text") {
      cloneChildren.value = value;
    }
    const updatedChildren = [
      ...cloneBody.children.slice(0, col),
      cloneChildren,
      ...cloneBody.children.slice(col + 1),
    ];
    //calculate subtotal
    if (data_type == "number") {
      subtotal = this.calculateSubtotal(updatedChildren);
    }
    //clone subtotal col[10]
    const cloneSubtotalChildren = { ...cloneBody.children[10] };
    cloneSubtotalChildren.value = subtotal;
    const updatedSubtotalChildren = [
      ...updatedChildren.slice(0, 10),
      cloneSubtotalChildren,
    ];

    const updatedBody = [
      ...body.slice(0, row),
      {
        children: updatedSubtotalChildren,
      },
      ...body.slice(row + 1),
    ];
    tableInput.body = updatedBody;
    const updatedFooterAll = this.calculateTotalAllOnChanged(updatedBody);
    tableInput.footer = updatedFooterAll;
    //
    this.setState(
      {
        [name_state]: tableInput,
      },
      () => {
        this.calculateMRP();
      }
    );
  };
  handleInputChangeMRP = () => {};
  handleInputChangePlanning =
    (row, col, data_type, name_state) => async (e) => {
      const { tableInputPlanning } = this.state;
      const cloneTableInputPlanning = { ...tableInputPlanning };
      const cloneBodyPlanning = [...cloneTableInputPlanning.body];
      const objTarget = Object.keys(e).length;

      const rowSelect = { ...cloneBodyPlanning[row] };
      const cloneChildrenSelect = { ...rowSelect.children[col] };
      let curKurs = null;
      const rowKurs = { ...cloneBodyPlanning[3] };
      const cloneChildrenKurs = { ...rowKurs.children[col] };
      let updatedChildrenKurs = [];

      if (objTarget == 2) {
        //select
        cloneChildrenSelect.value = e.value ? e.value.value : null;

        // if currency then check usd
        if (row == 2 && cloneChildrenSelect?.value) {
          let calCursDlu = cloneChildrenSelect?.value;
          if (cloneChildrenSelect?.value == "USD") {
            calCursDlu = "Rp";
          }
          if (cloneChildrenSelect?.value == "Rp") {
            calCursDlu = "USD";
          }
          curKurs = await this.exchangeRate(calCursDlu);
          let defaultUSD = curKurs.kurs_tengah ? curKurs.kurs_tengah : null;
          if (calCursDlu == "Rp") {
            defaultUSD = "1";
          }
          cloneChildrenKurs.value = defaultUSD;

          updatedChildrenKurs = [
            ...rowKurs.children.slice(0, col),
            cloneChildrenKurs,
            ...rowKurs.children.slice(col + 1),
          ];
        }
      }
      if (objTarget != 2) {
        cloneChildrenSelect.value = e.target ? e.target.value : null;
      }

      const updatedChildren = [
        ...rowSelect.children.slice(0, col),
        cloneChildrenSelect,
        ...rowSelect.children.slice(col + 1),
      ];
      const newCurs =
        objTarget == 2 && curKurs
          ? [
              {
                children: updatedChildrenKurs,
              },
              ...cloneBodyPlanning.slice(row + 2),
            ]
          : [...cloneBodyPlanning.slice(row + 1)];
      const updatedBody = [
        ...cloneBodyPlanning.slice(0, row),
        {
          children: updatedChildren,
        },
        ...newCurs,
      ];

      cloneTableInputPlanning.body = updatedBody;
      this.setState(
        {
          tableInputPlanning: cloneTableInputPlanning,
        },
        () => {
          this.calculatePlanning();
        }
      );
    };
  calculateMRP = () => {
    const {
      tableInputMRP,
      tableInputPO,
      tableInputContract,
      rmbalance_id,
      rmbalance_value,
      rmbalance_qty,
      rmbalance_price_kg,
    } = this.state;
    const cloneTableInputMRP = { ...tableInputMRP };
    const cloneBodyMRP = [...tableInputMRP.body];
    const cloneFooterPO = { ...tableInputPO.footer[0] };
    const cloneFooterContract = { ...tableInputContract.footer[0] };
    //sum total po and contract
    //create array of array
    let aoaPOConctract = [];
    let rowPO = [];
    cloneFooterPO.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) : 0;
      return rowPO.push(value);
    });
    aoaPOConctract.push(rowPO);

    let rowContract = [];
    cloneFooterContract.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) : 0;
      return rowContract.push(value);
    });
    aoaPOConctract.push(rowContract);

    const RowSumPOConctract = aoaPOConctract.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    let receiptObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Receipt",
      },
    ];
    RowSumPOConctract.map((ad, s) => {
      receiptObj.push({
        readOnly: true,
        data_type: "number",
        value: ad,
        width: "auto",
        name: `n_${s + 1}`,
      });
    });
    //create aoa balance
    let aoaRowSumNeedPOContract = [];

    const cloneBodyMRPFirstRow = { ...cloneBodyMRP[0] };
    let rowNeedsMRP = [];
    cloneBodyMRPFirstRow.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) * -1 : 0;
      return rowNeedsMRP.push(value);
    });
    aoaRowSumNeedPOContract.push(rowNeedsMRP);
    aoaRowSumNeedPOContract.push(RowSumPOConctract);

    //rowNeedsMRP
    //RowSumPOConctract
    const RowSumNeedPOContract = aoaRowSumNeedPOContract.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    let RowCarryOver = [];
    let RowBalance = [];
    let balance = 0;
    //get rmbalance if exists
    let carryOver =
      rmbalance_qty && !isNaN(rmbalance_qty) ? parseFloat(rmbalance_qty) : 0;
    let key = 0;
    //calculate carry over and balance
    RowSumNeedPOContract.map((s, l) => {
      if (s) {
        balance = s + carryOver;
        RowCarryOver.push(carryOver);
        carryOver = balance;

        if (carryOver == balance && l > 0) {
          key += 1;
        }
        return RowBalance.push(balance);
      }
      if (balance == carryOver && l > 0 && key > 1) {
        RowCarryOver.push(carryOver);

        if (carryOver == balance && l > 0) {
          key += 1;
        }
        return RowBalance.push(balance);
      }

      if (carryOver == balance && l > 0) {
        key += 1;
      }
      balance = carryOver;
      RowCarryOver.push(carryOver);
      return RowBalance.push(balance);
    });

    let carryOverObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Carry Over",
      },
    ];
    RowCarryOver.map((r, s) => {
      carryOverObj.push({
        readOnly: true,
        data_type: "number",
        value: r,
        width: "auto",
        name: "n_1",
      });
    });

    let balanceObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Balance",
      },
    ];
    RowBalance.map((r, s) => {
      balanceObj.push({
        readOnly: true,
        data_type: "number",
        value: r,
        width: "auto",
        name: "n_1",
      });
    });

    const updatedBodyMRP = [
      { ...cloneBodyMRP[0] },
      {
        children: receiptObj,
      },
      {
        children: carryOverObj,
      },

      {
        children: balanceObj,
      },
    ];
    cloneTableInputMRP.body = updatedBodyMRP;
    this.setState(
      {
        tableInputMRP: cloneTableInputMRP,
      },
      () => {
        this.calculatePlanning();
      }
    );
  };
  calculatePlanning = async () => {
    const {
      tableInputMRP,
      tableInputPlanning,
      tableInputPO,
      tableInputContract,
      select_items_future_price,
      rmbalance_price_kg,
      rmbalance_qty,
      rmbalance_value,
      rmbalance_currency,
    } = this.state;
    const cloneMRPBalance = { ...tableInputMRP.body[3] };
    const clonetableInputPlanning = { ...tableInputPlanning };

    const clonePricePlanning = { ...clonetableInputPlanning.body[1] };
    const cloneCurrencyPlanning = { ...clonetableInputPlanning.body[2] };
    const cloneERPlanning = { ...clonetableInputPlanning.body[3] };

    //calculate weighed price get
    // array merge po + contract
    const cloneHeaderPO = [...tableInputPO.header];
    const cloneBodyPO = [...tableInputPO.body];
    const cloneBodyContract = [...tableInputContract.body];
    //create array_merge

    let qtyObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Qty",
      },
    ];
    let priceKGObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Price/KG",
      },
    ];

    const arrayMergePOContract = [...cloneBodyPO, ...cloneBodyContract];
    const cloneFuturePrice = { ...select_items_future_price };
    const cloneOnlyExistsPrice = clonePricePlanning.children.slice(1);
    let tempPrice = [...priceKGObj];
    let tempQty = [...qtyObj];
    const caP = cloneMRPBalance.children.slice(1).map((r, s) => {
      let newQty = {
        readOnly: true,
        data_type: "number",
        value: r.value,
        width: r.width,
        name: r.name,
      };
      tempQty.push(newQty);
      let newPrice = {};
      if (parseFloat(r.value) < 0) {
        const existingVal =
          cloneFuturePrice[cloneHeaderPO.slice(4, 10)[s].label] &&
          cloneOnlyExistsPrice[s].value &&
          cloneFuturePrice[cloneHeaderPO.slice(4, 10)[s].label][0].value ==
            cloneOnlyExistsPrice[s].value
            ? cloneOnlyExistsPrice[s].value
            : null;

        newPrice = {
          readOnly: false,
          data_type: "number",
          value: existingVal,
          width: r.width,
          name: r.name,
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: cloneFuturePrice[cloneHeaderPO.slice(4, 10)[s].label],
          isValid: true,
          errors: {},
          isDisabled: false,
          zIndex: "20",
          fontSize: "0.60625rem",
        };
      } else {
        let initialPriceKG = 0;
        let initialQty = 0;
        if (s == 0 && (r.value || r.value == 0)) {
          //check rmbalance if usd then change kurs to rp

          const curERP = cloneERPlanning.children[1].value;
          const curCurrency = cloneCurrencyPlanning.children[1].value;
          if (rmbalance_price_kg && curCurrency && rmbalance_currency) {
            if (curCurrency == rmbalance_currency) {
              initialPriceKG = rmbalance_price_kg
                ? parseFloat(rmbalance_price_kg)
                : 0;
            } else {
              initialPriceKG = isFinite(
                parseFloat(rmbalance_price_kg) / parseFloat(curERP)
              )
                ? parseFloat(rmbalance_price_kg) / parseFloat(curERP)
                : 0;
            }
          }
          initialQty = rmbalance_qty ? parseFloat(rmbalance_qty) : 0;
        }
        //index > 0 then get previous price_kg and qty
        if (s > 0) {
          initialPriceKG = tempPrice[s].value
            ? parseFloat(tempPrice[s].value)
            : 0;
          initialQty = tempQty[s].value ? parseFloat(tempQty[s].value) : 0;
        }
        const calculateWeighedPOContract = this.calculateWeighedSpecMonth(
          arrayMergePOContract,
          initialQty,
          initialPriceKG,
          s,
          cloneCurrencyPlanning,
          cloneERPlanning
        );
        newPrice = {
          readOnly: true,
          data_type: "text",
          value:
            r.value || r.value == 0
              ? calculateWeighedPOContract[1].value.toString()
              : r.value,
          width: r.width,
          name: r.name,
        };
      }

      tempPrice.push(newPrice);
      return {
        qtyArray: newQty,
        priceArray: newPrice,
      };
    });
    const arQ = caP.map((item) => item.qtyArray);
    const arP = caP.map((item) => item.priceArray);

    const concatQ = qtyObj.concat(arQ);
    const concatP = priceKGObj.concat(arP);
    const updatedBody = [
      {
        children: concatQ,
      },
      {
        children: concatP,
      },
      { ...clonetableInputPlanning.body[2] },
      { ...clonetableInputPlanning.body[3] },
      { ...clonetableInputPlanning.body[4] },
    ];
    clonetableInputPlanning.body = updatedBody;
    this.setState(
      {
        tableInputPlanning: clonetableInputPlanning,
      },
      () => {
        this.calculateTotalPlanning();
      }
    );
  };

  calculateSubtotal = (updatedChildren) => {
    let subtotal = 0;
    updatedChildren.map((e, k) => {
      if (e.value && k >= 3 && k <= 9 && !isNaN(e.value)) {
        if (k == 3) {
          subtotal += parseFloat(e.value);
        }
        if (k > 3) {
          subtotal -= parseFloat(e.value);
        }
      }
    });
    return subtotal;
  };
  sum = (a, b) => {
    return a.map((v, i) => v + (b[i] || 0));
  };
  times = (a, b) => {
    return a.map((v, i) => v * (b[i] || 0));
  };
  divide = (a, b) => {
    return a.map((v, i) => (b[i] && b[i] > 0 ? v / (b[i] || 0) : 0));
  };
  calculateTotalAll = (updatedBody) => {
    let totalObj = [
      {
        label: "Total",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    updatedBody.map((e, k) => {
      let col = [];
      e.children.slice(4, 10).map((e, k) => {
        let value = e.value ? parseFloat(e.value) : 0;
        return col.push(value);
      });
      aoa.push(col);
    });
    //calculate vertical summary
    const res = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    res.map((ad, s) => {
      totalObj.push({
        value: ad,
      });
    });
    return totalObj;
  };

  calculateWeighedSpecMonth = (
    updatedBody,
    initialQty,
    initialPriceKG,
    index,
    cloneCurrencyPlanning,
    cloneERPlanning
  ) => {
    let totalObj = [
      {
        label: "Weighted",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    let total = [];
    updatedBody.map((e) => {
      let col = [];
      let w = [];
      const childrenPrice = e.children[2];

      e.children.slice(index + 4, index + 5).map((e, k) => {
        //check currency is exists then (check if not usd then divide exchange rate else dont divide) then else 0
        const currentCurrency = cloneCurrencyPlanning.children[k + 1]
          ? cloneCurrencyPlanning.children[k + 1].value
          : null;
        const currentER = cloneERPlanning.children[k + 1]
          ? cloneERPlanning.children[k + 1].value
          : null;
        const checkPrice =
          currentCurrency && currentER && childrenPrice.value
            ? parseFloat(childrenPrice.value)
            : 0;
        let child_weighed =
          e.value && childrenPrice.value && checkPrice
            ? checkPrice * parseFloat(e.value)
            : 0;
        let child_total =
          e.value && childrenPrice.value && checkPrice
            ? parseFloat(e.value)
            : 0;
        w.push(child_total);
        return col.push(child_weighed);
      });
      aoa.push(col);
      total.push(w);
    });
    //manual push
    const initialValue = initialQty * initialPriceKG;
    aoa.push([initialValue]);
    total.push([initialQty]);
    const sum_aoa = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    const sum_total = total.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );

    sum_aoa.map((ad, s) => {
      let valid_total = sum_total[s] > 0 ? ad / sum_total[s] : 0;
      totalObj.push({
        value: valid_total,
      });
    });

    return totalObj;
  };

  calculateWeighedAll = (updatedBody) => {
    let totalObj = [
      {
        label: "Weighted",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    let total = [];
    updatedBody.map((e, k) => {
      let col = [];
      let w = [];
      const childrenPrice = e.children[2];
      e.children.slice(4, 10).map((e, k) => {
        let child_weighed =
          e.value && childrenPrice.value
            ? parseFloat(childrenPrice.value) * parseFloat(e.value)
            : 0;
        let child_total =
          e.value && childrenPrice.value ? parseFloat(e.value) : 0;
        w.push(child_total);
        return col.push(child_weighed);
      });
      aoa.push(col);
      total.push(w);
    });
    const sum_aoa = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );

    const sum_total = total.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    sum_aoa.map((ad, s) => {
      let valid_total = sum_total[s] > 0 ? ad / sum_total[s] : 0;
      totalObj.push({
        value: valid_total,
      });
    });

    return totalObj;
  };
  calculateTotalAllOnChanged = (updatedBody) => {
    const cloneFooterWeight = this.calculateWeighedAll(updatedBody);
    const cloneFooterTotal = this.calculateTotalAll(updatedBody);
    const updatedFooterAll = [
      { children: cloneFooterTotal },
      { children: cloneFooterWeight },
    ];

    return updatedFooterAll;
  };

  calculateTotalPlanning = () => {
    const { tableInputPlanning } = this.state;
    const cloneTableInputPlanning = { ...tableInputPlanning };

    const cloneBodyPlanning = [...tableInputPlanning.body];
    let totalPriceObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Total Price (USD)",
      },
    ];
    //get row 0, 1,3
    const cloneQtyPrice = [...cloneBodyPlanning.slice(0, 2)];
    const QPExchange = [...cloneBodyPlanning.slice(3, 4)];
    //create array of array

    let aoa = [];

    cloneQtyPrice.map((e, k) => {
      let col = [];
      e.children.slice(1).map((m, j) => {
        let value = m.value ? parseFloat(m.value) : 0;
        return col.push(value);
      });
      aoa.push(col);
    });
    //calculate vertical summary
    const res = aoa.reduce((a, b) =>
      a.length > b.length ? this.times(a, b) : this.times(b, a)
    );
    let aoaQPE = [];
    QPExchange.map((b, k) => {
      b.children.slice(1).map((e, j) => {
        const checkPrice =
          e.value && isFinite(parseFloat(res[j]) / parseFloat(e.value))
            ? parseFloat(res[j]) / parseFloat(e.value)
            : 0;
        let value = checkPrice ?? 0;
        return aoaQPE.push(value);
      });
    });

    //calculate vertical summary

    aoaQPE.map((ad, s) => {
      totalPriceObj.push({
        readOnly: true,
        data_type: "number",
        value: Math.abs(ad),
        width: "auto",
        name: `n_${s + 1}`,
        label: null,
      });
    });
    const updatedBodyPlanning = [
      ...cloneBodyPlanning.slice(0, 4),
      {
        children: totalPriceObj,
      },
    ];
    cloneTableInputPlanning.body = updatedBodyPlanning;
    this.setState({
      tableInputPlanning: cloneTableInputPlanning,
    });
  };

  handleChecklist = async (event) => {
    const {
      tableInputPO,
      tableInputContract,
      isChecklist,
      tableInputPlanning,
      rmbalance_currency,
    } = this.state;
    const cloneTablePO = { ...tableInputPO };
    const cloneTableContract = { ...tableInputContract };
    const cloneTablePlanning = { ...tableInputPlanning };
    const cloneBodyPlanning = [...tableInputPlanning.body];
    const cloneBodyPO = [...cloneTablePO.body];
    const cloneFooterPO = [...cloneTablePO.footer];
    const cloneBodyContract = [...cloneTableContract.body];
    const cloneFooterContract = [...cloneTableContract.footer];
    if (!isChecklist) {
      Swal.fire({
        title: "Are you sure?",
        text: "PO and Contract will be disabled and value will be empty !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const readOnlyBodyPO = await Promise.all(
            cloneBodyPO.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = true;
                  b.value = null;
                  b.width = "auto";
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyBodyContract = await Promise.all(
            cloneBodyContract.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = true;
                  b.value = null;
                  b.width = "auto";
                  return b;
                })
              );
              return a;
            })
          );

          const readOnlyFooterPO = await Promise.all(
            cloneFooterPO.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.value = null;
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyFooterContract = await Promise.all(
            cloneFooterContract.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.value = null;
                  return b;
                })
              );
              return a;
            })
          );
          const setTablePO = {
            header: [...cloneTablePO.header],
            body: [...readOnlyBodyPO],
            footer: [...readOnlyFooterPO],
          };
          const setTableContract = {
            header: [...cloneTableContract.header],
            body: [...readOnlyBodyContract],
            footer: [...readOnlyFooterContract],
          };
          //set currency to usd only rmbalance_currency==usd
          const cloneCurrency = { ...cloneTablePlanning.body[2] };
          const cloneER = { ...cloneTablePlanning.body[3] };
          const initialCurrency = {
            readOnly: true,
            isLabel: true,
            data_type: "text",
            value: null,
            width: "auto",
            name: null,
            label: "Currency",
          };
          const initialER = {
            readOnly: true,
            isLabel: true,
            data_type: "text",
            value: null,
            width: "auto",
            name: null,
            label: "1 USD =",
          };
          const slicecloneCurrency = cloneCurrency.children
            .slice(1)
            .map((item) => {
              if (rmbalance_currency) {
                item.value = rmbalance_currency;
              }
              return item;
            });
          const slicecloneER = cloneER.children.slice(1).map((item) => {
            if (rmbalance_currency == "USD") {
              item.value = "1";
            }
            return item;
          });
          const updateBodyPlanning = [
            ...cloneBodyPlanning.slice(0, 2),
            { children: [initialCurrency, ...slicecloneCurrency] },
            { children: [initialER, ...slicecloneER] },
            ...cloneBodyPlanning.slice(4, 5),
          ];

          const setTablePlanning = {
            header: [...cloneTablePlanning.header],
            body: [...updateBodyPlanning],
            footer: [...cloneTablePlanning.footer],
          };
          this.setState(
            {
              isChecklist: !isChecklist,
              tableInputPO: setTablePO,
              tableInputContract: setTableContract,
              tableInputPlanning: setTablePlanning,
            },
            () => {
              this.calculateMRP();
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
    if (isChecklist) {
      Swal.fire({
        title: "Are you sure?",
        text: "PO and Contract will be enabled again",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const readOnlyBodyPO = await Promise.all(
            cloneBodyPO.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = false;
                  b.value = null;
                  b.width = "100%";
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyBodyContract = await Promise.all(
            cloneBodyContract.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = false;
                  b.value = null;
                  b.width = "100%";
                  return b;
                })
              );
              return a;
            })
          );

          const readOnlyFooterPO = await Promise.all(
            cloneFooterPO.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.value = null;
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyFooterContract = await Promise.all(
            cloneFooterContract.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.value = null;
                  return b;
                })
              );
              return a;
            })
          );
          const setTablePO = {
            header: [...cloneTablePO.header],
            body: [...readOnlyBodyPO],
            footer: [...readOnlyFooterPO],
          };
          const setTableContract = {
            header: [...cloneTableContract.header],
            body: [...readOnlyBodyContract],
            footer: [...readOnlyFooterContract],
          };

          this.setState(
            {
              isChecklist: !isChecklist,
              tableInputPO: setTablePO,
              tableInputContract: setTableContract,
            },
            () => {
              this.calculateMRP();
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
    return;
  };
  handlePrevMonth = () => {
    const { start_month, material, tableInputPO, tableInputContract } =
      this.state;
    const formatedMonth = dateFormat(start_month, "yyyy-mm-dd");
    const query_string = `start_month=${formatedMonth}&material_code=${material}`;
    Swal.fire({
      title: "Are you sure ?",
      text: "to get previous month of PO and Contract !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        getPrevMonth(query_string).then((response) => {
          const cloneTableInputPO = { ...tableInputPO };
          const cloneTableInputContract = { ...tableInputContract };
          cloneTableInputContract.body = response.bodyContract;
          cloneTableInputPO.body = response.bodyPO;
          this.setState(
            {
              tableInputPO: cloneTableInputPO,
              tableInputContract: cloneTableInputContract,
            },
            () => {
              this.handleAllCalculate("tableInputPO");
              this.handleAllCalculate("tableInputContract");
            }
          );
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  render() {
    let {
      id,
      errors,
      submitted,
      isSearched,
      loading,
      isClearable,
      isCreate,
      isRead,
      isValid,
      start_month,
      material,
      select_items_material,
      tableInputPO,
      tableInputContract,
      tableInputMRP,
      tableInputPlanning,
      isDisabled,
      isExists,
      isActiveHash,
      isChecklist,
      isPrevMonth,
      isAdmin,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <form
                    autoComplete="off"
                    noValidate
                    ref={(form) => (this.form = form)}
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-header ">
                            <h3 className="card-title">PO &amp; Contract</h3>
                          </div>
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group row">
                                  <Col xs={12} md={4}>
                                    <label htmlFor="start_month">
                                      Start Month *{" "}
                                    </label>
                                  </Col>

                                  <Col xs={12} md={8}>
                                    <DatePicker
                                      selected={start_month}
                                      startDate={start_month}
                                      disabledKeyboardNavigation
                                      onChange={this.handleDate.bind(
                                        this,
                                        "start_month"
                                      )}
                                      name="start_month"
                                      dateFormat="MMMM yyyy"
                                      className="form-control form-control-sm"
                                      required
                                      showMonthYearPicker
                                      showFullMonthYearPicker
                                      minDate={
                                        !isAdmin
                                          ? start_month && !isAdmin
                                            ? subMonths(
                                                new Date(start_month),
                                                0
                                              ) !== subMonths(new Date(), 0)
                                              ? subMonths(new Date(), 1)
                                              : subMonths(
                                                  new Date(start_month),
                                                  0
                                                )
                                            : subMonths(new Date(), 0)
                                          : subMonths(new Date(), 12)
                                      }
                                      maxDate={
                                        !isAdmin
                                          ? start_month && !isAdmin
                                            ? addMonths(
                                                new Date(start_month),
                                                0
                                              ) !== addMonths(new Date(), 0)
                                              ? addMonths(new Date(), 1)
                                              : addMonths(
                                                  new Date(start_month),
                                                  0
                                                )
                                            : addMonths(new Date(), 0)
                                          : addMonths(new Date(), 12)
                                      }
                                      popperContainer={Popper}
                                      calendarContainer={Calendar}
                                      disabled={
                                        isAdmin && !isSearched ? false : true
                                      }
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={4}>
                                <Col xs={12} md={12}>
                                  <FinalSelect
                                    label="Material *"
                                    name="material"
                                    value={material}
                                    placeholder="Select ..."
                                    selectOptions={select_items_material}
                                    isClearable={isClearable}
                                    handleChange={this.handleChange.bind(this)}
                                    isValid={isValid.material}
                                    error=""
                                    isFormRow={true}
                                    zIndex="30"
                                    isDisabled={isDisabled}
                                  />
                                </Col>
                              </Col>
                              <Col xs={12} md={2}>
                                {!isSearched && (
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                )}
                                {isSearched && (
                                  <Button
                                    variant="danger"
                                    onClick={this.handleReset}
                                    className="float-left"
                                  >
                                    <i className="fas fa-history" /> Reset
                                  </Button>
                                )}
                                {isPrevMonth && (
                                  <Button
                                    variant="warning"
                                    onClick={this.handlePrevMonth}
                                    className="float-right"
                                  >
                                    <i className="fas fa-clone" /> Clone Prev
                                    Month
                                  </Button>
                                )}
                              </Col>
                              <Col xs={12} md={3}>
                                {isSearched &&
                                  isExists &&
                                  isActiveHash &&
                                  isCreate && (
                                    <Button
                                      variant="success"
                                      onClick={this.handleSubmit}
                                      className="float-right"
                                    >
                                      <i className="fas fa-save" /> Submit
                                    </Button>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isSearched && isExists && !loading && (
                      <div>
                        <div className="row">
                          <div className="col-md-5 col-sm-12 col-5">
                            <div className="card card-info">
                              <div
                                className="card-header "
                                style={{ height: "52px" }}
                              >
                                <h3 className="card-title">
                                  {isActiveHash && "Summary  "}
                                  {!isActiveHash && isCreate && (
                                    <div>
                                      Summary{" "}
                                      <span
                                        style={{
                                          backgroundColor: "orange",
                                          padding: "5px",
                                        }}
                                      >
                                        There is new updated Data{" "}
                                      </span>
                                    </div>
                                  )}
                                </h3>
                                {!isActiveHash && isCreate && (
                                  <div className="card-tools ">
                                    <Button
                                      variant="warning"
                                      onClick={this.handleRecalculate}
                                    >
                                      <i className="fas fa-calculator" />{" "}
                                      Recalculate
                                    </Button>
                                  </div>
                                )}
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputMRP}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={
                                        this.handleInputChangeMRP
                                      }
                                      nameState="tableInputMRP"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-7">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  PO&nbsp;&nbsp;
                                  {isCreate && !isChecklist && (
                                    <Button
                                      variant="primary"
                                      onClick={this.handleAddRow.bind(
                                        this,
                                        "tableInputPO",
                                        DefaultRowPO
                                      )}
                                      className="btn btn-xs"
                                    >
                                      <i className="fas fa-plus" /> Row
                                    </Button>
                                  )}
                                  &nbsp;&nbsp;
                                  <Button
                                    variant="default"
                                    className="btn btn-xs"
                                    onClick={this.handleChecklist}
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={!!isChecklist}
                                        onChange={this.handleChecklist}
                                      />
                                      <label className="form-check-label">
                                        No Incoming
                                      </label>
                                    </div>
                                  </Button>
                                </h3>
                                <div className="card-tools ">
                                  {isCreate && !isChecklist && (
                                    <Button
                                      variant="warning"
                                      onClick={this.handleDeleteRow.bind(
                                        this,
                                        "tableInputPO"
                                      )}
                                      className="float-left  btn btn-xs"
                                      style={{ color: "gray" }}
                                    >
                                      <i className="fas fa-minus" /> Row
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputPO}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={this.handleInputChange}
                                      nameState="tableInputPO"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "scroll",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-sm-12 col-5">
                            <div className="card card-info">
                              <div
                                className="card-header "
                                style={{ height: "52px" }}
                              >
                                <h3 className="card-title">Planning</h3>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputPlanning}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={
                                        this.handleInputChangePlanning
                                      }
                                      nameState="tableInputPlanning"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-7">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  Contract&nbsp;&nbsp;
                                  {isCreate && !isChecklist && (
                                    <Button
                                      variant="primary"
                                      onClick={this.handleAddRow.bind(
                                        this,
                                        "tableInputContract",
                                        DefaultRowContract
                                      )}
                                      className="float-right btn btn-xs"
                                    >
                                      <i className="fas fa-plus" /> Row
                                    </Button>
                                  )}
                                </h3>
                                <div className="card-tools ">
                                  {isCreate && !isChecklist && (
                                    <Button
                                      variant="warning"
                                      onClick={this.handleDeleteRow.bind(
                                        this,
                                        "tableInputContract"
                                      )}
                                      className="float-left  btn btn-xs"
                                      style={{ color: "gray" }}
                                    >
                                      <i className="fas fa-minus" /> Row
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputContract}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={this.handleInputChange}
                                      nameState="tableInputContract"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "scroll",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isSearched && !isExists && !loading && (
                      <div>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="card card-info">
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <h3
                                      style={{ color: "red" }}
                                      className="text-center"
                                    >
                                      {" "}
                                      Data MRP is no exists!
                                    </h3>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(POContract);
