import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = 'simulator_price'

class SimulatorPriceService {
    list_material(query) {
        return axios.get(API_URL_DEFAULT + API_MODULE + '/?' + query, {
            headers: authHeader(),
        });
    }
}
export default new SimulatorPriceService();
