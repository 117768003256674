import React, { Component } from "react";
import RemoteAll from "../components/RemoteAll";
import RemoteNoPagination from "../components/RemoteNoPagination";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import thousandformat from "../helpers/thousanddelimiter";
import Container from "react-bootstrap/Container";
import ModalFooter from "react-bootstrap/ModalFooter";
import { decrypt } from "../modules/CryptoModule";

import "react-datepicker/dist/react-datepicker.css";

import { readByMonth, schedule } from "../modules/MapPowderRndModule";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import MapPowderRndTable from "../components/MapPowderRndTable";
const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class MaterialAveragePrice extends Component {
  responseresponseresponseresponseresponseresponse;
  constructor(props) {
    super(props);
    this.state = {
      disableButtonNotification: false,
      showModal: false,
      seconds: 60,
      requester: null,
      isExists: false,
      isSearched: false,
      requester_name: null,
      select_items_material: [],
      material_code: null,
      material_description: null,
      classification: null,
      isClearable: false,
      date: new Date(),
      created_at: null,
      uniq_code: null,
      select_items_parameter: [],
      errors: {},
      isValidMaterial: true,
      isValidClassification: true,
      loading: false,
      isCreate: true,
      isRead: true,
      list_datatable: [],
      cellEditProps: {},
      filter_by: null,
      material_search: null,
      showSelectClassification: true,
      showInputMaterial: false,
      detail_selected: {},
      showModalPrivateKey: false,
      file_create: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    let query_material = `planning_month=${dateFormat(
      new Date(),
      "yyyy-mm-01"
    )}`;
    readByMonth(query_material).then((response) => {
      this.setState({ list_datatable: response });
    });

    this.setState({
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
  }

  reloadDataBackend = () => {
    const { date } = this.state;
    this.setState({
      list_datatable: [],
    });
    let query_material = `planning_month=${dateFormat(date, "yyyy-mm-01")}`;
    readByMonth(query_material).then((response) => {
      this.setState({
        list_datatable: response,
        file_create: null,
      });
    });
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  onModalHide = () => {
    this.setState({
      id: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };

  toggleModalPrivateKey = () => {
    this.setState({
      showModalPrivateKey: !this.state.showModalPrivateKey,
    });
  };

  onModalPrivateKeyHide = () => {
    this.setState({
      privateKey: null,
    });
    this.toggleModalPrivateKey();
  };

  validateSearch = (date, material_code) => {
    let errors = this.state.errors;
    if (!date) {
      errors.date = "This is field required";
    }
    return errors;
  };

  GetDateFormat = (cell, row) => {
    return <div>{dateFormat(new Date(row.created_at), "dd-mmm-yyyy")}</div>;
  };

  //price MAP format
  GetPriceMAPFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    if (!row.receipt) {
      return <div>Not found</div>;
    }
    if (typeof row.receipt == "string") {
      return <div>{row.receipt}</div>;
    } else {
      return (
        <div>
          {this.GetThousandDelimiterFormatNumber(
            row.receipt.price_kg[1],
            "Receipt"
          )}
          {this.GetThousandDelimiterFormatNumber(
            row.usage.price_kg[0],
            "Usage"
          )}
          {this.GetThousandDelimiterFormatNumber(
            row.end_balance.price_kg[0],
            "End Balance"
          )}
        </div>
      );
    }
  };
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    return (
      <div>
        <button
          key={row.id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(row);
          }}
        >
          <i className="fas fa-edit" key={row.id}></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };

  GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? "$" + thousandformat(cell.toFixed(DECIMAL_PLACES), ".", ".", ",")
          : ""}
      </div>
    );
  };
  GetThousandDelimiterFormatNumber = (number, prefix) => {
    return (
      <div>
        {number || String(number) === "0" || !isNaN(parseFloat(number))
          ? prefix +
            ": $" +
            thousandformat(number.toFixed(DECIMAL_PLACES), ".", ".", ",")
          : ""}
      </div>
    );
  };

  handleDate = (name, date) => {
    this.setState({
      date: date,
    });
  };

  handleSearch = () => {
    const { date } = this.state;

    let query_material = `planning_month=${dateFormat(date, "yyyy-mm-01")}`;
    readByMonth(query_material).then((response) => {
      this.setState({ list_datatable: response });
    });
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name === "classification") {
        this.setState({
          isValidClassification: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name === "filter_by") {
        this.setState({
          classification: null,
          material_search: null,
        });
        if (newValue.value == "material") {
          this.setState({
            showInputMaterial: true,
            showSelectClassification: false,
          });
        } else if (newValue.value == "classification") {
          this.setState({
            showInputMaterial: false,
            showSelectClassification: true,
          });
        }
      }
    } else {
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
          [name]: null,
          [name + "_code"]: null,
          select_items_material: [],
        });
      }
      if (name === "classification") {
        this.setState({
          isValidClassification: false,
          [name]: null,
          [name + "_code"]: null,
        });
      }
      if (name === "filter_by") {
        this.setState({
          showInputMaterial: false,
          showSelectClassification: false,
          classification: null,
          material_search: null,
        });
      }
    }
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  onChangeHandlerFile = (name, generateList, event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const text = this.handleFile(files[0], name, generateList);
    }
  };
  handleFile = (file, name, generateList) => {
    const reader = new FileReader();
    reader.onload = this.handleText.bind(
      this,
      name,
      reader.result,
      generateList
    );
    reader.readAsText(file);
  };
  handleText = (name, text, generateList, e) => {
    const checkName = {
      public_key_file: "publicKey",
      private_key_file: "privateKey",
    };
    const customName = checkName[name];
    this.setState(
      {
        [customName]: e.target.result,
        generateList: generateList,
      },
      () => {
        customName == "privateKey" && this.checkPrivateKey();
      }
    );
  };
  checkPrivateKey = () => {
    const { privateKey, recipe_encrypted, generateList } = this.state;
    if (privateKey && recipe_encrypted) {
      decrypt({
        privateKey: privateKey,
        encryptedData: recipe_encrypted.ingredients,
      })
        .then((result) => {
          this.setState(
            {
              recipe: [...result],
            },
            () => {
              generateList && this.generateSelectVersionList();
            }
          );
        })
        .catch((err) => {
          if (this.refInputVersion) {
            this.refInputVersion.value = "";
          }
          this.setState(
            {
              select_items_version: [],
              version: null,
              data_schedule: [],
            },
            () => {
              Swal.fire("Cancelled!", "Wrong Private Key!.", "error");
            }
          );
        });
    }
  };
  generateSelectVersionList = () => {
    const { recipe } = this.state;
    let id_default = 0;
    let new_recipe = recipe.map((r, id) => {
      r.version = `Version ${r.version_no}${r.default ? " (default)" : ""}`;
      if (r.default) {
        id_default = id;
      }
      return r;
    });

    this.setState(
      {
        select_items_version: new_recipe,
        version: new_recipe[id_default].version,
      },
      () => {
        const ingredients = recipe[id_default].ingredients;
        let new_data_schedule = ingredients.map((ing) => {
          return {
            material_raw_code: ing.material_code,
            material_raw_description: ing.material_name,
            qty: ing.qty,
            uom: ing.uom,
          };
        });
        this.setState({
          data_schedule: new_data_schedule,
        });
      }
    );
  };

  handleSubmitPrivateKey = () => {
    const { privateKey, recipe_encrypted, toDelete, id, edit_batch_qty } =
      this.state;
    if (privateKey && recipe_encrypted.ingredients) {
      decrypt({
        privateKey: privateKey,
        encryptedData: recipe_encrypted.ingredients,
      })
        .then((result) => {
          this.setState(
            {
              data_schedule_update: [...result],
              data_schedule_update_beginning: [...result],
              edit_batch_qty_beginning: edit_batch_qty,
            },
            () => {
              this.toggle();
              this.toggleModalPrivateKey();
            }
          );
        })
        .catch((err) => {
          Swal.fire("Cancelled", "Wrong Private Key!", "error");
        });
    } else {
      Swal.fire("Cancelled", "Cannot find Private Key", "error");
    }
  };

  handleSubmitProcess = () => {
    const { detail_selected } = this.state;
    let collection = {
      privateKey: detail_selected.privateKey,
      material_code: detail_selected.material_code,
      planning_month: detail_selected.planning_month,
    };
    if (detail_selected.privateKey) {
      Swal.fire({
        title: "Are you sure ?",
        text: "Process this document ",
        icon: "info",
        confirmButtonText: "Yes, Process!",
        confirmButtonColor: "#589e3a",
        showCancelButton: true,
      }).then((result) => {
        this.setState({
          loading: true,
        });
        if (result.value) {
          schedule(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data on backend
                this.setState({
                  loading: false,
                });
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
      Swal.fire({
        title: "Now Allowed!",
        text: "Please completed data",
        icon: "info",
      });
    }
  };

  render() {
    let {
      showModal,
      material_code,
      material_description,
      isValidMaterial,
      date,
      list_datatable_receipt,
      list_datatable_usage,
      list_datatable_end_balance,
      columns_receipt,
      columns_non_receipt,
      loading,
      isRead,
      isExists,
      isSearched,
      uniq_code,
      isValidClassification,
      list_datatable,
      detail_selected,
      showModalPrivateKey,
      privateKey,
      file_create,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesClassification = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidClassification
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidClassification
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />

          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          ></Modal>

          {/* MODAL DETAIL */}
          <Modal
            show={showModal}
            dialogClassName="modal-90w"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                ({material_code}) {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <Row>
                  <Col>
                    <h5>Version {uniq_code}</h5>
                    <h5>Receipt</h5>
                  </Col>
                </Row>
                <RemoteNoPagination
                  data={list_datatable_receipt}
                  columns={columns_receipt}
                />
                <h5>Usage</h5>
                <RemoteNoPagination
                  data={list_datatable_usage}
                  columns={columns_non_receipt}
                />
                <h5>End Balance</h5>
                <RemoteNoPagination
                  data={list_datatable_end_balance}
                  columns={columns_non_receipt}
                />
              </div>
              {/* )} */}
            </ModalBody>
          </Modal>

          <Modal
            show={showModalPrivateKey}
            backdrop="static"
            onHide={this.onModalPrivateKeyHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <Modal.Title>Choose Private Key</Modal.Title>
            </ModalHeader>
            <ModalBody>
              <Container>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  name="file"
                  placeholder="File"
                  onChange={this.onChangeHandlerFile.bind(
                    this,
                    "private_key_file",
                    false
                  )}
                  accept={SheetJSFT}
                  required
                />
              </Container>
            </ModalBody>
            {privateKey && (
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={this.handleSubmitPrivateKey}
                  className="float-right"
                  size="sm"
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              </ModalFooter>
            )}
          </Modal>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && isSearched && isExists && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">MAP Powder RND</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <h5>Version {uniq_code}</h5>
                          <h5>Receipt</h5>
                          <RemoteNoPagination
                            data={list_datatable_receipt}
                            columns={columns_receipt}
                          />
                          <h5>Usage</h5>
                          <RemoteNoPagination
                            data={list_datatable_usage}
                            columns={columns_non_receipt}
                          />
                          <h5>End Balance</h5>
                          <RemoteNoPagination
                            data={list_datatable_end_balance}
                            columns={columns_non_receipt}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">MAP Powder RND </h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <form
                          autoComplete="off"
                          noValidate
                          ref={(form) => (this.form = form)}
                        >
                          <Row>
                            <Col xs={12} md={3}>
                              <div className="form-group">
                                <label htmlFor="date">Planning Month *</label>
                                <DatePicker
                                  selected={date}
                                  startDate={date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "date")}
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                              </div>
                            </Col>
                            <Col className="form-group d-flex align-items-end">
                              <Col xs={12} md={3}>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Show
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </form>
                        <br />
                        {!list_datatable ? (
                          "Data Not Found"
                        ) : (
                          <MapPowderRndTable
                            data={list_datatable}
                            file_create={file_create}
                            handleModal={(data) => {
                              this.setState(
                                {
                                  detail_selected: data,
                                },
                                () => {
                                  this.handleSubmitProcess();
                                }
                              );
                            }}
                            onChange={(data) => {}}
                            props={this.props}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(MaterialAveragePrice);
