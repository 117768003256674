import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import FinalSelect from "../components/FinalSelect";
import { withRouter } from "react-router-dom";
import { readMaterialByType } from "../modules/MaterialModule";
import DynamicTable from "../components/DynamicTable";

import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  searchFuturePrice,
  addPOContract,
  readPOContract,
  checkActiveHash,
  getLastUpdatedData,
  readHistoryPOContract,
} from "../modules/POContractModule";
import styled, { css } from "styled-components";

import {
  DefaultTableInputPO,
  DefaultTableInputContract,
  DefaultTableInputMRP,
  DefaultTableInputPlanning,
} from "../defaultData/defaultPOContract";
import { DefaultRowPO, DefaultRowContract } from "../defaultData/defaultRow";
import { getNextMonthList } from "../helpers/dateHelper";
import { startOfMonth, subMonths } from "date-fns";

const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
`;

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
`;
const numRow = 4;
class HistoryPOContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecklist: false,
      submitted: false,
      isActiveHash: true,
      isSearched: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      errors: {},
      requester: null,
      isCreate: false,
      isEdit: false,
      isRead: false,
      isDelete: false,
      id: null,
      isExists: false,
      isValid: { material: true },
      select_items_material: [],
      select_items_currency: [],
      select_items_future_price: [],
      start_month: startOfMonth(new Date()),
      material: null,
      hash_ingredient_list: null,
      tableInputPO: DefaultTableInputPO,
      tableInputContract: DefaultTableInputContract,
      tableInputMRP: DefaultTableInputMRP,
      tableInputPlanning: DefaultTableInputPlanning,
      rmbalance_id: null,
      rmbalance_value: null,
      rmbalance_qty: null,
      rmbalance_price_kg: null,
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
    });
    const query_string = `ZRAW,ZPAC`;
    readMaterialByType(query_string).then((response) => {
      this.setState({ select_items_material: response });
    });
    //set default row

    let { tableInputPO, tableInputContract, tableInputMRP } = { ...this.state };
    this.setDefaultRow(tableInputPO, tableInputContract);
    this.setState({
      tableInputMRP: tableInputMRP,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  setDefaultRow = (tableInputPO, tableInputContract) => {
    this.generateDefaultRow(tableInputPO, "tableInputPO");
    this.generateDefaultRow(tableInputContract, "tableInputContract");
  };
  //generate default ro
  generateDefaultRow = (tableInput, state) => {
    let body = [];
    for (let a = 0; a < numRow; a++) {
      let tableInputRowChildren = tableInput.body.children;
      let obj = {
        children: tableInputRowChildren,
      };
      body.push(obj);
    }
    tableInput.body = body;
    return this.setState({ [state]: tableInput });
  };
  assignCurrency = (selectOptions) => {
    const { tableInputPlanning } = this.state;
    const clonetableInputPlanning = { ...tableInputPlanning };
    const cloneBodyPlanning = [...clonetableInputPlanning.body];
    const cloneRowCurrency = { ...cloneBodyPlanning[2] };
    let rowCurrencyObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Currency",
      },
    ];
    cloneRowCurrency.children.slice(1).map((a, k) => {
      if (a.isSelect) {
        return rowCurrencyObj.push({
          readOnly: a.readOnly,
          data_type: a.data_type,
          value: a.value,
          width: a.width,
          name: a.name,
          isSelect: a.isSelect,
          placeholder: a.placeholder,
          selectOptions: selectOptions,
          isValid: true,
          errors: {},
          fontSize: a.fontSize,
        });
      }
      return rowCurrencyObj.push({
        readOnly: a.readOnly,
        data_type: a.data_type,
        value: a.value,
        width: a.width,
        name: a.name,
      });
    });
    const updatedBody = [
      { ...cloneBodyPlanning[0] },
      { ...cloneBodyPlanning[1] },
      {
        children: rowCurrencyObj,
      },
      { ...cloneBodyPlanning[3] },
      { ...cloneBodyPlanning[4] },
    ];
    clonetableInputPlanning.body = updatedBody;
    this.setState({
      tableInputPlanning: clonetableInputPlanning,
    });
  };
  handleClearSelect = () => {
    this.setState({
      material: null,
    });
  };
  handleReset = () => {
    this.setState({
      isSearched: false,
      isDisabled: false,
    });
  };
  handleRecalculate = () => {
    const { start_month, material } = this.state;
    let date_formatted = dateFormat(start_month, "yyyy-mm-dd");
    const query_string = `material_code=${material}&start_month=${date_formatted}`;
    Swal.fire({
      title: "Are you sure?",
      text: "to Recalculate Data !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({
          loading: true,
        });
        getLastUpdatedData(query_string)
          .then((response) => {
            this.setUpdatedDataMRP(response);
            toast.success("Data has been recalculated successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-recalculate-mrp-success",
            });
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-recalculate-mrp-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  handleSearch = async () => {
    this.setState({
      isChecklist: false,
    });
    const { start_month, material, isCreate } = this.state;
    const formatedMonth = dateFormat(start_month, "yyyy-mm-dd");
    const query_string = `start_month=${formatedMonth}&material_code=${material}`;

    if (!this.validateForm(start_month, material)) return;
    if (material) {
      this.setState({
        isDisabled: true,
        isChecklist: false,
      });
      readHistoryPOContract(query_string)
        .then(async (response) => {
          if (response.hash_ingredient_list.length == 0) {
            return this.setState({
              isExists: false,
            });
          }

          //access right
          //po
          //contract
          const tablePO = { ...response.po_contract.po };
          const tableContract = { ...response.po_contract.contract };
          const tablePlanning = { ...response.po_contract.planning };
          if (!isCreate) {
            const cloneBodyPO = [...tablePO.body];
            const setCloneBodyPO = cloneBodyPO.map((a, i) => {
              a.children = a.children.map((b, k) => {
                b.readOnly = true;
                b.width = k == 0 ? "40px" : "9%";
                return b;
              });
              return a;
            });
            tablePO.body = setCloneBodyPO;

            const cloneBodyContract = [...tableContract.body];
            const setCloneBodyContract = cloneBodyContract.map((a, i) => {
              a.children = a.children.map((b, k) => {
                b.readOnly = true;
                b.width = k == 0 ? "40px" : "9%";
                return b;
              });
              return a;
            });
            tableContract.body = setCloneBodyContract;

            const cloneBodyPlanning = [...tablePlanning.body];
            const setCloneBodyPlanning = cloneBodyPlanning.map((a, i) => {
              a.children = a.children.map((b, k) => {
                b.readOnly = true;
                b.width = k == 0 ? "40px" : "9%";
                return b;
              });
              return a;
            });
            tablePlanning.body = setCloneBodyPlanning;
          }
          const resrmbalance_id = response.rmbalance_id
            ? response.rmbalance_id
            : "";
          const isChecklistFromDB =
            typeof response.is_checklist == "boolean"
              ? response.is_checklist
              : false;

          const cloneTablePO = { ...tablePO };
          const cloneTableContract = { ...tableContract };
          const cloneTablePlanning = { ...tablePlanning };
          const cloneBodyPO = [...cloneTablePO.body];
          const cloneBodyContract = [...cloneTableContract.body];
          const cloneBodyPlanning = [...cloneTablePlanning.body];

          const readOnlyBodyPO = await Promise.all(
            cloneBodyPO.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = true;
                  b.width = "auto";
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyBodyContract = await Promise.all(
            cloneBodyContract.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = true;
                  b.width = "auto";
                  return b;
                })
              );
              return a;
            })
          );
          const readOnlyBodyPlanning = await Promise.all(
            cloneBodyPlanning.map(async (a) => {
              a.children = await Promise.all(
                a.children.map((b) => {
                  b.readOnly = true;
                  b.width = "auto";
                  return b;
                })
              );
              return a;
            })
          );
          const setTablePO = {
            header: [...cloneTablePO.header],
            body: [...readOnlyBodyPO],
            footer: [...cloneTablePO.footer],
          };
          const setTableContract = {
            header: [...cloneTableContract.header],
            body: [...readOnlyBodyContract],
            footer: [...cloneTableContract.footer],
          };
          const setTablePlanning = {
            header: [...cloneTablePlanning.header],
            body: [...readOnlyBodyPlanning],
            footer: [...cloneTablePlanning.footer],
          };

          this.setState(
            {
              isExists: true,
              hash_ingredient_list: response.hash_ingredient_list,
              tableInputPO: setTablePO,
              tableInputContract: setTableContract,
              tableInputMRP: response.po_contract.mrp,
              tableInputPlanning: setTablePlanning,
              rmbalance_id: response.rmbalance_id,
              rmbalance_value: response.rmbalance_value,
              rmbalance_qty: response.rmbalance_qty,
              rmbalance_price_kg: response.rmbalance_price_kg,
              isChecklist: isChecklistFromDB,
            },
            () => {
              this.activeSetLabel(formatedMonth);
            }
          );
        })
        .catch((err) => {
          return this.setState({
            isExists: false,
          });
        });
      searchFuturePrice(query_string).then((response) => {
        this.setState({
          select_items_future_price: response,
          isSearched: true,
        });
      });
    }
  };
  checkHash = (hash) => {
    checkActiveHash(hash).then((response) => {
      const comparedData = response.check_hash > 0 ? false : true;
      this.setState({
        isActiveHash: comparedData,
      });
    });
  };
  activeSetLabel = (formatedMonth) => {
    const {
      tableInputPO,
      tableInputContract,
      tableInputMRP,
      tableInputPlanning,
    } = this.state;
    //get next sixmonth
    const nextSixMonth = getNextMonthList(formatedMonth, 6);
    this.setLabelMonth("tableInputPO", tableInputPO, nextSixMonth, 4);
    this.setLabelMonth(
      "tableInputContract",
      tableInputContract,
      nextSixMonth,
      4
    );
    this.setLabelMonth("tableInputMRP", tableInputMRP, nextSixMonth, 1);
    this.setLabelMonth(
      "tableInputPlanning",
      tableInputPlanning,
      nextSixMonth,
      1
    );
    readCurrencyWithoutRP("?search=all").then((response) => {
      this.assignCurrency(response);
    });
  };
  setLabelMonth = (state, tableInput, nextSixMonth, startCol) => {
    const cloneTableInputMRPHeader = [...tableInput.header];

    let headerObj = [...cloneTableInputMRPHeader.slice(startCol, startCol + 6)];
    const updatedHeaderObj = headerObj.map((a, b) => {
      let insideObj = {};
      insideObj.label = nextSixMonth[b];
      if (a.width) insideObj.width = a.width;
      if (a.isLabel) insideObj.isLabel = a.isLabel;
      return insideObj;
    });
    const updatedHeader = [
      ...cloneTableInputMRPHeader.slice(0, startCol),
      ...updatedHeaderObj.slice(0),
      ...cloneTableInputMRPHeader.slice(startCol + 6),
    ];
    const cloneTableInput = { ...tableInput };
    cloneTableInput.header = updatedHeader;
    this.setState({
      [state]: cloneTableInput,
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  //handle change
  handleChange = (e) => {
    let isValid = this.state;
    const { name, value } = e;
    if (value) {
      isValid[name] = true;
      this.setState({
        [name]: value.value,
        isValid: isValid,
      });
    } else {
      isValid[name] = false;
      this.setState({
        [name]: null,
        isValid: isValid,
      });
    }
  };
  setUpdatedDataMRP = (result) => {
    const { tableInputMRP } = this.state;
    const cloneTableInputMRP = { ...tableInputMRP };
    cloneTableInputMRP.body = result.body;

    this.setState(
      {
        tableInputMRP: cloneTableInputMRP,
        isActiveHash: true,
        hash_ingredient_list: result.hash_ingredient_list,
        rmbalance_id: result.rmbalance_id,
        rmbalance_value: result.rmbalance_value,
        rmbalance_qty: result.rmbalance_qty,
        rmbalance_price_kg: result.rmbalance_price_kg,
      },
      () => {
        this.calculateMRP();
      }
    );
  };
  handleInputChange = (row, col, data_type, name_state) => (e) => {
    const { name, value } = e.target;
    const tableInput = { ...this.state[name_state] };

    const body = tableInput.body;
    const cloneBody = { ...body[row] };
    const cloneChildren = { ...cloneBody.children[col] };
    let subtotal = 0;
    if (data_type == "number") {
      cloneChildren.value = value >= 0 ? value : null;
    }
    if (data_type == "text") {
      cloneChildren.value = value;
    }
    const updatedChildren = [
      ...cloneBody.children.slice(0, col),
      cloneChildren,
      ...cloneBody.children.slice(col + 1),
    ];
    //calculate subtotal
    if (data_type == "number") {
      subtotal = this.calculateSubtotal(updatedChildren);
    }
    //clone subtotal col[10]
    const cloneSubtotalChildren = { ...cloneBody.children[10] };
    cloneSubtotalChildren.value = subtotal;
    const updatedSubtotalChildren = [
      ...updatedChildren.slice(0, 10),
      cloneSubtotalChildren,
    ];

    const updatedBody = [
      ...body.slice(0, row),
      {
        children: updatedSubtotalChildren,
      },
      ...body.slice(row + 1),
    ];
    tableInput.body = updatedBody;
    const updatedFooterAll = this.calculateTotalAllOnChanged(updatedBody);
    tableInput.footer = updatedFooterAll;
    //
    this.setState(
      {
        [name_state]: tableInput,
      },
      () => {
        this.calculateMRP();
      }
    );
  };
  handleInputChangeMRP = () => {};
  handleInputChangePlanning = (row, col, data_type, name_state) => (e) => {
    const { tableInputPlanning } = this.state;
    const cloneTableInputPlanning = { ...tableInputPlanning };
    const cloneBodyPlanning = [...cloneTableInputPlanning.body];
    const objTarget = Object.keys(e).length;

    const rowSelect = { ...cloneBodyPlanning[row] };
    const cloneChildrenSelect = { ...rowSelect.children[col] };

    if (objTarget == 2) {
      //select
      cloneChildrenSelect.value = e.value ? e.value.value : null;
    }
    if (objTarget != 2) {
      cloneChildrenSelect.value = e.target ? e.target.value : null;
    }

    const updatedChildren = [
      ...rowSelect.children.slice(0, col),
      cloneChildrenSelect,
      ...rowSelect.children.slice(col + 1),
    ];
    const updatedBody = [
      ...cloneBodyPlanning.slice(0, row),
      {
        children: updatedChildren,
      },
      ...cloneBodyPlanning.slice(row + 1),
    ];
    cloneTableInputPlanning.body = updatedBody;
    this.setState(
      {
        tableInputPlanning: cloneTableInputPlanning,
      },
      () => {
        this.calculateTotalPlanning();
      }
    );
  };
  calculateMRP = () => {
    const {
      tableInputMRP,
      tableInputPO,
      tableInputContract,
      rmbalance_id,
      rmbalance_value,
      rmbalance_qty,
      rmbalance_price_kg,
    } = this.state;
    const cloneTableInputMRP = { ...tableInputMRP };
    const cloneBodyMRP = [...tableInputMRP.body];
    const cloneFooterPO = { ...tableInputPO.footer[0] };
    const cloneFooterContract = { ...tableInputContract.footer[0] };
    //sum total po and contract
    //create array of array
    let aoaPOConctract = [];
    let rowPO = [];
    cloneFooterPO.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) : 0;
      return rowPO.push(value);
    });
    aoaPOConctract.push(rowPO);

    let rowContract = [];
    cloneFooterContract.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) : 0;
      return rowContract.push(value);
    });
    aoaPOConctract.push(rowContract);

    const RowSumPOConctract = aoaPOConctract.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    let receiptObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Receipt",
      },
    ];
    RowSumPOConctract.map((ad, s) => {
      receiptObj.push({
        readOnly: true,
        data_type: "number",
        value: ad,
        width: "auto",
        name: `n_${s + 1}`,
      });
    });
    //create aoa balance
    let aoaRowSumNeedPOContract = [];

    const cloneBodyMRPFirstRow = { ...cloneBodyMRP[0] };
    let rowNeedsMRP = [];
    cloneBodyMRPFirstRow.children.slice(1, 7).map((e, k) => {
      let value = e.value ? parseFloat(e.value) * -1 : 0;
      return rowNeedsMRP.push(value);
    });
    aoaRowSumNeedPOContract.push(rowNeedsMRP);
    aoaRowSumNeedPOContract.push(RowSumPOConctract);

    //rowNeedsMRP
    //RowSumPOConctract
    const RowSumNeedPOContract = aoaRowSumNeedPOContract.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    let RowCarryOver = [];
    let RowBalance = [];
    let balance = 0;
    //get rmbalance if exists
    let carryOver =
      rmbalance_qty && !isNaN(rmbalance_qty) ? parseFloat(rmbalance_qty) : 0;
    let key = 0;
    //calculate carry over and balance
    RowSumNeedPOContract.map((s, l) => {
      if (s) {
        balance = s + carryOver;
        RowCarryOver.push(carryOver);
        carryOver = balance;

        if (carryOver == balance && l > 0) {
          key += 1;
        }
        return RowBalance.push(balance);
      }
      if (balance == carryOver && l > 0 && key > 1) {
        RowCarryOver.push(0);

        if (carryOver == balance && l > 0) {
          key += 1;
        }
        return RowBalance.push(0);
      }

      if (carryOver == balance && l > 0) {
        key += 1;
      }
      balance = carryOver;
      RowCarryOver.push(carryOver);
      return RowBalance.push(balance);
    });

    let carryOverObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Carry Over",
      },
    ];
    RowCarryOver.map((r, s) => {
      carryOverObj.push({
        readOnly: true,
        data_type: "number",
        value: r,
        width: "auto",
        name: "n_1",
      });
    });

    let balanceObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Balance",
      },
    ];
    RowBalance.map((r, s) => {
      balanceObj.push({
        readOnly: true,
        data_type: "number",
        value: r,
        width: "auto",
        name: "n_1",
      });
    });

    const updatedBodyMRP = [
      { ...cloneBodyMRP[0] },
      {
        children: receiptObj,
      },
      {
        children: carryOverObj,
      },

      {
        children: balanceObj,
      },
    ];
    cloneTableInputMRP.body = updatedBodyMRP;
    this.setState(
      {
        tableInputMRP: cloneTableInputMRP,
      },
      () => {
        this.calculatePlanning();
      }
    );
  };
  calculatePlanning = () => {
    const {
      tableInputMRP,
      tableInputPlanning,
      tableInputPO,
      tableInputContract,
      select_items_future_price,
      rmbalance_price_kg,
      rmbalance_qty,
      rmbalance_value,
    } = this.state;
    const cloneMRPBalance = { ...tableInputMRP.body[3] };
    const clonetableInputPlanning = { ...tableInputPlanning };

    //calculate weighed price get
    // array merge po + contract
    const cloneHeaderPO = [...tableInputPO.header];
    const cloneBodyPO = [...tableInputPO.body];
    const cloneBodyContract = [...tableInputContract.body];
    //create array_merge

    let qtyObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Qty",
      },
    ];
    let priceKGObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Price/KG",
      },
    ];

    const arrayMergePOContract = [...cloneBodyPO, ...cloneBodyContract];
    const cloneFuturePrice = { ...select_items_future_price };

    cloneMRPBalance.children.slice(1).map((r, s) => {
      qtyObj.push({
        readOnly: true,
        data_type: "number",
        value: r.value,
        width: r.width,
        name: r.name,
      });
      if (parseFloat(r.value) < 0) {
        return priceKGObj.push({
          readOnly: true,
          data_type: "text",
          value: null,
          width: r.width,
          name: r.name,
          isSelect: true,
          placeholder: "Select ...",
          selectOptions: cloneFuturePrice[cloneHeaderPO.slice(4, 10)[s].label],
          isValid: true,
          errors: {},
          isDisabled: true,
          zIndex: "20",
          fontSize: "0.60625rem",
        });
      }
      let initialPriceKG = 0;
      let initialQty = 0;
      if (s == 0 && (r.value || r.value == 0)) {
        initialPriceKG = rmbalance_price_kg
          ? parseFloat(rmbalance_price_kg)
          : 0;
        initialQty = rmbalance_qty ? parseFloat(rmbalance_qty) : 0;
      }
      //index > 0 then get previous price_kg and qty
      if (s > 0) {
        initialPriceKG = priceKGObj[s].value
          ? parseFloat(priceKGObj[s].value)
          : 0;
        initialQty = qtyObj[s].value ? parseFloat(qtyObj[s].value) : 0;
      }

      const calculateWeighedPOContract = this.calculateWeighedSpecMonth(
        arrayMergePOContract,
        initialQty,
        initialPriceKG,
        s
      );

      return priceKGObj.push({
        readOnly: true,
        data_type: "text",
        value:
          r.value || r.value == 0
            ? calculateWeighedPOContract[1].value.toString()
            : r.value,
        width: r.width,
        name: r.name,
      });
    });

    const updatedBody = [
      {
        children: qtyObj,
      },
      {
        children: priceKGObj,
      },
      { ...clonetableInputPlanning.body[2] },
      { ...clonetableInputPlanning.body[3] },
      { ...clonetableInputPlanning.body[4] },
    ];
    clonetableInputPlanning.body = updatedBody;
    this.setState(
      {
        tableInputPlanning: clonetableInputPlanning,
      },
      () => {
        this.calculateTotalPlanning();
      }
    );
  };

  calculateSubtotal = (updatedChildren) => {
    let subtotal = 0;
    updatedChildren.map((e, k) => {
      if (e.value && k >= 3 && k <= 9 && !isNaN(e.value)) {
        if (k == 3) {
          subtotal += parseFloat(e.value);
        }
        if (k > 3) {
          subtotal -= parseFloat(e.value);
        }
      }
    });
    return subtotal;
  };
  sum = (a, b) => {
    return a.map((v, i) => v + (b[i] || 0));
  };
  times = (a, b) => {
    return a.map((v, i) => v * (b[i] || 0));
  };
  divide = (a, b) => {
    return a.map((v, i) => (b[i] && b[i] > 0 ? v / (b[i] || 0) : 0));
  };
  calculateTotalAll = (updatedBody) => {
    let totalObj = [
      {
        label: "Total",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    updatedBody.map((e, k) => {
      let col = [];
      e.children.slice(4, 10).map((e, k) => {
        let value = e.value ? parseFloat(e.value) : 0;
        return col.push(value);
      });
      aoa.push(col);
    });
    //calculate vertical summary
    const res = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    res.map((ad, s) => {
      totalObj.push({
        value: ad,
      });
    });
    return totalObj;
  };

  calculateWeighedSpecMonth = (
    updatedBody,
    initialQty,
    initialPriceKG,
    index
  ) => {
    let totalObj = [
      {
        label: "Weighted",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    let total = [];
    updatedBody.map((e, k) => {
      let col = [];
      let w = [];
      const childrenPrice = e.children[2];

      e.children.slice(index + 4, index + 5).map((e, k) => {
        let child_weighed =
          e.value && childrenPrice.value
            ? parseFloat(childrenPrice.value) * parseFloat(e.value)
            : 0;
        let child_total =
          e.value && childrenPrice.value ? parseFloat(e.value) : 0;
        w.push(child_total);
        return col.push(child_weighed);
      });
      aoa.push(col);
      total.push(w);
    });
    //manual push
    const initialValue = initialQty * initialPriceKG;
    aoa.push([initialValue]);
    total.push([initialQty]);

    const sum_aoa = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    const sum_total = total.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );

    sum_aoa.map((ad, s) => {
      let valid_total = sum_total[s] > 0 ? ad / sum_total[s] : 0;
      totalObj.push({
        value: valid_total,
      });
    });

    return totalObj;
  };
  calculateWeighedAll = (updatedBody) => {
    let totalObj = [
      {
        label: "Weighted",
        colspan: "4",
      },
    ];
    //create array of array
    let aoa = [];
    let total = [];
    updatedBody.map((e, k) => {
      let col = [];
      let w = [];
      const childrenPrice = e.children[2];
      e.children.slice(4, 10).map((e, k) => {
        let child_weighed =
          e.value && childrenPrice.value
            ? parseFloat(childrenPrice.value) * parseFloat(e.value)
            : 0;
        let child_total =
          e.value && childrenPrice.value ? parseFloat(e.value) : 0;
        w.push(child_total);
        return col.push(child_weighed);
      });
      aoa.push(col);
      total.push(w);
    });
    const sum_aoa = aoa.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );

    const sum_total = total.reduce((a, b) =>
      a.length > b.length ? this.sum(a, b) : this.sum(b, a)
    );
    sum_aoa.map((ad, s) => {
      let valid_total = sum_total[s] > 0 ? ad / sum_total[s] : 0;
      totalObj.push({
        value: valid_total,
      });
    });

    return totalObj;
  };
  calculateTotalAllOnChanged = (updatedBody) => {
    const cloneFooterWeight = this.calculateWeighedAll(updatedBody);
    const cloneFooterTotal = this.calculateTotalAll(updatedBody);
    const updatedFooterAll = [
      { children: cloneFooterTotal },
      { children: cloneFooterWeight },
    ];

    return updatedFooterAll;
  };

  calculateTotalPlanning = () => {
    const { tableInputPlanning } = this.state;
    const cloneTableInputPlanning = { ...tableInputPlanning };
    const cloneBodyPlanning = [...tableInputPlanning.body];
    let totalPriceObj = [
      {
        readOnly: true,
        isLabel: true,
        data_type: "text",
        value: null,
        width: "auto",
        name: null,
        label: "Total Price (USD)",
      },
    ];
    //get row 0, 1,3
    const cloneQtyPrice = [...cloneBodyPlanning.slice(0, 2)];

    //create array of array
    let aoa = [];
    cloneQtyPrice.map((e, k) => {
      let col = [];
      e.children.slice(1).map((e, k) => {
        let value = e.value ? parseFloat(e.value) : 0;
        return col.push(value);
      });
      aoa.push(col);
    });
    //calculate vertical summary
    const res = aoa.reduce((a, b) =>
      a.length > b.length ? this.times(a, b) : this.times(b, a)
    );
    const QPExchange = [...cloneBodyPlanning.slice(3, 4)];
    let aoaQPE = [];
    aoaQPE.push(res);
    QPExchange.map((e, k) => {
      let colqpe = [];
      e.children.slice(1).map((e, k) => {
        let value = e.value ? parseFloat(e.value) : 0;
        return colqpe.push(value);
      });
      aoaQPE.push(colqpe);
    });

    //calculate vertical summary
    const resFinal = aoaQPE.reduce((a, b) =>
      a.length >= b.length ? this.divide(a, b) : this.divide(b, a)
    );

    resFinal.map((ad, s) => {
      totalPriceObj.push({
        readOnly: true,
        data_type: "number",
        value: Math.abs(ad),
        width: "auto",
        name: `n_${s + 1}`,
        label: null,
      });
    });
    const updatedBodyPlanning = [
      ...cloneBodyPlanning.slice(0, 4),
      {
        children: totalPriceObj,
      },
    ];
    cloneTableInputPlanning.body = updatedBodyPlanning;
    this.setState({
      tableInputPlanning: cloneTableInputPlanning,
    });
  };
  //validate input
  validateForm = (date, material) => {
    let errors = this.state.errors;
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValid: { material: false },
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    return errors;
  };
  render() {
    let {
      id,
      errors,
      submitted,
      isSearched,
      loading,
      isClearable,
      isCreate,
      isRead,
      isValid,
      start_month,
      material,
      select_items_material,
      tableInputPO,
      tableInputContract,
      tableInputMRP,
      tableInputPlanning,
      isDisabled,
      isExists,
      isActiveHash,
      isChecklist,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <form
                    autoComplete="off"
                    noValidate
                    ref={(form) => (this.form = form)}
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-header ">
                            <h3 className="card-title">
                              History PO &amp; Contract
                            </h3>
                          </div>
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group row">
                                  <Col xs={12} md={4}>
                                    <label htmlFor="start_month">
                                      Start Month *{" "}
                                    </label>
                                  </Col>

                                  <Col xs={12} md={8}>
                                    <DatePicker
                                      selected={start_month}
                                      startDate={start_month}
                                      disabledKeyboardNavigation
                                      onChange={this.handleDate.bind(
                                        this,
                                        "start_month"
                                      )}
                                      name="start_month"
                                      dateFormat="MMMM yyyy"
                                      className="form-control form-control-sm"
                                      required
                                      showMonthYearPicker
                                      showFullMonthYearPicker
                                      popperContainer={Popper}
                                      calendarContainer={Calendar}
                                      disabled={isDisabled}
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col xs={12} md={4}>
                                <Col xs={12} md={12}>
                                  <FinalSelect
                                    label="Material *"
                                    name="material"
                                    value={material}
                                    placeholder="Select ..."
                                    selectOptions={select_items_material}
                                    isClearable={isClearable}
                                    handleChange={this.handleChange.bind(this)}
                                    isValid={isValid.material}
                                    error={errors.material}
                                    isFormRow={true}
                                    zIndex="30"
                                    isDisabled={isDisabled}
                                  />
                                </Col>
                              </Col>
                              <Col xs={12} md={2}>
                                {!isSearched && (
                                  <Button
                                    variant="info"
                                    onClick={this.handleSearch}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                )}
                                {isSearched && (
                                  <Button
                                    variant="danger"
                                    onClick={this.handleReset}
                                    className="float-left"
                                  >
                                    <i className="fas fa-history" /> Reset
                                  </Button>
                                )}
                              </Col>
                              <Col xs={12} md={3}></Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isSearched && isExists && (
                      <div>
                        <div className="row">
                          <div className="col-md-5 col-sm-12 col-5">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  {isActiveHash && "Summary  "}
                                  {!isActiveHash && isCreate && (
                                    <div>
                                      Summary{" "}
                                      <span
                                        style={{
                                          backgroundColor: "orange",
                                          padding: "5px",
                                        }}
                                      >
                                        There is new updated Data{" "}
                                      </span>
                                    </div>
                                  )}
                                </h3>
                                {!isActiveHash && isCreate && (
                                  <div className="card-tools ">
                                    <Button
                                      variant="warning"
                                      onClick={this.handleRecalculate}
                                    >
                                      <i className="fas fa-calculator" />{" "}
                                      Recalculate
                                    </Button>
                                  </div>
                                )}
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputMRP}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={
                                        this.handleInputChangeMRP
                                      }
                                      nameState="tableInputMRP"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-7">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  PO&nbsp;&nbsp;
                                  <Button
                                    variant="default"
                                    className="btn btn-xs"
                                    disabled={true}
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={!!isChecklist}
                                      />
                                      <label className="form-check-label">
                                        No Incoming
                                      </label>
                                    </div>
                                  </Button>
                                </h3>
                                <div className="card-tools "></div>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputPO}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={this.handleInputChange}
                                      nameState="tableInputPO"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "scroll",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 col-sm-12 col-5">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">Planning</h3>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputPlanning}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={
                                        this.handleInputChangePlanning
                                      }
                                      nameState="tableInputPlanning"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-7">
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  Contract&nbsp;&nbsp;
                                </h3>
                                <div className="card-tools "></div>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <DynamicTable
                                      tableInput={tableInputContract}
                                      otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                      handleInputChange={this.handleInputChange}
                                      nameState="tableInputContract"
                                      styleTable={{
                                        height: "350px",
                                        overflow: "scroll",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isSearched && !isExists && (
                      <div>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="card card-info">
                              <div className="card-body">
                                <Row>
                                  <Col xs={12} md={12}>
                                    <h3
                                      style={{ color: "red" }}
                                      className="text-center"
                                    >
                                      {" "}
                                      Data is not found!
                                    </h3>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(HistoryPOContract);
