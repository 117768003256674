import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import { addMonths } from "date-fns";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceListMonthlyTableSales = ({ data, planning_month }) => {
    const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
    const detailStyle = { color: "#0052CC", cursor: "pointer" };

    const [isOpen, setOpen] = useState(false);
    const [dataDetail, setDataDetail] = useState([]);
    const [title, setTitle] = useState("");

    const renderHeaderMonth = () => {
        let list_date = [];
        let date = new Date(planning_month);
        for (let i = 0; i < 6; i++) {
            list_date.push(<th>{dateFormat(addMonths(date, i), "mmm-yy")}</th>);
        }
        return list_date;
    };

    const detail = (name, row, index) => {
        const datas = data[row]["detail_price" + index];
        setTitle(`${name} ${dateFormat(addMonths(new Date(planning_month), index), "mmm-yy")}`);
        if (datas) {
            setDataDetail(datas);
        }
        setOpen(true);
    };

    return (
        <>
            <div key={data}>
                <Form>
                    <Table
                        bordered
                        hover
                        size="sm"
                        style={{ textAlign: "center", justifyContent: "center" }}
                    >
                        <thead className="Stick to top">
                            <tr style={headerStyle}>
                                <th>No</th>
                                <th>Material Code</th>
                                <th>Material Name</th>
                                <th>Facility</th>
                                <th>Status</th>
                                {renderHeaderMonth()}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, idx) => (
                                <tr>
                                    <td>{idx + 1}</td>
                                    <td>{item.material_code}</td>
                                    <td>{item.material_name}</td>
                                    <td>
                                        {item.type}
                                        {item.plant ? " " + item.plant : ""}
                                    </td>
                                    <td>{item.status}</td>
                                    <td>
                                        {isNaN(parseFloat(item.val0)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                    <td>
                                        {isNaN(parseFloat(item.val1)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                    <td>
                                        {isNaN(parseFloat(item.val2)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                    <td>
                                        {isNaN(parseFloat(item.val3)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                    <td>
                                        {isNaN(parseFloat(item.val4)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                    <td>
                                        {isNaN(parseFloat(item.val5)) ? (
                                            <i class="fa fa-info-circle" aria-hidden="true" title="Not found"></i>
                                        ) : item.val0.toFixed(DECIMAL_PLACES)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form>
            </div>
        </>
    );
};

export default PriceListMonthlyTableSales;
