import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const NotificationException = ({ data, type, search }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  if (data.type == "ingredient_list") {
    return !data.data ? (
      "Data Not Found"
    ) : (
      <div key={data}>
        <a>Created at :</a> {dateFormat(data.created_at, "dd-mm-yyyy H:MM")}
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="still">
              <tr>
                <th>No</th>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Prod Facility</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{item.material_code}</td>
                  <td>{item?.material_name ?? item?.material_desc}</td>
                  <td>
                    {JSON.stringify(item.prod_facility)
                      .replace("[", "")
                      .replace("]", "")
                      .replaceAll('"', "")}
                  </td>
                  <td>BOM FG Not Found</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
  if (data.type == "price_list") {
    return (
      <div key={data}>
        <a>Created at :</a> {dateFormat(data.created_at, "dd-mm-yyyy H:MM")}
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="still">
              <tr>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Planning Date</th>
                <th>Production Date</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((item, idx) => (
                <tr>
                  <td>{item.material_code}</td>
                  <td>{item.material_desc}</td>
                  <td>{dateFormat(item.planning_date, "mmmm yyyy")}</td>
                  <td>{dateFormat(item.production_date, "mmmm yyyy")}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
  if (data.type == "map") {
    return (
      <div key={data}>
        <a>Created at :</a> {dateFormat(data.created_at, "dd-mm-yyyy H:MM")}
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="still">
              <tr>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Planning Month</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((item, idx) => (
                <tr>
                  <td>{item.material_code}</td>
                  <td>{item.material_desc}</td>
                  <td>{dateFormat(item.planning_month, "mmmm yyyy")}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
  if (data.type == "powder_price") {
    return (
      <div key={data}>
        <a>Created at :</a> {dateFormat(data.created_at, "dd-mm-yyyy H:MM")}
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="still">
              <tr>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Planning Month</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((item, idx) => (
                <tr>
                  <td>{item.material_code}</td>
                  <td>{item.material_desc}</td>
                  <td>{dateFormat(item.planning_month, "mmmm yyyy")}</td>
                  <td>{item.remark}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
};

export default NotificationException;
