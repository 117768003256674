import React, { Component } from "react";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Table from "react-bootstrap/Table";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
// import { createRpp, readByDate } from "../modules/ApsRawMaterialModule";
import {
  readRawMaterial,
  downloadExcelRawMaterial,
} from "../../modules/handri/ApsRawMaterialModule";
import thousandformat from "../../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  readMaterialGetBalance,
  readMaterialByType,
  readMaterialBomFG,
} from "../../modules/MaterialModule";

import NumberFormat from "react-number-format";
import DataTable from "react-data-table-component";
import moment from "moment/moment";
import addSeparatorsNF from "../../helpers/thousanddelimiter";
import fileDownload from "js-file-download";

class ApsRawMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_first: null,
      date_second: null,
      loading: false,
      list_datatable: [],
      columns: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "date",
      sortOrder: "desc",
    };
  }

  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleSearch = () => {
    this.setState({ loading: true });
    const { date_first, date_second, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    if (date_first > date_second) {
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readRawMaterial(query_string).then((response) => {
      const col = [];
      col.push({
        selector: (row) => (
          <div style={{ whiteSpace: "normal" }}>{row?.material_name}</div>
        ),
        name: <b>Material Name</b>,
        sort: false,
      });
      for (
        let m = moment(date_first_formatted);
        m.diff(moment(date_second_formatted), "days") <= 0;
        m.add(1, "days")
      ) {
        const n = m.format("YYYY-MM-DD").toString();
        col.push({
          cell: (row) => {
            return addSeparatorsNF(
              row.data.filter((v) => v.date === n)[0]?.qty,
              ".",
              ".",
              "."
            );
          },
          name: <b>{m.format("DD MMM YY")}</b>,
          sort: false,
        });
      }
      this.setState({
        list_datatable: response ? response : [],
        loading: false,
        columns: col,
      });
    });
  };

  handleDownload = () => {
    const { date_first, date_second, sortBy, sortOrder, page, sizePerPage } =
      this.state;

    const query = {
      first: moment(date_first).format("YYYY-MM-DD"),
      second: moment(date_second).format("YYYY-MM-DD"),
      sizePerPage: sizePerPage,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };

    downloadExcelRawMaterial(query)
      .then((response) => {
        const datetime = moment().format("YYYYMMDD-HHmmss");
        const fileName = `raw-material-${datetime}.xlsx`;
        fileDownload(response, fileName);
        this.handleBulkClear();
      })
      .catch(() => {})
      .finally(() => {});
  };

  handleReset = () => {
    this.setState({
      list_datatable: [],
      date_first: null,
      date_second: null,
    });
  };

  render() {
    let { date_first, date_second, list_datatable, columns, loading } =
      this.state;
    return (
      <div>
        <div>
          <ToastContainer />

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="card-body">
                    <Row className="mt-3">
                      <div className="col-3">
                        <div className="form-group">
                          <DatePicker
                            selected={date_first}
                            startDate={date_first}
                            disabledKeyboardNavigation
                            onChange={this.handleDate.bind(this, "date_first")}
                            name="date"
                            dateFormat="dd MMMM yyyy"
                            className="form-control form-control-sm"
                            placeholderText="Start Date"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <DatePicker
                            selected={date_second}
                            startDate={date_second}
                            disabledKeyboardNavigation
                            onChange={this.handleDate.bind(this, "date_second")}
                            name="date"
                            dateFormat="dd MMMM yyyy"
                            className="form-control form-control-sm"
                            placeholderText="End Date"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <Button
                            variant="info"
                            onClick={this.handleSearch}
                            className="float-left"
                          >
                            <i className="fas fa-search" /> Search
                          </Button>
                          <Button
                            style={{ marginLeft: "2px" }}
                            variant="warning"
                            onClick={this.handleReset}
                            className="float-left"
                          >
                            <i className="fas fa-history" /> Reset
                          </Button>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <Button
                            style={{ marginRight: "5px" }}
                            variant="info"
                            onClick={this.handleDownload}
                            className="float-right"
                          >
                            <i className="fas fa-download" /> Download Excel
                          </Button>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <DataTable
                          className="table table-modern"
                          columns={columns}
                          data={list_datatable}
                          progressPending={loading}
                          pagination
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>
    );
  }
}

export default withRouter(ApsRawMaterial);
