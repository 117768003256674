import React, { Component, useMemo } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";
import DatePicker from "react-datepicker";
import {
  getCloudRunJobs,
  monitoringScheduler,
} from "../../modules/ninda/MonitoringSchedulerModule";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");
const TableCustom = ({
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  fetchData,
  handleCustomUpdate,
}) => {
  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return fetchData(page, newPerPage);
  };
  const columns = useMemo(
    () => [
      {
        name: "Type",
        selector: (row) => (row?.cron ? row.cron : "-"),
        sortable: true,
      },
      {
        name: "Planning Month",
        selector: (row) => (row?.planning_month ? row.planning_month : "-"),
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: (row) => (row?.status ? row.status : "-"),
        sortable: true,
        center: true,
      },
      {
        name: "Requester",
        selector: (row) => (row?.requester ? row.requester : "-"),
        sortable: true,
        center: true,
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  fetchData: PropTypes.func,
  handleCustomUpdate: PropTypes.func,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  fetchData: () => {},
  handleCustomUpdate: () => {
    return false;
  },
};
class MonitorScheduler extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      planning_month: null,
      selectedType: null,
      cloud_run: [],
      requester: null,
      page: 1,
      count: 0,
      user: "",
      type: null,
      prevPage: 1,
      sizePerPage: 10,
      loading_cloud_run_job: false,
      file_value: null,
    };
  }

  componentDidMount() {
    const { page, sizePerPage } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      user: user.username,
    });
    this.getData(page, sizePerPage);
  }

  handleDate = (name, date) => {
    this.setState({
      planning_month: date,
      date: date,
    });
  };

  handleChange = async (e) => {
    this.setState(
      {
        selectedType: e.target.value,
      },
      () => {}
    );
  };

  getData = async (page, sizePerPage) => {
    this.setState({
      loading_cloud_run_job: true,
    });
    const query = { page: page, sizePerPage: sizePerPage };
    getCloudRunJobs(query)
      .then((response) => {
        this.setState({
          cloud_run: response.foundData,
          count: response.countData,
        });
      })
      .catch((err) => {
        this.setState({
          cloud_run: [],
          count: 0,
        });
        Swal.fire("Error", err, "error");
      })
      .finally(() => {
        this.setState({ loading_cloud_run_job: false });
      });
  };

  handlePage = async (idx) => {
    await this.setState(
      {
        page: idx,
      },
      () => {}
    );
  };

  handleTrigger = async (e) => {
    const {
      page,
      planning_month,
      selectedType,
      user,
      sizePerPage,
      file_value,
    } = this.state;
    if (this.state.planning_month === null) {
      return Swal.fire("Warning", "Please choose planning month", "warning");
    }
    if (this.state.selectedType === null) {
      return Swal.fire("Warning", "Please choose scheduler type", "warning");
    }
    let body = {
      private_key: file_value,
      planning_month: planning_month,
      type: selectedType,
      requester: user,
    };
    this.setState({ loading: true });
    await monitoringScheduler(body)
      .then((res) => {
        this.getData(page, sizePerPage);
        Swal.fire("Success", "", "success");
      })
      .catch((err) => {
        Swal.fire("Error", err, "error");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onModalLoadingHide = () => {
    this.setState({ loading: false });
  };
  render() {
    let {
      date,
      cloud_run,
      page,
      count,
      loading_cloud_run_job,
      sizePerPage,
      loading,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <div className="content-wrapper">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Schedule Type</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label className="col-md-5 col-form-label">
                            Planning Month
                          </label>
                          <div className="input-group col-md-6">
                            <DatePicker
                              selected={date}
                              startDate={date}
                              disabledKeyboardNavigation
                              onChange={this.handleDate.bind(this, "date")}
                              name="date"
                              wrapperClassName="datePickerCustom"
                              dateFormat="MMMM yyyy"
                              className="form-control"
                              required
                              showMonthYearPicker
                              autoComplete="off"
                            />
                            <span className="error invalid-feedback">
                              Please entries planning month
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-5 col-form-label">
                            Type
                          </label>
                          <div className="input-group col-md-6">
                            <select
                              class="custom-select"
                              name="selectedType"
                              defaultValue={this.selectedType}
                              onChange={this.handleChange}
                              required
                            >
                              <option selected>Choose scheduler type</option>
                              <option value="salesforecast_to_mrp">
                                Sales Forecast to MRP
                              </option>
                              <option value="mrp_to_ingredients_needs">
                                MRP to Ingredients Needs
                              </option>
                              <option value="po_contract_to_map">
                                PO Contract to MAP
                              </option>
                              <option value="map_rnd">MAP RND</option>
                              <option value="price_list">
                                Map To Price List
                              </option>
                              <option value="summary_raw_mat_need_daily">
                                Summary Raw Mat Need Daily
                              </option>
                              <option value="draft_rpp">Draft RPP</option>
                            </select>
                            <span className="error invalid-feedback">
                              Please entries planning month
                            </span>
                          </div>
                        </div>
                        <div className="d-flex form-group justify-content-end">
                          <Button
                            variant="info"
                            className="col-md-3 mr-2 loading"
                            onClick={this.handleTrigger}
                          >
                            Trigger
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Monitor Table Scheduler</h3>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={cloud_run}
                          totalRows={count}
                          perPage={sizePerPage}
                          curPage={page}
                          fetchData={this.getData}
                          loading={loading_cloud_run_job}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MonitorScheduler);
