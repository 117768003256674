import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
const customStyles = {
  container: (provided, { selectProps: { fontSize } }) => ({
    ...provided,
    padding: "0",
    border: "0",
    fontSize: fontSize ? fontSize : "0.65625rem",
  }),
  menu: (provided, { selectProps: { zIndex } }) => ({
    ...provided,
    zIndex: zIndex ? zIndex : null,
  }),
  option: (provided, { selectProps: { fontSize } }) => ({
    ...provided,
    fontSize: fontSize ? fontSize : "0.65625rem",
  }),
  control: (provided, { selectProps: { isValid, isFocused, fontSize } }) => ({
    ...provided,
    height: "calc(1.5em + 0.5rem + 4px)",
    minHeight: "calc(1.5em + 0.5rem + 4px)",
    fontSize: fontSize ? fontSize : "0.65625rem",
    borderColor: isFocused ? "#ddd" : isValid ? "#ddd" : "red",
    boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    transition:
      "background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    ":hover": {
      borderColor: isFocused ? "#ddd" : isValid ? "#ddd" : "green",
      boxShadow: isFocused
        ? "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)"
        : "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    },
  }),
  valueContainer: (provided, { selectProps: { zIndex, fontSize } }) => ({
    ...provided,
    zIndex: zIndex ? zIndex : null,
    fontSize: fontSize ? fontSize : "0.65625rem",

    marginTop: "0",
    marginLeft: "6px",
    padding: "0",
    border: "0",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    marginTop: "0",
    padding: "0",
    border: "0",
    width: "16px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    marginTop: "0",
    padding: "0",
    border: "0",
    width: "16px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: "4px",
    border: "0",
  }),
};
const FinalSelect = ({
  label,
  name,
  value,
  placeholder,
  selectOptions,
  isClearable,
  handleChange,
  isValid,
  error,
  isFormRow,
  isDisabled,
  zIndex,
  fontSize,
  labelStyles,
  selectStyle,
}) => {
  return (
    <div>
      {isFormRow ? (
        <div className="form-group row" style={selectStyle}>
          {label ? (
            <Col xs={12} md={3}>
              <label htmlFor={name} style={labelStyles}>
                {label}
              </label>
            </Col>
          ) : (
            ""
          )}
          <Col xs={12} md={9}>
            {isClearable ? (
              <Select
                styles={customStyles}
                name={name}
                placeholder={placeholder}
                onChange={(value) => handleChange({ name, value })}
                options={selectOptions}
                value={selectOptions.filter((e) => e.value === value)}
                isClearable={isClearable}
                isValid={isValid}
                isDisabled={isDisabled}
                zIndex={zIndex}
                fontSize={fontSize}
              />
            ) : (
              <Select
                styles={customStyles}
                name={name}
                placeholder={placeholder}
                onChange={(value) => handleChange({ name, value })}
                options={selectOptions}
                value={selectOptions.filter((e) => e.value === value)}
                isValid={isValid}
                isDisabled={isDisabled}
                zIndex={zIndex}
                fontSize={fontSize}
              />
            )}
          </Col>
        </div>
      ) : (
        <div className="form-group" style={selectStyle}>
          <label htmlFor={name} style={labelStyles}>
            {label}
          </label>
          {isClearable ? (
            <Select
              styles={customStyles}
              name={name}
              placeholder={placeholder}
              onChange={(value) => handleChange({ name, value })}
              options={selectOptions}
              value={selectOptions.filter((e) => e.value === value)}
              isValid={isValid}
              isDisabled={isDisabled}
              zIndex={zIndex}
              fontSize={fontSize}
              isClearable={isClearable}
            />
          ) : (
            <Select
              styles={customStyles}
              name={name}
              placeholder={placeholder}
              onChange={(value) => handleChange({ name, value })}
              options={selectOptions}
              value={selectOptions.filter((e) => e.value === value)}
              isValid={isValid}
              isDisabled={isDisabled}
              zIndex={zIndex}
              fontSize={fontSize}
            />
          )}
        </div>
      )}
      {!isValid && (
        <div
          style={{
            width: "100%",
            marginTop: ".25rem",
            fontSize: "80%",
            color: " #dc3545",
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default FinalSelect;
