import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const NotificationMrpTable = ({ data, type, handleModal, props }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const { history } = props;
  const handleProcess = (data) => {
    //insert to local storage
    localStorage.setItem("to_po_contract", JSON.stringify(data));
    history.push("/raw-materials/po-contract");
  };

  return data.length <= 0 ? (
    "Data Not Found"
  ) : (
    <div key="head">
      <Form>
        <Table
          bordered
          hover
          size="sm"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <thead className="still" style={headerStyle}>
            <tr>
              <th rowspan={2}>No</th>
              <th rowspan={2}>Material Name</th>
              {data.detail.map((i, x) => (
                <th colspan={2}>
                  {dateFormat(i.production_date, "mmmm yyyy")}
                </th>
              ))}
            </tr>
            <tr>
              {data.detail.map((i, x) => (
                <>
                  <th>Qty (Kg)</th>
                  <th>Batch</th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.parent_data.map((i, x) => (
              <tr>
                <td>{x + 1}</td>
                <td>{i.material_desc}</td>
                {i.detail_parent.map((ii, xx) => (
                  <>
                    <td>
                      {isNaN(parseFloat(ii.qty))
                        ? ""
                        : ii.qty.toFixed(DECIMAL_PLACES)}
                    </td>
                    <td>{ii.batch}</td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

export default NotificationMrpTable;
