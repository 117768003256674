import React, { useState } from "react";
import { Table, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";

const BOMPowderTableEXP = ({
  data,
  type_data,
  uom,
  readOnly,
  loadOptions,
  defaultOptions,
  handleSelect,
  handleInput,
  onChange,
  list_plant,
  isCreate,
  errors,
}) => {
  const [key, setKey] = useState(0);

  const RenderTitle = (item, idx) => {
    return (
      <div>
        Version {item.version_no} {item.default && "(default)"}
        {!readOnly && (
          <Button
            variant="light"
            className="ml-2"
            onClick={() =>
              !item.default
                ? Swal.fire({
                    title: "Are you sure?",
                    text: "This version will be deleted",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      DeleteRecipe(idx);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire("Cancelled", "Your data is safe :)", "error");
                    }
                  })
                : Swal.fire(
                    "Not Allowed",
                    "Can't remove default version. Set default version to other version",
                    "info"
                  )
            }
            size="sm"
          >
            <i className="fa fa-times" />
          </Button>
        )}
      </div>
    );
  };

  const SetDefault = (idx) => {
    data[idx].default = true;

    data.forEach((item, idxData) => {
      if (idx != idxData) {
        item.default = false;
      }
    });

    onChange(data);
  };

  const RenderTabs = () => {
    return data.map((item, idx) => (
      <Tab
        eventKey={idx}
        title={RenderTitle(item, idx)}
        className="mx-3 mt-2 pt-3"
      >
        {!item.default && !readOnly && (
          <Button
            variant="outline-info"
            onClick={() => SetDefault(idx)}
            className="mt-2"
            size="sm"
          >
            Set as Default
          </Button>
        )}
        <Row className="mt-2">
          <Col
            xs={12}
            md={1}
            className="d-flex align-items-center justify-content-center"
          />
          <Col
            xs={12}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <h7>Plant 1</h7>
          </Col>
          <Col
            xs={12}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <h7>Plant 2</h7>
          </Col>
          <Col
            xs={12}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <h7>Mixer</h7>
          </Col>
          <Col xs={12} md={2} />
        </Row>

        <Row className="mt-2">
          <Col
            xs={12}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            {" "}
            <label htmlFor="qty_per_batch">Qty per Batch</label>
          </Col>
          <Col xs={12} md={1}>
            <div className="form-group input-group">
              <input
                type="number"
                step="any"
                className="form-control form-control-sm"
                name="qty_per_batch_p1"
                placeholder=""
                required="required"
                value={item.qty_per_batch_p1 || ""}
                onChange={(val) =>
                  handleInput(
                    val.target.value,
                    idx,
                    null,
                    "qty_per_batch_p1",
                    data,
                    type_data
                  )
                }
                min="0"
                readOnly={readOnly}
              />
            </div>
          </Col>
          <Col xs={12} md={1}>
            <div className="form-group input-group">
              <input
                type="number"
                step="any"
                className="form-control form-control-sm"
                name="qty_per_batch_p2"
                placeholder=""
                required="required"
                value={item.qty_per_batch_p2 || ""}
                onChange={(val) =>
                  handleInput(
                    val.target.value,
                    idx,
                    null,
                    "qty_per_batch_p2",
                    data,
                    type_data
                  )
                }
                min="0"
                readOnly={readOnly}
              />
            </div>
          </Col>
          <Col xs={12} md={1}>
            <div className="form-group input-group">
              <input
                type="number"
                step="any"
                className="form-control form-control-sm"
                name="qty_per_batch_mixer"
                placeholder=""
                required="required"
                value={item.qty_per_batch_mixer || ""}
                onChange={(val) =>
                  handleInput(
                    val.target.value,
                    idx,
                    null,
                    "qty_per_batch_mixer",
                    data,
                    type_data
                  )
                }
                min="0"
                readOnly={readOnly}
              />
            </div>
          </Col>
          <Col xs={12} md={1}>
            <span
              className="input-group-text form-control form-control-sm"
              id="basic-addon2"
              placeholder="UOM"
              readOnly={true}
            >
              kg/batch
            </span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={10} md={isCreate ? 10 : 12}>
            <div className="d-flex align-items-center mt-2">
              <h6>Ingredients</h6>
              {!readOnly && (
                <Button
                  variant="outline-success"
                  className="mb-2"
                  onClick={() => AddIngredients(idx)}
                  size="sm"
                >
                  <i className="fa fa-plus" />
                </Button>
              )}{" "}
            </div>
            <Table
              bordered
              hover
              size="sm"
              style={{
                textAlign: "center",
              }}
              width="100%"
            >
              <thead key={`header`}>
                <tr key={`h0`} className="bg-info">
                  <th key={`h1`}>No. </th>
                  <th key={`h77`}>EXP</th>
                  <th key={`h2`}>Material Code</th>
                  <th key={`h3`}>Material Name</th>
                  <th key={`h4`} width="10%">
                    Qty
                  </th>
                  <th key={`h5`} width="5%">
                    UOM
                  </th>
                  <th key={`h6`} width="12%">
                    Plant
                  </th>
                  {!readOnly && <th key={`h7`}>Action</th>}
                </tr>
              </thead>
              <tbody key={`body`}>
                {item.ingredients.map((ing, idxIng) => (
                  <tr key={`b${idxIng}0`}>
                    <td className="col-sm-1" key={`b${idxIng}1`}>
                      {idxIng + 1}{" "}
                    </td>
                    <td>
                      <Form>
                        <Form.Switch
                          type="switch"
                          id={`${idxIng}-${idx}-custom-switch`}
                          onChange={(val) =>
                            handleInput(
                              val.target.checked,
                              idx,
                              idxIng,
                              "switch",
                              data,
                              type_data
                            )
                          }
                          checked={ing.is_exp ?? false}
                          disabled={readOnly}
                        />
                      </Form>
                    </td>
                    <td className="col-sm-3" key={`b${idxIng}2`}>
                      {ing.is_exp ? (
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="No Material"
                          value={ing.material_exp || ""}
                          onChange={(val) =>
                            handleInput(
                              val.target.value,
                              idx,
                              idxIng,
                              "material_exp",
                              data,
                              type_data
                            )
                          }
                          readOnly={readOnly}
                        />
                      ) : (
                        <AsyncSelect
                          onChange={(val) =>
                            handleSelect(
                              val,
                              idx,
                              idxIng,
                              "material",
                              data,
                              type_data
                            )
                          }
                          cacheOptions
                          defaultOptions={defaultOptions}
                          loadOptions={(input) => loadOptions(input)}
                          value={
                            defaultOptions !== true
                              ? defaultOptions.filter(
                                  (e) => e.value == ing.material_code
                                )
                              : ing.material_code && ing.material_name
                              ? {
                                  value: ing.material_code,
                                  label: ing.material_name,
                                }
                              : null
                          }
                          //code below to override select items from parent
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          isDisabled={readOnly || ing.material_code === null}
                          additional={{
                            page: 1,
                          }}
                          placeholder="Material Code"
                        />
                      )}
                    </td>
                    <td className="col-sm-3" key={`b${idxIng}3`}>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder="Material Desc"
                        value={ing.material_name || ""}
                        onChange={(val) =>
                          handleInput(
                            val.target.value,
                            idx,
                            idxIng,
                            "material",
                            data,
                            type_data
                          )
                        }
                        readOnly={readOnly || ing.material_name === null}
                      />
                    </td>
                    <td className="col-sm-1" key={`b${idxIng}4`}>
                      <input
                        type="number"
                        className="form-control form-control-md"
                        placeholder="Qty"
                        value={ing.qty || ""}
                        onChange={(val) =>
                          handleInput(
                            val.target.value,
                            idx,
                            idxIng,
                            "qty",
                            data,
                            type_data
                          )
                        }
                        readOnly={readOnly}
                        step="0.0001"
                        min="0"
                      />
                    </td>
                    <td className="col-sm-1" key={`b${idxIng}5`}>
                      <select
                        className="form-control form-control-md"
                        name="uom"
                        onChange={(val) =>
                          handleSelect(
                            val.target.value,
                            idx,
                            idxIng,
                            "uom",
                            data,
                            type_data
                          )
                        }
                        value={ing.uom || ""}
                        readOnly={
                          readOnly ||
                          (ing.material_code != "" && ing.material_code != null)
                        }
                      >
                        <option value="">Choose</option>
                        {uom &&
                          uom.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.value}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td className="col-sm-1" key={`b${idxIng}6`}>
                      <select
                        className="form-control form-control-md"
                        name="plant"
                        onChange={(val) =>
                          handleSelect(
                            val.target.value,
                            idx,
                            idxIng,
                            "plant",
                            data,
                            type_data
                          )
                        }
                        value={ing.plant || ""}
                      >
                        <option value="">Choose</option>
                        {list_plant &&
                          list_plant.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>
                    </td>

                    {!readOnly && (
                      <td className="col-sm-1" key={`b${idxIng}7`}>
                        {
                          <Button
                            variant="outline-danger"
                            onClick={() => DeleteIngredients(idx, idxIng)}
                            size="sm"
                          >
                            <i className="fa fa-minus" />
                          </Button>
                        }
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col xs={2} md={2}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="d-flex align-items-center mt-3">
                Remark
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={`Remark of version ${item.version_no}`}
                onChange={(e) =>
                  onChangeRemark(item.version_no, e.target.value)
                }
                value={item.remark || ""}
                readOnly={readOnly}
              />
            </Form.Group>
          </Col>
        </Row>
      </Tab>
    ));
  };
  const onChangeRemark = (version_no, value) => {
    if (data.length >= 1) {
      const findRecipe = data.find((it) => it.version_no == version_no);
      const findRecipeIndex = data.findIndex(
        (it) => it.version_no == version_no
      );
      if (typeof findRecipe != "undefined") {
        const modifRecipe = {
          ...findRecipe,
          remark: value,
        };
        const newRecipe = [
          ...data.slice(0, findRecipeIndex),
          modifRecipe,
          ...data.slice(findRecipeIndex + 1),
        ];
        onChange(newRecipe);
      }
    }
  };
  const DeleteIngredients = (idx, idxIng) => {
    if (data[idx].ingredients.length != 1) {
      data[idx].ingredients.splice(idxIng, 1);
      onChange(data);
    }
  };

  const DeleteRecipe = (idx) => {
    if (data.length != 1) {
      data.splice(idx, 1);
      setKey(idx > 0 ? idx - 1 : 0);
      onChange(data);
    }
  };

  const AddRecipe = () => {
    if (
      data[key].ingredients.some(
        (item) =>
          item.material_code == "" ||
          item.material_name == "" ||
          item.qty == "" ||
          item.uom == "" ||
          item.plant == ""
      )
    ) {
      Swal.fire(
        "Not allowed",
        "Please fill the ingredients before adding new recipe",
        "info"
      );
    } else {
      data.unshift({
        default: false,
        version_no: data[0].version_no + 1,
        ingredients: [
          {
            material_code: "",
            material_name: "",
            qty: "",
            uom: "",
            plant: null,
          },
        ],
      });
      setKey(0);
      onChange(data);
    }
  };

  const AddIngredients = (idx) => {
    data[idx].ingredients.push({
      material_code: "",
      material_name: "",
      qty: "",
      uom: "",
      plant: null,
    });
    onChange(data);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h6>Recipe</h6>
        {!readOnly && (
          <Button
            variant="outline-success"
            className="ml-2"
            onClick={() => AddRecipe()}
            size="sm"
          >
            <i className="fa fa-plus" />
          </Button>
        )}
      </div>
      <div className="mt-3 mb-3 border rounded">
        <Tabs activeKey={key} onSelect={setKey}>
          {RenderTabs()}
        </Tabs>
      </div>
    </>
  );
};

export default BOMPowderTableEXP;
