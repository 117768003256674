import BeginningFgBalance from '../services/beginning_fg_balance.service'

export const addBeginningFgBalance = (payload) => {
  return BeginningFgBalance.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const addBeginningFgBalanceBulk = (payload) => {
  return BeginningFgBalance.createBulk(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const editBeginningFgBalance = (payload) => {
  return BeginningFgBalance.edit(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();


      return Promise.reject(message);
    }
  );
};
export const deleteBeginningFgBalance = (payload) => {
  return BeginningFgBalance.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readBeginningFgBalance = (date_first, date_second) => {
  return BeginningFgBalance.readBeginningFgBalance(date_first, date_second).then(
    (response) => {

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};

export const readQsBeginningFgBalance = (date_first, date_second) => {
  return BeginningFgBalance.readQsBeginningFgBalance(date_first, date_second).then(
    (response) => {

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};

export const overwriteBeginningFgBalance = (payload) => {
  return BeginningFgBalance.createOverwrite(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
}
