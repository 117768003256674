import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const NotificationMrpTable = ({ data, type, search, props }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const { history } = props;
  const handleProcess = (data) => {
    //insert to local storage
    localStorage.setItem("to_po_contract", JSON.stringify(data));
    history.push("/raw-materials/po-contract");
  };

  return data.length <= 0 ? (
    "Data Not Found"
  ) : (
    <div key="head">
      <Form>
        <Table
          bordered
          hover
          size="sm"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <thead className="still">
            <tr>
              <th>No</th>
              <th>Material Code</th>
              <th>Material Name</th>
              <th>Qty</th>
              <th>UoM</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{item.material_code}</td>
                <td>
                  {item.material_desc.replace(`(${item.material_code})`, "")}
                </td>
                <td>
                  {isNaN(parseFloat(item.need_value))
                    ? ""
                    : item.need_value.toFixed(DECIMAL_PLACES)}
                </td>
                <td>{item.uom}</td>
                <td>
                  <Button
                    className="font-weight-bold"
                    variant="warning"
                    onClick={() => handleProcess(data[idx])}
                  >
                    <i class="fa fa-tasks mr-2" aria-hidden="true"></i>
                    Proccess
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

export default NotificationMrpTable;
