import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class PriceListService {

    readPriceList(query_string) {
        return axios
            .get(API_URL_DEFAULT + "price_list/?" + query_string, { headers: authHeader() });
    }
}

export default new PriceListService();