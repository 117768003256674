import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "dashboard-timeline";

class DashboardTimelineService {
  getData(payload) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/?" + payload, {
      headers: authHeader(),
    });
  }
}
export default new DashboardTimelineService();
