import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class TollOutMaterialService{

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "toll_out_material", payload,{ headers: authHeader()});
  }

  overwrite(payload) {
    return axios
      .post(API_URL_DEFAULT + "toll_out_material/?type=overwrite", payload, { headers: authHeader()});
  }

  readTollOutMaterial(query_string) {
    return axios
      .get(API_URL_DEFAULT + "toll_out_material/?"+query_string, { headers: authHeader()});
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "toll_out_material", payload, { headers: authHeader()});
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "toll_out_material", {data:payload, headers: authHeader()});
  }
}

export default new TollOutMaterialService();
