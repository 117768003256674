import React, { Component, version } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { startOfMonth, addMonths, differenceInMonths } from "date-fns";
import {
  readMaterialByType,
  readMaterialUomByCode,
} from "../modules/MaterialModule";
import {
  readMrpMixerInput,
  addMrpMixerInput,
  readActiveMrpLabel,
  getActiveMrpLabel,
} from "../modules/MrpMixerInputModule";
import thousandformat from "../helpers/thousanddelimiter";
import { withRouter } from "react-router-dom";
import MrpTable from "../components/MrpTable";
import { sendNotification } from "../modules/NotificationModule";

import { readBOMFGSpec } from "../modules/BOMFGModule";
import { readMaterialClassification } from "../modules/MaterialClassificationModule";
import { readProdFacility } from "../modules/ProdFacilityModule";
import { readResource } from "../modules/ResourceModule";
import BOMFGTable from "../components/BOMFGTable";
import SelectionVersion from "../components/SelectionVersion";
import { ModalFooter } from "react-bootstrap";

class MrpMixer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select_items_prod_facility: [],
      material_bomfg: null,
      classification_bomfg: null,
      qty_bomfg: null,
      uom_bomfg: null,
      bom_contents: null,
      select_items_material_all: [],
      select_items_uom: [],
      readOnly: true,
      select_items_plant: [],
      remark_bmfg: null,
      showModalBOMFG: false,

      showModalSelectVersion: false,
      plant_version: null,
      select_version: [],
      recipe_version: null,
      material_select_version: null,
      indexMonth: null,
      material_code_version: null,

      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      disableButtonNotification: false,
      seconds: 60,
      loading: false,
      readOnly: false,
      isValidMaterial: true,
      isValidVendor: true,
      isValidMaterialCreate: true,
      isValidVendorCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_vendor: [],
      select_items_uom: [],
      data: [],
      list_datatable: [],
      temp_list_datatable: [],
      list_summary: {
        data: [],
      },
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      vendor: null,
      vendor_code: null,
      currency: null,
      price: null,
      unit: null,
      date: null,
      isValidMaterial_create: false,
      material_create: null,
      material_create_code: null,
      vendor_create: null,
      vendor_create_code: null,
      currency_create: null,
      price_create: null,
      unit_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "spot_date",
      sortOrder: "desc",
      isFound: false,
      isLoading: true,
      isLabelUpdated: false,
      isShowing: false,
      defaultSorted: [
        {
          dataField: "spot_date",
          order: "desc",
        },
      ],
      cellEditProps: {},

      cols_view: [
        {
          name: "no_material_rm",
          key: 0,
        },
        {
          name: "material_description_rm",
          key: 1,
        },
        {
          name: "qty",
          key: 2,
        },
        {
          name: "uom",
          key: 3,
        },
        {
          name: "plant",
          key: 4,
        },
      ],
    };
    this.defaultState = this.state;
  }

  columns_default = [
    {
      dataField: "",
      text: "",
    },
    {
      dataField: "balance",
      text: "Beginning Balance",
    },
  ];

  columns_summary_default = [
    {
      dataField: "",
      text: "",
    },
  ];

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    readMaterialByType("ZSFG,ZFGD").then((response) => {
      this.setState({ select_items_material: response });
    });

    const date_first = startOfMonth(new Date());
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const query_string = `planning_month=${date_first_formatted}`;

    readMrpMixerInput(query_string)
      .then(async (response) => {
        let isShow = false;
        let result = Array.isArray(response) ? response[0] : response;
        await Promise.all(
          result.details.map((item) => {
            if (item.isVisible) {
              isShow = true;
            } else isShow = false;
          })
        );
        this.setState({
          isLoading: false,
          isFound: true,
          isShowing: isShow,
          data: result,
          list_datatable: result.details,
          isLabelUpdated: true,
          result_created_at: result.created_at,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          isFound: false,
        });
      });

    this.setState({
      date: startOfMonth(new Date()),
      date_first: startOfMonth(new Date()),
      date_second: addMonths(new Date(), 6),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    readProdFacility().then((response) => {
      this.setState({ select_items_prod_facility: response });
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //render table based on first date and second date
  renderDatatable(date_first, date_second, default_columns) {
    let columns = [...default_columns];
    var diffMonth = differenceInMonths(date_second, date_first);
    for (let i = 0; i <= diffMonth; i++) {
      columns = [
        ...columns,
        {
          dataField: "plant",
          text: dateFormat(addMonths(date_first, i), "mmm-yy"),
          classes: "text-center",
        },
      ];
    }

    this.setState({
      date_first: date_first,
      date_second: date_second,
      columns: columns,
    });
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
      date_first: date,
      date_second: addMonths(date, 6),
    });
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
    }
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.spot_date), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let datenow = new Date();
    let is_noneditable = true;
    let date_db = new Date(row.spot_date);
    let currencyreadOnly = true;
    if (this.diff_date_on_day(date_db, datenow) <= 7) {
      is_noneditable = false;
    }
    const { isEdit } = this.state;
    //TODO REVERT BACK
    return isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.currency,
              row.material_name,
              row.material_code,
              row.vendor_name,
              row.vendor_code,
              row.spot_price,
              row.unit,
              date_db,
              is_noneditable,
              currencyreadOnly
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{thousandformat(cell, ".", ".", ",")}</div>;
  };

  toDetail = (
    id,
    currency,
    material,
    material_code,
    vendor,
    vendor_code,
    price,
    unit,
    date,
    currencyreadOnly
  ) => {
    this.setState(
      {
        id: id,
        currency: currency,
        material: material,
        material_code: material_code,
        vendor: vendor,
        vendor_code,
        vendor_code,
        date: date,
        price: price,
        unit: unit,
        currencyreadOnly: currencyreadOnly,
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      material: null,
      vendor: null,
      date: null,
      price: null,
      unit: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = (currency, material, vendor, price, unit, date) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendor: false,
      });
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  validateFormCreate = (currency, material, vendor, price, unit, date) => {
    let errors = this.state.errors_create;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendorCreate: false,
      });
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = (type) => {
    const date_first = startOfMonth(new Date());
    const date_first_formatted = dateFormat(this.state.date, "yyyy-mm-dd");
    const query_string = `planning_month=${date_first_formatted}`;

    this.form.reset();

    readMrpMixerInput(query_string)
      .then((response) => {
        let result = Array.isArray(response) ? response[0] : response;
        readActiveMrpLabel(result.hash_mrp_label)
          .then((resp) => {
            this.setState({
              data: result,
              list_datatable: result.details,
              isLabelUpdated: true,
            });
          })
          .catch((e) => {
            getActiveMrpLabel(
              `true&planning_month=${date_first_formatted}`
            ).then((res) => {
              let isSimilar = false;
              let tempCode;

              //check if theres some duplicate, reload until the data is not duplicated
              res.details.forEach((item, idx) => {
                if (item.material_code === tempCode) {
                  isSimilar = true;
                  this.setState(
                    {
                      isLoading: true,
                      isFound: false,
                      isShowing: false,
                    },
                    () => this.reloadDataBackend()
                  );
                } else {
                  tempCode = item.material_code;
                }
              });
              if (isSimilar === false) {
                let isShow = false;
                res.details.map((item) => {
                  if (item.isVisible) {
                    isShow = true;
                  } else isShow = false;
                });

                //updating each balance and forecast
                let tempData = [...this.state.list_datatable];

                for (let i = 0; i < tempData.length; i++) {
                  tempData[i] = {
                    ...tempData[i],
                    balance: res.details[i].balance,
                  };
                  for (let y = 0; y < tempData[i].data.length; y++) {
                    tempData[i].data[y] = {
                      ...tempData[i].data[y],
                      forecast: res.details[i].data[y].forecast,
                    };
                  }
                }

                this.setState(
                  {
                    data: res,
                    list_datatable: tempData,
                    isLabelUpdated: true,
                    isLoading: false,
                    isFound: true,
                    isShowing: isShow,
                  },
                  () => {
                    if (type === "save") {
                      const {
                        requester,
                        date_first,
                        data,
                        list_datatable,
                        temp_list_datatable,
                      } = this.state;
                      this.setState({
                        submitted_create: true,
                        temp_list_datatable: [...list_datatable],
                      });

                      //get department
                      const user = JSON.parse(localStorage.getItem("user"));
                      let department =
                        user.details.hris_org_tree.current_person
                          .nama_department;

                      let date_formatted = dateFormat(
                        date_first,
                        "dd mmmm yyyy"
                      );
                      let collection = {
                        requester: requester,
                        planning_month_object: dateFormat(
                          date_first,
                          "yyyy-mm-dd"
                        ),
                        department: department,
                        type: "mixer",
                        hash_mrp_label: data.hash_mrp_label,
                        details: list_datatable,
                      };

                      addMrpMixerInput(collection)
                        .then((response) => {
                          Swal.fire(
                            "Warning",
                            "There is a changes in the Forecast and/or Beginning Balance. We will reloading Forecast and Beginning Balance data for you. You can continue saving after the data is reloaded."
                          );
                        })
                        .catch((err) => {
                          toast.error(err, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            toastId: "customId-add-daily-spot-price-failed",
                          });
                          this.setState({
                            loading: false,
                          });
                        });
                    }
                    this.calcSummary(this.state.list_datatable);
                  }
                );
              }
            });
          });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          isFound: false,
        });
      });
  };

  calcSummary = (newTable) => {
    const { date_first, date_second } = this.state;

    //make the object for summary
    var diffMonth = differenceInMonths(date_second, date_first);
    let arrResult = [];

    //create json template for summary based on picked month
    for (let i = 0; i < diffMonth; i++) {
      let month = dateFormat(addMonths(date_first, i), "yyyy-mm-dd");
      arrResult = [
        ...arrResult,
        {
          month: month,
          total_batch: "0",
          total_prod_hours: "0",
          total_qty: "0",
        },
      ];
    }
    newTable.map((itemDataTable) => {
      if (itemDataTable.isVisible) {
        itemDataTable.data.map((itemData) => {
          let idx = arrResult.findIndex(
            (item) => item.month === itemData.month
          );

          //calculating, result => arr for saving the calculation, data => arr that are calculated
          let qtyResult = arrResult[idx]?.total_qty ?? "0";
          let batchResult = arrResult[idx]?.total_batch ?? "0";
          let prodHrsResult = arrResult[idx]?.total_prod_hours ?? "0";

          let qtyData = itemData?.total_qty ?? "0";
          let batchData = itemData?.total_batch ?? "0";
          let prodHrsData = itemData?.total_prod_hours ?? "0";

          let qty = 0;
          let batch = 0;
          let prodHrs = 0;

          if (qtyResult !== null && qtyData !== null) {
            qty = !isFinite(parseInt(qtyResult) + parseInt(qtyData))
              ? 0
              : parseInt(qtyResult) + parseInt(qtyData);
          }
          if (batchResult !== null && batchData !== null) {
            batch = !isFinite(parseInt(batchResult) + parseInt(batchData))
              ? 0
              : parseInt(batchResult) + parseInt(batchData);
          }
          if (prodHrsResult !== null && prodHrsData !== null) {
            prodHrs = !isFinite(
              parseFloat(prodHrsResult) + parseFloat(prodHrsData)
            )
              ? 0
              : parseFloat(prodHrsResult) + parseFloat(prodHrsData);
          }
          arrResult[idx] = {
            ...arrResult[idx],
            total_batch: batch.toString(),
            total_prod_hours: prodHrs.toFixed(1),
            total_qty: qty.toString(),
          };
        });
      }
    });
    this.setState({
      list_summary: { data: arrResult },
    });
  };
  //handletable
  handleTableChange = (listData, idx) => {
    const { list_datatable } = this.state;
    let newDataTable = [...list_datatable];

    newDataTable[idx] = { ...listData };

    //REMEMBER, this is how setState in loops
    this.setState(
      (prevState) => {
        prevState.list_datatable[idx] = listData;
        return {
          ...prevState,
        };
      },
      () => {
        this.calcSummary(list_datatable);
      }
    );
  };

  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          date: this.state.date,
          page: "mrp_mixer",
        };
        this.setState({ loadingSpinner: true });
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
              loadingSpinner: false,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loadingSpinner: false,
              loading: false,
            });
          });
      }
    });
  };

  //submit form
  handleSubmit = (idx) => {
    const { history } = this.props;
    const { requester, date_first, data, list_datatable } = this.state; // remove is_updated all nomat
    const cloneLD = [...list_datatable].map((ld) => {
      const newOb = { ...ld };
      newOb.isVisible = true;
      delete newOb.is_updated;
      return newOb;
    });

    // add is_updated on current nomat
    const currentLD = cloneLD[idx];
    const newC = { ...currentLD, is_updated: false };
    const newLD = [...cloneLD.slice(0, idx), newC, ...cloneLD.slice(idx + 1)];
    this.setState({
      submitted_create: true,
      temp_list_datatable: newLD,
    });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(date_first, "dd mmmm yyyy");
    let collection = {
      requester: requester,
      planning_month_object: dateFormat(date_first, "yyyy-mm-dd"),
      department: department,
      type: "mixer",
      hash_mrp_label: data.hash_mrp_label,
      details: newLD,
    };
    readActiveMrpLabel(data.hash_mrp_label)
      .then((response) => {
        if (requester && date_first) {
          Swal.fire({
            title: "Are you sure?",
            text: "Please check your entries !",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.value) {
              addMrpMixerInput(collection)
                .then((response) => {
                  toast.success("Data has been saved successfully", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-add-daily-spot-price-success",
                  });
                  Swal.fire({
                    title: "Information",
                    icon: "success",
                    text: "Data has been saved successfully",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then((result) => {
                    this.reloadDataBackend("");
                    history.push("/production/mrp-mixer");
                  });
                })
                .catch((err) => {
                  toast.error(err, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-add-daily-spot-price-failed",
                  });
                  if (
                    err ===
                    "data is already exist, Are you sure to overwrite data ?"
                  ) {
                    Swal.fire({
                      title: "Are you sure ?",
                      text: `to overwrite data for ${date_formatted} !`,
                      icon: "info",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        addMrpMixerInput(collection)
                          .then((response) => {
                            toast.success(
                              "Data has been updated successfully",
                              {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                toastId:
                                  "customId-update-daily-spot-price-success",
                              }
                            );
                            Swal.fire({
                              title: "Information",
                              icon: "success",
                              text: "Data has been updated successfully",
                              showConfirmButton: false,
                              timer: 2000,
                            }).then((result) => {
                              this.setState({
                                material_create: null,
                                material_create_code: null,
                                vendor_create: null,
                                vendor_create_code: null,
                                price_create: null,
                                unit_create: null,
                                currency_create: null,
                                date_create: new Date(),
                              });
                              this.reloadDataBackend();
                              history.push("/production/mrp-mixer");
                            });
                          })
                          .catch((err) => {
                            toast.error(err, {
                              position: "top-right",
                              autoClose: 1500,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              toastId:
                                "customId-update-daily-spot-price-failed",
                            });
                            this.setState({
                              loading: false,
                            });
                          });
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire("Cancelled", "Your data is safe :)", "error");
                      }
                    });
                  }
                  this.setState({
                    loading: false,
                  });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.reloadDataBackend("save");
      });
  };

  handleSubmitAll = (idx) => {
    const { history } = this.props;
    const { requester, date_first, data, list_datatable } = this.state; // remove is_updated all nomat
    const cloneLD = [...list_datatable].map((ld) => {
      const newOb = { ...ld };
      newOb.isVisible = true;
      newOb.is_updated = true;
      return newOb;
    });

    this.setState({
      submitted_create: true,
      temp_list_datatable: cloneLD,
    });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(date_first, "dd mmmm yyyy");
    let collection = {
      requester: requester,
      planning_month_object: dateFormat(date_first, "yyyy-mm-dd"),
      department: department,
      type: "mixer",
      hash_mrp_label: data.hash_mrp_label,
      details: cloneLD,
    };

    readActiveMrpLabel(data.hash_mrp_label)
      .then((response) => {
        if (requester && date_first) {
          Swal.fire({
            title: "Are you sure?",
            text: "Please check your entries !",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.value) {
              addMrpMixerInput(collection)
                .then((response) => {
                  toast.success("Data has been saved successfully", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-add-daily-spot-price-success",
                  });
                  Swal.fire({
                    title: "Information",
                    icon: "success",
                    text: "Data has been saved successfully",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then((result) => {
                    this.reloadDataBackend("");
                    history.push("/production/mrp-mixer");
                  });
                })
                .catch((err) => {
                  toast.error(err, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-add-daily-spot-price-failed",
                  });
                  if (
                    err ===
                    "data is already exist, Are you sure to overwrite data ?"
                  ) {
                    Swal.fire({
                      title: "Are you sure ?",
                      text: `to overwrite data for ${date_formatted} !`,
                      icon: "info",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.value) {
                        addMrpMixerInput(collection)
                          .then((response) => {
                            toast.success(
                              "Data has been updated successfully",
                              {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                toastId:
                                  "customId-update-daily-spot-price-success",
                              }
                            );
                            Swal.fire({
                              title: "Information",
                              icon: "success",
                              text: "Data has been updated successfully",
                              showConfirmButton: false,
                              timer: 2000,
                            }).then((result) => {
                              this.setState({
                                material_create: null,
                                material_create_code: null,
                                vendor_create: null,
                                vendor_create_code: null,
                                price_create: null,
                                unit_create: null,
                                currency_create: null,
                                date_create: new Date(),
                              });
                              this.reloadDataBackend();
                              history.push("/production/mrp-mixer");
                            });
                          })
                          .catch((err) => {
                            toast.error(err, {
                              position: "top-right",
                              autoClose: 1500,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              toastId:
                                "customId-update-daily-spot-price-failed",
                            });
                            this.setState({
                              loading: false,
                            });
                          });
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire("Cancelled", "Your data is safe :)", "error");
                      }
                    });
                  }
                  this.setState({
                    loading: false,
                  });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.reloadDataBackend("save");
      });
  };

  //handle search
  handleSearch = () => {
    this.setState({
      isLoading: true,
    });
    const { date, material_code } = this.state;
    const date_first = startOfMonth(date);
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const query_string = `planning_month=${date_first_formatted}`;

    readMrpMixerInput(query_string)
      .then((response) => {
        let result = Array.isArray(response) ? response[0] : response;
        let temp_datatable = [...result.details];
        let isShow = false;
        temp_datatable.map((item, idx) => {
          if (material_code) {
            if (item.material_code !== material_code) {
              temp_datatable[idx] = {
                ...temp_datatable[idx],
                isVisible: false,
              };
            } else {
              temp_datatable[idx] = {
                ...temp_datatable[idx],
                isVisible: true,
              };
            }
          } else {
            temp_datatable[idx] = {
              ...temp_datatable[idx],
              isVisible: true,
            };
          }
        });
        isShow = temp_datatable.some((item) => {
          return item.isVisible == true;
        });

        this.setState({
          data: result,
          isLoading: false,
          isFound: true,
          isShowing: isShow,
          list_datatable: temp_datatable,

          result_created_at: result.created_at,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          isFound: false,
        });
      });
  };

  onModalSpecHide = () => {
    this.setState({
      material_code_spec: null,
      material_desc_spec: null,
      worksheet_detail: [],
      showModal: false,
    });
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleSpinner = () => {
    this.setState({
      loadingSpinner: !this.state.loadingSpinner,
    });
  };
  handleModal = (material_code_spec) => {
    //show spinner when get data from backend
    this.toggleSpinner();
    const query_string = `no_material=${material_code_spec}&type=mixer`;
    readBOMFGSpec(query_string)
      .then((res) => {
        const {
          no_material,
          material_description,
          qty,
          uom,
          material_classification,
          bom_contents,
        } = res.foundData[0];
        this.toDetailBOMFG(
          no_material,
          material_description,
          qty,
          uom,
          material_classification,
          bom_contents,
          true
        );
        this.toggleSpinner();
      })
      .catch((err) => {
        this.toggleSpinner();
      });
    //hide spinner when finish
  };

  toggleBOMFG = () => {
    this.setState({
      showModalBOMFG: !this.state.showModalBOMFG,
    });
  };
  toDetailBOMFG = (
    no_material,
    material_description,
    qty,
    uom,
    material_classification,
    bom_contents,
    readOnly
  ) => {
    this.setState(
      {
        material_code_bomfg: no_material,
        material_bomfg: material_description,
        classification_bomfg: material_classification,
        bom_contents: bom_contents,
        readOnly: readOnly,
        qty_bomfg: qty,
        uom_bomfg: uom,
        errors: {},
      },
      () => {
        this.toggleBOMFG();
      }
    );
  };

  onModalBOMFG = () => {
    this.setState({
      showModalBOMFG: false,

      material_code_bomfg: null,
      material_bomfg: null,
      classification_bomfg: null,
      bom_contents: { facility: [], recipe: [] },
      readOnly: true,
      qty_bomfg: null,
      uom_bomfg: null,
    });
  };

  getMaterial = (classification) => {
    const queryString = `constraint=mapping&constraint_value=${classification}`;
    readMaterialClassification(queryString).then((response) => {
      this.setState({ select_items_material: response });
    });
  };

  handleSelectBomFG = (val, idx, idxIng, type, data, type_data) => {
    if (type == "material") {
      if (val.label !== "" && val.value !== this.state.material_code) {
        readMaterialUomByCode(val.value).then((response) => {
          data.recipe[idx].ingredients[idxIng].no_material_rm = val.value;
          data.recipe[idx].ingredients[idxIng].material_description_rm =
            val.label;
          data.recipe[idx].ingredients[idxIng].uom = response[0].value;
          if (response[0].material_type == "ZFGD") {
            data.recipe[idx].ingredients[idxIng].plant = "";
          } else {
            data.recipe[idx].ingredients[idxIng].plant = null;
          }
          this.setState({
            [type_data]: { ...data },
          });
        });
      } else {
        val.value === this.state.material_code &&
          Swal.fire({
            title: "Not allowed",
            icon: "info",
            text: `${val.label} is already selected as Parent Material! Please select another Material`,
          });

        data.recipe[idx].ingredients[idxIng].no_material_rm = "";
        data.recipe[idx].ingredients[idxIng].material_description_rm = "";
        data.recipe[idx].ingredients[idxIng].uom = "";
        this.setState({
          [type_data]: { ...data },
        });
      }
    }

    if (type === "uom") {
      data.recipe[idx].ingredients[idxIng].uom = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type === "plant") {
      data.recipe[idx].ingredients[idxIng].plant = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "prod_facility" && val > 0) {
      let facility = this.state.select_items_prod_facility[val - 1];
      //check picked facility has to be uniqued
      if (data.facility.length > 1) {
        if (
          data.facility.some((x) => x.facility_code == facility.facility_code)
        ) {
          Swal.fire({
            title: "Not allowed",
            icon: "info",
            text: `${facility.facility_code} is already selected! Please select another production Facility`,
          });
        } else if (
          data.facility[data.facility.length - 1].facility_type !=
          facility.facility_type
        ) {
          Swal.fire({
            title: "Not Allowed",
            icon: "info",
            text: `You can only select Facility ${
              data.facility[data.facility.length - 1].facility_type
            }`,
          });
        } else {
          this.onChangeProdFacility(val, idx, data, type_data);
        }
      } else {
        this.onChangeProdFacility(val, idx, data, type_data);
      }
    }
  };

  onChangeProdFacility = (val, idx, data, type_data) => {
    let index = val;
    const { select_items_prod_facility, machine_capacity, qty } = this.state;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      let query_string = `facility_code=${prod_facility.facility_code}`;
      readResource(query_string).then((response) => {
        let res_gas = response.find((o) => o.resource_desc == "GAS");
        let res_electricity = response.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = response.find((o) => o.resource_desc == "LABOR");
        let res_machine = response.find((o) => o.resource_desc == "MACHINE");
        let res_overhead = response.find((o) => o.resource_desc == "OVERHEAD");
        if (
          !this.validateResource(
            res_gas,
            res_electricity,
            res_labor,
            res_machine,
            res_overhead,
            idx,
            type_data
          )
        ) {
          return;
        }

        let usage = isFinite(qty / machine_capacity)
          ? qty / machine_capacity
          : 0;

        let gas = res_gas ? usage * res_gas.resource_qty : "";
        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : "";
        let labor = res_labor ? usage * res_labor.resource_qty : "";
        let machine = res_machine ? usage * res_machine.resource_qty : "";
        let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

        data.facility[idx].facility_code = prod_facility.facility_code;
        data.facility[idx].facility_desc = prod_facility.facility_desc;
        data.facility[idx].facility_type = prod_facility.facility_type;
        data.facility[idx].facility_number = prod_facility.facility_number;
        data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
        data.facility[idx].electricity = isNaN(parseFloat(electricity))
          ? ""
          : electricity.toFixed(2);
        data.facility[idx].labor = isNaN(parseFloat(labor))
          ? ""
          : labor.toFixed(2);
        data.facility[idx].machine = isNaN(parseFloat(machine))
          ? ""
          : machine.toFixed(2);
        data.facility[idx].overhead = isNaN(parseFloat(overhead))
          ? ""
          : overhead.toFixed(2);
        data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
        data.facility[idx].unit_electricity = res_electricity
          ? res_electricity.uom
          : "";
        data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
        data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
        data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

        this.setState({
          [type_data]: { ...data },
          list_resource: response,
        });
      });
    } else {
      data.facility[idx].facility_code = "";
      data.facility[idx].facility_desc = "";
      data.facility[idx].facility_type = "";
      data.facility[idx].facility_number = "";
      data.facility[idx].gas = "";
      data.facility[idx].electricity = "";

      data.facility[idx].labor = "";
      data.facility[idx].machine = "";
      data.facility[idx].overhead = "";
      data.facility[idx].unit_gas = "";
      data.facility[idx].unit_electricity = "";
      data.facility[idx].unit_labor = "";
      data.facility[idx].unit_machine = "";
      data.facility[idx].unit_overhead = "";
      this.setState({
        [type_data]: { ...data },
        list_resource: [],
      });
    }
  };
  handleInputBomFG = (val, idx, idxIng, type, data, type_data) => {
    if (type == "machine_capacity") {
      this.calculateResource(val, idx, data, type_data);
    }
    if (type == "material") {
      data.recipe[idx].ingredients[idxIng].material_description_rm = val;
      data.recipe[idx].ingredients[idxIng].uom = "";
      if (val == "") {
        data.recipe[idx].ingredients[idxIng].no_material_rm = "";
      } else {
        data.recipe[idx].ingredients[idxIng].no_material_rm = null;
        data.recipe[idx].ingredients[idxIng].plant = null;
      }
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "amount_per_batch") {
      data.recipe[idx].ingredients[idxIng].amount_per_batch = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "qty_per_batch" || type == "duration_per_batch") {
      const findFac = data.facility.find((it, z) => z == idx);
      const findFacIndex = data.facility.findIndex((it, z) => z == idx);
      if (typeof findFac != "undefined") {
        const modifFac = {
          ...findFac,
          [type]: val,
        };
        const newFac = [
          ...data.facility.slice(0, findFacIndex),
          modifFac,
          ...data.facility.slice(findFacIndex + 1),
        ];
        const newData = {
          ...data,
          facility: [...newFac],
        };

        this.setState({
          [type_data]: newData,
        });
      }
    }
  };

  calculateResource = (machine_capacity, idx, data, type_data) => {
    const { qty, list_resource } = this.state;

    if (list_resource.length > 0) {
      let res_gas = list_resource.find((o) => o.resource_desc == "GAS");
      let res_electricity = list_resource.find(
        (o) => o.resource_desc == "ELECTRICITY"
      );
      let res_labor = list_resource.find((o) => o.resource_desc == "LABOR");
      let res_machine = list_resource.find((o) => o.resource_desc == "MACHINE");
      let res_overhead = list_resource.find(
        (o) => o.resource_desc == "OVERHEAD"
      );

      if (
        !this.validateResource(
          res_gas,
          res_electricity,
          res_labor,
          res_machine,
          res_overhead,
          idx,
          type_data
        )
      ) {
        return;
      }

      let usage = isFinite(qty / machine_capacity) ? qty / machine_capacity : 0;

      let gas = res_gas ? usage * res_gas.resource_qty : "";
      let electricity = res_electricity
        ? usage * res_electricity.resource_qty
        : "";
      let labor = res_labor ? usage * res_labor.resource_qty : "";
      let machine = res_machine ? usage * res_machine.resource_qty : "";
      let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

      data.facility[idx].machine_capacity = machine_capacity;
      data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
      data.facility[idx].electricity = isNaN(parseFloat(electricity))
        ? ""
        : electricity.toFixed(2);
      data.facility[idx].labor = isNaN(parseFloat(labor))
        ? ""
        : labor.toFixed(2);
      data.facility[idx].machine = isNaN(parseFloat(machine))
        ? ""
        : machine.toFixed(2);
      data.facility[idx].overhead = isNaN(parseFloat(overhead))
        ? ""
        : overhead.toFixed(2);
      data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
      data.facility[idx].unit_electricity = res_electricity
        ? res_electricity.uom
        : "";
      data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
      data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
      data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

      this.setState({
        [type_data]: { ...data },
        [type_data.includes("edit")
          ? "machine_capacity_edit"
          : "machine_capacity"]: machine_capacity,
      });
    } else {
      let query_string = `facility_code=${data.facility[idx].facility_code}`;
      readResource(query_string).then((response) => {
        let list_resource = response;
        let res_gas = list_resource.find((o) => o.resource_desc == "GAS");
        let res_electricity = list_resource.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = list_resource.find((o) => o.resource_desc == "LABOR");
        let res_machine = list_resource.find(
          (o) => o.resource_desc == "MACHINE"
        );
        let res_overhead = list_resource.find(
          (o) => o.resource_desc == "OVERHEAD"
        );

        if (
          !this.validateResource(
            res_gas,
            res_electricity,
            res_labor,
            res_machine,
            res_overhead,
            idx,
            type_data
          )
        ) {
          return;
        }

        let usage = isFinite(qty / machine_capacity)
          ? qty / machine_capacity
          : 0;

        let gas = res_gas ? usage * res_gas.resource_qty : "";
        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : "";
        let labor = res_labor ? usage * res_labor.resource_qty : "";
        let machine = res_machine ? usage * res_machine.resource_qty : "";
        let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

        data.facility[idx].machine_capacity = machine_capacity;
        data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
        data.facility[idx].electricity = isNaN(parseFloat(electricity))
          ? ""
          : electricity.toFixed(2);
        data.facility[idx].labor = isNaN(parseFloat(labor))
          ? ""
          : labor.toFixed(2);
        data.facility[idx].machine = isNaN(parseFloat(machine))
          ? ""
          : machine.toFixed(2);
        data.facility[idx].overhead = isNaN(parseFloat(overhead))
          ? ""
          : overhead.toFixed(2);
        data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
        data.facility[idx].unit_electricity = res_electricity
          ? res_electricity.uom
          : "";
        data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
        data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
        data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

        this.setState({
          list_resource: list_resource,
          [type_data]: { ...data },
          [type_data.includes("edit")
            ? "machine_capacity_edit"
            : "machine_capacity"]: machine_capacity,
        });
      });
    }
  };

  onChangeBomFGTable = (data, type) => {
    this.setState({
      [type]: { ...data },
    });
  };

  validateResource = (
    gas,
    electricity,
    labor,
    machine,
    overhead,
    idx,
    type_data
  ) => {
    let errors = this.state.errors;
    if (!gas) {
      errors[`gas${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[`gas${idx}${type_data.includes("edit") ? "_edit" : ""}`];
    if (!electricity) {
      errors[`electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!labor) {
      errors[`labor${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!machine) {
      errors[`machine${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!overhead) {
      errors[`overhead${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];

    return errors;
  };
  onHandleChangeVersion = (version_no) => {
    this.setState({
      selected_version: version_no,
    });
  };
  onModalSelectVersion = () => {
    this.toggleVersion();
  };
  toShowVersion = (
    plant,
    material_description,
    recipe,
    filterSelectionVersion,
    indexMonth,
    material_code
  ) => {
    this.setState(
      {
        plant_version: plant,
        material_select_version: material_description,
        recipe_version: recipe,
        select_version: filterSelectionVersion,
        indexMonth: indexMonth,
        material_code_version: material_code,
      },
      () => {
        this.toggleVersion();
      }
    );
  };
  toggleVersion = () => {
    const { showModalSelectVersion } = this.state;
    this.setState({
      showModalSelectVersion: !showModalSelectVersion,
    });
  };
  onHandleSelection = (material_code, plant, indexMonth) => {
    // show modal spinner while fetching data
    //show spinner when get data from backend
    this.toggleSpinner();
    const selectedTypePlant = {
      BLENDING: "mixer",
      PLANT_1: "plant",
      PLANT_2: "plant",
    };
    const selectedPlant = {
      BLENDING: null,
      PLANT_1: "1",
      PLANT_2: "2",
    };
    let query_string = `no_material=${material_code}&type=${selectedTypePlant[plant]}`;
    if (selectedPlant[plant]) {
      query_string += `&plant=${selectedPlant[plant]}`;
    }
    readBOMFGSpec(query_string)
      .then((res) => {
        const { material_description, bom_contents } = res.foundData[0];
        const filterSelectionVersion = bom_contents.recipe.map((rr) => {
          const newOB = {
            label: `Version ${rr.version_no}`,
            value: rr.version_no,
          };
          return newOB;
        });
        this.toShowVersion(
          plant,
          material_description,
          bom_contents.recipe,
          filterSelectionVersion,
          indexMonth,
          material_code
        );
        this.toggleSpinner();
      })
      .catch((err) => {
        this.toggleSpinner();
      });
    //hide spinner when finish
    // show data
  };
  handleChooseVersion = () => {
    const {
      material_code_version,
      list_datatable,
      selected_version,
      plant_version,
      indexMonth,
    } = this.state;
    const findMat = [...list_datatable].find(
      (ld) => ld.material_code == material_code_version
    );
    const findIndexMat = [...list_datatable].findIndex(
      (ld) => ld.material_code == material_code_version
    );
    let newData;
    if (plant_version == "BLENDING") {
      const changeData = {
        ...findMat.data[indexMonth],
        bom_version: selected_version,
      };
      newData = [
        ...findMat.data.slice(0, indexMonth),
        changeData,
        ...findMat.data.slice(indexMonth + 1),
      ];
    }
    if (["PLANT_1", "PLANT_2"].includes(plant_version)) {
      const curPlantData =
        plant_version == "PLANT_1"
          ? {
              ...findMat.data[indexMonth].plant[0],
              bom_version: selected_version,
            }
          : {
              ...findMat.data[indexMonth].plant[1],
              bom_version: selected_version,
            };
      let changeData;
      if (plant_version == "PLANT_1") {
        changeData = {
          ...findMat.data[indexMonth],
          plant: [curPlantData, ...findMat.data[indexMonth].plant[1]],
        };
      } else {
        changeData = {
          ...findMat.data[indexMonth],
          plant: [...findMat.data[indexMonth].plant[0], curPlantData],
        };
      }

      newData = [
        ...findMat.data.slice(0, indexMonth),
        changeData,
        ...findMat.data.slice(indexMonth + 1),
      ];
    }
    const newMat = { ...findMat, data: newData };
    const newListDT = [
      ...list_datatable.slice(0, findIndexMat),
      newMat,
      ...list_datatable.slice(findIndexMat + 1),
    ];
    this.setState(
      {
        list_datatable: newListDT,
      },
      () => {
        this.toggleVersion();
      }
    );
  };
  render() {
    let {
      disableButtonNotification,
      seconds,
      select_items_material,
      list_datatable,
      date,
      material_create,
      errors_create,
      submitted_create,
      isValidMaterialCreate,
      isClearable,
      isRead,
      isEdit,
      isShowing,
      isLoading,
      isFound,
      list_summary,
      loadingSpinner,
      result_created_at,

      select_items_prod_facility,
      material_bomfg,
      classification_bomfg,
      qty_bomfg,
      uom_bomfg,
      bom_contents,
      select_items_material_all,
      select_items_uom,
      readOnly,
      select_items_plant,
      remark_bmfg,
      showModalBOMFG,

      showModalSelectVersion,
      select_version,
      material_select_version,
      recipe_version,
    } = this.state;

    const Line = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 3,
          borderRadius: 2,
        }}
      />
    );

    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loadingSpinner}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalBOMFG}
            size="xl"
            backdrop="static"
            onHide={this.onModalBOMFG}
            dialogClassName="full-modal-dialog"
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Detail {material_bomfg}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <>
                <Row>
                  <Col xs={12} md={2}>
                    <label htmlFor="material">Material</label>
                  </Col>
                  <Col xs={12} md={3}>
                    <p>{material_bomfg}</p>
                  </Col>
                  <Col xs={12} md={2}></Col>

                  <Col xs={12} md={2}>
                    <label htmlFor="classification">Classification</label>
                  </Col>
                  <Col xs={12} md={3}>
                    <p>{classification_bomfg}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={2}>
                    <label htmlFor="qty">Qty</label>
                  </Col>
                  <Col xs={12} md={3}>
                    <p>{qty_bomfg}</p>
                  </Col>
                  <Col xs={12} md={2}></Col>

                  <Col xs={12} md={2}>
                    <label htmlFor="uom">UOM</label>
                  </Col>
                  <Col xs={12} md={3}>
                    <p>{uom_bomfg}</p>
                  </Col>
                  <Col xs={12} md={12}>
                    <Line color={"#cccccc"} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <BOMFGTable
                      data={bom_contents}
                      type_data="bom_contents"
                      materials={select_items_material_all}
                      list_prod_facility={select_items_prod_facility}
                      uom={select_items_uom}
                      readOnly={readOnly}
                      loadOptions={this.getMaterial}
                      handleSelect={this.handleSelectBomFG}
                      handleInput={this.handleInputBomFG}
                      defaultOptions={true}
                      errors={this.state.errors}
                      onChange={(data, remark) =>
                        this.onChangeBomFGTable(data, "bom_contents")
                      }
                      list_plant={select_items_plant}
                      isCreate={false}
                      remark={remark_bmfg}
                      isMRP={true}
                    />
                  </Col>
                </Row>
              </>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalSelectVersion}
            size="lg"
            backdrop="static"
            onHide={() => this.onModalSelectVersion()}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                Select version of FG BOM {material_select_version}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <>
                <Row>
                  <Col xs={12} md={12}>
                    <SelectionVersion
                      select_version={select_version}
                      recipe_version={recipe_version}
                      handleChangeVersion={(version_no) =>
                        this.onHandleChangeVersion(version_no)
                      }
                    />
                  </Col>
                </Row>
              </>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="success"
                onClick={this.handleChooseVersion}
                className="float-right"
              >
                <i className="fas fa-save" /> Submit
              </Button>
            </ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info" style={{ zIndex: 12 }}>
                        <div className="card-header ">
                          <h3 className="card-title">
                            Material Resource Planning: Mixer
                          </h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div className="form-group">
                                  <label htmlFor="date_create">
                                    Planning Month *
                                  </label>
                                  <DatePicker
                                    disabled={isLoading}
                                    selected={date}
                                    startDate={date}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date"
                                    )}
                                    name="date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                    showMonthYearPicker
                                  />
                                  {errors_create.date && (
                                    <div className="invalid-feedback">
                                      {errors_create.date}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={
                                    errors_create.material !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="material">Material</label>
                                  <Select
                                    isDisabled={isLoading}
                                    styles={customStylesMaterialCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "material"
                                    )}
                                    options={select_items_material}
                                    defaultValue={select_items_material.filter(
                                      (e) => e.value === material_create
                                    )}
                                    isClearable={isClearable}
                                    ref={(ref) => (this.refMaterial = ref)}
                                  />

                                  {!isValidMaterialCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.material}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-right"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <>
                                      <i className="fas fa-spinner" />{" "}
                                      ...Loading
                                    </>
                                  ) : (
                                    <>
                                      <i className="fas fa-filter" /> Filter
                                    </>
                                  )}{" "}
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : isFound && isShowing ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 Stick-to-top">
                      <div className="card card-success">
                        <div className="card-header ">
                          <h3 className="card-title">Summary</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <MrpTable
                            listMaterials={list_summary}
                            summary={true}
                            type="mixer"
                            onChange={this.handleTableChange.bind(this)}
                            readOnly={true}
                          />
                          <Row>
                            <Col xs={12} md={12}>
                              {isEdit && (
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmitAll}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Save
                                </Button>
                              )}
                              <Button
                                variant="info"
                                onClick={this.handleNotification}
                                className="float-left"
                                disabled={disableButtonNotification}
                              >
                                <i className="fab fa-telegram-plane" /> Send
                                Notification{" "}
                                {disableButtonNotification
                                  ? `(in ${seconds}s)`
                                  : ""}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    {list_datatable.map(
                      (item, idx) =>
                        item.isVisible && (
                          <div
                            className="col-md-12 col-sm-12 col-12"
                            key={`row-list_datatable-${idx}`}
                          >
                            <div className="card card-info">
                              <div className="card-header ">
                                <h3 className="card-title">
                                  {item.material_name}&nbsp;&nbsp;
                                  <Button
                                    variant="default"
                                    onClick={this.handleModal.bind(
                                      this,
                                      item.material_code
                                    )}
                                    className="btn btn-xs"
                                    key={idx}
                                  >
                                    <i className="fas fa-search" /> FG BOM
                                  </Button>
                                </h3>
                                <div className="card-tools float-right">
                                  {result_created_at
                                    ? `Last Changed at: ${dateFormat(
                                        result_created_at,
                                        "yyyy-mm-dd HH:MM"
                                      )}    `
                                    : ""}
                                  {isEdit && (
                                    <Button
                                      variant="primary"
                                      onClick={() => this.handleSubmit(idx)}
                                      className="ml-2 float-right"
                                    >
                                      <i className="fas fa-save" /> Save Draft
                                    </Button>
                                  )}
                                </div>
                              </div>
                              <div className="card-body">
                                <MrpTable
                                  listMaterials={item}
                                  idx={idx}
                                  type="mixer"
                                  summary={false}
                                  onChange={this.handleTableChange.bind(this)}
                                  readOnly={!isEdit}
                                  handleSelection={(a, b, c) =>
                                    this.onHandleSelection(a, b, c)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                ) : (
                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={{ textAlign: "center" }}
                  >
                    No data found
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(MrpMixer);
