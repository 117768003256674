import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialAveragePriceService {
  readMaterialAveragePrice(date, material) {
    return axios
      .get(API_URL_DEFAULT + "map?start_month="+date+"&material_code="+material, { headers: authHeader() });
  }
  readMaterialAveragePriceByMonth(query_string) {
    return axios
      .get(API_URL_DEFAULT + "map/byMonth?"+query_string, { headers: authHeader() });
  }

}

export default new MaterialAveragePriceService();