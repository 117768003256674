import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MAPRNDService {
  readMAPRND(date, material, classification) {
    return axios.get(
      API_URL_DEFAULT +
        "map_rnd/?planning_month=" +
        date +
        "&material_code=" +
        material +
        "&classification=" +
        classification,
      { headers: authHeader() }
    );
  }

  readMAPRNDByMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT +
        "map_rnd/byMonth?" +
        query_string, 
      { headers: authHeader() }
    );
  }
}

export default new MAPRNDService();
