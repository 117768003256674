import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE_FINAL = "simulator/sales";

class SalesSimulatorService {
  simulateSales(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE_FINAL, payload, {
      headers: authHeader(),
    });
  }
}
export default new SalesSimulatorService();
