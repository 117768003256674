import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

class PowderPriceService {
    get_powder_price(query){
        return axios.get(API_URL_DEFAULT + "list-powder-price", {
            headers: authHeader(),
            params: query
        });
    }
}

export default new PowderPriceService();