import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApsRppService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "calendar/createRpp", payload, {
      headers: authHeader(),
    });
  }
  cancelRPP(payload) {
    return axios.post(API_URL_DEFAULT + "rpp/cancel-rpp/", payload, {
      headers: authHeader(),
    });
  }
  convertManualRPP(payload) {
    return axios.post(API_URL_DEFAULT + "rpp/convert-manual-rpp/", payload, {
      headers: authHeader(),
    });
  }

  readByDate(query) {
    return axios.get(API_URL_DEFAULT + "rpp/?" + query, {
      headers: authHeader(),
    });
  }

  readDetail(query) {
    return axios.get(API_URL_DEFAULT + "rpp/readDetail?" + query, {
      headers: authHeader(),
    });
  }

  readDraftRpp(query) {
    return axios.get(API_URL_DEFAULT + "draft_rpp?" + query, {
      headers: authHeader(),
    });
  }
}

export default new ApsRppService();
