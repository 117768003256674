import React, { useState } from "react";
import { Table, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";

const BOMFGTableEXP = ({
  data,
  type_data,
  uom,
  readOnly,
  loadOptions,
  defaultOptions,
  handleSelect,
  handleInput,
  onChange,
  errors,
  list_prod_facility,
  list_plant,
}) => {
  const [keyFacility, setKeyFacility] = useState(0);
  const [keyRecipe, setKeyRecipe] = useState(0);

  const Line = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 3,
        borderRadius: 2,
      }}
    />
  );

  const RenderTitleFacility = (item, idx) => {
    return (
      <div>
        {item.facility_desc ? item.facility_desc : "Facility"}{" "}
        {item.default && "(default)"}
        {!readOnly && (
          <Button
            variant="light"
            className="ml-2"
            onClick={() =>
              !item.default
                ? Swal.fire({
                    title: "Are you sure?",
                    text: "This version will be deleted",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      DeleteFacility(idx);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire("Cancelled", "Your data is safe :)", "error");
                    }
                  })
                : Swal.fire(
                    "Not Allowed",
                    "Can't remove default version. Set default version to other version",
                    "info"
                  )
            }
            size="sm"
          >
            <i className="fa fa-times" />
          </Button>
        )}
      </div>
    );
  };

  const RenderTitleRecipe = (item, idx) => {
    return (
      <div>
        Version {item.version_no} {item.default && "(default)"}
        {!readOnly && (
          <Button
            variant="light"
            className="ml-2"
            onClick={() =>
              !item.default
                ? Swal.fire({
                    title: "Are you sure?",
                    text: "This version will be deleted",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.value) {
                      DeleteRecipe(idx);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire("Cancelled", "Your data is safe :)", "error");
                    }
                  })
                : Swal.fire(
                    "Not Allowed",
                    "Can't remove default version. Set default version to other version",
                    "info"
                  )
            }
            size="sm"
          >
            <i className="fa fa-times" />
          </Button>
        )}
      </div>
    );
  };

  const SetDefaultFacility = (idx) => {
    data.facility[idx].default = true;

    data.facility.forEach((item, idxData) => {
      if (idx != idxData) {
        item.default = false;
      }
    });

    onChange(data);
  };

  const SetDefaultRecipe = (idx) => {
    data.recipe[idx].default = true;

    data.recipe.forEach((item, idxData) => {
      if (idx != idxData) {
        item.default = false;
      }
    });

    onChange(data);
  };

  const RenderTabsFacility = () => {
    return (
      data.facility &&
      data.facility.map((item, idx) => (
        <Tab
          eventKey={idx}
          title={RenderTitleFacility(item, idx)}
          className="mx-2 mt-2 pt-3 "
        >
          {!item.default && !readOnly && (
            <Button
              variant="outline-info"
              onClick={() => SetDefaultFacility(idx)}
              className="mx-2"
              size="sm"
            >
              Set as Default
            </Button>
          )}
          <Row className="mt-2">
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              {" "}
              <label htmlFor="prod_facility">Prod Facility</label>
            </Col>
            <Col xs={12} md={5}>
              <select
                className={
                  errors.prod_facility != "This field is required"
                    ? "form-control form-control-sm "
                    : "form-control form-control-sm  has-error has-feedback"
                }
                name="prod_facility"
                onChange={(val) =>
                  handleSelect(
                    val.target.selectedIndex,
                    idx,
                    null,
                    "prod_facility",
                    data,
                    type_data
                  )
                }
                style={{ padding: 0 }}
                value={item.facility_code}
                readOnly={readOnly}
              >
                <option value="">Choose</option>
                {list_prod_facility &&
                  list_prod_facility.map(function (item, i) {
                    if (item.facility_type != "RND") {
                      return (
                        <option value={item.facility_code} key={i}>
                          {item.facility_code} - {item.facility_desc}
                        </option>
                      );
                    }
                  })}
              </select>
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label htmlFor="gas">Gas</label>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="gas"
                  placeholder="Gas"
                  required="required"
                  value={item.gas || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "gas",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text form-control form-control-sm"
                    id="basic-addon2"
                    placeholder="UOM"
                    readOnly={true}
                  >
                    {item.unit_gas || ""}
                  </span>
                </div>
                {type_data == "bom_contents" && errors[`gas${idx}`] && (
                  <div className="text-danger font-weight-bold m-2 text-wrap">
                    {errors[`gas${idx}`]}
                  </div>
                )}
                {type_data == "bom_contents_edit" &&
                  errors[`gas${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`gas${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              {" "}
              <label htmlFor="machine_capacity">Machine Capacity</label>
            </Col>
            <Col xs={12} md={4}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="machine_capacity"
                  placeholder="Machine Capacity"
                  required="required"
                  value={item.machine_capacity || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "machine_capacity",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly={readOnly}
                />
                {type_data == "bom_contents" &&
                  errors[`machine_capacity${idx}`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`machine_capacity${idx}`]}
                    </div>
                  )}
                {type_data == "bom_contents_edit" &&
                  errors[`machine_capacity${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`machine_capacity${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col xs={12} md={1}>
              <span
                className="input-group-text form-control form-control-sm"
                id="basic-addon2"
                placeholder="UOM"
                readOnly={true}
              >
                kg/H
              </span>
            </Col>

            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label htmlFor="gas">Electricity</label>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="electricity"
                  placeholder="Electricity"
                  required="required"
                  value={item.electricity || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "electricity",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text form-control form-control-sm"
                    id="basic-addon2"
                    placeholder="UOM"
                    readOnly={true}
                  >
                    {item.unit_electricity || ""}
                  </span>
                </div>
                {type_data == "bom_contents" && errors[`electricity${idx}`] && (
                  <div className="text-danger font-weight-bold m-2 text-wrap">
                    {errors[`electricity${idx}`]}
                  </div>
                )}
                {type_data == "bom_contents_edit" &&
                  errors[`electricity${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`electricity${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              {" "}
              <label htmlFor="qty_per_batch">Qty per Batch</label>
            </Col>
            <Col xs={12} md={4}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="qty_per_batch"
                  placeholder="Qty per Batch"
                  required="required"
                  value={item.qty_per_batch || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "qty_per_batch",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly={readOnly}
                />
                {type_data == "bom_contents" &&
                  errors[`qty_per_batch${idx}`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`qty_per_batch${idx}`]}
                    </div>
                  )}
                {type_data == "bom_contents_edit" &&
                  errors[`qty_per_batch${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`qty_per_batch${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col xs={12} md={1}>
              <span
                className="input-group-text form-control form-control-sm"
                id="basic-addon2"
                placeholder="UOM"
                readOnly={true}
              >
                kg/batch
              </span>
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label htmlFor="gas">Labor</label>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="overhead"
                  placeholder="Labor"
                  required="required"
                  value={item.labor || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "overhead",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text form-control form-control-sm"
                    id="basic-addon2"
                    placeholder="UOM"
                    readOnly={true}
                  >
                    {item.unit_labor || ""}
                  </span>
                </div>
                {type_data == "bom_contents" && errors[`labor${idx}`] && (
                  <div className="text-danger font-weight-bold m-2 text-wrap">
                    {errors[`labor${idx}`]}
                  </div>
                )}
                {type_data == "bom_contents_edit" &&
                  errors[`labor${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`labor${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>

            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label
                htmlFor="duration_per_batch"
                style={{ fontSize: "0.79rem" }}
              >
                Duration per Batch
              </label>
            </Col>
            <Col xs={12} md={4}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="duration_per_batch"
                  placeholder="Duration per Batch"
                  required="required"
                  value={item.duration_per_batch || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "duration_per_batch",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly={readOnly}
                />
                {type_data == "bom_contents" &&
                  errors[`duration_per_batch${idx}`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`duration_per_batch${idx}`]}
                    </div>
                  )}
                {type_data == "bom_contents_edit" &&
                  errors[`duration_per_batch${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`duration_per_batch${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col xs={12} md={1}>
              <span
                className="input-group-text form-control form-control-sm"
                id="basic-addon2"
                placeholder="UOM"
                readOnly={true}
              >
                hour/batch
              </span>
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label htmlFor="gas">Machine</label>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="machine"
                  placeholder="Machine"
                  required="required"
                  value={item.machine || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      idx,
                      null,
                      "machine",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text form-control form-control-sm"
                    id="basic-addon2"
                    placeholder="UOM"
                    readOnly={true}
                  >
                    {item.unit_machine || ""}
                  </span>
                </div>
                {type_data == "bom_contents" && errors[`machine${idx}`] && (
                  <div className="text-danger font-weight-bold m-2 text-wrap">
                    {errors[`machine${idx}`]}
                  </div>
                )}
                {type_data == "bom_contents_edit" &&
                  errors[`machine${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`machine${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            <Col xs={12} md={6}>
              {" "}
            </Col>
            <Col
              xs={12}
              md={1}
              className="d-flex align-items-center justify-content-center"
            >
              <label htmlFor="gas">Overhead</label>
            </Col>
            <Col xs={12} md={5}>
              <div className="form-group input-group">
                <input
                  type="number"
                  step="any"
                  className="form-control form-control-sm"
                  name="overhead"
                  placeholder="Overhead"
                  required="required"
                  value={item.overhead || ""}
                  onChange={(val) =>
                    handleInput(
                      val.target.value,
                      null,
                      "overhead",
                      data,
                      type_data
                    )
                  }
                  min="0"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text form-control form-control-sm"
                    id="basic-addon2"
                    placeholder="UOM"
                    readOnly={true}
                  >
                    {item.unit_overhead || ""}
                  </span>
                </div>
                {type_data == "bom_contents" && errors[`overhead${idx}`] && (
                  <div className="text-danger font-weight-bold m-2 text-wrap">
                    {errors[`overhead${idx}`]}
                  </div>
                )}
                {type_data == "bom_contents_edit" &&
                  errors[`overhead${idx}_edit`] && (
                    <div className="text-danger font-weight-bold m-2 text-wrap">
                      {errors[`overhead${idx}_edit`]}
                    </div>
                  )}
              </div>
            </Col>
            {type_data == "bom_contents" && errors[`facility${idx}`] && (
              <div className="text-danger font-weight-bold m-2 text-wrap">
                {errors[`facility${idx}`]}
              </div>
            )}
            {type_data == "bom_contents_edit" &&
              errors[`facility${idx}_edit`] && (
                <div className="text-danger font-weight-bold m-2 text-wrap">
                  {errors[`facility${idx}_edit`]}
                </div>
              )}
          </Row>
        </Tab>
      ))
    );
  };

  const RenderTabsRecipe = () => {
    return data.recipe.map((item, idx) => (
      <Tab
        eventKey={idx}
        title={RenderTitleRecipe(item, idx)}
        className="mx-3 mt-2 pt-3"
      >
        {!item.default && !readOnly && (
          <Button
            variant="outline-info"
            onClick={() => SetDefaultRecipe(idx)}
            className="mt-2"
            size="sm"
          >
            Set as Default
          </Button>
        )}
        <div className="d-flex align-items-start">
          <div className="mr-2" style={{ width: "70%" }}>
            <div className="d-flex align-items-center my-2 ">
              <h6>Ingredients</h6>
              {!readOnly && (
                <Button
                  variant="outline-success"
                  className="ml-2"
                  onClick={() => AddIngredients(idx)}
                  size="sm"
                >
                  <i className="fa fa-plus" />
                </Button>
              )}{" "}
            </div>
            <div
              className="overflow-auto"
              style={{ height: "200px", fontSize: "0.9rem" }}
            >
              <Table
                bordered
                hover
                size="sm"
                style={{
                  textAlign: "center",
                }}
              >
                <thead key={`header`}>
                  <tr key={`h0`} className="bg-info">
                    <th key={`h1`}>No. </th>
                    <th key={`h77`}>EXP</th>
                    <th key={`h2`}>Material Code</th>
                    <th key={`h3`}>Material Name</th>
                    <th key={`h4`}>QTY</th>
                    <th key={`h5`}>UOM</th>
                    <th key={`h6`}>Plant</th>
                    {!readOnly && <th key={`h7`}>Action</th>}
                  </tr>
                </thead>
                <tbody key={`body`}>
                  {item.ingredients.map((ing, idxIng) => (
                    <tr key={`b${idxIng}0`}>
                      <td className="col-sm-1" key={`b${idxIng}1`}>
                        {idxIng + 1}{" "}
                      </td>
                      <td>
                        <Form>
                          <Form.Switch
                            type="switch"
                            id={`${idxIng}-${idx}-custom-switch`}
                            onChange={(val) =>
                              handleInput(
                                val.target.checked,
                                idx,
                                idxIng,
                                "switch",
                                data,
                                type_data
                              )
                            }
                            checked={ing.is_exp ?? false}
                            disabled={readOnly}
                          />
                        </Form>
                      </td>
                      <td className="col-sm-3" key={`b${idxIng}2`}>
                        {ing.is_exp ? (
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder="No Material"
                            value={ing.material_exp || ""}
                            onChange={(val) =>
                              handleInput(
                                val.target.value,
                                idx,
                                idxIng,
                                "material_exp",
                                data,
                                type_data
                              )
                            }
                            readOnly={readOnly}
                          />
                        ) : (
                          <AsyncSelect
                            onChange={(val) =>
                              handleSelect(
                                val,
                                idx,
                                idxIng,
                                "material",
                                data,
                                type_data
                              )
                            }
                            cacheOptions
                            defaultOptions={defaultOptions}
                            loadOptions={(input) => loadOptions(input)}
                            value={
                              defaultOptions !== true
                                ? defaultOptions.filter(
                                    (e) => e.value == ing.no_material_rm
                                  )
                                : ing.no_material_rm &&
                                  ing.material_description_rm
                                ? {
                                    value: ing.no_material_rm,
                                    label: ing.material_description_rm,
                                  }
                                : null
                            }
                            //code below to override select items from parent
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            isDisabled={
                              readOnly || ing.material_description_rm === null
                            }
                            additional={{
                              page: 1,
                            }}
                            placeholder="Material Code"
                          />
                        )}
                      </td>
                      <td className="col-sm-3" key={`b${idxIng}3`}>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder="Material Desc"
                          value={ing.material_description_rm || ""}
                          onChange={(val) =>
                            handleInput(
                              val.target.value,
                              idx,
                              idxIng,
                              "material",
                              data,
                              type_data
                            )
                          }
                          readOnly={
                            readOnly ||
                            (ing.material_description_rm === null &&
                              !ing.is_exp)
                          }
                        />
                      </td>
                      <td className="col-sm-1" key={`b${idxIng}4`}>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          placeholder="Qty"
                          value={ing.amount_per_batch || ""}
                          onChange={(val) =>
                            handleInput(
                              val.target.value,
                              idx,
                              idxIng,
                              "amount_per_batch",
                              data,
                              type_data
                            )
                          }
                          readOnly={readOnly}
                          step="0.0001"
                          min="0"
                        />
                      </td>
                      <td className="col-sm-1" key={`b${idxIng}5`}>
                        <select
                          className="form-control form-control-md"
                          name="uom"
                          onChange={(val) =>
                            handleSelect(
                              val.target.value,
                              idx,
                              idxIng,
                              "uom",
                              data,
                              type_data
                            )
                          }
                          value={ing.uom || ""}
                          readOnly={
                            readOnly ||
                            (ing.material_code != "" &&
                              ing.material_code != null)
                          }
                        >
                          <option value="">Choose</option>
                          {uom &&
                            uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      <td className="col-sm-1" key={`b${idxIng}6`}>
                        <select
                          className="form-control form-control-md"
                          name="plant"
                          onChange={(val) =>
                            handleSelect(
                              val.target.value,
                              idx,
                              idxIng,
                              "plant",
                              data,
                              type_data
                            )
                          }
                          value={ing.plant || ""}
                          readOnly={readOnly || ing.plant === null}
                        >
                          <option value="">Choose</option>
                          {list_plant &&
                            list_plant.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      {!readOnly && (
                        <td className="col-sm-1" key={`b${idxIng}7`}>
                          {
                            <Button
                              variant="outline-danger"
                              onClick={() => DeleteIngredients(idx, idxIng)}
                              size="sm"
                            >
                              <i className="fa fa-minus" />
                            </Button>
                          }
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            {type_data == "bom_contents" && errors[`recipe${idx}`] && (
              <div className="text-danger font-weight-bold m-2 text-wrap">
                {errors[`recipe${idx}`]}
              </div>
            )}
            {type_data == "bom_contents_edit" &&
              errors[`recipe${idx}_edit`] && (
                <div className="text-danger font-weight-bold m-2 text-wrap">
                  {errors[`recipe${idx}_edit`]}
                </div>
              )}
          </div>
          <Form.Group
            controlId="exampleForm.ControlTextarea1"
            className="my-3"
            style={{ width: "30%" }}
          >
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={`Remark of version ${item.version_no}`}
              onChange={(e) => onChangeRemark(item.version_no, e.target.value)}
              value={item.remark || ""}
              readOnly={readOnly}
            />
          </Form.Group>
        </div>
      </Tab>
    ));
  };
  const onChangeRemark = (version_no, value) => {
    if (data.recipe.length >= 1) {
      const findRecipe = data.recipe.find((it) => it.version_no == version_no);
      const findRecipeIndex = data.recipe.findIndex(
        (it) => it.version_no == version_no
      );
      if (typeof findRecipe != "undefined") {
        const modifRecipe = {
          ...findRecipe,
          remark: value,
        };
        const newRecipe = [
          ...data.recipe.slice(0, findRecipeIndex),
          modifRecipe,
          ...data.recipe.slice(findRecipeIndex + 1),
        ];
        const newData = {
          facility: [...data.facility],
          recipe: [...newRecipe],
        };
        onChange(newData);
      }
    }
  };
  const DeleteIngredients = (idx, idxIng) => {
    if (data.recipe[idx].ingredients.length != 1) {
      data.recipe[idx].ingredients.splice(idxIng, 1);
      onChange(data);
    }
  };

  const DeleteRecipe = (idx) => {
    if (data.length != 1) {
      data.recipe.splice(idx, 1);
      setKeyRecipe(idx > 0 ? idx - 1 : 0);
      onChange(data);
    }
  };

  const DeleteFacility = (idx) => {
    if (data.length != 1) {
      data.facility.splice(idx, 1);
      setKeyFacility(idx > 0 ? idx - 1 : 0);
      onChange(data);
    }
  };

  const AddRecipe = () => {
    if (
      data.recipe[keyRecipe].ingredients.some(
        (item) =>
          item.material_code == "" ||
          item.material_name == "" ||
          item.qty == "" ||
          item.uom == "" ||
          item.plant == ""
      )
    ) {
      Swal.fire(
        "Not allowed",
        "Please fill the ingredients before adding new recipe",
        "info"
      );
    } else {
      data.recipe.unshift({
        default: false,
        version_no: data.recipe[0].version_no + 1,
        ingredients: [
          {
            no_material_rm: "",
            material_description_rm: "",
            amount_per_batch: "",
            uom: "",
            plant: null,
          },
        ],
      });

      setKeyRecipe(0);
      onChange(data);
    }
  };

  const AddFacility = () => {
    if (
      data.facility[keyFacility].facility_code == "" ||
      data.facility[keyFacility].machine_capacity == ""
    ) {
      Swal.fire(
        "Not allowed",
        "Please fill the prod facility and capacity before adding new facility",
        "info"
      );
    } else {
      data.facility.unshift({
        default: false,
        facility_no: data.facility[0].facility_no + 1,
        facility_code: "",
        facility_desc: "",
        facility_type: "",
        facility_number: "",
        machine_capacity: "",
        unit_machine_capacity: "kg/H",
        gas: "",
        electricity: "",
        labor: "",
        machine: "",
        overhead: "",
        unit_gas: "",
        unit_electricity: "",
        unit_labor: "",
        unit_machine: "",
        unit_overhead: "",
      });
      setKeyFacility(0);
      onChange(data);
    }
  };

  const AddIngredients = (idx) => {
    data.recipe[idx].ingredients.push({
      no_material_rm: "",
      material_description_rm: "",
      amount_per_batch: "",
      uom: "",
      plant: null,
    });
    onChange(data);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h6>Product Facility</h6>
        {!readOnly && (
          <Button
            variant="outline-success"
            className="ml-2"
            onClick={() =>
              data.facility.length < list_prod_facility.length
                ? AddFacility()
                : Swal.fire(
                    "Cancelled",
                    `Cannot add more than ${list_prod_facility.length} facilities`,
                    "error"
                  )
            }
            size="sm"
          >
            <i className="fa fa-plus" />
          </Button>
        )}
      </div>
      <div className="mt-3 mb-3 border rounded">
        <Tabs activeKey={keyFacility} onSelect={setKeyFacility}>
          {RenderTabsFacility()}
        </Tabs>
      </div>
      <Line color={"#cccccc"} />

      <div className="d-flex align-items-center">
        <h6>Recipe</h6>
        {!readOnly && (
          <Button
            variant="outline-success"
            className="ml-2"
            onClick={() => AddRecipe()}
            size="sm"
          >
            <i className="fa fa-plus" />
          </Button>
        )}
      </div>
      <div className="mt-3 mb-3 border rounded">
        <Tabs activeKey={keyRecipe} onSelect={setKeyRecipe}>
          {RenderTabsRecipe()}
        </Tabs>
      </div>
    </>
  );
};

export default BOMFGTableEXP;
