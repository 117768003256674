import PowderPriceService from "../../services/ninda/powder_price.service";

export const getPowderPrice = (query) => {
    return PowderPriceService.get_powder_price(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message = (error.response && error.response.data
                && error.response.data.message) || error.message || error.toString();

            return Promise.reject(message);
        }
    )
}