import React from "react";
import moment from "moment";
import thousandformat from "../helpers/thousanddelimiter";
import DecimalPrecision from "../helpers/decimalprecision";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const CheckNeedTableSummary = ({ num_month, total, otherClass }) => {
  return (
    <div className="">
      <table
        className={
          otherClass
            ? otherClass
            : " table table-bordered table-striped table-compact"
        }
      >
        <div>
          <tr>
            <th style={{ width: "20%" }}>{total.material_description_rm}</th>
            {num_month &&
              num_month.map((nm) => {
                return (
                  <th className="text-center">{moment(nm).format("MMM-YY")}</th>
                );
              })}
          </tr>
          <tr>
            <th>Qty Needs</th>
            {total.value_month &&
              total.value_month.map((pd) => {
                return (
                  <th className="text-right">
                    {!isNaN(pd)
                      ? thousandformat(
                          DecimalPrecision.round(pd, DECIMAL_PLACES),
                          ".",
                          ".",
                          ","
                        )
                      : pd}
                  </th>
                );
              })}
          </tr>
        </div>
      </table>
    </div>
  );
};
export default CheckNeedTableSummary;
