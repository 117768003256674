import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceList = ({
  data
}) => {
  const headerStyle = { backgroundColor: '#17A2B8', color: 'white' };
  return (
    <div key={data}>
      <Form>
        <Table bordered hover size="sm" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <thead className="still">
            <tr >
              <th rowSpan='2'>No</th>
              <th rowSpan='2'>Material Code</th>
              <th rowSpan='2'>Material Name</th>
              <th rowSpan='2'>Classification</th>
              <th rowSpan='2'>Facility</th>
              <th rowSpan='2'>Status</th>
              <th rowSpan='2'>COGM per KG {dateFormat(data.production_date, "mmm-yy")}</th>
              <th rowSpan='2'>COGS {dateFormat(data.production_date, "mmm-yy")}</th>
              <th rowSpan='2'>Lowest Price Per Kg</th>
              <th colSpan='6'>Recomended Selling Price</th>
            </tr>
            <tr>
              <th > &gt; 1000 MT</th>
              <th >500-1000 MT</th>
              <th >100-500 MT</th>
              <th >50-100 MT</th>
              <th >10-50 MT</th>
              <th > &lt; 10 MT</th>
            </tr>
          </thead>
          <tbody>
            {data.price.map((item, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{item.material_code}</td>
                <td>{item.material_desc}</td>
                <td>{item.classification ? item.classification.mapping : '-'}</td>
                <td>{item.type}{item.plant ? ' ' + item.plant : ''}</td>
                <td>{item.status}</td>
                <td>{isNaN(parseFloat(item.cogm)) ? "" : (item.cogm).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.cogs)) ? "" : (item.cogs).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.lowest_price)) ? "" : (item.lowest_price).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_upper_1000)) ? "" : (item.margin_upper_1000).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_500_1000)) ? "" : (item.margin_500_1000).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_100_500)) ? "" : (item.margin_100_500).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_50_100)) ? "" : (item.margin_50_100).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_10_50)) ? "" : (item.margin_10_50).toFixed(DECIMAL_PLACES)}</td>
                <td>{isNaN(parseFloat(item.margin_under_10)) ? "" : (item.margin_under_10).toFixed(DECIMAL_PLACES)}</td>
              </tr>
            ))}

          </tbody>
        </Table>
      </Form>

    </div >
  )
};

export default PriceList;