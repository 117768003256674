import dateFormat from "dateformat";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  createMultiRpp,
  getSalesForecast,
} from "../../modules/ApsCalendarModule";
import { readProdFacility } from "../../modules/ProdFacilityModule";
import addSeparatorsNF from "../../helpers/thousanddelimiter";

export const SalesForecast = ({
  data,
  isShow,
  prod_facility,
  reloadDataBackend,
}) => {
  const [requester, setRequester] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTotalQuantity, setIsOpenTotalQuantity] = useState(false);
  const [itemTotalQuantity, setItemTotalQuantity] = useState(null);
  const [detail, setDetail] = useState(null);
  const [prodFacility, setProdFacility] = useState(null);
  const [remark, setRemark] = useState(null);
  const [qtyList, setQtyList] = useState([
    { date: null, date_show: null, qty: null },
  ]);
  const [sfList, setSfList] = useState([]);
  const [listProd, setListProd] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setRequester(user?.username);
    if (isShow) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const handleTotalQuantity = () => {
    let totalPlannedQuantity1 = 0;
    let totalPlannedQuantity2 = 0;
    let totalPlannedQuantityMixer = 0;
    let totalPlannerAllActivity = 0;

    if (sfList && sfList.length > 0) {
      for (const item of sfList) {
        totalPlannedQuantity1 += item.plant_1;
        totalPlannedQuantity2 += item.plant_2;
        totalPlannedQuantityMixer += item.mixer;
      }

      totalPlannerAllActivity =
        totalPlannedQuantity1 +
        totalPlannedQuantity2 +
        totalPlannedQuantityMixer;
    }

    setItemTotalQuantity({
      totalPlannedQuantity1: totalPlannedQuantity1,
      totalPlannedQuantity2: totalPlannedQuantity2,
      totalPlannedQuantityMixer: totalPlannedQuantityMixer,
      totalPlannerAllActivity: totalPlannerAllActivity,
    });

    setIsOpenTotalQuantity(true);
  };

  const fetchData = () => {
    const params = {
      date: dateFormat(data.selectedDate, "yyyy-mm-dd"),
    };

    readProdFacility().then((response) => {
      const newRes = [...response].map((m) => {
        return {
          prod_facility_desc: m?.facility_code,
          prod_facility_code: m?.facility_code,
          prod_facility_type: m?.facility_type,
          prod_facility_number: m?.facility_number,
          label: `${m?.facility_code} - ${m?.facility_desc}`,
          value: m?.facility_code,
        };
      });
      setListProd(newRes);
    });
    getSalesForecast(new URLSearchParams(params)).then((res) => {
      setSfList(res);
    });
  };

  useEffect(() => {
    setQtyList([{ date: null, date_show: null, qty: null }]);
    setRemark(null);
    setProdFacility(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const wajib = () => {
    return <span style={{ color: "red" }}>*</span>;
  };

  const handleAction = (type, index) => {
    const list = [...qtyList];
    if (type === "delete") {
      list.splice(index, 1);
    } else {
      list.push({ date: null, date_show: null, qty: null });
    }
    setQtyList(list);
  };

  const handleChange = (value, name, index) => {
    const list = [...qtyList];
    if (name === "date") {
      list[index][name] = dateFormat(value, "yyyy-mm-dd");
      list[index].date_show = dateFormat(value, "dd/mm/yyyy");
    } else {
      list[index][name] = value;
    }
    setQtyList(list);
  };

  const handleSubmit = () => {
    let check = true;
    qtyList.map((item) => {
      if (!item.date || !item.qty) {
        check = false;
      }
      return item;
    });
    const qtySum = qtyList.reduce(
      (partialSum, a) => parseInt(partialSum) + parseInt(a.qty ? a.qty : 0),
      0
    );
    if (
      check &&
      qtyList.length > 0 &&
      prodFacility &&
      qtySum <= detail.remaining
    ) {
      const payload = [];
      qtyList.map((item) => {
        let collection = {
          material_desc: detail?.material_desc,
          material_code: detail?.material_code,
          qty: item.qty,
          total_qty: item.qty,
          unit: "KG",
          date_start: item.date,
          date_finish: item.date,
          cb_safety_stock: false,
          safety_stock: 0,
          beginning_balance: 0,
          remark: remark,
          type: "RPP",
          data_source: "salesforcast",
          requester: requester,
          prod_facility_desc: prodFacility?.prod_facility_desc,
          prod_facility_code: prodFacility?.prod_facility_code,
          prod_facility_type: prodFacility?.prod_facility_type,
          prod_facility_number: prodFacility?.prod_facility_number,
          position_picked: null,
          event_picked: null,
          duration: "0",
          total_batch: 0,
        };
        payload.push(collection);
        return item;
      });

      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          createMultiRpp(payload)
            .then(() => {
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                setIsOpen(false);
                fetchData();
                reloadDataBackend(data.selectedDate, prod_facility);
              });
            })
            .catch((err) => {
              Swal.fire("Warning", err, "warning");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancel", "Your data safe..", "warning");
        }
      });
    } else {
      if (check && qtySum > detail.remaining) {
        Swal.fire(
          "Warning",
          `Total Quantity ${qtySum} more than ${
            detail.remaining < 0 ? 0 : detail.remaining
          }`,
          "warning"
        );
      } else {
        Swal.fire("Warning", "Please Complete Form", "warning");
      }
    }
  };

  return (
    <>
      <div className="card card-info target-left">
        <div className="card-header d-flex flex-row justify-content-between">
          <div>
            <h3 className="card-title">
              Sales Forecast Information
              <br />
              Total Needed Quantity (KG) :{" "}
              <b>
                {addSeparatorsNF(
                  sfList.reduce((sum, item) => sum + item.needed, 0),
                  ".",
                  ".",
                  "."
                )}
              </b>
            </h3>
          </div>
          <div className="d-flex align-items-center" style={{ marginLeft: 20 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleTotalQuantity()}
            >
              <i
                className="fas fa-info-circle"
                style={{ fontSize: 20, color: "black" }}
              ></i>
            </div>
          </div>
        </div>
        <div
          className="px-2 py-2"
          style={{ maxHeight: "1024px", overflowY: "auto" }}
        >
          {sfList &&
            sfList.length > 0 &&
            sfList.map((item) => (
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(true);
                  setDetail(item);
                }}
              >
                <div className="card-body">
                  {item.plant_1 + item.plant_2 > item.needed && (
                    <div
                      className="text-right"
                      style={{ marginTop: "-23px", marginRight: "-19px" }}
                    >
                      <label className="badge badge-danger">Overplanned</label>
                    </div>
                  )}
                  <table width="100%" style={{ marginTop: "-10px" }}>
                    <tr>
                      <td colSpan={2}>
                        <b>{item.material_label}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>{item.material_code}</b>
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">Needed Quantity (KG)</td>
                      <td>
                        :{" "}
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          {isNaN(parseFloat(item.needed))
                            ? "0"
                            : addSeparatorsNF(item.needed, ".", ".", ".")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Planned Quantity Plant 1 (KG)</td>
                      <td>
                        :{" "}
                        <span style={{ color: "aqua", fontWeight: "bold" }}>
                          {addSeparatorsNF(item.plant_1, ".", ".", ".")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Planned Quantity Plant 2 (KG)</td>
                      <td>
                        :{" "}
                        <span style={{ color: "aqua", fontWeight: "bold" }}>
                          {addSeparatorsNF(item.plant_2, ".", ".", ".")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Planned Quantity Mixer (KG)</td>
                      <td>
                        :{" "}
                        <span style={{ color: "aqua", fontWeight: "bold" }}>
                          {addSeparatorsNF(item.mixer, ".", ".", ".")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Rem. Needed Quantity (KG)</td>
                      <td>
                        :{" "}
                        <span style={{ color: "orange", fontWeight: "bold" }}>
                          {item.remaining > 0
                            ? addSeparatorsNF(item.remaining, ".", ".", ".")
                            : 0}
                        </span>
                      </td>
                    </tr>
                    {item.plant_1 + item.plant_2 > item.needed && (
                      <tr>
                        <td>Overplanned Quantity</td>
                        <td>
                          :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {addSeparatorsNF(
                              Math.abs(
                                item.needed - item.plant_1 - item.plant_2
                              ),
                              ".",
                              ".",
                              "."
                            )}
                          </span>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal show={isOpen} backdrop="static" size="m" onHide={setIsOpen}>
        <ModalHeader closeButton className="bg-info text-white">
          <ModalTitle>Create RPP</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" style={{ marginBottom: "3px" }}>
                  Material
                </label>
                <p>{`(${detail?.material_code}) ${detail?.material_label}`}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" style={{ marginBottom: "3px" }}>
                  Product Facility {wajib()}
                </label>
                <Select
                  onChange={(e) => setProdFacility(e)}
                  options={listProd}
                  value={prodFacility}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" style={{ marginBottom: "3px" }}>
                  Quantity
                </label>
                <p>
                  <i className="fas fa-info-circle"></i> Remaining Need (Plant 1
                  & Plant 2) :{" "}
                  {detail?.remaining > 0
                    ? addSeparatorsNF(detail?.remaining, ".", ".", ".")
                    : 0}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {qtyList &&
                    qtyList.length > 0 &&
                    qtyList.map((item, i) => (
                      <div className="row">
                        <div className="col-5">
                          <div className="form-group">
                            <label
                              className="form-label"
                              style={{ marginBottom: "3px" }}
                            >
                              Date {wajib()}
                            </label>
                            <DatePicker
                              value={item?.date_show}
                              disabledKeyboardNavigation
                              onChange={(e) => handleChange(e, "date", i)}
                              name="date_qty"
                              wrapperClassName="datePickerCustom"
                              dateFormat="dd/MM/yyyy"
                              className={
                                item?.date_show
                                  ? "form-control form-control-sm"
                                  : "form-control form-control-sm is-invalid"
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              style={{ marginBottom: "3px" }}
                            >
                              Qty {wajib()}
                            </label>
                            <div className="input-group input-group-sm">
                              <input
                                value={item?.qty}
                                onChange={(e) =>
                                  handleChange(e.target.value, "qty", i)
                                }
                                type="number"
                                className={
                                  item?.qty
                                    ? "form-control form-control-sm"
                                    : "form-control form-control-sm is-invalid"
                                }
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">KG</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <Button
                            variant="danger"
                            style={{ marginTop: "24px" }}
                            className="btn btn-sm btn-outline"
                            onClick={() => handleAction("delete", i)}
                          >
                            <i class="fas fa-times"></i>
                          </Button>
                        </div>
                      </div>
                    ))}
                  <div className="row">
                    <div className="col-5">
                      <Button
                        variant="info"
                        className="btn btn-sm btn-outline"
                        onClick={() => handleAction("add")}
                      >
                        <i class="fas fa-plus"></i> Add New
                      </Button>
                    </div>
                    <div className="col-7">
                      <label
                        className="form-label"
                        style={{ marginBottom: "3px" }}
                      >
                        Total Quantity :{" "}
                        {qtyList.reduce(
                          (partialSum, a) =>
                            parseInt(partialSum) + parseInt(a.qty ? a.qty : 0),
                          0
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label" style={{ marginBottom: "3px" }}>
                  Remark
                </label>
                <textarea
                  rows={3}
                  className="form-control"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={() => handleSubmit()}>
            <i class="fas fa-save"></i> Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        show={isOpenTotalQuantity}
        backdrop="static"
        size="m"
        onHide={setIsOpenTotalQuantity}
      >
        <ModalHeader closeButton className="bg-info text-white">
          <ModalTitle>Total Planner All Facility</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <table>
            <tr>
              <td style={{ width: "200px" }}>Total Planned Quantity 1</td>
              <td>:</td>
              <td>
                {addSeparatorsNF(
                  itemTotalQuantity?.totalPlannedQuantity1,
                  ".",
                  ".",
                  "."
                )}
              </td>
            </tr>
            <tr>
              <td>Total Planned Quantity 2</td>
              <td>:</td>
              <td>
                {addSeparatorsNF(
                  itemTotalQuantity?.totalPlannedQuantity2,
                  ".",
                  ".",
                  "."
                )}
              </td>
            </tr>
            <tr>
              <td>Total Planned Quantity Mixer</td>
              <td>:</td>
              <td>
                {addSeparatorsNF(
                  itemTotalQuantity?.totalPlannedQuantityMixer,
                  ".",
                  ".",
                  "."
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>&nbsp;</td>
            </tr>
            <tr>
              <td>Total Planner All Facility</td>
              <td>:</td>
              <td>
                {addSeparatorsNF(
                  itemTotalQuantity?.totalPlannerAllActivity,
                  ".",
                  ".",
                  "."
                )}
              </td>
            </tr>
          </table>
        </ModalBody>
      </Modal>
    </>
  );
};
