import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";

import { generateKey, encrypt, decrypt } from "../modules/CryptoModule";

const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");
class Crypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      showModal: false,
      errors: {},
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  //handle change

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
    } else {
      this.setState({
        [name]: null,
      });
    }
  };
  //trigger modal
  toDetail = (id) => {
    this.setState(
      {
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModal: !this.state.showModal,
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester } = this.state;

    let collection = {
      requester: requester,
    };
    if (requester) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleGenerate = () => {
    generateKey().then((res) => {
      this.downloadTxtFile(res.publicKey, "publicKey");
      this.downloadTxtFile(res.privateKey, "privateKey");
    });
  };
  generateTimestamp = () => {
    const date = dateFormat(new Date(), "yyyymmdd-HHMMss");
    return date;
  };
  downloadTxtFile = (data, name) => {
    const timestamp = this.generateTimestamp();
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${name}--${timestamp}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  onChangeHandlerFile = (name, event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const text = this.handleFile(files[0], name);
    }
  };
  handleFile = (file, name) => {
    const reader = new FileReader();
    reader.onload = this.handleText.bind(this, name, reader.result);
    reader.readAsText(file);
  };
  handleText = (name, text, e) => {
    const checkName = {
      public_key_file: "publicKey",
      private_key_file: "privateKey",
    };
    const customName = checkName[name];
    this.setState({
      [customName]: e.target.result,
    });
  };
  handleEncrypt = () => {
    const { publicKey, input } = this.state;
    if (publicKey && input) {
      //encrypt
      encrypt({ publicKey: publicKey, input: input }).then((res) => {
        this.setState({
          encryptData: res,
        });
      });
    }
  };
  handleDecrypt = () => {
    const { privateKey, encryptData } = this.state;
    if (privateKey && encryptData) {
      //decrypt
      decrypt({ privateKey: privateKey, encryptedData: encryptData })
        .then((result) => {
          this.setState({
            decryptData: result,
          });
        })
        .catch((err) => {
          this.setState({
            decryptData: err,
          });
        });
    }
  };
  render() {
    let {
      submitted,
      loading,
      isCreate,
      privateKey,
      publicKey,
      input,
      encryptData,
      decryptData,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Pair Key Encryption</h3>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off" noValidate>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="warning"
                                  onClick={this.handleGenerate}
                                  className=""
                                >
                                  <i className="fas fa-key" /> Generate Key
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-6"></div>
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Input</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated "
                                : "needs-validation "
                            }
                            noValidate
                          >
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label htmlFor="input">Text *</label>
                                <textarea
                                  className="form-control form-control-sm"
                                  name="input"
                                  placeholder="Text"
                                  required="required"
                                  onChange={this.onChangeValue}
                                  defaultValue={input}
                                  rows="5"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-6"></div>
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Encrypt</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated "
                                : "needs-validation "
                            }
                            noValidate
                          >
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label htmlFor="file">Choose Public Key*</label>
                                <input
                                  type="file"
                                  className="form-control form-control-sm"
                                  name="file"
                                  placeholder="File"
                                  onChange={this.onChangeHandlerFile.bind(
                                    this,
                                    "public_key_file"
                                  )}
                                  accept={SheetJSFT}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label>Public Key</label>
                                <textarea
                                  className="form-control form-control-sm"
                                  rows="10"
                                  readOnly
                                  value={publicKey}
                                />
                              </div>
                            </div>
                          </form>
                          <div className="row">
                            <div className="col-md-12 col-12 align-self-center">
                              <button
                                id="button-addon2"
                                type="button"
                                className="btn btn-info center"
                                onClick={this.handleEncrypt}
                              >
                                <i className="fas fa-save"></i> Encrypt
                              </button>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-md-12 mb-3 mt-3">
                              <label>Encrypted Data</label>
                              <textarea
                                className="form-control form-control-sm"
                                rows="10"
                                readOnly
                                value={encryptData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Decrypt</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated "
                                : "needs-validation "
                            }
                            noValidate
                          >
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label htmlFor="file">
                                  Choose Private Key*
                                </label>
                                <input
                                  type="file"
                                  className="form-control form-control-sm"
                                  name="file"
                                  placeholder="File"
                                  onChange={this.onChangeHandlerFile.bind(
                                    this,
                                    "private_key_file"
                                  )}
                                  accept={SheetJSFT}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label>Private Key</label>{" "}
                                <textarea
                                  className="form-control form-control-sm"
                                  rows="10"
                                  readOnly
                                  value={privateKey}
                                />
                              </div>
                            </div>
                          </form>
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <button
                                id="button-addon2"
                                type="button"
                                className="btn btn-info"
                                onClick={this.handleDecrypt}
                              >
                                <i className="fas fa-save"></i> Decrypt
                              </button>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-md-12 mb-3 mt-3">
                              <label>Decrypted Data</label>{" "}
                              <textarea
                                className="form-control form-control-sm"
                                rows="10"
                                readOnly
                                value={decryptData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Crypto);
