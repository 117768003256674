import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ExchangeRateService { 

  create(payload) {  
    return axios
      .post(API_URL_DEFAULT + "exchange_rate", payload,{ headers: authHeader()});
  }
  readExchangeRate(query_string) {   
    return axios
      .get(API_URL_DEFAULT + "exchange_rate/?"+query_string,{ headers: authHeader()});
  }
  readCurrentExchangeRate(currency) {   
    return axios
      .get(API_URL_DEFAULT + "exchange_rate/?currency="+currency,{ headers: authHeader()});
  }
  readActiveExchangeRate() {   
    return axios
      .get(API_URL_DEFAULT + "exchange_rate/?active=active",{ headers: authHeader()});
  }
  edit(payload) {  
    return axios
      .put(API_URL_DEFAULT + "exchange_rate", payload,{ headers: authHeader()});
  }

  delete(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "exchange_rate", {data:payload, headers: authHeader()});
  } 
  
}

export default new ExchangeRateService();