import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class SpotPriceOutlookService {

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "spotpriceoutlook/", payload, { headers: authHeader() });
  }
  createOverwrite(payload) {
    return axios
      .post(API_URL_DEFAULT + "spotpriceoutlook/?type=overwrite", payload, { headers: authHeader() });
  }
  readSpotPriceOutlook(first, second) {
    return axios
      .get(API_URL_DEFAULT + "spotpriceoutlook/?first=" + first + "&second=" + second, { headers: authHeader() });
  }
  readQsSpotPriceOutlook(qs) {
    return axios
      .get(API_URL_DEFAULT + "spotpriceoutlook/?" + qs, { headers: authHeader() });
  }
  readCurrentSpotPriceOutlook(currency) {
    return axios
      .get(API_URL_DEFAULT + "spotpriceoutlook/?currency=" + currency, { headers: authHeader() });
  }
  readActiveSpotPriceOutlook() {
    return axios
      .get(API_URL_DEFAULT + "spotpriceoutlook/?active=active", { headers: authHeader() });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "spotpriceoutlook", payload, { headers: authHeader() });
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "spotpriceoutlook", { data: payload, headers: authHeader() });
  }
}

export default new SpotPriceOutlookService();
