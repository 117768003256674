import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MonitoringSchedulerService {
    monitoring_scheduler(payload) {
        return axios.post(API_URL_DEFAULT + "monitoring_scheduler", payload, {
            headers: authHeader()
        });
    }

    triggerPowderPrice(payload) {
        return axios.post(API_URL_DEFAULT + "powder_price/generate", payload, {
            headers: authHeader()
        });
    }
    get_cloud_run_jobs(query) {
        return axios.get(API_URL_DEFAULT + "monitoring_scheduler", {
            headers: authHeader(),
            params: query
        });
    }
}

export default new MonitoringSchedulerService();