import ImportRppService from "../../services/ninda/import_rpp.service";

export const downloadTemplate = (query) => {
    return ImportRppService.download_template(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message = (error.response && error.response.data
                && error.response.data.message) || error.message || error.toString();

            return Promise.reject(message);
        }
    )
}

export const insertImportRPP = (query) => {
    return ImportRppService.insertImportRPP(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message = (error.response && error.response.data
                && error.response.data.message) || error.message || error.toString();

            return Promise.reject(message);
        }
    )
}

export const getImportRpp = (query) => {
    return ImportRppService.getImportRpp(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message = (error.response && error.response.data
                && error.response.data.message) || error.message || error.toString();

            return Promise.reject(message);
        }
    )
}