import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class NotificationService {
  send(payload) {
    return axios.post(API_URL_DEFAULT + "notification/", payload, {
      headers: authHeader(),
    });
  }
}

export default new NotificationService();
