import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class UomService {

    create(payload) {
        return axios
            .post(API_URL_DEFAULT + "uom", payload, { headers: authHeader() });
    }
    readUom() {
        return axios
            .get(API_URL_DEFAULT + "uom/", { headers: authHeader() });
    }
    edit(payload) {
        return axios
            .put(API_URL_DEFAULT + "uom", payload, { headers: authHeader() });
    }

    delete(payload) {
        return axios
            .delete(API_URL_DEFAULT + "uom", { data: payload, headers: authHeader() });
    }

}

export default new UomService();