import CategoryForecastService from "../services/category_forecast.service"; 

export const readCategoryForecast = () =>  {
  return CategoryForecastService.readCategoryForecast().then(
    (response) => {  
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 
      return Promise.reject(message);
    }
  );
}; 