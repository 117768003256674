import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import XLSX from "xlsx";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import {
  convertPOContract
} from "../modules/ConvertMaterialModule";
import WorksheetTabCustom from "../components/WorksheetTabCustom";
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

const TextJSFT = ["txt"].map((x) => `.${x}`).join(",");
const array = new Uint32Array(10);
class ConvertMaterialPOContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planning_month: null,
      loading: false,
      submitted: false,
      showModal: false,
      readOnly: false,
      isClearable: true,
      errors: {},
      department: null,
      requester: null,
      requester_name: null,
      inputFileExcel: self.crypto.getRandomValues(array),  /* eslint-disable-line no-restricted-globals */
      fileExcel: {
        name: "Choose file",
      },
      file_worksheet: []

    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const user = JSON.parse(localStorage.getItem("user"));

    const department =
      user.details.hris_org_tree.current_person.nama_department;


    this.setState({
      department,
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModal: !this.state.showModal,
      readOnly: false,
      loading_download: false
    });
  };
  isEmpty = (o) => {

    return Object.keys(o).length == 0 ? true : false
  }
  //validate input
  validateForm = ({ fileExcel }) => {
    let errors = this.state.errors;
    if (this.isEmpty(fileExcel) || !fileExcel?.value) {
      errors.excel = "This field is required";
    } else {
      delete errors.excel;
    }
    return errors
  };

  handleSubmit = () => {
    try {
      const { fileExcel, department, requester, file_worksheet, planning_month } =
        this.state;
      const { history } = this.props;
      const new_values = {
        excel: file_worksheet,
        department: department,
        requester: requester,
        planning_month: dateFormat(planning_month, "yyyy-mm-01")
      };
      const validate = this.validateForm({ fileExcel });
      if ((validate?.excel) && file_worksheet.length == 0) {
        return this.setState({ errors: validate });
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            loading: true
          });
          convertPOContract(new_values)
            .then(() => {
              Swal.fire(
                "Information",
                "Data has been converted successfuly!",
                "success"
              );
              // CLEAR
              this.handleClear();
              history.push("/convert-material/po-contract");
            })
            .catch((err) => {
              Swal.fire("Information", err, "error");
            })
            .finally(() => {
              this.setState({
                loading: false
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } catch (err) {
      Swal.fire("Information ", "Please check your entries again!", "error");
    }
  };

  handleClear = () => {
    this.setState({
      load_file: false,
      file_worksheet: [],
      fileExcel: {
        name: "Choose file",
        type: "",
        size: 0,
        value: null
      },
      errors: {},
      planning_month: null,
    });
  };

  handleFile = (name, e) => {

    if (e.target.files.length > 0 && name == "excel") {
      const fileObject = e.target.files[0];

      this.handleReadFileExcel(fileObject)
        .then((response) => {
          this.setState({
            load_file: true,
            file_worksheet: response,
            fileExcel: {
              name: fileObject.name,
              type: fileObject.type,
              size: fileObject.size,
              value: e.target.value
            },
          });
        })
        .catch((e) => {
          this.setState({
            load_file: false,
            file_worksheet: [],
            file: {
              name: "Choose file",
              type: "",
              size: 0,
              value: e.target.value
            },
          });
        })
        .finally(() => {
          this.setState({ errors: {} });
        });
    }
  };

  handleReadFileExcel = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const binaryString = e.target.result;
          const workbook = XLSX.read(binaryString, { type: "buffer" });

          const result = workbook.SheetNames.map((item) => {
            const worksheet = workbook.Sheets[item];
            return {
              sheetName: item,
              worksheet: XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
                blankrows: false,
              }),
            };
          });
          resolve(result);
        };
      } catch (e) {
        reject(new Error(e.message));
      }
    });
  };

  handleDate = (date) => {
    this.setState({
      planning_month: date
    })
  }
  render() {
    let {
      inputFileExcel,
      loading,
      isCreate,
      fileExcel,
      file_worksheet,
      load_file,
      errors,
      planning_month,

    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>


          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Convert Material (PO Contract)</h3>
                        </div>
                        <div className="card-body">


                          <div className="row">
                            <div className="col-sm-12 col-md-6 p-2">
                              <div className='form-group'>
                                <label className='col-md-12 col-form-label'>Planning Month</label>
                                <div className='input-group col-md-12'>
                                  <DatePicker
                                    selected={planning_month}
                                    startDate={planning_month}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(this)}
                                    name='date'
                                    wrapperClassName='datePickerCustom'
                                    dateFormat='MMMM yyyy'
                                    className='form-control'
                                    required
                                    showMonthYearPicker
                                    autoComplete='off'
                                    placeholderText="Choose month"
                                  />
                                  <span className='error invalid-feedback'>Please entries planning month</span>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputFileExcel">
                                  Upload Excel
                                </label>
                                <div className="input-group">
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="exampleInputFileExcel"
                                      onChange={this.handleFile.bind(this, 'excel')}
                                      accept={SheetJSFT}
                                      value={fileExcel?.value || ""}
                                      key={inputFileExcel || ""}
                                      placeholder={`Choose file ...`}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="exampleInputFileExcel"
                                    >
                                      {fileExcel?.name || ""}
                                    </label>
                                  </div>
                                </div>

                                {errors?.excel && (
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: ".25rem",
                                      fontSize: "80%",
                                      color: " #dc3545",
                                    }}
                                  >
                                    {errors?.excel}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {load_file && (
                            <div className="row">
                              <div className="col-sm-12 col-md-12 p-2">
                                <WorksheetTabCustom data={file_worksheet} type={`no-default`} />
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-sm-12 col-md-6 p-2">
                              <Button
                                variant="success"
                                className="float-right m-1"
                                onClick={this.handleSubmit}
                              >
                                <i className="fas fa-save" /> Submit
                              </Button>
                              <Button
                                variant="danger"
                                className="float-right m-1"
                                onClick={this.handleClear}
                              >
                                <i className="fas fa-eraser" /> Clear
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(ConvertMaterialPOContract);
