import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class SegmentService {

    readSegment() {
        return axios
            .get(API_URL_DEFAULT + "segment/", { headers: authHeader() });
    }

}

export default new SegmentService();