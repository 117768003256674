import React, { Component } from "react";
import OutTable from "../components/OutTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const Worksheet = ({ worksheet }) => {
  return (
    <div>
      <Tabs defaultActiveKey="first" variant="pills">
        {worksheet &&
          worksheet.map((e, i) => {
            return i === 0 ?
              (
                <Tab eventKey="first" title={e.worksheet} key={i}>
                  <OutTable
                    data={e.data}
                    cols={e.cols}
                    otherClass="tableFreezeHeader table table-striped table-compact"
                  />
                </Tab>
              ) :
              (
                <Tab eventKey={e.worksheet} title={e.worksheet} key={i}>
                  <OutTable
                    data={e.data}
                    cols={e.cols}
                    otherClass="tableFreezeHeader table table-striped table-compact"
                  />
                </Tab>
              );
          })}
      </Tabs>
    </div>
  );
}

export default Worksheet;
