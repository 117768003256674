import React, { Component } from "react";

import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Modal from "react-bootstrap/Modal";

import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

import { withRouter } from "react-router-dom";

import DynamicTable from "../components/DynamicTable";
import { readSummarySalesForecast } from "../modules/SalesForecastModule";
class SummarySalesForecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreate: false,
      isRead: false,
      isDelete: false,
      isEdit: false,
      tableData: {
        header: [],
        body: [],
        footer: [],
      },
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    //read summary sales forecast
    const query_string = "";
    readSummarySalesForecast(`${query_string}`)
      .then((res) => {
        this.setState({
          tableData: res.foundData,
        });
      })
      .catch((err) => {
        const notFound = {
          header: [],
          footer: [
            {
              children: [
                {
                  label: err,
                  colspan: "1",
                },
              ],
            },
          ],
          body: [],
        };
        this.setState({
          tableData: notFound,
        });
      });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  handleData = () => {};

  render() {
    let { loading, isRead, tableData } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Summary Sales Forecast <small>each segment</small>
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col xs={12} md={12}>
                              <DynamicTable
                                tableInput={tableData}
                                otherClass="tableFreezeCustom  table table-bordered table-striped table-compact"
                                handleInputChange={this.handleData}
                                nameState="tableData"
                                styleTable={{
                                  overflow: "hidden",
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(SummarySalesForecast);
