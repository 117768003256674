import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MrpInputService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "mrp_input", payload, {
      headers: authHeader(),
    });
  }
  readMrpInput(query_string) {
    return axios.get(API_URL_DEFAULT + "mrp_input/?" + query_string, {
      headers: authHeader(),
    });
  }
  readActiveMrpLabel(hash) {
    return axios.get(API_URL_DEFAULT + "mrp_input/?hash_mrp_label=" + hash, {
      headers: authHeader(),
    });
  }
  getActiveMrpLabel(bool) {
    return axios.get(API_URL_DEFAULT + "mrp_input/?active_label=" + bool, {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "mrp_input", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "mrp_input", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new MrpInputService();
