import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, ModalBody, ModalTitle, Row, Table } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import { downloadTemplate, getImportRpp, insertImportRPP } from '../../modules/ninda/ImportRppModule';
import FileDownload from 'js-file-download';

moment.locale('id', {
    weekdays : ['Minggu', "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
    }
)
const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

const array = new Uint32Array(10);

const ImportRPP = ({
    setOpenModal
}) => {
    const [fileTemp, setFileTemp] = useState(null)
    const [readExcel, setReadExcel] = useState(null)
    const [showHistory, setShowHistory] = useState(false)
    const [fileInputRef, setFileInputRef] = useState(self.crypto.getRandomValues(array)) /* eslint-disable-line no-restricted-globals */
    const [history, setHistory] = useState(null)

    const onChangeFile = (event) => {
        const file = event.target.files[0]
        if (file) {
            if (
                ![
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/wps-office.xlsx',
                ].includes(file?.type)
            ) {
                Swal.fire('Warning', 'the file format you uploaded is incorrect. Plase check your file', 'error')
                setFileTemp(null);
            } else {
                setFileTemp(file);
                handleReadFile(file)
                    .then((response) => {
                        let no_rpp = response[0].worksheet[5]
                        let hari_tanggal = response[0].worksheet[6]
                        let periode = response[0].worksheet[7]
                        let keterangan = response[0].worksheet[8]
                        let plant = response[0].worksheet[9]
                        const header = response[0].worksheet[10].map((item, i) => {
                            let head = `${item}`
                            if(response[0].worksheet[11][i] && response[0].worksheet[11][i] !== ''){
                                head = `${item} ${response[0].worksheet[11][i]}`
                            }
                            return head
                        });

                        const validateHeader = [
                            "No.",
                            "Bulan",
                            "Tanggal",
                            "Nama Produk",
                            "Kode Produk",
                            "Alokasi Produk",
                            "Jumlah Batch",
                            "Jumlah Kg",
                        ];

                        if(!no_rpp || no_rpp[0] !== 'No. RPP' || !no_rpp[3] || no_rpp[3] === ''){
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            no_rpp = no_rpp[3]
                        }

                        if(!hari_tanggal || hari_tanggal[0] !== 'Hari & Tanggal' || !hari_tanggal[3] || hari_tanggal[3] === ''){
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            hari_tanggal = hari_tanggal[3]
                        }

                        if(!periode || periode[0] !== 'Periode' || !periode[3] || periode[3] === ''){
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            periode = periode[3]
                        }

                        if(!keterangan || keterangan[0] !== 'Keterangan' || !keterangan[3] || keterangan[3] === ''){
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            keterangan = keterangan[3]
                        }

                        if(!plant || plant[0] !== 'Plant' || !plant[3] || plant[3] === ''){
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            plant = plant[3]
                        }

                        const checker = (arr, target) => target.every((v) => arr.includes(v));
                        if (!checker(header, validateHeader) || response[0].worksheet[11][0].length > 0) {
                            setFileTemp(null);
                            return Swal.fire(
                                'Information ',
                                'Please upload documents according to the template!',
                                'error',
                            );
                        } else {
                            let detail = (response[0].worksheet.slice(12, (response[0].worksheet.length-5))).map((item) => {
                                let dataItem = {}
                                validateHeader.forEach((x) => {
                                    const idx_header = header.indexOf(x)
                                    let label = x
                                    label = label.replace(/\s/g, '_')
                                    label = label.replace(/\./g, '')
                                    label = label.toLowerCase()
                                    dataItem[`${label}`] = item[idx_header]
                                })
                                return dataItem
                            })
                            const allDetail = {
                                rpp: detail
                            }

                            allDetail.no_rpp = no_rpp
                            allDetail.hari_tanggal = hari_tanggal
                            allDetail.periode = periode
                            allDetail.keterangan = keterangan
                            allDetail.plant = plant
                            setReadExcel(allDetail)
                            setFileTemp(file)
                        }
                    })
                    .catch((err) => {
                        Swal.fire('Warning', err, 'error');
                    });
                }
        } else {
            setReadExcel(null);
            setFileTemp(null);
        }
    }

    const handleReadFile = (file) => {
        return new Promise((resolve, reject) => {
			try {
				const fileReader = new FileReader();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const binaryString = e.target.result;
					const workbook = XLSX.read(binaryString, { type: 'buffer' });
					const result = workbook.SheetNames.map((item) => {
						const worksheet = workbook.Sheets[item];
						return {
							sheetName: item,
							worksheet: XLSX.utils.sheet_to_json(worksheet, {
								header: 1,
								defval: '',
								blankrows: false,
								raw: false,
							}),
						};
					});
					resolve(result);
				};
			} catch (e) {
				reject(new Error(e.message));
			}
		});
    }

    const handleDownload = async () => {
		try {
			const result = await downloadTemplate();
			const fileName = `TemplateImportRPP.xlsx`;
			FileDownload(result, fileName)
		} catch (e) {
			Swal.fire('Warning', e, 'error');
		}
	};

    const handleSubmit = async () => {
        try {
            const formData = new FormData()
            if (fileTemp) {
                formData.append('files', fileTemp);
            }
            formData.append('no_rpp', readExcel.no_rpp);
            formData.append('hari_tanggal', readExcel.hari_tanggal);
            formData.append('periode', readExcel.periode);
            formData.append('keterangan', readExcel.keterangan);
            formData.append('plant', readExcel.plant);
            formData.append('rpp', JSON.stringify(readExcel.rpp));
            formData.append('file_name', fileTemp.name);

            await insertImportRPP(formData)
                .then(() => {
                    setReadExcel(null)
                    setFileTemp(null)
                    setOpenModal(false)
                    Swal.fire('Success', 'Data saved successfully', 'success');
                })
        } catch (error) {
            Swal.fire('Warning', error, 'error');
        }
    }

    const fetchHistory = async () => {
        await getImportRpp()
            .then((res) => {
                setHistory(res)
            })
            .catch((err) => {
                Swal.fire('Warning', err, 'error');
            })
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    useEffect(() => {

    }, [fileTemp])

    return (
        <div>
            <section className='content'>
                <div className='container-fluid'>
                    <div className='gap-1'>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <div className='form-row from-group'>
                                            <label htmlFor='file'>Upload File Excel</label>
                                            <input 
                                                className='form-control form-control-sm'
                                                name='file'
                                                id="formFile"
                                                type='file'
                                                placeholder="Choose file ..."
                                                onChange={(e) => onChangeFile(e)}
                                                accept={SheetJSFT}
                                                // value={fileTemp?.filename || ""}
                                                key={fileInputRef || ""}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={5} md={2} className='align-self-end'>
                                        <Button
                                            variant="warning"
                                            onClick={() => {
                                                setFileTemp(null)
                                                setReadExcel(null)
                                            }}
                                            >
                                            <i className="fas fa-trash" aria-hidden='true'/> Reset
                                        </Button>
                                    </Col>
                                    <Col xs={8} md={{span: 3, offset: 1}} className='align-self-end text-right'>
                                        <Button
                                            variant="success"
                                            onClick={() => handleDownload()}
                                            >
                                            <i className="fas fa-download" aria-hidden='true'/> Download Template
                                        </Button>
                                    </Col>
                                    <Col xs={8} md={2} className='align-self-end text-right'>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowHistory(true)}
                                            >
                                            <i className="fas fa-history" aria-hidden='true'/> Import History
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title>Preview Data</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {!!readExcel ? (
                                    <div>
                                        <Row>
                                            <Col xs={5} md={3}>
                                                <p className='fw-semibold'>No. RPP</p>
                                            </Col>
                                            <Col xs={7} md={9}>
                                                <p>{readExcel?.no_rpp || '-'}</p>
                                            </Col>
                                            <Col xs={5} md={3}>
                                                <p className='fw-semibold'>Hari &amp; Tanggal</p>
                                            </Col>
                                            <Col xs={7} md={9}>
                                                <p>{readExcel?.hari_tanggal || '-'}</p>
                                            </Col>
                                            <Col xs={5} md={3}>
                                                <p className='fw-semibold'>Periode</p>
                                            </Col>
                                            <Col xs={7} md={9}>
                                                <p>{readExcel?.periode || '-'}</p>
                                            </Col>
                                            <Col xs={5} md={3}>
                                                <p className='fw-semibold'>Keterangan</p>
                                            </Col>
                                            <Col xs={7} md={9}>
                                                <p>{readExcel?.keterangan || '-'}</p>
                                            </Col>
                                            <Col xs={5} md={3}>
                                                <p className='fw-semibold'>Plant</p>
                                            </Col>
                                            <Col xs={7} md={9}>
                                                <p>{readExcel?.plant || '-'}</p>
                                            </Col>
                                        </Row>
                                        <div style={{maxHeight: '500px'}} className='mb-2'>
                                            <Table responsive hover size='md' className='border border-1 rounded rounded-3'>
                                                <thead>
                                                    <tr className='py-2'>
                                                        <th 
                                                            className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >No.</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Tanggal</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Hari</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Nama Produk</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Kode Produk</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Alokasi Produk</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Jumlah Batch</th>
                                                        <th className='bg-secondary text-light text-center align-middle'
                                                            style={{position: 'sticky', zIndex: 2, top: 0}}
                                                        >Jumlah KG</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {readExcel.rpp && readExcel.rpp.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td className='text-center align-middle'>{item.no}</td>
                                                                <td className='align-middle'>{moment(`${item.tanggal}-${item.bulan}`, 'D-MMM-YY').format('D-MMM-YY') || '-'}</td>
                                                                <td className='text-center align-middle'>{moment(`${item.tanggal}-${item.bulan}`, 'D-MMM-YY').format('dddd')}</td>
                                                                <td className='align-middle'>{item.nama_produk}</td>
                                                                <td className='align-middle'>{item.kode_produk}</td>
                                                                <td className='align-middle'>{item.alokasi_produk}</td>
                                                                <td className='text-center align-middle'>{item.jumlah_batch}</td>
                                                                <td className='text-center align-middle'>{item.jumlah_kg}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className='d-flex justify-content-end gap-2'>
                                            <Button
                                                variant='outline-danger'
                                                className='rounded rounded-3'
                                                onClick={() => {
                                                    setOpenModal(false)
                                                    setReadExcel(null)
                                                    setFileTemp(null)
                                                }}
                                            >Close</Button>
                                            <Button
                                                variant='info'
                                                className='rounded rounded-3'
                                                onClick={() => handleSubmit()}
                                            >Submit</Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <p className='fw-medium'>No Data Displayed</p>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <Modal
                        show={showHistory}
                        size="lg"
                        backdrop="static"
                        onHide={() => setShowHistory(false)}
                        centered
                    >
                        <ModalHeader closeButton className="bg-light">
                            <ModalTitle>Import History</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <ul className="timeline-list" style={{ padding: '0', listStyle: 'none' }}>
                                {!!history && 
                                history.map((x, index) => {
                                    return (
                                    <li key={index} className="timeline-item mb-3" style={{ position: 'relative', paddingLeft: '30px' }}>
                                        <span className="timeline-icon" style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '8px',
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#8de2c8',
                                        border: '2px solid #fff'
                                        }}></span>
                                        <div className="timeline-content">
                                        <strong>{x.file.filename || '-'}</strong>
                                        <br />
                                        <small>{moment(x.created_at).format('DD MMMM YYYY, HH:mm:ss')}</small>
                                        </div>
                                        <div className="timeline-line" style={{
                                        position: 'absolute',
                                        left: '5px',
                                        top: '20px',
                                        width: '2px',
                                        height: '100%',
                                        backgroundColor: '#e0e0e0'
                                        }}></div>
                                    </li>
                                    );
                                })
                                }
                            </ul>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </section>
        </div>
    )
}

export default ImportRPP;