import React, { Component, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import { getPowderPrice } from "../../modules/ninda/PowderPriceModule";
import Swal from "sweetalert2";
import moment from "moment";
import dateFormat from "dateformat";

const TableCustom = ({
  header,
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  fetchData,
}) => {
  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return fetchData(page, newPerPage);
  };
  const columns = useMemo(() => {
    return [
      {
        name: "Planning Month",
        selector: (row) => row.planning_month,
        sortable: true,
        center: true,
        width: "11%",
      },
      {
        name: "Material Code",
        selector: (row) => row.material_code,
        sortable: true,
        center: true,
        width: "10%",
      },
      {
        name: "Material Desc",
        selector: (row) => row.material_desc,
        sortable: true,
        width: "15%",
      },
      {
        name: header.length > 0 ? header[0] : "1st Month",
        selector: (row) => (row.price ? row.price[0] : ""),
        sortable: true,
      },
      {
        name: header.length > 0 ? header[1] : "2nd Month",
        selector: (row) => (row.price ? row.price[1] : ""),
        sortable: true,
      },
      {
        name: header.length > 0 ? header[2] : "3rd Month",
        selector: (row) => (row.price ? row.price[2] : ""),
        sortable: true,
      },
      {
        name: header.length > 0 ? header[3] : "4th Month",
        selector: (row) => (row.price ? row.price[3] : ""),
        sortable: true,
      },
      {
        name: header.length > 0 ? header[4] : "5th Month",
        selector: (row) => (row.price ? row.price[4] : ""),
        sortable: true,
      },
      {
        name: header.length > 0 ? header[5] : "6th Month",
        selector: (row) => (row.price ? row.price[5] : ""),
        sortable: true,
      },
    ];
  }, [data]);

  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  fetchData: PropTypes.func,
  header: PropTypes.instanceOf(Array),
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  fetchData: () => {},
  header: [],
};

class PowderPrice extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      planning_month: new Date(),
      powder_price_list: [],
      count_list: 0,
      sizePerPage: 10,
      loading_fetch_data: false,
      page: 1,
      header: [],
      powder_price: null,
    };
  }

  componentDidMount() {
    this.handleDate(this.state.planning_month);
    const { page, sizePerPage } = this.state;
    this.getDataPowderPrice(page, sizePerPage);
  }

  getDataPowderPrice = async (page, sizePerPage, search) => {
    const { planning_month } = this.state;
    this.setState({
      loading_fetch_data: true,
    });
    const query = {
      page: page,
      sizePerPage: sizePerPage,
      planning_month: dateFormat(planning_month, "yyyy-mm-01"),
      search: search,
    };
    getPowderPrice(query)
      .then((response) => {
        this.setState({
          powder_price_list: response.data,
          count_list: response.countData,
        });
      })
      .catch((err) => {
        this.setState({
          count_list: 0,
          powder_price_list: [],
        });
        Swal.fire("Error", err, "error");
      })
      .finally(() => {
        this.setState({ loading_fetch_data: false });
      });
  };
  handlePage = async (idx) => {
    return this.setState({
      page: idx,
    });
  };

  handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  };
  handleDate = (date) => {
    let header = [];
    for (let i = 0; i < 6; i++) {
      const month = moment(date).add(i, "months").format("MMMM yyyy");
      header.push(month);
    }

    this.setState({
      planning_month: date,
      header: header,
    });
  };

  handleSearch = () => {
    const { page, sizePerPage, powder_price } = this.state;
    this.getDataPowderPrice(page, sizePerPage, powder_price);
  };

  render() {
    let {
      planning_month,
      powder_price_list,
      count_list,
      page,
      sizePerPage,
      loading_fetch_data,
      header,
      powder_price,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card card-info mt-3">
                      <div className="card-header">
                        <h3 className="card-title">Filter Powder Price</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            Planning Month
                          </label>
                          <div className="input-group col-md-8">
                            <DatePicker
                              selected={planning_month}
                              startDate={planning_month}
                              disabledKeyboardNavigation
                              name="date"
                              onChange={this.handleDate.bind(this)}
                              wrapperClassName="datePickerCustom"
                              dateFormat="MMMM yyyy"
                              className="form-control"
                              required
                              showMonthYearPicker
                              autoComplete="off"
                            />
                            <span className="error invalid-feedback">
                              Please entries planning month
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            Powder Price
                          </label>
                          <div className="input-group col-md-8">
                            <input
                              name="powder_price"
                              value={powder_price || ""}
                              onChange={this.handleChange}
                              className="form-control form-control-sm"
                            />
                            <span className="error invalid-feedback">
                              Please entries
                            </span>
                          </div>
                        </div>
                        <div className="d-flex form-group justify-content-end">
                          <Button
                            variant="info"
                            className="col-md-3 mr-2 loading"
                            onClick={this.handleSearch}
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Powder Price List</h3>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          header={header}
                          data={powder_price_list}
                          totalRows={count_list}
                          perPage={sizePerPage}
                          curPage={page}
                          fetchData={this.getDataPowderPrice}
                          loading={loading_fetch_data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PowderPrice);
