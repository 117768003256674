import RNDSimulatorService from "../services/rnd_simulator.service";

export const listMaterial = (query) => {
    return RNDSimulatorService.list_material(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const simulateRND = (payload) => {
    return RNDSimulatorService.simulateRND(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};
