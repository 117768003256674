import ExchangeRateService from "../services/exchange_rate.service";
  
export const addExchangeRate = (payload)  => {
  return ExchangeRateService.create(payload).then(
    (response) => { 
      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
};

export const editExchangeRate = (payload)  => {
  return ExchangeRateService.edit(payload).then(
    (response) => { 
      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
 

      return Promise.reject(message);
    }
  );
};
export const deleteExchangeRate = (payload)  => {
  return ExchangeRateService.delete(payload).then(
    (response) => { 
      return Promise.resolve(response.data,response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 

      return Promise.reject(message);
    }
  );
}; 

export const readExchangeRate = (query_string)  => {
  return ExchangeRateService.readExchangeRate(query_string).then(
    (response) => {  

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 
      return Promise.reject(message);
    }
  );
};  