import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class FactorService{

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "factor", payload,{ headers: authHeader()});
  }
  overwrite(payload) {
    return axios
      .post(API_URL_DEFAULT + "factor/?type=overwrite", payload, { headers: authHeader()});
  }
  readFactor(query_string) {
    return axios
      .get(API_URL_DEFAULT + "factor/?"+query_string, { headers: authHeader()});
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "factor", payload, { headers: authHeader()});
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "factor", {data:payload, headers: authHeader()});
  }
}

export default new FactorService();
