import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class TollOutPriceService {

    readTollOutPrice(query_string) {
        return axios
            .get(API_URL_DEFAULT + "toll_out_price/?" + query_string, { headers: authHeader() });
    }
}

export default new TollOutPriceService();