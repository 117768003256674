import React, { Component } from "react";
import Swal from "sweetalert2";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import {
  readMaterialByMonth,
  read,
  ProcessMaterial,
} from "../modules/NotificationMrpRndModule";

import { startOfMonth } from "date-fns";
import NotificationMrpRndTable from "../components/NotificationMrpRndTable";
import NotificationMrpRndModalTable from "../components/NotificationMrpRndModalTable";
import { decrypt } from "../modules/CryptoModule";
import SelectionVersion from "../components/SelectionVersion";
import ProgressBar from "react-bootstrap/ProgressBar";
const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
const headerStyle = { backgroundColor: "#17A2B8", color: "white" };

class NotificationMrpRnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalSelectVersion: false,
      plant_version: null,
      select_version: [],
      recipe_version: null,
      material_select_version: null,
      indexMonth: null,
      material_code_version: null,
      allrecipe: [],
      loadingSpinner: false,

      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      columns: null,
      list_datatable: null,
      filter_by: null,
      search: null,
      searchSubmit: null,
      month: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "no_material",
      sortOrder: "asc",
      defaultSorted: [],
      cellEditProps: {},
      isSearchFound: true,
      errors_create: {},
      select_items_material: [],
      isValidMaterialCreate: true,
      isClearable: true,
      list_mrp_rnd: [],
      detail_selected: [],
      showModalProcess: false,
      data_ingredients: [],
      version_ingredients: "",
      material_code: null,
      group_id_ingredient: [],
      detail_selected_detail: [],
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    let query_material = `date=${dateFormat(new Date(), "yyyy-mm-01")}`;
    readMaterialByMonth(query_material).then((response) => {
      this.setState({ select_items_material: response });
    });

    read(query_material).then((response) => {
      this.setState({ list_datatable: response });
    });

    this.setState({
      month: startOfMonth(new Date()),
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  reloadDataBackend = () => {
    const { month, material_code } = this.state;

    let additional_query = "";
    if (material_code) {
      additional_query = `&material_code=${material_code}`;
    }
    let query_material = `date=${dateFormat(
      month,
      "yyyy-mm-dd"
    )}${additional_query}`;

    readMaterialByMonth(query_material).then((response) => {
      this.setState({ select_items_material: response });
    });

    read(query_material)
      .then((response) => {
        this.setState({ list_datatable: response });
      })
      .catch((e) => {
        this.setState({ list_datatable: [] });
      });
  };

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
    }
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };

  toggleModalProcess = () => {
    const { detail_selected } = this.state;
    this.setState({
      showModalProcess: !this.state.showModalProcess,
    });
  };

  onModalProcessHide = () => {
    this.setState({
      allrecipe: [],
      detail_selected: [],
      data_ingredients: [],
      version_ingredients: "",
      privateKey: null,
    });
    this.toggleModalProcess();
  };

  onChangeHandlerFile = (name, generateList, event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const text = this.handleFile(files[0], name, generateList);
    }
  };

  handleFile = (file, name, generateList) => {
    const reader = new FileReader();
    reader.onload = this.handleText.bind(
      this,
      name,
      reader.result,
      generateList
    );
    reader.readAsText(file);
  };

  handleText = (name, text, generateList, e) => {
    const checkName = {
      public_key_file: "publicKey",
      private_key_file: "privateKey",
    };
    const customName = checkName[name];
    this.setState(
      {
        [customName]: e.target.result,
        generateList: generateList,
      },
      () => {
        customName == "privateKey" && this.checkPrivateKey();
      }
    );
  };
  checkPrivateKey = () => {
    const { privateKey, recipe_encrypted, generateList } = this.state;
    if (privateKey && recipe_encrypted) {
      decrypt({
        privateKey: privateKey,
        encryptedData: recipe_encrypted,
      })
        .then((result) => {
          this.setState(
            {
              recipe: [...result],
            },
            () => {
              generateList && this.generateSelectVersionList();
            }
          );
        })
        .catch((err) => {
          if (this.refInputVersion) {
            this.refInputVersion.value = "";
          }
          this.setState(
            {
              select_items_version: [],
              version: null,
              data_schedule: [],
            },
            () => {
              Swal.fire("Cancelled!", "Wrong Private Key!.", "error");
            }
          );
        });
    }
  };
  generateSelectVersionList = () => {
    const { recipe } = this.state;
    let id_default = 0;
    let new_recipe = recipe.map((r, id) => {
      r.version = `Version ${r.version_no}${r.default ? " (default)" : ""}`;
      if (r.default) {
        id_default = id;
      }
      return r;
    });

    this.setState(
      {
        select_items_version: new_recipe,
        version: new_recipe[id_default].version,
      },
      () => {
        const ingredients = recipe[id_default].ingredients;
        let new_data_schedule = ingredients.map((ing) => {
          return {
            material_raw_code: ing.material_code,
            material_raw_description: ing.material_name,
            qty: ing.qty,
            uom: ing.uom,
          };
        });
        this.setState({
          data_schedule: new_data_schedule,
        });
      }
    );
  };

  handleSubmitPrivateKey = () => {
    const { privateKey, recipe_encrypted, detail_selected } = this.state;
    if (privateKey && recipe_encrypted) {
      decrypt({
        privateKey: privateKey,
        encryptedData: recipe_encrypted,
      })
        .then((result) => {
          const allrecipe = [...result];
          const defaultRecipe = allrecipe.find((ar) => ar.default);
          const setDefaultDtl = detail_selected.detail.map((ds) => {
            const needVa = defaultRecipe.ingredients.map((hi) => {
              return {
                need_value: (parseFloat(hi.qty) * ds.need_value) / 1000,
                production_date: ds.production_date,
                material_code: hi.material_code,
                material_name: hi.material_name,
                uom: hi.uom,
              };
            });
            return {
              ...ds,
              bom_version: defaultRecipe.version_no,
              recipe_detail: needVa,
            };
          });
          const dts = { ...detail_selected, detail: setDefaultDtl };
          this.setState({
            allrecipe: allrecipe,
            group_id_ingredient: detail_selected.group_id_ingredient,
            detail_selected: dts,
          });
        })
        .catch((err) => {
          Swal.fire("Cancelled", "Wrong Private Key!", "error");
        });
    } else {
      Swal.fire("Cancelled", "Cannot find Private Key", "error");
    }
  };

  //validate input
  validateForm = (month, date) => {
    let errors = this.state.errors;
    if (!month) {
      errors.month = "This field is required";
    }
    if (!date) {
      errors.date = "This field is required";
    }

    return errors;
  };

  //change state date
  handleDate = (name, date) => {
    let query_material = `date=${dateFormat(date, "yyyy-mm-dd")}`;
    readMaterialByMonth(query_material).then((response) => {
      this.setState({ select_items_material: response });
    });

    this.setState({
      [name]: date,
      isDownloadable: true,
    });
  };

  handleSearch = () => {
    this.setState({ loading: true });
    const { month, material_code } = this.state;
    let additional_query = "";
    if (material_code) {
      additional_query = `&material_code=${material_code}`;
    }
    let query_material = `date=${dateFormat(
      month,
      "yyyy-mm-dd"
    )}${additional_query}`;

    read(query_material).then((response) => {
      this.setState({ list_datatable: response });
    });

    read(query_material)
      .then((response) => {
        this.setState({ list_datatable: response });
      })
      .catch((e) => {
        this.setState({ list_datatable: [] });
      });
  };

  handleSubmitProcess = () => {
    const { allrecipe, requester, group_id_ingredient, detail_selected } =
      this.state;
    let collection = {
      requester: requester,
      detail: detail_selected.detail,
      group_id_ingredient: group_id_ingredient,
      origin_data: detail_selected.parent_data,
      powder_material: detail_selected.material_code,
      planning_date: detail_selected.planning_date,
    };
    if (allrecipe.length > 0 && group_id_ingredient.length > 0) {
      Swal.fire({
        title: "Are you sure ?",
        text: "Process this document ",
        icon: "info",
        confirmButtonText: "Yes, Process!",
        confirmButtonColor: "#589e3a",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.setState({
            loadingSpinner: true,
          });
          ProcessMaterial(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-process-mrp-rnd-success",
              });

              this.setState({
                loadingSpinner: false,
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.onModalProcessHide();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-process-mrp-rnd-failed",
              });
              this.setState({
                loading: false,
                loadingSpinner: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      Swal.fire({
        title: "Now Allowed!",
        text: "Please completed data",
        icon: "info",
      });
    }
  };

  onModalSelectVersion = () => {
    this.toggleVersion();
  };
  toggleVersion = () => {
    const { showModalSelectVersion } = this.state;
    this.setState({
      showModalSelectVersion: !showModalSelectVersion,
    });
  };
  onHandleChangeVersion = (version_no) => {
    this.setState({
      selected_version: version_no,
    });
  };
  handleSelection = (index, bom_version = null) => {
    const { allrecipe } = this.state;
    const selectRecipe = allrecipe.map((ar) => {
      return {
        label: `Version ${ar.version_no}`,
        value: ar.version_no,
      };
    });
    this.setState(
      {
        indexMonth: index,
        select_version: selectRecipe,
        recipe_version: allrecipe,
        selected_version: bom_version,
      },
      () => {
        this.toggleVersion();
      }
    );
  };
  handleChooseVersion = () => {
    const { detail_selected, selected_version, indexMonth, allrecipe } =
      this.state;
    const curRecipe = allrecipe.find((ar) => ar.version_no == selected_version);
    const curDetailSelected = detail_selected.detail[indexMonth];

    const needValue = curRecipe.ingredients.map((ig) => {
      return {
        need_value: (parseFloat(ig.qty) * curDetailSelected.need_value) / 1000,
        production_date: curDetailSelected.production_date,
        material_code: ig.material_code,
        material_name: ig.material_name,
        uom: ig.uom,
      };
    });
    const curdetail = {
      ...detail_selected.detail[indexMonth],
      bom_version: selected_version,
      recipe_detail: needValue,
    };
    const newDetailselected = [
      ...detail_selected.detail.slice(0, indexMonth),
      curdetail,
      ...detail_selected.detail.slice(indexMonth + 1),
    ];
    const dts = { ...detail_selected, detail: newDetailselected };

    this.setState(
      {
        detail_selected: dts,
      },
      () => {
        this.toggleVersion();
      }
    );
  };
  onModalLoadingHide = () => { };
  render() {
    let {
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      month,
      filter_by,
      errors_create,
      select_items_material,
      material_code,
      isValidMaterialCreate,
      isClearable,
      detail_selected,
      showModalProcess,
      data_ingredients,
      version_ingredients,
      detail_selected_detail,

      showModalSelectVersion,
      select_version,
      material_select_version,
      recipe_version,
      allrecipe,
      selected_version,

      loadingSpinner,
    } = this.state;

    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
              ? "#ddd"
              : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <Modal
              show={loadingSpinner}
              size="xl"
              backdrop="static"
              onHide={this.onModalLoadingHide}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              keyboard={false}
            >
              <ModalHeader className="bg-info text-white"></ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <ProgressBar animated now={100} />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
            <Modal
              show={showModalProcess}
              size="xl"
              backdrop="static"
              onHide={this.onModalProcessHide}
              dialogClassName="full-modal-dialog"
            >
              <form
                className={
                  submitted
                    ? "needs-validation was-validated"
                    : "needs-validation"
                }
                noValidate
              >
                <ModalHeader
                  closeButton
                  className="text-center bg-info text-white"
                >
                  <ModalTitle className="w-100 text-center">
                    Detail Material
                  </ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col xs={12} md={12}>
                      <div
                        className={
                          errors_create.plan_date != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="reason">
                          Material : {detail_selected.material_desc}
                        </label>
                      </div>
                      <hr
                        style={{
                          color: "#000000",
                          backgroundColor: "#000000",
                          height: 0.5,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      Needed
                    </Col>
                    <Col xs={12} md={12}>
                      <Table
                        bordered
                        hover
                        size="sm"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <thead className="still" style={headerStyle}>
                          <tr>
                            {detail_selected_detail.map((j) => (
                              <th>
                                {dateFormat(j.production_date, "mmmm yyyy")}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {detail_selected_detail.map((j) => (
                              <td>
                                {isNaN(parseFloat(j.need_value))
                                  ? ""
                                  : j.need_value.toFixed(DECIMAL_PLACES)}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "#000000",
                      backgroundColor: "#000000",
                      height: 0.5,
                    }}
                  ></hr>
                  <Row>
                    <Col xs={12} md={12}>
                      Origin
                    </Col>
                    <Col xs={12} md={12}>
                      {!detail_selected ? (
                        "Data Not Found"
                      ) : (
                        <NotificationMrpRndModalTable
                          data={detail_selected}
                          type={filter_by}
                          handleModal={(data) => {
                            this.setState({
                              detail_selected: data,
                            });
                          }}
                          props={this.props}
                        />
                      )}
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "#000000",
                      backgroundColor: "#000000",
                      height: 0.5,
                    }}
                  ></hr>
                  <Row>
                    <Col xs={12} md={12}>
                      Ingredients<br></br>
                      {allrecipe.length == 0 ? (
                        <span style={{ fontSize: 10 }}>
                          Upload private key to open inredients
                        </span>
                      ) : (
                        <span style={{ fontSize: 14 }}>
                          Choose version per month
                        </span>
                      )}
                    </Col>
                    <br></br>
                    {allrecipe.length == 0 ? (
                      <>
                        <Col xs={12} md={3}>
                          <input
                            type="file"
                            className="form-control form-control-xs"
                            name="file"
                            placeholder="File"
                            onChange={this.onChangeHandlerFile.bind(
                              this,
                              "private_key_file",
                              false
                            )}
                            accept={SheetJSFT}
                            required
                          />
                        </Col>

                        <Col xs={12} md={3}>
                          <Button
                            variant="primary"
                            onClick={this.handleSubmitPrivateKey}
                            className="float-left"
                          >
                            Open Recipe &amp; Choose Version
                          </Button>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    {allrecipe.length > 0 ? (
                      <Col xs={12} md={12}>
                        <Table
                          bordered
                          hover
                          size="sm"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <thead className="still" style={headerStyle}>
                            <tr>
                              {detail_selected.detail &&
                                detail_selected.detail.map((i, x) => (
                                  <th colSpan={3}>
                                    {dateFormat(i.production_date, "mmmm yyyy")}
                                    <br />
                                    {i.bom_version
                                      ? `Version ${i.bom_version}  `
                                      : ""}
                                    <Button
                                      variant="default"
                                      onClick={() =>
                                        this.handleSelection(x, i.bom_version)
                                      }
                                      className="btn btn-xs"
                                      key={`bpb${x}`}
                                    >
                                      <i className="fas fa-project-diagram" />
                                    </Button>
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {detail_selected.detail &&
                                detail_selected.detail.map((it, k) => {
                                  return (
                                    <td colSpan={3}>
                                      <tr>
                                        <th>Material Name</th>
                                        <th>Need Value</th>
                                        <th>UoM</th>
                                      </tr>
                                      {it.recipe_detail &&
                                        it.recipe_detail.map((rd) => (
                                          <tr>
                                            <td>{rd.material_name}</td>
                                            <td>{rd.need_value}</td>
                                            <td>{rd.uom ?? ""}</td>
                                          </tr>
                                        ))}{" "}
                                    </td>
                                  );
                                })}
                            </tr>{" "}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </ModalBody>
                {allrecipe.length > 0 ? (
                  <ModalFooter>
                    <>
                      <Button
                        variant="secondary"
                        onClick={this.onModalProcessHide}
                        className="float-left"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="success"
                        onClick={this.handleSubmitProcess}
                        className="float-right"
                      >
                        Process
                      </Button>
                    </>
                  </ModalFooter>
                ) : (
                  ""
                )}
              </form>
            </Modal>
            <Modal
              show={showModalSelectVersion}
              size="lg"
              backdrop="static"
              onHide={() => this.onModalSelectVersion()}
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  Select version of FG BOM {detail_selected.material_desc}
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <>
                  <Row>
                    <Col xs={12} md={12}>
                      <SelectionVersion
                        selected_version={selected_version}
                        select_version={select_version}
                        recipe_version={recipe_version}
                        handleChangeVersion={(version_no) =>
                          this.onHandleChangeVersion(version_no)
                        }
                        type="notif-rnd"
                      />
                    </Col>
                  </Row>
                </>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={this.handleChooseVersion}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              </ModalFooter>
            </Modal>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header">
                          <h3 className="card-title">Notification MRP RND</h3>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <label htmlFor="currency">Planning Month</label>
                              </div>
                            </Col>

                            <Col xs={12} md={3}>
                              <div
                                className={
                                  errors_create.month !=
                                    "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <DatePicker
                                  selected={month}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "month")}
                                  name="month"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                                {errors.month && (
                                  <div className="invalid-feedback">
                                    {errors.date}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <label htmlFor="currency">Material Code</label>
                              </div>
                            </Col>

                            <Col xs={12} md={3}>
                              <Select
                                styles={customStylesMaterialCreate}
                                onChange={this.handleChange.bind(
                                  this,
                                  "material"
                                )}
                                options={select_items_material}
                                value={select_items_material.filter(
                                  (e) => e.value === material_code
                                )}
                                isClearable={isClearable}
                                ref={(ref) => (this.refMaterial = ref)}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} md={3}>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                              >
                                <i className="fas fa-filter" /> Filter
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && list_datatable ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Summary &nbsp;</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <div>
                            {!list_datatable ? (
                              "Data Not Found"
                            ) : (
                              <NotificationMrpRndTable
                                data={list_datatable}
                                type={filter_by}
                                handleModal={(data) => {
                                  this.setState(
                                    {
                                      detail_selected: data,
                                      detail_selected_detail: data.detail,
                                      recipe_encrypted: data.recipe,
                                    },
                                    () => {
                                      this.toggleModalProcess();
                                    }
                                  );
                                }}
                                props={this.props}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      variant="primary"
                    />
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(NotificationMrpRnd);
