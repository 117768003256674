import React from "react";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";

const APSPO = ({
  data, //data that is inputted by user in each row
  columns, //column from the parent, each type of PR has its own column
  uom, //list uom to be picked user
  selectedUOM,
  month,
  type,
  onChange, //to return data to parrent
}) => {
  const handleTableRowAdd = () => {
    if (type === "ETA" && !month) {
      alert("Choose month first");
      return;
    }
    // copy last existing eta (if exist)
    let last_eta = data.at(-1);
    let eta = last_eta ? last_eta.eta : '';
    let quantity = last_eta ? last_eta.quantity : '';
    let uom = last_eta ? last_eta.uom : '';
    const array = new Uint32Array(10);

    data.push({ id: [...Array(24)].map(i=>(
       /* eslint-disable-next-line no-restricted-globals */
      ~~(self.crypto.getRandomValues(array)*36)).toString(36)).join(''), 
      eta: eta, quantity: quantity, uom: uom });
    onChange(data);
  };

  const handleTableRowRemove = () => {
    data.pop();
    onChange(data);
  };

  const handleRemoveRow = (index_eta) => {
    data.splice(index_eta, 1);
    onChange(data);
  };

  const handleChange = (val, type, idx) => {
    if (type === "po_number") {
      data[idx]["po_number"] = val;
    }
    if (type === "quantity") {
      data[idx]["quantity"] = val.target.value;
    }
    if (type === "uom") {
      data[idx]["uom"] = val;
    }
    if (type === "eta") {
      data[idx]["eta"] = val;
    }
    onChange(data);
  };

  return (
    <div>
      <Table bordered hover size="sm" style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th className="bg-info" style={{ width: "5%" }}>
              {" "}
              No.{" "}
            </th>
            {columns.map((item) => (
              <th key={item.text} className="bg-info">{item.text} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((itemData, idxData) => (
            <tr>
              <td> {idxData + 1} </td>
              {columns.map((item) => {
                if (item.type === "po_number") {
                  return (
                    <td className="col-sm-4">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={item.text}
                        value={itemData["po_number"] || ""}
                        onChange={(val) =>
                          handleChange(val.target.value, "po_number", idxData)
                        }
                        required
                      />
                    </td>
                  );
                }
                if (item.type === "quantity") {
                  return (
                    <td className="col-sm-1">
                      <input
                        type="number"
                        min={0}
                        step="any"
                        className="form-control form-control-md Input-number-hide-step"
                        placeholder={item.text}
                        value={itemData["quantity"] || ""}
                        onChange={(val) =>
                          handleChange(val, "quantity", idxData)
                        }
                        required
                      />
                    </td>
                  );
                }
                if (item.type === "uom") {
                  return (
                    <td className="col-sm-1">
                      <select
                        className="form-control form-control-md"
                        name="uom"
                        onChange={(val) =>
                          handleChange(val.target.value, "uom", idxData)
                        }
                        value={selectedUOM || ""}
                        required
                        readOnly={true}
                      >
                        <option value="">UOM</option>
                        {uom &&
                          uom.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.value}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                  );
                }
                if (item.type === "eta") {
                  return (
                    <td className="col-sm-1">
                      <DatePicker
                        selected={
                          itemData["eta"] ? new Date(itemData["eta"]) : null
                        }
                        minDate={
                          new Date().getMonth() === month.getMonth()
                            ? new Date()
                            : new Date(month.getFullYear(), month.getMonth(), 1)
                        }
                        maxDate={
                          new Date(month.getFullYear(), month.getMonth() + 1, 0)
                        }
                        disabledKeyboardNavigation
                        onChange={(val) => handleChange(val, "eta", idxData)}
                        name="eta"
                        wrapperClassName="datePickerCustom"
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-sm"
                        required
                      />
                    </td>
                  );
                }
                if (item.type === "del") {
                  return (
                    <td className="col-sm-1">
                      <i
                        className="fas fa-minus bg-danger"
                        style={{
                          color: "white",
                          padding: 5,
                          fontWeight: "bold",
                          borderTopRightRadius: 3,
                          borderBottomRightRadius: 3,
                        }}
                        onClick={() => handleRemoveRow(idxData)}
                      />
                    </td>
                  );
                }
                return '';
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      <div>
        <i
          className="fas fa-plus bg-success"
          style={{
            color: "white",
            padding: 5,
            fontWeight: "bold",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
          }}
          onClick={() => handleTableRowAdd()}
        />
      </div>
    </div>
  );
};

export default APSPO;
