import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { Link, withRouter } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { listMaterial, simulateRND } from "../modules/RNDSimulatorModule";


const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");
class RNDSimulator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isValidMaterial: true,
      material_code_option: [],
      material_code_default: [],
      material: [],
      list_material: [],
      temp_list_material: [],
      file_value: null,
      current_filename: null,
      planning_month: "",
      loading: false,
    };
  }

  componentDidMount() { }

  getMaterial = (inputValue) => {
    const getOptionMaterial = (search) => {
      let params = {
        planning_month: this.state.planning_month,
        search: search,
      };
      return listMaterial(new URLSearchParams(params));
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOptionMaterial(inputValue));
      }, 1000);
    });
  };

  onChangeValue = (name, value) => {
    this.setState({ [name]: value.target.value });
  };

  handleChange = (value, index) => {
    let temp_material = this.state.material;
    let checkMaterial = temp_material.filter(
      (e) => e.no_material_rm === value.value
    );
    if (checkMaterial.length > 0) {
      this.showToats(value.label + " Already exist in list");
    } else {
      const indexMonth = parseInt(this.state.production_month) - parseInt(dateFormat(this.state.planning_month, "m"))
      temp_material[index].material = value;
      temp_material[index].no_material_rm = value.value;
      temp_material[index].material_description_rm = value.label;
      temp_material[index].price =
        value.price_kg[indexMonth];
      this.setState({ material: temp_material });
    }
  };

  handleNewPrice = (value, index) => {
    let temp_material = this.state.material;
    temp_material[index].new_price = parseFloat(value.target.value);
    this.setState({ material: temp_material });
  };

  handleDate = (name, date) => {
    let params = {
      planning_month: dateFormat(date, "yyyy-mm-dd"),
    };
    this.setState({
      [name]: date,
      material: [],
      material_code_option: [],
      list_material: [],
      planning_month: dateFormat(date, "yyyy-mm-dd"),
    });

    listMaterial(new URLSearchParams(params))
      .then((res) => {
        this.setState({
          material_code_option: res,
        });
      })
      .catch((err) => {
        this.setState({
          material_code_option: [],
        });
      });
  };

  handleDateProduction = (name, date) => {
    this.setState({
      [name]: date,
      material: [],
      production_month: dateFormat(date, "m"),
    });
  };

  add = () => {
    if (this.state.date && this.state.production_date) {
      let temp_material = [
        ...this.state.material,
        {
          material: null,
          no_material_rm: null,
          material_description_rm: null,
          price: 0,
          new_price: 0,
        },
      ];
      this.setState({ material: temp_material });
    } else {
      let msg = "";
      if (!this.state.date) {
        msg += "Planning Month";
      }
      if (!this.state.production_date) {
        msg += " Production Month";
      }
      this.showToats("Please entries " + msg);
    }
  };

  showToats = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "toats",
      onClose: () => this.setState({ submitted: false }),
    });
  };

  handleSimulate = () => {
    let checkMaterial = this.state.material.filter((e) => !e.material);
    let checkPrice = this.state.material.filter(
      (e) => e.price === undefined || e.price === "" || e.price <= 0
    );
    let checkNewPrice = this.state.material.filter(
      (e) => e.new_price === undefined || e.new_price === "" || e.new_price <= 0
    );
    if (
      this.state.date &&
      this.state.production_date &&
      checkMaterial.length === 0 &&
      checkPrice.length === 0 &&
      checkNewPrice.length === 0 &&
      this.state.file_value &&
      this.state.material.length > 0
    ) {
      this.setState({ loading: true });
      let payload = {
        planning_month: this.state.planning_month,
        production_month: parseInt(this.state.production_month) - 1,
        material: this.state.material,
        private_key: this.state.file_value,
      };
      this.simulate(payload);
    } else {
      if (!this.state.date) {
        this.showToats("Please entries planning month");
      }
      if (!this.state.production_date) {
        this.showToats("Please entries production month");
      }
      if (!this.state.file_value) {
        this.showToats("Please entries private key");
      }
      if (this.state.material.length === 0) {
        this.showToats("Please entries material");
      }
      if (checkMaterial.length > 0) {
        this.showToats("Please entries material");
      }
      if (checkPrice.length > 0) {
        this.showToats("Please entries price");
      }
      if (checkNewPrice.length > 0) {
        this.showToats("Please entries new price");
      }
    }
  };

  handleReset = () => {
    const array = new Uint32Array(10);
    this.setState({
      date: null,
      production_date: null,
      current_filename: null,
      file_value: null,
      material: [],
      list_material: [],
      inputFile: self.crypto.getRandomValues(array),  /* eslint-disable-line no-restricted-globals */
    });
  };

  simulate = (payload) => {
    simulateRND(payload)
      .then((res) => {
        this.setState({ list_material: [...res], loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.showToats(err);
      });
  };

  handleDelete = (index) => {
    let temp_material = this.state.material;
    temp_material.splice(index, 1);
    if (this.state.list_material.length > 0) {
      if (temp_material.length === 0) {
        this.setState({ list_material: [] });
      } else {
        let payload = {
          material: this.state.material,
          private_key: this.state.file_value,
        };
        this.simulate(payload);
      }
    }
    this.setState({ material: temp_material });
  };

  onChangeHandlerFile = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
    }
  };

  handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = this.handleText.bind(reader.result, file);
    reader.readAsText(file);
  };

  handleText = (file, e) => {
    this.setState({
      file_value: e.target.result,
      current_filename: file.name,
    });
  };

  render() {
    let {
      loading,
      date,
      production_date,
      material_code_default,
      material_code_option,
      isValidMaterial,
      current_filename,
      inputFile
    } = this.state;

    const customStylesMaterial = {
      option: (provided) => ({
        ...provided,
        color: "black",
      }),
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
              ? "#ddd"
              : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Container
              style={{ backgroundColor: "#007bff", borderRadius: "5px" }}
            >
              <Row>
                <Col xs={12} md={12}>
                  <ProgressBar animated now={100} />
                </Col>
              </Row>
            </Container>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">RND Simulator</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        rnd simulator
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Adjustment</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4">
                            <div className="form-group">
                              <label
                                className="form-label"
                                style={{ marginBottom: "3px" }}
                              >
                                Planning Month
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  readOnly={this.state.material.length > 0}
                                  selected={date}
                                  startDate={date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "date")}
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control"
                                  required
                                  showMonthYearPicker
                                  autoComplete="off"
                                />
                                <span className="error invalid-feedback">
                                  Please entries planning month
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label
                                className="form-label"
                                style={{ marginBottom: "3px" }}
                              >
                                Production Month
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  readOnly={this.state.material.length > 0}
                                  selected={production_date}
                                  startDate={production_date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDateProduction.bind(
                                    this,
                                    "production_date"
                                  )}
                                  name="production_date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control"
                                  maxDate={
                                    new Date(
                                      new Date().getFullYear() + 5,
                                      5,
                                      30
                                    )
                                  }
                                  required
                                  showMonthYearPicker
                                  autoComplete="off"
                                />
                                <span className="error invalid-feedback">
                                  Please entries production month
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label
                                className="form-label"
                                style={{ marginBottom: "3px" }}
                              >
                                Upload Private Key
                              </label>
                              <div className="input-group">
                                <div
                                  className="custom-file"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    accept={SheetJSFT}
                                    onChange={this.onChangeHandlerFile.bind(
                                      this
                                    )}
                                    key={inputFile || ""}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {current_filename}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.material.map((item, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: "3px" }}
                                  >
                                    Material
                                  </label>
                                  <AsyncSelect
                                    ref={this.selectRef}
                                    isClearable={true}
                                    styles={customStylesMaterial}
                                    single
                                    cacheOptions
                                    defaultOptions={material_code_option}
                                    loadOptions={this.getMaterial}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                    value={item.material}
                                    thema="info"
                                    className="loading"
                                    defaultValue={material_code_default || ""}
                                    placeholder="Choose material"
                                  />
                                  <span className="error invalid-feedback">
                                    Please entries material
                                  </span>

                                  <div className="input-group" key={index}>
                                    <div className="col-md-12"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: "3px" }}
                                  >
                                    Price/kg
                                  </label>
                                  <div className="input-group" key={index}>
                                    <input
                                      value={item?.price.toFixed(DECIMAL_PLACES) || ""}
                                      type="text"
                                      className="form-control"
                                      readOnly
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: "3px" }}
                                  >
                                    Adjustment Price/kg
                                  </label>
                                  <div className="input-group" key={index}>
                                    <input
                                      onChange={(e) =>
                                        this.handleNewPrice(e, index)
                                      }
                                      defaultValue={item?.new_price || ""}
                                      type="number"
                                      className="form-control"
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                  <span className="error invalid-feedback">
                                    Please entries new price/kg
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-1">
                                <Button
                                  style={{ marginTop: "25px" }}
                                  variant="outline-danger"
                                  key={index}
                                  onClick={() => this.handleDelete(index)}
                                >
                                  <i className="fas fa-times" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                        <div className="mb-3">
                          <Button variant="outline-primary" onClick={this.add}>
                            <i className="fas fa-plus" />
                            &nbsp;Add
                          </Button>
                          <Button
                            style={{ marginLeft: "5px" }}
                            variant="outline-danger"
                            onClick={this.handleReset}
                          >
                            <i className="fas fa-sync" />
                            &nbsp;Reset
                          </Button>
                        </div>
                        <div className="d-flex">
                          <Button
                            className="col-md-12"
                            onClick={this.handleSimulate}
                            variant="info"
                          >
                            <i className="fas fa-calculator" /> Simulate
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Result</h3>
                      </div>
                      <div className="card-body">
                        <div className="col-12 row">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th rowSpan={2} className="rowspan-center">
                                  No.
                                </th>
                                <th rowSpan={2} className="rowspan-center">
                                  Material Code
                                </th>
                                <th rowSpan={2} className="rowspan-center">
                                  Material Description
                                </th>
                                <th
                                  colSpan={2}
                                  className="text-center"
                                  width="40%"
                                >
                                  Price
                                </th>
                              </tr>
                              <tr>
                                <th>Before</th>
                                <th>After</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.list_material.length > 0
                                ? this.state.list_material.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.material_code}</td>
                                    <td>{item.material_desc}</td>
                                    <td>{item?.price_before.toFixed(DECIMAL_PLACES) || ""}</td>
                                    <td>{item?.price_after.toFixed(DECIMAL_PLACES) || ""}</td>
                                  </tr>
                                ))
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(RNDSimulator);
