import React from "react";
import PropTypes from "prop-types";
function WorksheetCustom({ data, type }) {
  const getColumnName = (value) => {
    let ordA = "A".charCodeAt(0);
    let ordZ = "Z".charCodeAt(0);
    let len = ordZ - ordA + 1;

    var s = "";
    while (value >= 0) {
      s = String.fromCharCode((value % len) + ordA) + s;
      value = Math.floor(value / len) - 1;
    }

    return s;
  };

  const headerStyle = { backgroundColor: "yellow", color: "black" };

  return (
    <div className="card border-dark">
      <div
        className="card-body overflow-auto p-0"
        style={{ maxHeight: "350px" }}
      >
        <table className="table table-sm text-nowrap table-bordered">
          <thead>
            <tr>
              <th key={"th-"} style={{ textAlign: "center" }}>
                {""}
              </th>
              {data.length > 0 &&
                data[0].map((item, index) => (
                  <th key={"th".concat(index)} style={{ textAlign: "center" }}>
                    {getColumnName(index)}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item_row, indexRow) => (
              <tr
                key={"row".concat(indexRow)}
                style={(indexRow === 1 || indexRow === 2) && type == 'default' ? headerStyle : {}}
              >
                <td
                  key={"row".concat(indexRow, "col-")}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  {indexRow + 1}
                </td>
                {item_row.map((item_col, indexCol) => (
                  <td key={"row".concat(indexRow, "col", indexCol)}>
                    {item_col}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

WorksheetCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  type: PropTypes.string
};
WorksheetCustom.defaultProps = {
  data: [],
  type: 'default'
};

export default WorksheetCustom;
