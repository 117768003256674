import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const NotificationMrpTable = ({ data, type, handleModal, props }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const { history } = props;
  const handleProcess = (data) => {
    //insert to local storage
    localStorage.setItem("to_po_contract", JSON.stringify(data));
    history.push("/raw-materials/po-contract");
  };

  return data.length <= 0 ? (
    "Data Not Found"
  ) : (
    <div key="head">
      <Table
        bordered
        hover
        size="sm"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <thead className="still" style={headerStyle}>
          <tr>
            <th rowspan={2}>No</th>
            <th rowspan={2}>Material Code</th>
            <th rowspan={2}>Material Name</th>
            <th colspan={data[0].detail.length}>Quantity (Kg)</th>
            <th rowspan={2}>Action</th>
          </tr>
          <tr>
            {data[0].detail.map((i, x) => (
              <th>{dateFormat(i.production_date, "mmm yyyy")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr>
              <td>{idx + 1}</td>
              <td>{item.material_code}</td>
              <td>
                {item.material_desc.replace(`(${item.material_code})`, "")}
              </td>
              {item.detail.map((dtl, i) => (
                <td>
                  {isNaN(parseFloat(dtl.need_value))
                    ? ""
                    : dtl.need_value.toFixed(DECIMAL_PLACES)}
                </td>
              ))}
              <td>
                <Button
                  className="font-weight-bold"
                  variant="warning"
                  onClick={() => handleModal(data[idx])}
                >
                  <i class="fa fa-tasks mr-2" aria-hidden="true"></i>
                  Proccess
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NotificationMrpTable;
