import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BeginningRmBalanceService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "rm_balance", payload, {
      headers: authHeader(),
    });
  }
  createOverwrite(payload) {
    return axios.put(API_URL_DEFAULT + "rm_balance", payload, {
      headers: authHeader(),
    });
  }
  createBulk(payload) {
    return axios.post(API_URL_DEFAULT + "rm_balance/bulk", payload, {
      headers: authHeader(),
    });
  }
  readBeginningRmBalance(first, second) {
    return axios.get(
      API_URL_DEFAULT + "rm_balance/?first=" + first + "&second=" + second,
      { headers: authHeader() }
    );
  }
  readQsBeginningRmBalance(qs) {
    return axios.get(API_URL_DEFAULT + "rm_balance/?" + qs, {
      headers: authHeader(),
    });
  }

  readCurrentBeginningRmBalance(currency) {
    return axios.get(API_URL_DEFAULT + "rm_balance/?currency=" + currency, {
      headers: authHeader(),
    });
  }
  readActiveBeginningRmBalance() {
    return axios.get(API_URL_DEFAULT + "rm_balance/?active=active", {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "rm_balance", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "rm_balance", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new BeginningRmBalanceService();
