import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BOMPowderServiceEXP {
    create(payload) {
        return axios.post(API_URL_DEFAULT + "bom_powder_exp", payload, {
            headers: authHeader(),
        });
    }
    readBOMPowder(query_string) {
        return axios.get(API_URL_DEFAULT + "bom_powder_exp/?" + query_string, {
            headers: authHeader(),
        });
    }
    delete(payload) {
        return axios.delete(API_URL_DEFAULT + "bom_powder_exp", {
            data: payload,
            headers: authHeader(),
        });
    }
}

export default new BOMPowderServiceEXP();
