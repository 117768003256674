import MrpMixerInput from '../services/mrp-mixer-input.service'

export const addMrpMixerInput = (payload) => {
    return MrpMixerInput.create(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const editMrpMixerInput = (payload) => {
    return MrpMixerInput.edit(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();


            return Promise.reject(message);
        }
    );
};
export const deleteMrpMixerInput = (payload) => {
    return MrpMixerInput.delete(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const readMrpMixerInput = (payload) => {
    return MrpMixerInput.readMrpMixerInput(payload).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};
export const readActiveMrpLabel = (payload) => {
    return MrpMixerInput.readActiveMrpLabel(payload).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};
export const getActiveMrpLabel = (payload) => {
    return MrpMixerInput.getActiveMrpLabel(payload).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
};