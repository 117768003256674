import NotificationMrpService from "../services/notification_mrp.service";

export const readNotification = (query_string) => {
  return NotificationMrpService.readNotification(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
