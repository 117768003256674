import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import WorksheetCustom from "./WorksheetCustom";

const WorksheetTabCustom = ({ data, type }) => {
  return (
    <div>
      <Tabs
        defaultActiveKey={data[0]?.sheetName ?? ""}
        title={data[0]?.sheetName ?? "sheet"}
        variant="pills"
      >
        {data.map((sheet) => (
          <Tab
            eventKey={sheet.sheetName}
            title={sheet.sheetName}
            key={sheet.sheetName}
          >
            <div className="py-2">
              <WorksheetCustom data={sheet.worksheet} type={type} />
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

WorksheetTabCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  type: PropTypes.string,
};
WorksheetTabCustom.defaultProps = {
  data: [],
  type: "default",
};

export default WorksheetTabCustom;
