import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceList = ({
  data,
}) => {
  const headerStyle = { backgroundColor: '#17A2B8', color: 'white' };
  let month = data.month;
  let month_tmp = new Date(data.month);
  let last_month = month_tmp.setMonth(month_tmp.getMonth() + 5);
  return (
    <div key={data}>
      <Form>
        <Table bordered hover size="sm" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <thead className="still">
            <tr >
              <th rowSpan='2'>No</th>
              <th rowSpan='2'>Material Code</th>
              <th rowSpan='2'>Material Name</th>
              <th rowSpan='2'>Material Cons</th>
              <th rowSpan='2'>Material Description</th>
              <th rowSpan='2'>Plant</th>
              <th rowSpan='2'>Quantity</th>
              <th colSpan='6'>Price</th>
              <th colSpan='6'>Value</th>
            </tr>
            <tr>
              {data.month.map((m, idx) => (
                <th >{dateFormat(m, "mmm-yy")}</th>
              ))}
              {data.month.map((m, idx) => (
                <th >{dateFormat(m, "mmm-yy")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.material.map((item, idx) => (
              < tr >
                <td>{idx + 1}</td>
                <td>{item.material_code}</td>
                <td>{item.material_name}</td>
                <td>{item.no_material_rm}</td>
                <td>{item.material_description_rm}</td>
                <td>{item.type}</td>

                <td>{isNaN(parseFloat(item.value0)) ? '' : thousandformat((item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>

                <td>{isNaN(parseFloat(item.price0)) ? "" : thousandformat((item.price0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{isNaN(parseFloat(item.price1)) ? "" : thousandformat((item.price1).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{isNaN(parseFloat(item.price2)) ? "" : thousandformat((item.price2).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{isNaN(parseFloat(item.price3)) ? "" : thousandformat((item.price3).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{isNaN(parseFloat(item.price4)) ? "" : thousandformat((item.price4).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{isNaN(parseFloat(item.price5)) ? "" : thousandformat((item.price5).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>

                <td>{!isFinite(parseFloat(item.price0) * parseFloat(item.value0)) ? "" : thousandformat((item.price0 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{!isFinite(parseFloat(item.price1) * parseFloat(item.value0)) ? "" : thousandformat((item.price1 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{!isFinite(parseFloat(item.price2) * parseFloat(item.value0)) ? "" : thousandformat((item.price2 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{!isFinite(parseFloat(item.price3) * parseFloat(item.value0)) ? "" : thousandformat((item.price3 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{!isFinite(parseFloat(item.price4) * parseFloat(item.value0)) ? "" : thousandformat((item.price4 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>
                <td>{!isFinite(parseFloat(item.price5) * parseFloat(item.value0)) ? "" : thousandformat((item.price5 * item.value0).toFixed(DECIMAL_PLACES), ".", ".", ",")}</td>

              </tr>
            ))}

          </tbody>
        </Table>
      </Form>

    </div >
  )
};

export default PriceList;