import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "imported-salesforecast";

class ImportedSalesforecastService {
  read(query) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "?" + query, {
      headers: authHeader(),
    });
  }
  download(query) {
    return axios.get(API_URL_DEFAULT + API_MODULE + "/download?" + query, {
      headers: authHeader(),
    });
  }
}
export default new ImportedSalesforecastService();
