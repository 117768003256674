import React, { Component } from "react";
import Axios from "axios";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

import { withRouter } from "react-router-dom";

import {
  editMaterialExp,
  readAllMaterialExp,
} from "../modules/materialExpModule";

const TableCell = ({ value, name, onChange, index }) => (
  <input
    type="number"
    name={name}
    value={value}
    className="form-control form-control-sm"
    onChange={(event) => onChange(event, index)}
  />
);

class PriceEXP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      showModal: false,
      loading: false,

      errors: {},
      allData: [],
      user: [],
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user: user });

    this.toggleLoading();

    this.fetchData();

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  fetchData = () => {
    readAllMaterialExp()
      .then((result) => {
        this.setState({ allData: result });
        this.toggleLoading();
      })
      .catch((err) => {
        this.toggleLoading();
      });
  };
  // untuk update dan change setiap kolom
  handleChange = (event, index) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const data = [...prevState.allData];
      data[index][name] = value;
      data[index]["updated_by"] = this.state.user?.username;
      data[index]["updated"] = true;

      return { data };
    });
  };

  handleSubmit = (event, index) => {
    event.preventDefault();

    this.setState({ submitted: true });
    this.toggleLoading();

    const payload = {
      data: this.state.allData,
      requester: this.state.user?.username,
    };
    editMaterialExp(payload)
      .then((result) => {
        toast.success("Data has been updated successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.toggleLoading();
      })
      .catch((err) => {
        toast.error("Internal Server Error", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.toggleLoading();
      });
  };

  toggleLoading = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
    this.setState({
      loading: !this.state.loading,
    });
  };

  render() {
    let { submitted, loading, isCreate, download_file, allData } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody>
              <ProgressBar animated now={100} />
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-8">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Price EXP</h3>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={12} sm={12}>
                              <form>
                                <div className="col-md-12 mb-3">
                                  <Button
                                    variant="success"
                                    onClick={this.handleSubmit}
                                    className="float-right"
                                  >
                                    <i className="fas fa-save" /> Submit
                                  </Button>
                                </div>
                              </form>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col md={12} sm={12}>
                              <table className="table table-hover table-stripped">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Material Code</th>
                                    <th>Material Desc</th>
                                    <th>UoM</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {allData &&
                                    allData.map((item, index) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>{index + 1}</td>
                                          <td>{item.material_code}</td>
                                          <td>{item.material_desc}</td>
                                          <td>{item.uom}</td>
                                          <td>
                                            <TableCell
                                              value={item.price}
                                              name="price"
                                              onChange={this.handleChange}
                                              index={index}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(PriceEXP);
