import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import RemoteAll from "../components/RemoteAll";
import BOMPowderTable from "../components/BOMPowderTableEXP";
import {
  readBOMPowder,
  addBOMPowder,
  deleteBOMPowder,
} from "../modules/BOMPowderEXPModule";
import {
  readMaterialExcPowder,
  readMaterialPowderOnly,
  readMaterialUomByCode,
  readMaterialBySearch,
} from "../modules/MaterialModule";
import { readProdFacility } from "../modules/ProdFacilityModule";
import { readResource } from "../modules/ResourceModule";
import { readUom } from "../modules/UomModule";

import { sendNotification } from "../modules/NotificationModule";
import { generateKey, decrypt } from "../modules/CryptoModule";

const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");
class BOMPowderEXP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      showModalPublicKey: false,
      showModalPrivateKey: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isClearable: true,
      collection: {},
      errors: {},
      id: null,
      current_filename: null,
      template_weekly: [
        [
          "no_material",
          "powder",
          "week_1",
          "week_2",
          "week_3",
          "week_4",
          "week_5",
        ],
      ],
      filename: null,
      periode: null,
      excel: null,
      worksheet: [],
      requester: null,
      cols_length: null,
      search: null,
      filter_by: null,
      list_datatable: [],
      columns: [
        {
          dataField: "material_code",
          text: "Material Code",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_desc",
          text: "Material Name",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      list_datatable_weekly: [],
      page_weekly: 1,
      sizePerPage_weekly: 10,
      totalSize_weekly: 10,
      sortBy_weekly: "no_material",
      sizePerPage: 10,
      totalSize: 10,
      page: 1,
      sortOrder_weekly: "asc",
      columns_monthly: [
        {
          dataField: "no_material",
          text: "No Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "powder",
          text: "Powder",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "year",
          text: "Year",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "January",
          text: "January",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "February",
          text: "February",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "March",
          text: "March",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "April",
          text: "April",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "May",
          text: "May",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "June",
          text: "June",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "July",
          text: "July",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "August",
          text: "August",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "September",
          text: "September",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "October",
          text: "October",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "November",
          text: "November",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "December",
          text: "December",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
      ],
      list_datatable_monthly: [],
      page_monthly: 1,
      sortBy: "material_code",
      sortOrder: "asc",
      sizePerPage_monthly: 10,
      totalSize_monthly: 10,
      sortBy_monthly: "no_material",
      sortOrder_monthly: "asc",
      link_weekly: `${process.env.PUBLIC_URL}/weekly.xlsx`,
      link_monthly: `${process.env.PUBLIC_URL}/monthly.xlsx`,
      defaultSorted: [
        {
          dataField: "material_code",
          order: "asc",
        },
      ],
      cellEditProps: {},
      isCreateHidden: false,
      select_items_material: [],
      select_items_material_all: [],
      select_items_uom: [],
      select_items_plant: [
        { value: "1", label: "Plant 1" },
        { value: "2", label: "Plant 2" },
        { value: "mixer", label: "Mixer" },
      ],
      select_items_prod_facility: [],
      select_items_uom: [
        {
          value: "KG",
          label: "KG",
        },
      ],
      qty: 1000,
      uom: "KG",
      isValidMaterial: true,
      isValidMaterialEdit: true,
      material: null,
      material_code: null,
      electricity: null,
      unit_electricity: null,
      labor: null,
      unit_labor: null,
      machine: null,
      unit_machine: null,
      overhead: null,
      unit_overhead: null,
      isFacilPicked: false,
      machine_capacity: null,
      unit_machine_capacity: "kg/H",
      qty_edit: 1000,
      uom_edit: null,
      material_edit: null,
      material_code_edit: null,
      electricity_edit: null,
      unit_electricity_edit: null,
      labor_edit: null,
      unit_labor_edit: null,
      machine_edit: null,
      unit_machine_edit: null,
      overhead_edit: null,
      unit_overhead_edit: null,
      machine_capacity_edit: null,
      unit_machine_capacity_edit: "kg/H",
      prod_facility_edit: null,
      list_resource: [],
      list_resource_edit: [],
      recipe: [
        {
          default: true,
          version_no: 1,
          ingredients: [
            {
              material_code: "",
              material_name: "",
              qty: "",
              uom: "KG",
              plant: null,
            },
          ],
        },
      ],
      recipe_edit: [],
      recipe_encrypted: null,
      active_key_bom_tab: 0,
      publicKey: null,
      privateKey: null,
      toDelete: false,
      loadingSpinner: false,
      defaultOptions: [{ value: "", label: "" }],
    };
  }
  recipe_default = [
    {
      default: true,
      version_no: 1,
      ingredients: [
        { material_code: "", material_name: "", qty: "", uom: "", plant: null },
      ],
    },
  ];

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readMaterialPowderOnly().then((response) => {
      this.setState({ select_items_material: response });
    });

    readMaterialExcPowder().then((response) => {
      this.setState({ select_items_material_all: response });
    });

    readProdFacility().then((response) => {
      let tmp_select_facility = [];
      response.map((x) => {
        if (x.facility_type == "RND") {
          tmp_select_facility.push(x);
        }
      });
      this.setState({ select_items_prod_facility: tmp_select_facility });

      if (tmp_select_facility.length == 1) {
        const { machine_capacity, qty } = this.state;
        let query_string = `facility_code=${tmp_select_facility[0].facility_code}`;
        readResource(query_string).then((response) => {
          let res_electricity = response.find(
            (o) => o.resource_desc == "ELECTRICITY"
          );
          let res_labor = response.find((o) => o.resource_desc == "LABOR");
          let res_machine = response.find((o) => o.resource_desc == "MACHINE");
          let res_overhead = response.find(
            (o) => o.resource_desc == "OVERHEAD"
          );
          if (
            !this.validateResource(
              res_electricity,
              res_labor,
              res_machine,
              res_overhead
            )
          ) {
            return;
          }

          let usage = isFinite(qty / machine_capacity)
            ? qty / machine_capacity
            : 0;

          let electricity = res_electricity
            ? usage * res_electricity.resource_qty
            : "";
          let labor = res_labor ? usage * res_labor.resource_qty : "";
          let machine = res_machine ? usage * res_machine.resource_qty : "";
          let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

          this.setState({
            list_resource: response,
            prod_facility: tmp_select_facility[0].facility_code,
            desc_facility: tmp_select_facility[0].facility_desc,
            type_facility: tmp_select_facility[0].facility_type,
            no_facility: tmp_select_facility[0].facility_number,
            unit_electricity: res_electricity ? res_electricity.uom : "",
            unit_labor: res_labor ? res_labor.uom : "",
            unit_machine: res_machine ? res_machine.uom : "",
            unit_overhead: res_overhead ? res_overhead.uom : "",
            isFacilPicked: true,
            electricity: isNaN(parseFloat(electricity))
              ? ""
              : electricity.toFixed(2),
            labor: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
            machine: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
            overhead: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
            machine_capacity: machine_capacity,
          });
        });
      }
    });

    readBOMPowder(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  validateResource = (electricity, labor, machine, overhead) => {
    let errors = this.state.errors;

    if (!electricity) {
      errors.electricity = "Master electricity resource not found";
    } else delete errors.electricity;
    if (!labor) {
      errors.labor = "Master labor resource not found";
    } else delete errors.labor;
    if (!machine) {
      errors.machine = "Master machine resource not found";
    } else delete errors.machine;
    if (!overhead) {
      errors.overhead = "Master overhead resource not found";
    } else delete errors.overhead;
    return errors;
  };

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    if (name == "machine_capacity") {
      this.calculateResource(value);
    } else if (name == "machine_capacity_edit") {
      this.calculateResourceEdit(value);
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  onChangeProdFacility = (any) => {
    let index = any.target.selectedIndex;
    const { select_items_prod_facility, machine_capacity, qty } = this.state;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      let query_string = `facility_code=${prod_facility.facility_code}`;
      readResource(query_string).then((response) => {
        let res_electricity = response.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = response.find((o) => o.resource_desc == "LABOR");
        let res_machine = response.find((o) => o.resource_desc == "MACHINE");
        let res_overhead = response.find((o) => o.resource_desc == "OVERHEAD");
        if (
          !this.validateResource(
            res_electricity,
            res_labor,
            res_machine,
            res_overhead
          )
        ) {
          return;
        }

        let usage = isFinite(qty / machine_capacity)
          ? qty / machine_capacity
          : 0;

        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : "";
        let labor = res_labor ? usage * res_labor.resource_qty : "";
        let machine = res_machine ? usage * res_machine.resource_qty : "";
        let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

        this.setState({
          list_resource: response,
          prod_facility: prod_facility.facility_code,
          desc_facility: prod_facility.facility_desc,
          type_facility: prod_facility.facility_type,
          no_facility: prod_facility.facility_number,
          unit_electricity: res_electricity ? res_electricity.uom : "",
          unit_labor: res_labor ? res_labor.uom : "",
          unit_machine: res_machine ? res_machine.uom : "",
          unit_overhead: res_overhead ? res_overhead.uom : "",
          isFacilPicked: true,
          electricity: isNaN(parseFloat(electricity))
            ? ""
            : electricity.toFixed(2),
          labor: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
          machine: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
          overhead: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
          machine_capacity: machine_capacity,
        });
      });
    } else {
      this.setState({
        list_resource: [],
        prod_facility: null,
        desc_facility: null,
        type_facility: null,
        no_facility: null,
        unit_electricity: null,
        unit_labor: null,
        unit_machine: null,
        unit_overhead: null,
        isFacilPicked: false,
        electricity: null,
        labor: null,
        machine: null,
        overhead: null,
      });
    }
  };
  onChangeProdFacilityEdit = (any) => {
    let index = any.target.selectedIndex;
    const { select_items_prod_facility, machine_capacity_edit, qty_edit } =
      this.state;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      let query_string = `facility_code=${prod_facility.facility_code}`;
      readResource(query_string).then((response) => {
        let res_electricity = response.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = response.find((o) => o.resource_desc == "LABOR");
        let res_machine = response.find((o) => o.resource_desc == "MACHINE");
        let res_overhead = response.find((o) => o.resource_desc == "OVERHEAD");
        if (
          !this.validateResource(
            res_electricity,
            res_labor,
            res_machine,
            res_overhead
          )
        ) {
          return;
        }

        let usage = isFinite(qty_edit / machine_capacity_edit)
          ? qty_edit / machine_capacity_edit
          : 0;

        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : parseInt("");
        let labor = res_labor ? usage * res_labor.resource_qty : parseInt("");
        let machine = res_machine
          ? usage * res_machine.resource_qty
          : parseInt("");
        let overhead = res_overhead
          ? usage * res_overhead.resource_qty
          : parseInt("");

        this.setState({
          list_resource_edit: response,
          prod_facility_edit: prod_facility.facility_code,
          desc_facility_edit: prod_facility.facility_desc,
          type_facility_edit: prod_facility.facility_type,
          no_facility_edit: prod_facility.facility_number,
          unit_electricity_edit: res_electricity ? res_electricity.uom : "",
          unit_labor_edit: res_labor ? res_labor.uom : "",
          unit_machine_edit: res_machine ? res_machine.uom : "",
          unit_overhead_edit: res_overhead ? res_overhead.uom : "",
          electricity_edit: isNaN(parseFloat(electricity))
            ? ""
            : electricity.toFixed(2),
          labor_edit: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
          machine_edit: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
          overhead_edit: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
          machine_capacity_edit: machine_capacity_edit,
        });
      });
    } else {
      this.setState({
        list_resource_edit: [],
        prod_facility: null,
        desc_facility: null,
        type_facility: null,
        no_facility: null,
        unit_gas: null,
        unit_electricity: null,
        unit_labor: null,
        unit_machine: null,
        unit_overhead: null,
        isFacilPicked: false,
        gas: null,
        electricity: null,
        labor: null,
        machine: null,
        overhead: null,
      });
    }
  };
  onChangeHandlerFile = (name, event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const text = this.handleFile(files[0], name);
    }
  };
  handleFile = (file, name) => {
    const reader = new FileReader();
    reader.onload = this.handleText.bind(this, name, reader.result, file);
    reader.readAsText(file);
  };
  handleText = (name, text, file, e) => {
    const checkName = {
      public_key_file: "publicKey",
      private_key_file: "privateKey",
    };
    const customName = checkName[name];
    this.setState({
      [customName]: e.target.result,
      current_filename: file.name,
    });
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{isNaN(cell) ? "" : thousandformat(cell, ".", ".", ",")}</div>;
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetail = (
    id,
    material_code,
    material_desc,
    qty,
    uom,
    facility_code,
    machine_capacity,
    electricity,
    unit_electricity,
    labor,
    unit_labor,
    machine,
    unit_machine,
    overhead,
    unit_overhead,
    recipe,
    readOnly,
    facility_desc,
    facility_type,
    facility_no
  ) => {
    this.toggleSpinner();
    let query_string = `facility_code=${facility_code}`;
    readResource(query_string).then((response) => {
      this.setState(
        {
          list_resource_edit: response,
          id: id,
          material_code_edit: material_code,
          material_edit: material_desc,
          qty_edit: qty,
          uom_edit: uom,
          prod_facility_edit: facility_code,
          machine_capacity_edit: machine_capacity,
          electricity_edit: electricity,
          unit_electricity_edit: unit_electricity,
          labor_edit: labor,
          unit_labor_edit: unit_labor,
          machine_edit: machine,
          unit_machine_edit: unit_machine,
          overhead_edit: overhead,
          unit_overhead_edit: unit_overhead,
          recipe_encrypted: recipe,
          readOnly: readOnly,
          toUpdate: !readOnly,
          submitted: false,
          desc_facility_edit: facility_desc,
          type_facility_edit: facility_type,
          no_facility_edit: facility_no,
        },
        () => {
          this.toggleSpinner();
          this.toggleModalPrivateKey();
        }
      );
    });
  };

  deleteData = (id) => {
    const { history } = this.props;
    const { requester, department } = this.state;

    let collection = {
      _id: id,
      requester: requester,
      department: department,
    };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteBOMPowder(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/master-data/bom-powder-exp");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetailBOMFG = (
    id,
    no_material,
    material_description,
    data,
    cols,
    prod_facility,
    description_facility,
    machine_capacity,
    unit_machine_capacity,
    qty,
    uom,
    material_classification
  ) => {
    const worksheet_detail = [
      {
        worksheet: no_material + "_" + material_description,
        data: data,
        cols: this.state.cols_view,
        special_case: {
          description_facility: description_facility,
          machine_capacity: machine_capacity,
          material_description_fg: material_description,
          no_material_fg: no_material,
          prod_facility: prod_facility,
          unit_machine_capacity: unit_machine_capacity,
          qty: qty,
          uom: uom,
          classification: material_classification,
        },
      },
    ];

    this.setState(
      {
        id: id,
        worksheet_detail: worksheet_detail,
        submitted: false,
        no_material: no_material,
        material_description: material_description,
        prod_facility_dtl: prod_facility,
        description_facility_dtl: description_facility,
        machine_capacity_dtl: machine_capacity,
        unit_machine_capacity_dtl: unit_machine_capacity,
        classification_edit: material_classification,
      },
      () => {
        this.toggle();
      }
    );
  };

  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetail(
              row._id,
              row.material_code,
              row.material_desc,
              row.qty,
              row.uom,
              row.facility_code,
              row.machine_capacity,
              row.electricity,
              row.electricity_unit,
              row.labor,
              row.labor_unit,
              row.machine,
              row.machine_unit,
              row.overhead,
              row.overhead_unit,
              row.recipe,
              true,
              row.facility_desc,
              row.facility_type,
              row.facility_no
            )
          }
        >
          <i className="fas fa-th" key={row._id}></i>
        </button>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.toDetail(
                row._id,
                row.material_code,
                row.material_desc,
                row.qty,
                row.uom,
                row.facility_code,
                row.machine_capacity,
                row.electricity,
                row.electricity_unit,
                row.labor,
                row.labor_unit,
                row.machine,
                row.machine_unit,
                row.overhead,
                row.overhead_unit,
                row.recipe,
                false,
                row.facility_desc,
                row.facility_type,
                row.facility_no
              );
            }}
          >
            <i className="fas fa-edit" key={row._id}></i>
          </button>
        )}
        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => {
              this.setState(
                { id: row._id, toDelete: true, recipe_encrypted: row.recipe },
                () => this.toggleModalPrivateKey()
              );
            }}
          >
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };

  onChangeHandlerFile_old = (event) => {
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile_old = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });
      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (data, cols, start_row = 0, allowed_cols = []) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== start_row && cleanArray.length > 2) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }
    return { header: header, detail: detail };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */

    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };

  exportFile = () => {
    /* convert state to workbook */
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date();
    let sheet = monthNames[date.getMonth()] + "_" + date.getFullYear();

    const data = this.state.template_weekly;
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "weekly.xlsx");
  };
  //toggle modal

  handleChange = (name, newValue) => {
    if (newValue) {
      if (name === "classification") {
        this.setState({
          isValidClassification: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name == "material") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            uom: response[0].value,
            isValidMaterial: true,
            [name]: newValue.label,
            [name + "_code"]: newValue.value,
          });
        });
      } else if (name == "material_edit") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            uom_edit: response[0].value,
            isValidMaterialEdit: true,
            [name]: newValue.label,
            [name + "_code"]: newValue.value,
          });
        });
      }
    } else {
      if (name == "material") {
        this.setState({
          [name]: null,
          [name + "_code"]: null,
          isValidMaterial: false,
        });
      } else if (name == "material_edit") {
        this.setState({
          [name]: null,
          [name + "_code"]: null,
          isValidMaterialEdit: false,
        });
      }

      if (name === "classification") {
        this.setState({
          isValidClassification: false,
          [name]: null,
          [name + "_code"]: null,
        });
      }
    }
  };

  handleGenerate = () => {
    generateKey().then((res) => {
      this.downloadTxtFile(res.publicKey, "publicKey");
      this.downloadTxtFile(res.privateKey, "privateKey");
    });
  };

  generateTimestamp = () => {
    const date = dateFormat(new Date(), "yyyymmdd-HHMMss");
    return date;
  };
  downloadTxtFile = (data, name) => {
    const timestamp = this.generateTimestamp();
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${name}--${timestamp}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toggleModalPublicKey = () => {
    this.setState({
      showModalPublicKey: !this.state.showModalPublicKey,
    });
  };

  toggleModalPrivateKey = () => {
    this.setState({
      showModalPrivateKey: !this.state.showModalPrivateKey,
    });
  };
  toggleSpinner = () => {
    this.setState({
      loadingSpinner: !this.state.loadingSpinner,
    });
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      material_code_edit: null,
      material_desc_edit: null,
      qty_edit: 1000,
      uom_edit: null,
      prod_facility_edit: null,
      machine_capacity_edit: null,
      electricity_edit: null,
      unit_electricity_edit: null,
      labor_edit: null,
      unit_labor_edit: null,
      machine_edit: null,
      unit_machine_edit: null,
      overhead_edit: null,
      unit_overhead: null,
      recipe_edit: [],
      readOnly: false,
      publicKey: null,
      privateKey: null,
      showModal: !this.state.showModal,
    });
  };

  onModalPublicKeyHide = () => {
    this.setState({
      showModalPublicKey: !this.state.showModalPublicKey,
      publicKey: null,
    });
  };

  onModalPrivateKeyHide = () => {
    this.setState({
      showModalPrivateKey: !this.state.showModalPrivateKey,
      privateKey: null,
    });
  };
  //capitalize first word

  //validate input
  validateForm = (
    material_code,
    material_desc,
    prod_facility,
    machine_capacity,
    recipe
  ) => {
    let errors = this.state.errors;

    if (!material_code) {
      errors.material_code = "This is field required";
    } else {
      delete errors.material_code;
    }
    if (!material_desc) {
      errors.material_desc = "This is field required";
    } else {
      delete errors.material_desc;
    }

    if (!prod_facility) {
      errors.prod_facility = "This field is required";
    } else {
      delete errors.prod_facility;
    }

    if (!machine_capacity) {
      errors.machine_capacity = "This field is required";
    } else {
      delete errors.machine_capacity;
    }

    if (recipe) {
      for (let ver of recipe) {
        for (let ing of ver.ingredients) {
          if (
            ing.material_name == "" ||
            ing.plant == "" ||
            ing.uom == "" ||
            ing.qty == "" ||
            ing.plant == ""
          ) {
            errors.recipe = "Please check your recipe";
            break;
          } else {
            delete errors.recipe;
          }
        }
        const checkQPB = this.checkValidQtyperBatch(
          ver.qty_per_batch_p1,
          ver.qty_per_batch_p2,
          ver.qty_per_batch_mixer
        );
        if (checkQPB) {
          delete errors.qty_per_batch;
        } else {
          errors.qty_per_batch = "Please check your qty per batch";
          break;
        }
      }
    } else {
      errors.recipe = "Please check your recipe";
    }

    return errors;
  };
  checkValidQtyperBatch = (p1, p2, mix) => {
    if (p1 && p2 && !mix) return true;
    if (mix && !p1 && !p2) return true;
    return false;
  };
  validateFormEdit = (material, prod_facility, machine_capacity, recipe) => {
    let errors = this.state.errors;
    if (!material) {
      errors.material_edit = "This field is required";
      this.setState({
        isValidMaterialEdit: false,
      });
    }

    if (!prod_facility) {
      errors.prod_facility_edit = "This field is required";
    }

    if (!machine_capacity) {
      errors.machine_capacity_edit = "This field is required";
    }

    if (recipe) {
      for (let ver of recipe) {
        for (let ing of ver.ingredients) {
          if (Object.values(ing).every((x) => x == null || x == "")) {
            errors.recipe_edit = "Please check your recipe";
            break;
          } else {
            delete errors.recipe;
          }
        }
        const checkQPB = this.checkValidQtyperBatch(
          ver.qty_per_batch_p1,
          ver.qty_per_batch_p2,
          ver.qty_per_batch_mixer
        );
        if (checkQPB) {
          delete errors.qty_per_batch_edit;
        } else {
          errors.qty_per_batch_edit = "Please check your qty per batch";
          break;
        }
      }
    } else {
      errors.recipe_edit = "Please check your recipe";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    this.form.reset();
    readMaterialPowderOnly().then((response) => {
      this.setState({ select_items_material: response });
    });

    readMaterialExcPowder().then((response) => {
      this.setState({ select_items_material_all: response });
    });

    readProdFacility().then((response) => {
      let tmp_select_facility = [];
      response.map((x) => {
        if (x.facility_type == "RND") {
          tmp_select_facility.push(x);
        }
      });
      this.setState({ select_items_prod_facility: tmp_select_facility });

      if (tmp_select_facility.length == 1) {
        const { qty } = this.state;
        const machine_capacity = 0;
        let query_string = `facility_code=${tmp_select_facility[0].facility_code}`;
        readResource(query_string).then((response) => {
          let res_electricity = response.find(
            (o) => o.resource_desc == "ELECTRICITY"
          );
          let res_labor = response.find((o) => o.resource_desc == "LABOR");
          let res_machine = response.find((o) => o.resource_desc == "MACHINE");
          let res_overhead = response.find(
            (o) => o.resource_desc == "OVERHEAD"
          );
          if (
            !this.validateResource(
              res_electricity,
              res_labor,
              res_machine,
              res_overhead
            )
          ) {
            return;
          }

          let usage = isFinite(qty / machine_capacity)
            ? qty / machine_capacity
            : 0;

          let electricity = res_electricity
            ? usage * res_electricity.resource_qty
            : "";
          let labor = res_labor ? usage * res_labor.resource_qty : "";
          let machine = res_machine ? usage * res_machine.resource_qty : "";
          let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

          this.setState({
            list_resource: response,
            prod_facility: tmp_select_facility[0].facility_code,
            desc_facility: tmp_select_facility[0].facility_desc,
            type_facility: tmp_select_facility[0].facility_type,
            no_facility: tmp_select_facility[0].facility_number,
            unit_electricity: res_electricity ? res_electricity.uom : "KWH",
            unit_labor: res_labor ? res_labor.uom : "KG/HR",
            unit_machine: res_machine ? res_machine.uom : "KG/HR",
            unit_overhead: res_overhead ? res_overhead.uom : "KG/HR",
            isFacilPicked: true,
            electricity: isNaN(parseFloat(electricity))
              ? "0.00"
              : electricity.toFixed(2),
            labor: isNaN(parseFloat(labor)) ? "0.00" : labor.toFixed(2),
            machine: isNaN(parseFloat(machine)) ? "0.00" : machine.toFixed(2),
            overhead: isNaN(parseFloat(overhead))
              ? "0.00"
              : overhead.toFixed(2),
            machine_capacity: machine_capacity,
          });
        });
      }
    });
    readBOMPowder(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        machine_capacity: null,
        material: null,
        material_code: null,
        material_desc: null,
        prod_facility: null,
        electricity: null,
        unit_electricity: null,
        labor: null,
        unit_labor: null,
        machine: null,
        unit_machine: null,
        overhead: null,
        unit_overhead: null,
        toDelete: false,
        toUpdate: false,
        id: null,
        recipe_encrypted: null,
        recipe: [
          {
            default: true,
            version_no: 1,
            ingredients: [
              {
                material_code: "",
                material_name: "",
                qty: "",
                uom: "",
                plant: null,
              },
            ],
          },
        ],
        recipe_edit: [
          {
            default: true,
            version_no: 1,
            ingredients: [
              {
                material_code: "",
                material_name: "",
                qty: "",
                uom: "",
                plant: null,
              },
            ],
          },
        ],
        submitted: false,
        current_filename: null,
        errors: {},
      });
    });
  };
  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "powder_bom",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      material_code,
      material_desc,
      prod_facility,
      machine_capacity,
      recipe,
      errors,
    } = this.state;
    if (
      !this.validateForm(
        material_code,
        material_desc,
        prod_facility,
        machine_capacity,
        recipe
      )
    ) {
      return;
    }
    if (
      requester &&
      department &&
      material_code &&
      material_desc &&
      prod_facility &&
      machine_capacity &&
      !errors.electricity &&
      !errors.labor &&
      !errors.machine &&
      !errors.overhead &&
      !errors.recipe &&
      !errors.qty_per_batch
    ) {
      this.toggleModalPublicKey();
    } else {
      Swal.fire("Cancelled", "Please check your input", "error");
    }
  };

  handleSubmitEdit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      material_code_edit,
      prod_facility_edit,
      machine_capacity_edit,
      recipe_edit,
      errors,
    } = this.state;
    if (
      !this.validateFormEdit(
        material_code_edit,
        prod_facility_edit,
        machine_capacity_edit,
        recipe_edit
      )
    ) {
      return;
    }

    if (
      requester &&
      department &&
      material_code_edit &&
      prod_facility_edit &&
      machine_capacity_edit &&
      !errors.electricity_edit &&
      !errors.labor_edit &&
      !errors.machine_edit &&
      !errors.overhead_edit &&
      !errors.recipe_edit &&
      !errors.qty_per_batch_edit
    ) {
      this.toggleModalPublicKey();
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSubmitPrivateKey = () => {
    const { privateKey, recipe_encrypted, toDelete, id } = this.state;
    if (privateKey && recipe_encrypted) {
      decrypt({ privateKey: privateKey, encryptedData: recipe_encrypted })
        .then((result) => {
          this.setState(
            {
              recipe_edit: [...result],
            },
            () => {
              this.toggleModalPrivateKey();
              toDelete ? this.deleteData(id) : this.toggle();
            }
          );
        })
        .catch((err) => {
          Swal.fire("Cancelled", "Wrong Private Key!", "error");
        });
    } else {
      Swal.fire("Cancelled", "Cannot find Private Key", "error");
    }
  };

  handleSubmitPublicKey = () => {
    const { history } = this.props;
    const {
      requester,
      department,
      material,
      material_code,
      material_desc,
      qty,
      uom,
      prod_facility,
      desc_facility,
      type_facility,
      no_facility,
      machine_capacity,
      electricity,
      labor,
      machine,
      overhead,
      unit_machine_capacity,
      unit_electricity,
      unit_labor,
      unit_machine,
      unit_overhead,
      recipe,
      material_edit,
      material_code_edit,
      qty_edit,
      uom_edit,
      prod_facility_edit,
      desc_facility_edit,
      type_facility_edit,
      no_facility_edit,
      machine_capacity_edit,
      electricity_edit,
      labor_edit,
      machine_edit,
      overhead_edit,
      unit_machine_capacity_edit,
      unit_electricity_edit,
      unit_labor_edit,
      unit_machine_edit,
      unit_overhead_edit,
      recipe_edit,
      publicKey,
      toUpdate,
      current_filename,
      id,
    } = this.state;
    if (!current_filename) {
      return Swal.fire("Information!", "Please upload file properly", "error");
    }
    if (!current_filename.match("^publicKey+--[0-9]+-[0-9]+.*$")) {
      return Swal.fire("Information!", "Please upload file properly", "error");
    }
    let collection = {
      requester: requester,
      department: department,
      material_desc: toUpdate ? material_edit : material_desc,
      material_code: toUpdate ? material_code_edit : material_code,
      qty: toUpdate ? qty_edit : qty,
      uom: toUpdate ? uom_edit : uom,
      facility_code: toUpdate ? prod_facility_edit : prod_facility,
      facility_desc: toUpdate ? desc_facility_edit : desc_facility,
      facility_type: toUpdate ? type_facility_edit : type_facility,
      facility_no: toUpdate ? no_facility_edit : no_facility,
      electricity: toUpdate ? electricity_edit : electricity,
      electricity_unit: toUpdate ? unit_electricity_edit : unit_electricity,
      labor: toUpdate ? labor_edit : labor,
      labor_unit: toUpdate ? unit_labor_edit : unit_labor,
      machine_capacity: toUpdate ? machine_capacity_edit : machine_capacity,
      machine: toUpdate ? machine_edit : machine,
      machine_unit: toUpdate ? unit_machine_edit : unit_machine,
      overhead: toUpdate ? overhead_edit : overhead,
      overhead_unit: toUpdate ? unit_overhead_edit : unit_overhead,
      machine_capacity_unit: toUpdate
        ? unit_machine_capacity_edit
        : unit_machine_capacity,
      recipe: toUpdate ? recipe_edit : recipe,
      public_key: publicKey,
    };
    if (toUpdate) {
      collection.id = id;
    }
    Swal.fire({
      title: "Are you sure you want to use this key?",
      text: "If you lost the key, you cannot view/edit/delete the data",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        addBOMPowder(collection)
          .then((response) => {
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-add-bom-powder-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              //read data exchange rate on backend
              history.push("/master-data/bom-powder-exp");
              toUpdate && this.toggle();
              this.toggleModalPublicKey();
              this.reloadDataBackend();
              window.location.reload();
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-add-bom-powder-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
    });

    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      no_material: "material_code",
      material_desc: "material_desc",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }

    let query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }

    readBOMPowder(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  handleSearch = () => {
    const { search, sortBy, sortOrder, page, sizePerPage } = this.state;

    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&search=${search}`;
    readBOMPowder(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  calculateResource = (machine_capacity) => {
    const { qty, list_resource } = this.state;
    let res_electricity = list_resource.find(
      (o) => o.resource_desc == "ELECTRICITY"
    );
    let res_labor = list_resource.find((o) => o.resource_desc == "LABOR");
    let res_machine = list_resource.find((o) => o.resource_desc == "MACHINE");
    let res_overhead = list_resource.find((o) => o.resource_desc == "OVERHEAD");

    let usage = isFinite(qty / machine_capacity) ? qty / machine_capacity : 0;

    let electricity = res_electricity
      ? usage * res_electricity.resource_qty
      : "";
    let labor = res_labor ? usage * res_labor.resource_qty : "";
    let machine = res_machine ? usage * res_machine.resource_qty : "";
    let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

    this.setState({
      machine_capacity: machine_capacity,
      electricity: isNaN(parseFloat(electricity)) ? "" : electricity.toFixed(2),
      labor: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
      machine: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
      overhead: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
    });
  };

  calculateResourceEdit = (machine_capacity) => {
    const { qty_edit, list_resource_edit } = this.state;
    let res_electricity = list_resource_edit.find(
      (o) => o.resource_desc == "ELECTRICITY"
    );
    let res_labor = list_resource_edit.find((o) => o.resource_desc == "LABOR");
    let res_machine = list_resource_edit.find(
      (o) => o.resource_desc == "MACHINE"
    );
    let res_overhead = list_resource_edit.find(
      (o) => o.resource_desc == "OVERHEAD"
    );

    let usage = isFinite(qty_edit / machine_capacity)
      ? qty_edit / machine_capacity
      : 0;

    let electricity = res_electricity
      ? usage * res_electricity.resource_qty
      : parseInt("");
    let labor = res_labor ? usage * res_labor.resource_qty : parseInt("");
    let machine = res_machine ? usage * res_machine.resource_qty : parseInt("");
    let overhead = res_overhead
      ? usage * res_overhead.resource_qty
      : parseInt("");

    this.setState({
      machine_capacity_edit: machine_capacity,
      electricity_edit: isNaN(parseFloat(electricity))
        ? ""
        : electricity.toFixed(2),
      labor_edit: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
      machine_edit: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
      overhead_edit: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
    });
  };

  onChangeBomPowderTable = (data, type) => {
    this.setState({
      [type]: [...data],
    });
  };

  getDefaultOptions = () => {
    const { recipe_edit } = this.state;
    let option = [];
    recipe_edit.map((recipe) => {
      recipe.ingredients.map((ing) => {
        option.push({
          value: ing.material_code,
          label: ing.material_name,
        });
      });
    });
    return option;
  };

  getMaterial = (input) => {
    const queryString = input
      ? `excPowder=true&sizePerPage=100&search=${input}`
      : `excPowder=true&sizePerPage=100`;
    const result = readMaterialBySearch(queryString).then((res) => {
      return res;
    });
    return result;
  };

  handleSelectBomPowder = (val, idx, idxIng, type, data, type_data) => {
    if (type == "material") {
      if (val.label !== "") {
        readMaterialUomByCode(val.value).then((response) => {
          data[idx].ingredients[idxIng].material_code = val.value;
          data[idx].ingredients[idxIng].material_name = val.label;
          data[idx].ingredients[idxIng].uom = response[0].value;
          if (response[0].material_type == "ZFGD") {
            data[idx].ingredients[idxIng].plant = "";
          } else {
            data[idx].ingredients[idxIng].plant = null;
          }
          this.setState({
            [type_data]: [...data],
          });
        });
      } else {
        data[idx].ingredients[idxIng].material_code = "";
        data[idx].ingredients[idxIng].material_name = "";
        data[idx].ingredients[idxIng].uom = "";
        this.setState({
          [type_data]: [...data],
        });
      }
    }

    if (type === "uom") {
      data[idx].ingredients[idxIng].uom = val;
      this.setState({
        [type_data]: [...data],
      });
    }

    if (type === "plant") {
      data[idx].ingredients[idxIng].plant = val;
      this.setState({
        [type_data]: [...data],
      });
    }
  };

  handleInputBomPowder = (val, idx, idxIng, type, data, type_data) => {
    if (type == "material") {
      data[idx].ingredients[idxIng].material_name = val;
      data[idx].ingredients[idxIng].uom = "";
      if (val == "") {
        data[idx].ingredients[idxIng].material_code = "";
      } else {
        data[idx].ingredients[idxIng].material_code = null;
        data[idx].ingredients[idxIng].plant = null;
      }
      this.setState({
        [type_data]: [...data],
      });
    }
    if (type == "qty") {
      data[idx].ingredients[idxIng].qty = val;
      this.setState({
        [type_data]: [...data],
      });
    }

    if (
      type == "qty_per_batch_p1" ||
      type == "qty_per_batch_p2" ||
      type == "qty_per_batch_mixer"
    ) {
      const findFac = data.find((it, z) => z == idx);
      const findFacIndex = data.findIndex((it, z) => z == idx);
      if (typeof findFac != "undefined") {
        const modifFac = {
          ...findFac,
          [type]: val,
        };
        const newData = [
          ...data.slice(0, findFacIndex),
          modifFac,
          ...data.slice(findFacIndex + 1),
        ];
        this.setState({
          [type_data]: newData,
        });
      }
    }
    if (type == "switch") {
      data[idx].ingredients[idxIng].is_exp = val;
      if (!val) {
        data[idx].ingredients[idxIng].material_exp = null;
        data[idx].ingredients[idxIng].material_code = "";
        data[idx].ingredients[idxIng].material_name = "";
      } else {
        data[idx].ingredients[idxIng].material_code = null;
        data[idx].ingredients[idxIng].material_name = "";
        data[idx].ingredients[idxIng].plant = null;
      }
      this.setState({
        [type_data]: [...data],
      });
    }
    if (type == "material_exp") {
      data[idx].ingredients[idxIng].material_exp = val;
      this.setState({
        [type_data]: [...data],
      });
    }
  };

  render() {
    let {
      disableButtonNotification,
      seconds,
      showModal,
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      cellEditProps,
      search,
      select_items_material,
      isValidMaterial,
      select_items_uom,
      qty,
      uom,
      select_items_prod_facility,
      prod_facility,
      electricity,
      unit_electricity,
      labor,
      unit_labor,
      machine,
      unit_machine,
      overhead,
      unit_overhead,
      isFacilPicked,
      machine_capacity,
      unit_machine_capacity,
      recipe,
      select_items_material_all,
      showModalPublicKey,
      columns,
      page,
      defaultSorted,
      sizePerPage,
      totalSize,
      readOnly,
      electricity_edit,
      unit_electricity_edit,
      qty_edit,
      labor_edit,
      unit_labor_edit,
      prod_facility_edit,
      machine_edit,
      unit_machine_edit,
      machine_capacity_edit,
      unit_machine_capacity_edit,
      overhead_edit,
      unit_overhead_edit,
      recipe_edit,
      showModalPrivateKey,
      uom_edit,
      publicKey,
      privateKey,
      loadingSpinner,
      isValidMaterialEdit,
      material_code,
      material_desc,
      material_edit,
      material_code_edit,
    } = this.state;

    const Line = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 3,
          borderRadius: 2,
        }}
      />
    );

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loadingSpinner}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalPublicKey}
            backdrop="static"
            onHide={this.onModalPublicKeyHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <Modal.Title>Choose Public Key</Modal.Title>
            </ModalHeader>
            <ModalBody>
              <Container>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  name="file"
                  placeholder="File"
                  onChange={this.onChangeHandlerFile.bind(
                    this,
                    "public_key_file"
                  )}
                  accept={SheetJSFT}
                  required
                />
              </Container>
            </ModalBody>
            {publicKey && (
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={this.handleSubmitPublicKey}
                  size="sm"
                  className="float-right  "
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              </ModalFooter>
            )}
          </Modal>
          <Modal
            show={showModalPrivateKey}
            backdrop="static"
            onHide={this.onModalPrivateKeyHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <Modal.Title>Choose Private Key</Modal.Title>
            </ModalHeader>
            <ModalBody>
              <Container>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  name="file"
                  placeholder="File"
                  onChange={this.onChangeHandlerFile.bind(
                    this,
                    "private_key_file"
                  )}
                  accept={SheetJSFT}
                  required
                />
              </Container>
            </ModalBody>
            {privateKey && (
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={this.handleSubmitPrivateKey}
                  className="float-right"
                  size="sm"
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              </ModalFooter>
            )}
          </Modal>
          <Modal
            show={showModal}
            backdrop="static"
            onHide={this.onModalHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <Modal.Title>
                {readOnly ? "Detail" : "Update"} BOM Powder EXP
              </Modal.Title>
            </ModalHeader>
            <ModalBody>
              <Container>
                <form
                  autoComplete="off"
                  className={
                    submitted
                      ? "needs-validation was-validated"
                      : "needs-validation"
                  }
                  noValidate
                  ref={(form) => (this.form = form)}
                >
                  <Row>
                    <Col xs={12} md={1}>
                      <label htmlFor="material">Material</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div
                        className={
                          errors.material_code_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_code_edit"
                          placeholder="No. Material"
                          required="required"
                          value={material_code_edit || ""}
                          onChange={this.onChangeValue}
                        />
                        {errors.material_code_edit && (
                          <div className="invalid-feedback">
                            {errors.material_code_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.material_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_edit"
                          placeholder="Material Desc"
                          required="required"
                          value={material_edit || ""}
                          onChange={this.onChangeValue}
                        />
                        {errors.material_edit && (
                          <div className="invalid-feedback">
                            {errors.material_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="electricity">Electricity</label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.electricity_edit != "This field is required"
                            ? "form-group input-group"
                            : "form-group input-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="electricity_edit"
                          placeholder="Electricity"
                          required="required"
                          value={electricity_edit || ""}
                          onChange={this.onChangeValue}
                          min="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {unit_electricity_edit || ""}
                          </span>
                        </div>
                      </div>
                      {errors.electricity_edit && (
                        <div className="text-danger">
                          {errors.electricity_edit}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col xs={12} md={1}>
                      <label htmlFor="qty_edit">Qty</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div
                        className={
                          errors.qty != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="qty_edit"
                          placeholder="Qty"
                          required="required"
                          value={qty_edit || ""}
                          readOnly={true}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.qty_edit && (
                          <div className="invalid-feedback">
                            {errors.qty_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="currency">UOM</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div
                        className={
                          errors.uom_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <select
                          className={
                            errors.uom_edit != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="uom_edit"
                          onChange={this.onChangeValue}
                          defaultValue={uom_edit}
                          required
                          ref={(ref) => (this.refUnit = ref)}
                          readOnly={true}
                        >
                          <option value="">UOM</option>
                          {select_items_uom &&
                            select_items_uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.uom_edit && (
                          <div className="invalid-feedback">
                            {errors.uom_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="labor_edit">Labor</label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.labor_edit != "This field is required"
                            ? "form-group input-group"
                            : "form-group input-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="labor_edit"
                          placeholder="Labor"
                          required="required"
                          value={labor_edit || ""}
                          onChange={this.onChangeValue}
                          min="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {unit_labor_edit || ""}
                          </span>
                        </div>
                      </div>
                      {errors.labor_edit && (
                        <div className="text-danger">{errors.labor_edit}</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={1}>
                      <label htmlFor="prod_facility">Prod Facility</label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.prod_facility_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <select
                          className={
                            errors.prod_facility_edit !=
                            "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="prod_facility_edit"
                          onChange={this.onChangeProdFacilityEdit}
                          style={{ padding: 0 }}
                          defaultValue={prod_facility_edit}
                          required
                          readOnly
                        >
                          <option value="">Choose</option>
                          {select_items_prod_facility &&
                            select_items_prod_facility.map(function (item, i) {
                              return (
                                <option value={item.facility_code} key={i}>
                                  {item.facility_code} - {item.facility_desc}
                                </option>
                              );
                            })}
                        </select>

                        {errors.prod_facility && (
                          <div className="invalid-feedback">
                            {errors.prod_facility}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="labor">Machine</label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.machine_edit != "This field is required"
                            ? "form-group input-group"
                            : "form-group input-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="machine_edit"
                          placeholder="Machine"
                          required="required"
                          value={machine_edit || ""}
                          onChange={this.onChangeValue}
                          min="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {unit_machine_edit || ""}
                          </span>
                        </div>
                      </div>
                      {errors.machine_edit && (
                        <div className="text-danger">{errors.machine_edit}</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={1}>
                      <label htmlFor="machine_capacity_edit">
                        Machine Capacity
                      </label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.machine_capacity_edit !=
                          "This field is required"
                            ? "form-group input-group"
                            : "form-group input-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="machine_capacity_edit"
                          placeholder="Machine Capacity"
                          required="required"
                          value={machine_capacity_edit || ""}
                          onChange={this.onChangeValue}
                          readOnly={readOnly}
                          min="0"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {unit_machine_capacity_edit || ""}
                          </span>
                        </div>
                        {errors.machine_capacity_edit && (
                          <div className="invalid-feedback">
                            {errors.machine_capacity_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="overhead">Overhead</label>
                    </Col>
                    <Col xs={12} md={5}>
                      <div
                        className={
                          errors.overhead != "This field is required"
                            ? "form-group input-group"
                            : "form-group input-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="overhead_edit"
                          placeholder="Overhead"
                          required="required"
                          value={overhead_edit || ""}
                          onChange={this.onChangeValue}
                          min="0"
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {unit_overhead_edit || ""}
                          </span>
                        </div>
                      </div>
                      {errors.overhead_edit && (
                        <div className="text-danger">
                          {errors.overhead_edit}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Line color={"#cccccc"} />
                    </Col>
                  </Row>
                </form>
                <Row>
                  <Col xs={12} md={12}>
                    <BOMPowderTable
                      data={recipe_edit}
                      type_data="recipe_edit"
                      materials={select_items_material_all}
                      uom={select_items_uom}
                      readOnly={readOnly}
                      loadOptions={this.getMaterial}
                      handleSelect={this.handleSelectBomPowder}
                      handleInput={this.handleInputBomPowder}
                      defaultOptions={true}
                      onChange={(data) =>
                        this.onChangeBomPowderTable(data, "recipe_edit")
                      }
                      ref={(ref) => (this.refBomPowder = ref)}
                      list_plant={this.state.select_items_plant}
                      isCreate={false}
                    />
                    {errors.recipe_edit && (
                      <div className="text-danger font-weight-bold">
                        {errors.recipe_edit}
                      </div>
                    )}
                    {errors.qty_per_batch_edit && (
                      <div className="text-danger font-weight-bold">
                        {errors.qty_per_batch_edit}
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              {!readOnly && (
                <Button
                  variant="success"
                  onClick={this.handleSubmitEdit}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Update
                </Button>
              )}
            </ModalFooter>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Button
                        style={{
                          position: "absolute",
                          zIndex: 999,
                          alignSelf: "flex-end",
                          top: 7,
                          right: 10,
                        }}
                        variant="warning"
                        onClick={this.handleGenerate}
                      >
                        <i className="fa fa-key" aria-hidden="true" /> Generate
                        Key
                      </Button>
                      <Accordion.Toggle
                        className="bg-info card-title"
                        as={Card.Header}
                        eventKey="0"
                        onClick={() =>
                          this.setState({
                            isCreateHidden: !this.state.isCreateHidden,
                          })
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          BOM Powder EXP
                          <div
                            className="d-flex align-items-center"
                            style={{ marginRight: 130 }}
                          >
                            {this.state.isCreateHidden ? (
                              <i
                                className="fa fa-plus float-right align-middle"
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="fa fa-minus float-right align-middle"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {" "}
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={1}>
                                <label htmlFor="material">Material</label>
                              </Col>
                              <Col xs={12} md={2}>
                                <div
                                  className={
                                    errors.material_code !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="material_code"
                                    placeholder="No. Material"
                                    required="required"
                                    value={material_code || ""}
                                    onChange={this.onChangeValue}
                                  />
                                  {errors.material_code && (
                                    <div className="invalid-feedback">
                                      {errors.material_code}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div
                                  className={
                                    errors.material_desc !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="material_desc"
                                    placeholder="Material Desc"
                                    required="required"
                                    value={material_desc || ""}
                                    onChange={this.onChangeValue}
                                  />
                                  {errors.material_desc && (
                                    <div className="invalid-feedback">
                                      {errors.material_desc}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="electricity">Electricity</label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.electricity !=
                                    "This field is required"
                                      ? "form-group input-group"
                                      : "form-group input-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="electricity"
                                    placeholder="Electricity"
                                    required="required"
                                    value={electricity || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text form-control form-control-sm"
                                      id="basic-addon2"
                                      placeholder="UOM"
                                      readOnly={true}
                                    >
                                      {unit_electricity || ""}
                                    </span>
                                  </div>
                                </div>
                                {errors.electricity && (
                                  <div className="text-danger">
                                    {errors.electricity}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              {" "}
                              <Col xs={12} md={1}>
                                <label htmlFor="qty">Qty</label>
                              </Col>
                              <Col xs={12} md={2}>
                                <div
                                  className={
                                    errors.qty != "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="qty"
                                    placeholder="Qty"
                                    required="required"
                                    value={qty || ""}
                                    readOnly={true}
                                    onChange={this.onChangeValue}
                                    min="0"
                                  />
                                  {errors.qty && (
                                    <div className="invalid-feedback">
                                      {errors.qty}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="currency">UOM</label>
                              </Col>
                              <Col xs={12} md={2}>
                                <div
                                  className={
                                    errors.uom != "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <select
                                    className={
                                      errors.uom != "This field is required"
                                        ? "form-control form-control-sm "
                                        : "form-control form-control-sm  has-error has-feedback"
                                    }
                                    name="uom"
                                    onChange={this.onChangeValue}
                                    defaultValue={uom}
                                    required
                                    ref={(ref) => (this.refUnit = ref)}
                                    readOnly
                                  >
                                    <option value="">Choose</option>
                                    {select_items_uom &&
                                      select_items_uom.map(function (item, i) {
                                        return (
                                          <option value={item.value} key={i}>
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors.uom && (
                                    <div className="invalid-feedback">
                                      {errors.uom}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="labor">Labor</label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.labor != "This field is required"
                                      ? "form-group input-group"
                                      : "form-group input-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="labor"
                                    placeholder="Labor"
                                    required="required"
                                    value={labor || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text form-control form-control-sm"
                                      id="basic-addon2"
                                      placeholder="Labor"
                                      readOnly={true}
                                    >
                                      {unit_labor || ""}
                                    </span>
                                  </div>
                                </div>
                                {errors.labor && (
                                  <div className="text-danger">
                                    {errors.labor}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={1}>
                                <label htmlFor="prod_facility">
                                  Prod Facility
                                </label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.prod_facility_ !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <select
                                    className={
                                      errors.prod_facility !=
                                      "This field is required"
                                        ? "form-control form-control-sm  "
                                        : "form-control form-control-sm  has-error has-feedback"
                                    }
                                    name="prod_facility"
                                    onChange={this.onChangeProdFacility}
                                    style={{ padding: 0 }}
                                    defaultValue={prod_facility}
                                    required
                                    readOnly
                                  >
                                    {select_items_prod_facility &&
                                      select_items_prod_facility.map(function (
                                        item,
                                        i
                                      ) {
                                        return (
                                          <option
                                            value={item.facility_code}
                                            key={i}
                                          >
                                            {item.facility_code} -{" "}
                                            {item.facility_desc}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors.prod_facility && (
                                    <div className="invalid-feedback">
                                      {errors.prod_facility}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="labor">Machine</label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.machine != "This field is required"
                                      ? "form-group input-group"
                                      : "form-group input-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="machine"
                                    placeholder="Machine"
                                    required="required"
                                    value={machine || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text form-control form-control-sm"
                                      id="basic-addon2"
                                      placeholder="Machine"
                                      readOnly={true}
                                    >
                                      {unit_machine || ""}
                                    </span>
                                  </div>
                                </div>
                                {errors.machine && (
                                  <div className="text-danger">
                                    {errors.machine}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={1}>
                                <label htmlFor="machine_capacity">
                                  Machine Capacity
                                </label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.machine_capacity !=
                                    "This field is required"
                                      ? "form-group input-group"
                                      : "form-group input-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="machine_capacity"
                                    placeholder="Machine Capacity"
                                    required="required"
                                    value={machine_capacity || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                    readOnly={!isFacilPicked}
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text form-control form-control-sm"
                                      id="basic-addon2"
                                      placeholder="UOM"
                                      readOnly={true}
                                    >
                                      {unit_machine_capacity || ""}
                                    </span>
                                  </div>
                                  {errors.machine_capacity && (
                                    <div className="invalid-feedback">
                                      {errors.machine_capacity}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="overhead">Overhead</label>
                              </Col>
                              <Col xs={12} md={5}>
                                <div
                                  className={
                                    errors.overhead != "This field is required"
                                      ? "form-group input-group"
                                      : "form-group input-group has-error has-feedback"
                                  }
                                >
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control form-control-sm"
                                    name="overhead"
                                    placeholder="Overhead"
                                    required="required"
                                    value={overhead || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text form-control form-control-sm"
                                      id="basic-addon2"
                                      placeholder="UOM"
                                      readOnly={true}
                                    >
                                      {unit_overhead || ""}
                                    </span>
                                  </div>
                                </div>
                                {errors.overhead && (
                                  <div className="text-danger">
                                    {errors.overhead}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Line color={"#cccccc"} />
                              </Col>
                            </Row>
                          </form>
                          <Row>
                            <Col xs={12} md={12}>
                              <BOMPowderTable
                                data={recipe}
                                type_data="recipe"
                                materials={select_items_material_all}
                                uom={select_items_uom}
                                defaultOptions={true}
                                loadOptions={this.getMaterial}
                                handleSelect={this.handleSelectBomPowder}
                                handleInput={this.handleInputBomPowder}
                                onChange={(data) =>
                                  this.onChangeBomPowderTable(data, "recipe")
                                }
                                list_plant={this.state.select_items_plant}
                                isCreate={true}
                              />
                              {errors.recipe && (
                                <div className="text-danger font-weight-bold">
                                  {errors.recipe}
                                </div>
                              )}
                              {errors.qty_per_batch && (
                                <div className="text-danger font-weight-bold">
                                  {errors.qty_per_batch}
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <Button
                                variant="success"
                                onClick={this.handleSubmit}
                                className="float-right  "
                              >
                                <i className="fas fa-save" /> Submit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={this.reloadDataBackend}
                                className="float-right mr-2"
                              >
                                <i className="fas fa-eraser" /> Clear{" "}
                              </Button>
                              <Button
                                variant="info"
                                onClick={this.handleNotification}
                                className="float-right mr-2"
                                disabled={disableButtonNotification}
                              >
                                <i className="fab fa-telegram-plane" /> Send
                                Notification{" "}
                                {disableButtonNotification
                                  ? `(in ${seconds}s)`
                                  : ""}
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <input
                                    className="form-control form-control-sm"
                                    name="search"
                                    value={search || ""}
                                    placeholder="Material Code / Description"
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    onChange={this.onChangeValue}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={1}>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br></br>
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            cellEditProps={cellEditProps}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(BOMPowderEXP);
