import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialExpService {

    readAll() {
        return axios
            .get(API_URL_DEFAULT + "material-exp", { headers: authHeader() });
    }
    edit(payload) {
        return axios
            .put(API_URL_DEFAULT + "material-exp", payload, { headers: authHeader() });
    }


}

export default new MaterialExpService();