import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ETAService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "eta", payload, {
      headers: authHeader(),
    });
  }
  overwrite(payload) {
    return axios.post(API_URL_DEFAULT + "eta/?type=overwrite", payload, {
      headers: authHeader(),
    });
  }
  readETA(query_string) {
    return axios.get(API_URL_DEFAULT + "eta/?" + query_string, {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "eta", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "eta", {
      data: payload,
      headers: authHeader(),
    });
  }
  confirmCancelETA(payload) {
    return axios.delete(API_URL_DEFAULT + "eta/confirm-cancel-eta/", {
      data: payload,
      headers: authHeader(),
    });
  }

  confirmUpdateETA(payload) {
    return axios.put(API_URL_DEFAULT + "eta/confirm-update-eta/", payload, {
      headers: authHeader(),
    });
  }
}

export default new ETAService();
