import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Worksheet from "../components/Worksheet";
import ReactTooltip from "react-tooltip";

import { readNotification } from "../modules/NotificationExceptionModule";

import { startOfMonth } from "date-fns";
import NotificationExceptionTable from "../components/NotificationExceptionTable";
import dateFormat from "dateformat";
class NotifikasiException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      id: null,
      filename: null,
      excel: null,
      worksheet: [],
      worksheet_detail: [],
      requester: null,
      cols_length: null,
      material_description: null,
      no_material: null,
      date_first: null,
      date_second: null,
      date_first_formatted: null,
      date_second_formatted: null,
      date: null,
      plan_date: null,
      columns: null,
      list_datatable: null,
      filter_by: null,
      search: null,
      searchSubmit: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "no_material",
      sortOrder: "asc",
      defaultSorted: [],
      cellEditProps: {},
      isSearchFound: true,
      planning_month: new Date(),
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    this.setState({
      plan_date: startOfMonth(new Date()),
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };

  //validate input
  validateForm = (plan_date, date) => {
    let errors = this.state.errors;
    if (!plan_date) {
      errors.plan_date = "This field is required";
    }
    if (!date) {
      errors.date = "This field is required";
    }

    return errors;
  };

  //change state date
  handleDate = (name) => {
    this.setState({
      planning_month: name,
    });
  };

  handleSearch = () => {
    const { filter_by, search, planning_month } = this.state;

    this.setState({ searchSubmit: search });
    const newPlanMonth = dateFormat(planning_month, "yyyy-mm-01");
    let query_string = `planning_month=${newPlanMonth}&notes_log=true&type=${filter_by}&search=${search}`;
    readNotification(query_string)
      .then((response) => {
        this.setState({
          list_datatable: response,
          isSearchFound: true,
        });
      })
      .catch((e) => {
        this.setState({
          list_datatable: e,
          isSearchFound: false,
        });
      });
  };

  render() {
    let {
      worksheet,
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      plan_date,
      filter_by,
      search,
      searchSubmit,
      planning_month,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Filter Notification Exception
                          </h3>
                        </div>
                        <div className="card-body">
                          <label htmlFor="planning_month">
                            {" "}
                            Planning Month *
                          </label>
                          <Row>
                            <Col xs={12} md={3}>
                              <div className="form-row form-group">
                                <DatePicker
                                  selected={planning_month}
                                  startDate={planning_month}
                                  disabledKeyboardNavigation
                                  name="planning_month"
                                  onChange={this.handleDate.bind(this)}
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control"
                                  required
                                  showMonthYearPicker
                                  autoComplete="off"
                                />
                                <span className="error invalid-feedback">
                                  Please entries planning month
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <label htmlFor="filter_by">Filter By *</label>
                          <Row>
                            <Col xs={12} md={3}>
                              <div className="form-row form-group">
                                <select
                                  className="form-control form-control-sm"
                                  name="filter_by"
                                  onChange={this.onChangeValue}
                                  style={{ padding: 0 }}
                                  defaultValue={filter_by}
                                >
                                  <option value="">Choose</option>
                                  <option value="map">MAP</option>
                                  <option value="ingredient_list">
                                    Ingredient List
                                  </option>
                                  <option value="powder_price">
                                    Powder Price
                                  </option>
                                  <option value="price_list">Price List</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <label htmlFor="date">Material Code / Name</label>
                          <Row>
                            <Col
                              xs={12}
                              md={3}
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  errors.plan_date != "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <input
                                  className="form-control form-control-sm"
                                  name="search"
                                  value={search || ""}
                                  placeholder="Search ..."
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  onChange={this.onChangeValue}
                                />
                              </div>
                            </Col>
                            <Col>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                className="float-right"
                              >
                                <i className="fas fa-search" /> Show
                              </Button>
                            </Col>
                          </Row>
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Col>
                              <Row
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="float"
                                />
                              </Row>
                            </Col>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && list_datatable && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Notification Exception Table &nbsp;
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <div>
                            {!list_datatable.data ? (
                              "Data Not Found"
                            ) : (
                              <NotificationExceptionTable
                                data={list_datatable}
                                type={filter_by}
                                search={searchSubmit}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(NotifikasiException);
