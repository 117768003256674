import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MaterialService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "material", payload, {
      headers: authHeader(),
    });
  }
  readMaterial() {
    return axios.get(API_URL_DEFAULT + "material?sizePerPage=100000", {
      headers: authHeader(),
    });
  }
  readMaterialSelect(query) {
    return axios.get(API_URL_DEFAULT + "material/select", {
      headers: authHeader(),
      params: query,
    });
  }
  readMaterialByType(type) {
    return axios.get(
      API_URL_DEFAULT +
        "material?sizePerPage=100000&constraint=material_type&constraint_value=" +
        type,
      { headers: authHeader() }
    );
  }
  readMaterialAndEXPBySearch(query_string) {
    return axios.get(`${API_URL_DEFAULT}material/with-exp`, {
      headers: authHeader(),
      params: query_string,
    });
  }
  readMaterialPowderOnly() {
    return axios.get(
      API_URL_DEFAULT + "material?sizePerPage=100000&powder=true",
      { headers: authHeader() }
    );
  }
  readMaterialExcPowder() {
    return axios.get(
      API_URL_DEFAULT + "material?sizePerPage=100000&excPowder=true",
      { headers: authHeader() }
    );
  }
  readMaterialByCode(code) {
    return axios.get(API_URL_DEFAULT + "material?code=" + code, {
      headers: authHeader(),
    });
  }
  readMaterialUomByCode(code) {
    return axios.get(API_URL_DEFAULT + "material/uom?code=" + code, {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "material", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "material", {
      data: payload,
      headers: authHeader(),
    });
  }
  readMaterialBySearch(query_string) {
    return axios.get(API_URL_DEFAULT + "material?" + query_string, {
      headers: authHeader(),
    });
  }
  readMaterialfromIngredient(month) {
    return axios.get(
      API_URL_DEFAULT +
        "material?sizePerPage=100000&ingredient_list=true&month=" +
        month,
      { headers: authHeader() }
    );
  }

  readMaterialGetBalance(query) {
    return axios.get(API_URL_DEFAULT + "material/balance?" + query, {
      headers: authHeader(),
    });
  }
  readMaterialBomFG(query) {
    return axios.get(API_URL_DEFAULT + "material/bom_fg?" + query, {
      headers: authHeader(),
    });
  }
}

export default new MaterialService();
