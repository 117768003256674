import moment from "moment";
import React, { useState } from "react";
import dateFormat from "dateformat";
const CustomToolbar = (props) => {
  const [viewState, setViewState] = useState("month");
  const getCustomToolbar = () => {
    const goToDayView = () => {
      props.onView("day");
      setViewState("day");
    };
    const goToWeekView = () => {
      props.onView("week");
      setViewState("week");
    };
    const goToMonthView = () => {
      props.onView("month");
      setViewState("month");
    };
    const goToAgendaView = () => {
      props.onView("agenda");
      setViewState("agenda");
    };
    const goToBack = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate("prev", newDate);
      props.handleNavigate("prev", newDate);
    };
    const goToNext = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === "month") {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === "week") {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate("next", newDate);
      props.handleNavigate("next", newDate);
    };

    const goToToday = () => {
      const now = new Date();
      props.date.setMonth(now.getMonth());
      props.date.setYear(now.getFullYear());
      props.date.setDate(now.getDate());
      props.onNavigate("current");

      props.handleNavigate("today", now);
    };

    const goToBackYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() - 1, 1);
      props.onNavigate("prev", newDate);
    };

    const goToNextYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() + 1, 1);
      props.onNavigate("next", newDate);
    };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === "day") {
        day = date.format("ddd") + " " + date.format("Do");
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };
    const month = () => {
      let view = viewState;
      const date = moment(props.date);
      if (view == "day") {
        return (
          <span className="rbc-toolbar-label">
            {dateFormat(date, "dddd, dd mmmm yyyy")}
          </span>
        );
      }
      let month = date.format("MMMM");
      let day = date.format("D");
      let year = date.format("yyyy");

      return (
        <span className="rbc-toolbar-label rbc-date">
          <i className="far fa-calendar"></i> <span>{`${month} ${year} `}</span>
        </span>
      );
    };
    const year = () => {
      const date = moment(props.date);
      let year = date.format("YYYY");

      return (
        <span className="rbc-btn-group">
          {viewState === "month" && (
            <button type="button" onClick={goToBackYear}>
              <span className="prev-icon">&#8249;&#8249;</span>
            </button>
          )}
          <span className="rbc-toolbar-label">{year}</span>
          {viewState === "month" && (
            <button type="button" onClick={goToNextYear}>
              <span className="prev-icon">&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={goToToday}>
            Today
          </button>

          <button type="button" onClick={goToBack}>
            Back
          </button>
          <button type="button" onClick={goToNext}>
            Next
          </button>
        </span>

        {month()}
        <span className="rbc-btn-group">
          <button className="" onClick={goToMonthView}>
            <span className="label-filter-off">Month</span>
          </button>
          <button className="" onClick={goToDayView}>
            <span className="label-filter-off">Day</span>
          </button>
          <button className="" onClick={goToAgendaView}>
            <span className="label-filter-off">Agenda</span>
          </button>
        </span>
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
};

export default CustomToolbar;
