import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import { InputGroup, FormControl, FormGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import "react-datepicker/dist/react-datepicker.css";
import Table from "react-bootstrap/Table";

import { withRouter } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled, { createGlobalStyle } from "styled-components";
import {
  readProdFacilityValue,
  readProdFacility,
} from "../modules/ProdFacilityModule";
import TableDetailCalendar from "../components/TableDetailCalendar";
import CustomToolbar from "../components/CustomToolbar";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import TablePreviewSplit from "../components/TablePreviewSplit";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import {
  readApsCalendar,
  submitMove,
  confirmRpp,
  confirmRppWithoutFGBom,
  confirmETD,
  splitRpp,
  updateCIP,
  unconfirmRpp,
  cancelCIP,
  deleteUnconfirmRPP,
  recalculate,
  editQtyRpp,
} from "../modules/ApsCalendarModule";
import {
  readDetailRpp,
  cancelRPP,
  convertManualRPP,
  readDraftRpp,
  createRpp,
} from "../modules/ApsRppModule";
import ApsRpp from "../views/ApsRpp";
import { addDays, checkDateBefore, isSameDay } from "../helpers/dateHelper";
import {
  readApprovalByEventId,
  sendApprovalToManagerPpc,
} from "../modules/ApprovalModule";
import { readApsConfig } from "../modules/ApsConfigModule";

import { getUserByDeptPos } from "../modules/UserModule";
import { SalesForecast } from "../components/sodiq/SalesForecastInformation";
import APSRawMaterial from "./handri/APSRawMaterial";
import ImportRPP from "./ninda/ImportRPP";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
`;
const localizer = momentLocalizer(moment);

const DatePickerCalendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
`;

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
`;
const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
const TIME_STEP = parseInt(process.env.REACT_APP_TIME_STEP);
class APSCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      submittedMove: false,
      submitted: false,
      showModal: false,
      showModalCreate: false,
      showModalRawMaterial: false,
      showModalForecast: false,
      showModalMove: false,
      showModalInformation: false,
      showModalRecalculate: false,
      showModalImportRpp: false,
      showModalETA: false,
      showModalApproval: false,
      showModalDetailApproval: false,
      showModalSplitRpp: false,
      showModalDraftRPP: false,
      showModalConfirmRPP: false,
      showModalEditRPP: false,
      title: null,
      status: null,
      data_source: null,
      start_time: null,
      finish_time: null,
      details: [],
      defaultDate: new Date(),
      select_items_prod_facility: [],
      select_items_prod_facility_com: [],
      selectedData: {},
      prod_facility: "Plant 1",
      displayDragItemInCell: false,
      option: [
        { code: "Plant 1" },
        { code: "Plant 2" },
        { code: "Mixer" },
        { code: "ETA" },
      ],
      isForecast: false,
      approval: null,
      default_description: "Dear PPC Manager, please approve this move of",
      description: "Dear PPC Manager, please approve this move of",
      events: [],
      title: null,
      type: null,
      date_from: null,
      date_to: null,
      event: null,
      checkAll: true,
      loading: false,
      isDetail: false,
      isCip: false,
      config: null,
      splitQty: null,
      previewSplittedRpp: null,
      previewSplittedRpp_update: null,
      prod_facility_code: null,
      error_split: null,
      detail_split: [],
      isEditCIP: false,
      time_ro_add: 0,
      preview_time: null,
      error_preview_time: null,
      download_export_url: null,
      start_date: null,
      finish_date: null,
      list_filter_legend: [
        { type: "ETA", checked: true, color_class: "bg-eta color-palette" },
        {
          type: "There is ETA cancelled",
          checked: true,
          color_class: "bg-cancel color-palette",
        },
        {
          type: "ETD Unconfirmed",
          checked: true,
          color_class: "bg-etd-unconfirmed color-palette",
        },
        {
          type: "ETD Confirmed",
          checked: true,
          color_class: "bg-etd-confirmed color-palette",
        },

        {
          type: "ETD Waiting Approval",
          checked: true,
          color_class: "bg-etd-waiting color-palette",
        },

        {
          type: "RPP Unconfirmed",
          checked: true,
          color_class: "bg-rpp-unconfirmed color-palette",
        },
        {
          type: "RPP Confirmed",
          checked: true,
          color_class: "bg-rpp-confirmed color-palette",
        },
        {
          type: "RPP Waiting Approval",
          checked: true,
          color_class: "bg-rpp-waiting color-palette",
        },
        { type: "CIP", checked: true, color_class: "bg-rpp-cip color-palette" },
        {
          type: "CIP Manual",
          checked: true,
          color_class: "bg-rpp-cip-manual color-palette",
        },

        {
          type: "Maintenance",
          checked: true,
          color_class: "bg-rpp-maintenance color-palette",
        },
      ],
      details_checked: [],
      requester: null,
      department: null,
      ppc_manager: {
        name: "",
        nik: "",
      },
      isExportExcelShowing: true,
      data_draft_rpp: [],
      total_data_draft_rpp: "0",
      date_create: null,
      min_date: null,
      max_date: null,
      is_split_generate: false,
      show_sales_forecast: false,
    };

    this.moveEvent = this.moveEvent.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    getUserByDeptPos({ dept: "PPC", pos: "Manager" }).then((response) => {
      this.setState({
        ppc_manager: {
          name: response[0].name,
          nik: response[0].username,
        },
      });
    });

    let minDate = new Date();
    minDate.setDate(1);

    let maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(1);
    maxDate.setDate(maxDate.getDate() - 1);

    this.setState({
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      date_create: new Date(),
      min_date: minDate,
      max_date: maxDate,
    });

    readApsConfig().then((response) => {
      this.setState({
        config: response,
      });
    });
    readProdFacilityValue().then((response) => {
      this.setState({ select_items_prod_facility: response });
    });
    readProdFacility().then((response) => {
      this.setState({ select_items_prod_facility_com: response });
    });
    const { prod_facility, defaultDate, option } = this.state;
    this.reloadDataBackend(defaultDate, prod_facility);
    this.loadDraftRPP();
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      if (check_access.isShowOnlyETA) {
        const cloneOption = [];
        cloneOption.push({ code: "ETA" });
        this.setState({
          option: cloneOption,
        });
      }
      if (check_access.isShowAll) {
        const cloneOption = [...option];
        cloneOption.push({ code: "ETA" });
        this.setState({
          option: cloneOption,
        });
      }
      return this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  setDetailToday = (defaultDate, events) => {
    const selectedEvent = events
      .filter((f) => {
        return (
          dateFormat(f.start, "yyyy-mm-dd") ==
          dateFormat(defaultDate, "yyyy-mm-dd")
        );
      })
      .sort((a, b) => {
        if (a.start > b.end) {
          return 1;
        }
        if (a.start < b.start) {
          return -1;
        }
        return 0;
      });

    const summary_daily = this.state.summary_daily.filter(
      (e) => e.date === dateFormat(defaultDate, "yyyy-mm-dd")
    );
    const selectedData = {
      selectedDate: defaultDate,
      events: selectedEvent,
      summary_daily: summary_daily,
    };
    this.setState({
      selectedData: selectedData,
      defaultDate: defaultDate,
    });
  };
  Event = ({ event }) => {
    return (
      <span style={{ fontSize: "12px" }}>
        {event.desc && event.desc + ":  "}
        <strong>{event.title}</strong>
      </span>
    );
  };

  //change state input
  onChangeValue = (any) => {
    const { showModalCreate, start_time, finish_time } = this.state;
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    data["previewSplittedRpp"] = null;
    this.setState(data, () => {
      if (name == "prod_facility" && !showModalCreate) {
        const { defaultDate } = this.state;

        this.reloadDataBackend(defaultDate, value);
      }
      if (name == "time_to_add") {
        let preview_time_mom = moment(finish_time).add(value * 60, "minutes");

        let preview_time = preview_time_mom.format("YYYY-MM-DD h:mm A");
        this.setState({
          preview_time: preview_time,
        });
      }
    });
  };

  onModalSplitHide = () => {
    const { showModalSplitRpp } = this.state;
    this.setState({
      submitted: false,
      date: null,
      start_time: null,
      finish_time: null,
      title: null,
      showModalSplitRpp: !showModalSplitRpp,
      isReadOnly: false,
      isRPP: false,
      is_cancel: false,
      splitQty: null,
      previewSplittedRpp: null,
    });
  };

  onModalApprovalHide = () => {
    const { showModalApproval, default_description } = this.state;
    this.setState({
      approval: {},
      type: null,
      title: null,
      date_from: null,
      date_to: null,
      description: default_description,
      isDetail: false,
      showModalApproval: !showModalApproval,
    });
  };
  onModalHide = () => {
    const { showModal } = this.state;
    this.setState({
      submitted: false,
      date: null,
      start_time: null,
      finish_time: null,
      title: null,
      showModal: !showModal,
      isReadOnly: false,
      isRPP: false,
      is_cancel: false,
      isCip: false,
      isEditCIP: false,
      details_checked: [],
    });
  };
  onModalCreateHide = () => {
    const { showModalCreate, defaultDate, prod_facility } = this.state;
    this.setState({
      submitted: false,
      date: null,
      start_time: null,
      finish_time: null,
      title: null,
      showModalCreate: !showModalCreate,
      isReadOnly: false,
      isRPP: false,
      messageError: null,
    });

    this.reloadDataBackend(defaultDate, prod_facility);
  };
  onModalRawMaterialHide = () => {
    const { showModalRawMaterial, defaultDate, prod_facility } = this.state;
    this.setState({
      showModalRawMaterial: !showModalRawMaterial,
    });

    this.reloadDataBackend(defaultDate, prod_facility);
  };
  onModalImportRppHide = () => {
    const { showModalImportRpp } = this.state;
    this.setState({
      showModalImportRpp: !showModalImportRpp,
    });
  };
  onModalInformationHide = () => {
    const { showModalInformation } = this.state;
    this.setState({
      showModalInformation: !showModalInformation,
    });
  };
  onModalCalculateHide = () => {
    const { showModalRecalculate } = this.state;
    this.setState({
      start_date: null,
      finish_date: null,
      showModalRecalculate: !showModalRecalculate,
    });
  };
  onModalForecastHide = () => {
    const { showModalForecast } = this.state;
    this.setState({
      submittedForecast: false,
      date: null,
      eta_date: null,
      material_name: null,
      qty: null,
      uom: null,
      showModalForecast: !showModalForecast,
      isReadOnly: false,
      isRPP: false,
      is_cancel: false,
      cancelled_by: null,
    });
  };
  onModalETAHide = () => {
    const { showModalETA } = this.state;
    this.setState({
      submittedETA: false,
      eta_date: null,
      material_name: null,
      qty: null,
      uom: null,
      showModalETA: !showModalETA,
      isReadOnly: false,
      isRPP: false,
    });
  };
  onModalMoveHide = () => {
    const { showModalMove } = this.state;
    this.setState({
      submittedMove: false,
      date: null,
      material_name: null,
      qty: null,
      uom: null,
      sequence: null,
      showModalMove: !showModalMove,
      isReadOnly: false,
      isRPP: false,
    });
  };
  onModalLoadingHide = () => {
    const { loading } = this.state;
    this.setState({
      loading: !loading,
    });
  };
  onModalDraftRPPHide = () => {
    const { showModalDraftRPP } = this.state;
    this.setState({
      showModalDraftRPP: !showModalDraftRPP,
    });
  };
  onModalEditRPP = () => {
    const { showModalEditRPP } = this.state;
    this.setState({
      new_qty: null,
      showModalEditRPP: !showModalEditRPP,
    });
  };
  onModalConfirmRPP = () => {
    const { showModalConfirmRPP } = this.state;
    this.setState({
      showModalConfirmRPP: !showModalConfirmRPP,
    });
  };

  splitDraftRPP = (data) => {
    const { showModalSplitRpp } = this.state;
    const detail = [];
    let obj = {
      material: data.material_desc,
      material_code: data.material_code,
      qty: data.qty,
      main_qty: data.qty,
      uom: data.uom,
      source: "GENERATED",
      customer: data.customer_desc,
      etd: "-",
      eta: "-",
      draft_id: data._id,
    };
    detail.push(obj);
    this.setState({
      qty: data.qty,
      material_name: data.material_desc,
      details: detail,
      showModalSplitRpp: !showModalSplitRpp,
      prod_facility_code: null,
      event: obj,
      is_split_generate: true,
    });
  };

  handleSelect = ({ start, end, slot, action }) => {
    if (action == "click") {
      const { events } = this.state;
      if (events) {
        this.setDetailToday(start, events);

        this.setState({
          defaultDate: start,
        });
      }
    }
    if (action == "doubleClick") {
      const { showModal, isCreate } = this.state;
      if (isCreate) {
      }
    }
  };
  handleSubmit = () => {
    const { showModal, title, date, start_time, finish_time, events } =
      this.state;
    const start = new Date(
      `${dateFormat(date, "yyyy-mm-dd")} ${dateFormat(start_time, "HH:MM:00")}`
    );

    const end = new Date(
      `${dateFormat(date, "yyyy-mm-dd")} ${dateFormat(finish_time, "HH:MM:00")}`
    );
    const idList = events.map((a) => {
      return a.id;
    });
    const newId = Math.max(...idList) + 1;
    this.setState(
      {
        events: [
          ...events,
          {
            id: newId,
            start,
            end,
            title,
          },
        ],
        showModal: !showModal,

        isReadOnly: false,
      },
      () => {
        this.setDetailToday(start, this.state.events);
      }
    );
  };

  handleSubmitCip = () => {
    const {
      _id,
      finish_time,
      requester,
      defaultDate,
      prod_facility,
      start_time,
      time_to_add,
    } = this.state;
    let payload = {
      _id: _id,
      requester: requester,
      finish_time: finish_time,
      start_time: start_time,
      time_to_add: time_to_add,
    };

    Swal.fire({
      title: "Please check your input !",
      text: "Are you sure you want to update this CIP?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        updateCIP(payload)
          .then((result) => {
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.setState({
                isEditCIP: false,
                time_to_add: 0,
                preview_time: null,
                error_preview_time: null,
              });
              this.toggleModal();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Cancelled",
              icon: "error",
              text: err,
              showConfirmButton: false,
              timer: 2000,
            });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  handleDeleteUnconfirmRPP = () => {
    const { _id, defaultDate, prod_facility, requester } = this.state;
    let payload = {
      _id: _id,
      requester: requester,
    };
    Swal.fire({
      title: "Please check your input !",
      text: "Are you sure you want to delete this?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteUnconfirmRPP(payload)
          .then((result) => {
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.setState({
                isEditCIP: false,
                time_to_add: 0,
                preview_time: null,
                error_preview_time: null,
              });
              this.handleReload();
              this.toggleModal();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Deleted",
              icon: "error",
              text: err,
              showConfirmButton: false,
              timer: 2000,
            });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-delete-rpp-failed",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Deleted", "Your data is safe :)", "error");
      }
    });
  };
  handleCancelCip = () => {
    const { _id, defaultDate, prod_facility } = this.state;
    let payload = {
      _id: _id,
    };

    Swal.fire({
      title: "Please check your input !",
      text: "Are you sure you want to cancel this CIP?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        cancelCIP(payload)
          .then((result) => {
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.setState({
                isEditCIP: false,
                time_to_add: 0,
                preview_time: null,
                error_preview_time: null,
              });
              this.toggleModal();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Cancelled",
              icon: "error",
              text: err,
              showConfirmButton: false,
              timer: 2000,
            });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleShowEvent = (event) => {
    const {
      showModal,
      isCreate,
      showModalForecast,
      showModalETA,
      showModalApproval,
    } = this.state;
    if (isCreate) {
      if (event.isForecast) {
        if (event.approval == "Waiting for Approval") {
          readApprovalByEventId(event._id).then((response) => {
            return this.setState({
              title: response.title,
              type: event.isForecast ? "ETD" : "RPP",
              date_to: response.date_to,
              date_from: response.date_from,
              showModalApproval: !showModalApproval,
              isDetail: true,
            });
          });
        } else
          return this.setState({
            _id: event._id,
            date: event.start,
            showModalForecast: !showModalForecast,
            material_name: event.material_name,
            shipped_to_name: event.shipped_to_name,
            shipped_to_duration: event.shipped_to_duration,
            customer_name: event.customer_name,
            qty: event.qty,
            eta_date: event.eta_date,
            uom: event.uom,
            isRPP: false,
            isReadOnly: true,
            status: event.status,
            is_cancel: event.is_cancel,
            cancelled_by: event.cancelled_by,
          });
      } else if (event.isETA) {
        return this.setState({
          showModalETA: !showModalETA,
          customer_name: event.customer_name,
          material_name: event.material_name,
          qty: event.qty,
          eta_date: event.eta_date,
          uom: event.uom,
          isRPP: false,
          isReadOnly: true,
        });
      } else if (event.type == "RPP" && event.status == "confirmed") {
        readDetailRpp(`id=${event._id}`)
          .then((response) => {
            const checkIsCancel = response.filter((z) => {
              return z.is_cancel;
            });
            const messageError =
              checkIsCancel.length > 0
                ? "There is ETA thats has been cancelled. Please reload page first!"
                : "";

            return this.setState({
              _id: event._id,
              date: event.start,
              showModal: !showModal,
              start_time: event.start,
              finish_time: event.end,
              material_name: event.material_name,
              material_name_parent: event.material_name_parent,
              qty: event.qty,
              uom: event.uom,
              type: event.type,
              isRPP: event.type == "RPP" ? true : false,
              isCip: ["CIP", "Maintenance"].includes(event.type) ? true : false,
              isReadOnly: true,
              status: event.status,
              data_source:
                event.data_source == "GENERATED" ? "ETA" : event.data_source,
              details: event.details,
              prod_facility_code: event.prod_facility_code,
              is_cancel: event.is_cancel,
              cancelled_by: event.cancelled_by,
              messageError: messageError,
              detail_split: [],
              event: event,
            });
          })
          .catch((err) => {
            return this.setState({
              _id: event._id,
              date: event.start,
              showModal: !showModal,
              start_time: event.start,
              finish_time: event.end,
              material_name: event.material_name,
              qty: event.qty,
              uom: event.uom,
              type: event.type,
              isRPP: event.type == "RPP" ? true : false,
              isCip: ["CIP", "Maintenance"].includes(event.type) ? true : false,
              isReadOnly: true,
              status: event.status,
              data_source:
                event.data_source == "GENERATED" ? "ETA" : event.data_source,
              details: [],
              event: null,
            });
          });
      } else if (
        event.type == "RPP" &&
        event.approval == "Waiting for Approval"
      ) {
        readApprovalByEventId(event._id).then((response) => {
          return this.setState({
            title: response.title,
            type: event.isForecast ? "ETD" : "RPP",
            date_to: response.date_to,
            date_from: response.date_from,
            showModalApproval: !showModalApproval,
            isDetail: true,
          });
        });
      } else {
        readDetailRpp(`id=${event._id}`)
          .then((response) => {
            return this.setState({
              _id: event._id,
              date: event.start,
              showModal: !showModal,
              start_time: event.start,
              finish_time: event.end,
              material_name: event.material_name,
              material_name_parent: event.material_name_parent,
              qty: event.qty,
              uom: event.uom,
              type: event.type,
              isRPP: event.type == "RPP" ? true : false,
              isCip: ["CIP", "Maintenance"].includes(event.type) ? true : false,
              isReadOnly: true,
              status: event.status,
              data_source:
                event.data_source == "GENERATED" ? "ETA" : event.data_source,
              details: response,
              prod_facility_code: event.prod_facility_code,
              is_cancel: event.is_cancel,
              cancelled_by: event.cancelled_by,
              detail_split: [],
              event: event,
            });
          })
          .catch((err) => {
            return this.setState({
              _id: event._id,
              date: event.start,
              showModal: !showModal,
              start_time: event.start,
              finish_time: event.end,
              material_name: event.material_name,
              material_name_parent: event.material_name_parent,
              qty: event.qty,
              uom: event.uom,
              type: event.type,
              isRPP: event.type == "RPP" ? true : false,
              isCip: ["CIP", "Maintenance"].includes(event.type) ? true : false,
              isReadOnly: true,
              status: event.status,
              data_source:
                event.data_source == "GENERATED" ? "ETA" : event.data_source,
              details: [],
              detail_split: event.detail_split ? event.detail_split : [],
            });
          });
      }
    }
  };

  handleCheckDetails = (index, e) => {
    const { details_checked } = this.state;
    let copy_details_checked = [...details_checked];
    copy_details_checked[index] = e.target.checked;
    this.setState({
      details_checked: copy_details_checked,
    });
  };

  handleCheckLegendAll = () => {
    const { checkAll, list_filter_legend, events } = this.state;
    let checkAllLegend = true;
    let list_filter_legend_;
    if (checkAll) {
      checkAllLegend = false;
      list_filter_legend_ = list_filter_legend.map((obj) => {
        return {
          ...obj,
          checked: false,
        };
      });
    } else {
      list_filter_legend_ = list_filter_legend.map((obj, idx) => {
        return {
          ...obj,
          checked: true,
        };
      });
    }

    this.setState(
      {
        checkAll: checkAllLegend,
        list_filter_legend: [...list_filter_legend_],
      },
      () => {
        list_filter_legend_.map((obj, idx) => {
          let checked = obj.checked;
          let new_events;
          switch (obj.type) {
            case "ETA":
              new_events = events.map((event) => {
                if (event.isETA) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "There is ETA cancelled":
              new_events = events.map((event) => {
                if (event.is_cancel) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "ETD Unconfirmed":
              new_events = events.map((event) => {
                if (event.isForecast && event.status == "unconfirmed") {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "ETD Confirmed":
              new_events = events.map((event) => {
                if (
                  event.isForecast &&
                  event.status == "confirmed" &&
                  !event.is_cancel
                ) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "ETD Waiting Approval":
              new_events = events.map((event) => {
                if (
                  event.isForecast &&
                  event.approval == "Waiting for Approval"
                ) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "RPP Unconfirmed":
              new_events = events.map((event) => {
                if (event.type == "RPP" && event.status == "unconfirmed") {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "RPP Confirmed":
              new_events = events.map((event) => {
                if (
                  event.type == "RPP" &&
                  event.status == "confirmed" &&
                  !event.is_cancel
                ) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "RPP Waiting Approval":
              new_events = events.map((event) => {
                if (
                  event.approval == "Waiting for Approval" &&
                  event.type == "RPP"
                ) {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "CIP":
              new_events = events.map((event) => {
                if (event.type == "CIP" && event.data_source == "GENERATED") {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "CIP Manual":
              new_events = events.map((event) => {
                if (event.type == "CIP" && event.data_source == "MANUAL") {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
            case "Maintenance":
              new_events = events.map((event) => {
                if (event.type == "Maintenance") {
                  event.isShowing = checked;
                }
                return event;
              });
              this.setState({
                events: [...new_events],
              });
              break;
          }
        });
      }
    );
  };

  handleCheckLegend = (event) => {
    const { list_filter_legend, events } = this.state;
    const checked = event.target.checked;
    const idx = parseInt(event.target.id);

    let new_legend = [...list_filter_legend];
    new_legend[idx].checked = checked;
    this.setState(
      {
        list_filter_legend: [...new_legend],
      },
      () => {
        let new_events;
        switch (new_legend[idx].type) {
          case "ETA":
            new_events = events.map((event) => {
              if (event.isETA) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "There is ETA cancelled":
            new_events = events.map((event) => {
              if (event.is_cancel) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "ETD Unconfirmed":
            new_events = events.map((event) => {
              if (event.isForecast && event.status == "unconfirmed") {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "ETD Confirmed":
            new_events = events.map((event) => {
              if (
                event.isForecast &&
                event.status == "confirmed" &&
                !event.is_cancel
              ) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "ETD Waiting Approval":
            new_events = events.map((event) => {
              if (
                event.isForecast &&
                event.approval == "Waiting for Approval"
              ) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "RPP Unconfirmed":
            new_events = events.map((event) => {
              if (event.type == "RPP" && event.status == "unconfirmed") {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "RPP Confirmed":
            new_events = events.map((event) => {
              if (
                event.type == "RPP" &&
                event.status == "confirmed" &&
                !event.is_cancel
              ) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "RPP Waiting Approval":
            new_events = events.map((event) => {
              if (
                event.approval == "Waiting for Approval" &&
                event.type == "RPP"
              ) {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "CIP":
            new_events = events.map((event) => {
              if (event.type == "CIP" && event.data_source == "GENERATED") {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "CIP Manual":
            new_events = events.map((event) => {
              if (event.type == "CIP" && event.data_source == "MANUAL") {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
          case "Maintenance":
            new_events = events.map((event) => {
              if (event.type == "Maintenance") {
                event.isShowing = checked;
              }
              return event;
            });
            this.setState({
              events: [...new_events],
            });
            break;
        }
      }
    );
  };

  eventStyle = (event, start, end, isSelected) => {
    let newStyle = {
      backgroundColor: "#f2e63f",
      color: "black",
    };

    if (
      event.status == "confirmed" &&
      event.type == "RPP" &&
      !event.is_cancel
    ) {
      newStyle.backgroundColor = "lightgreen";
    }

    if (event.status == "unconfirmed" && event.type == "RPP") {
      newStyle.backgroundColor = "#cccccc";
    }

    if (event.approval == "Waiting for Approval" && event.type == "RPP") {
      newStyle.backgroundColor = "#116e11";
      newStyle.color = "white";
    }

    if (event.type == "CIP" && event.data_source == "GENERATED") {
      newStyle.backgroundColor = "#83c5f7";
    }

    if (event.type == "CIP" && event.data_source == "MANUAL") {
      newStyle.backgroundColor = "#0984e3";
      newStyle.color = "white";
    }

    if (event.type == "Maintenance") {
      newStyle.backgroundColor = "#fc8181";
    }

    if (event.isForecast && event.status == "confirmed" && !event.is_cancel) {
      newStyle.backgroundColor = "orange";
    }
    if (event.isForecast && event.status == "unconfirmed") {
      newStyle.backgroundColor = "#ffe4cc";
    }
    if (event.isForecast && event.approval == "Waiting for Approval") {
      newStyle.backgroundColor = "#b37400";
      newStyle.color = "white";
    }
    if (event.isETA) {
      newStyle.backgroundColor = "indigo";
      newStyle.color = "white";
    }
    if (event.is_cancel) {
      newStyle.backgroundColor = "black";
      newStyle.color = "white";
    }

    return {
      className: "",
      style: event.isShowing && newStyle,
    };
  };

  checkShowingLegend = (event) => {
    const { list_filter_legend } = this.state;
    let isShowing = true;

    if (event.isETA) {
      return list_filter_legend.find((item) => item.type == "ETA").checked;
    }
    if (event.is_cancel) {
      return list_filter_legend.find(
        (item) => item.type == "There is ETA cancelled"
      ).checked;
    }
    if (event.isForecast && event.status == "unconfirmed") {
      return list_filter_legend.find((item) => item.type == "ETD Unconfirmed")
        .checked;
    }
    if (event.isForecast && event.status == "confirmed" && !event.is_cancel) {
      return list_filter_legend.find((item) => item.type == "ETD Confirmed")
        .checked;
    }

    if (event.isForecast && event.approval == "Waiting for Approval") {
      return list_filter_legend.find(
        (item) => item.type == "ETD Waiting Approval"
      ).checked;
    }

    if (event.status == "unconfirmed" && event.type == "RPP") {
      return list_filter_legend.find((item) => item.type == "RPP Unconfirmed")
        .checked;
    }

    if (
      event.status == "confirmed" &&
      event.type == "RPP" &&
      !event.is_cancel
    ) {
      return list_filter_legend.find((item) => item.type == "RPP Confirmed")
        .checked;
    }

    if (event.approval == "Waiting for Approval" && event.type == "RPP") {
      return list_filter_legend.find(
        (item) => item.type == "RPP Waiting Approval"
      ).checked;
    }

    if (event.type == "CIP" && event.data_source == "GENERATED") {
      return list_filter_legend.find((item) => item.type == "CIP").checked;
    }

    if (event.type == "CIP" && event.data_source == "MANUAL") {
      return list_filter_legend.find((item) => item.type == "CIP Manual")
        .checked;
    }

    if (event.type == "Maintenance") {
      return list_filter_legend.find((item) => item.type == "Maintenance")
        .checked;
    }

    return isShowing;
  };

  dayPropStyle = (date) => {
    const { defaultDate } = this.state;
    return dateFormat(defaultDate, "yyyy-mm-dd") ==
      dateFormat(date, "yyyy-mm-dd")
      ? {
          style: {
            backgroundColor: "#71b5fd73",
          },
        }
      : {};
  };
  handleDraggable = (event) => {
    if (event.status == "confirmed") return false;
    if (event.approval == "Waiting for Approval") return false;
    if (event.isETA) return false;
    return true;
  };

  handleDragStart = (event) => {
    this.setState({ draggedEvent: event });
  };

  dragFromOutsideItem = () => {
    return this.state.draggedEvent;
  };

  onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = this.state;
    if (!draggedEvent.isForecast && !draggedEvent.isETA) {
      const event = {
        id: draggedEvent.id,
        title: draggedEvent.title,
        start,
        end,
        allDay: allDay,
      };
      this.setState({ draggedEvent: null });
      this.moveEvent({ event, start, end });
    }
  };

  moveEvent_old = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { showModalMove, events, config } = this.state;
    if (event.status != "confirmed" && event.type == "RPP") {
      let now = new Date();
      let additionalDays = new Date(addDays(now, config.approval_days));
      if (
        (checkDateBefore(start, additionalDays) ||
          checkDateBefore(event.start, additionalDays)) &&
        !isSameDay(start, event.start)
      ) {
        Swal.fire({
          title: "Warning !",
          text: "To move this, you need approval from PPC Manager. Change of H+3 from production date, must be approved by PPC Manager",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            const user = JSON.parse(localStorage.getItem("user"));
            const access_token = JSON.parse(
              localStorage.getItem("accessToken")
            );
            const { requester, showModalApproval } = this.state;
            let obj = {
              _id: event._id,
              origin_start: event.start,
              origin_qty: event.qty,
              origin_uom: event.uom,
              destination_start: new Date(start).toISOString(),
              isForecast: event.isForecast,
              requester: requester,
              sequence: event.queue,
            };
            let token = access_token.accessToken;
            let collection = {
              requester: requester,
              department:
                user.details.hris_org_tree.current_person.nama_department,
              name: user.details.hris_org_tree.current_person.person_name,
              access_token: token,
              type: event.isForecast ? "ETD" : "RPP",
              title: event.title,
              module_obj: obj,
              date_to: dateFormat(new Date(start), "dd-mm-yyyy"),
              date_from: dateFormat(new Date(event.start), "dd-mm-yyyy"),
              event_id: event._id,
            };
            this.setState({
              approval: { ...collection },
              title: event.title,
              type: event.isForecast ? "ETD" : "RPP",
              date_to: dateFormat(new Date(start), "dd-mm-yyyy"),
              date_from: dateFormat(new Date(event.start), "dd-mm-yyyy"),
              showModalApproval: !showModalApproval,
              description: `${this.state.description} ${
                event.title
              } from ${dateFormat(
                new Date(event.start),
                "dd-mm-yyyy"
              )} to ${dateFormat(new Date(start), "dd-mm-yyyy")}`,
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else if (!isSameDay(start, event.start)) {
        this.chooseSequence(event, events, start, end);
      }
    }
  };

  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { showModalMove, events, config } = this.state;
    if (event.status != "confirmed") {
      let now = new Date();
      if (!isSameDay(start, event.start)) {
        this.chooseSequence(event, events, start, end);
      }
    }
  };

  chooseSequence = (event, events, start, end) => {
    const { requester, prod_facility, defaultDate } = this.state;
    let collection = {
      _id: event._id,
      origin_start: event.start,
      origin_qty: event.qty,
      origin_uom: event.uom,
      destination_start: new Date(start).toISOString(),
      isForecast: event.isForecast,
      requester: requester,
      sequence: event.queue,
    };

    submitMove(collection)
      .then((response) => {
        this.setState({
          submittedMove: false,
          showModalMove: false,
          sequence: null,
        });
        this.reloadDataBackend(defaultDate, prod_facility);
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-submit-move-failed",
        });
      });
  };

  updateEventDB = (event, start, end, droppedOnAllDaySlot) => {
    const { events } = this.state;
    let allDay = event.allDay;
    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = events.map((existingEvent) => {
      const updateEvent = {
        id: existingEvent.id,
        _id: existingEvent._id,
        allDay: allDay,
        isForecast: existingEvent.isForecast,
        title: existingEvent.title,
        start: start,
        end: end,
        type: existingEvent.type,
        qty: existingEvent.qty,
        material_code: existingEvent.material_code,
        material_name: existingEvent.material_name,
        uom: existingEvent.uom,
      };
      return existingEvent.id == event.id ? updateEvent : existingEvent;
    });
    this.setState({
      events: nextEvents,
    });
  };

  handleNavigate = (action, newDate) => {
    const actionArray = ["prev", "next", "today"];
    if (["prev", "next"].includes(action)) {
      this.setState({
        show_sales_forecast: false,
      });
    }

    if (actionArray.includes(action)) {
      const { prod_facility } = this.state;
      this.reloadDataBackend(newDate, prod_facility);
    }
  };
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };
  toggleRPP = () => {
    const { showModalCreate } = this.state;
    return this.setState({
      showModalCreate: !showModalCreate,
    });
  };
  toggleRawMaterial = () => {
    const { showModalRawMaterial } = this.state;
    return this.setState({
      showModalRawMaterial: !showModalRawMaterial,
    });
  };
  toggleImportRPP = () => {
    const { showModalImportRpp } = this.state;
    return this.setState({
      showModalImportRpp: !showModalImportRpp
    })
  }
  toggleInformation = () => {
    const { showModalInformation } = this.state;
    return this.setState({
      showModalInformation: !showModalInformation,
    });
  };

  toggleRecalculate = () => {
    const { showModalRecalculate } = this.state;
    return this.setState({
      showModalRecalculate: !showModalRecalculate,
    });
  };
  toggleDraftRPP = () => {
    const { showModalDraftRPP, defaultDate } = this.state;
    this.loadDraftRPP();
    return this.setState({
      current_month: dateFormat(defaultDate, "mmmm"),
      showModalDraftRPP: !showModalDraftRPP,
    });
  };

  loadDraftRPP = () => {
    const query_string = `current=${dateFormat(
      this.state.defaultDate,
      "yyyy-mm-01"
    )}`;
    readDraftRpp(query_string)
      .then((res) => {
        let total = "0";
        if (res.length > 99) {
          total = "99+";
        } else {
          total = "" + res.length;
        }
        return this.setState({
          data_draft_rpp: res,
          total_data_draft_rpp: total,
        });
      })
      .catch((err) => {
        return this.setState({
          data_draft_rpp: [],
        });
      });
  };

  hideShowExport = (all_event) => {
    let isShow = all_event.some(
      (event) =>
        (event.type == "RPP" && event.status == "confirmed") ||
        (event.type == "CIP" && event.status == "unconfirmed")
    );
    return isShow;
  };

  reloadDataBackend = (defaultDate, prod_facility) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { ppc_manager } = this.state;
    const query_string = `month=${dateFormat(
      defaultDate,
      "yyyy-mm-dd"
    )}&prod_facility=${encodeURIComponent(prod_facility)}`;
    readApsCalendar(query_string).then((res) => {
      const cloneRes = [...res];
      const temp_summary_daily = [];
      const mapRes = cloneRes.map((a) => {
        a.start = new Date(a.start);
        a.end = new Date(a.end);
        a.isShowing = this.checkShowingLegend(a);
        if (a.type === "RPP" && a.status === "confirmed" && a.summary) {
          temp_summary_daily.push(a.summary);
        }
        return a;
      });

      let summary_daily = [];
      if (temp_summary_daily.length > 0) {
        temp_summary_daily.sort((a, b) => a.date - b.date);
        for (let sd of temp_summary_daily) {
          for (let dt of sd.detail) {
            if (summary_daily.length === 0) {
              dt.date = sd.date;
              summary_daily.push(dt);
            } else {
              const check = summary_daily.find(
                (e) =>
                  e.material_code === dt.material_code && sd.date === e.date
              );
              if (check) {
                check.qty += dt.qty;
              } else {
                dt.date = sd.date;
                summary_daily.push(dt);
              }
            }
          }
        }
      }

      let show = this.hideShowExport(mapRes);
      return this.setState(
        {
          summary_daily: summary_daily,
          events: mapRes,
          defaultDate: defaultDate,
          download_export_url: `${
            process.env.REACT_APP_API
          }calendar/excel/?date=${dateFormat(
            defaultDate,
            "yyyy-mm-dd"
          )}&prod_facility=${prod_facility}&requester=${
            user.username
          }&department=${
            user.details.hris_org_tree.current_person
              ? user.details.hris_org_tree.current_person.nama_department
              : null
          }&requester_name=${
            user.details.hris_org_tree.current_person.person_name
          }&manager_name=${ppc_manager.name}&manager_nik=${ppc_manager.nik}
          `,
          isForecast: false,
          isExportExcelShowing: show,
        },
        () => this.setDetailToday(this.state.defaultDate, this.state.events)
      );
    });
  };

  handleSubmitMove = (e) => {
    this.setState({ submittedMove: true });
    const {
      _id,
      destination_start,
      requester,
      sequence,
      prod_facility,
      defaultDate,
      isForecast,
    } = this.state;
    const { history } = this.props;
    if (!this.validateForm(sequence)) {
      return;
    }
    let collection = {
      _id: _id,
      isForecast: isForecast,
      destination_start: new Date(destination_start).toISOString(),
      requester: requester,
      sequence: sequence,
    };

    if (sequence) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          submitMove(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-submit-move-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  submittedMove: false,
                  showModalMove: false,
                  sequence: null,
                });
                this.reloadDataBackend(defaultDate, prod_facility);
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-submit-move-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleConfirmRpp = () => {
    const { _id, prod_facility, defaultDate, requester } = this.state;
    let collection = {
      _id: _id,
      requester: requester,
    };

    Swal.fire({
      title: "Confirm RPP",
      text: "Are you sure ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true });
        confirmRpp(collection)
          .then((response) => {
            this.setState({ loading: false });
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.onModalHide();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
            if (
              err === "FG BOM not found, are you sure to continue?" ||
              err.search("FG BOM Default is not found") >= 0
            ) {
              Swal.fire({
                title: "FG BOM not found",
                text: `Are you sure to continue ?`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.value) {
                  this.setState({ loading: true });
                  confirmRppWithoutFGBom(collection)
                    .then((response) => {
                      this.setState({ loading: false });
                      toast.success("Data has been updated successfully", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId: "customId-update-exchange-rate-success",
                      });
                      Swal.fire({
                        title: "Information",
                        icon: "success",
                        text: "Data has been updated successfully",
                        showConfirmButton: false,
                        timer: 2000,
                      }).then((result) => {
                        this.onModalHide();
                        this.reloadDataBackend(defaultDate, prod_facility);
                      });
                    })
                    .catch((err) => {
                      toast.error(err, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId: "customId-update-exchange-rate-failed",
                      });
                      this.setState({
                        loading: false,
                      });
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  this.setState({ loading: false });
                }
              });
            } else {
              Swal.fire({
                title: "Cancelled",
                text: `${err}`,
                icon: "error",
              });
            }
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  handleEditQty = () => {
    const { _id, new_qty, requester, prod_facility, defaultDate } = this.state;
    let collection = {
      _id: _id,
      qty: new_qty,
      requester: requester,
    };

    Swal.fire({
      title: "Confirm Edit Qty",
      text: "Are you sure ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true });
        editQtyRpp(collection)
          .then((response) => {
            this.setState({ loading: false });
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.reloadDataBackend(defaultDate, prod_facility);
              this.handleReload();
              this.setState({
                qty: new_qty,
                showModalEditRPP: false,
              });
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  handleEditRpp = (event) => {
    const { showModalEditRPP } = this.state;
    this.setState({
      showModalEditRPP: !showModalEditRPP,
    });
  };

  hanldeConfirmDraftRPP = (item) => {
    const { showModalConfirmRPP } = this.state;
    this.setState({
      draft_rpp: item,
      showModalConfirmRPP: !showModalConfirmRPP,
    });
  };

  handleSubmitDraftRPP = () => {
    const {
      requester,
      draft_rpp,
      date_create,
      facility_code_create,
      facility_desc_create,
      facility_type_create,
      facility_number_create,
      prod_facility,
      defaultDate,
    } = this.state;

    let collection = {
      material_desc: draft_rpp.material_desc,
      material_code: draft_rpp.material_code,
      qty: draft_rpp.qty,
      unit: draft_rpp.uom,
      date_start: date_create,
      date_finish: date_create,
      cb_safety_stock: false,
      safety_stock: 0,
      beginning_balance: 0,
      remark: "",
      type: "RPP",
      data_source: "GENERATED",
      requester: requester,
      prod_facility_desc: facility_desc_create,
      prod_facility_code: facility_code_create,
      prod_facility_type: facility_type_create,
      prod_facility_number: facility_number_create,
      position_picked: null,
      event_picked: null,
      duration: "0",
      total_batch: 0,
      draft_id: draft_rpp._id,
    };

    if (requester && date_create && facility_desc_create) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          createRpp(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.loadDraftRPP();
                this.reloadDataBackend(defaultDate, prod_facility);
                this.onModalConfirmRPP();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });

              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      this.setState({
        loading: false,
      });
      Swal.fire("Warning", "Please Complete Form", "warning");
    }
  };

  handleConfirmETD = () => {
    const { _id, prod_facility, defaultDate, requester } = this.state;
    let collection = {
      _id: _id,
      requester: requester,
    };
    Swal.fire({
      title: "Confirm ETD",
      text: "Are you sure ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        confirmETD(collection)
          .then((response) => {
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.onModalForecastHide();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
            this.setState({
              loading: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  handleUnconfirm = () => {
    const {
      _id,
      requester,
      defaultDate,
      prod_facility,
      details_checked,
      details,
    } = this.state;
    //get id of unconfirmed rpp,
    let idx_arr = details_checked.map((item, idx) =>
      item === true ? idx : null
    );

    let picked_details = details.filter((det, idx) => idx_arr.includes(idx));

    let collection = {
      _id: _id,
      picked_details: picked_details,
      requester: requester,
    };

    Swal.fire({
      title: "Unconfirm RPP",
      text: "Are you sure ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true });
        unconfirmRpp(collection)
          .then((response) => {
            this.setState({ loading: false });
            toast.success("Data has been saved successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been saved successfully",
              showConfirmButton: false,
              timer: 2000,
            }).then((result) => {
              this.onModalHide();
              this.reloadDataBackend(defaultDate, prod_facility);
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-submit-move-failed",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  //validate input
  validateForm = (sequence) => {
    let errors = this.state.errors;
    if (!sequence) {
      errors.sequence = "This is field required";
    }
    return errors;
  };
  handleCancelRPP = (id_detail_eta) => {
    const { _id, requester, defaultDate, prod_facility } = this.state;
    let collection = {
      _id: _id,
      id_detail_eta: id_detail_eta,
      requester: requester,
    };
    if (id_detail_eta) {
      Swal.fire({
        title: "Are you sure to cancel this?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          cancelRPP(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-cancel-rpp-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              });
              this.setState({
                showModal: false,
                loading: false,
                is_cancel: false,
                cancelled_by: null,
                messageError: null,
              });
              this.reloadDataBackend(defaultDate, prod_facility);
            })
            .catch((err) => {
              Swal.fire({
                title: "Information",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 1500,
              });
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-cancel-rpp-failed",
              });
              this.setState({
                showModal: false,
                loading: false,
                is_cancel: false,
                cancelled_by: null,
                messageError: null,
              });
              this.reloadDataBackend(defaultDate, prod_facility);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };

  handleConvertManualRPP = (id_detail_eta) => {
    const { _id, requester, defaultDate, prod_facility } = this.state;
    let collection = {
      _id: _id,
      id_detail_eta: id_detail_eta,
      requester: requester,
    };
    if (id_detail_eta) {
      Swal.fire({
        title: "Are you sure to convert manual?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          convertManualRPP(collection)
            .then((response) => {
              toast.success("Data has been converted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-convert-manual-rpp-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been converted successfully",
                showConfirmButton: false,
                timer: 2000,
              });
              this.setState({
                showModal: false,
                loading: false,
                is_cancel: false,
                cancelled_by: null,
                messageError: null,
              });
              this.reloadDataBackend(defaultDate, prod_facility);
            })
            .catch((err) => {
              Swal.fire({
                title: "Information",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 1500,
              });
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-convert-manual-rpp-failed",
              });
              this.setState({
                showModal: false,
                loading: false,
                is_cancel: false,
                cancelled_by: null,
                messageError: null,
              });
              this.reloadDataBackend(defaultDate, prod_facility);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };

  handleApproval = () => {
    const { approval, defaultDate, prod_facility, description } = this.state;
    approval.description = description;
    this.setState({
      loading: true,
    });
    sendApprovalToManagerPpc(approval)
      .then((response) => {
        this.setState({
          loading: false,
        });
        toast.success("Approval has been sent successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-submit-move-success",
        });
        Swal.fire({
          title: "Information",
          icon: "success",
          text: "Approval has been sent",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          this.onModalApprovalHide();
          this.reloadDataBackend(defaultDate, prod_facility);
        });
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-submit-move-failed",
        });
      });
  };

  handlePreviewSplit = () => {
    const { splitQty, details, qty, prod_facility_code } = this.state;
    let split_qty = parseFloat(splitQty);
    let _qty = parseFloat(qty);

    if (!split_qty) {
      this.setState({
        error_split: "This field is required",
        previewSplittedRpp: null,
      });
    } else if (split_qty <= 0) {
      this.setState({
        error_split: `Please fill qty above 0`,
        previewSplittedRpp: null,
      });
    } else if (split_qty >= _qty) {
      this.setState({
        error_split: `Please fill qty below ${_qty}`,
        previewSplittedRpp: null,
      });
    } else {
      let detail_ = details.map((x) => {
        return {
          ...x,
          prod_facility_code,
        };
      });
      let old_detail = JSON.parse(JSON.stringify(detail_));
      let remaining_qty = _qty - split_qty;
      let preview_splitted = [
        {
          ...old_detail[0],
          qty:
            remaining_qty % 1 != 0
              ? remaining_qty.toFixed(DECIMAL_PLACES)
              : remaining_qty,
          detail_split: [],
        },
        {
          ...old_detail[0],
          qty:
            split_qty % 1 != 0 ? split_qty.toFixed(DECIMAL_PLACES) : split_qty,
          detail_split: [],
        },
      ];
      // to help counting the detail
      let remaining_rpp = preview_splitted[0];
      let splitted_rpp = preview_splitted[1];

      let counted = 0;
      let remains = split_qty;
      let remains_rpp = {};
      while (counted < _qty) {
        let rpp = old_detail.shift();
        if (rpp) {
          let remaining = rpp.qty - remains;
          if (counted < split_qty) {
            //fill the detail of the splitted rpp
            if (Math.sign(remaining) == -1) {
              remains = Math.abs(remaining);
              splitted_rpp.detail_split.push({
                ...rpp,
                qty:
                  rpp.qty % 1 != 0 ? rpp.qty.toFixed(DECIMAL_PLACES) : rpp.qty,
              });
              counted += rpp.qty;
              remains_rpp = { ...rpp };
            } else if (Math.sign(remaining) == 1) {
              splitted_rpp.detail_split.push({
                ...rpp,
                qty:
                  remains % 1 != 0 ? remains.toFixed(DECIMAL_PLACES) : remains,
              });
              counted += remains;
              remains = Math.abs(remaining);
              remains_rpp = { ...rpp };
            } else {
              splitted_rpp.detail_split.push({
                ...rpp,
                qty:
                  rpp.qty % 1 != 0 ? rpp.qty.toFixed(DECIMAL_PLACES) : rpp.qty,
              });
              counted += rpp.qty;
              remains = Math.abs(remaining);
              remains_rpp = { ...rpp };
            }
          } else {
            remaining_rpp.detail_split.push({
              ...rpp,
              qty: rpp.qty % 1 != 0 ? rpp.qty.toFixed(DECIMAL_PLACES) : rpp.qty,
            });
            counted += rpp.qty;
          }
        } else if (remains > 0) {
          remaining_rpp.detail_split.unshift({
            ...remains_rpp,
            qty: remains % 1 != 0 ? remains.toFixed(DECIMAL_PLACES) : remains,
          });
          counted += remains;
        }
      }

      this.setState(
        {
          previewSplittedRpp: [...preview_splitted],
          previewSplittedRpp_update: [...preview_splitted],
          error_split: null,
        },
        () => {}
      );
    }
  };

  handleSubmitSplit = () => {
    const {
      previewSplittedRpp_update,
      event,
      defaultDate,
      prod_facility,
      is_split_generate,
      date_create,
      requester,
    } = this.state;

    let payload = {
      rpp_old: event,
      rpp_splitted: previewSplittedRpp_update,
    };

    payload.rpp_old.is_split_generate = is_split_generate;
    payload.rpp_old.date_create = date_create;
    payload.rpp_old.requester = requester;
    payload.rpp_old.draft_id = event.draft_id;

    const payload_check_split = {
      rpp_old: event,
      rpp_splitted: previewSplittedRpp_update,
      check_split: true,
    };

    this.setState({ loading: true });
    splitRpp(payload_check_split)
      .then((response) => {
        this.setState({ loading: false });
        Swal.fire({
          title: "Warning !",
          text: "Are you sure you want to split this?",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            splitRpp(payload)
              .then((response) => {
                toast.success("Data has been splitted successfully", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-split-rpp-success",
                });
                Swal.fire({
                  title: "Information",
                  icon: "success",
                  text: "Data has been converted successfully",
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.loadDraftRPP();
                this.onModalSplitHide();
                this.reloadDataBackend(defaultDate, prod_facility);
              })
              .catch((err) => {
                Swal.fire({
                  title: "Information",
                  icon: "error",
                  text: err,
                  showConfirmButton: true,
                });
                toast.error(err, {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-split-rpp-failed",
                });
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Information",
          icon: "error",
          text: err,
          showConfirmButton: true,
        });
        toast.error(err, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-split-rpp-failed",
        });
        this.setState({ loading: false });
      });
  };

  handleReload = () => {
    const { show_sales_forecast } = this.state;
    this.setState(
      {
        show_sales_forecast: !show_sales_forecast,
      },
      () => {
        this.setState({
          show_sales_forecast: !this.state.show_sales_forecast,
        });
      }
    );
  };
  handleSubmitRecalculate = () => {
    const { start_date, finish_date, defaultDate, prod_facility } = this.state;
    const payload = {
      start_date,
      finish_date,
      prod_facility,
    };

    if (!start_date || !finish_date) {
      Swal.fire("Error", "please complete form", "error");
      return;
    }
    if (
      dateFormat(finish_date, "yyyy-mm-dd HH:MM") <
      dateFormat(start_date, "yyyy-mm-dd HH:MM")
    ) {
      Swal.fire(
        "Error",
        "finish date cannot be smaller than start date",
        "error"
      );
      return;
    }

    this.setState({ loading: false });
    Swal.fire({
      title: "Warning !",
      text: "Are you sure you want to recalculate?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      this.setState({ loading: true });
      if (result.value) {
        recalculate(payload)
          .then((response) => {
            this.setState({ loading: false });
            toast.success("Data has been recalculate successfully", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-split-rpp-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Data has been recalculate successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            this.onModalCalculateHide();
            this.reloadDataBackend(defaultDate, prod_facility);
          })
          .catch((err) => {
            Swal.fire({
              title: "Information",
              icon: "error",
              text: err,
              showConfirmButton: true,
            });
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-split-rpp-failed",
            });
            this.setState({ loading: false });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState({ loading: false });
      }
    });
  };

  onChangeProdFacility = (any) => {
    const { select_items_prod_facility_com } = this.state;
    let index = any.target.selectedIndex;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility_com[index - 1];
      this.setState({
        facility_code_create: prod_facility.facility_code,
        facility_desc_create: prod_facility.facility_desc,
        facility_type_create: prod_facility.facility_type,
        facility_number_create: prod_facility.facility_number,
      });
    }
  };

  showSalesForecast = () => {
    const { show_sales_forecast } = this.state;
    this.setState({
      show_sales_forecast: !show_sales_forecast,
    });
  };

  render() {
    let {
      loading,
      isCreate,
      isReadOnly,
      isRead,
      events,
      date,
      showModal,
      submitted,
      title,
      status,
      data_source,
      details,
      start_time,
      finish_time,
      defaultDate,
      selectedData,
      prod_facility,
      option,
      showModalForecast,
      submittedForecast,
      material_name,
      material_name_parent,
      new_qty,
      qty,
      uom,
      isRPP,
      type,
      showModalCreate,
      showModalRawMaterial,
      showModalImportRpp,
      chosenData,
      showModalMove,
      sequence,
      submittedMove,
      errors,
      isForecast,
      eta_date,
      showModalInformation,
      showModalRecalculate,
      showModalETA,
      submittedETA,
      customer_name,
      is_cancel,
      cancelled_by,
      messageError,
      showModalApproval,
      description,
      approval,
      date_from,
      date_to,
      event,
      checkAll,
      isDetail,
      id,
      showModalSplitRpp,
      splitQty,
      previewSplittedRpp,
      error_split,
      detail_split,
      shipped_to_name,
      shipped_to_duration,
      list_filter_legend,
      select_items_prod_facility,
      select_items_prod_facility_com,
      prod_facility_code,
      previewSplittedRpp_update,
      isCip,
      time_to_add,
      isEditCIP,
      preview_time,
      error_preview_time,
      details_checked,
      download_export_url,
      start_date,
      finish_date,
      isExportExcelShowing,
      showModalDraftRPP,
      showModalConfirmRPP,
      showModalEditRPP,
      data_draft_rpp,
      total_data_draft_rpp,
      prod_facility_create,
      date_create,
      is_split_generate,
      current_month,
      min_date,
      max_date,
      show_sales_forecast,
    } = this.state;

    const style_approval_title = {
      backgroundColor: "#e9f8fb",
      color: "black",
      fontWeight: "bold",
      margin: 5,
    };

    const style_align_center = { "vertical-align": "middle" };

    const Line = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 5,
          borderRadius: 2,
        }}
      />
    );

    const EventLegend = () => {
      return (
        <Container>
          <Row className="mb-3">
            <Col xs={12} md={1}>
              <i className="fa fa-eye font-weight-bold" aria-hidden="true"></i>
            </Col>
            <Col xs={12} md={11} className="font-weight-bold">
              Event Name
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} md={1} className="align-middle">
              <Form.Check
                type="checkbox"
                id="checkAll"
                checked={checkAll}
                onChange={this.handleCheckLegendAll.bind(this)}
              />
            </Col>
            <Col xs={12} md={11}>
              <div className="text-wrap">
                {" "}
                {checkAll ? "Uncheck All" : "Check All"}
              </div>
            </Col>
          </Row>
          <hr></hr>
          {list_filter_legend.map((legend, idx) => {
            return (
              <Row className="mb-2">
                {/*checkbox*/}
                <Col xs={12} md={1} className="align-middle">
                  <Form.Check
                    type="checkbox"
                    id={idx.toString()}
                    checked={legend.checked}
                    onChange={this.handleCheckLegend.bind(this)}
                  />
                </Col>
                {/*color*/}
                <Col xs={12} md={1}>
                  <div className="color-palette-set">
                    <div className={legend.color_class + " rounded"}>
                      <span>&nbsp;</span>
                    </div>
                  </div>
                </Col>
                {/*text*/}
                <Col xs={12} md={10}>
                  <div className="text-wrap"> {legend.type}</div>
                </Col>
              </Row>
            );
          })}
        </Container>
      );
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <DatePickerWrapperStyles />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalApproval}
            size="lg"
            backdrop="static"
            onHide={this.onModalApprovalHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalHeader closeButton className="bg-info text-white">
              Move {title}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Table hover size="sm">
                      <tbody>
                        <tr>
                          <td style={style_approval_title}>Material</td>
                          <td>{title}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Type</td>
                          <td>{type}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Move From</td>
                          <td>{date_from}</td>
                        </tr>
                        <tr>
                          <td style={style_approval_title}>Move To</td>
                          <td>{date_to}</td>
                        </tr>
                      </tbody>
                    </Table>
                    {isDetail ? (
                      <Alert
                        variant="warning"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Waiting for PPC Manager Approval
                      </Alert>
                    ) : (
                      <Form>
                        <Form.Group className="mb-3" controlId="message">
                          <Form.Label>Message</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            name="description"
                            value={description || ""}
                            readOnly={isDetail}
                            onChange={this.onChangeValue}
                          />
                        </Form.Group>
                        <Button
                          className="float-left"
                          onClick={this.onModalApprovalHide}
                          variant="danger"
                        >
                          <i class="fas fa-times"></i> Cancel
                        </Button>
                        <Button
                          className="float-right"
                          variant="success"
                          onClick={this.handleApproval}
                        >
                          <i class="fas fa-check"></i> Submit
                        </Button>
                      </Form>
                    )}
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            dialogClassName={
              status == "unconfirmed" && type == "RPP"
                ? ""
                : is_cancel
                ? "full-modal-dialog"
                : ""
            }
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated  "
                  : "needs-validation "
              }
              autoComplete="off"
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>{isReadOnly ? "Detail" : "Create"} RPP</ModalTitle>
              </ModalHeader>
              <ModalBody>
                {is_cancel && (
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label
                          htmlFor="etd"
                          style={{
                            color: "white",
                            backgroundColor: "red",
                            padding: "5px",
                          }}
                        >
                          There is ETA that has been cancelled by {cancelled_by}
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}
                {messageError && !is_cancel && (
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label
                          htmlFor="etd"
                          style={{
                            color: "white",
                            backgroundColor: "red",
                            padding: "5px",
                          }}
                        >
                          {messageError}
                        </label>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} md={2}>
                    <div className="form-group">
                      <label htmlFor="prod_facility">Category</label>
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="prod_facility"
                        value={prod_facility || ""}
                        placeholder="Prod Facility"
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="form-group">
                      <label htmlFor="type">Type</label>
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="type"
                        value={type || ""}
                        placeholder="Type"
                        readOnly={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={2}>
                    <div className="form-group">
                      <label htmlFor="date">Date</label>
                    </div>
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="date"
                        value={dateFormat(date, "yyyy-mm-dd") || ""}
                        readOnly={true}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={1}>
                    <div className="form-group">
                      <label htmlFor="Start">Start</label>
                    </div>
                  </Col>
                  <Col xs={12} md={3}>
                    <DatePicker
                      wrapperClassName="date_picker full-width"
                      disabledKeyboardNavigation
                      selected={start_time}
                      onChange={this.handleDate.bind(this, "start_time")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Start"
                      dateFormat="h:mm aa"
                      popperContainer={Popper}
                      calendarContainer={DatePickerCalendar}
                      className="form-control form-control-sm"
                      disabled={isReadOnly}
                    />
                  </Col>
                  <Col xs={12} md={1}>
                    <div className="form-group">
                      <label htmlFor="Finish">Finish</label>
                    </div>
                  </Col>

                  <Col xs={12} md={2}>
                    <DatePicker
                      minDate={new Date(date)}
                      minTime={
                        new Date(date).getDate() ===
                        new Date(
                          finish_time ?? new Date(date).getTime()
                        ).getDate()
                          ? moment(new Date(date)).add(30, "minutes").toDate()
                          : new Date(date).setHours(0, 0, 0)
                      }
                      maxTime={new Date(date).setHours(23, 59, 59)}
                      wrapperClassName="date_picker full-width"
                      disabledKeyboardNavigation
                      selected={finish_time}
                      onChange={this.handleDate.bind(this, "finish_time")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Finish"
                      dateFormat="h:mm aa"
                      popperContainer={Popper}
                      calendarContainer={DatePickerCalendar}
                      injectTimes={[new Date(date).setHours(23, 59, 59)]}
                      className="form-control form-control-sm"
                      disabled={isReadOnly}
                    />

                    {isEditCIP && (
                      <InputGroup size="sm" className="mt-2">
                        <Col xs={12} md={12}>
                          <Line color={"#cccccc"} />
                          <Form.Label htmlFor="inputPassword5">
                            Edit Finish Time
                          </Form.Label>
                        </Col>

                        <FormControl
                          placeholder="Time"
                          aria-label="Time"
                          aria-describedby="basic-addon2"
                          value={time_to_add || ""}
                          name="time_to_add"
                          onChange={this.onChangeValue}
                          size="sm"
                          type="number"
                          step="0.5"
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="form-control form-control-sm"
                        >
                          hour
                        </InputGroup.Text>
                      </InputGroup>
                    )}
                    {isEditCIP && (
                      <div className="d-flex flex-row justify-content-center">
                        <i className="my-2 fas fa-arrow-down"></i>
                      </div>
                    )}
                    {isEditCIP && (
                      <FormGroup>
                        <Form.Label htmlFor="inputPassword5">
                          Finish Time After Update
                        </Form.Label>
                        <FormControl
                          placeholder="Time"
                          aria-label="Time"
                          aria-describedby="basic-addon2"
                          value={preview_time || ""}
                          name="preview_time"
                          size="sm"
                          type="text"
                          readOnly
                        />
                        {error_preview_time && (
                          <Form.Label
                            className="mt-2"
                            style={{ color: "red" }}
                            htmlFor="inputPassword5"
                          >
                            {error_preview_time}
                          </Form.Label>
                        )}
                      </FormGroup>
                    )}
                  </Col>
                  {isCip && type == "CIP" && !error_preview_time && false && (
                    <Col xs={12} md={1}>
                      <Button
                        size="sm"
                        variant={
                          isEditCIP ? "outline-warning" : "outline-success"
                        }
                        onClick={() =>
                          this.setState((prevState) => {
                            return {
                              ...prevState,
                              isEditCIP: !prevState.isEditCIP,
                            };
                          })
                        }
                      >
                        {isEditCIP ? (
                          <i className="fas fa-minus"></i>
                        ) : (
                          <i className="fas fa-plus"></i>
                        )}
                      </Button>{" "}
                    </Col>
                  )}
                </Row>
                {isRPP && (
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="material_name">Material Name</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_name"
                          value={material_name || ""}
                          placeholder="Material Name"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md={1}>
                      <label htmlFor="qty">Qty</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="qty"
                          value={qty || ""}
                          placeholder="Qty"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="uom"
                          value={uom || ""}
                          placeholder="UOM"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {isCip && (
                  <Row>
                    <Col xs={12} md={2}>
                      <label htmlFor="data_source">Data Source</label>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="data_source"
                          value={data_source || ""}
                          placeholder="Data Source"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {isRPP && (
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="material_name">Status</label>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="status"
                          value={status || ""}
                          placeholder="status"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={2}>
                      <label htmlFor="data_source">Data Source</label>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="data_source"
                          value={data_source || ""}
                          placeholder="Data Source"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {isRPP && material_name_parent && (
                  <Row>
                    <Col xs={12} md={2}>
                      <label htmlFor="material_name_parent">
                        FG Component of
                      </label>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_name_parent"
                          value={material_name_parent || ""}
                          placeholder="Material Parent"
                          readOnly={isReadOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {isCip && isEditCIP && !error_preview_time && preview_time && (
                  <Button
                    className="float-right m-2"
                    variant="success"
                    onClick={this.handleSubmitCip}
                  >
                    Process <i className="fas fa-edit"></i>
                  </Button>
                )}
                <Row>
                  <Col xs={12} md={12}>
                    {details.length > 0 && status == "confirmed" && !isCip && (
                      <Table
                        className="float-right"
                        bordered
                        hover
                        size="sm"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <thead>
                          <tr key="00">
                            <th
                              colSpan={is_cancel ? "10" : "9"}
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              Detail
                            </th>
                          </tr>
                          <tr key="0">
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            ></th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              Choose
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Material{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Qty{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Main Qty{" "}
                            </th>

                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Source{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Customer{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              ETD{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              ETA{" "}
                            </th>
                            {is_cancel && (
                              <th
                                style={{
                                  backgroundColor: "#17A2B8",
                                  color: "white",
                                }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {details &&
                            details.map((x, i) => (
                              <tr
                                key={i + 1}
                                style={
                                  x.is_cancel && {
                                    backgroundColor: "black",
                                    color: "white",
                                  }
                                }
                              >
                                <td key={`td-11-${i + 1}`}>{i + 1}</td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    id={`check${i}`}
                                    checked={details_checked[i]}
                                    onChange={this.handleCheckDetails.bind(
                                      this,
                                      i
                                    )}
                                  />
                                </td>
                                <td key={`td-2d-${i + 1}`}>{x.material}</td>
                                <td key={`td-2-${i + 1}`}>
                                  {x.qty} {x.uom}
                                </td>
                                <td key={`td-2-main-qty-${i + 1}`}>
                                  {x.main_qty} {x.uom}
                                </td>
                                <td key={`td-3-${i + 1}`}>{x.source}</td>
                                <td key={`td-4-${i + 1}`}>{x.customer}</td>
                                <td key={`td-5-${i + 1}`}>{x.etd}</td>
                                <td key={`td-6-${i + 1}`}>{x.eta}</td>
                                {is_cancel && (
                                  <td key={`td-7-${i + 1}`}>
                                    {x.is_cancel && (
                                      <div className="justify-content-between">
                                        <Button
                                          variant="warning"
                                          onClick={this.handleCancelRPP.bind(
                                            this,
                                            x.id_detail_eta
                                          )}
                                          className="btn-sm"
                                        >
                                          <i className="fas fa-times" /> Cancel
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button
                                          variant="primary"
                                          onClick={this.handleConvertManualRPP.bind(
                                            this,
                                            x.id_detail_eta
                                          )}
                                          className="btn-sm"
                                        >
                                          <i className="fas fa-history" />{" "}
                                          Convert Manual
                                        </Button>
                                      </div>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                    {detail_split.length > 0 && (
                      <Table
                        className="float-right"
                        bordered
                        hover
                        size="sm"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <thead>
                          <tr key="00">
                            <th
                              colSpan={is_cancel ? "8" : "7"}
                              className="bg-warning"
                            >
                              Detail Split
                            </th>
                          </tr>
                          <tr key="0">
                            <th className="bg-warning"></th>
                            <th className="bg-warning"> Material </th>
                            <th className="bg-warning"> Qty </th>

                            <th className="bg-warning"> Source </th>
                            <th className="bg-warning"> Customer </th>
                            <th className="bg-warning"> ETD </th>
                            <th className="bg-warning"> ETA </th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail_split &&
                            detail_split.map((x, i) => (
                              <tr key={i + 1}>
                                <td key={`td-1-${i + 1}`}>{i + 1}</td>
                                <td key={`td-2d-${i + 1}`}>{x.material}</td>
                                <td key={`td-2-${i + 1}`}>
                                  {x.qty} {x.uom}
                                </td>
                                <td key={`td-3-${i + 1}`}>{x.source}</td>
                                <td key={`td-4-${i + 1}`}>{x.customer}</td>
                                <td key={`td-5-${i + 1}`}>{x.etd}</td>
                                <td key={`td-6-${i + 1}`}>{x.eta}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
              </ModalBody>
              {status == "unconfirmed" && type == "RPP" && (
                <ModalFooter className="justify-content-between">
                  {(data_source == "MANUAL" ||
                    data_source == "salesforcast") && (
                    <Button
                      variant="warning"
                      className=" "
                      onClick={this.handleDeleteUnconfirmRPP}
                    >
                      <i className="fas fa-trash" /> Delete RPP
                    </Button>
                  )}
                  <Button
                    variant="info"
                    className="float-right"
                    onClick={() =>
                      this.setState({
                        showModal: !showModal,
                        showModalSplitRpp: !showModalSplitRpp,
                      })
                    }
                  >
                    <i className="fas fa-code-branch" /> Split RPP
                  </Button>
                  <div>
                    <Button
                      variant="success"
                      className="float-right"
                      onClick={this.handleConfirmRpp.bind(this)}
                    >
                      <i className="fas fa-save" /> Confirm RPP
                    </Button>
                    <Button
                      variant="info"
                      className="float-right"
                      style={{ marginRight: 10 }}
                      onClick={this.handleEditRpp.bind(this)}
                    >
                      <i className="fas fa-edit" /> Edit RPP
                    </Button>
                  </div>
                </ModalFooter>
              )}
              {status == "confirmed" && type == "RPP" && (
                <ModalFooter className="d-flex flex-row justify-content-between">
                  <Button
                    disabled={details_checked.every((x) => x === false)}
                    variant="success"
                    onClick={this.handleUnconfirm}
                  >
                    <i className="fas fa-times" /> Unconfirm
                  </Button>
                  <Button
                    variant="info"
                    className="invisible"
                    onClick={() =>
                      this.setState({
                        showModal: !showModal,
                        showModalSplitRpp: !showModalSplitRpp,
                      })
                    }
                  >
                    <i className="fas fa-code-branch" /> Split RPP
                  </Button>
                </ModalFooter>
              )}
              {isCip && data_source == "MANUAL" && (
                <ModalFooter className="d-flex flex-row justify-content-between">
                  <Button variant="warning" onClick={this.handleCancelCip}>
                    <i className="fas fa-times" /> Cancel{" "}
                    {type == "CIP" ? "CIP" : "Maintenance"}
                  </Button>
                </ModalFooter>
              )}
            </form>
          </Modal>

          <Modal
            show={showModalSplitRpp}
            backdrop="static"
            size="xl"
            onHide={this.onModalSplitHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Split Rpp</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Table
                      className="float-right"
                      bordered
                      hover
                      size="sm"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <thead>
                        <tr key="before">
                          <th colSpan={5} key="before0" className="bg-warning">
                            Before
                          </th>
                        </tr>
                        <tr key="head">
                          <th key="head0">No.</th>
                          <th key="head1">Material</th>
                          <th key="head3"> Source </th>
                          <th key="head4"> Customer </th>
                          <th key="head2">Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={"row1"}>
                          <td
                            key={"row01"}
                            rowSpan={details.length + 2}
                            style={style_align_center}
                          >
                            1
                          </td>
                          <td
                            key={"row11"}
                            rowSpan={details.length + 2}
                            style={style_align_center}
                          >
                            {material_name}
                          </td>
                        </tr>
                        {details.map((item, i) => [
                          <tr key={"row2"}>
                            <td
                              key={`td-3-${i + 1}`}
                              style={style_align_center}
                            >
                              {item.source}
                            </td>
                            <td
                              key={`td-4-${i + 1}`}
                              style={style_align_center}
                            >
                              {item.customer}
                            </td>
                            <td
                              key={"row2" + (i + 1)}
                              style={style_align_center}
                            >
                              {item.qty} {item.uom}
                            </td>
                          </tr>,
                        ])}

                        <tr key={"rowTotal"}>
                          <td
                            key={"row0Total"}
                            colSpan={2}
                            className="font-weight-bold bg-light"
                            style={style_align_center}
                          >
                            Total
                          </td>
                          <td
                            key={"row1Total"}
                            style={style_align_center}
                            className="font-weight-bold bg-light"
                          >
                            {qty} {uom}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {is_split_generate ? (
                  <Row>
                    <Col xs={3} md={3}>
                      <Line color={"#cccccc"} />
                    </Col>
                    <Col
                      xs={6}
                      md={6}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <InputGroup className="mb-3">
                        <DatePicker
                          selected={date_create}
                          disabledKeyboardNavigation
                          minDate={min_date}
                          maxDate={max_date}
                          onChange={this.handleDate.bind(this, "date_create")}
                          name="date_create"
                          wrapperClassName="datePickerCustom"
                          dateFormat="dd MMMM yyyy"
                          className="form-control"
                          required
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Qty"
                          aria-describedby="basic-addon2"
                          type="number"
                          value={splitQty || ""}
                          name="splitQty"
                          onChange={this.onChangeValue}
                          min={0}
                          step="0.001"
                          className="Input-number-hide-step"
                        />
                        <Button
                          variant="info"
                          id="button-addon2"
                          onClick={this.handlePreviewSplit}
                        >
                          Split <i className="fas fa-arrow-circle-down"></i>
                        </Button>
                        <Form.Label className="text-danger">
                          {error_split}
                        </Form.Label>
                      </InputGroup>
                    </Col>
                    <Col xs={3} md={3}>
                      <Line color={"#cccccc"} />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={5} md={5}>
                      <Line color={"#cccccc"} />
                    </Col>
                    <Col
                      xs={2}
                      md={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Qty"
                          aria-describedby="basic-addon2"
                          type="number"
                          value={splitQty || ""}
                          name="splitQty"
                          onChange={this.onChangeValue}
                          min={0}
                          step="0.001"
                          className="Input-number-hide-step"
                        />
                        <Button
                          variant="info"
                          id="button-addon2"
                          onClick={this.handlePreviewSplit}
                        >
                          Split <i className="fas fa-arrow-circle-down"></i>
                        </Button>
                        <Form.Label className="text-danger">
                          {error_split}
                        </Form.Label>
                      </InputGroup>
                    </Col>
                    <Col xs={5} md={5}>
                      <Line color={"#cccccc"} />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex align-items-center justifiy-content-center"
                  ></Col>
                  {previewSplittedRpp && (
                    <TablePreviewSplit
                      data={previewSplittedRpp}
                      prod_facility={select_items_prod_facility}
                      prod_facility_default={prod_facility_code}
                      onChange={(data) => {
                        this.setState({
                          previewSplittedRpp_update: [...data],
                        });
                      }}
                    />
                  )}
                </Row>
              </Container>
            </ModalBody>
            {previewSplittedRpp && (
              <ModalFooter>
                <Container>
                  <Button
                    className="float-right"
                    variant="success"
                    onClick={this.handleSubmitSplit}
                  >
                    <i className="fas fa-save"></i> Submit
                  </Button>
                </Container>
              </ModalFooter>
            )}
          </Modal>
          <Modal
            show={showModalCreate}
            size="xl"
            backdrop="static"
            onHide={this.onModalCreateHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle> Create RPP</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <ApsRpp handleReload={this.handleReload} />
            </ModalBody>
          </Modal>
          <Modal
            show={showModalRawMaterial}
            size="xl"
            backdrop="static"
            onHide={this.onModalRawMaterialHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle> Raw Material Requirement</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <APSRawMaterial handleReload={this.handleReload} />
            </ModalBody>
          </Modal>
          <Modal
            show={showModalImportRpp}
            size="xl"
            backdrop="static"
            onHide={this.onModalImportRppHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Import RPP</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <ImportRPP setOpenModal={this.onModalImportRppHide}/>
              {/* <APSRawMaterial handleReload={this.handleReload} /> */}
            </ModalBody>
          </Modal>
          <Modal
            show={showModalForecast}
            size="lg"
            backdrop="static"
            onHide={this.onModalForecastHide}
          >
            <form
              className={
                submittedForecast
                  ? "needs-validation was-validated  "
                  : "needs-validation "
              }
              autoComplete="off"
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>ETD Detail</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  {is_cancel && (
                    <Row>
                      <Col xs={12} md={12}>
                        <div className="form-group">
                          <label
                            htmlFor="etd"
                            style={{
                              color: "white",
                              backgroundColor: "red",
                              padding: "5px",
                            }}
                          >
                            There is ETA that has been cancelled by{" "}
                            {cancelled_by}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="date">ETA</label>
                      </div>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="eta_date"
                          value={dateFormat(eta_date, "yyyy-mm-dd") || ""}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1}></Col>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <label htmlFor="date">ETD</label>
                      </div>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="date"
                          value={dateFormat(date, "yyyy-mm-dd") || ""}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="customer_name">Sold To</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="customer_name"
                          value={customer_name || ""}
                          placeholder="Sold To"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="shipped_to_name">Shipped To</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="shipped_to_name"
                          value={shipped_to_name || ""}
                          placeholder="Shipped To"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1} className="mr-3">
                      <label htmlFor="shipped_to_duration">
                        Delivery Duration
                      </label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="shipped_to_duration"
                          value={shipped_to_duration || ""}
                          placeholder="Delivery Duration"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="material_name">Material Name</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_name"
                          value={material_name || ""}
                          placeholder="Material Name"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1} className="mr-3">
                      <label htmlFor="qty">Qty</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="form-group input-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="qty"
                          value={qty || ""}
                          placeholder="Qty"
                          readOnly={true}
                          aria-describedby="basic-addon2"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text form-control form-control-sm"
                            id="basic-addon2"
                            placeholder="UOM"
                            readOnly={true}
                          >
                            {uom || ""}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              {status == "unconfirmed" && (
                <ModalFooter>
                  <Button
                    variant="success"
                    className="float-right"
                    onClick={this.handleConfirmETD.bind(this)}
                  >
                    <i className="fas fa-save" /> Confirm ETD
                  </Button>
                </ModalFooter>
              )}
            </form>
          </Modal>
          <Modal
            show={showModalETA}
            size="lg"
            backdrop="static"
            onHide={this.onModalETAHide}
          >
            <form
              className={
                submittedETA
                  ? "needs-validation was-validated  "
                  : "needs-validation "
              }
              autoComplete="off"
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>ETA Detail</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="date">ETA</label>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="eta_date"
                          value={dateFormat(eta_date, "yyyy-mm-dd") || ""}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="customer">Customer Name</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="customer_name"
                          value={customer_name || ""}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={2}>
                      <div className="form-group">
                        <label htmlFor="material_name">Material Name</label>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="material_name"
                          value={material_name || ""}
                          placeholder="Material Name"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <label htmlFor="qty">Qty</label>
                    </Col>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="qty"
                          value={qty || ""}
                          placeholder="Qty"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={1}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="uom"
                          value={uom || ""}
                          placeholder="UOM"
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalMove}
            size="xl"
            backdrop="static"
            onHide={this.onModalMoveHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Detail Move RPP</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={8}>
                    <Row>
                      <Col xs={12} md={12}>
                        <h2>Origin</h2>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={2}>
                        <div className="form-group">
                          <label htmlFor="prod_facility">Category</label>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="prod_facility"
                            value={prod_facility || ""}
                            placeholder="Prod Facility"
                            readOnly={true}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={2}>
                        <div className="form-group">
                          <label htmlFor="type">Type</label>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="type"
                            value={type || ""}
                            placeholder="Type"
                            readOnly={true}
                          />
                        </div>
                      </Col>
                    </Row>

                    {isRPP && (
                      <Row>
                        <Col xs={12} md={2}>
                          <div className="form-group">
                            <label htmlFor="material_name">Material Name</label>
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="material_name"
                              value={material_name || ""}
                              placeholder="Material Name"
                              readOnly={true}
                            />
                          </div>
                        </Col>

                        <Col xs={12} md={1}>
                          <label htmlFor="qty">Qty</label>
                        </Col>
                        <Col xs={12} md={1}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="qty"
                              value={qty || ""}
                              placeholder="Qty"
                              readOnly={true}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={1}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="uom"
                              value={uom || ""}
                              placeholder="UOM"
                              readOnly={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs={12} md={2}>
                        <div className="form-group">
                          <label htmlFor="date">Date</label>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="date"
                            value={dateFormat(date, "yyyy-mm-dd") || ""}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={3}>
                        <div className="form-group">
                          <label htmlFor="sequence">Choose Sequence *</label>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <form
                          className={
                            submittedMove
                              ? "needs-validation was-validated  "
                              : "needs-validation "
                          }
                          autoComplete="off"
                          noValidate
                        >
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control form-control-sm"
                              name="sequence"
                              value={sequence || ""}
                              onChange={this.onChangeValue}
                              placeholder="00"
                              required
                            />

                            {errors.sequence && (
                              <div className="invalid-feedback">
                                {errors.sequence}
                              </div>
                            )}
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4}>
                    <Row>
                      <Col xs={12} md={12}>
                        <h2>Destination</h2>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <TableDetailCalendar data={chosenData} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="success"
                className="float-right"
                onClick={this.handleSubmitMove.bind(this)}
              >
                <i className="fas fa-save" /> Submit
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            show={showModalInformation}
            backdrop="static"
            size="m"
            onHide={this.onModalInformationHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Legend Colours</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <EventLegend />
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalRecalculate}
            backdrop="static"
            size="m"
            onHide={this.onModalCalculateHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Recalculate</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <label htmlFor="currency">Start Date</label>
                    <div className={"form-group"}>
                      <DatePicker
                        selected={start_date}
                        disabledKeyboardNavigation
                        onChange={this.handleDate.bind(this, "start_date")}
                        name="start_date"
                        wrapperClassName="datePickerCustom"
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-sm"
                        required
                      />
                      {errors.start_date && (
                        <div className="invalid-feedback">
                          {errors.start_date}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <label htmlFor="currency">Finish Date</label>
                    <div className={"form-group"}>
                      <DatePicker
                        selected={finish_date}
                        disabledKeyboardNavigation
                        onChange={this.handleDate.bind(this, "finish_date")}
                        name="finish_date"
                        wrapperClassName="datePickerCustom"
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-sm"
                        required
                      />
                      {errors.finish_date && (
                        <div className="invalid-feedback">
                          {errors.finish_date}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Button
                      variant="success"
                      onClick={this.handleSubmitRecalculate}
                      className="float-right"
                    >
                      <i className="fas fa-save" /> Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalDraftRPP}
            backdrop="static"
            size="xl"
            onHide={this.onModalDraftRPPHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Draft RPP {current_month}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <table className="table table-bordered">
                      <thead>
                        <th>Material</th>
                        <th>Customer</th>
                        <th>Qty</th>
                        <th>Uom</th>
                        <th className="text-center">Action</th>
                      </thead>
                      <tbody>
                        {data_draft_rpp.length === 0 && (
                          <tr>
                            <td align="center" colSpan={5}>
                              Not found draft rpp
                            </td>
                          </tr>
                        )}
                        {data_draft_rpp &&
                          data_draft_rpp.length > 0 &&
                          data_draft_rpp.map((item) => (
                            <tr>
                              <td>{item.material_desc}</td>
                              <td>{item.customer_desc}</td>
                              <td>{item.qty}</td>
                              <td>{item.uom}</td>
                              <td align="center">
                                {item.qty > 0 ? (
                                  <div>
                                    <button
                                      className="btn btn-sm btn-info"
                                      onClick={() => {
                                        this.splitDraftRPP(item);
                                      }}
                                    >
                                      <i className="fas fa-code-branch" /> Split
                                      RPP
                                    </button>
                                    <button
                                      style={{ marginLeft: "5px" }}
                                      className="btn btn-sm btn-success"
                                      onClick={() => {
                                        this.hanldeConfirmDraftRPP(item);
                                      }}
                                    >
                                      <i className="fas fa-save" /> Confirm RPP
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalConfirmRPP}
            backdrop="static"
            size="m"
            onHide={this.onModalConfirmRPP}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Confirm Draft RPP</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <label htmlFor="currency">Date</label>
                    <div
                      className={
                        date_create != "This field is required"
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <DatePicker
                        selected={date_create}
                        disabledKeyboardNavigation
                        minDate={min_date}
                        maxDate={max_date}
                        onChange={this.handleDate.bind(this, "date_create")}
                        name="date_create"
                        wrapperClassName="datePickerCustom"
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-sm"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div
                      className={
                        prod_facility_create != "This field is required"
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <label htmlFor="currency">Prod Facility</label>
                      <select
                        className={
                          errors.prod_facility != "This field is required"
                            ? "form-control form-control-sm "
                            : "form-control form-control-sm  has-error has-feedback"
                        }
                        name="prod_facility_edit"
                        onChange={this.onChangeProdFacility}
                        style={{ padding: 0 }}
                        defaultValue={prod_facility_create}
                        required
                      >
                        <option value="">Choose</option>
                        {select_items_prod_facility_com &&
                          select_items_prod_facility_com.map(function (
                            item,
                            i
                          ) {
                            return (
                              <option value={item.facility_code} key={i}>
                                {item.facility_code} - {item.facility_desc}
                              </option>
                            );
                          })}
                      </select>
                      {prod_facility_create && (
                        <div className="invalid-feedback"></div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Button
                      variant="success"
                      onClick={this.handleSubmitDraftRPP}
                      className="float-right"
                    >
                      <i className="fas fa-save" /> Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalEditRPP}
            backdrop="static"
            size="sm"
            onHide={this.onModalEditRPP}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Edit Qty</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="qty"
                        value={new_qty ? new_qty : qty}
                        placeholder="Qty"
                        onChange={(e) =>
                          this.setState({ new_qty: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="info"
                className="float-right"
                onClick={this.onModalEditRPP}
              >
                <i className="fas fa-times" /> Cancel
              </Button>
              <Button
                variant="success"
                className="float-right"
                onClick={this.handleEditQty}
              >
                <i className="fas fa-save" /> Submit
              </Button>
            </ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Calendar Planning &amp; Scheduling
                            <Button
                              style={{ marginLeft: "5px" }}
                              variant={
                                show_sales_forecast ? "primary" : "secondary"
                              }
                              onClick={this.showSalesForecast}
                              className="float-right position-relative"
                            >
                              <i className="fas fa-info-circle" /> Sales
                              Forecast
                            </Button>
                            <Button
                              style={{ marginLeft: "5px" }}
                              variant="secondary"
                              onClick={this.toggleDraftRPP}
                              className="float-right position-relative"
                            >
                              <i className="fas fa-book" /> Draft RPP
                              {total_data_draft_rpp !== "0" && (
                                <span className="position-absolute badge rounded-pill badge-danger">
                                  {total_data_draft_rpp}
                                </span>
                              )}
                            </Button>
                            <Button
                              style={{ marginLeft: "5px" }}
                              variant="warning"
                              onClick={this.toggleImportRPP}
                              className="float-right"
                            >
                              <i className="fas fa-upload" /> Import RPP
                            </Button>
                            <Button
                              style={{ marginLeft: "5px" }}
                              variant="warning"
                              onClick={this.toggleRPP}
                              className="float-right"
                            >
                              <i className="fas fa-plus-square" /> RPP
                            </Button>
                            <Button
                              style={{ marginLeft: "5px" }}
                              variant="warning"
                              onClick={this.toggleRawMaterial}
                              className="float-right"
                            >
                              <i className="fas fa-info-circle" /> Raw Material
                            </Button>
                          </h3>

                          <div className="card-tools float-right">
                            <Button
                              variant="primary"
                              onClick={this.toggleInformation}
                              className="float-right"
                            >
                              <i className="fas fa-info" /> Legend Colours
                            </Button>
                          </div>
                          {isExportExcelShowing && (
                            <div className="card-tools float-right">
                              <Button
                                variant="success"
                                href={download_export_url}
                                className="float-right mr-4"
                              >
                                <i className="fas fa-file" /> Export to Excel
                              </Button>
                            </div>
                          )}
                          <div className="card-tools float-right">
                            <Button
                              variant="dark"
                              onClick={this.toggleRecalculate}
                              className="float-right mr-4"
                            >
                              <i className="fas fa-calculator" /> Recalculate
                            </Button>
                          </div>
                          <Row>
                            <Col xs={12} md={3}>
                              <label className="float-right">Category: </label>
                            </Col>
                            <Col xs={12} md={3}>
                              <select
                                className="form-control form-control-sm"
                                value={prod_facility}
                                name="prod_facility"
                                onChange={this.onChangeValue}
                              >
                                {option &&
                                  option.map((l, k) => {
                                    return (
                                      <option value={l.code} key={k}>
                                        {l.code}
                                      </option>
                                    );
                                  })}
                              </select>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col xs={12} md={9}>
                              <DragAndDropCalendar
                                selectable
                                events={events.filter(
                                  (event) => event.isShowing
                                )}
                                localizer={localizer}
                                defaultDate={defaultDate}
                                date={defaultDate}
                                onNavigate={() => {}}
                                views={["month", "day", "agenda"]}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 780 }}
                                components={{
                                  event: this.Event,
                                  toolbar: (props) => (
                                    <CustomToolbar
                                      {...props}
                                      prod_code={prod_facility}
                                      handleNavigate={this.handleNavigate.bind(
                                        this
                                      )}
                                    />
                                  ),
                                }}
                                timeslots={1}
                                step={TIME_STEP}
                                dayLayoutAlgorithm="no-overlap"
                                prod_code={prod_facility}
                                showAllEvents={true}
                                onSelectSlot={this.handleSelect.bind(this)}
                                onSelectEvent={this.handleShowEvent.bind(this)}
                                eventPropGetter={this.eventStyle}
                                dayPropGetter={this.dayPropStyle}
                                onEventDrop={this.moveEvent}
                                popup={true}
                                dragFromOutsideItem={
                                  this.state.displayDragItemInCell
                                    ? this.dragFromOutsideItem
                                    : null
                                }
                                draggableAccessor={this.handleDraggable}
                                onDropFromOutside={this.onDropFromOutside}
                                handleDragStart={this.handleDragStart}
                              />
                            </Col>
                            <Col xs={12} md={3}>
                              {show_sales_forecast ? (
                                <SalesForecast
                                  data={selectedData}
                                  isShow={show_sales_forecast}
                                  prod_facility={prod_facility}
                                  reloadDataBackend={this.reloadDataBackend}
                                />
                              ) : (
                                <TableDetailCalendar data={selectedData} />
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(APSCalendar);
