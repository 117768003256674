import MAPRNDService from "../services/maprnd.service";

export const readMAPRND = (date, material, classification) => {
  return MAPRNDService.readMAPRND(date, material, classification).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readMAPRNDByMonth = (query) => {
  return MAPRNDService.readMAPRNDByMonth(query).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
