import BOMPowderService from "../services/bom_powder.service";

export const addBOMPowder = (payload) => {
  return BOMPowderService.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readBOMPowder = (query_string) => {
  return BOMPowderService.readBOMPowder(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};

export const deleteBOMPowder = (payload) => {
  return BOMPowderService.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
