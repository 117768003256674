import React, { Component } from "react";
import OutTable from "./OutTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const WorksheetBomFg = ({ worksheet }) => {
  return (
    <div>
      <Tabs defaultActiveKey="first" variant="pills">
        {worksheet &&
          worksheet.map((e, i) => {
            return i === 0 ? (
              <Tab eventKey="first" title={e.worksheet} key={i}>
                <span>
                  <b>Classification :</b> {e.special_case.classification}{" "}
                </span>
                <br />
                <span>
                  <b>No Material FG :</b> {e.special_case.no_material_fg} -{" "}
                  {e.special_case.material_description_fg}{" "}
                </span>
                <br />
                <span>
                  <b>QTY :</b> {e.special_case.qty} {e.special_case.uom}{" "}
                </span>
                <br />
                <span>
                  <b>Prod Facility :</b> {e.special_case.prod_facility} -{" "}
                  {e.special_case.description_facility}{" "}
                </span>
                <br />
                <span>
                  <b>Machine Capacity :</b> {e.special_case.machine_capacity}{" "}
                  {e.special_case.unit_machine_capacity}{" "}
                </span>
                <br />
                <br />
                <OutTable
                  data={e.data}
                  cols={e.cols}
                  otherClass="tableFreezeHeader table table-striped table-compact"
                />
              </Tab>
            ) : (
              <Tab eventKey={e.worksheet} title={e.worksheet} key={i}>
                <span>
                  <b>Classification :</b> {e.special_case.classification}{" "}
                </span>
                <br />

                <span>
                  <b>No Material FG :</b> {e.special_case.no_material_fg} -{" "}
                  {e.special_case.material_description_fg}{" "}
                </span>
                <br />
                <span>
                  <b>QTY :</b> {e.special_case.qty} {e.special_case.uom}{" "}
                </span>
                <br />
                <span>
                  <b>Prod Facility :</b> {e.special_case.prod_facility} -{" "}
                  {e.special_case.description_facility}{" "}
                </span>
                <br />
                <span>
                  <b>Machine Capacity :</b> {e.special_case.machine_capacity}{" "}
                  {e.special_case.unit_machine_capacity}{" "}
                </span>
                <br />
                <br />
                <OutTable
                  data={e.data}
                  cols={e.cols}
                  otherClass="tableFreezeHeader table table-striped table-compact"
                />
              </Tab>
            );
          })}
      </Tabs>
    </div>
  );
};

export default WorksheetBomFg;
