import PriceListRndService from "../services/price_list_rnd.service";

export const readPriceListRnd = (query_string) => {
  return PriceListRndService.readPriceList(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
