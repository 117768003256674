import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class DailySpotPriceService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "daily_spot_price", payload, {
      headers: authHeader(),
    });
  }
  addBulk(payload) {
    return axios.post(API_URL_DEFAULT + "daily_spot_price/bulk", payload, {
      headers: authHeader(),
    });
  }
  createAddMore(payload) {
    return axios.post(API_URL_DEFAULT + "daily_spot_price/add_more", payload, {
      headers: authHeader(),
    });
  }
  createOverwrite(payload) {
    return axios.post(
      API_URL_DEFAULT + "daily_spot_price/?type=overwrite",
      payload,
      { headers: authHeader() }
    );
  }
  readDailySpotPriceNewFormat(payload) {
    return axios.get(
      API_URL_DEFAULT + "daily_spot_price/new_format/?" + payload,
      {
        headers: authHeader(),
      }
    );
  }
  readQsDailySpotPrice(qs) {
    return axios.get(API_URL_DEFAULT + "daily_spot_price/?" + qs, {
      headers: authHeader(),
    });
  }
  readDailySpotPrice(first, second) {
    return axios.get(
      API_URL_DEFAULT +
        "daily_spot_price/?date_first=" +
        first +
        "&date_second=" +
        second,
      { headers: authHeader() }
    );
  }
  readCurrentDailySpotPrice(currency) {
    return axios.get(
      API_URL_DEFAULT + "daily_spot_price/?currency=" + currency,
      { headers: authHeader() }
    );
  }
  readActiveDailySpotPrice() {
    return axios.get(API_URL_DEFAULT + "daily_spot_price/?active=active", {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "daily_spot_price", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "daily_spot_price", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new DailySpotPriceService();
