import React, { Component } from "react";
import Submenu from "./Submenu";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: "nav-item",
      currentActive: "nav-link",
    };
  }
  componentDidMount() {
    const { link } = this.props;
    const active_menu = JSON.parse(localStorage.getItem("active_menu"));
    const menu_open = "nav-item menu-open";
    const menu_active = "nav-link active";
    
    if (active_menu === link) {
      this.setState({
        currentMenu: menu_open,
        currentActive: menu_active,
      });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    const { active_menu, link } = nextProps;
    const menu_open = "nav-item menu-open";
    const menu_active = "nav-link active";

    if (active_menu === link) {
      return {
        currentMenu: menu_open,
        currentActive: menu_active,
      };
      
    }
  }
  activeMenu = (name) => {
    const menu_open = "nav-item menu-open";
    const menu_active = "nav-link active";
    const { link } = this.props;
    
    if (name === link) {
      this.setState({
        currentMenu: menu_open,
        currentActive: menu_active,
      });
    }
  };
  render() {
    let { name, icon, link, details } = this.props;
    return (
      <li className={this.state.currentMenu}>
        <a href="#!" className={this.state.currentActive}>
          <i className={icon ? icon : "nav-icon"} />
          <p>
            {name}
            <i className="fas fa-angle-left right" />
          </p>
        </a>
        <ul className="nav nav-treeview">
          {details && details.length > 0
            ? details.map((item, i) => {
                return (
                  <Submenu 
                    menu_link={link}
                    name={item.name}
                    link={item.link}
                    key={i}
                    activeMenu={this.activeMenu}
                  />
                );
              })
            : ""}
        </ul>
      </li>
    );
  }
}

export default withRouter(Menu);
