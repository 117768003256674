import TollOutMaterialService from '../services/toll_out_material.service'

export const addTollOutMaterial = (payload) => {
  return TollOutMaterialService.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const overwriteTollOutMaterial = (payload) => {
  return TollOutMaterialService.overwrite(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const editTollOutMaterial = (payload) => {
  return TollOutMaterialService.edit(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();


      return Promise.reject(message);
    }
  );
};
export const deleteTollOutMaterial = (payload) => {
  return TollOutMaterialService.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readTollOutMaterial = (payload) =>  {
  return TollOutMaterialService.readTollOutMaterial(payload).then(
    (response) => {  
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); 
      return Promise.reject(message);
    }
  );
}; 