import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import BOMFGTable from "../components/BOMFGTableEXP";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Worksheet from "../components/WorksheetBomFg";
import RemoteAll from "../components/RemoteAll";
import { readGeneralSetting } from "../modules/generalSettingModule";
import {
  readBOMFG,
  addBOMFG,
  deleteBOMFG,
  editBOMFG,
} from "../modules/BOMFGEXPModule";
import {
  readMaterial,
  readMaterialByType,
  readMaterialUomByCode,
  readMaterialBySearch,
} from "../modules/MaterialModule";
import { readProdFacility } from "../modules/ProdFacilityModule";
import { readResource } from "../modules/ResourceModule";
import { readUom } from "../modules/UomModule";

import { readMappingClassification } from "../modules/MappingClassificationModule";

import { readMaterialClassification } from "../modules/MaterialClassificationModule";

class BOMFGEXP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverwrite: false,
      submitted: false,
      worksheet_name: true,
      showModal: false,
      showModalEdit: false,
      loading: false,
      loadingSpinner: false,
      readOnly: false,
      isClearable: true,
      collection: {},
      errors: {},
      id: null,
      filename: null,
      excel: null,
      worksheet: [],
      worksheet_detail: [],
      material_code: null,
      material_desc: null,
      no_material_fg: null,
      material_description_fg: null,
      prod_facility: null,
      description_facility: null,
      machine_capacity: null,
      unit_machine_capacity: "kg/H",
      gas: null,
      unit_gas: null,
      electricity: null,
      unit_electricity: null,
      no_material_fg_dtl: null,
      material_description_fg_dtl: null,
      prod_facility_dtl: null,
      description_facility_dtl: null,
      machine_capacity_dtl: null,
      unit_machine_capacity_dtl: null,
      gas_dtl: null,
      unit_gas_dtl: null,
      electricity_dtl: null,
      unit_electricity_dtl: null,
      div_hide: false,
      requester: null,
      cols_length: null,
      material_description: null,
      material_description_edit: null,
      no_material: null,
      no_material_edit: null,
      select_items_material: [],
      select_items_material_all: [],
      uom: "KG",
      select_items_uom: [],
      select_items_plant: [
        { value: "1", label: "Plant 1" },
        { value: "2", label: "Plant 2" },
        { value: "mixer", label: "Mixer" },
      ],
      select_items_prod_facility: [],
      unit: null,
      material: null,
      material_edit: null,
      material_code: null,
      material_edit_code: null,
      prod_facility: null,
      desc_facility: null,
      type_facility: null,
      no_facility: null,
      isValidMaterial: true,
      isValidMaterialEdit: true,
      qty: "1000",
      data: [["", "", "", "", ""]],
      noMatRef: [{ readOnly: false }],
      uomRef: [{ readOnly: false }],
      plantRef: [{ readOnly: true }],
      labor: null,
      labor_dtl: null,
      unit_labor: null,
      unit_labor_dtl: null,
      machine: null,
      machine_dtl: null,
      unit_machine: null,
      unit_machine_dtl: null,
      overhead: null,
      overhead_dtl: null,
      unit_overhead: null,
      unit_overhead_dtl: null,
      list_resource: [],
      list_resource_edit: [],
      select_items_parameter: [],
      isFacilPicked: false,
      isValidClassification: true,
      isValidClassificationEdit: true,
      unit_edit: null,
      material_desc_edit: null,
      material_code_edit: null,
      material_edit: null,
      material_code_edit: null,
      prod_facility_edit: null,
      desc_facility_edit: null,
      type_facility_edit: null,
      no_facility_edit: null,
      isValidMaterial_edit: true,
      qty_edit: "1000",
      data_edit: [["", "", "", ""]],
      noMatRef_edit: [{ readOnly: false }],
      uomRef_edit: [{ readOnly: false }],
      plantRef_edit: [{ readOnly: true }],
      prod_facility_edit: null,
      description_facility_edit: null,
      machine_capacity_edit: null,
      unit_machine_capacity_edit: "kg/H",
      gas_edit: null,
      unit_gas_edit: null,
      electricity_edit: null,
      unit_electricity_edit: null,
      labor_edit: null,
      unit_labor_edit: null,
      machine_edit: null,
      unit_machine_edit: null,
      overhead_edit: null,
      unit_overhead_edit: null,
      uom_edit: null,
      no_facility_edit: null,
      type_facility_edit: null,
      disabled_edit_clasification: true,
      cols: [
        {
          name: "no_material_rm",
          key: 0,
        },
        {
          name: "material_description_rm",
          key: 1,
        },
        {
          name: "amount_per_batch",
          key: 2,
        },
        {
          name: "uom",
          key: 3,
        },
        {
          name: "plant",
          key: 4,
        },
      ],
      cols_view: [
        {
          name: "no_material_rm",
          key: 0,
        },
        {
          name: "material_description_rm",
          key: 1,
        },
        {
          name: "qty",
          key: 2,
        },
        {
          name: "uom",
          key: 3,
        },
        {
          name: "plant",
          key: 4,
        },
      ],
      columns: [
        {
          dataField: "no_material",
          text: "No Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_description",
          text: "Mat. Desc",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      columns_table: [
        {
          text: "No. Material",
          type: "material",
        },
        {
          text: "Material Desc",
          type: "text",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Plant",
          type: "plant",
        },
      ],
      list_datatable: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "created_at",
      sortOrder: "desc",
      defaultSorted: [],
      cellEditProps: {},
      link: `${process.env.PUBLIC_URL}/bom_fg.xlsx`,
      material_code_search: "",
      bom_contents: null,
      bom_contents_edit: null,
      recipe_edit: [],
      recipe_encrypted: null,
      bom_contents_default: {
        recipe: [
          {
            default: true,
            version_no: 1,
            ingredients: [
              {
                no_material_rm: "",
                material_description_rm: "",
                amount_per_batch: "",
                uom: "",
                plant: null,
              },
            ],
          },
        ],
        facility: [
          {
            default: true,
            facility_no: 1,
            facility_code: "",
            facility_desc: "",
            facility_type: "",
            facility_number: "",
            machine_capacity: "",
            unit_machine_capacity: "kg/H",
            gas: "",
            electricity: "",
            labor: "",
            machine: "",
            overhead: "",
            unit_gas: "",
            unit_electricity: "",
            unit_labor: "",
            unit_machine: "",
            unit_overhead: "",
          },
        ],
      },
      readOnly: false,
      remark: "",
      remark_edit: "",
    };
  }

  recipe_default = [
    {
      default: true,
      version_no: 1,
      ingredients: [
        {
          no_material_rm: "",
          material_description_rm: "",
          amount_per_batch: "",
          uom: "KG",
          plant: null,
        },
      ],
    },
  ];

  facility_default = [
    {
      default: true,
      facility_no: 1,
      facility_code: "",
      facility_desc: "",
      facility_type: "",
      facility_number: "",
      machine_capacity: "",
      unit_machine_capacity: "kg/H",
      gas: "",
      electricity: "",
      labor: "",
      machine: "",
      overhead: "",
      unit_gas: "",
      unit_electricity: "",
      unit_labor: "",
      unit_machine: "",
      unit_overhead: "",
    },
  ];

  bom_contents_default = {
    recipe: this.recipe_default,
    facility: this.facility_default,
  };

  refDefault = [{ ref: null, readOnly: false }];
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
      bom_contents: this.bom_contents_default,
    });
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    readMaterialByType("ZSFG,ZFGD").then((response) => {
      this.setState({ select_items_material: response });
    });

    readMaterial().then((response) => {
      this.setState({ select_items_material_all: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    readProdFacility().then((response) => {
      this.setState({ select_items_prod_facility: response });
    });

    readBOMFG(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });

    let query_setting = `user=${user.username}&module=bom_fg&action=edit_clasification`;
    readGeneralSetting(query_setting).then((response) => {
      this.setState({
        disabled_edit_clasification: response.user ? false : true,
      });
    });

    const queryString = `constraint=type&constraint_value=bom_fg`;
    readMappingClassification(queryString).then((response) => {
      this.setState({ select_items_parameter: response });
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  calculateResource = (machine_capacity, idx, data, type_data) => {
    const { qty, list_resource } = this.state;

    if (list_resource.length > 0) {
      let res_gas = list_resource.find((o) => o.resource_desc == "GAS");
      let res_electricity = list_resource.find(
        (o) => o.resource_desc == "ELECTRICITY"
      );
      let res_labor = list_resource.find((o) => o.resource_desc == "LABOR");
      let res_machine = list_resource.find((o) => o.resource_desc == "MACHINE");
      let res_overhead = list_resource.find(
        (o) => o.resource_desc == "OVERHEAD"
      );

      if (
        !this.validateResource(
          res_gas,
          res_electricity,
          res_labor,
          res_machine,
          res_overhead,
          idx,
          type_data
        )
      ) {
        return;
      }

      let usage = isFinite(qty / machine_capacity) ? qty / machine_capacity : 0;

      let gas = res_gas ? usage * res_gas.resource_qty : "";
      let electricity = res_electricity
        ? usage * res_electricity.resource_qty
        : "";
      let labor = res_labor ? usage * res_labor.resource_qty : "";
      let machine = res_machine ? usage * res_machine.resource_qty : "";
      let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

      data.facility[idx].machine_capacity = machine_capacity;
      data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
      data.facility[idx].electricity = isNaN(parseFloat(electricity))
        ? ""
        : electricity.toFixed(2);
      data.facility[idx].labor = isNaN(parseFloat(labor))
        ? ""
        : labor.toFixed(2);
      data.facility[idx].machine = isNaN(parseFloat(machine))
        ? ""
        : machine.toFixed(2);
      data.facility[idx].overhead = isNaN(parseFloat(overhead))
        ? ""
        : overhead.toFixed(2);
      data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
      data.facility[idx].unit_electricity = res_electricity
        ? res_electricity.uom
        : "";
      data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
      data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
      data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

      this.setState({
        [type_data]: { ...data },
        [type_data.includes("edit")
          ? "machine_capacity_edit"
          : "machine_capacity"]: machine_capacity,
      });
    } else {
      let query_string = `facility_code=${data.facility[idx].facility_code}`;
      readResource(query_string).then((response) => {
        let list_resource = response;
        let res_gas = list_resource.find((o) => o.resource_desc == "GAS");
        let res_electricity = list_resource.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = list_resource.find((o) => o.resource_desc == "LABOR");
        let res_machine = list_resource.find(
          (o) => o.resource_desc == "MACHINE"
        );
        let res_overhead = list_resource.find(
          (o) => o.resource_desc == "OVERHEAD"
        );

        if (
          !this.validateResource(
            res_gas,
            res_electricity,
            res_labor,
            res_machine,
            res_overhead,
            idx,
            type_data
          )
        ) {
          return;
        }

        let usage = isFinite(qty / machine_capacity)
          ? qty / machine_capacity
          : 0;

        let gas = res_gas ? usage * res_gas.resource_qty : "";
        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : "";
        let labor = res_labor ? usage * res_labor.resource_qty : "";
        let machine = res_machine ? usage * res_machine.resource_qty : "";
        let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

        data.facility[idx].machine_capacity = machine_capacity;
        data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
        data.facility[idx].electricity = isNaN(parseFloat(electricity))
          ? ""
          : electricity.toFixed(2);
        data.facility[idx].labor = isNaN(parseFloat(labor))
          ? ""
          : labor.toFixed(2);
        data.facility[idx].machine = isNaN(parseFloat(machine))
          ? ""
          : machine.toFixed(2);
        data.facility[idx].overhead = isNaN(parseFloat(overhead))
          ? ""
          : overhead.toFixed(2);
        data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
        data.facility[idx].unit_electricity = res_electricity
          ? res_electricity.uom
          : "";
        data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
        data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
        data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

        this.setState({
          list_resource: list_resource,
          [type_data]: { ...data },
          [type_data.includes("edit")
            ? "machine_capacity_edit"
            : "machine_capacity"]: machine_capacity,
        });
      });
    }
  };

  calculateResourceEdit = (machine_capacity) => {
    const { qty_edit, list_resource_edit } = this.state;
    let res_gas = list_resource_edit.find((o) => o.resource_desc == "GAS");
    let res_electricity = list_resource_edit.find(
      (o) => o.resource_desc == "ELECTRICITY"
    );
    let res_labor = list_resource_edit.find((o) => o.resource_desc == "LABOR");
    let res_machine = list_resource_edit.find(
      (o) => o.resource_desc == "MACHINE"
    );
    let res_overhead = list_resource_edit.find(
      (o) => o.resource_desc == "OVERHEAD"
    );

    let usage = isFinite(qty_edit / machine_capacity)
      ? qty_edit / machine_capacity
      : 0;

    let gas = res_gas ? usage * res_gas.resource_qty : parseInt("");
    let electricity = res_electricity
      ? usage * res_electricity.resource_qty
      : parseInt("");
    let labor = res_labor ? usage * res_labor.resource_qty : parseInt("");
    let machine = res_machine ? usage * res_machine.resource_qty : parseInt("");
    let overhead = res_overhead
      ? usage * res_overhead.resource_qty
      : parseInt("");

    this.setState({
      machine_capacity_edit: machine_capacity,
      gas_edit: isNaN(parseFloat(gas)) ? "" : gas.toFixed(2),
      electricity_edit: isNaN(parseFloat(electricity))
        ? ""
        : electricity.toFixed(2),
      labor_edit: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
      machine_edit: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
      overhead_edit: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
    });
  };

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    if (name == "machine_capacity") {
      this.calculateResource(value);
    }
    if (name == "machine_capacity_edit") {
      this.calculateResourceEdit(value);
    }
  };
  onChangeValueSearch = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    this.setState({
      [name]: value,
    });
  };
  onChangeProdFacility = (val, idx, data, type_data) => {
    let index = val;
    const { select_items_prod_facility, machine_capacity, qty } = this.state;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      let query_string = `facility_code=${prod_facility.facility_code}`;
      readResource(query_string).then((response) => {
        let res_gas = response.find((o) => o.resource_desc == "GAS");
        let res_electricity = response.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = response.find((o) => o.resource_desc == "LABOR");
        let res_machine = response.find((o) => o.resource_desc == "MACHINE");
        let res_overhead = response.find((o) => o.resource_desc == "OVERHEAD");
        if (
          !this.validateResource(
            res_gas,
            res_electricity,
            res_labor,
            res_machine,
            res_overhead,
            idx,
            type_data
          )
        ) {
          return;
        }

        let usage = isFinite(qty / machine_capacity)
          ? qty / machine_capacity
          : 0;

        let gas = res_gas ? usage * res_gas.resource_qty : "";
        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : "";
        let labor = res_labor ? usage * res_labor.resource_qty : "";
        let machine = res_machine ? usage * res_machine.resource_qty : "";
        let overhead = res_overhead ? usage * res_overhead.resource_qty : "";

        data.facility[idx].facility_code = prod_facility.facility_code;
        data.facility[idx].facility_desc = prod_facility.facility_desc;
        data.facility[idx].facility_type = prod_facility.facility_type;
        data.facility[idx].facility_number = prod_facility.facility_number;
        data.facility[idx].gas = isNaN(parseFloat(gas)) ? "" : gas.toFixed(2);
        data.facility[idx].electricity = isNaN(parseFloat(electricity))
          ? ""
          : electricity.toFixed(2);
        data.facility[idx].labor = isNaN(parseFloat(labor))
          ? ""
          : labor.toFixed(2);
        data.facility[idx].machine = isNaN(parseFloat(machine))
          ? ""
          : machine.toFixed(2);
        data.facility[idx].overhead = isNaN(parseFloat(overhead))
          ? ""
          : overhead.toFixed(2);
        data.facility[idx].unit_gas = res_gas ? res_gas.uom : "";
        data.facility[idx].unit_electricity = res_electricity
          ? res_electricity.uom
          : "";
        data.facility[idx].unit_labor = res_labor ? res_labor.uom : "";
        data.facility[idx].unit_machine = res_machine ? res_machine.uom : "";
        data.facility[idx].unit_overhead = res_overhead ? res_overhead.uom : "";

        this.setState({
          [type_data]: { ...data },
          list_resource: response,
        });
      });
    } else {
      data.facility[idx].facility_code = "";
      data.facility[idx].facility_desc = "";
      data.facility[idx].facility_type = "";
      data.facility[idx].facility_number = "";
      data.facility[idx].gas = "";
      data.facility[idx].electricity = "";

      data.facility[idx].labor = "";
      data.facility[idx].machine = "";
      data.facility[idx].overhead = "";
      data.facility[idx].unit_gas = "";
      data.facility[idx].unit_electricity = "";
      data.facility[idx].unit_labor = "";
      data.facility[idx].unit_machine = "";
      data.facility[idx].unit_overhead = "";
      this.setState({
        [type_data]: { ...data },
        list_resource: [],
      });
    }
  };
  onChangeProdFacilityEdit = (any) => {
    let index = any.target.selectedIndex;
    const { select_items_prod_facility, machine_capacity, qty_edit } =
      this.state;
    if (index >= 1) {
      let prod_facility = select_items_prod_facility[index - 1];
      let query_string = `facility_code=${prod_facility.facility_code}`;
      readResource(query_string).then((response) => {
        let res_gas = response.find((o) => o.resource_desc == "GAS");
        let res_electricity = response.find(
          (o) => o.resource_desc == "ELECTRICITY"
        );
        let res_labor = response.find((o) => o.resource_desc == "LABOR");
        let res_machine = response.find((o) => o.resource_desc == "MACHINE");
        let res_overhead = response.find((o) => o.resource_desc == "OVERHEAD");
        if (
          !this.validateResource(
            res_gas,
            res_electricity,
            res_labor,
            res_machine,
            res_overhead
          )
        ) {
          return;
        }

        let usage = isFinite(qty_edit / machine_capacity)
          ? qty_edit / machine_capacity
          : 0;

        let gas = res_gas ? usage * res_gas.resource_qty : parseInt("");
        let electricity = res_electricity
          ? usage * res_electricity.resource_qty
          : parseInt("");
        let labor = res_labor ? usage * res_labor.resource_qty : parseInt("");
        let machine = res_machine
          ? usage * res_machine.resource_qty
          : parseInt("");
        let overhead = res_overhead
          ? usage * res_overhead.resource_qty
          : parseInt("");

        this.setState({
          list_resource_edit: response,
          prod_facility_edit: prod_facility.facility_code,
          desc_facility_edit: prod_facility.facility_desc,
          type_facility_edit: prod_facility.facility_type,
          no_facility_edit: prod_facility.facility_number,
          unit_gas_edit: res_gas ? res_gas.uom : "",
          unit_electricity_edit: res_electricity ? res_electricity.uom : "",
          unit_labor_edit: res_labor ? res_labor.uom : "",
          unit_machine_edit: res_machine ? res_machine.uom : "",
          unit_overhead_edit: res_overhead ? res_overhead.uom : "",
          gas_edit: isNaN(parseFloat(gas)) ? "" : gas.toFixed(2),
          electricity_edit: isNaN(parseFloat(electricity))
            ? ""
            : electricity.toFixed(2),
          labor_edit: isNaN(parseFloat(labor)) ? "" : labor.toFixed(2),
          machine_edit: isNaN(parseFloat(machine)) ? "" : machine.toFixed(2),
          overhead_edit: isNaN(parseFloat(overhead)) ? "" : overhead.toFixed(2),
          machine_capacity_edit: machine_capacity,
        });
      });
    } else {
      this.setState({
        list_resource: [],
        prod_facility: null,
        desc_facility: null,
        type_facility: null,
        no_facility: null,
        unit_gas: null,
        unit_electricity: null,
        unit_labor: null,
        unit_machine: null,
        unit_overhead: null,
        isFacilPicked: false,
        gas: null,
        electricity: null,
        labor: null,
        machine: null,
        overhead: null,
      });
    }
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete, loadingSpinner } = this.state;
    return (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toEditBOMFG(
              row._id,
              row.no_material,
              row.material_description,
              row.qty,
              row.uom,
              row.material_classification,
              row.bom_contents,
              true,
              row.remark,
              row.qty_per_batch,
              row.duration_per_batch
            )
          }
        >
          <i className="fas fa-th" key={row._id}></i>
        </button>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.toEditBOMFG(
                row._id,
                row.no_material,
                row.material_description,
                row.qty,
                row.uom,
                row.material_classification,
                row.bom_contents,
                false,
                row.remark,
                row.qty_per_batch,
                row.duration_per_batch
              );
            }}
          >
            <i className="fas fa-edit" key={row._id}></i>
          </button>
        )}
        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row.no_material, row.bom_contents)}
          >
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };
  deleteData = (no_material, bom_contents) => {
    const { history } = this.props;
    const { requester, department } = this.state;
    let collection = {
      requester: requester,
      department: department,
      bom_contents: bom_contents,
      no_material: no_material,
    };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteBOMFG(collection)
            .then((response) => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/master-data/bom-fg-exp");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetailBOMFG = (
    id,
    no_material,
    material_description,
    data,
    cols,
    prod_facility,
    description_facility,
    machine_capacity,
    unit_machine_capacity,
    qty,
    uom,
    material_classification
  ) => {
    const worksheet_detail = [
      {
        worksheet: no_material + "_" + material_description,
        data: data,
        cols: this.state.cols_view,
        special_case: {
          description_facility: description_facility,
          machine_capacity: machine_capacity,
          material_description_fg: material_description,
          no_material_fg: no_material,
          prod_facility: prod_facility,
          unit_machine_capacity: unit_machine_capacity,
          qty: qty,
          uom: uom,
          classification: material_classification,
        },
      },
    ];

    this.setState(
      {
        id: id,
        worksheet_detail: worksheet_detail,
        submitted: false,
        no_material: no_material,
        material_description: material_description,
        prod_facility_dtl: prod_facility,
        description_facility_dtl: description_facility,
        machine_capacity_dtl: machine_capacity,
        unit_machine_capacity_dtl: unit_machine_capacity,
        classification_edit: material_classification,
        errors: {},
      },
      () => {
        this.toggle();
      }
    );
  };

  toEditBOMFG = (
    id,
    no_material,
    material_description,
    qty,
    uom,
    material_classification,
    bom_contents,
    readOnly,
    remark,
    qty_per_batch,
    duration_per_batch
  ) => {
    this.toggleSpinner();
    this.setState(
      {
        id: id,
        material_edit_code: no_material,
        material_edit: material_description,
        classification_edit: material_classification,
        bom_contents_edit: bom_contents,
        readOnly: readOnly,
        qty_edit: qty,
        uom_edit: uom,
        errors: {},
        remark_edit: remark,
        qty_per_batch_edit: qty_per_batch,
        duration_per_batch_edit: duration_per_batch,
      },
      () => {
        this.toggleEdit();
        this.toggleSpinner();
      }
    );
  };

  onChangeHandlerFile = (event) => {
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });

      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (
    data,
    cols,
    start_row = 0,
    allowed_cols = null,
    row_header_case = null,
    row_header_value = null
  ) => {
    let header = [];
    let detail = [];
    let row_header = [];
    let row_detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          if (allowed_cols) {
            let z_clone = [...r];
            let splice_custom = z_clone.splice(0, allowed_cols);
            header.push(splice_custom);
          } else {
            header.push(header_concat);
          }
        }

        if (start_row !== 0 && i < start_row) {
          let z_clone = [...r];
          const header_title = [0, 2, 4, 6, 8];
          const detail_content = [1, 3, 5, 7, 9];
          if (header_title.includes(i)) {
            row_header.push(z_clone); //header_title
          }
          if (detail_content.includes(i)) {
            row_detail.push(z_clone); //header_content
          }
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i > start_row && cleanArray.length > 0) {
          let abc = z.map((o) => {
            if (!isNaN(o) && o) {
              return parseFloat(o.toString().replace(/,/g, "")).toFixed(2);
            }
          });

          let array_concat = splice.concat(abc);
          detail.push(array_concat);
        }
      });
    }
    return {
      header: header,
      detail: detail,
      special_case: { header: row_header, detail: row_detail },
    };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    //const result = this.exists_only(data, cols, 2, [3], 0, 1); //original
    const result = this.exists_only(data, cols, 10, [3], 0, 1);
    let special_case = {};
    let special_case_name = [];
    let no_material_fg_,
      material_description_fg_,
      prod_facility_,
      description_facility_,
      machine_capacity_,
      unit_machine_capacity_,
      gas_,
      unit_gas_,
      electricity_,
      unit_electricity_ = null;

    if (result.special_case) {
      if (result.special_case.header && result.special_case.detail) {
        let special_custom_cols = this.make_custom_cols(
          result.special_case.header
        );
        let special_data_header = result.special_case.header;
        let special_data = result.special_case.detail;
        special_data.map((r, k) => {
          special_custom_cols.map((c, i) => {
            if (r[c.key]) {
              special_case_name.push(r[c.key]);
            }
          });
        });

        //setting key special_case;
        let no_header = 0;
        special_data_header.forEach((header) => {
          let no_detail = 0;
          header.forEach((header_list) => {
            special_case[header_list] = special_data[no_header][no_detail];
            let value_ = special_data[no_header][no_detail];
            if (header_list === "no_material_fg") no_material_fg_ = value_;
            if (header_list === "material_description_fg")
              material_description_fg_ = value_;
            if (header_list === "prod_facility") prod_facility_ = value_;
            if (header_list === "description_facility")
              description_facility_ = value_;
            if (header_list === "machine_capacity") machine_capacity_ = value_;
            if (header_list === "unit_machine_capacity")
              unit_machine_capacity_ = value_;
            if (header_list === "gas") gas_ = value_;
            if (header_list === "unit_gas") unit_gas_ = value_;
            if (header_list === "electricity") electricity_ = value_;
            if (header_list === "unit_electricity") unit_electricity_ = value_;

            no_detail++;
          });
          no_header++;
        });

        this.setState({
          no_material_fg: no_material_fg_,
          material_description_fg: material_description_fg_,
          prod_facility: prod_facility_,
          description_facility: description_facility_,
          machine_capacity: machine_capacity_,
          unit_machine_capacity: unit_machine_capacity_,
          gas: gas_,
          unit_gas: unit_gas_,
          electricity: electricity_,
          unit_electricity: unit_electricity_,
          div_hide: true,
        });
      }
    }

    let custom_ws_name =
      special_case_name.length > 0 ? special_case_name.join("_") : wsname;
    const custom_cols = this.make_custom_cols(result.header);
    if (custom_ws_name != wsname) {
      return {
        worksheet: no_material_fg_ + "_" + material_description_fg_,
        data: result.detail,
        special_case: special_case,
        cols: custom_cols,
      };
    }
  };

  onChangeBomFGTable = (data, type) => {
    this.setState({
      [type]: { ...data },
    });
  };

  getDefaultOptions = () => {
    const { recipe_edit } = this.state;
    let option = [];
    recipe_edit.map((recipe) => {
      recipe.ingredients.map((ing) => {
        option.push({
          value: ing.material_code,
          label: ing.material_name,
        });
      });
    });
    return option;
  };

  getMaterial = (classification) => {
    this.refMaterial.state.value = "";
    this.refMaterial.state.label = "";
    const queryString = `constraint=mapping&constraint_value=${classification}`;
    readMaterialClassification(queryString).then((response) => {
      this.setState({ select_items_material: response });
    });
  };
  handleChange = (name, newValue) => {
    const { errors } = this.state;
    delete errors[name];

    if (newValue) {
      if (name === "classification") {
        this.setState({
          isValidClassification: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name === "classification_edit") {
        this.setState({
          isValidClassificationEdit: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name == "material") {
        const queryString = `checkBomFg=${newValue.value}`;
        readBOMFG(queryString).then((response) => {
          if (Object.entries(response).length > 0) {
            Swal.fire({
              title: "Warning",
              text: `FG BOM already exist for this material ${newValue.label}. What do you want to do?`,
              icon: "warning",
              showDenyButton: true,
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: "Edit",
              denyButtonText: `Overwrite`,
              customClass: {
                confirmButton: "btn btn-warning mr-1",
                cancelButton: "btn btn-secondary mr-1",
                denyButton: "btn btn-danger mr-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (this.refMaterial) {
                  this.refMaterial.state.value = "";
                  this.refMaterial.state.label = "";
                }
                this.setState(
                  {
                    [name]: null,
                    [name + "_code"]: null,
                  },
                  () => {
                    this.toEditBOMFG(
                      response._id,
                      response.no_material,
                      response.material_description,
                      response.qty,
                      response.uom,
                      response.material_classification,
                      response.bom_contents,
                      false
                    );
                  }
                );
              } else if (result.isDenied) {
                readMaterialUomByCode(newValue.value).then((response) => {
                  this.setState({
                    uom: response[0].value,
                    isValidMaterial: true,
                    [name]: newValue.label,
                    [name + "_code"]: newValue.value,
                    isOverwrite: true,
                  });
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (this.refMaterial) {
                  this.refMaterial.state.value = "";
                  this.refMaterial.state.label = "";
                }
                this.setState({
                  [name]: null,
                  [name + "_code"]: null,
                });
              }
            });
          } else {
            readMaterialUomByCode(newValue.value).then((response) => {
              this.setState({
                uom: response[0].value,
                isValidMaterial: true,
                [name]: newValue.label,
                [name + "_code"]: newValue.value,
              });
            });
          }
        });
      } else if (name == "material_edit") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.setState({
            uom_edit: response[0].value,
            isValidMaterialEdit: true,
            [name]: newValue.label,
            [name + "_code"]: newValue.value,
          });
        });
      }
    } else {
      if (name == "material") {
        this.setState({
          [name]: null,
          [name + "_code"]: null,
          isValidMaterial: false,
        });
      } else if (name == "material_edit") {
        this.setState({
          [name]: null,
          [name + "_code"]: null,
          isValidMaterialEdit: false,
        });
      }

      if (name === "classification") {
        this.setState({
          isValidClassification: false,
          [name]: null,
          [name + "_code"]: null,
        });
      }
      if (name === "classification_edit") {
        this.setState({
          isValidClassification_edit: false,
          [name]: null,
          [name + "_code"]: null,
        });
      }
    }
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleEdit = () => {
    this.setState({
      showModalEdit: !this.state.showModalEdit,
    });
  };
  toggleSpinner = () => {
    this.setState({
      loadingSpinner: !this.state.loadingSpinner,
    });
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      worksheet_detail: null,
      no_material: null,
      material_description: null,
      showModal: !this.state.showModal,
      readOnly: false,
      classification: null,
    });
  };
  onModalEditHide = () => {
    this.setState({
      list_resource_edit: [],
      prod_facility_edit: null,
      desc_facility_edit: null,
      type_facility_edit: null,
      no_facility_edit: null,
      unit_gas_edit: null,
      unit_electricity_edit: null,
      unit_labor_edit: null,
      unit_machine_edit: null,
      unit_overhead_edit: null,
      gas_edit: null,
      electricity_edit: null,
      labor_edit: null,
      machine_edit: null,
      overhead_edit: null,
      machine_capacity_edit: null,

      id: null,
      submitted: false,
      material_code_edit: null,
      material_edit: null,
      prod_facility_edit: null,
      description_facility_edit: null,
      machine_capacity_edit: null,
      unit_machine_capacity_edit: null,
      data_edit: [...["", "", "", ""]],
      uomRef_edit: [{ ref: null, readOnly: false }],
      noMatRef_edit: [{ ref: null, readOnly: false }],
      showModalEdit: !this.state.showModalEdit,
      classification_edit: null,
      bom_contents_edit: this.bom_contents_default,
      readOnly: false,
    });
  };
  //capitalize first word

  validateResource = (
    gas,
    electricity,
    labor,
    machine,
    overhead,
    idx,
    type_data
  ) => {
    let errors = this.state.errors;
    if (!gas) {
      errors[`gas${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[`gas${idx}${type_data.includes("edit") ? "_edit" : ""}`];
    if (!electricity) {
      errors[`electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!labor) {
      errors[`labor${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!machine) {
      errors[`machine${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];
    if (!overhead) {
      errors[`overhead${idx}${type_data.includes("edit") ? "_edit" : ""}`] =
        "Master resource not found";
    } else
      delete errors[
        `electricity${idx}${type_data.includes("edit") ? "_edit" : ""}`
      ];

    return errors;
  };

  //validate input
  validateForm = (
    material_code,
    material_desc,
    classification,
    qty,
    uom,
    bom_contents,
    gas,
    electricity,
    labor,
    machine,
    overhead
  ) => {
    let errors = this.state.errors;

    if (!material_code) {
      errors.material_code = "This is field required";
    } else {
      delete errors.material_code;
    }
    if (!material_desc) {
      errors.material_desc = "This is field required";
    } else {
      delete errors.material_desc;
    }
    if (!classification) {
      errors.classification = "This is field required";
      this.setState({
        isValidClassification: false,
      });
    } else {
      delete errors.classification;
    }

    if (!qty) {
      errors.qty = "This is field required";
    } else {
      delete errors.qty;
    }
    if (!uom) {
      errors.uom = "This is field required";
    } else {
      delete errors.uom;
    }

    if (bom_contents) {
      for (let facility of bom_contents.facility) {
        let idx = bom_contents.facility.indexOf(facility);
        if (facility.facility_code == "" || facility.machine_capacity == "") {
          errors[`facility${idx}`] = "Please check your input";
        } else {
          delete errors[`facility${idx}`];
        }
      }
      for (let recipe of bom_contents.recipe) {
        let idxRecipe = bom_contents.recipe.indexOf(recipe);
        for (let ing of recipe.ingredients) {
          if (
            ing.material_description_rm == "" ||
            ing.amount_per_batch == "" ||
            ing.plant == "" ||
            ing.uom == "" ||
            ing.plant == ""
          ) {
            errors[`recipe${idxRecipe}`] = "Please check your input";
          } else {
            delete errors[`recipe${idxRecipe}`];
          }
        }
      }
    }

    return errors;
  };

  //validate input
  validateFormEdit = (
    material_edit,
    classification_edit,
    qty_edit,
    uom_edit,
    bom_contents_edit,
    gas_edit,
    electricity_edit,
    labor_edit,
    machine_edit,
    overhead_edit
  ) => {
    let errors = this.state.errors;
    if (!material_edit) {
      errors.material_edit = "This is field required";
      this.setState({
        isValidMaterialEdit: false,
      });
    }
    if (!classification_edit) {
      errors.classification_edit = "This is field required";
      this.setState({
        isValidClassificationEdit: false,
      });
    }

    if (!qty_edit) {
      errors.qty_edit = "This is field required";
    }
    if (!uom_edit) {
      errors.uom_edit = "This is field required";
    }

    if (bom_contents_edit) {
      for (let facility of bom_contents_edit.facility) {
        let idx = bom_contents_edit.facility.indexOf(facility);
        if (facility.facility_code == "" || facility.machine_capacity == "") {
          errors[`facility${idx}_edit`] = "Please check your input";
        } else {
          delete errors[`facility${idx}_edit`];
        }
      }

      for (let recipe of bom_contents_edit.recipe) {
        let idxRecipe = bom_contents_edit.recipe.indexOf(recipe);

        for (let ing of recipe.ingredients) {
          let idxIng = recipe.ingredients.indexOf(ing);
          if (
            ing.no_material_rm == "" ||
            ing.material_description_rm == "" ||
            ing.amount_per_batch == "" ||
            ing.plant === "" ||
            ing.plant === " "
          ) {
            errors[`recipe${idxRecipe}_edit`] =
              "Please check your input on number " +
              (idxIng + 1) +
              ", version " +
              recipe.version_no;
            break;
          } else {
            delete errors[`recipe${idxRecipe}_edit`];
          }
        }
      }
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      noMatRef,
      material_code_search,
    } = this.state;

    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}&material_description=${material_code_search}`;

    this.form.reset();
    this.refClassification.state.value = "";
    this.refClassification.state.label = "";

    const queryString = `constraint=type&constraint_value=bom_fg`;
    readMappingClassification(queryString).then((response) => {
      this.setState({ select_items_parameter: response });
    });
    readBOMFG(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        selectedFile: null,
        submitted: false,
        worksheet: [],
        no_material_fg: "",
        material_description_fg: "",
        prod_facility: "",
        description_facility: "",
        machine_capacity: "",
        uom: "KG",
        uom_edit: "",
        gas: "",
        unit_gas: "",
        electricity: "",
        unit_electricity: "",
        labor: "",
        unit_labor: "",
        machine: "",
        unit_machine: "",
        overhead: "",
        unit_overhead: "",
        classification: "",
        div_hide: false,
        data: [["", "", "", ""]],
        noMatRef: [{ ref: null, readOnly: false }],
        bom_contents: { ...this.state.bom_contents_default },
        bom_contents_edit: { ...this.state.bom_contents_default },
        isOverwrite: false,
        remark: "",
        remark_edit: "",
      });
    });
    readMaterialByType("ZSFG,ZFGD").then((response) => {
      this.setState({ select_items_material: response });
    });

    readMaterial().then((response) => {
      this.setState({ select_items_material_all: response });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      material,
      material_code,
      material_desc,
      qty,
      uom,
      gas,
      machine_capacity,
      electricity,
      labor,
      machine,
      overhead,
      classification,
      bom_contents,
      errors,
      isOverwrite,
    } = this.state;
    if (
      !this.validateForm(
        material_code,
        material_desc,
        classification,
        qty,
        uom,
        bom_contents,
        gas,
        machine_capacity,
        electricity,
        labor,
        machine,
        overhead
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      no_material: material_code,
      material_description: material_desc,
      qty: qty,
      uom: uom,
      material_classification: classification,
      bom_contents: bom_contents,
    };

    if (
      requester &&
      department &&
      material_code &&
      material_desc &&
      qty &&
      uom &&
      classification &&
      Object.keys(errors).length == 0
    ) {
      if (isOverwrite) {
        Swal.fire({
          title: "Are you sure",
          text: `Old data will be overwritten`,
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Yes, overwrite",
          cancelButtonText: `Cancel`,
          customClass: {
            confirmButton: "btn btn-danger mr-1",
            cancelButton: "btn btn-secondary mr-1",
          },
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            addBOMFG(collection)
              .then((response) => {
                toast.success("Data has been saved successfully", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-add-bom-fg-success",
                });
                Swal.fire({
                  title: "Information",
                  icon: "success",
                  text: "Data has been saved successfully",
                  showConfirmButton: false,
                  timer: 2000,
                }).then((result) => {
                  //read data exchange rate on backend
                  this.reloadDataBackend();
                  history.push("/master-data/bom-fg-exp");
                  window.location.reload();
                });
              })
              .catch((err) => {
                Swal.fire({
                  title: "Cancelled",
                  icon: "error",
                  text: err,
                  showConfirmButton: true,
                });
                this.setState({
                  loading: false,
                  isOverwrite: false,
                });
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.setState({
              isOverwrite: false,
            });
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else
        Swal.fire({
          title: "Are you sure?",
          text: "Please check your entries !",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            addBOMFG(collection)
              .then((response) => {
                toast.success("Data has been saved successfully", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-add-bom-fg-success",
                });
                Swal.fire({
                  title: "Information",
                  icon: "success",
                  text: "Data has been saved successfully",
                  showConfirmButton: false,
                  timer: 2000,
                }).then((result) => {
                  //read data exchange rate on backend
                  this.reloadDataBackend();
                  history.push("/master-data/bom-fg-exp");
                  window.location.reload();
                });
              })
              .catch((err) => {
                Swal.fire({
                  title: "Cancelled",
                  icon: "error",
                  text: err,
                  showConfirmButton: true,
                });
                this.setState({
                  loading: false,
                });
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
    } else {
      Swal.fire("Cancelled", "Please check your input", "error");
    }
  };

  //submit form
  handleSubmitEdit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      department,
      material_edit,
      qty_edit,
      uom_edit,
      gas_edit,
      machine_capacity_edit,
      electricity_edit,
      labor_edit,
      machine_edit,
      overhead_edit,
      classification_edit,
      bom_contents_edit,
      errors,
      material_edit_code,
    } = this.state;
    if (
      !this.validateFormEdit(
        material_edit,
        classification_edit,
        qty_edit,
        uom_edit,
        bom_contents_edit,
        gas_edit,
        machine_capacity_edit,
        electricity_edit,
        labor_edit,
        machine_edit,
        overhead_edit
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      no_material: material_edit_code,
      material_description: material_edit,
      qty: qty_edit,
      uom: uom_edit,
      material_classification: classification_edit,
      bom_contents: bom_contents_edit,
    };
    if (
      requester &&
      department &&
      material_edit &&
      qty_edit &&
      uom_edit &&
      classification_edit &&
      Object.keys(errors).length == 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          editBOMFG(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-bom-fg-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.toggleEdit();
                this.reloadDataBackend();
                history.push("/master-data/bom-fg-exp");
              });
            })
            .catch((err) => {
              Swal.fire("Cancelled", err, "error");
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      let message = Object.values(errors)
        ? Object.values(errors)[0]
        : "Please check your input";
      Swal.fire("Cancelled", message, "error");
    }
  };

  onChangeCalculate = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortBy, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
    });
    const { material_code_search } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      no_material: "no_material",
      material_description: "material_description",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }

    let query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}&material_description=${material_code_search}`;

    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }

    readBOMFG(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });
  };

  //handle search
  handleSearch = () => {
    const { sortBy, sortOrder, page, sizePerPage, material_code_search } =
      this.state;

    let query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&no_material=${material_code_search}&material_description=${material_code_search}`;
    readBOMFG(query_string).then((response) => {
      this.setState(
        {
          list_datatable: response.foundData ? response.foundData : [],
          totalSize: response.foundData ? response.countData : 0,
          page: response.foundData ? response.currentPage : 1,
        },
        () => {
          if (response.foundData.length === 0) {
            query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=1&sizePerPage=${sizePerPage}&no_material=${material_code_search}&material_description=${material_code_search}`;
            readBOMFG(query_string).then((res) => {
              this.setState({
                list_datatable: res.foundData ? res.foundData : [],
                totalSize: res.foundData ? res.countData : 0,
                page: res.foundData ? res.currentPage : 1,
              });
            });
          }
        }
      );
    });
  };
  getMaterial = (input) => {
    const queryString = input
      ? `constraint_exclude=material_type&sizePerPage=100&search=${input}`
      : `constraint_exclude=material_type&sizePerPage=100`;
    const result = readMaterialBySearch(queryString).then((res) => {
      return res;
    });
    return result;
  };

  handleSelectBomFG = (val, idx, idxIng, type, data, type_data) => {
    if (type == "material") {
      if (val.label !== "" && val.value !== this.state.material_code) {
        readMaterialUomByCode(val.value).then((response) => {
          data.recipe[idx].ingredients[idxIng].no_material_rm = val.value;
          data.recipe[idx].ingredients[idxIng].material_description_rm =
            val.label;
          data.recipe[idx].ingredients[idxIng].uom = response[0].value;
          if (response[0].material_type == "ZFGD") {
            data.recipe[idx].ingredients[idxIng].plant = "";
          } else {
            data.recipe[idx].ingredients[idxIng].plant = null;
          }
          this.setState({
            [type_data]: { ...data },
          });
        });
      } else {
        val.value === this.state.material_code &&
          Swal.fire({
            title: "Not allowed",
            icon: "info",
            text: `${val.label} is already selected as Parent Material! Please select another Material`,
          });

        data.recipe[idx].ingredients[idxIng].no_material_rm = "";
        data.recipe[idx].ingredients[idxIng].material_description_rm = "";
        data.recipe[idx].ingredients[idxIng].uom = "";
        this.setState({
          [type_data]: { ...data },
        });
      }
    }

    if (type === "uom") {
      data.recipe[idx].ingredients[idxIng].uom = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type === "plant") {
      data.recipe[idx].ingredients[idxIng].plant = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "prod_facility" && val > 0) {
      let facility = this.state.select_items_prod_facility[val - 1];
      //check picked facility has to be uniqued
      if (data.facility.length > 1) {
        if (
          data.facility.some((x) => x.facility_code == facility.facility_code)
        ) {
          Swal.fire({
            title: "Not allowed",
            icon: "info",
            text: `${facility.facility_code} is already selected! Please select another production Facility`,
          });
        } else if (
          data.facility[data.facility.length - 1].facility_type !=
          facility.facility_type
        ) {
          Swal.fire({
            title: "Not Allowed",
            icon: "info",
            text: `You can only select Facility ${
              data.facility[data.facility.length - 1].facility_type
            }`,
          });
        } else {
          this.onChangeProdFacility(val, idx, data, type_data);
        }
      } else {
        this.onChangeProdFacility(val, idx, data, type_data);
      }
    }
  };

  handleInputBomFG = (val, idx, idxIng, type, data, type_data) => {
    if (type == "machine_capacity") {
      this.calculateResource(val, idx, data, type_data);
    }
    if (type == "material") {
      data.recipe[idx].ingredients[idxIng].material_description_rm = val;
      data.recipe[idx].ingredients[idxIng].uom = "";
      if (val == "") {
        data.recipe[idx].ingredients[idxIng].no_material_rm = "";
      } else {
        data.recipe[idx].ingredients[idxIng].no_material_rm = null;
        data.recipe[idx].ingredients[idxIng].plant = null;
      }
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "amount_per_batch") {
      data.recipe[idx].ingredients[idxIng].amount_per_batch = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "qty_per_batch" || type == "duration_per_batch") {
      const findFac = data.facility.find((it, z) => z == idx);
      const findFacIndex = data.facility.findIndex((it, z) => z == idx);
      if (typeof findFac != "undefined") {
        const modifFac = {
          ...findFac,
          [type]: val,
        };
        const newFac = [
          ...data.facility.slice(0, findFacIndex),
          modifFac,
          ...data.facility.slice(findFacIndex + 1),
        ];
        const newData = {
          ...data,
          facility: [...newFac],
        };

        this.setState({
          [type_data]: newData,
        });
      }
    }
    if (type == "switch") {
      data.recipe[idx].ingredients[idxIng].is_exp = val;
      if (!val) {
        data.recipe[idx].ingredients[idxIng].material_exp = null;
        data.recipe[idx].ingredients[idxIng].material_description_rm = "";
      } else {
        data.recipe[idx].ingredients[idxIng].material_description_rm = null;
      }
      this.setState({
        [type_data]: { ...data },
      });
    }
    if (type == "material_exp") {
      data.recipe[idx].ingredients[idxIng].material_exp = val;
      this.setState({
        [type_data]: { ...data },
      });
    }
  };
  render() {
    let {
      material_desc,
      material_code,
      showModal,
      showModalEdit,
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      page,
      sizePerPage,
      totalSize,
      columns,
      defaultSorted,
      cellEditProps,
      worksheet_detail,
      material_description,
      no_material,
      select_items_material_all,
      select_items_uom,
      select_items_plant,
      select_items_prod_facility,
      qty,
      uom,
      material_code_search,
      qty_edit,
      uom_edit,
      readOnly,
      loadingSpinner,
      isValidClassification,
      classification,
      select_items_parameter,
      classification_edit,
      isValidClassificationEdit,
      material_edit_code,
      material_edit,
      bom_contents,
      bom_contents_edit,
      disabled_edit_clasification,
      remark,
      remark_edit,
    } = this.state;

    const customStylesClassification = {
      control: (base, state) => ({
        ...base,

        borderColor: state.isFocused
          ? "#ddd"
          : isValidClassification
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidClassification
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesClassificationEdit = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidClassificationEdit
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidClassificationEdit
            ? "#ddd"
            : "green",
        },
      }),
    };
    const Line = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 3,
          borderRadius: 2,
        }}
      />
    );

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                Detail {no_material} {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Worksheet worksheet={worksheet_detail} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          <Modal
            show={loadingSpinner}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalEdit}
            size="xl"
            backdrop="static"
            onHide={this.onModalEditHide}
            dialogClassName="full-modal-dialog"
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                {readOnly ? "Detail" : "Edit"} {material_edit}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <>
                <form
                  autoComplete="off"
                  className={
                    submitted
                      ? "needs-validation was-validated"
                      : "needs-validation"
                  }
                  noValidate
                  ref={(form) => (this.form = form)}
                >
                  <Row>
                    <Col xs={12} md={2}>
                      <label htmlFor="material_edit_code"> Material</label>
                    </Col>
                    <Col xs={12} md={2}>
                      <div
                        className={
                          errors.material_edit_code != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="text"
                          step="any"
                          className="form-control form-control-sm"
                          name="material_edit_code"
                          placeholder="No. Material"
                          required="required"
                          value={material_edit_code || ""}
                          readOnly={true}
                          onChange={this.onChangeValue}
                        />
                        {errors.material_edit_code && (
                          <div className="invalid-feedback">
                            {errors.material_edit_code}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={2}>
                      <div
                        className={
                          errors.material_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="text"
                          step="any"
                          className="form-control form-control-sm"
                          name="material_edit"
                          placeholder="Material Desc"
                          required="required"
                          value={material_edit || ""}
                          readOnly={true}
                          onChange={this.onChangeValue}
                        />
                        {errors.material_edit && (
                          <div className="invalid-feedback">
                            {errors.material_edit}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} md={2}>
                      <label htmlFor="classification_edit">
                        Classification
                      </label>
                    </Col>
                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.classification_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <Select
                          styles={customStylesClassificationEdit}
                          onChange={this.handleChange.bind(
                            this,
                            "classification_edit"
                          )}
                          options={select_items_parameter}
                          defaultValue={select_items_parameter.filter(
                            (e) => e.label === classification_edit
                          )}
                          ref={(ref) => (this.refClassification = ref)}
                          isDisabled={
                            readOnly ? true : disabled_edit_clasification
                          }
                        />
                        {!isValidClassificationEdit && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.classification_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={2}>
                      {" "}
                      <label htmlFor="qty_edit">Qty</label>
                    </Col>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.qty != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <input
                          type="number"
                          step="any"
                          className="form-control form-control-sm"
                          name="qty_edit"
                          placeholder="Qty"
                          required="required"
                          value={qty_edit || ""}
                          readOnly={true}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.qty_edit && (
                          <div className="invalid-feedback">
                            {errors.qty_edit}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} md={2}>
                      <label htmlFor="currency">UOM</label>
                    </Col>
                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.uom_edit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <select
                          className={
                            errors.uom_edit != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="uom_edit"
                          onChange={this.onChangeValue}
                          value={uom_edit}
                          required
                          ref={(ref) => (this.refUnit = ref)}
                        >
                          <option value="">UOM</option>
                          {select_items_uom &&
                            select_items_uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.uom_edit && (
                          <div className="invalid-feedback">
                            {errors.uom_edit}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <Line color={"#cccccc"} />
                    </Col>
                  </Row>
                </form>
                <Row>
                  <Col xs={12} md={12}>
                    <BOMFGTable
                      data={bom_contents_edit}
                      type_data="bom_contents_edit"
                      materials={select_items_material_all}
                      list_prod_facility={select_items_prod_facility}
                      uom={select_items_uom}
                      readOnly={readOnly}
                      loadOptions={this.getMaterial}
                      handleSelect={this.handleSelectBomFG}
                      handleInput={this.handleInputBomFG}
                      defaultOptions={true}
                      errors={this.state.errors}
                      onChange={(data, remark) =>
                        this.onChangeBomFGTable(data, "bom_contents_edit")
                      }
                      list_plant={select_items_plant}
                      isCreate={false}
                      remark={remark_edit}
                    />
                    {errors.bom_contents && (
                      <div className="text-danger font-weight-bold">
                        {errors.bom_contents}
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            </ModalBody>
            <ModalFooter>
              {!readOnly && (
                <Button
                  variant="success"
                  onClick={this.handleSubmitEdit}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Update
                </Button>
              )}
            </ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle
                            className="bg-info card-title"
                            as={Card.Header}
                            eventKey="0"
                            onClick={() =>
                              this.setState({
                                isCreateHidden: !this.state.isCreateHidden,
                              })
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              BOM FG EXP
                              <div className="d-flex align-items-center">
                                {this.state.isCreateHidden ? (
                                  <i
                                    className="fa fa-plus float-right align-middle"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <i
                                    className="fa fa-minus float-right align-middle"
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <div className="card-body">
                              <form
                                autoComplete="off"
                                className={
                                  submitted
                                    ? "needs-validation was-validated"
                                    : "needs-validation"
                                }
                                noValidate
                                ref={(form) => (this.form = form)}
                              >
                                <Row>
                                  <Col xs={12} md={1}>
                                    <label htmlFor="material">Material</label>
                                  </Col>
                                  <Col xs={12} md={2}>
                                    <div
                                      className={
                                        errors.material_code !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="material_code"
                                        placeholder="No. Material"
                                        required="required"
                                        value={material_code || ""}
                                        onChange={this.onChangeValueSearch}
                                      />
                                      {errors.material_code && (
                                        <div className="invalid-feedback">
                                          {errors.material_code}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={2}>
                                    <div
                                      className={
                                        errors.material_desc !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="material_desc"
                                        placeholder="Material Desc"
                                        required="required"
                                        value={material_desc || ""}
                                        onChange={this.onChangeValueSearch}
                                      />
                                      {errors.material_desc && (
                                        <div className="invalid-feedback">
                                          {errors.material_desc}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <label htmlFor="classification">
                                      Classification
                                    </label>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div
                                      className={
                                        errors.classification !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <Select
                                        styles={customStylesClassification}
                                        onChange={this.handleChange.bind(
                                          this,
                                          "classification"
                                        )}
                                        options={select_items_parameter}
                                        defaultValue={select_items_parameter.filter(
                                          (e) => e.label === classification
                                        )}
                                        ref={(ref) =>
                                          (this.refClassification = ref)
                                        }
                                      />
                                      {!isValidClassification && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors.classification}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={1}>
                                    {" "}
                                    <label htmlFor="qty">Qty</label>
                                  </Col>
                                  <Col xs={12} md={4}>
                                    <div
                                      className={
                                        errors.qty != "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <input
                                        type="number"
                                        step="any"
                                        className="form-control form-control-sm"
                                        name="qty"
                                        placeholder="Qty"
                                        required="required"
                                        value={qty || ""}
                                        readOnly={true}
                                        onChange={this.onChangeValue}
                                        min="0"
                                      />
                                      {errors.qty && (
                                        <div className="invalid-feedback">
                                          {errors.qty}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={1}>
                                    <label htmlFor="currency">UOM</label>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div
                                      className={
                                        errors.uom != "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <select
                                        className={
                                          errors.uom != "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="uom"
                                        onChange={this.onChangeValueSearch}
                                        value={uom}
                                        required
                                        ref={(ref) => (this.refUnit = ref)}
                                        readOnly
                                      >
                                        <option value="">Choose</option>
                                        {select_items_uom &&
                                          select_items_uom.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors.uom && (
                                        <div className="invalid-feedback">
                                          {errors.uom}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} md={12}>
                                    <Line color={"#cccccc"} />
                                  </Col>
                                </Row>
                              </form>

                              <Row>
                                <Col xs={12} md={12}>
                                  <BOMFGTable
                                    data={bom_contents}
                                    type_data="bom_contents"
                                    materials={select_items_material_all}
                                    list_prod_facility={
                                      select_items_prod_facility
                                    }
                                    uom={select_items_uom}
                                    readOnly={false}
                                    loadOptions={this.getMaterial}
                                    handleSelect={this.handleSelectBomFG}
                                    handleInput={this.handleInputBomFG}
                                    defaultOptions={true}
                                    errors={this.state.errors}
                                    onChange={(data, remark) =>
                                      this.onChangeBomFGTable(
                                        data,
                                        "bom_contents"
                                      )
                                    }
                                    list_plant={select_items_plant}
                                    isCreate={true}
                                    remark={remark}
                                  />
                                  {errors.bom_contents && (
                                    <div className="text-danger font-weight-bold">
                                      {errors.bom_contents}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={12}>
                                  <Button
                                    variant="success"
                                    onClick={this.handleSubmit}
                                    className="float-right"
                                  >
                                    <i className="fas fa-save" /> Submit
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={this.reloadDataBackend}
                                    className="float-right mr-2"
                                  >
                                    <i className="fas fa-eraser" /> Clear
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={2}>
                                <div className="form-row form-group">
                                  <label htmlFor="material_code_search"></label>

                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="material_code_search"
                                    placeholder="Material Code / Description"
                                    required="required"
                                    value={material_code_search || ""}
                                    onChange={this.onChangeValueSearch}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            cellEditProps={cellEditProps}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(BOMFGEXP);
