import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ProdFacilityService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "prod_facility", payload, {
      headers: authHeader(),
    });
  }
  readProdFacility() {
    return axios.get(API_URL_DEFAULT + "prod_facility/", {
      headers: authHeader(),
    });
  }
  readProdFacilityValue() {
    return axios.get(API_URL_DEFAULT + "prod_facility/?value=1", {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "prod_facility", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "prod_facility", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new ProdFacilityService();
