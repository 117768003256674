import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import {
  uploadFile,
  downloadFile,
  readAllFile,
} from "../modules/UploadFileModule";
const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");
class DummyFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      showModal: false,
      errors: {},
      selectedFile: null,
      allFiles: [],
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    readAllFile()
      .then((res) => {
        this.setState({ allFiles: res });
      })
      .catch((err) => {});
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  //handle change

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
    } else {
      this.setState({
        [name]: null,
      });
    }
  };
  //trigger modal
  toDetail = (id) => {
    this.setState(
      {
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModal: !this.state.showModal,
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester, selectedFile } = this.state;
    const data = new FormData();
    if (selectedFile) {
      for (var x = 0; x < selectedFile.length; x++) {
        data.append("upload_file", selectedFile[x]);
      }
    }

    if (selectedFile) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({
            loading: true,
          });
          //send to backend
          uploadFile(data)
            .then((response) => {
              this.toggle();
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                readAllFile()
                  .then((res) => {
                    this.setState({ allFiles: res });
                  })
                  .catch((err) => {});
                this.setState({
                  loading: false,
                });
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-failed",
              });

              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  generateTimestamp = () => {
    const date = dateFormat(new Date(), "yyyymmdd-HHMMss");
    return date;
  };

  onChangeHandlerFile = (name, event) => {
    const files = event.target.files;
    this.setState({
      selectedFile: files,
    });
  };
  handleDownload = (onedrive_id) => {
    this.setState({
      loading: true,
    });
    downloadFile("onedrive_id=" + onedrive_id)
      .then((res) => {
        const tempLink = document.createElement("a");
        const contentType = res.mimetype;
        const blob = this.b64toBlob(res.file, contentType);

        const fileURL = URL.createObjectURL(blob);
        tempLink.href = fileURL;
        tempLink.setAttribute(
          "download",
          `file_${new Date().getTime()}.${res.extension}`
        );
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);

        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
    return;
  };
  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  render() {
    let { submitted, loading, isCreate, download_file, allFiles } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Upload File</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated "
                                : "needs-validation "
                            }
                            noValidate
                          >
                            <div className="form-row">
                              <div className="col-md-12 mb-3">
                                <label htmlFor="file">Choose File*</label>
                                <input
                                  type="file"
                                  className="form-control form-control-sm"
                                  name="file"
                                  placeholder="File"
                                  onChange={this.onChangeHandlerFile.bind(
                                    this,
                                    "file"
                                  )}
                                  required
                                  multiple
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <Button
                                variant="success"
                                onClick={this.handleSubmit}
                                className="float-right"
                              >
                                <i className="fas fa-save" /> Submit
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-4">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Download File</h3>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col md={12} sm={12}>
                              <table className="table table-hover table-stripped text-center">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Filename</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {allFiles &&
                                    allFiles.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{item.originalname}</td>
                                          <td>
                                            <Button
                                              variant="success"
                                              onClick={this.handleDownload.bind(
                                                this,
                                                item.onedrive_id
                                              )}
                                              className="btn-sm float-right"
                                            >
                                              <i className="fas fa-download" />{" "}
                                              Download
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(DummyFeature);
