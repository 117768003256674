import moment from "moment";

export const diff_date_on_day = (date1, date2) => {
  let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
  return diff_on_second / (1000 * 60 * 60 * 24);
};
export const getNextMonthList = (start_month, numMonth) => {
  const firstMonth = moment(start_month);
  let nextSixMonth = firstMonth.add(numMonth, "month");
  let dates = [];
  const cloneFirstMonth = moment(start_month);
  while (cloneFirstMonth < nextSixMonth) {
    dates.push(cloneFirstMonth.format("YYYY-MM-DD"));
    cloneFirstMonth.add(1, "month");
  }
  return dates;
};

export const checkTimeBetween = (time1, time2, time3) => {
  return moment(time1, "HH:mm").isBetween(
    moment(time2, "HH:mm"),
    moment(time3, "HH:mm")
  );
};

export const checkTimeBefore = (time1, time2) => {
  return moment(time1, "HH:mm").isBefore(moment(time2, "HH:mm"));
};

export const checkDateBefore = (date1, date2) => {
  return moment(date1, "dd-mm-yyyy").isBefore(moment(date2, "dd-mm-yyyy"));
};

export const addDays = (date, days) => {
  return new Date(date).setDate(new Date(date).getDate() + days);
};

export const isSameDay = (date1, date2) => {
  return moment(date1).isSame(date2, "day");
};
