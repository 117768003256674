import MaterialExpService from '../services/material_exp.services'

export const editMaterialExp = (payload) => {
  return MaterialExpService.edit(payload).then(
    (response) => {

      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readAllMaterialExp = () => {
  return MaterialExpService.readAll().then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
