import React from "react";
import thousandformat from "../helpers/thousanddelimiter";
import DecimalPrecision from "../helpers/decimalprecision";
import FinalSelect from "../components/FinalSelect";
import moment from "moment";
import dateFormat from "dateformat";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const renderHeaderTable = (header) => {
  return (
    <thead>
      <tr>
        {header &&
          header.map((h, i) => {
            const width = h.width ? h.width : "9%";
            if (i === 0 && !h.isLabel)
              return (
                <th
                  key={`row-header-${i}`}
                  style={{ fontSize: "0.64rem", width: "2%" }}
                  className="text-center"
                  colSpan={h.colspan ? h.colspan : null}
                  rowSpan={h.rowspan ? h.rowspan : null}
                >
                  {" "}
                  {moment(h.label, "YYYY-MM-DD", true).isValid()
                    ? dateFormat(h.label, "mmm yyyy")
                    : h.label}{" "}
                </th>
              );

            return (
              <th
                key={`row-header-${i}`}
                style={{ fontSize: "0.64rem", width: width }}
                className="text-center"
                colSpan={h.colspan ? h.colspan : null}
                rowSpan={h.rowspan ? h.rowspan : null}
              >
                {moment(h.label, "YYYY-MM-DD", true).isValid()
                  ? dateFormat(h.label, "mmm yyyy")
                  : h.label}
              </th>
            );
          })}
      </tr>
    </thead>
  );
};
const renderBodyTable = (body, handleInputChange, nameState) => {
  return (
    <tbody>
      {body &&
        body.map((b, i) => {
          return (
            <tr key={i}>
              {b.children &&
                b.children.map((c, k) => {
                  const width = c.width ? c.width : "100%";
                  if (k === 0 && !c.isLabel)
                    return (
                      <td
                        key={`row-body-${k}`}
                        style={{ fontSize: "0.64rem", width: "40px" }}
                        className="text-center"
                        colSpan={c.colspan ? c.colspan : null}
                        rowSpan={c.rowspan ? c.rowspan : null}
                      >
                        {" "}
                        {parseInt(i) + 1}{" "}
                      </td>
                    );
                  if (k === 0 && c.isLabel)
                    return (
                      <td
                        key={`row-body-${k}`}
                        style={{ fontSize: "0.64rem", width: width }}
                        className="text-center"
                        colSpan={c.colspan ? c.colspan : null}
                        rowSpan={c.rowspan ? c.rowspan : null}
                      >
                        {" "}
                        {c.label}
                      </td>
                    );

                  return c.readOnly ? (
                    <td
                      key={`row-body-${k}`}
                      style={{
                        fontSize: "0.64rem",
                        padding: "0",
                        width: width,
                      }}
                      className="text-center align-middle"
                      colSpan={c.colspan ? c.colspan : null}
                      rowSpan={c.rowspan ? c.rowspan : null}
                    >
                      {c.value && !isNaN(c.value)
                        ? thousandformat(
                            DecimalPrecision.round(c.value, DECIMAL_PLACES),
                            ".",
                            ".",
                            ","
                          )
                        : c.value}
                    </td>
                  ) : c.isSelect ? (
                    <td
                      key={`row-body-${k}`}
                      style={{ fontSize: "0.64rem", padding: "0" }}
                      colSpan={c.colspan ? c.colspan : null}
                      rowSpan={c.rowspan ? c.rowspan : null}
                    >
                      <FinalSelect
                        label={c.label ? c.label : null}
                        name={c.name ? c.name : null}
                        value={c.value ? c.value : ""}
                        placeholder={c.placeholder ? c.placeholder : null}
                        selectOptions={c.selectOptions ? c.selectOptions : []}
                        isClearable={c.isClearable ? c.isClearable : false}
                        handleChange={handleInputChange(
                          i,
                          k,
                          c.data_type,
                          nameState
                        )}
                        isValid={c.isValid}
                        error={c.errors}
                        isFormRow={false}
                        isDisabled={c.isDisabled}
                        zIndex={c.zIndex}
                        fontSize={c.fontSize}
                      />
                    </td>
                  ) : (
                    <td
                      key={`row-body-${k}`}
                      style={{ fontSize: "0.64rem", padding: "0" }}
                      colSpan={c.colspan ? c.colspan : null}
                      rowSpan={c.rowspan ? c.rowspan : null}
                    >
                      <input
                        type={c.data_type == "number" ? c.data_type : "text"}
                        name={c.name ? c.name : null}
                        value={c.value ? c.value : ""}
                        className="form-control form-control-sm"
                        style={{ fontSize: "0.64rem", width: width }}
                        placeholder={c.placeholder ? c.placeholder : null}
                        step={c.data_type == "number" ? "0.01" : null}
                        min={c.data_type == "number" ? "0" : null}
                        onChange={handleInputChange(
                          i,
                          k,
                          c.data_type,
                          nameState
                        )}
                      />
                    </td>
                  );
                })}
            </tr>
          );
        })}
    </tbody>
  );
};
const renderFooterTable = (footer) => {
  return (
    <tfoot>
      {footer &&
        footer.map((h, i) => {
          return (
            <tr key={i}>
              {h.children &&
                h.children.map((c, k) => {
                  const width = c.width ? c.width : "9%";
                  if (k === 0)
                    return (
                      <th
                        key={`row-footer-${i}-${k}`}
                        style={{ fontSize: "0.64rem", width: "2%" }}
                        className="text-center"
                        colSpan={c.colspan ? c.colspan : null}
                        rowSpan={c.rowspan ? c.rowspan : null}
                      >
                        {" "}
                        {c.label}{" "}
                      </th>
                    );
                  return (
                    <th
                      key={`row-footer-${i}-${k}`}
                      style={{ fontSize: "0.64rem", width: width }}
                      className="text-center"
                      colSpan={c.colspan ? c.colspan : null}
                      rowSpan={c.rowspan ? c.rowspan : null}
                    >
                      {c.value
                        ? thousandformat(
                            DecimalPrecision.round(c.value, 2),
                            ".",
                            ".",
                            ","
                          )
                        : null}
                    </th>
                  );
                })}
            </tr>
          );
        })}
    </tfoot>
  );
};
const DynamicTable = ({
  tableInput,
  otherClass,
  handleInputChange,
  nameState,
  styleTable,
}) => {
  return (
    <div
      className={
        tableInput && tableInput.header.length > 0 ? "table-responsive" : ""
      }
      style={
        tableInput.body.length > 4
          ? styleTable
          : {
              height: "350px",
              overflow: "hidden",
            }
      }
    >
      <table
        className={
          otherClass
            ? otherClass
            : " table table-bordered table-striped table-compact"
        }
      >
        {renderHeaderTable(tableInput.header)}
        {renderBodyTable(tableInput.body, handleInputChange, nameState)}

        {renderFooterTable(tableInput.footer)}
      </table>
    </div>
  );
};
export default DynamicTable;
