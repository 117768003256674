import SimulatorPriceService from '../../services/sodiq/simulator_service';

export const listMaterial = (query) => {
    return SimulatorPriceService.list_material(query).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    )
}