import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Worksheet from "../components/Worksheet";
import ReactTooltip from "react-tooltip";

import { readNotification } from "../modules/NotificationMrpModule";

import { startOfMonth } from "date-fns";
import NotificationMrpTable from "../components/NotificationMrpTable";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

class NotifikasiMrp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      columns: null,
      list_datatable: [],
      filter_by: null,
      search: null,
      searchSubmit: null,
      month: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "no_material",
      sortOrder: "asc",
      defaultSorted: [],
      cellEditProps: {},
      isSearchFound: true,
      errors_create: {},
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    this.setState({
      month: startOfMonth(new Date()),
    });

    let query_string = `month=${dateFormat(new Date(), "yyyy-mm-01")}`;
    readNotification(query_string)
      .then((response) => {
        this.setState({
          list_datatable: response,
          isSearchFound: true,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          list_datatable: e,
          isSearchFound: false,
          loading: false,
        });
      });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    return <div>{cell ? thousandformat(cell, ".", ".", ",") : ""}</div>;
  };

  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };

  //validate input
  validateForm = (month, date) => {
    let errors = this.state.errors;
    if (!month) {
      errors.month = "This field is required";
    }
    if (!date) {
      errors.date = "This field is required";
    }

    return errors;
  };

  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
      isDownloadable: true,
    });
  };

  handleSearch = () => {
    this.setState({ loading: true });
    const { month, search } = this.state;
    let srch = "";
    if (search) {
      srch = search.replaceAll("(", "").replaceAll(")", "");
    }
    let query_string = `month=${dateFormat(
      month,
      "yyyy-mm-01"
    )}&search=${srch}`;
    readNotification(query_string)
      .then((response) => {
        this.setState({
          list_datatable: response,
          isSearchFound: true,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          list_datatable: e,
          isSearchFound: false,
          loading: false,
        });
      });
  };

  render() {
    let {
      worksheet,
      errors,
      submitted,
      isCreate,
      isRead,
      list_datatable,
      month,
      filter_by,
      errors_create,
      search,
      searchSubmit,
      loading,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Filter Notification MRP
                          </h3>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <label htmlFor="currency">Month *</label>
                              </div>
                            </Col>

                            <Col xs={12} md={3}>
                              <div
                                className={
                                  errors_create.month !=
                                    "This field is required"
                                    ? "form-group"
                                    : "form-group has-error has-feedback"
                                }
                              >
                                <DatePicker
                                  selected={month}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "month")}
                                  name="month"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                                {errors.month && (
                                  <div className="invalid-feedback">
                                    {errors.date}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} md={2}>
                              <div className="form-group">
                                <label htmlFor="currency">Material/Code</label>
                              </div>
                            </Col>

                            <Col xs={12} md={3}>
                              <input
                                className="form-control form-control-sm"
                                name="search"
                                value={search || ""}
                                placeholder="Search ..."
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeValue}
                              />
                            </Col>
                            <Col xs={12} md={3}>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                              >
                                <i className="fas fa-search" /> Show
                              </Button>
                            </Col>
                          </Row>

                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Col>
                              <Row
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="float"
                                />
                              </Row>
                            </Col>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && list_datatable && !loading ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">List &nbsp;</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <div>
                            {!list_datatable ? (
                              "Data Not Found"
                            ) : (
                              <NotificationMrpTable
                                data={list_datatable}
                                type={filter_by}
                                search={searchSubmit}
                                props={this.props}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      variant="primary"
                    />
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(NotifikasiMrp);
