import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ResourceService {

    create(payload) {
        return axios
            .post(API_URL_DEFAULT + "resource", payload, { headers: authHeader() });
    }
    readResource(qs) {
        return axios
            .get(API_URL_DEFAULT + "resource/?" + qs, { headers: authHeader() });
    }
    edit(payload) {
        return axios
            .put(API_URL_DEFAULT + "resource", payload, { headers: authHeader() });
    }

    delete(payload) {
        return axios
            .delete(API_URL_DEFAULT + "resource", { data: payload, headers: authHeader() });
    }

}

export default new ResourceService();