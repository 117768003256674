import MappingClassificationService from "../services/mapping_classification.service";

export const readMappingClassification = (queryString) => {
  return MappingClassificationService.readMappingClassification(
    queryString
  ).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
