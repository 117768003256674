import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ImportRppService {
    download_template() {
        return axios.get(API_URL_DEFAULT + "import-rpp/download-template", {
            headers: authHeader(),
            responseType: 'blob'
        });
    }

    insertImportRPP(payload) {
        return axios.post(API_URL_DEFAULT + "import-rpp/create", payload, {
            headers: authHeader()
        });
    }

    getImportRpp() {
        return axios.get(API_URL_DEFAULT + "import-rpp", {
            headers: authHeader()
        });
    }
}

export default new ImportRppService();