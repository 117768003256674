import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class NotificationMrpService {
  readMaterialByMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT + "notification_mrp_rnd/read_material?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }

  read(query_string) {
    return axios.get(API_URL_DEFAULT + "notification_mrp_rnd?" + query_string, {
      headers: authHeader(),
    });
  }

  ProcessMaterial(payload) {
    return axios.post(
      API_URL_DEFAULT + "notification_mrp_rnd/process",
      payload,
      { headers: authHeader() }
    );
  }
}

export default new NotificationMrpService();
