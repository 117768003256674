import ApsRawMaterialService from "../../services/handri/aps_raw_material.service";

export const readRawMaterial = (query) => {
  return ApsRawMaterialService.read(query).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const downloadExcelRawMaterial = (query) => {
  return ApsRawMaterialService.downloadExcel(query).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
