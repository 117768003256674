import React from "react";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import thousandformat from "../helpers/thousanddelimiter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const SpotPrice = ({
  per_material,
  data, //data that is inputted by user in each row
  columns, //column from the parent, each type of PR has its own column
  vendors, //list vendor to be picked user
  currencies,
  interval_days,
  start_date,
  end_date,
  onChange, //to return data to parrent
  onOpenEdit,
}) => {
  const handleTableRowAdd = () => {
    let objSpotPrice = {};
    for (let index = interval_days; index >= 0; index--) {
      let objCurrency = {};
      objCurrency["currency"] = currencies[0].value;
      objSpotPrice[index] = objCurrency;
    }
    const array = new Uint32Array(10);
    data.push({
      id: [...Array(24)]
        .map((i) => (
           /* eslint-disable-next-line no-restricted-globals */
          ~~(self.crypto.getRandomValues(array) * 36)).toString(36))
        .join(""),
      vendor_code: null,
      vendor_name: null,
      spot_price: objSpotPrice,
    });
    onChange(data);
  };

  const handleTableRowRemove = () => {
    data.pop();
    onChange(data);
  };

  const handleRemoveRow = (index_eta) => {
    data.splice(index_eta, 1);
    onChange(data);
  };

  const handleChange = (val, type, idx, pengurang_date = null) => {
    if (type === "vendor") {
      data[idx]["vendor_code"] = val.value;
      data[idx]["vendor_name"] = val.label;
    }
    if (type === "currency") {
      if (data[idx]["spot_price"][pengurang_date]) {
        data[idx]["spot_price"][pengurang_date]["currency"] = val;
      } else {
        let objCurrency = {};
        objCurrency["currency"] = val;
        data[idx]["spot_price"][pengurang_date] = objCurrency;
      }
    }
    if (type === "spot_price") {
      if (data[idx]["spot_price"][pengurang_date]) {
        data[idx]["spot_price"][pengurang_date]["price"] = val.target.value;
      } else {
        let objSpotPrice = {};
        objSpotPrice["price"] = val.target.value;
        data[idx]["spot_price"][pengurang_date] = objSpotPrice;
      }
    }
    onChange(data);
  };

  const handleOpenEdit = (spot_price, per_vendor, per_material) => {
    let objOpenEdit = {};
    objOpenEdit["currency"] = spot_price.currency;
    objOpenEdit["material_name"] = per_material.material_name;
    objOpenEdit["material_code"] = per_material.material_code;
    objOpenEdit["vendor_name"] = per_vendor.vendor_name;
    objOpenEdit["vendor_code"] = per_vendor.vendor_code;
    objOpenEdit["spot_price"] = spot_price.price;
    objOpenEdit["spot_date"] = spot_price.date;
    objOpenEdit["unit"] = per_material.unit;
    objOpenEdit["_id"] = spot_price._id;
    onOpenEdit(objOpenEdit);
  };

  let arr_date = [];
  let arr_date_original = [];
  const start = new Date(start_date);
  const end = new Date(end_date);
  let loop = new Date(start);
  while (loop <= end) {
    arr_date.push(dateFormat(loop, "dd-mmm-yy"));
    arr_date_original.push(new Date(loop.getTime()));
    let newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(newDate);
  }
  let date_difference = Math.floor(
    (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
      Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
      (1000 * 60 * 60 * 24)
  );

  return (
    <div className="overflow-auto">
      <Table bordered hover size="sm" style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th
              rowSpan={2}
              className="bg-info"
              style={{ "vertical-align": "middle" }}
            >
              No.
            </th>
            <th
              rowSpan={2}
              style={{ "vertical-align": "middle", width: "12.5%" }}
              className="bg-info"
            >
              Vendor
            </th>
            <th colSpan={(date_difference + 1) * 3} className="bg-info">
              Spot Price
            </th>
          </tr>
          <tr>
            {arr_date.map((element) => (
              <th colSpan={3} className="bg-info">
                {element}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((itemData, idxData) => {
            return (
              <tr>
                <td> {idxData + 1} </td>
                {columns.map((item) => {
                  if (item.type === "text") {
                    return (
                      <td className="col-sm-4">
                        <input
                          type="text"
                          className="form-control form-control-md"
                          placeholder={item.text}
                          value={itemData["po_number"] || ""}
                          onChange={(val) =>
                            handleChange(val.target.value, "po_number", idxData)
                          }
                          required
                        />
                      </td>
                    );
                  }
                  if (item.type === "spot_pricee") {
                    let index_pengurang = [];
                    for (let index = interval_days; index >= 0; index--) {
                      index_pengurang.push(index);
                    }
                    let td_spot_price = index_pengurang.map((element) => (
                      <td>
                        <div className="input-group">
                          <input
                            type="number"
                            min={0}
                            step="any"
                            className="form-control form-control-md Input-number-hide-step"
                            value={
                              itemData["spot_price"][element]
                                ? itemData["spot_price"][element]["price"]
                                : ""
                            }
                            onChange={(val) =>
                              handleChange(val, "spot_price", idxData, element)
                            }
                            required
                          />

                          <div class="input-group-append">
                            <select
                              style={{ width: "50px" }}
                              name="currency"
                              value={
                                itemData["spot_price"][element]["currency"]
                              }
                              onChange={(val) =>
                                handleChange(
                                  val.target.value,
                                  "currency",
                                  idxData,
                                  element
                                )
                              }
                              required
                            >
                              {currencies &&
                                currencies.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </td>
                    ));
                    return td_spot_price;
                  }
                  if (item.type === "spot_price") {
                    return arr_date_original.map((item) => {
                      let to_be_displayed = itemData.spot_price.find(
                        (element) => {
                          return (
                            dateFormat(item, "dd-mmm-yy") ==
                            dateFormat(element.date, "dd-mmm-yy")
                          );
                        }
                      );

                      return to_be_displayed ? (
                        [
                          <td>
                            {to_be_displayed
                              ? to_be_displayed["currency"]
                              : "-"}
                          </td>,
                          <td>
                            {to_be_displayed
                              ? thousandformat(
                                  parseFloat(to_be_displayed["price"]).toFixed(
                                    DECIMAL_PLACES
                                  ),
                                  ".",
                                  ".",
                                  ","
                                )
                              : "-"}
                          </td>,
                          <td>
                            <Button
                              className="btn btn-info btn-xs"
                              key={idxData}
                              onClick={() => {
                                handleOpenEdit(
                                  to_be_displayed,
                                  itemData,
                                  per_material
                                );
                              }}
                            >
                              <i className="fas fa-edit" />
                            </Button>
                          </td>,
                        ]
                      ) : (
                        <td colSpan={3}> - </td>
                      );
                    });
                  }
                  if (item.type === "vendor") {
                    return <td className="col-sm-1">{itemData.vendor_name}</td>;
                  }
                  if (item.type === "del") {
                    return (
                      <td style={{ "vertical-align": "middle" }}>
                        <i
                          className="fas fa-minus bg-danger"
                          style={{
                            color: "white",
                            padding: 5,
                            fontWeight: "bold",
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                          }}
                          onClick={() => handleRemoveRow(idxData)}
                        />
                      </td>
                    );
                  }
                  return "";
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SpotPrice;
