import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MappingClassificationService {
  readMappingClassification(queryString) {
    return axios.get(
      API_URL_DEFAULT + "mapping_classification?" + queryString,
      { headers: authHeader() }
    );
  }
}

export default new MappingClassificationService();
