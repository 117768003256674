import React, { useEffect } from "react";
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceList = ({
  data
}) => {
  const headerStyle = { backgroundColor: '#17A2B8', color: 'white' };
  return (
    <div key={data}>
      <Form>
        <Table bordered hover size="sm" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <thead className="still">
            <tr >
              <th>No</th>
              <th>Material Code</th>
              <th>Material Name</th>
              <th>Facility</th>
              <th>Vendor</th>
              <th>Tonnage</th>
              <th>Price Type</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {data.detail.map((item, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{item.material_code}</td>
                <td>{item.material_desc}</td>
                <td>{item.type}{item.plant ? ' ' + item.plant : ''}</td>
                <td>{item.vendor_toll_out_name}</td>
                <td>{item.tonase}</td>
                <td>{item.price_type}</td>
                <td>{isNaN(parseFloat(item.value)) ? '' : (item.value).toFixed(DECIMAL_PLACES)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>

    </div >
  )
};

export default PriceList;