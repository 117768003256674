import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import FileDownload from "js-file-download";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { Link, withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Pagination from "react-bootstrap/Pagination";
import DataTable from "react-data-table-component";
import { Tab, Tabs } from "react-bootstrap";
import {
  read,
  create,
  update,
  download,
} from "../../modules/sodiq/FreightCostModule";
import thousandformat from "../../helpers/thousanddelimiter";
import Footer from "../../components/templates/Footer";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";

const DataTableCustome = ({
  data,
  loading,
  totalRows,
  handlePageChange,
  handlePerRowsChange,
  headerColumn,
  ActionForm,
}) => {
  const columns = useMemo(() => [...headerColumn], []);
  columns.push({
    name: <b>Action</b>,
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: (row) => {
      return ActionForm(row);
    },
    width: "15%",
    center: true,
  });

  return (
    <DataTable
      className="table table-modern"
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

DataTableCustome.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  loading: PropTypes.bool,
  totalRows: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePerRowsChange: PropTypes.func,
  headerColumn: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  ActionForm: PropTypes.func,
};

DataTableCustome.defaultProps = {
  data: [],
  loading: false,
  totalRows: 0,
  handlePageChange: null,
  handlePerRowsChange: null,
  headerColumn: [],
  ActionForm: {},
};

class Freight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      search: null,
      list_datatable: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "vehicle_type",
      sortOrder: "asc",
      count_data: null,
      current_page: 1,
      columns_local: [
        {
          selector: (row) => row?.category,
          name: <b>Category</b>,
          sort: false,
        },
        {
          selector: (row) => row?.origin,
          name: <b>Origin</b>,
          sort: false,
        },
        {
          selector: (row) => row?.vehicle_type,
          name: <b>Vehicle Type</b>,
          sort: false,
        },
        {
          selector: (row) => row?.city,
          name: <b>City</b>,
          sort: false,
        },
        {
          selector: (row) =>
            thousandformat(isNaN(row?.cost) ? "" : row?.cost, ".", ".", ","),
          name: <b>Freight Cost</b>,
          sort: false,
        },
      ],
      columns_international: [
        {
          selector: (row) => row?.category,
          name: <b>Category</b>,
          sort: false,
        },
        {
          selector: (row) => row?.vehicle_type,
          name: <b>Vehicle Type</b>,
          sort: false,
        },
        {
          selector: (row) => row?.delivery_type,
          name: <b>Delivery Type</b>,
          sort: false,
        },
        {
          selector: (row) => row?.port_dest_code,
          name: <b>Port Dest. Code</b>,
          sort: false,
        },
        {
          selector: (row) => row?.port_dest_name,
          name: <b>Port Dest. Name</b>,
          sort: false,
        },
        {
          selector: (row) => row?.country,
          name: <b>Country</b>,
          sort: false,
        },
        {
          selector: (row) =>
            thousandformat(isNaN(row?.cost) ? "" : row?.cost, ".", ".", ","),
          name: <b>Freight Cost</b>,
          sort: false,
        },
      ],
      columns_local_update: [
        {
          dataField: "category",
          text: "Category",
        },
        {
          dataField: "origin",
          text: "Origin",
        },
        {
          dataField: "vehicle_type",
          text: "Vehicle Type",
        },
        {
          dataField: "city",
          text: "City",
        },
        {
          dataField: "cost",
          text: "Freight Cost",
        },
      ],
      columns_international_update: [
        {
          dataField: "category",
          text: "Category",
        },
        {
          dataField: "vehicle_type",
          text: "Vehicle Type",
        },
        {
          dataField: "delivery_type",
          text: "Delivery Type",
        },
        {
          dataField: "port_dest_code",
          text: "Port Dest. Code",
        },
        {
          dataField: "port_dest_name",
          text: "Port Dest. Name",
        },
        {
          dataField: "country",
          text: "Country",
        },
        {
          dataField: "cost",
          text: "Freight Cost",
        },
      ],
      defaultSorted: [
        {
          selector: "category",
          order: "asc",
        },
      ],
      type_action: null,
      data_detail: null,
      current_filename: "Please choose file...",
      file_value: {},
      file_content: {},
      file_worksheet: {},
      prev_temp_worksheet: [],
      prev_list_worksheet: [],
      prev_curr_page: 1,
      prev_per_page: 10,
      payload: {},
      list_tab: [
        { key: "local", title: "Local" },
        { key: "international", title: "International" },
      ],
      active_tab: "local",
    };
    this.refInput = React.createRef();
  }

  fetchData = (isReset, active_tab) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, page, sizePerPage, search } = this.state;
    let params = {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      tab: active_tab,
    };
    if (search && !isReset) {
      params.search = search;
      params.page = 1;
    }

    if (isReset) {
      this.setState({ search: null });
      params.page = 1;
      const val1 = document.getElementById("search_val_local");
      if (val1) {
        val1.value = "";
      }
      const val2 = document.getElementById("search_val_international");
      if (val2) {
        val2.value = "";
      }
      this.refInput.current.value = "";
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
      });
    });
  };

  //when component already mount (lifecycle react)
  componentDidMount() {
    const { active_tab } = this.state;
    this.fetchData(false, active_tab);
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      date_create: new Date(),
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  ActionForm = (row) => {
    const { isEdit, isDelete } = this.state;
    return (
      <div className="d-flex text-center">
        {isEdit ? (
          <div>
            <button
              key={row.id}
              type="button"
              className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
              size="sm"
              onClick={() => {
                this.toOpen("edit", row);
              }}
            >
              <i className="fas fa-edit" key={row.id}></i>
              &nbsp;Edit
            </button>
          </div>
        ) : (
          ""
        )}
        {isDelete ? (
          <div>
            <button
              key={row.id}
              type="button"
              className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
              size="sm"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <i className="fas fa-trash" key={row.id}></i>
              &nbsp;Delete
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  toOpen = (type, datas) => {
    const { payload } = this.state;
    if (type === "edit") {
      payload._id = datas?._id;
      payload.category = datas?.category;
      payload.origin = datas?.origin;
      payload.vehicle_type = datas?.vehicle_type;
      payload.city = datas?.city;
      payload.delivery_type = datas?.delivery_type;
      payload.paletize = datas?.paletize;
      payload.port_dest_code = datas?.port_dest_code;
      payload.port_dest_name = datas?.port_dest_name;
      payload.country = datas?.country;
      payload.cost = thousandformat(
        isNaN(datas?.cost) ? "" : datas?.cost,
        ".",
        ".",
        ","
      );
    }
    this.setState(
      {
        type_action: type,
        payload: payload,
        prev_list_worksheet: [],
        prev_temp_worksheet: [],
        file_value: {},
        file_content: {},
        file_worksheet: {},
        prev_curr_page: 1,
        prev_per_page: 10,
        current_filename: "Please choose file...",
      },
      () => {
        this.toggle();
      }
    );
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  onModalHide = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleSubmit = () => {
    const { file_value, prev_list_worksheet, active_tab } = this.state;
    if (file_value && prev_list_worksheet.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          create(prev_list_worksheet)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true, active_tab);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", "Please import data...", "error");
    }
  };

  handleUpdate = () => {
    const {
      payload,
      active_tab,
      columns_local_update,
      columns_international_update,
    } = this.state;
    let check = true;
    let msg = "";
    if (active_tab === "local") {
      columns_local_update.map((item) => {
        if (!payload[item.dataField]) {
          check = false;
          if (msg === "") {
            msg = item.text;
          } else {
            msg = `${msg}, ${item.text}`;
          }
        }
      });
    }
    if (active_tab === "international") {
      columns_international_update.map((item) => {
        if (!payload[item.dataField]) {
          check = false;
          if (msg === "") {
            msg = item.text;
          } else {
            msg = `${msg}, ${item.text}`;
          }
        }
      });
    }
    if (check) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          payload.cost = payload.cost.replaceAll(",", "");
          update(payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true, active_tab);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", `Please check your entries (${msg})`, "error");
    }
  };

  handleDelete = (datas) => {
    const { active_tab } = this.state;
    if (datas._id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You wont be able to revert this !",
        icon: "info",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete it!`,
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          const payload = {
            _id: datas._id,
            is_active: false,
          };
          this.setState({ loading: true });
          update(payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({ showModal: false });
                this.fetchData(true, active_tab);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Warning",
                icon: "error",
                text: err,
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({ loading: false });
      Swal.fire("Warning", "Please check your entries...", "error");
    }
  };

  handleFile = (e) => {
    if (e.target.files.length > 0) {
      const fileObject = e.target.files[0];
      this.handleReadFile(fileObject)
        .then((response) => {
          if (response) {
            const { active_tab } = this.state;
            let check = true;
            if (active_tab === "local") {
              if (response[0]?.count_column === 6) {
                check = false;
              }
            } else {
              if (response[0]?.count_column === 8) {
                check = false;
              }
            }
            if (check) {
              Swal.fire("Warning", "Import excel incorrect", "error");
              this.setState({
                prev_list_worksheet: [],
                prev_temp_worksheet: [],
                file_value: {},
                file_content: {},
                file_worksheet: {},
                prev_curr_page: 1,
                prev_per_page: 10,
                current_filename: "Please choose file...",
              });
            } else {
              let temp = response[0]?.temp_data;
              let detail = response[0]?.detail_data;
              this.setState({
                load_file: true,
                file_value: fileObject,
                file_worksheet: response[0],
                file_content: {
                  name: fileObject.name,
                  type: fileObject.type,
                  size: fileObject.size,
                },
                current_filename: fileObject.name,
                prev_list_worksheet: detail,
                prev_temp_worksheet: temp.splice(
                  0,
                  temp.length < 10 ? temp.length : 10
                ),
                prev_curr_page: 1,
                prev_per_page: 10,
              });
            }
          }
        })
        .catch((e) => {
          this.setState({
            load_file: false,
            file_value: null,
            file_worksheet: [],
            file: {
              name: "Please choose file...",
              type: "",
              size: 0,
            },
            current_filename: "Please choose file...",
          });
        })
        .finally(() => {
          this.setState({ errors_create_bulk: {} });
        });
    }
  };

  handleReadFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        const { active_tab } = this.state;

        fileReader.onload = (e) => {
          const binaryString = e.target.result;
          const workbook = XLSX.read(binaryString, { type: "buffer" });

          const result = workbook.SheetNames.map((item) => {
            const worksheet = workbook.Sheets[item];
            const datas = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              defval: "",
              blankrows: false,
              blankcolumn: false,
            });
            const list = [];
            const temp = [];
            let countColumn = 0;
            const item_list = datas.slice(1, datas.length);
            if (item_list.length > 0) {
              if (active_tab === "local") {
                item_list.map((el) => {
                  const obj = {
                    category: el[0],
                    origin: el[1],
                    vehicle_type: el[2],
                    city: el[3],
                    delivery_type: el[4],
                    cost: el[5],
                  };
                  list.push(obj);
                  temp.push(obj);
                  countColumn = el.length;
                  return el;
                });
              } else {
                item_list.map((el) => {
                  const obj = {
                    category: el[0],
                    vehicle_type: el[1],
                    delivery_type: el[2],
                    paletize: el[3],
                    port_dest_code: el[4],
                    port_dest_name: el[5],
                    country: el[6],
                    cost: String(el[7]),
                  };
                  list.push(obj);
                  temp.push(obj);
                  countColumn = el.length;
                  return el;
                });
              }
            }
            return {
              sheetName: item,
              worksheet: item_list,
              detail_data: list,
              temp_data: temp,
              count_column: countColumn,
            };
          });
          resolve(result);
        };
      } catch (e) {
        reject(new Error(e.message));
      }
    });
  };

  changePagiantion = (type) => {
    const { prev_curr_page, prev_per_page, prev_list_worksheet } = this.state;
    const list = [...prev_list_worksheet];

    if (type === "next") {
      let next = prev_curr_page + 1;
      const start = (next - 1) * prev_per_page + 1;
      const end =
        prev_per_page * next < prev_list_worksheet?.length
          ? prev_per_page * next
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: next,
      });
    }
    if (type === "prev") {
      let prev = prev_curr_page - 1;
      const start = (prev - 1) * prev_per_page + 1;
      const end =
        prev_per_page * prev < prev_list_worksheet?.length
          ? prev_per_page * prev
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: prev,
      });
    }
  };

  handleDownloadTemplate = async () => {
    try {
      const { active_tab } = this.state;
      const params = {
        tab: active_tab === "local" ? "Lokal" : "International",
      };
      const result = await download(new URLSearchParams(params));
      const fileName = `Freight-${params.tab}.xlsx`;
      FileDownload(result, fileName);
    } catch (e) {
      Swal.fire("Warning", e, "error");
    }
  };

  setPage = (nPage) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, search, active_tab, sizePerPage } = this.state;
    let params = {
      page: nPage,
      sizePerPage,
      tab: active_tab,
      sortBy,
      sortOrder,
    };
    if (search) {
      params.search = search;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
        sizePerPage: sizePerPage,
      });
    });
  };

  setPerPage = (nPer) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, search, active_tab } = this.state;
    let params = {
      page: 1,
      sizePerPage: nPer,
      tab: active_tab,
      sortBy,
      sortOrder,
    };
    if (search) {
      params.search = search;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
        sizePerPage: nPer,
      });
    });
  };

  render() {
    let {
      columns_local,
      columns_international,
      columns_local_update,
      columns_international_update,
      showModal,
      list_datatable,
      loading,
      isRead,
      totalSize,
      current_filename,
      type_action,
      prev_temp_worksheet,
      prev_list_worksheet,
      prev_curr_page,
      prev_per_page,
      payload,
      search,
      list_tab,
      active_tab,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size={type_action === "edit" ? "lg" : "xl"}
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                {type_action === "edit" ? "Update" : "Import Excel"}{" "}
                {active_tab === "local"
                  ? "Freight Local"
                  : "Freight International"}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {type_action === "edit" ? (
                <Container>
                  {active_tab === "local" &&
                    columns_local_update.map((el, i) => (
                      <div className={`row ${i > 0 ? "mt-3" : ""}`}>
                        <div className="col-12">
                          <label>{el.text}</label>
                          <input
                            className="form-control"
                            value={payload[el.dataField]}
                            onChange={(e) => {
                              const { payload } = this.state;
                              let values = e.target.value;
                              if (el.dataField === "cost") {
                                let pass = e.target.value.replaceAll(",", "");
                                pass = pass.replaceAll(".", "");
                                values = thousandformat(
                                  isNaN(pass) ? "" : pass,
                                  ".",
                                  ".",
                                  ","
                                );
                              }
                              payload[el.dataField] = values;
                              this.setState({ payload: payload });
                            }}
                            readOnly={i === 0}
                          />
                        </div>
                      </div>
                    ))}
                  {active_tab === "international" &&
                    columns_international_update.map((el, i) => (
                      <div className={`row ${i > 0 ? "mt-3" : ""}`}>
                        <div className="col-12">
                          <label>{el.text}</label>
                          <input
                            className="form-control"
                            value={payload[el.dataField]}
                            onChange={(e) => {
                              const { payload } = this.state;
                              let values = e.target.value;
                              if (el.dataField === "cost") {
                                let pass = e.target.value.replaceAll(",", "");
                                pass = pass.replaceAll(".", "");
                                values = thousandformat(
                                  isNaN(pass) ? "" : pass,
                                  ".",
                                  ".",
                                  ","
                                );
                              }
                              payload[el.dataField] = values;
                              this.setState({ payload: payload });
                            }}
                            readOnly={i === 0}
                          />
                        </div>
                      </div>
                    ))}
                </Container>
              ) : (
                <Container>
                  <Row>
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          style={{ marginBottom: "3px" }}
                        >
                          Import Excel <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <div
                            className="custom-file"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={this.handleFile}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {current_filename}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12">Preview Data</div>
                  </Row>
                  <Row className="mt-1">
                    <div className="col-12">
                      <table className="table table-striped">
                        <thead>
                          {active_tab === "local"
                            ? columns_local_update.map((item) => (
                                <th>{item?.text}</th>
                              ))
                            : columns_international_update.map((item) => (
                                <th>{item?.text}</th>
                              ))}
                        </thead>
                        <tbody>
                          {prev_temp_worksheet &&
                            prev_temp_worksheet?.length > 0 &&
                            prev_temp_worksheet.map((item) =>
                              active_tab === "local" ? (
                                <tr>
                                  <td>{item?.category}</td>
                                  <td>{item?.origin}</td>
                                  <td>{item?.vehicle_type}</td>
                                  <td>{item?.city}</td>
                                  <td>{item?.cost}</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>{item?.category}</td>
                                  <td>{item?.vehicle_type}</td>
                                  <td>{item?.delivery_type}</td>
                                  <td>{item?.port_dest_code}</td>
                                  <td>{item?.port_dest_name}</td>
                                  <td>{item?.country}</td>
                                  <td>{item?.cost}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </Row>
                  {prev_temp_worksheet && prev_temp_worksheet?.length > 0 && (
                    <Row>
                      <div className="col-12">
                        <div className="d-flex float-right">
                          <span
                            style={{
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            Showing {(prev_curr_page - 1) * prev_per_page + 1}-
                            {prev_per_page * prev_curr_page <
                            prev_list_worksheet?.length
                              ? prev_per_page * prev_curr_page
                              : prev_list_worksheet?.length}{" "}
                            of {prev_list_worksheet?.length}
                          </span>
                          <Pagination>
                            <Pagination.Prev
                              disabled={prev_curr_page === 1}
                              onClick={() => this.changePagiantion("prev")}
                            />
                            <Pagination.Next
                              disabled={
                                prev_per_page * prev_curr_page >
                                prev_list_worksheet?.length
                              }
                              onClick={() => this.changePagiantion("next")}
                            />
                          </Pagination>
                        </div>
                      </div>
                    </Row>
                  )}
                </Container>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="default"
                onClick={this.onModalHide}
                className="float-left"
              >
                <i className="fas fa-times" /> Close
              </Button>
              {type_action === "edit" ? (
                <Button
                  variant="success"
                  onClick={this.handleUpdate}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Update
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={this.handleSubmit}
                  className="float-right"
                >
                  <i className="fas fa-save" /> Submit
                </Button>
              )}
            </ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Freight Cost</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">freight cost</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Freight Cost{" "}
                            {active_tab === "local" ? "Local" : "International"}
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <Tabs
                            defaultActiveKey="local"
                            variant="tabs"
                            onSelect={(e) => {
                              this.setState({
                                active_tab: e,
                                search: null,
                                page: 1,
                                sizePerPage: 10,
                              });
                              this.refInput.current.value = "";
                              this.fetchData(true, e);
                            }}
                          >
                            {list_tab.map((el) => (
                              <Tab eventKey={el.key} title={el.title}>
                                <>
                                  <Row className="mt-3">
                                    <div className="col-3">
                                      <div className="form-group">
                                        <input
                                          ref={this.refInput}
                                          autoComplete="off"
                                          id={`search_val_${active_tab}`}
                                          placeholder="Search"
                                          value={search}
                                          type="text"
                                          className="form-control"
                                          onChange={(e) => {
                                            this.setState({
                                              search: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <Button
                                          variant="info"
                                          onClick={() =>
                                            this.fetchData(false, active_tab)
                                          }
                                          className="float-left"
                                        >
                                          <i className="fas fa-search" /> Search
                                        </Button>
                                        <Button
                                          style={{ marginLeft: "2px" }}
                                          variant="warning"
                                          onClick={() =>
                                            this.fetchData(true, active_tab)
                                          }
                                          className="float-left"
                                        >
                                          <i className="fas fa-history" /> Reset
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="col-5">
                                      <div className="form-group">
                                        <Button
                                          variant="success"
                                          onClick={() => {
                                            this.toOpen("add", {});
                                          }}
                                          className="float-right"
                                        >
                                          <i className="fas fa-upload" /> Import
                                          Excel
                                        </Button>
                                        <Button
                                          style={{ marginRight: "5px" }}
                                          variant="info"
                                          onClick={() => {
                                            this.handleDownloadTemplate();
                                          }}
                                          className="float-right"
                                        >
                                          <i className="fas fa-download" />{" "}
                                          Download Template
                                        </Button>
                                      </div>
                                    </div>
                                  </Row>
                                  <br />
                                  <DataTableCustome
                                    data={list_datatable}
                                    loading={loading}
                                    totalRows={totalSize}
                                    handlePageChange={this.setPage}
                                    handlePerRowsChange={this.setPerPage}
                                    headerColumn={
                                      el.key === "local"
                                        ? columns_local
                                        : columns_international
                                    }
                                    ActionForm={this.ActionForm}
                                  />
                                </>
                              </Tab>
                            ))}
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(Freight);
