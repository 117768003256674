import React, { Component } from "react";
import RemoteAll from "../components/RemoteAll";
import RemoteNoPagination from "../components/RemoteNoPagination";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { addMonths, differenceInMonths } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";
import XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";

import { readMaterial, readMaterialBySearch } from "../modules/MaterialModule";
import {
  readMaterialAveragePrice,
  readMaterialAveragePriceByMonth,
} from "../modules/MaterialAveragePriceModule";

import { withRouter } from "react-router-dom";
import Select from "react-select";

import { sendNotification } from "../modules/NotificationModule";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class MaterialAveragePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButtonNotification: false,
      showModal: false,
      seconds: 60,
      requester: null,
      isExists: false,
      isSearched: false,
      requester_name: null,
      select_items_material: [],
      material_code: null,
      material_description: null,
      isClearable: false,
      date: new Date(),
      created_at: null,
      uniq_code: null,
      list_datatable_receipt: [
        {
          parameter: "Quantity",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_usage: [
        {
          parameter: "Quantity",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_end_balance: [
        {
          parameter: "Quantity",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      columns_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "beginning_balance",
          text: "Beginning Balance",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      columns_non_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      header_xlsx: [["Parameter"]],
      errors: {},
      isValidMaterial: true,

      loading: false,
      isCreate: true,
      isRead: true,
      isDownload: false,

      list_datatable: [],
      columns: [
        {
          dataField: "material_code",
          text: "No. Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material Description",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "created_at",
          text: "Last Change",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "price_map",
          text: "Price",
          formatter: this.GetPriceMAPFormat,
          classes: "text-center",
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "material_code",
          order: "asc",
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "material_code",
      sortOrder: "asc",
      material_search: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
  }

  toDetail = (row) => {
    let receipt = row.receipt;
    let receipt_formatted = [
      {
        parameter: "Quantity",
        beginning_balance: receipt.qty[0],
        month_1: receipt.qty[1],
        month_2: receipt.qty[2],
        month_3: receipt.qty[3],
        month_4: receipt.qty[4],
        month_5: receipt.qty[5],
        month_6: receipt.qty[6],
      },
      {
        parameter: "Value",
        beginning_balance: receipt.value[0],
        month_1: receipt.value[1],
        month_2: receipt.value[2],
        month_3: receipt.value[3],
        month_4: receipt.value[4],
        month_5: receipt.value[5],
        month_6: receipt.value[6],
      },
      {
        parameter: "Price/kg",
        beginning_balance: receipt.price_kg[0],
        month_1: receipt.price_kg[1],
        month_2: receipt.price_kg[2],
        month_3: receipt.price_kg[3],
        month_4: receipt.price_kg[4],
        month_5: receipt.price_kg[5],
        month_6: receipt.price_kg[6],
      },
    ];

    let usage = row.usage;
    let usage_formatted = [
      {
        parameter: "Quantity",
        month_1: usage.qty[0],
        month_2: usage.qty[1],
        month_3: usage.qty[2],
        month_4: usage.qty[3],
        month_5: usage.qty[4],
        month_6: usage.qty[5],
      },
      {
        parameter: "Value",
        month_1: usage.value[0],
        month_2: usage.value[1],
        month_3: usage.value[2],
        month_4: usage.value[3],
        month_5: usage.value[4],
        month_6: usage.value[5],
      },
      {
        parameter: "Price/kg",
        month_1: usage.price_kg[0],
        month_2: usage.price_kg[1],
        month_3: usage.price_kg[2],
        month_4: usage.price_kg[3],
        month_5: usage.price_kg[4],
        month_6: usage.price_kg[5],
      },
    ];

    let end_balance = row.end_balance;
    let end_balance_formatted = [
      {
        parameter: "Quantity",
        month_1: end_balance.qty[0],
        month_2: end_balance.qty[1],
        month_3: end_balance.qty[2],
        month_4: end_balance.qty[3],
        month_5: end_balance.qty[4],
        month_6: end_balance.qty[5],
      },
      {
        parameter: "Value",
        month_1: end_balance.value[0],
        month_2: end_balance.value[1],
        month_3: end_balance.value[2],
        month_4: end_balance.value[3],
        month_5: end_balance.value[4],
        month_6: end_balance.value[5],
      },
      {
        parameter: "Price/kg",
        month_1: end_balance.price_kg[0],
        month_2: end_balance.price_kg[1],
        month_3: end_balance.price_kg[2],
        month_4: end_balance.price_kg[3],
        month_5: end_balance.price_kg[4],
        month_6: end_balance.price_kg[5],
      },
    ];

    this.setState(
      {
        id: "id",
        list_datatable_receipt: receipt_formatted,
        list_datatable_usage: usage_formatted,
        list_datatable_end_balance: end_balance_formatted,
        created_at: row.created_at,
        uniq_code: row.uniq_code,
        material_code: row.material_code,
        material_description: row.material_name,
        isDownload: true,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  onModalHide = () => {
    this.setState({
      id: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };

  getMaterial = (input) => {
    const queryString = `constraint_exclude=material_type&constraint_value_exclude=ZSFG,ZFGD&sizePerPage=100&search=${input}`;
    const result = input
      ? readMaterialBySearch(queryString).then((res) => {
          return res;
        })
      : [];
    return result;
  };
  onModalLoadingHide() {}

  validateSearch = (date, material_code) => {
    let errors = this.state.errors;
    if (!date) {
      errors.date = "This is field required";
    }
    return errors;
  };

  GetDateFormat = (cell, row) => {
    return <div>{dateFormat(new Date(row.created_at), "dd-mmm-yyyy")}</div>;
  };

  //price MAP format
  GetPriceMAPFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    return (
      <div>
        {this.GetThousandDelimiterFormatNumber(
          row.receipt.price_kg[1],
          "Receipt"
        )}
        {this.GetThousandDelimiterFormatNumber(row.usage.price_kg[0], "Usage")}
        {this.GetThousandDelimiterFormatNumber(
          row.end_balance.price_kg[0],
          "End Balance"
        )}
      </div>
    );
  };
  //action button
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    return (
      <div>
        <button
          key={row.id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(row);
          }}
        >
          <i className="fas fa-edit" key={row.id}></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? (rowIndex > 0 ? "$" : "") +
            (parseFloat(cell) % 1 != 0
              ? thousandformat(cell.toFixed(DECIMAL_PLACES), ".", ".", ",")
              : thousandformat(cell, ".", ".", ","))
          : ""}
      </div>
    );
  };
  GetThousandDelimiterFormatNumber = (number, prefix) => {
    return (
      <div>
        {number || String(number) === "0" || !isNaN(parseFloat(number))
          ? prefix +
            ": $" +
            thousandformat(number.toFixed(DECIMAL_PLACES), ".", ".", ",")
          : ""}
      </div>
    );
  };

  exportFile = () => {
    /* convert state to workbook */
    const {
      list_datatable_usage,
      list_datatable_receipt,
      list_datatable_end_balance,
      material_code,
      date,
      created_at,
      header_xlsx,
      uniq_code,
    } = this.state;

    let data_receipt = [...header_xlsx];
    let data_usage = [...header_xlsx];
    let data_end_balance = [...header_xlsx];

    let space_array = [["", "", "", "", "", "", "", "", "", "", "", "", ""]];

    let date_formatted = dateFormat(date, "mmmyy");
    let date_second = addMonths(date, 6);
    let diffMonths = differenceInMonths(date_second, date);

    /* RECEIPT */

    //creating headers
    data_receipt[0] = [...data_receipt[0], "Beginning Balance"];
    for (let i = 0; i < diffMonths; i++) {
      data_receipt[0] = [
        ...data_receipt[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_receipt.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_receipt = [...data_receipt, rowItem];
    });

    /* USAGE */

    //creating headers
    for (let i = 0; i < diffMonths; i++) {
      data_usage[0] = [
        ...data_usage[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_usage.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_usage = [...data_usage, rowItem];
    });

    /* END BALANCE */

    //creating header
    for (let i = 0; i < diffMonths; i++) {
      data_end_balance[0] = [
        ...data_end_balance[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_end_balance.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_end_balance = [...data_end_balance, rowItem];
    });

    let merge_data = data_receipt
      .concat(space_array)
      .concat(data_usage.concat(space_array))
      .concat(data_end_balance);

    let ws_data = XLSX.utils.aoa_to_sheet(merge_data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws_data);
    let year = dateFormat(created_at, "yyyy");
    let month = dateFormat(created_at, "mm");
    let tanggal = dateFormat(created_at, "dd");
    let date_for_file_name = year + "." + month + "." + tanggal;
    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      "map_version_" + date_for_file_name + "_" + material_code + ".xlsx"
    );
  };

  columns_default_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
    {
      dataField: "beginning_balance",
      text: "Beginning Balance",
      formatter: this.GetThousandDelimiterFormat,
      classes: "text-center",
    },
  ];
  columns_default_non_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
  ];

  handleDate = (name, date) => {
    this.setState({
      date: date,
    });
  };

  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "map",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  handleSearch = () => {
    this.setState(
      {
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        sortBy: "material_code",
        sortOrder: "asc",
      },
      () => {
        let {
          date,
          material_code,
          sortBy,
          sortOrder,
          page,
          sizePerPage,
          material_search,
        } = this.state;

        if (!this.validateSearch(date, material_code)) {
          return this.setState({
            isSearched: false,
          });
        }
        if (date) {
          this.setState({
            isSearched: true,
            list_datatable_receipt: [
              {
                parameter: "Quantity",
                beginning_balance: null,
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Value",
                beginning_balance: null,
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Price/kg",
                beginning_balance: null,
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
            ],
            list_datatable_usage: [
              {
                parameter: "Quantity",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Value",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Price/kg",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
            ],
            list_datatable_end_balance: [
              {
                parameter: "Quantity",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Value",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
              {
                parameter: "Price/kg",
                month_1: null,
                month_2: null,
                month_3: null,
                month_4: null,
                month_5: null,
                month_6: null,
              },
            ],
          });

          let start_month = dateFormat(date, "yyyy-mm-01");
          let query_string = `start_month=${start_month}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
          if (material_search) {
            query_string += `&material_search=${material_search}`;
          }
          readMaterialAveragePriceByMonth(query_string).then((response) => {
            this.setState({
              list_datatable: response.foundData ? response.foundData : [],
              totalSize: response.countData ? response.countData : 0,
              page: response.currentPage ? response.currentPage : 1,
              columns_receipt: [
                {
                  dataField: "parameter",
                  text: "Parameter",
                  classes: "text-center",
                },
                {
                  dataField: "beginning_balance",
                  text: "Beginning Balance",
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_1",
                  text: dateFormat(new Date(date), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_2",
                  text: dateFormat(addMonths(new Date(date), 1), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_3",
                  text: dateFormat(addMonths(new Date(date), 2), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_4",
                  text: dateFormat(addMonths(new Date(date), 3), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_5",
                  text: dateFormat(addMonths(new Date(date), 4), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_6",
                  text: dateFormat(addMonths(new Date(date), 5), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ],
              columns_non_receipt: [
                {
                  dataField: "parameter",
                  text: "Parameter",
                  classes: "text-center",
                },
                {
                  dataField: "month_1",
                  text: dateFormat(new Date(date), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_2",
                  text: dateFormat(addMonths(new Date(date), 1), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_3",
                  text: dateFormat(addMonths(new Date(date), 2), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_4",
                  text: dateFormat(addMonths(new Date(date), 3), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_5",
                  text: dateFormat(addMonths(new Date(date), 4), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_6",
                  text: dateFormat(addMonths(new Date(date), 5), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ],
            });
          });
        }
      }
    );
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
        material_code_object: newValue,
      });
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
        });
      }
    }
  };

  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, material_search }
  ) => {
    let { date } = this.state;
    let encoded_filter = encodeURI(JSON.stringify(filters));
    this.setState({
      page: page,
      sizePerPage: sizePerPage,
      sortBy: sortField,
      sortOrder: sortOrder,
      encoded_filter: encoded_filter,
    });

    let start_month = dateFormat(date, "yyyy-mm-01");
    let query_string = `start_month=${start_month}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&filter=${encoded_filter}`;
    if (material_search) {
      query_string += `&material_search=${material_search}`;
    }
    readMaterialAveragePriceByMonth(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };

  render() {
    let {
      disableButtonNotification,
      showModal,
      seconds,
      select_items_material,
      material_code,
      material_description,
      isValidMaterial,
      isClearable,
      date,
      list_datatable_receipt,
      list_datatable_usage,
      list_datatable_end_balance,
      columns_receipt,
      columns_non_receipt,
      errors,
      isRead,
      isDownload,
      errorMessage,
      isExists,
      isSearched,
      uniq_code,
      material_code_object,

      list_datatable,
      columns,
      defaultSorted,
      cellEditProps,
      page,
      sizePerPage,
      totalSize,
      material_search,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />

          {/* MODAL DETAIL */}
          <Modal
            show={showModal}
            dialogClassName="modal-90w"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                ({material_code}) {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <Row>
                  <Col>
                    <h5>Version {uniq_code}</h5>
                    <h5>Receipt</h5>
                  </Col>
                  <Col>
                    {isDownload && (
                      <div style={{ marginBottom: 20 }}>
                        <Button
                          variant="success"
                          onClick={this.exportFile}
                          className="float-right"
                        >
                          <i className="fas fa-download" /> Export to Excel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <RemoteNoPagination
                  data={list_datatable_receipt}
                  columns={columns_receipt}
                />
                <h5>Usage</h5>
                <RemoteNoPagination
                  data={list_datatable_usage}
                  columns={columns_non_receipt}
                />
                <h5>End Balance</h5>
                <RemoteNoPagination
                  data={list_datatable_end_balance}
                  columns={columns_non_receipt}
                />
              </div>
              {/* )} */}
            </ModalBody>
          </Modal>

          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Material Average Price </h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <form
                          autoComplete="off"
                          noValidate
                          ref={(form) => (this.form = form)}
                        >
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="form-group">
                                <label htmlFor="date">Planning Month *</label>
                                <DatePicker
                                  selected={date}
                                  startDate={date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "date")}
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="date">Material Code/Desc</label>
                                <input
                                  className={"form-control"}
                                  name="material_search"
                                  value={material_search || ""}
                                  placeholder="Search ..."
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  onChange={this.onChangeValue}
                                />
                              </div>
                            </Col>
                            <Col className="form-group d-flex align-items-end">
                              <Col xs={12} md={9}></Col>
                              <Col xs={12} md={3}>
                                <Button
                                  variant="warning"
                                  onClick={this.handleNotification}
                                  className="float-left"
                                  disabled={disableButtonNotification}
                                >
                                  <i className="fab fa-telegram-plane" /> Send
                                  Notification{" "}
                                  {disableButtonNotification
                                    ? `(in ${seconds}s)`
                                    : ""}
                                </Button>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-right"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </form>
                        <br />
                        <RemoteAll
                          data={list_datatable}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={totalSize}
                          onTableChange={this.handleTableChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(MaterialAveragePrice);
