import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import { addMonths } from "date-fns";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import DecimalPrecision from "../helpers/decimalprecision";
const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceListMonthly = ({ data, planning_month }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const detailStyle = { color: "#0052CC", cursor: "pointer" };

  const [isOpen, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [title, setTitle] = useState("");

  const renderHeaderMonth = () => {
    let list_date = [];
    let date = new Date(planning_month);
    for (let i = 0; i < 6; i++) {
      list_date.push(<th>{dateFormat(addMonths(date, i), "mmm-yy")}</th>);
    }
    return list_date;
  };

  const detail = (name, row, index) => {
    const datas = data[row]["detail_price" + index];
    setTitle(
      `${name} ${dateFormat(
        addMonths(new Date(planning_month), index),
        "mmm-yy"
      )}`
    );
    if (datas) {
      setDataDetail(datas);
    }
    setOpen(true);
  };

  return (
    <>
      <div key={data}>
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="Stick to top">
              <tr style={headerStyle}>
                <th>No</th>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Facility</th>
                <th>Status</th>
                {renderHeaderMonth()}
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{item.material_code}</td>
                  <td>{item.material_name}</td>
                  <td>
                    {item.type}
                    {item.plant ? " " + item.plant : ""}
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {isNaN(parseFloat(item.val0)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 0)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 0)}
                      >
                        {item.val0.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val1)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 1)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 1)}
                      >
                        {item.val1.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val2)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 2)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 2)}
                      >
                        {item.val2.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val3)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 3)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 3)}
                      >
                        {item.val3.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val4)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 4)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 4)}
                      >
                        {item.val4.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val5)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 5)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 5)}
                      >
                        {item.val5.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
      <Modal show={isOpen} size="lg" backdrop="static" onHide={setOpen}>
        <ModalHeader closeButton className="bg-info text-white">
          <ModalTitle>Detail {title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Container>
            <table className="table table-bordered">
              <thead>
                <tr style={headerStyle}>
                  <th>Material Desc</th>
                  <th>Price/KG</th>
                  <th>Amount/Batch</th>
                  <th>Total</th>
                  <th>Plant</th>
                </tr>
              </thead>
              <tbody>
                {dataDetail && dataDetail.length > 0 ? (
                  dataDetail.map((item) => {
                    return (
                      <tr>
                        <td>{item.material_description_rm}</td>
                        <td>
                          {!isNaN(item.price) && item.price !== ""
                            ? DecimalPrecision.toFixed(
                                item.price,
                                DECIMAL_PLACES
                              )
                            : "not found"}
                        </td>
                        <td>{item.amount_per_batch}</td>
                        <td>
                          {!isNaN(item.total) && item.total !== ""
                            ? DecimalPrecision.toFixed(
                                item.total,
                                DECIMAL_PLACES
                              )
                            : "not found"}
                        </td>
                        <td>{item.plant || "not found"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>Data Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PriceListMonthly;
