import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ConvertService {
  convertPowderBom(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/bom-powder/", payload, {
      headers: authHeader(),
    });
  }
  convertFGBom(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/bom-fg/", payload, {
      headers: authHeader(),
    });
  }
  convertRMBalance(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/rm-balance/", payload, {
      headers: authHeader(),
    });
  }
  convertPOContract(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/po-contract/", payload, {
      headers: authHeader(),
    });
  }
  convertMAP(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/map/", payload, {
      headers: authHeader(),
    });
  }
  convertMaterialClassification(payload) {
    return axios.post(API_URL_DEFAULT + "convert-material/material-classification/", payload, {
      headers: authHeader(),
    });
  }
}

export default new ConvertService();
