import React, { Component } from "react";
import RemoteAll from "../components/RemoteAll";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import {
  readExchangeRate,
  addExchangeRate,
  editExchangeRate,
} from "../modules/ExchangeRateModule";
import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import filterFactory, { Comparator } from "react-bootstrap-table2-filter";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

class ExchangeRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_edit: false,
      showModal: false,
      showModalExchangeRate: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_currency: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      currency: null,
      opening_rate: null,
      closing_rate: null,
      date: null,
      currency_create: null,
      opening_rate_create: null,
      closing_rate_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "date",
      sortOrder: "desc",
      columns: [
        {
          dataField: "date",
          text: "Rate Date",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "opening_rate",
          text: "Opening Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "closing_rate",
          text: "Closing Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "kurs_tengah",
          text: "Average Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "date",
          order: "desc",
        },
      ],
      cellEditProps: {},
    };
  }
  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const currency_string = `?search=all`;
    //read data currency without rp on backend
    readCurrencyWithoutRP(currency_string).then(async (response) => {
      const columns = [
        {
          dataField: "date",
          text: "Rate Date",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "opening_rate",
          text: "Opening Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "closing_rate",
          text: "Closing Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "kurs_tengah",
          text: "Average Rate",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ];
      this.setState({ columns: columns }, () => {
        readCurrencyWithoutRP("").then(async (response) => {
          this.setState({ select_items: response });
        });
      });
    });
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const date_first = subDays(new Date(), 7);
    const date_second = new Date();
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    //read data exchange rate on backend
    readExchangeRate(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });
    this.setState({
      date_create: new Date(),
      date_first: subDays(new Date(), 7),
      date_second: new Date(),
      requester: user.username,
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable

  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.date), "dd mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let datenow = new Date();
    let is_noneditable = true;
    let date_db = new Date(row.date);
    let currencyreadOnly = true;
    if (this.diff_date_on_day(date_db, datenow) <= 7) {
      is_noneditable = false;
    }
    const { isEdit } = this.state;
    return !is_noneditable && isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetail(
              row._id,
              row.currency,
              row.opening_rate,
              row.closing_rate,
              date_db,
              is_noneditable,
              currencyreadOnly
            )
          }
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    let formatted_cell = isNaN(cell)
      ? ""
      : parseFloat(cell).toFixed(DECIMAL_PLACES);
    return <div>{thousandformat(formatted_cell, ".", ".", ",")}</div>;
  };
  //trigger modal
  toDetail = (
    id,
    currency,
    opening_rate,
    closing_rate,
    date,
    currencyreadOnly
  ) => {
    this.setState(
      {
        id: id,
        currency: currency,
        opening_rate: opening_rate,
        closing_rate: closing_rate,
        date: date,
        currencyreadOnly: true,
        submitted: false,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      opening_rate: null,
      closing_rate: null,
      date: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = (currency, opening_rate, closing_rate, date) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!opening_rate) {
      errors.opening_rate = "This is field required";
    } else if (opening_rate < 0) {
      errors.opening_rate = "Value cannot below 0";
    }

    if (!closing_rate) {
      errors.closing_rate = "This is field required";
    } else if (closing_rate < 0) {
      errors.closing_rate = "Value cannot below 0";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  validateFormCreate = (currency, opening_rate, closing_rate, date) => {
    let errors = this.state.errors_create;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!opening_rate) {
      errors.opening_rate = "This is field required";
    } else if (opening_rate < 0) {
      errors.opening_rate = "Value cannot below 0";
    }

    if (!closing_rate) {
      errors.closing_rate = "This is field required";
    } else if (closing_rate < 0) {
      errors.closing_rate = "Value cannot below 0";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { date_first, date_second, page, sizePerPage, sortBy, sortOrder } =
      this.state;
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    this.form.reset();
    readExchangeRate(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        submitted: false,
        currency_create: null,
        opening_rate_create: null,
        closing_rate_create: null,
        date_create: null,
      });
    });
  };
  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
    const { date_first, date_second } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      currency: "currency",
      opening_rate: "opening_rate",
      closing_rate: "closing_rate",
      kurs_tengah: "kurs_tengah",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }

    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }
    readExchangeRate(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const {
      requester,
      currency_create,
      opening_rate_create,
      closing_rate_create,
      date_create,
    } = this.state;
    if (
      !this.validateFormCreate(
        currency_create,
        opening_rate_create,
        closing_rate_create,
        date_create
      )
    ) {
      return;
    }

    let date_formatted = dateFormat(date_create, "dd mmmm yyyy");
    let collection = {
      requester: requester,
      currency: currency_create,
      opening_rate: opening_rate_create,
      closing_rate: closing_rate_create,
      date: date_formatted,
    };
    if (
      requester &&
      currency_create &&
      opening_rate_create &&
      closing_rate_create &&
      date_create &&
      opening_rate_create >= 0 &&
      closing_rate_create >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addExchangeRate(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
                history.push("/price/exchange-rate");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-failed",
              });
              if (err === "Data is already exists!") {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${date_formatted} ${currency_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    editExchangeRate(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          //read data exchange rate on backend
                          this.reloadDataBackend();

                          history.push("/price/exchange-rate");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted_edit: true });
    const { id, currency, opening_rate, closing_rate, date, requester } =
      this.state;

    if (!this.validateForm(currency, opening_rate, closing_rate, date)) {
      return;
    }
    const { history } = this.props;
    let collection = {
      currency: currency,
      opening_rate: opening_rate,
      closing_rate: closing_rate,
      date: date,
      requester: requester,
    };
    if (
      id &&
      currency &&
      opening_rate &&
      closing_rate &&
      date &&
      opening_rate >= 0 &&
      closing_rate >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editExchangeRate(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted_edit: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/price/exchange-rate");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { date_first, date_second, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    if (date_first > date_second) {
      this.setState({
        date_first: subDays(new Date(), 7),
        date_second: new Date(),
      });
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readExchangeRate(query_string).then((response) => {
      this.setState(
        {
          list_datatable: response.foundData ? response.foundData : [],
          totalSize: response.foundData ? response.countData : 0,
          page: response.foundData ? response.currentPage : 1,
        },
        () => {
          if (response.foundData.length === 0) {
            query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=1&sizePerPage=${sizePerPage}`;
            readExchangeRate(query_string).then((res) => {
              this.setState({
                list_datatable: res.foundData ? res.foundData : [],
                totalSize: res.foundData ? res.countData : 0,
                page: res.foundData ? res.currentPage : 1,
              });
            });
          }
        }
      );
    });
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items,
      list_datatable,
      id,
      currency,
      opening_rate,
      closing_rate,
      date,
      currency_create,
      opening_rate_create,
      closing_rate_create,
      date_create,
      date_first,
      date_second,
      errors,
      errors_create,
      submitted,
      submitted_edit,
      currencyreadOnly,
      loading,
      isClearable,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted_edit
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Exchange Rate to Rp
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="form-group">
                        <label htmlFor="date">Rate Date *</label>
                        <DatePicker
                          selected={date}
                          startDate={date}
                          disabledKeyboardNavigation
                          onChange={this.handleDate.bind(this, "date")}
                          name="date"
                          dateFormat="dd MMMM yyyy"
                          wrapperClassName="datePickerCustom"
                          className="form-control form-control-sm"
                          required
                          isClearable
                        />
                        {errors.date && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.date}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          errors.currency != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>

                        <select
                          className={
                            errors.currency != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="currency"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={currency}
                          readOnly={currencyreadOnly}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items &&
                            select_items.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.currency && (
                          <div className="invalid-feedback">
                            {errors.currency}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="opening_rate">Opening Rate *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="opening_rate"
                          placeholder="Opening Rate"
                          required="required"
                          onChange={this.onChangeValue}
                          value={opening_rate || ""}
                          min="0"
                        />
                        {errors.opening_rate && (
                          <div className="invalid-feedback">
                            {errors.opening_rate}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="closing_rate">Closing Rate *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="closing_rate"
                          placeholder="Closing Rate"
                          required="required"
                          onChange={this.onChangeValue}
                          value={closing_rate || ""}
                          min="0"
                        />
                        {errors.closing_rate && (
                          <div className="invalid-feedback">
                            {errors.closing_rate}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Exchange Rate</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div className="form-group">
                                  <label htmlFor="date">Rate Date *</label>
                                  <DatePicker
                                    selected={date_create}
                                    startDate={date_create}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_create"
                                    )}
                                    name="date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                    isClearable={isClearable}
                                  />
                                  {errors_create.date && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.date}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={
                                    errors_create.currency !=
                                      "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="currency">Currency *</label>

                                  <select
                                    className={
                                      errors_create.currency !=
                                        "This field is required"
                                        ? "form-control form-control-sm "
                                        : "form-control form-control-sm  has-error has-feedback"
                                    }
                                    name="currency_create"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={currency_create}
                                    readOnly={currencyreadOnly}
                                    required
                                  >
                                    <option value="">Choose</option>
                                    {select_items &&
                                      select_items.map(function (item, i) {
                                        return (
                                          <option value={item.value} key={i}>
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors_create.currency && (
                                    <div className="invalid-feedback">
                                      {errors_create.currency}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="opening_rate">
                                    Opening Rate *
                                  </label>
                                  <input
                                    type="number"
                                    step="0.001"
                                    className="form-control form-control-sm"
                                    name="opening_rate_create"
                                    placeholder="Opening Rate"
                                    required="required"
                                    value={opening_rate_create || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                  />
                                  {errors_create.opening_rate && (
                                    <div className="invalid-feedback">
                                      {errors_create.opening_rate}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="closing_rate">
                                    Closing Rate *
                                  </label>
                                  <input
                                    type="number"
                                    step="0.001"
                                    className="form-control form-control-sm"
                                    name="closing_rate_create"
                                    placeholder="Closing Rate"
                                    required="required"
                                    onChange={this.onChangeValue}
                                    value={closing_rate_create || ""}
                                    min="0"
                                  />
                                  {errors_create.closing_rate && (
                                    <div className="invalid-feedback">
                                      {errors_create.closing_rate}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">Start Date</label>
                                  <DatePicker
                                    selected={date_first}
                                    startDate={date_first}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_first"
                                    )}
                                    name="date"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">End Date</label>
                                  <DatePicker
                                    selected={date_second}
                                    startDate={date_second}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_second"
                                    )}
                                    name="date"
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            cellEditProps={cellEditProps}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(ExchangeRate);
