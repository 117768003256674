import MonitoringScheduleService from "../../services/ninda/monitoring_scheduler.service";

export const monitoringScheduler = (payload) => {
    return MonitoringScheduleService.monitoring_scheduler(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    )
}

export const triggerPowderPrice = (payload) => {
    return MonitoringScheduleService.triggerPowderPrice(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    )
}

export const getCloudRunJobs = (page) => {
    return MonitoringScheduleService.get_cloud_run_jobs(page).then(
        (response) => {
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message);
        }
    );
}