import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import { Link, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  read,
  download,
} from "../../modules/sodiq/ImportedSalesforecastModule";
import Footer from "../../components/templates/Footer";
import Header from "../../components/templates/Header";
import SideMenu from "../../components/templates/SideMenu";
import moment from "moment/moment";

const DataTableCustome = ({
  data,
  loading,
  totalRows,
  handlePageChange,
  handlePerRowsChange,
  headerColumn,
  ActionForm,
}) => {
  const columns = useMemo(() => [...headerColumn], []);
  columns.push({
    name: <b>Action</b>,
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: (row) => {
      return ActionForm(row);
    },
    width: "15%",
    center: true,
  });

  return (
    <DataTable
      className="table table-modern"
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

DataTableCustome.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  loading: PropTypes.bool,
  totalRows: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePerRowsChange: PropTypes.func,
  headerColumn: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  ActionForm: PropTypes.func,
};

DataTableCustome.defaultProps = {
  data: [],
  loading: false,
  totalRows: 0,
  handlePageChange: null,
  handlePerRowsChange: null,
  headerColumn: [],
  ActionForm: {},
};

class VehicleTonase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      search: null,
      list_datatable: [],
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "created_at",
      sortOrder: "asc",
      count_data: null,
      current_page: 1,
      columns: [
        {
          selector: (row) =>
            `${row?.created_by?.username} - ${row?.created_by?.name}`,
          name: <b>Created By</b>,
          sort: false,
        },
        {
          selector: (row) =>
            moment(row?.created_at).format("DD MMM YYYY HH:mm"),
          name: <b>Create At</b>,
          sort: false,
        },
      ],
      defaultSorted: [
        {
          dataField: "created_at",
          order: "asc",
        },
      ],
    };
  }

  fetchData = (isReset) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, page, sizePerPage, search } = this.state;
    let params = {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
    };
    if (search && !isReset) {
      params.search = search;
      params.page = 1;
    }

    if (isReset) {
      params.page = 1;
      this.setState({ search: null });
      document.getElementById("search_val").value = "";
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
      });
    });
  };

  //when component already mount (lifecycle react)
  componentDidMount() {
    this.fetchData(false);
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      date_create: new Date(),
      requester: user.username,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  ActionForm = (row) => {
    return (
      <div className="d-flex text-center">
        <div>
          <button
            key={row.id}
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.handleDownload(row);
            }}
          >
            <i className="fas fa-download" key={row.id}></i>
            &nbsp;Download
          </button>
        </div>
      </div>
    );
  };

  handleDownload = (datas) => {
    const params = {
      onedrive_id: datas.onedrive_id,
    };
    download(new URLSearchParams(params))
      .then((res) => {
        if (res.file) {
          const linkSource = `data:${res.mimetype};base64,${res.file}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = datas.filename;
          downloadLink.click();
        } else {
          Swal.fire("Warning", "Error when download file", "error");
        }
      })
      .catch((ee) => {
        Swal.fire("Warning", ee, "error");
      });
  };

  changePagiantion = (type) => {
    const { prev_curr_page, prev_per_page, prev_list_worksheet } = this.state;
    const list = [...prev_list_worksheet];

    if (type === "next") {
      let next = prev_curr_page + 1;
      const start = (next - 1) * prev_per_page + 1;
      const end =
        prev_per_page * next < prev_list_worksheet?.length
          ? prev_per_page * next
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: next,
      });
    }
    if (type === "prev") {
      let prev = prev_curr_page - 1;
      const start = (prev - 1) * prev_per_page + 1;
      const end =
        prev_per_page * prev < prev_list_worksheet?.length
          ? prev_per_page * prev
          : prev_list_worksheet?.length;
      const new_list = list.slice(start, end);
      this.setState({
        prev_temp_worksheet: new_list,
        prev_curr_page: prev,
      });
    }
  };

  setPage = (nPage) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, search, sizePerPage } = this.state;
    let params = {
      page: nPage,
      sizePerPage,
      sortBy,
      sortOrder,
    };
    if (search) {
      params.search = search;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
        sizePerPage: sizePerPage,
      });
    });
  };

  setPerPage = (nPer) => {
    this.setState({ loading: true });
    const { sortBy, sortOrder, search } = this.state;
    let params = {
      page: 1,
      sizePerPage: nPer,
      sortBy,
      sortOrder,
    };
    if (search) {
      params.search = search;
    }

    read(new URLSearchParams(params)).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        loading: false,
        sizePerPage: nPer,
      });
    });
  };

  render() {
    let { columns, list_datatable, loading, isRead, totalSize, search } =
      this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Imported Sales Forecast</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        imported sales forecast
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">
                            Imported Sales Forecast
                          </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <div className="col-3">
                                <div className="form-group">
                                  <input
                                    id="search_val"
                                    placeholder="Search by Created By"
                                    value={search}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({ search: e.target.value });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <Button
                                    variant="info"
                                    onClick={() => this.fetchData(false)}
                                    className="float-left"
                                  >
                                    <i className="fas fa-search" /> Search
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "2px" }}
                                    variant="warning"
                                    onClick={() => this.fetchData(true)}
                                    className="float-left"
                                  >
                                    <i className="fas fa-history" /> Reset
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </form>
                          <br />
                          <DataTableCustome
                            data={list_datatable}
                            loading={loading}
                            totalRows={totalSize}
                            handlePageChange={this.setPage}
                            handlePerRowsChange={this.setPerPage}
                            headerColumn={columns}
                            ActionForm={this.ActionForm}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(VehicleTonase);
