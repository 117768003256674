import MaterialClassificationService from "../services/material_classification.service";

export const readMaterialClassification = (queryString) => {
  return MaterialClassificationService.readMaterialClassification(
    queryString
  ).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
