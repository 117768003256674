import ResourceService from '../services/resource.service'

export const addResource = (payload) => {
    return ResourceService.create(payload).then(
        (response) => {

            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const editResource = (payload) => {
    return ResourceService.edit(payload).then(
        (response) => {

            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};
export const deleteResource = (payload) => {
    return ResourceService.delete(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const readResource = (qs) => {
    return ResourceService.readResource(qs).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};
