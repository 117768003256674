import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class POContractService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "po_contract", payload, {
      headers: authHeader(),
    });
  }
  readPOContract(query_string) {
    return axios.get(API_URL_DEFAULT + "po_contract/?" + query_string, {
      headers: authHeader(),
    });
  }
  searchFuturePrice(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/search-future-price?" + query_string,
      { headers: authHeader() }
    );
  }
  getLastExchange(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/search-last-exchange?" + query_string,
      { headers: authHeader() }
    );
  }
  getLastUpdatedData(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/get-last-updated-data/?" + query_string,
      { headers: authHeader() }
    );
  }
  checkActiveHash(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/check-active-hash/?" + query_string,
      { headers: authHeader() }
    );
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "po_contract", payload, {
      headers: authHeader(),
    });
  }

  readHistoryPOContract(query_string) {
    return axios.get(API_URL_DEFAULT + "po_contract/history?" + query_string, {
      headers: authHeader(),
    });
  }
  checkPrevMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/check-previous-month?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }
  getPrevMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT + "po_contract/previous-month?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new POContractService();
