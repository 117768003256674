import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class UploadFileService {
  uploadFile(payload) {
    return axios.post(API_URL_DEFAULT + "upload_file", payload, {
      headers: authHeader(),
    });
  }
  downloadFile(query_string) {
    return axios.get(API_URL_DEFAULT + "upload_file/read?" + query_string, {
      headers: authHeader(),
    });
  }
  readAllFile(query_string) {
    return axios.get(
      API_URL_DEFAULT + "upload_file/read-all-files?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new UploadFileService();
