import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class CryptoService {
  generate(payload) {
    return axios.post(API_URL_DEFAULT + "crypto/generate", payload, {
      headers: authHeader(),
    });
  }
  encrypt(payload) {
    return axios.post(API_URL_DEFAULT + "crypto/encrypt/", payload, {
      headers: authHeader(),
    });
  }
  decrypt(payload) {
    return axios.post(API_URL_DEFAULT + "crypto/decrypt/", payload, {
      headers: authHeader(),
    });
  }
}

export default new CryptoService();
