import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class VendorService {

    create(payload) {
        return axios
            .post(API_URL_DEFAULT + "vendor", payload, { headers: authHeader() });
    }
    readVendor() {
        return axios
            .get(API_URL_DEFAULT + "vendor/?sizePerPage=100000", { headers: authHeader() });
    }
    edit(payload) {
        return axios
            .put(API_URL_DEFAULT + "vendor", payload, { headers: authHeader() });
    }

    delete(payload) {
        return axios
            .delete(API_URL_DEFAULT + "vendor", { data: payload, headers: authHeader() });
    }

}

export default new VendorService();