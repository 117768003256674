import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Select from "react-select";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";

const DynamicTable = ({
  data, //data that is inputted by user in each row
  columns, //column from the parent, each type of PR has its own column
  onChange, //to return data to parrent
  materials, // list materials to be picked by user
  uom, //list uom to be picked user
  gl_account, //list gl account to be picked by user
  read_type,
}) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };

  const handleTableRowAdd = () => {
    // make new object based on the data sent and empty it
    let data_template = { ...data[0] };
    Object.keys(data_template).forEach((key) => (data_template[key] = ""));

    data.push(data_template);
    onChange(data);
  };

  const handleTableRowRemove = () => {
    if (data.length > 1) {
      data.pop();
      onChange(data);
    }
  };

  const handleTextChange = (val, type, idx) => {
    if (type === "text") {
      data[idx] = {
        ...data[idx],
        name: val,
      };
    }
    if (type === "text2") {
      data[idx] = {
        ...data[idx],
        uom: val,
      };
    }
    if (type === "numeric") {
      data[idx] = {
        ...data[idx],
        kg: val,
      };
    }
    if (type === "numeric2") {
      data[idx] = {
        ...data[idx],
        price: val,
      };
    }
    onChange(data);
  };

  return (
    <Container>
      <Table
        hover
        size="md"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <thead>
          <tr key="0">
            <th> No. </th>
            {columns.map((item, c) => (
              <th key={c}>{item.text} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((itemData, idxData) => (
            <tr key={idxData}>
              <td key={`td-${idxData}`}> {idxData + 1} </td>
              {columns.map((item) => {
                if (item.type === "text") {
                  return (
                    <td className="col-sm-3" key={`td-text-${idxData}`}>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={item.text}
                        required="required"
                        defaultValue={itemData.name}
                        readOnly={read_type == "readonly" ? true : false}
                        onChange={(val) =>
                          handleTextChange(val.target.value, item.type, idxData)
                        }
                      />
                    </td>
                  );
                }

                if (item.type === "text2") {
                  return (
                    <td className="col-sm-3" key={`td-item-${idxData}`}>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={item.text}
                        required="required"
                        defaultValue={itemData.uom}
                        readOnly={read_type == "readonly" ? true : false}
                        onChange={(val) =>
                          handleTextChange(val.target.value, item.type, idxData)
                        }
                      />
                    </td>
                  );
                }

                if (item.type === "numeric") {
                  return (
                    <td className="col-sm-3" key={`td-numeric-${idxData}`}>
                      <input
                        type="number"
                        min="0"
                        step="any"
                        className="form-control form-control-md"
                        placeholder={item.text}
                        required="required"
                        readOnly={read_type == "readonly" ? true : false}
                        onChange={(val) =>
                          handleTextChange(val.target.value, item.type, idxData)
                        }
                        defaultValue={itemData.kg}
                      />
                    </td>
                  );
                }
                if (item.type === "numeric2") {
                  return (
                    <td className="col-sm-3" key={`td-numeric2-${idxData}`}>
                      <input
                        type="number"
                        min="0"
                        step="any"
                        className="form-control form-control-md"
                        placeholder={item.text}
                        required="required"
                        readOnly={read_type == "readonly" ? true : false}
                        onChange={(val) =>
                          handleTextChange(val.target.value, item.type, idxData)
                        }
                        defaultValue={itemData.price}
                      />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      <i
        className="fas fa-plus"
        style={{
          backgroundColor: "green",
          color: "white",
          padding: 5,
          fontWeight: "bold",
          borderRadius: 3,
          display: read_type == "readonly" ? "none" : "",
        }}
        onClick={() => handleTableRowAdd()}
      ></i>

      <i
        className="fas fa-minus"
        style={{
          backgroundColor: "red",
          color: "white",
          padding: 5,
          fontWeight: "bold",
          borderRadius: 3,
          display: read_type == "readonly" ? "none" : "",
        }}
        onClick={() => handleTableRowRemove()}
      ></i>
    </Container>
  );
};

export default DynamicTable;
