import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import RemoteNoPagination from "../components/RemoteNoPagination";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { addMonths, differenceInMonths } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";
import "react-datepicker/dist/react-datepicker.css";
import { readMaterialClassification } from "../modules/MaterialClassificationModule";
import { readMAPRND, readMAPRNDByMonth } from "../modules/MAPRNDModule";
import { readParameterByType } from "../modules/ParameterModule";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import XLSX, { utils } from "xlsx";
import { sendNotification } from "../modules/NotificationModule";
import moment from "moment";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const TableCustom = ({
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  handleTableChange,
  GetPriceMAPFormat,
  GetActionFormat
}) => {
  const handlePageChange = (page) => {
    handleTableChange(null, {page, sizePerPage: perPage});
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return handleTableChange(null, {page, sizePerPage: newPerPage});
  };
  const columns = useMemo(() => {
    return [
      {
        name: "No. Material",
        selector: (row) => row.material_code,
        sortable: true,
        center: true,
        width: "20%",
      },
      {
        name: "Material Description",
        selector: (row) => row.material_name,
        sortable: true,
        center: true,
        width: "25%",
      },
      {
        name: "Classification",
        selector: (row) => row.classification,
        sortable: true,
        width: "15%",
      },
      {
        name: "Last Change",
        selector: (row) => moment(row.created_at).format('DD-MMM-YYYY'),
        sortable: true,
        width: "10%",
      },
      {
        name: "Price",
        selector: (row) => GetPriceMAPFormat(null, row),
        sortable: true,
        width: "20%",
      },
      {
        name: "Action",
        selector: (row) => GetActionFormat(null, row),
        sortable: true,
        width: "10%",
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  GetPriceMAPFormat: PropTypes.func,
  GetActionFormat: PropTypes.func,
  handleTableChange: PropTypes.func,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  loading: false,
  GetPriceMAPFormat: () => {},
  GetActionFormat: () => {},
  handleTableChange: () => {},
};

class MaterialAveragePrice extends Component {
  responseresponseresponseresponseresponseresponse;
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false,
      disableButtonNotification: false,
      showModal: false,
      seconds: 60,
      requester: null,
      isExists: false,
      isSearched: false,
      requester_name: null,
      select_items_material: [],
      material_code: null,
      material_description: null,
      classification: null,
      isClearable: false,
      date: new Date(),
      created_at: null,
      uniq_code: null,
      list_datatable_receipt: [
        {
          parameter: "Price/kg",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_usage: [
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_end_balance: [
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      header_xlsx: [["Parameter"]],
      columns_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "beginning_balance",
          text: "Beginning Balance",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      columns_non_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      select_items_parameter: [],
      errors: {},
      isValidMaterial: true,
      isValidClassification: true,
      loading: false,
      isCreate: true,
      isRead: true,
      isDownload: false,

      list_datatable: [],
      columns: [
        {
          dataField: "material_code",
          text: "No. Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material Description",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "classification",
          text: "Classification",
          classes: "text-center",
        },
        {
          dataField: "created_at",
          text: "Last Change",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "price_map",
          text: "Price",
          formatter: this.GetPriceMAPFormat,
          classes: "text-center",
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "material_code",
          order: "asc",
        },
      ],
      cellEditProps: {},
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "material_code",
      sortOrder: "asc",
      filter_by: null,
      material_search: null,
      showSelectClassification: true,
      showInputMaterial: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    const queryString = `rnd`;
    readParameterByType(queryString).then((response) => {
      const result = response.map((a) => {
        a.label = a.label.replace("Markup MAP R&D: ", "").toUpperCase();
        return a;
      });
      this.setState({ select_items_parameter: result });
    });

    this.setState({
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
  }

  toDetail = (row) => {
    let receipt_formatted = [];
    let usage_formatted = [];
    let end_balance_formatted = [];
    if (typeof row.receipt == "string") {
      receipt_formatted = [
        {
          parameter: "Price/kg",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ];

      usage_formatted = [
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ];

      end_balance_formatted = [
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ];
    } else {
      let receipt = row.receipt;
      receipt_formatted = [
        {
          parameter: "Price/kg",
          beginning_balance: receipt.price_kg[0],
          month_1: receipt.price_kg[1],
          month_2: receipt.price_kg[2],
          month_3: receipt.price_kg[3],
          month_4: receipt.price_kg[4],
          month_5: receipt.price_kg[5],
          month_6: receipt.price_kg[6],
        },
      ];

      let usage = row.usage;
      usage_formatted = [
        {
          parameter: "Price/kg",
          month_1: usage.price_kg[0],
          month_2: usage.price_kg[1],
          month_3: usage.price_kg[2],
          month_4: usage.price_kg[3],
          month_5: usage.price_kg[4],
          month_6: usage.price_kg[5],
        },
      ];

      let end_balance = row.end_balance;
      end_balance_formatted = [
        {
          parameter: "Price/kg",
          month_1: end_balance.price_kg[0],
          month_2: end_balance.price_kg[1],
          month_3: end_balance.price_kg[2],
          month_4: end_balance.price_kg[3],
          month_5: end_balance.price_kg[4],
          month_6: end_balance.price_kg[5],
        },
      ];
    }

    let isDownload = typeof row.receipt == "string" ? false : true;

    this.setState(
      {
        id: "id",
        list_datatable_receipt: receipt_formatted,
        list_datatable_usage: usage_formatted,
        list_datatable_end_balance: end_balance_formatted,
        uniq_code: row.uniq_code,
        created_at: row.created_at,
        material_code: row.material_code,
        material_description: row.material_name,
        isDownload: isDownload,
      },
      () => {
        this.toggle();
      }
    );
  };

  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  onModalHide = () => {
    this.setState({
      id: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };

  validateSearch = (date, material_code) => {
    let errors = this.state.errors;
    if (!date) {
      errors.date = "This is field required";
    }
    return errors;
  };

  GetDateFormat = (cell, row) => {
    return <div>{dateFormat(new Date(row.created_at), "dd-mmm-yyyy")}</div>;
  };

  //price MAP format
  GetPriceMAPFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    if (!row.receipt) {
      return <div>Not found</div>;
    }
    if (typeof row.receipt == "string") {
      return <div>{row.receipt}</div>;
    } else {
      return (
        <div className="py-2">
          {this.GetThousandDelimiterFormatNumber(
            row.receipt.price_kg[1],
            "Receipt"
          )}
          {this.GetThousandDelimiterFormatNumber(
            row.usage.price_kg[0],
            "Usage"
          )}
          {this.GetThousandDelimiterFormatNumber(
            row.end_balance.price_kg[0],
            "End Balance"
          )}
        </div>
      );
    }
  };
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    const { isEdit } = this.state;
    return (
      <div>
        <button
          key={row.id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(row);
          }}
        >
          <i className="fas fa-edit" key={row.id}></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };

  GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? "$" + thousandformat(cell.toFixed(DECIMAL_PLACES), ".", ".", ",")
          : ""}
      </div>
    );
  };
  GetThousandDelimiterFormatNumber = (number, prefix) => {
    return (
      <div>
        {number || String(number) === "0" || !isNaN(parseFloat(number))
          ? prefix +
            ": $" +
            thousandformat(number.toFixed(DECIMAL_PLACES), ".", ".", ",")
          : ""}
      </div>
    );
  };

  handleExportMAP = (data) => {
    const planningMonth = moment(this.state.date).format('MM');
    const planningYear = moment(this.state.date).format('YY');
    const jsonData = [];
    data.forEach((item) => {
        const monthColumns = [];
        item.usage.price_kg.map((el, i) => monthColumns.push({
          [`${moment((Number(planningMonth) + Number(i) > 12 ? Number(planningMonth) + Number(i) - 12 : Number(planningMonth) + Number(i)).toString())
            .format('MMM')}-${Number(planningMonth) + Number(i) > 12 ? Number(planningYear) + 1 : planningYear}`]: `$${el.toFixed(4)}`
        }));

        jsonData.push({
        'No Material': item.material_code,
        'Material Desc': item.material_name,
        'Classification': item.classification,
        ...Object.assign(...monthColumns)
        });
    });
    const ws = utils.json_to_sheet(jsonData);
    const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, `MAP RND.xlsx`);
  }

  exportFile = () => {
    /* convert state to workbook */
    const {
      list_datatable_usage,
      list_datatable_receipt,
      list_datatable_end_balance,
      material_code,
      date,
      created_at,
      header_xlsx,
      uniq_code,
    } = this.state;

    let data_receipt = [...header_xlsx];
    let data_usage = [...header_xlsx];
    let data_end_balance = [...header_xlsx];

    let space_array = [["", "", "", "", "", "", "", "", "", "", "", "", ""]];

    let date_formatted = dateFormat(date, "mmmyy");
    let date_second = addMonths(date, 6);
    let diffMonths = differenceInMonths(date_second, date);

    /* RECEIPT */

    //creating headers
    data_receipt[0] = [...data_receipt[0], "Beginning Balance"];
    for (let i = 0; i < diffMonths; i++) {
      data_receipt[0] = [
        ...data_receipt[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_receipt.map((item, idx) => {
      let rowItem = Object.values(item);
      let temp = rowItem.map((item, idx) => {
        if (idx === 0) {
          return item;
        } else return parseFloat(item).toFixed(DECIMAL_PLACES);
      });
      rowItem = temp;
      data_receipt = [...data_receipt, rowItem];
    });

    /* USAGE */

    //creating headers
    for (let i = 0; i < diffMonths; i++) {
      data_usage[0] = [
        ...data_usage[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_usage.map((item, idx) => {
      let rowItem = Object.values(item);
      let temp = rowItem.map((item, idx) => {
        if (idx === 0) {
          return item;
        } else return parseFloat(item).toFixed(DECIMAL_PLACES);
      });
      rowItem = temp;
      data_usage = [...data_usage, rowItem];
    });

    /* END BALANCE */

    //creating header
    for (let i = 0; i < diffMonths; i++) {
      data_end_balance[0] = [
        ...data_end_balance[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_end_balance.map((item, idx) => {
      let rowItem = Object.values(item);
      let temp = rowItem.map((item, idx) => {
        if (idx === 0) {
          return item;
        } else return parseFloat(item).toFixed(DECIMAL_PLACES);
      });
      rowItem = temp;
      data_end_balance = [...data_end_balance, rowItem];
    });

    let merge_data = data_receipt
      .concat(space_array)
      .concat(data_usage.concat(space_array))
      .concat(data_end_balance);

    let ws_data = XLSX.utils.aoa_to_sheet(merge_data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws_data);
    let year = dateFormat(created_at, "yyyy");
    let month = dateFormat(created_at, "mm");
    let tanggal = dateFormat(created_at, "dd");
    let date_for_file_name = year + "." + month + "." + tanggal;
    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      "maprnd_version_" + date_for_file_name + "_" + material_code + ".xlsx"
    );
  };

  columns_default_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
    {
      dataField: "beginning_balance",
      text: "Beginning Balance",
      formatter: this.GetThousandDelimiterFormat,
      classes: "text-center",
    },
  ];
  columns_default_non_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
  ];

  handleDate = (name, date) => {
    this.setState({
      date: date,
    });
  };

  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "map_rnd",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  handleSearch = () => {
    this.setState(
      {
        page: 1,
        sizePerPage: 10,
        totalSize: 10,
        sortBy: "material_code",
        sortOrder: "asc",
      },
      () => {
        let {
          date,
          material_code,
          classification,
          page,
          sizePerPage,
          sortBy,
          sortOrder,
          material_search,
        } = this.state;

        if (!this.validateSearch(date, material_code, classification)) {
          return this.setState({
            isSearched: false,
          });
        }

        if (date) {
          let planning_month = dateFormat(date, "yyyy-mm-01");
          let query_string = `planning_month=${planning_month}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
          if (classification) {
            query_string += `&classification=${classification}`;
          }
          if (material_search) {
            query_string += `&material_search=${material_search}`;
          }

          readMAPRNDByMonth(query_string).then((response) => {
            this.setState({
              list_datatable: response.foundData ? response.foundData : [],
              totalSize: response.countData ? response.countData : 0,
              page: response.currentPage ? response.currentPage : 1,
              columns_receipt: [
                {
                  dataField: "parameter",
                  text: "Parameter",
                  classes: "text-center",
                },
                {
                  dataField: "beginning_balance",
                  text: "Beginning Balance",
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_1",
                  text: dateFormat(new Date(date), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_2",
                  text: dateFormat(addMonths(new Date(date), 1), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_3",
                  text: dateFormat(addMonths(new Date(date), 2), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_4",
                  text: dateFormat(addMonths(new Date(date), 3), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_5",
                  text: dateFormat(addMonths(new Date(date), 4), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_6",
                  text: dateFormat(addMonths(new Date(date), 5), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ],
              columns_non_receipt: [
                {
                  dataField: "parameter",
                  text: "Parameter",
                  classes: "text-center",
                },
                {
                  dataField: "month_1",
                  text: dateFormat(new Date(date), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_2",
                  text: dateFormat(addMonths(new Date(date), 1), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_3",
                  text: dateFormat(addMonths(new Date(date), 2), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_4",
                  text: dateFormat(addMonths(new Date(date), 3), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_5",
                  text: dateFormat(addMonths(new Date(date), 4), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
                {
                  dataField: "month_6",
                  text: dateFormat(addMonths(new Date(date), 5), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ],
            });
          });
        }
      }
    );
  };

  handleReset = () => {
    this.setState({
      date: new Date(),
      material_code: null,
      classification: null,
      page: 1,
      sizePerPage: 10,
      material_search: null,
    });
    this.handleSearch();
  }

  handleChange = (name, newValue) => {
    if (newValue) {
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name === "classification") {
        this.setState({
          isValidClassification: true,
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        });
      }
      if (name === "filter_by") {
        this.setState({
          classification: null,
          material_search: null,
        });
        if (newValue.value == "material") {
          this.setState({
            showInputMaterial: true,
            showSelectClassification: false,
          });
        } else if (newValue.value == "classification") {
          this.setState({
            showInputMaterial: false,
            showSelectClassification: true,
          });
        }
      }
    } else {
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
          [name]: null,
          [name + "_code"]: null,
          select_items_material: [],
        });
      }
      if (name === "classification") {
        this.setState({
          isValidClassification: false,
          [name]: null,
          [name + "_code"]: null,
        });
      }
      if (name === "filter_by") {
        this.setState({
          showInputMaterial: false,
          showSelectClassification: false,
          classification: null,
          material_search: null,
        });
      }
    }
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  getMaterial = (classification) => {
    this.refMaterial.state.value = "";
    this.refMaterial.state.label = "";
    const queryString = `constraint=mapping&constraint_value=${classification}`;
    readMaterialClassification(queryString).then((response) => {
      this.setState({ select_items_material: response });
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, material_search }
  ) => {
    let { date, classification } = this.state;
    let encoded_filter = encodeURI(JSON.stringify(filters));
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
      encoded_filter: encoded_filter,
      loadingData: true
    });

    let planning_month = dateFormat(date, "yyyy-mm-01");
    let query_string = `planning_month=${planning_month}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (classification) {
      query_string += `&classification=${classification}`;
    }
    if (material_search) {
      query_string += `&material_search${material_search}`;
    }
    readMAPRNDByMonth(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    }).finally(() => {
      this.setState({
        loadingData: false
      })
    })
  };

  render() {
    let {
      disableButtonNotification,
      showModal,
      seconds,
      select_items_material,
      material_code,
      material_description,
      isValidMaterial,
      isClearable,
      date,
      list_datatable_receipt,
      list_datatable_usage,
      list_datatable_end_balance,
      columns_receipt,
      columns_non_receipt,
      errors,
      isRead,
      isDownload,
      errorMessage,
      isExists,
      isSearched,
      uniq_code,
      isValidClassification,
      classification,
      select_items_parameter,
      material_code_object,

      list_datatable,
      columns,
      defaultSorted,
      cellEditProps,
      page,
      sizePerPage,
      totalSize,
      material_search,
      showSelectClassification,
      showInputMaterial,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesClassification = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidClassification
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidClassification
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />

          {/* MODAL DETAIL */}
          <Modal
            show={showModal}
            dialogClassName="modal-90w"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                ({material_code}) {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <Row>
                  <Col>
                    <h5>Version {uniq_code}</h5>
                    <h5>Receipt</h5>
                  </Col>
                  <Col>
                    {isDownload && (
                      <div style={{ marginBottom: 20 }}>
                        <Button
                          variant="success"
                          onClick={this.exportFile}
                          className="float-right"
                        >
                          <i className="fas fa-download" /> Export to Excel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <RemoteNoPagination
                  data={list_datatable_receipt}
                  columns={columns_receipt}
                />
                <h5>Usage</h5>
                <RemoteNoPagination
                  data={list_datatable_usage}
                  columns={columns_non_receipt}
                />
                <h5>End Balance</h5>
                <RemoteNoPagination
                  data={list_datatable_end_balance}
                  columns={columns_non_receipt}
                />
              </div>
              {/* )} */}
            </ModalBody>
          </Modal>

          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isRead && isSearched && isExists && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">MAP RND Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          {isDownload && (
                            <div style={{ marginBottom: 20 }}>
                              <Button
                                variant="success"
                                onClick={this.exportFile}
                                className="float-right"
                              >
                                <i className="fas fa-download" /> Export to
                                Excel
                              </Button>
                            </div>
                          )}
                          <h5>Version {uniq_code}</h5>
                          <h5>Receipt</h5>
                          <RemoteNoPagination
                            data={list_datatable_receipt}
                            columns={columns_receipt}
                          />
                          <h5>Usage</h5>
                          <RemoteNoPagination
                            data={list_datatable_usage}
                            columns={columns_non_receipt}
                          />
                          <h5>End Balance</h5>
                          <RemoteNoPagination
                            data={list_datatable_end_balance}
                            columns={columns_non_receipt}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}

                {isSearched && !isExists && (
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={12}>
                                <h3
                                  style={{ color: "red" }}
                                  className="text-center"
                                >
                                  {" "}
                                  {errorMessage}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">MAP Data </h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <form
                          autoComplete="off"
                          noValidate
                          ref={(form) => (this.form = form)}
                        >
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="form-group">
                                <label htmlFor="date">Planning Month *</label>
                                <DatePicker
                                  selected={date}
                                  startDate={date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "date")}
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="filter_by">Filter By </label>
                                <Row>
                                  <Col xs={12} md={4}>
                                    <div className="form-group">
                                      <Select
                                        options={[
                                          {
                                            value: "classification",
                                            label: "Classification",
                                          },
                                          {
                                            value: "material",
                                            label: "Material Code/Desc",
                                          },
                                        ]}
                                        defaultValue={{
                                          value: "classification",
                                          label: "Classification",
                                        }}
                                        onChange={this.handleChange.bind(
                                          this,
                                          "filter_by"
                                        )}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={8}>
                                    <div className="form-group">
                                      <Select
                                        onChange={this.handleChange.bind(
                                          this,
                                          "classification"
                                        )}
                                        options={select_items_parameter}
                                        value={select_items_parameter.filter(
                                          (e) => e.label === classification
                                        )}
                                        className={
                                          showSelectClassification
                                            ? ""
                                            : "d-none"
                                        }
                                        isClearable={true}
                                      />
                                      <input
                                        className={
                                          showInputMaterial
                                            ? "form-control "
                                            : "form-control d-none"
                                        }
                                        name="material_search"
                                        value={material_search || ""}
                                        placeholder="Search ..."
                                        onKeyPress={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        onChange={this.onChangeValue}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col className="form-group d-flex align-items-end">
                              <Col xs={12} md={6}></Col>
                              <Col xs={12} md={6} className="d-flex gap-2 flex-wrap justify-content-end">
                                <Button
                                  variant="success"
                                  onClick={() => this.handleExportMAP(list_datatable)}
                                  className={list_datatable.length < 1 && 'd-none'}
                                >
                                  <i className="fas fa-download" /> Export
                                </Button>
                                <Button
                                  variant="warning"
                                  onClick={this.handleNotification}
                                  disabled={disableButtonNotification}
                                >
                                  <i className="fab fa-telegram-plane" /> Send
                                  Notification{" "}
                                  {disableButtonNotification
                                    ? `(in ${seconds}s)`
                                    : ""}
                                </Button>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  onClick={this.handleReset}
                                >
                                  <i className="fas fa-undo" /> Reset
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </form>
                        <br />
                        <TableCustom
                          data={list_datatable}
                          curPage={page}
                          totalRows={totalSize}
                          perPage={sizePerPage}
                          handleTableChange={this.handleTableChange.bind(this)}
                          GetPriceMAPFormat={this.GetPriceMAPFormat.bind(this)}
                          GetActionFormat={this.GetActionFormat.bind(this)}
                          loading={this.state.loadingData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(MaterialAveragePrice);
