import axios from "axios";
import authHeader from "./../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApsRawMaterialService {
  read(query) {
    return axios.get(API_URL_DEFAULT + "raw-material/?" + query, {
      headers: authHeader(),
    });
  }

  downloadExcel(query) {
    return axios.get(API_URL_DEFAULT + "raw-material/downloadExcel", {
      params: query,
      headers: authHeader(),
      responseType: "blob",
    });
  }
}

export default new ApsRawMaterialService();
