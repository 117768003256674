import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class SalesForecastService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "sales_forecast", payload, {
      headers: authHeader(),
    });
  }
  createOverwrite(payload) {
    return axios.post(
      API_URL_DEFAULT + "sales_forecast/?type=overwrite",
      payload,
      { headers: authHeader() }
    );
  }
  readQsDailySpotPrice(qs) {
    return axios.get(API_URL_DEFAULT + "sales_forecast/?" + qs, {
      headers: authHeader(),
    });
  }
  readSalesForecas(query_string) {
    return axios.get(API_URL_DEFAULT + "sales_forecast?" + query_string, {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "sales_forecast", payload, {
      headers: authHeader(),
    });
  }
  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "sales_forecast", {
      data: payload,
      headers: authHeader(),
    });
  }
  readSummarySalesForecast(query_string) {
    return axios.get(
      API_URL_DEFAULT + "sales_forecast/summary_sales_forecast?" + query_string,
      { headers: authHeader() }
    );
  }

  generateExcel(query) {
    return axios.get(API_URL_DEFAULT + "sales_forecast/excel", {
      params: query,
      headers: authHeader(),
      responseType: "blob",
    });
  }

  downloadExcel(query) {
    return axios.get(API_URL_DEFAULT + "sales_forecast/downloadExcel", {
      params: query,
      headers: authHeader(),
      responseType: "blob",
    });
  }

  createBulk(payload) {
    return axios.post(API_URL_DEFAULT + "sales_forecast/bulk", payload, {
      headers: authHeader(),
    });
  }

  generateExcelSalesForecastConfirmation(query) {
    return axios.get(
      API_URL_DEFAULT + "sales_forecast/generateExcelConfirmation",
      {
        params: query,
        headers: authHeader(),
        responseType: "blob",
      }
    );
  }

  createBulkConfirmation(payload) {
    return axios.post(
      API_URL_DEFAULT + "sales_forecast/bulkConfirmation",
      payload,
      {
        headers: authHeader(),
      }
    );
  }

  confirmBulk(payload) {
    return axios.post(API_URL_DEFAULT + "sales_forecast/confirmBulk", payload, {
      headers: authHeader(),
    });
  }
}

export default new SalesForecastService();
