import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class Parameter {
  readParameter() {
    return axios.get(API_URL_DEFAULT + "parameter/?sizePerPage=1000", {
      headers: authHeader(),
    });
  }
  readParameterByType(queryString) {
    return axios.get(
      API_URL_DEFAULT +
        "parameter/by-type/?sizePerPage=1000&type=" +
        queryString,
      { headers: authHeader() }
    );
  }
}

export default new Parameter();
