import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Check from "react-bootstrap/FormCheck";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactTooltip from "react-tooltip";

import "react-datepicker/dist/react-datepicker.css";
import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RemoteAll from "../components/RemoteAll";
import RemoteTable from "../components/RemoteTable";
import capitalize from "../helpers/capitalize";
import { readCostRates, addCostRates } from "../modules/CostRatesModule";
import { readBOMFG, addBOMFG } from "../modules/BOMFGModule";
import detectFloat from "../helpers/detectFloat";
import DecimalPrecision from "../helpers/decimalprecision";
import {
  addMonths,
  differenceInMonths,
  startOfMonth,
  subMonths,
} from "date-fns";
import { th } from "date-fns/locale";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");
class CostRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      worksheet_name: true,
      showModal: false,
      loading: false,
      readOnly: false,
      isClearable: true,
      isDownloadable: false,
      collection: {},
      errors: {},
      id: null,
      filename: null,
      excel: null,
      worksheet: [],
      worksheet_detail: [],
      requester: null,
      cols_length: null,
      material_description: null,
      no_material: null,
      date_first: null,
      date_second: null,
      date_first_formatted: null,
      date_second_formatted: null,
      date: null,
      columns: null,
      list_datatable: [],
      page: 1,
      sizePerPage: 50,
      totalSize: 100,
      sortBy: "account",
      sortOrder: "asc",
      defaultSorted: [
        {
          dataField: "account",
          order: "asc",
        },
      ],
      cellEditProps: {},
      link: `${process.env.PUBLIC_URL}/bom_fg.xlsx`,
      template_cost_rates: [
        ["account", "facility", "unit", "month", "value"],
        ["P00001/SAP003", "5611-80179", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80179", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80179", "HR", "0", "0"],
        ["P00006/ZAP004", "5611-80179", "M3", "0", "0"],
        ["P00007/ZAP005", "5611-80179", "KWH", "0", "0"],
        ["P00001/SAP003", "5611-80180", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80180", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80180", "HR", "0", "0"],
        ["P00007/ZAP005", "5611-80180", "KWH", "0", "0"],
        ["P00001/SAP003", "5611-80181", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80181", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80181", "HR", "0", "0"],
        ["P00007/ZAP005", "5611-80181", "KWH", "0", "0"],
        ["P00001/SAP003", "5611-80183", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80183", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80183", "HR", "0", "0"],
        ["P00006/ZAP004", "5611-80183", "M3", "0", "0"],
        ["P00007/ZAP005", "5611-80183", "KWH", "0", "0"],
        ["P00001/SAP003", "5611-80182", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80182", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80182", "HR", "0", "0"],
        ["P00007/ZAP005", "5611-80182", "KWH", "0", "0"],
        ["P00001/SAP003", "5611-80217", "HR", "0", "0"],
        ["P00002/SAP002", "5611-80217", "HR", "0", "0"],
        ["P00003/SAPFOH", "5611-80217", "HR", "0", "0"],
      ],
    };
  }

  columns_default = [
    {
      dataField: "account",
      text: "Account",
      classes: "text-center",
      sort: true,
    },
    {
      dataField: "facility",
      text: "Facility",
      classes: "text-center",
      sort: true,
    },
    {
      dataField: "unit",
      text: "Unit",
      classes: "text-center",
      sort: true,
    },
  ];

  //render table based on first date and second date
  renderDatatable(date_first, date_second) {
    let columns = [...this.columns_default];
    var diffMonth = differenceInMonths(date_second, date_first);
    for (let i = 0; i <= diffMonth; i++) {
      columns = [
        ...columns,
        {
          dataField: "value" + i,
          text: dateFormat(addMonths(date_first, i), "mmm-yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: false,
        },
      ];
    }

    this.setState({
      date_first: date_first,
      date_second: date_second,
      columns: columns,
    });
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      requester: user.username,
    });

    const date_first = subMonths(startOfMonth(new Date()), 3);
    const date_second = startOfMonth(new Date());
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    this.renderDatatable(date_first, date_second);

    readCostRates(query_string).then((response) => {
      this.setState({
        list_datatable: response.custom ? response.custom : [],
        totalSize: response.custom ? response.countData : 0,
        page: response.custom ? response.currentPage : 1,
      });
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }

  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    let formatted_cell = isNaN(parseFloat(cell))
      ? ""
      : parseFloat(cell).toFixed(DECIMAL_PLACES);
    return <div>{thousandformat(formatted_cell, ".", ".", ",")}</div>;
  };

  //action button
  GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailBOMFG(
              row._id,
              row.no_material,
              row.material_description,
              row.data,
              row.cols
            )
          }
        >
          <i className="fas fa-th" key={row._id}></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  //default
  GetDefaultValue = (cell, row) => {
    return <div>{cell ? cell : ""}</div>;
  };
  GetMonthYear = (cell, row) => {
    return <div>{cell ? row.month + " " + row.year : ""}</div>;
  };
  //trigger modal
  toDetailBOMFG = (id, no_material, material_description, data, cols) => {
    const worksheet_detail = [
      {
        worksheet: no_material + "_" + material_description,
        data: data,
        cols: cols,
      },
    ];
    this.setState(
      {
        id: id,
        worksheet_detail: worksheet_detail,
        submitted: false,
        no_material: no_material,
        material_description: material_description,
      },
      () => {
        this.toggle();
      }
    );
  };

  onChangeHandlerFile = (event) => {
    let errors = this.state.errors;
    errors.filename = null;
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      worksheet_name: true,
      error_message_filename: null,
      errors: errors,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
      let errors = this.state.errors;
      errors.worksheet = null;
      this.setState({
        errors: errors,
      });
    }
    this.setState({
      loading: false,
    });
  };
  checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      // if message not same old that mean has error
      this.setState({ error_message_filename: err });
      event.target.value = null; // discard selected file
      return false;
    }
    return true;
  };
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });

      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  exists_only = (
    data,
    cols,
    start_row = 0,
    allowed_cols = null,
    row_header_case = null,
    row_header_value = null
  ) => {
    let header = [];
    let detail = [];
    let row_header = [];
    let row_detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === start_row) {
          let header_concat = splice.concat(z);
          if (allowed_cols) {
            let z_clone = [...r];
            let splice_custom = z_clone.splice(0, allowed_cols);
            header.push(splice_custom);
          } else {
            header.push(header_concat);
          }
        }
        if (start_row !== 0 && i < start_row) {
          let z_clone = [...r];
          if (row_header_case === i) {
            row_header.push(z_clone);
          }
          if (row_header_value === i) {
            row_detail.push(z_clone);
          }
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== start_row && cleanArray.length > 0) {
          let abc = z.map((o) => {
            return o;
          });

          let array_concat = splice.concat(abc);
          detail.push(array_concat);
        }
      });
    }
    return {
      header: header,
      detail: detail,
      special_case: { header: row_header, detail: row_detail },
    };
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);
    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      worksheet_detail: null,
      no_material: null,
      material_description: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //capitalize first word

  //validate input
  validateForm = (file, worksheet) => {
    let errors = this.state.errors;
    if (!file) {
      errors.file = "This field is required";
    }
    if (worksheet.length == 0) {
      errors.worksheet =
        "Please upload excel according to the template that is already available!";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { sortBy, sortOrder, page, sizePerPage, date_first, date_second } =
      this.state;
    const query_string = `first=${date_first}&second=${date_second}`;

    this.form.reset();
    this.setState({
      worksheet_detail: [],
      worksheet: [],
      submitted: false,
    });
    readCostRates(query_string).then((response) => {
      this.setState({
        list_datatable: response.custom ? response.custom : [],
        totalSize: response.custom ? response.countData : 0,
        page: response.custom ? response.currentPage : 1,
      });
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
      isDownloadable: true,
    });
  };

  exportFile = () => {
    /* convert state to workbook */
    const { date } = this.state;
    const data = this.state.template_cost_rates;
    const date_formatted = dateFormat(date, "yyyy-mm-dd");

    data.map((item, idx) => {
      if (idx !== 0) item[3] = date_formatted;
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "cost_rates.xlsx");
  };

  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { history } = this.props;
    const { requester, department, excel, selectedFile, worksheet, date } =
      this.state;
    if (!this.validateForm(selectedFile, worksheet)) {
      return;
    }
    let collection = {
      requester: requester,
      department: department,
      excel: excel,
    };

    if (requester && department && excel && worksheet.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addCostRates(collection)
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-cost-rates-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                //read data exchange rate on backend
                this.reloadDataBackend();
                history.push("/price-list/cost-rates");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-cost-rates-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleSearch = () => {
    const { date_first, date_second, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    this.renderDatatable(date_first, date_second);
    if (date_first > date_second) {
      this.setState({
        date_second: startOfMonth(new Date()),
        date_first: subMonths(startOfMonth(new Date()), 3),
      });
      this.renderDatatable(
        subMonths(startOfMonth(new Date()), 3),
        startOfMonth(new Date())
      );
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readCostRates(query_string).then((response) => {
      this.setState({
        list_datatable: response.custom ? response.custom : [],
        totalSize: response.custom ? response.countData : 0,
        page: response.custom ? response.currentPage : 1,
      });
    });
    readCostRates(query_string).then((response) => {
      this.setState(
        {
          list_datatable: response.custom ? response.custom : [],
          totalSize: response.custom ? response.countData : 0,
          page: response.custom ? response.currentPage : 1,
        },
        () => {
          if (response.custom.length === 0) {
            query_string = `first=${date_first_formatted}&second=${date_second_formatted}`;
            readCostRates(query_string).then((res) => {
              this.setState({
                list_datatable: res.custom ? res.custom : [],
                totalSize: res.custom ? res.custom : 0,
                page: res.custom ? res.custom : 1,
              });
            });
          }
        }
      );
    });
  };

  //handletable
  handleTableChange = (
    type,
    {
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder,
      cellEdit,
      dateFirst,
      dateSecond,
    }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });

    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      no_material: "no_material",
      material_description: "material_description",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }

    const { date_first, date_second } = this.state;

    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `first=${date_first_formatted}&second=${date_second_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }

    readCostRates(query_string).then((response) => {
      this.setState({
        list_datatable: response.custom ? response.custom : [],
        totalSize: response.custom ? response.countData : 0,
        page: response.custom ? response.currentPage : 1,
      });
    });
  };

  render() {
    let {
      showModal,
      id,
      excel,
      worksheet,
      errors,
      submitted,
      loading,
      isDownloadable,
      error_message_filename,
      isCreate,
      isRead,
      list_datatable,
      page,
      sizePerPage,
      totalSize,
      columns,
      defaultSorted,
      cellEditProps,
      link,
      worksheet_detail,
      material_description,
      no_material,
      date_first,
      date_second,
      date,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>
                Detail {no_material} {material_description}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <Worksheet worksheet={worksheet_detail} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Cost Rates</h3>
                        </div>
                        <div className="card-body">
                          <Row
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="form-group">
                              <label htmlFor="date">Choose Month *</label>
                              <DatePicker
                                selected={date}
                                startDate={date}
                                disabledKeyboardNavigation
                                onChange={this.handleDate.bind(this, "date")}
                                name="date"
                                wrapperClassName="datePickerCustom"
                                dateFormat="MMMM yyyy"
                                className="form-control form-control-sm"
                                required
                                showMonthYearPicker
                              />
                              {errors.date && (
                                <div className="invalid-feedback">
                                  {errors.date}
                                </div>
                              )}
                            </div>
                            <div
                              data-tip="Please choose month before download template"
                              data-tip-disable={this.state.date !== null}
                            >
                              <Button
                                variant="primary"
                                className="fas fa-download"
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  pointerEvents:
                                    this.state.date === null ? "none" : "auto",
                                }}
                                disabled={this.state.date === null}
                                onClick={this.exportFile}
                              >
                                Download Template Cost Rates
                              </Button>
                            </div>

                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="float"
                            />
                          </Row>
                          <form
                            autoComplete="off"
                            className={
                              submitted
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Col>
                              <Row
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="form-group row">
                                  <div className="col-md-9 col-xs-12">
                                    <label htmlFor="file">
                                      Choose File (Excel) *
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      name="file"
                                      placeholder="File"
                                      onChange={this.onChangeHandlerFile}
                                      accept={SheetJSFT}
                                      required
                                    />

                                    {errors.file && (
                                      <div className="invalid-feedback">
                                        {errors.file}
                                      </div>
                                    )}

                                    {error_message_filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {error_message_filename}
                                      </em>
                                    )}

                                    {errors.worksheet && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.worksheet}
                                      </em>
                                    )}
                                    {errors.filename && (
                                      <em
                                        className="error-message"
                                        style={{
                                          fontSize: "80%",
                                        }}
                                      >
                                        {errors.filename}
                                      </em>
                                    )}
                                  </div>
                                </div>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Row>
                            </Col>
                            <Row>
                              <Col xs={12} md={12}>
                                <Worksheet worksheet={worksheet} />
                              </Col>
                            </Row>
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">Start Month</label>
                                  <DatePicker
                                    selected={date_first}
                                    startDate={date_first}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_first"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">End Month</label>
                                  <DatePicker
                                    selected={date_second}
                                    startDate={date_second}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_second"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(CostRates);
