import BOMFGService from "../services/bom_fg.service";

export const addBOMFG = (payload) => {
  return BOMFGService.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readBOMFG = (query_string) => {
  return BOMFGService.readBOMFG(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
export const deleteBOMFG = (payload) => {
  return BOMFGService.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};
export const editBOMFG = (payload) => {
  return BOMFGService.edit(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readBOMFGSpec = (query_string) => {
  return BOMFGService.readBOMFGSpec(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};


export const readBOMFGSpecPlant = (query_string) => {
  return BOMFGService.readBOMFGSpecPlant(query_string).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
