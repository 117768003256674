import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dateFormat from "dateformat";
import Row from "react-bootstrap/Row";
import thousandformat from "../helpers/thousanddelimiter";
const SheetJSFT = ["txt"].map((x) => `.${x}`).join(",");

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const MapPowderRndTable = ({ data, file_create, handleModal, onChange }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };

  const onChangeHandlerFile = (files, index, privateKey) => {
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = function (event) {
        let text = event.target.result.replaceAll("\n", "");
        let tmp = text.split("-----BEGIN ENCRYPTED PRIVATE KEY-----");
        let tmp2 = tmp[1].split("-----END ENCRYPTED PRIVATE KEY-----");
        let final_result = `-----BEGIN ENCRYPTED PRIVATE KEY-----\n${tmp2[0]}\n${tmp2[0]}\n-----END ENCRYPTED PRIVATE KEY-----\n`;
        data[index]["privateKey"] = final_result;
        onChange(data);
      };
      reader.readAsText(files[0]);
    }
  };

  return data.length <= 0 ? (
    "Data Not Found"
  ) : (
    <div key="head">
      <Table
        bordered
        hover
        size="sm"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <thead style={headerStyle}>
          <tr>
            <th>No</th>
            <th>Material Code</th>
            <th>Material Name</th>
            <th>Upload Key</th>
            <th>Message</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr>
              <td>{idx + 1}</td>
              <td>{item.material_code}</td>
              <td>
                {item.material_desc.replace(`(${item.material_code})`, "")}
              </td>
              <td>
                <input
                  type="file"
                  className="form-control form-control-sm"
                  name="file"
                  placeholder="File"
                  onChange={(e) =>
                    onChangeHandlerFile(e.target.files, idx, "privateKey")
                  }
                  accept={SheetJSFT}
                  required
                />
              </td>
              <td>{item.message}</td>
              <td>
                <Button
                  className="font-weight-bold btn btn-sm"
                  variant="warning"
                  onClick={() => handleModal(data[idx])}
                >
                  <i class="fa fa-tasks mr-2" aria-hidden="true"></i>
                  Proccess
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MapPowderRndTable;
