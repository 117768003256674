import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import "react-datepicker/dist/react-datepicker.css";
import { startOfMonth, subMonths, addMonths } from "date-fns";
import {
  readSpotPriceOutlook,
  addSpotPriceOutlook,
  editSpotPriceOutlook,
  readQsSpotPriceOutlook,
  overwriteSpotPriceOutlook,
} from "../modules/SpotPriceOutlookModule";
import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import {
  readMaterial,
  readMaterialByType,
  readMaterialUomByCode,
} from "../modules/MaterialModule";
import { readVendor } from "../modules/VendorModule";
import { readUom } from "../modules/UomModule";

import thousandformat from "../helpers/thousanddelimiter";

import { withRouter } from "react-router-dom";
import Select from "react-select";
import RemoteAll from "../components/RemoteAll";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class SpotPriceOutlook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      submitted_create: false,
      showModal: false,
      showSpotPriceOutlook: false,
      loading: false,
      readOnly: false,
      currencyreadOnly: false,
      isValidMaterial: true,
      isValidMaterialCreate: true,
      isValidVendor: true,
      isValidVendorCreate: true,
      isClearable: true,
      isAdmin: false,
      collection: {},
      select_items: [],
      select_items_material: [],
      select_items_vendor: [],
      select_items_uom: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      id: null,
      requester: null,
      material: null,
      material_code: null,
      date: null,
      price: null,
      unit: null,
      vendor: null,
      vendor_code: null,
      currency: null,
      currency_create: null,
      material_create: null,
      date_create: null,
      price_create: null,
      unit_create: null,
      vendor_create: null,
      date_first: null,
      date_second: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      sortBy: "outlook_date",
      sortOrder: "desc",
      columns: [
        {
          dataField: "outlook_date",
          text: "Outlook Month",
          formatter: this.GetDateFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_name",
          text: "Material",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "vendor_name",
          text: "Vendor",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "outlook_price",
          text: "Outlook Price",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "unit",
          text: "Unit",
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "outlook_date",
          order: "desc",
        },
      ],
      cellEditProps: {},
    };
  }

  //when component already mount (lifecycle react)
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    //read data currency without rp on backend
    readCurrencyWithoutRP().then((response) => {
      this.setState({ select_items: response });
    });
    readMaterialByType("ZRAW").then((response) => {
      this.setState({ select_items_material: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    readVendor().then((response) => {
      this.setState({ select_items_vendor: response });
    });
    const { sortBy, sortOrder, page, sizePerPage } = this.state;
    const date_first = startOfMonth(new Date());
    const date_second = addMonths(new Date(), 6);
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `start_date=${date_first_formatted}&end_date=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    //read data exchange rate on backend

    readQsSpotPriceOutlook(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });

    this.setState({
      date_create: startOfMonth(new Date()),
      date_first: startOfMonth(new Date()),
      date_second: addMonths(new Date(), 6),
      requester: user.username,
    });
    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
        isAdmin: check_access.isAdmin,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };
  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.outlook_date), "mmmm yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    let is_noneditable = true;
    let date_db = new Date(row.outlook_date);
    let currencyreadOnly = true;
    const { isEdit } = this.state;
    return isEdit ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => {
            this.toDetail(
              row._id,
              row.currency,
              row.material_name,
              row.material_code,
              row.vendor_name,
              row.vendor_code,
              row.outlook_price,
              row.unit,
              date_db,
              is_noneditable,
              currencyreadOnly
            );
          }}
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell, row) => {
    let formatted_cell = isNaN(cell)
      ? ""
      : parseFloat(cell).toFixed(DECIMAL_PLACES);
    return <div>{thousandformat(formatted_cell, ".", ".", ",")}</div>;
  };
  //handle change
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: true,
        });
      } else if (name == "material_create") {
        readMaterialUomByCode(newValue.value).then((response) => {
          this.refUnit.value = response[0].value;
          this.setState({
            unit_create: response[0].value,
            isValidMaterialCreate: true,
          });
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "vendor") {
        this.setState({
          isValidVendor: false,
        });
      } else if (name == "material_create") {
        this.refUnit.value = null;
        this.setState({
          isValidMaterialCreate: false,
          unit_create: null,
        });
      } else if (name == "vendor_create") {
        this.setState({
          isValidVendorCreate: false,
        });
      }
    }
  };
  //trigger modal237:24
  toDetail = (
    id,
    currency,
    material,
    material_code,
    vendor,
    vendor_code,
    price,
    unit,
    date,
    currencyreadOnly
  ) => {
    console.log(
      id,
      currency,
      material,
      material_code,
      vendor,
      vendor_code,
      price,
      unit,
      date,
      currencyreadOnly
    );
    this.setState(
      {
        id: id,
        currency: currency,
        material: material,
        material_code: material_code,
        vendor: vendor,
        vendor_code,
        vendor_code,
        date: date,
        price: price,
        unit: unit,
        currencyreadOnly: currencyreadOnly,
        submitted: false,
        isValidMaterial: true,
        isValidVendor: true,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      material: null,
      currency: null,
      vendor: null,
      price: null,
      unit: null,
      date: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  //validate input
  validateForm = (currency, material, vendor, date, price, unit) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendor: false,
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    return errors;
  };
  validateFormCreate = (currency, material, vendor, date, price, unit) => {
    let errors = this.state.errors_create;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    }
    if (!vendor) {
      errors.vendor = "This is field required";
      this.setState({
        isValidVendorCreate: false,
      });
    }
    if (!date) {
      errors.date = "This is field required";
    }
    if (!price) {
      errors.price = "This is field required";
    } else if (price < 0) {
      errors.price = "Value cannot below 0";
    }
    if (!unit) {
      errors.unit = "This is field required";
    }
    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const { date_first, date_second, page, sizePerPage, sortBy, sortOrder } =
      this.state;
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    const query_string = `start_date=${date_first_formatted}&end_date=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;

    this.form.reset();
    this.refMaterial.state.value.value = "";
    this.refMaterial.state.value.label = "";
    this.refVendor.state.value.value = "";
    this.refVendor.state.value.label = "";
    readQsSpotPriceOutlook(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
        submitted_create: false,
        currency_create: null,
        vendor_create: null,
        vendor_create_code: null,
        material_create: null,
        material_create_code: null,
        unit_create: null,
        price_create: null,
        date_create: new Date(),
      });
    });
    readMaterialByType("ZRAW").then((response) => {
      this.setState({ select_items_material: response });
    });
    readVendor().then((response) => {
      this.setState({ select_items_vendor: response });
    });
  };
  //handletable
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (page == 0) {
      page = 1;
    }
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });
    const { date_first, date_second } = this.state;
    const operator = {
      "=": "eq",
      ">": "gt",
      ">=": "gte",
      "<": "lt",
      "<=": "lte",
      "!=": "ne",
    };
    const columnField = {
      material: "material_name",
      vendor: "vendor_name",
      spot_price: "spot_price",
      unit: "unit",
    };
    let filterColumn = false;
    let array_filter = [];
    for (const dataField in filters) {
      const { filterVal, comparator, filterType } = filters[dataField];
      filterColumn = true;
      if (filterType === "SELECT") {
        if (filterVal) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal}&${columnField[dataField]}_operator=${operator[comparator]}`
          );
        }
      }
      if (filterType === "NUMBER") {
        if (filterVal.number) {
          array_filter.push(
            `${columnField[dataField]}=${filterVal.number}&${
              columnField[dataField]
            }_operator=${operator[filterVal.comparator]}`
          );
        }
      }
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `start_date=${date_first_formatted}&end_date=${date_second_formatted}&sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    if (filterColumn) {
      query_string += "&" + array_filter.join("&");
    }
    readQsSpotPriceOutlook(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  //submit form
  handleSubmit = (e) => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      currency_create,
      material_create,
      material_create_code,
      vendor_create,
      vendor_create_code,
      price_create,
      unit_create,
      date_create,
    } = this.state;
    if (
      !this.validateFormCreate(
        currency_create,
        material_create,
        vendor_create,
        date_create,
        price_create,
        unit_create
      )
    ) {
      return;
    }

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(date_create, "mmmm yyyy");
    let collection = {
      requester: requester,
      material_name: material_create,
      material_code: material_create_code,
      vendor_name: vendor_create,
      vendor_code: vendor_create_code,
      outlook_price: price_create,
      unit: unit_create,
      currency: currency_create,
      outlook_date: date_create,
      department: department,
    };
    console.log(collection);
    if (
      requester &&
      currency_create &&
      price_create &&
      material_create &&
      vendor_create &&
      unit_create &&
      price_create >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addSpotPriceOutlook(collection)
            .then((response) => {
              console.log(response);
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/price/future-price");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-exchange-rate-failed",
              });
              if (
                err ===
                "data is already exist, Are you sure to overwrite data ?"
              ) {
                Swal.fire({
                  title: "Are you sure ?",
                  text: `to overwrite data for ${date_formatted} ${currency_create} !`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteSpotPriceOutlook(collection)
                      .then((response) => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then((result) => {
                          this.reloadDataBackend();
                          history.push("/price/future-price");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-exchange-rate-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const { id, currency, material, vendor, unit, price, date, requester } =
      this.state;

    if (!this.validateForm(currency, material, vendor, date, price, unit)) {
      return;
    }
    const { history } = this.props;

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let collection = {
      _id: id,
      outlook_price: price,
      requester: requester,
      department: department,
    };
    if (
      id &&
      currency &&
      material &&
      vendor &&
      unit &&
      price &&
      date &&
      price >= 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editSpotPriceOutlook(collection)
            .then((response) => {
              this.toggle();
              this.setState({
                submitted: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
                history.push("/price/future-price");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-exchange-rate-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { date_first, date_second, sortBy, sortOrder, page, sizePerPage } =
      this.state;
    if (date_first > date_second) {
      this.setState({
        date_second: addMonths(new Date(), 6),
        date_first: new Date(),
      });
      return Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please select date properly!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const date_first_formatted = dateFormat(date_first, "yyyy-mm-dd");
    const date_second_formatted = dateFormat(date_second, "yyyy-mm-dd");
    let query_string = `start_date=${date_first_formatted}&end_date=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}`;
    readQsSpotPriceOutlook(query_string).then((response) => {
      this.setState(
        {
          list_datatable: response.foundData ? response.foundData : [],
          totalSize: response.foundData ? response.countData : 0,
          page: response.foundData ? response.currentPage : 1,
        },
        () => {
          if (response.foundData.length === 0) {
            query_string = `start_date=${date_first_formatted}&end_date=${date_second_formatted}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=1&sizePerPage=${sizePerPage}`;
            readQsSpotPriceOutlook(query_string).then((res) => {
              this.setState({
                list_datatable: res.foundData ? res.foundData : [],
                totalSize: res.foundData ? res.countData : 0,
                page: res.foundData ? res.currentPage : 1,
              });
            });
          }
        }
      );
    });
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items,
      select_items_material,
      select_items_vendor,
      select_items_uom,
      list_datatable,
      id,
      currency,
      material,
      vendor,
      unit,
      price,
      date,
      currency_create,
      material_create,
      vendor_create,
      unit_create,
      price_create,
      date_create,
      date_first,
      date_second,
      errors,
      errors_create,
      submitted_create,
      submitted,
      currencyreadOnly,
      loading,
      isValidMaterial,
      isValidMaterialCreate,
      isValidVendor,
      isValidVendorCreate,
      isClearable,
      isCreate,
      isRead,
      page,
      sizePerPage,
      totalSize,
      isAdmin,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesVendor = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValidVendor ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendor
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStylesVendorCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidVendorCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidVendorCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>{id ? "Update" : "Create"} Future Price</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.material != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="material">Material *</label>
                        <Select
                          isDisabled
                          styles={customStylesMaterial}
                          onChange={this.handleChange.bind(this, "material")}
                          options={select_items_material}
                          defaultValue={select_items_material.filter(
                            (e) => e.label === material
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidMaterial && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.material}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="date">Outlook Month *</label>
                        <DatePicker
                          disabled
                          selected={date}
                          startDate={date}
                          maxDate={
                            !isAdmin
                              ? date && !isAdmin
                                ? addMonths(new Date(date), 0) !==
                                  addMonths(new Date(), 0)
                                  ? addMonths(new Date(), 1)
                                  : addMonths(new Date(date), 0)
                                : addMonths(new Date(), 0)
                              : addMonths(new Date(), 12)
                          }
                          minDate={
                            !isAdmin
                              ? date && !isAdmin
                                ? subMonths(new Date(date), 0) !==
                                  subMonths(new Date(), 0)
                                  ? subMonths(new Date(), 1)
                                  : subMonths(new Date(date), 0)
                                : subMonths(new Date(), 0)
                              : subMonths(new Date(), 12)
                          }
                          disabledKeyboardNavigation
                          onChange={this.handleDate.bind(this, "date")}
                          name="date"
                          wrapperClassName="datePickerCustom"
                          dateFormat="MMMM yyyy"
                          className="form-control form-control-sm"
                          required
                          showMonthYearPicker
                        />
                        {errors.date && (
                          <div className="invalid-feedback">{errors.date}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="price">Outlook Price *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="price"
                          placeholder="Price"
                          required="required"
                          value={price || ""}
                          onChange={this.onChangeValue}
                          min="0"
                        />
                        {errors.price && (
                          <div className="invalid-feedback">{errors.price}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.price != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>

                        <select
                          disabled
                          className={
                            errors.currency != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="currency_create"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={currency}
                          readOnly={currencyreadOnly}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items &&
                            select_items.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.currency && (
                          <div className="invalid-feedback">
                            {errors.currency}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          errors.unit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="unit">Unit *</label>

                        <select
                          disabled
                          className={
                            errors.unit != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="unit"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={unit}
                          readOnly={currencyreadOnly}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_uom &&
                            select_items_uom.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.unit && (
                          <div className="invalid-feedback">{errors.unit}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.vendor != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="vendor">Vendor *</label>
                        <Select
                          isDisabled
                          styles={customStylesVendor}
                          onChange={this.handleChange.bind(this, "vendor")}
                          options={select_items_vendor}
                          defaultValue={select_items_vendor.filter(
                            (e) => e.label === vendor
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidVendor && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.vendor}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id && (
                  <Button
                    variant="success"
                    onClick={this.handleSubmitEdit}
                    className="float-right"
                  >
                    <i className="fas fa-save" /> Update
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Future Price</h3>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off"
                            className={
                              submitted_create
                                ? "needs-validation was-validated"
                                : "needs-validation"
                            }
                            noValidate
                            ref={(form) => (this.form = form)}
                          >
                            <Row>
                              <Col xs={12} md={4}>
                                <div
                                  className={
                                    errors_create.material !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="material">Material *</label>
                                  <Select
                                    styles={customStylesMaterialCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "material_create"
                                    )}
                                    options={select_items_material}
                                    defaultValue={select_items_material.filter(
                                      (e) => e.value === material_create
                                    )}
                                    isClearable={isClearable}
                                    ref={(ref) => (this.refMaterial = ref)}
                                  />

                                  {!isValidMaterialCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.material}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="date">Outlook Month *</label>
                                  <DatePicker
                                    selected={date_create}
                                    startDate={date_create}
                                    maxDate={
                                      !isAdmin
                                        ? date_create && !isAdmin
                                          ? addMonths(
                                              new Date(date_create),
                                              0
                                            ) !== addMonths(new Date(), 0)
                                            ? addMonths(new Date(), 1)
                                            : addMonths(
                                                new Date(date_create),
                                                0
                                              )
                                          : addMonths(new Date(), 0)
                                        : addMonths(new Date(), 12)
                                    }
                                    minDate={
                                      !isAdmin
                                        ? date_create && !isAdmin
                                          ? subMonths(
                                              new Date(date_create),
                                              0
                                            ) !== subMonths(new Date(), 0)
                                            ? subMonths(new Date(), 1)
                                            : subMonths(
                                                new Date(date_create),
                                                0
                                              )
                                          : subMonths(new Date(), 0)
                                        : subMonths(new Date(), 12)
                                    }
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_create"
                                    )}
                                    name="date"
                                    wrapperClassName="datePickerCustom"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    required
                                    showMonthYearPicker
                                    isClearable={isClearable}
                                  />
                                  {errors_create.date && (
                                    <div className="invalid-feedback">
                                      {errors_create.date}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="opening_rate">
                                    Outlook Price *
                                  </label>
                                  <input
                                    type="number"
                                    step="0.001"
                                    className="form-control form-control-sm"
                                    name="price_create"
                                    placeholder="Price"
                                    required="required"
                                    value={price_create || ""}
                                    onChange={this.onChangeValue}
                                    min="0"
                                  />
                                  {errors_create.price && (
                                    <div className="invalid-feedback">
                                      {errors_create.price}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={
                                    errors_create.price !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="currency">Currency *</label>

                                  <select
                                    className={
                                      errors_create.currency !=
                                      "This field is required"
                                        ? "form-control form-control-sm "
                                        : "form-control form-control-sm  has-error has-feedback"
                                    }
                                    name="currency_create"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={currency_create}
                                    required
                                  >
                                    <option value="">Choose</option>
                                    {select_items &&
                                      select_items.map(function (item, i) {
                                        return (
                                          <option value={item.value} key={i}>
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors_create.currency && (
                                    <div className="invalid-feedback">
                                      {errors_create.currency}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={
                                    errors_create.unit !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="unit">Unit *</label>

                                  <select
                                    className={
                                      errors_create.unit !=
                                      "This field is required"
                                        ? "form-control form-control-sm "
                                        : "form-control form-control-sm  has-error has-feedback"
                                    }
                                    name="unit_create"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={unit_create}
                                    readOnly={true}
                                    required
                                    ref={(ref) => (this.refUnit = ref)}
                                  >
                                    <option value="">Choose</option>
                                    {select_items_uom &&
                                      select_items_uom.map(function (item, i) {
                                        return (
                                          <option value={item.value} key={i}>
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors_create.currency && (
                                    <div className="invalid-feedback">
                                      {errors_create.currency}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={
                                    errors_create.vendor !=
                                    "This field is required"
                                      ? "form-group"
                                      : "form-group has-error has-feedback"
                                  }
                                >
                                  <label htmlFor="vendor">Vendor *</label>
                                  <Select
                                    styles={customStylesVendorCreate}
                                    onChange={this.handleChange.bind(
                                      this,
                                      "vendor_create"
                                    )}
                                    options={select_items_vendor}
                                    defaultValue={select_items_vendor.filter(
                                      (e) => e.value === vendor_create
                                    )}
                                    isClearable={isClearable}
                                    ref={(ref) => (this.refVendor = ref)}
                                  />

                                  {!isValidVendorCreate && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: " #dc3545",
                                      }}
                                    >
                                      {errors_create.vendor}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <Button
                                  variant="success"
                                  onClick={this.handleSubmit}
                                  className="float-right"
                                >
                                  <i className="fas fa-save" /> Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form autoComplete="off">
                            <Row>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">Start Month</label>
                                  <DatePicker
                                    selected={date_first}
                                    startDate={date_first}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_first"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col xs={12} md={3}>
                                <div className="form-group">
                                  <label htmlFor="date">End Month</label>
                                  <DatePicker
                                    selected={date_second}
                                    startDate={date_second}
                                    disabledKeyboardNavigation
                                    onChange={this.handleDate.bind(
                                      this,
                                      "date_second"
                                    )}
                                    name="date"
                                    dateFormat="MMMM yyyy"
                                    className="form-control form-control-sm"
                                    showMonthYearPicker
                                    required
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                            </Row>
                          </form>
                          <br />
                          <RemoteAll
                            data={list_datatable}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            cellEditProps={cellEditProps}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(SpotPriceOutlook);
