import SpotPriceOutlookService from "../services/spot_price_outlook.service";

export const addSpotPriceOutlook = (payload) => {
  return SpotPriceOutlookService.create(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const overwriteSpotPriceOutlook = (payload) => {
  return SpotPriceOutlookService.createOverwrite(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const editSpotPriceOutlook = (payload) => {
  return SpotPriceOutlookService.edit(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();


      return Promise.reject(message);
    }
  );
};
export const deleteSpotPriceOutlook = (payload) => {
  return SpotPriceOutlookService.delete(payload).then(
    (response) => {
      return Promise.resolve(response.data, response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject(message);
    }
  );
};

export const readSpotPriceOutlook = (date_first, date_second) => {
  return SpotPriceOutlookService.readSpotPriceOutlook(date_first, date_second).then(
    (response) => {

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};

export const readQsSpotPriceOutlook = (date_first, date_second) => {
  return SpotPriceOutlookService.readQsSpotPriceOutlook(date_first, date_second).then(
    (response) => {

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};
