import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ApsConfigService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "aps_config", payload, {
      headers: authHeader(),
    });
  }
  readApsConfig() {
    return axios.get(API_URL_DEFAULT + "aps_config/", {
      headers: authHeader(),
    });
  }
}

export default new ApsConfigService();
