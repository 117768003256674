import React, { Component } from "react";
import RemoteNoPagination from "../components/RemoteNoPagination";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { addMonths, differenceInMonths } from "date-fns";
import thousandformat from "../helpers/thousanddelimiter";
import XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";

import { readMaterial, readMaterialBySearch } from "../modules/MaterialModule";
import { readMaterialAveragePrice } from "../modules/MaterialAveragePriceModule";

import { withRouter } from "react-router-dom";
import Select from "react-select";

import { sendNotification } from "../modules/NotificationModule";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
class MaterialAveragePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButtonNotification: false,
      seconds: 60,
      requester: null,
      isExists: false,
      isSearched: false,
      requester_name: null,
      select_items_material: [],
      material_code: null,
      isClearable: false,
      date: new Date(),
      uniq_code: null,
      list_datatable_receipt: [
        {
          parameter: "Quantity",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          beginning_balance: null,
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_usage: [
        {
          parameter: "Quantity",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      list_datatable_end_balance: [
        {
          parameter: "Quantity",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Value",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
        {
          parameter: "Price/kg",
          month_1: null,
          month_2: null,
          month_3: null,
          month_4: null,
          month_5: null,
          month_6: null,
        },
      ],
      columns_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "beginning_balance",
          text: "Beginning Balance",
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      columns_non_receipt: [
        {
          dataField: "parameter",
          text: "Parameter",
          classes: "text-center",
        },
        {
          dataField: "month_1",
          text: dateFormat(new Date(), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_2",
          text: dateFormat(addMonths(new Date(), 1), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_3",
          text: dateFormat(addMonths(new Date(), 2), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_4",
          text: dateFormat(addMonths(new Date(), 3), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_5",
          text: dateFormat(addMonths(new Date(), 4), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
        {
          dataField: "month_6",
          text: dateFormat(addMonths(new Date(), 5), "mmm yy"),
          formatter: this.GetThousandDelimiterFormat,
          classes: "text-center",
        },
      ],
      header_xlsx: [["Parameter"]],
      errors: {},
      isValidMaterial: true,

      loading: false,
      isCreate: true,
      isRead: true,
      isDownload: false,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });
  }

  getMaterial = (input) => {
    const queryString = `constraint_exclude=material_type&constraint_value_exclude=ZSFG,ZFGD&sizePerPage=100&search=${input}`;
    const result = input
      ? readMaterialBySearch(queryString).then((res) => {
          return res;
        })
      : [];
    return result;
  };
  onModalLoadingHide() {}

  validateSearch = (date, material_code) => {
    let errors = this.state.errors;
    if (!date) {
      errors.date = "This is field required";
    }
    if (!material_code) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterial: false,
      });
    }
    return errors;
  };

  GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? (rowIndex > 0 ? "$" : "") +
            (rowIndex > 0
              ? thousandformat(cell.toFixed(DECIMAL_PLACES), ".", ".", ",")
              : thousandformat(cell, ".", ".", ","))
          : ""}
      </div>
    );
  };

  exportFile = () => {
    /* convert state to workbook */
    const {
      list_datatable_usage,
      list_datatable_receipt,
      list_datatable_end_balance,
      material_code,
      date,
      header_xlsx,
      uniq_code,
    } = this.state;

    let data_receipt = [...header_xlsx];
    let data_usage = [...header_xlsx];
    let data_end_balance = [...header_xlsx];

    let space_array = [["", "", "", "", "", "", "", "", "", "", "", "", ""]];

    let date_formatted = dateFormat(date, "mmmyy");
    let date_second = addMonths(date, 6);
    let diffMonths = differenceInMonths(date_second, date);

    /* RECEIPT */

    //creating headers
    data_receipt[0] = [...data_receipt[0], "Beginning Balance"];
    for (let i = 0; i < diffMonths; i++) {
      data_receipt[0] = [
        ...data_receipt[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_receipt.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_receipt = [...data_receipt, rowItem];
    });

    /* USAGE */

    //creating headers
    for (let i = 0; i < diffMonths; i++) {
      data_usage[0] = [
        ...data_usage[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_usage.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_usage = [...data_usage, rowItem];
    });

    /* END BALANCE */

    //creating header
    for (let i = 0; i < diffMonths; i++) {
      data_end_balance[0] = [
        ...data_end_balance[0],
        dateFormat(addMonths(date, i), "mmm-yy"),
      ];
    }

    //inserting values
    list_datatable_end_balance.map((item, idx) => {
      let rowItem = Object.values(item);
      if (idx !== 0) {
        let temp = rowItem.map((item, idx) => {
          if (idx !== 0) {
            return parseFloat(item).toFixed(DECIMAL_PLACES);
          } else return item;
        });
        rowItem = temp;
      }
      data_end_balance = [...data_end_balance, rowItem];
    });

    let merge_data = data_receipt
      .concat(space_array)
      .concat(data_usage.concat(space_array))
      .concat(data_end_balance);

    let ws_data = XLSX.utils.aoa_to_sheet(merge_data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws_data);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "map_version_" + uniq_code + ".xlsx");
  };

  columns_default_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
    {
      dataField: "beginning_balance",
      text: "Beginning Balance",
      formatter: this.GetThousandDelimiterFormat,
      classes: "text-center",
    },
  ];
  columns_default_non_receipt = [
    {
      dataField: "parameter",
      text: "Parameter",
      classes: "text-center",
    },
  ];

  handleDate = (name, date) => {
    this.setState({
      date: date,
    });
  };

  handleNotification = (e) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "map",
        };
        sendNotification(payload)
          .then((response) => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  handleSearch = () => {
    let {
      date,
      material_code,
      isValidMaterial,
      list_datatable_receipt,
      list_datatable_usage,
      list_datatable_end_balance,
    } = this.state;

    if (!this.validateSearch(date, material_code)) {
      return this.setState({
        isSearched: false,
      });
    }
    if (date && material_code) {
      this.setState({
        isSearched: true,
        list_datatable_receipt: [
          {
            parameter: "Quantity",
            beginning_balance: null,
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Value",
            beginning_balance: null,
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Price/kg",
            beginning_balance: null,
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
        ],
        list_datatable_usage: [
          {
            parameter: "Quantity",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Value",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Price/kg",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
        ],
        list_datatable_end_balance: [
          {
            parameter: "Quantity",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Value",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
          {
            parameter: "Price/kg",
            month_1: null,
            month_2: null,
            month_3: null,
            month_4: null,
            month_5: null,
            month_6: null,
          },
        ],
      });

      // WILL BE USED LATER
      readMaterialAveragePrice(dateFormat(date, "yyyy-mm-01"), material_code)
        .then((response) => {
          if (response != null && Object.keys(response).length) {
            this.setState({
              list_datatable_receipt: response.receipt,
              list_datatable_usage: response.usage,
              list_datatable_end_balance: response.end_balance,
              uniq_code: response.uniq_code,
              isDownload: true,
            });

            let columns_receipt = [...this.columns_default_receipt];
            let columns_non_receipt = [...this.columns_default_non_receipt];
            for (let i = 0; i < 6; i++) {
              columns_receipt = [
                ...columns_receipt,
                {
                  dataField: "month_" + (i + 1),
                  text: dateFormat(addMonths(date, i), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ];
              columns_non_receipt = [
                ...columns_non_receipt,
                {
                  dataField: "month_" + (i + 1),
                  text: dateFormat(addMonths(date, i), "mmm yy"),
                  formatter: this.GetThousandDelimiterFormat,
                  classes: "text-center",
                },
              ];
            }

            this.setState({
              columns_receipt: columns_receipt,
              columns_non_receipt: columns_non_receipt,

              errorMessage: null,
              isExists: true,
            });
          } else {
            this.setState({
              uniq_code: null,
              isDownload: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            uniq_code: null,
            isExists: false,
            errorMessage: e,
            isDownload: false,
          });
        });
    }
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.label,
        [name + "_code"]: newValue.value,
        material_code_object: newValue,
      });
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
        [name + "_code"]: null,
      });
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
        });
      }
    }
  };

  render() {
    let {
      disableButtonNotification,
      seconds,
      select_items_material,
      material_code,
      isValidMaterial,
      isClearable,
      date,
      list_datatable_receipt,
      list_datatable_usage,
      list_datatable_end_balance,
      columns_receipt,
      columns_non_receipt,
      errors,
      isRead,
      isDownload,
      errorMessage,
      isExists,
      isSearched,
      uniq_code,
      material_code_object,
    } = this.state;

    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
            ? "#ddd"
            : "green",
        },
      }),
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Material Average Price</h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <form
                          autoComplete="off"
                          noValidate
                          ref={(form) => (this.form = form)}
                        >
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="form-group">
                                <label htmlFor="date">Planning Month *</label>
                                <DatePicker
                                  selected={date}
                                  startDate={date}
                                  disabledKeyboardNavigation
                                  onChange={this.handleDate.bind(this, "date")}
                                  name="date"
                                  wrapperClassName="datePickerCustom"
                                  dateFormat="MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required
                                  showMonthYearPicker
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="material_code">
                                  Material *
                                </label>
                                <AsyncSelect
                                  isClearable={true}
                                  styles={customStylesMaterial}
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={this.getMaterial}
                                  onChange={this.handleChange.bind(
                                    this,
                                    "material"
                                  )}
                                  defaultValue={material_code_object || ""}
                                  placeholder="Type something ..."
                                />
                                {!isValidMaterial && (
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: ".25rem",
                                      fontSize: "80%",
                                      color: " #dc3545",
                                    }}
                                  >
                                    {errors.material}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}></Col>
                            <Col xs={12} md={6}>
                              <Button
                                variant="warning"
                                onClick={this.handleNotification}
                                className="float-left"
                                disabled={disableButtonNotification}
                              >
                                <i className="fab fa-telegram-plane" /> Send
                                Notification{" "}
                                {disableButtonNotification
                                  ? `(in ${seconds}s)`
                                  : ""}
                              </Button>
                              <Button
                                variant="info"
                                onClick={this.handleSearch}
                                className="float-right"
                              >
                                <i className="fas fa-search" /> Search
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {isRead && isSearched && isExists && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">MAP Data </h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          {isDownload && (
                            <div style={{ marginBottom: 20 }}>
                              <Button
                                variant="success"
                                onClick={this.exportFile}
                                className="float-right"
                              >
                                <i className="fas fa-download" /> Export to
                                Excel
                              </Button>
                            </div>
                          )}
                          <h5>Version {uniq_code}</h5>
                          <h5>Receipt</h5>
                          <RemoteNoPagination
                            data={list_datatable_receipt}
                            columns={columns_receipt}
                          />
                          <h5>Usage</h5>
                          <RemoteNoPagination
                            data={list_datatable_usage}
                            columns={columns_non_receipt}
                          />
                          <h5>End Balance</h5>
                          <RemoteNoPagination
                            data={list_datatable_end_balance}
                            columns={columns_non_receipt}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}

                {isSearched && !isExists && (
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <div className="card card-info">
                          <div className="card-body">
                            <Row>
                              <Col xs={12} md={12}>
                                <h3
                                  style={{ color: "red" }}
                                  className="text-center"
                                >
                                  {" "}
                                  {errorMessage}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(MaterialAveragePrice);
