import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BOMPowderService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "bom_powder_price", payload, {
      headers: authHeader(),
    });
  }
  readBOMPowder(query_string) {
    return axios.get(API_URL_DEFAULT + "bom_powder_price/?" + query_string, {
      headers: authHeader(),
    });
  }
}

export default new BOMPowderService();
